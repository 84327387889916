import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify } from '@affine/component';
import { SettingRow } from '@affine/component/setting-components';
import { ConfirmModal } from '@affine/component/ui/modal';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { useI18n } from '@affine/i18n';
import { ArrowRightSmallIcon } from '@blocksuite/icons/rc';
import { GlobalContextService, useLiveData, useServices, WorkspaceService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { RouteLogic, useNavigateHelper } from '../../../../../../components/hooks/use-navigate-helper';
import { openSettingModalAtom } from '../../../../../atoms';
import { WorkspaceDeleteModal } from './delete';
export const DeleteLeaveWorkspace = ()=>{
    const { workspaceService, globalContextService, workspacePermissionService, workspacesService } = useServices({
        WorkspaceService,
        GlobalContextService,
        WorkspacePermissionService,
        WorkspacesService
    });
    const t = useI18n();
    const workspace = workspaceService.workspace;
    const { jumpToPage, jumpToIndex } = useNavigateHelper();
    const [showDelete, setShowDelete] = useState(false);
    const [showLeave, setShowLeave] = useState(false);
    const setSettingModal = useSetAtom(openSettingModalAtom);
    const workspaceList = useLiveData(workspacesService.list.workspaces$);
    const currentWorkspaceId = useLiveData(globalContextService.globalContext.workspaceId.$);
    const isOwner = useLiveData(workspacePermissionService.permission.isOwner$);
    useEffect(()=>{
        workspacePermissionService.permission.revalidate();
    }, [
        workspacePermissionService
    ]);
    const onLeaveOrDelete = useCallback(()=>{
        if (isOwner !== null) {
            if (isOwner) {
                setShowDelete(true);
            } else {
                setShowLeave(true);
            }
        }
    }, [
        isOwner
    ]);
    const onDeleteConfirm = useAsyncCallback(async ()=>{
        setSettingModal((prev)=>({
                ...prev,
                open: false,
                workspaceId: null
            }));
        if (currentWorkspaceId === workspace.id) {
            const backWorkspace = workspaceList.find((ws)=>ws.id !== currentWorkspaceId);
            if (backWorkspace) {
                jumpToPage(backWorkspace?.id || '', 'all', RouteLogic.REPLACE);
            } else {
                jumpToIndex(RouteLogic.REPLACE);
            }
        }
        if (isOwner) {
            await workspacesService.deleteWorkspace(workspace.meta);
        } else {
            await workspacePermissionService.leaveWorkspace();
        }
        notify.success({
            title: t['Successfully deleted']()
        });
    }, [
        setSettingModal,
        currentWorkspaceId,
        workspace.id,
        workspace.meta,
        isOwner,
        t,
        workspaceList,
        jumpToPage,
        jumpToIndex,
        workspacesService,
        workspacePermissionService
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingRow, {
                name: _jsx("span", {
                    style: {
                        color: 'var(--affine-error-color)'
                    },
                    children: isOwner ? t['com.affine.workspaceDelete.title']() : t['com.affine.deleteLeaveWorkspace.leave']()
                }),
                desc: t['com.affine.deleteLeaveWorkspace.description'](),
                style: {
                    cursor: 'pointer'
                },
                onClick: onLeaveOrDelete,
                "data-testid": "delete-workspace-button",
                children: _jsx(ArrowRightSmallIcon, {})
            }),
            isOwner ? _jsx(WorkspaceDeleteModal, {
                onConfirm: onDeleteConfirm,
                open: showDelete,
                onOpenChange: setShowDelete,
                workspaceMetadata: workspace.meta
            }) : _jsx(ConfirmModal, {
                open: showLeave,
                cancelText: t['com.affine.confirmModal.button.cancel'](),
                onConfirm: onDeleteConfirm,
                onOpenChange: setShowLeave,
                title: `${t['com.affine.deleteLeaveWorkspace.leave']()}?`,
                description: t['com.affine.deleteLeaveWorkspace.leaveDescription'](),
                confirmText: t['Leave'](),
                confirmButtonOptions: {
                    variant: 'error'
                }
            })
        ]
    });
};
