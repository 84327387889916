import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading, Scrollable } from '@affine/component';
import { WorkspaceDetailSkeleton } from '@affine/component/setting-components';
import { Modal } from '@affine/component/ui/modal';
import { openIssueFeedbackModalAtom, openStarAFFiNEModalAtom } from '@affine/core/components/atoms';
import { AuthService } from '@affine/core/modules/cloud';
import { Trans } from '@affine/i18n';
import { ContactWithUsIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash-es';
import { Suspense, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { AccountSetting } from './account-setting';
import { settingModalScrollContainerAtom } from './atoms';
import { GeneralSetting } from './general-setting';
import { SettingSidebar } from './setting-sidebar';
import * as style from './style.css';
import { GeneralSettingKeys } from './types';
import { WorkspaceSetting } from './workspace-setting';
const isGeneralSetting = (key)=>GeneralSettingKeys.includes(key);
const CenteredLoading = ()=>{
    return _jsx("div", {
        className: style.centeredLoading,
        children: _jsx(Loading, {
            size: 24
        })
    });
};
const SettingModalInner = ({ activeTab = 'appearance', workspaceMetadata = null, onSettingClick, ...modalProps })=>{
    const loginStatus = useLiveData(useService(AuthService).session.status$);
    const modalContentRef = useRef(null);
    const modalContentWrapperRef = useRef(null);
    const setSettingModalScrollContainer = useSetAtom(settingModalScrollContainerAtom);
    useLayoutEffect(()=>{
        if (!modalProps.open) return;
        let animationFrameId;
        const onResize = debounce(()=>{
            cancelAnimationFrame(animationFrameId);
            animationFrameId = requestAnimationFrame(()=>{
                if (!modalContentRef.current || !modalContentWrapperRef.current) return;
                const wrapperWidth = modalContentWrapperRef.current.offsetWidth;
                const wrapperHeight = modalContentWrapperRef.current.offsetHeight;
                const contentWidth = modalContentRef.current.offsetWidth;
                const wrapper = modalContentWrapperRef.current;
                wrapper?.style.setProperty('--setting-modal-width', `${wrapperWidth}px`);
                wrapper?.style.setProperty('--setting-modal-height', `${wrapperHeight}px`);
                wrapper?.style.setProperty('--setting-modal-content-width', `${contentWidth}px`);
                wrapper?.style.setProperty('--setting-modal-gap-x', `${(wrapperWidth - contentWidth) / 2}px`);
            });
        }, 200);
        window.addEventListener('resize', onResize);
        onResize();
        return ()=>{
            cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', onResize);
        };
    }, [
        modalProps.open
    ]);
    useEffect(()=>{
        setSettingModalScrollContainer(modalContentWrapperRef.current);
        return ()=>{
            setSettingModalScrollContainer(null);
        };
    }, [
        setSettingModalScrollContainer
    ]);
    const onTabChange = useCallback((key, meta)=>{
        onSettingClick({
            activeTab: key,
            workspaceMetadata: meta
        });
    }, [
        onSettingClick
    ]);
    const setOpenIssueFeedbackModal = useSetAtom(openIssueFeedbackModalAtom);
    const setOpenStarAFFiNEModal = useSetAtom(openStarAFFiNEModalAtom);
    const handleOpenIssueFeedbackModal = useCallback(()=>{
        setOpenIssueFeedbackModal(true);
    }, [
        setOpenIssueFeedbackModal
    ]);
    const handleOpenStarAFFiNEModal = useCallback(()=>{
        setOpenStarAFFiNEModal(true);
    }, [
        setOpenStarAFFiNEModal
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingSidebar, {
                activeTab: activeTab,
                onTabChange: onTabChange,
                selectedWorkspaceId: workspaceMetadata?.id ?? null
            }),
            _jsx(Scrollable.Root, {
                children: _jsxs(Scrollable.Viewport, {
                    "data-testid": "setting-modal-content",
                    className: style.wrapper,
                    ref: modalContentWrapperRef,
                    children: [
                        _jsxs("div", {
                            ref: modalContentRef,
                            className: style.centerContainer,
                            children: [
                                _jsx("div", {
                                    className: style.content,
                                    children: _jsxs(Suspense, {
                                        fallback: _jsx(WorkspaceDetailSkeleton, {}),
                                        children: [
                                            activeTab.startsWith('workspace:') && workspaceMetadata ? _jsx(WorkspaceSetting, {
                                                subTab: activeTab.split(':')[1],
                                                workspaceMetadata: workspaceMetadata
                                            }, workspaceMetadata.id) : null,
                                            isGeneralSetting(activeTab) ? _jsx(GeneralSetting, {
                                                generalKey: activeTab
                                            }) : null,
                                            activeTab === 'account' && loginStatus === 'authenticated' ? _jsx(AccountSetting, {}) : null
                                        ]
                                    })
                                }),
                                _jsxs("div", {
                                    className: style.footer,
                                    children: [
                                        _jsx(ContactWithUsIcon, {
                                            fontSize: 16
                                        }),
                                        _jsx(Trans, {
                                            i18nKey: 'com.affine.settings.suggestion-2',
                                            components: {
                                                1: _jsx("span", {
                                                    className: style.link,
                                                    onClick: handleOpenStarAFFiNEModal
                                                }),
                                                2: _jsx("span", {
                                                    className: style.link,
                                                    onClick: handleOpenIssueFeedbackModal
                                                })
                                            }
                                        })
                                    ]
                                })
                            ]
                        }),
                        _jsx(Scrollable.Scrollbar, {})
                    ]
                })
            })
        ]
    });
};
export const SettingModal = ({ activeTab = 'appearance', workspaceMetadata = null, onSettingClick, ...modalProps })=>{
    return _jsx(Modal, {
        width: 1280,
        height: 920,
        contentOptions: {
            ['data-testid']: 'setting-modal',
            style: {
                maxHeight: '85vh',
                maxWidth: '70vw',
                padding: 0,
                overflow: 'hidden',
                display: 'flex'
            }
        },
        ...modalProps,
        children: _jsx(Suspense, {
            fallback: _jsx(CenteredLoading, {}),
            children: _jsx(SettingModalInner, {
                activeTab: activeTab,
                workspaceMetadata: workspaceMetadata,
                onSettingClick: onSettingClick,
                ...modalProps
            })
        })
    });
};
