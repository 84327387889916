export const FrameConfig = [
    {
        name: '1:1',
        wh: [
            1200,
            1200
        ]
    },
    {
        name: '4:3',
        wh: [
            1600,
            1200
        ]
    },
    {
        name: '16:9',
        wh: [
            1600,
            900
        ]
    },
    {
        name: '2:1',
        wh: [
            1600,
            800
        ]
    }
];
