function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init_fillScreen, _init_frame, _init_frameViewportWH, _init_previewEditor, _init_surfaceHeight, _init_surfaceWidth, _initProto;
import { BlockServiceWatcher, BlockStdScope } from '@blocksuite/block-std';
import { ShadowlessElement } from '@blocksuite/block-std';
import { Bound, debounce, deserializeXYWH, DisposableGroup, WithDisposable } from '@blocksuite/global/utils';
import { BlockViewType } from '@blocksuite/store';
import { css, html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { SpecProvider } from '../../../../_specs/index.js';
const DEFAULT_PREVIEW_CONTAINER_WIDTH = 280;
const DEFAULT_PREVIEW_CONTAINER_HEIGHT = 166;
const styles = css`
  .frame-preview-container {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .frame-preview-surface-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .frame-preview-viewport {
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    pointer-events: none;
    user-select: none;

    .edgeless-background {
      background-color: transparent;
      background-image: none;
    }
  }
`;
_dec = state(), _dec1 = property({
    attribute: false
}), _dec2 = state(), _dec3 = query('editor-host'), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
});
export class FramePreview extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init_fillScreen, _init_frame, _init_frameViewportWH, _init_previewEditor, _init_surfaceHeight, _init_surfaceWidth, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "fillScreen"
            ],
            [
                _dec1,
                1,
                "frame"
            ],
            [
                _dec2,
                1,
                "frameViewportWH"
            ],
            [
                _dec3,
                1,
                "previewEditor"
            ],
            [
                _dec4,
                1,
                "surfaceHeight"
            ],
            [
                _dec5,
                1,
                "surfaceWidth"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _originalDoc() {
        return this.frame.doc;
    }
    _initPreviewDoc() {
        this._previewDoc = this._originalDoc.collection.getDoc(this._originalDoc.id, {
            query: this._docFilter,
            readonly: true
        });
        this.disposables.add(()=>{
            this._originalDoc.blockCollection.clearQuery(this._docFilter);
        });
    }
    _initSpec() {
        const refreshViewport = this._refreshViewport.bind(this);
        class FramePreviewWatcher extends BlockServiceWatcher {
            static{
                this.flavour = 'affine:page';
            }
            mounted() {
                const blockService = this.blockService;
                blockService.disposables.add(blockService.specSlots.viewConnected.on(({ component })=>{
                    const edgelessBlock = component;
                    edgelessBlock.editorViewportSelector = 'frame-preview-viewport';
                    edgelessBlock.service.viewport.sizeUpdated.once(()=>{
                        refreshViewport();
                    });
                }));
            }
        }
        this._previewSpec.extend([
            FramePreviewWatcher
        ]);
    }
    _refreshViewport() {
        const previewEditorHost = this.previewEditor;
        if (!previewEditorHost) return;
        const edgelessService = previewEditorHost.std.getService('affine:page');
        const frameBound = Bound.deserialize(this.frame.xywh);
        edgelessService.viewport.setViewportByBound(frameBound);
    }
    _renderSurfaceContent() {
        if (!this._previewDoc || !this.frame) return nothing;
        const { width, height } = this.frameViewportWH;
        const _previewSpec = this._previewSpec.value;
        return html`<div
      class="frame-preview-surface-container"
      style=${styleMap({
            width: `${this.surfaceWidth}px`,
            height: `${this.surfaceHeight}px`
        })}
    >
      <div
        class="frame-preview-viewport"
        style=${styleMap({
            width: `${width}px`,
            height: `${height}px`
        })}
      >
        ${new BlockStdScope({
            doc: this._previewDoc,
            extensions: _previewSpec
        }).render()}
      </div>
    </div>`;
    }
    _setFrameDisposables(frame) {
        this._clearFrameDisposables();
        this._frameDisposables = new DisposableGroup();
        this._frameDisposables.add(frame.propsUpdated.on(debounce(this._updateFrameViewportWH, 10)));
    }
    connectedCallback() {
        super.connectedCallback();
        this._initSpec();
        this._initPreviewDoc();
        this._updateFrameViewportWH();
        this._setFrameDisposables(this.frame);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._clearFrameDisposables();
    }
    render() {
        const { frame } = this;
        const noContent = !frame || !frame.xywh;
        return html`<div class="frame-preview-container">
      ${noContent ? nothing : this._renderSurfaceContent()}
    </div>`;
    }
    updated(_changedProperties) {
        if (_changedProperties.has('frame')) {
            this._setFrameDisposables(this.frame);
        }
        if (_changedProperties.has('frameViewportWH')) {
            this._refreshViewport();
        }
    }
    #___private_fillScreen_1;
    get fillScreen() {
        return this.#___private_fillScreen_1;
    }
    set fillScreen(_v) {
        this.#___private_fillScreen_1 = _v;
    }
    #___private_frame_2;
    get frame() {
        return this.#___private_frame_2;
    }
    set frame(_v) {
        this.#___private_frame_2 = _v;
    }
    #___private_frameViewportWH_3;
    get frameViewportWH() {
        return this.#___private_frameViewportWH_3;
    }
    set frameViewportWH(_v) {
        this.#___private_frameViewportWH_3 = _v;
    }
    #___private_previewEditor_4;
    get previewEditor() {
        return this.#___private_previewEditor_4;
    }
    set previewEditor(_v) {
        this.#___private_previewEditor_4 = _v;
    }
    #___private_surfaceHeight_5;
    get surfaceHeight() {
        return this.#___private_surfaceHeight_5;
    }
    set surfaceHeight(_v) {
        this.#___private_surfaceHeight_5 = _v;
    }
    #___private_surfaceWidth_6;
    get surfaceWidth() {
        return this.#___private_surfaceWidth_6;
    }
    set surfaceWidth(_v) {
        this.#___private_surfaceWidth_6 = _v;
    }
    constructor(...args){
        super(...args), this._clearFrameDisposables = ()=>{
            this._frameDisposables?.dispose();
            this._frameDisposables = null;
        }, this._docFilter = {
            mode: 'loose',
            match: [
                {
                    flavour: 'affine:frame',
                    viewType: BlockViewType.Hidden
                }
            ]
        }, this._frameDisposables = null, this._previewDoc = null, this._previewSpec = SpecProvider.getInstance().getSpec('edgeless:preview'), this._updateFrameViewportWH = ()=>{
            const [, , w, h] = deserializeXYWH(this.frame.xywh);
            let scale = 1;
            if (this.fillScreen) {
                scale = Math.max(this.surfaceWidth / w, this.surfaceHeight / h);
            } else {
                scale = Math.min(this.surfaceWidth / w, this.surfaceHeight / h);
            }
            this.frameViewportWH = {
                width: w * scale,
                height: h * scale
            };
        }, this.#___private_fillScreen_1 = (_initProto(this), _init_fillScreen(this, false)), this.#___private_frame_2 = _init_frame(this), this.#___private_frameViewportWH_3 = _init_frameViewportWH(this, {
            width: 0,
            height: 0
        }), this.#___private_previewEditor_4 = _init_previewEditor(this, null), this.#___private_surfaceHeight_5 = _init_surfaceHeight(this, DEFAULT_PREVIEW_CONTAINER_HEIGHT), this.#___private_surfaceWidth_6 = _init_surfaceWidth(this, DEFAULT_PREVIEW_CONTAINER_WIDTH);
    }
}
