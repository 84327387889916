import { focusTextModel } from '@blocksuite/affine-components/rich-text';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { correctNumberedListsOrderToPrev } from './utils.js';
export const indentListCommand = (ctx, next)=>{
    let { blockId, inlineIndex } = ctx;
    const { std } = ctx;
    const { selection, doc } = std;
    if (!blockId) {
        const text = selection.find('text');
        if (!text || text.to && text.from.blockId !== text.to.blockId) {
            return;
        }
        blockId = text.from.blockId;
        inlineIndex = text.from.index;
    }
    if (blockId == null || inlineIndex == null) {
        return;
    }
    const model = doc.getBlock(blockId)?.model;
    if (!model || !matchFlavours(model, [
        'affine:list'
    ])) {
        console.error(`block ${blockId} is not a list block`);
        return;
    }
    const schema = std.doc.schema;
    const previousSibling = doc.getPrev(model);
    if (doc.readonly || !previousSibling || !schema.isValid(model.flavour, previousSibling.flavour)) {
        return;
    }
    const nextSibling = doc.getNext(model);
    doc.captureSync();
    doc.moveBlocks([
        model
    ], previousSibling);
    correctNumberedListsOrderToPrev(doc, model);
    if (nextSibling) correctNumberedListsOrderToPrev(doc, nextSibling);
    focusTextModel(std, model.id, inlineIndex);
    return next();
};
