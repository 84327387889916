import { DebugLogger } from '@affine/debug';
import { nanoid } from 'nanoid';
import { map } from 'rxjs';
import { LiveData } from '../../livedata';
import { MANUALLY_STOP } from '../../utils';
import { DocEngineLocalPart } from './local';
import { DocEngineRemotePart } from './remote';
import { DocStorageInner } from './storage';
const logger = new DebugLogger('doc-engine');
export { MemoryDocEventBus } from './event';
export { MemoryStorage as MemoryDocStorage, ReadonlyStorage as ReadonlyDocStorage } from './storage';
export class DocEngine {
    docState$(docId) {
        const localState$ = this.localPart.docState$(docId);
        const remoteState$ = this.remotePart?.docState$(docId);
        return LiveData.computed((get)=>{
            const localState = get(localState$);
            const remoteState = remoteState$ ? get(remoteState$) : null;
            if (remoteState) {
                return {
                    syncing: remoteState.syncing,
                    saving: localState.syncing,
                    loading: localState.syncing,
                    retrying: remoteState.retrying,
                    ready: localState.ready,
                    errorMessage: remoteState.errorMessage,
                    serverClock: remoteState.serverClock
                };
            }
            return {
                syncing: localState.syncing,
                saving: localState.syncing,
                loading: localState.syncing,
                ready: localState.ready,
                retrying: false,
                errorMessage: null,
                serverClock: null
            };
        });
    }
    markAsReady(docId) {
        this.localPart.actions.markAsReady(docId);
    }
    constructor(storage, server){
        this.server = server;
        this.engineState$ = LiveData.computed((get)=>{
            const localState = get(this.localPart.engineState$);
            if (this.remotePart) {
                const remoteState = get(this.remotePart?.engineState$);
                return {
                    total: remoteState.total,
                    syncing: remoteState.syncing,
                    saving: localState.syncing,
                    retrying: remoteState.retrying,
                    errorMessage: remoteState.errorMessage
                };
            }
            return {
                total: localState.total,
                syncing: localState.syncing,
                saving: localState.syncing,
                retrying: false,
                errorMessage: null
            };
        });
        this.abort = new AbortController();
        this.clientId = nanoid();
        this.storage = new DocStorageInner(storage);
        this.localPart = new DocEngineLocalPart(this.clientId, this.storage);
        this.remotePart = this.server ? new DocEngineRemotePart(this.clientId, this.storage, this.server) : null;
    }
    start() {
        this.abort.abort(MANUALLY_STOP);
        this.abort = new AbortController();
        Promise.all([
            this.localPart.mainLoop(this.abort.signal),
            this.remotePart?.mainLoop(this.abort.signal)
        ]).catch((err)=>{
            if (err === MANUALLY_STOP) {
                return;
            }
            logger.error('Doc engine error', err);
        });
        return this;
    }
    stop() {
        this.abort.abort(MANUALLY_STOP);
    }
    async resetSyncStatus() {
        this.stop();
        await this.storage.clearSyncMetadata();
        await this.storage.clearServerClock();
    }
    addDoc(doc, withSubDocs = true) {
        this.remotePart?.actions.addDoc(doc.guid);
        this.localPart.actions.addDoc(doc);
        if (withSubDocs) {
            doc.on('subdocs', ({ added, loaded })=>{
                for (const subdoc of added){
                    this.remotePart?.actions.addDoc(subdoc.guid);
                }
                for (const subdoc of loaded){
                    this.localPart.actions.addDoc(subdoc);
                }
            });
        }
    }
    setPriority(docId, priority) {
        this.localPart.setPriority(docId, priority);
        this.remotePart?.setPriority(docId, priority);
    }
    waitForSaved() {
        return new Promise((resolve)=>{
            this.engineState$.pipe(map((state)=>state.saving === 0)).subscribe((saved)=>{
                if (saved) {
                    resolve();
                }
            });
        });
    }
    waitForSynced() {
        return new Promise((resolve)=>{
            this.engineState$.pipe(map((state)=>state.syncing === 0 && state.saving === 0)).subscribe((synced)=>{
                if (synced) {
                    resolve();
                }
            });
        });
    }
    waitForReady(docId) {
        return new Promise((resolve)=>{
            this.docState$(docId).pipe(map((state)=>state.ready)).subscribe((ready)=>{
                if (ready) {
                    resolve();
                }
            });
        });
    }
    dispose() {
        this.stop();
        this.server?.dispose?.();
    }
}
