import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createReactComponentFromLit, useConfirmModal, useLitPortalFactory } from '@affine/component';
import { useJournalInfoHelper } from '@affine/core/components/hooks/use-journal';
import { EditorService } from '@affine/core/modules/editor';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { toURLSearchParams } from '@affine/core/modules/navigation';
import { PeekViewService } from '@affine/core/modules/peek-view';
import { DocTitle, EdgelessEditor, PageEditor } from '@blocksuite/affine/presets';
import { DocService, DocsService, FeatureFlagService, useFramework, useLiveData, useService, useServices, WorkspaceService } from '@toeverything/infra';
import React, { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { PagePropertiesTable } from '../../affine/page-properties';
import { AffinePageReference, AffineSharedPageReference } from '../../affine/reference-link';
import { BiDirectionalLinkPanel } from './bi-directional-link-panel';
import { BlocksuiteEditorJournalDocTitle } from './journal-doc-title';
import { patchDocModeService, patchEdgelessClipboard, patchEmbedLinkedDocBlockConfig, patchForSharedPage, patchNotificationService, patchParseDocUrlExtension, patchPeekViewService, patchQuickSearchService, patchReferenceRenderer } from './specs/custom/spec-patchers';
import { createEdgelessModeSpecs } from './specs/edgeless';
import { createPageModeSpecs } from './specs/page';
import * as styles from './styles.css';
const adapted = {
    DocEditor: createReactComponentFromLit({
        react: React,
        elementClass: PageEditor
    }),
    DocTitle: createReactComponentFromLit({
        react: React,
        elementClass: DocTitle
    }),
    EdgelessEditor: createReactComponentFromLit({
        react: React,
        elementClass: EdgelessEditor
    })
};
const usePatchSpecs = (shared, mode)=>{
    const [reactToLit, portals] = useLitPortalFactory();
    const { peekViewService, docService, docsService, editorService, workspaceService, featureFlagService } = useServices({
        PeekViewService,
        DocService,
        DocsService,
        WorkspaceService,
        EditorService,
        FeatureFlagService
    });
    const framework = useFramework();
    const referenceRenderer = useMemo(()=>{
        return function customReference(reference) {
            const data = reference.delta.attributes?.reference;
            if (!data) return _jsx("span", {});
            const pageId = data.pageId;
            if (!pageId) return _jsx("span", {});
            const params = toURLSearchParams(data.params);
            if (workspaceService.workspace.openOptions.isSharedMode) {
                return _jsx(AffineSharedPageReference, {
                    docCollection: workspaceService.workspace.docCollection,
                    pageId: pageId,
                    params: params
                });
            }
            return _jsx(AffinePageReference, {
                pageId: pageId,
                params: params
            });
        };
    }, [
        workspaceService
    ]);
    const specs = useMemo(()=>{
        const enableAI = featureFlagService.flags.enable_ai.value;
        return mode === 'edgeless' ? createEdgelessModeSpecs(framework, enableAI) : createPageModeSpecs(framework, enableAI);
    }, [
        featureFlagService,
        mode,
        framework
    ]);
    const confirmModal = useConfirmModal();
    const patchedSpecs = useMemo(()=>{
        let patched = specs.concat(patchReferenceRenderer(reactToLit, referenceRenderer));
        patched = patched.concat(patchNotificationService(confirmModal));
        patched = patched.concat(patchPeekViewService(peekViewService));
        patched = patched.concat(patchEdgelessClipboard());
        patched = patched.concat(patchParseDocUrlExtension(framework));
        patched = patched.concat(patchQuickSearchService(framework));
        patched = patched.concat(patchEmbedLinkedDocBlockConfig(framework));
        if (shared) {
            patched = patched.concat(patchForSharedPage());
        }
        patched = patched.concat(patchDocModeService(docService, docsService, editorService));
        return patched;
    }, [
        confirmModal,
        docService,
        docsService,
        editorService,
        framework,
        peekViewService,
        reactToLit,
        referenceRenderer,
        shared,
        specs
    ]);
    return [
        patchedSpecs,
        useMemo(()=>_jsx(_Fragment, {
                children: portals.map((p)=>_jsx(Fragment, {
                        children: p.portal
                    }, p.id))
            }), [
            portals
        ])
    ];
};
export const BlocksuiteDocEditor = forwardRef(function BlocksuiteDocEditor({ page, shared, onClickBlank, titleRef: externalTitleRef }, ref) {
    const titleRef = useRef(null);
    const docRef = useRef(null);
    const { isJournal } = useJournalInfoHelper(page.id);
    const editorSettingService = useService(EditorSettingService);
    const onDocRef = useCallback((el)=>{
        docRef.current = el;
        if (ref) {
            if (typeof ref === 'function') {
                ref(el);
            } else {
                ref.current = el;
            }
        }
    }, [
        ref
    ]);
    const onTitleRef = useCallback((el)=>{
        titleRef.current = el;
        if (externalTitleRef) {
            if (typeof externalTitleRef === 'function') {
                externalTitleRef(el);
            } else {
                externalTitleRef.current = el;
            }
        }
    }, [
        externalTitleRef
    ]);
    const [specs, portals] = usePatchSpecs(!!shared, 'page');
    const displayBiDirectionalLink = useLiveData(editorSettingService.editorSetting.settings$.selector((s)=>s.displayBiDirectionalLink));
    return _jsxs(_Fragment, {
        children: [
            _jsxs("div", {
                className: styles.affineDocViewport,
                style: {
                    height: '100%'
                },
                children: [
                    !isJournal ? _jsx(adapted.DocTitle, {
                        doc: page,
                        ref: onTitleRef
                    }) : _jsx(BlocksuiteEditorJournalDocTitle, {
                        page: page
                    }),
                    !shared ? _jsx(PagePropertiesTable, {
                        docId: page.id,
                        page: page
                    }) : null,
                    _jsx(adapted.DocEditor, {
                        className: styles.docContainer,
                        ref: onDocRef,
                        doc: page,
                        specs: specs,
                        hasViewport: false
                    }),
                    _jsx("div", {
                        className: styles.docEditorGap,
                        "data-testid": "page-editor-blank",
                        onClick: onClickBlank
                    }),
                    !shared && displayBiDirectionalLink ? _jsx(BiDirectionalLinkPanel, {}) : null
                ]
            }),
            portals
        ]
    });
});
export const BlocksuiteEdgelessEditor = forwardRef(function BlocksuiteEdgelessEditor({ page, shared }, ref) {
    const [specs, portals] = usePatchSpecs(!!shared, 'edgeless');
    const editorRef = useRef(null);
    const onDocRef = useCallback((el)=>{
        editorRef.current = el;
        if (ref) {
            if (typeof ref === 'function') {
                ref(el);
            } else {
                ref.current = el;
            }
        }
    }, [
        ref
    ]);
    useEffect(()=>{
        if (editorRef.current) {
            editorRef.current.updateComplete.then(()=>{
                editorRef.current?.querySelector('affine-edgeless-root')?.click();
            }).catch(console.error);
        }
    }, []);
    return _jsxs(_Fragment, {
        children: [
            _jsx(adapted.EdgelessEditor, {
                ref: onDocRef,
                doc: page,
                specs: specs
            }),
            portals
        ]
    });
});
