const UNTITLED_PAGE_NAME = 'Untitled';
export const FileExporter = {
    exportFile (filename, dataURL) {
        const element = document.createElement('a');
        element.setAttribute('href', dataURL);
        const safeFilename = getSafeFileName(filename);
        element.setAttribute('download', safeFilename);
        element.style.display = 'none';
        document.body.append(element);
        element.click();
        element.remove();
    },
    exportPng (docTitle, dataURL) {
        const title = docTitle?.trim() || UNTITLED_PAGE_NAME;
        FileExporter.exportFile(title + '.png', dataURL);
    }
};
function getSafeFileName(string) {
    const replacement = ' ';
    const filenameReservedRegex = /[<>:"/\\|?*\u0000-\u001F]/g;
    const windowsReservedNameRegex = /^(con|prn|aux|nul|com\d|lpt\d)$/i;
    const reControlChars = /[\u0000-\u001F\u0080-\u009F]/g;
    const reTrailingPeriods = /\.+$/;
    const allowedLength = 50;
    function trimRepeated(string, target) {
        const escapeStringRegexp = target.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
        const regex = new RegExp(`(?:${escapeStringRegexp}){2,}`, 'g');
        return string.replace(regex, target);
    }
    string = string.normalize('NFD').replace(filenameReservedRegex, replacement).replace(reControlChars, replacement).replace(reTrailingPeriods, '');
    string = trimRepeated(string, replacement);
    string = windowsReservedNameRegex.test(string) ? string + replacement : string;
    const extIndex = string.lastIndexOf('.');
    const filename = string.slice(0, extIndex).trim();
    const extension = string.slice(extIndex);
    string = filename.slice(0, Math.max(1, allowedLength - extension.length)) + extension;
    return string;
}
