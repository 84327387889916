import { getInlineEditorByModel } from '@blocksuite/affine-components/rich-text';
import { getCurrentNativeRange, getViewportElement, matchFlavours } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { DisposableGroup, throttle } from '@blocksuite/global/utils';
import { InlineEditor } from '@blocksuite/inline';
import { getPopperPosition } from '../../../root-block/utils/position.js';
import { getMenus } from './config.js';
import { LinkedDocPopover } from './linked-doc-popover.js';
export const AFFINE_LINKED_DOC_WIDGET = 'affine-linked-doc-widget';
export class AffineLinkedDocWidget extends WidgetComponent {
    get config() {
        return {
            triggerKeys: [
                '@',
                '[[',
                '【【'
            ],
            ignoreBlockTypes: [
                'affine:code'
            ],
            convertTriggerKey: true,
            getMenus,
            ...this.std.getConfig('affine:page')?.linkedWidget
        };
    }
    _handleInput(inlineEditor, isCompositionEnd) {
        const primaryTriggerKey = this.config.triggerKeys[0];
        const inlineRangeApplyCallback = (callback)=>{
            if (isCompositionEnd) callback();
            else inlineEditor.slots.inlineRangeSync.once(callback);
        };
        inlineRangeApplyCallback(()=>{
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const textPoint = inlineEditor.getTextPoint(inlineRange.index);
            if (!textPoint) return;
            const [leafStart, offsetStart] = textPoint;
            const text = leafStart.textContent ? leafStart.textContent.slice(0, offsetStart) : '';
            const matchedKey = this.config.triggerKeys.find((triggerKey)=>text.endsWith(triggerKey));
            if (!matchedKey) return;
            if (this.config.convertTriggerKey && primaryTriggerKey !== matchedKey) {
                const inlineRange = inlineEditor.getInlineRange();
                if (!inlineRange) return;
                const startIdxBeforeMatchKey = inlineRange.index - matchedKey.length;
                inlineEditor.deleteText({
                    index: startIdxBeforeMatchKey,
                    length: matchedKey.length
                });
                inlineEditor.insertText({
                    index: startIdxBeforeMatchKey,
                    length: 0
                }, primaryTriggerKey);
                inlineEditor.setInlineRange({
                    index: startIdxBeforeMatchKey + primaryTriggerKey.length,
                    length: 0
                });
                inlineEditor.slots.inlineRangeSync.once(()=>{
                    this.showLinkedDocPopover(inlineEditor, primaryTriggerKey);
                });
                return;
            }
            this.showLinkedDocPopover(inlineEditor, matchedKey);
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.handleEvent('keyDown', this._onKeyDown);
        this.handleEvent('compositionEnd', this._onCompositionEnd);
    }
    constructor(...args){
        super(...args), this._abortController = null, this._getInlineEditor = (evt)=>{
            if (evt.target instanceof HTMLElement) {
                const editor = evt.target.closest('.can-link-doc > .inline-editor')?.inlineEditor;
                if (editor instanceof InlineEditor) {
                    return editor;
                }
            }
            const text = this.host.selection.value.find((selection)=>selection.is('text'));
            if (!text) return;
            const model = this.host.doc.getBlockById(text.blockId);
            if (!model) return;
            if (matchFlavours(model, this.config.ignoreBlockTypes)) {
                return;
            }
            return getInlineEditorByModel(this.host, model);
        }, this._onCompositionEnd = (ctx)=>{
            const event = ctx.get('defaultState').event;
            const key = event.data;
            if (!key || !this.config.triggerKeys.some((triggerKey)=>triggerKey.includes(key))) return;
            const inlineEditor = this._getInlineEditor(event);
            if (!inlineEditor) return;
            this._handleInput(inlineEditor, true);
        }, this._onKeyDown = (ctx)=>{
            const eventState = ctx.get('keyboardState');
            const event = eventState.raw;
            const key = event.key;
            if (key === undefined || key === 'Process' || event.isComposing) return;
            const inlineEditor = this._getInlineEditor(event);
            if (!inlineEditor) return;
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            if (inlineRange.length > 0) {
                return;
            }
            this._handleInput(inlineEditor, false);
        }, this.showLinkedDocPopover = (inlineEditor, triggerKey)=>{
            const curRange = getCurrentNativeRange();
            if (!curRange) return;
            this._abortController?.abort();
            this._abortController = new AbortController();
            const disposables = new DisposableGroup();
            this._abortController.signal.addEventListener('abort', ()=>disposables.dispose());
            const linkedDoc = new LinkedDocPopover(triggerKey, this.config.getMenus, this.host, inlineEditor, this._abortController);
            document.body.append(linkedDoc);
            disposables.add(()=>linkedDoc.remove());
            const updatePosition = throttle(()=>{
                const linkedDocElement = linkedDoc.linkedDocElement;
                if (!linkedDocElement) return;
                const position = getPopperPosition(linkedDocElement, curRange);
                linkedDoc.updatePosition(position);
            }, 10);
            disposables.addFromEvent(window, 'resize', updatePosition);
            const scrollContainer = getViewportElement(this.host);
            if (scrollContainer) {
                disposables.addFromEvent(scrollContainer, 'scroll', updatePosition, {
                    passive: true
                });
            }
            setTimeout(updatePosition);
            disposables.addFromEvent(window, 'mousedown', (e)=>{
                if (e.target === linkedDoc) return;
                this._abortController?.abort();
            });
            return linkedDoc;
        };
    }
}
