export class BroadcastChannelDocEventBus {
    constructor(workspaceId){
        this.workspaceId = workspaceId;
        this.senderChannel = new BroadcastChannel('doc:' + this.workspaceId);
    }
    emit(event) {
        this.senderChannel.postMessage(event);
    }
    on(cb) {
        const listener = (event)=>{
            cb(event.data);
        };
        const channel = new BroadcastChannel('doc:' + this.workspaceId);
        channel.addEventListener('message', listener);
        return ()=>{
            channel.removeEventListener('message', listener);
            channel.close();
        };
    }
}
