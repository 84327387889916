export class Vec {
    static{
        this.abs = (A)=>{
            return [
                Math.abs(A[0]),
                Math.abs(A[1])
            ];
        };
    }
    static{
        this.add = (A, B)=>{
            return [
                A[0] + B[0],
                A[1] + B[1]
            ];
        };
    }
    static{
        this.addScalar = (A, n)=>{
            return [
                A[0] + n,
                A[1] + n
            ];
        };
    }
    static{
        this.ang = (A, B)=>{
            return Math.atan2(Vec.cpr(A, B), Vec.dpr(A, B));
        };
    }
    static{
        this.ang3 = (p1, pc, p2)=>{
            const v1 = Vec.vec(pc, p1);
            const v2 = Vec.vec(pc, p2);
            return Vec.ang(v1, v2);
        };
    }
    static{
        this.angle = (A, B)=>{
            return Math.atan2(B[1] - A[1], B[0] - A[0]);
        };
    }
    static{
        this.clockwise = (p1, pc, p2)=>{
            return Vec.isLeft(p1, pc, p2) > 0;
        };
    }
    static{
        this.cpr = (A, B)=>{
            return A[0] * B[1] - B[0] * A[1];
        };
    }
    static{
        this.dist = (A, B)=>{
            return Math.hypot(A[1] - B[1], A[0] - B[0]);
        };
    }
    static{
        this.dist2 = (A, B)=>{
            return Vec.len2(Vec.sub(A, B));
        };
    }
    static{
        this.distanceToBounds = (bounds, P)=>{
            return Vec.dist(P, Vec.nearestPointOnBounds(bounds, P));
        };
    }
    static{
        this.distanceToLineSegment = (A, B, P, clamp = true)=>{
            return Vec.dist(P, Vec.nearestPointOnLineSegment(A, B, P, clamp));
        };
    }
    static{
        this.distanceToLineThroughPoint = (A, u, P)=>{
            return Vec.dist(P, Vec.nearestPointOnLineThroughPoint(A, u, P));
        };
    }
    static{
        this.div = (A, n)=>{
            return [
                A[0] / n,
                A[1] / n
            ];
        };
    }
    static{
        this.divV = (A, B)=>{
            return [
                A[0] / B[0],
                A[1] / B[1]
            ];
        };
    }
    static{
        this.dpr = (A, B)=>{
            return A[0] * B[0] + A[1] * B[1];
        };
    }
    static{
        this.fastDist = (A, B)=>{
            const V = [
                B[0] - A[0],
                B[1] - A[1]
            ];
            const aV = [
                Math.abs(V[0]),
                Math.abs(V[1])
            ];
            let r = 1 / Math.max(aV[0], aV[1]);
            r = r * (1.29289 - (aV[0] + aV[1]) * r * 0.29289);
            return [
                V[0] * r,
                V[1] * r
            ];
        };
    }
    static{
        this.int = (A, B, from, to, s = 1)=>{
            const t = (Vec.clamp(from, to) - from) / (to - from);
            return Vec.add(Vec.mul(A, 1 - t), Vec.mul(B, s));
        };
    }
    static{
        this.isEqual = (A, B)=>{
            return A[0] === B[0] && A[1] === B[1];
        };
    }
    static{
        this.isLeft = (p1, pc, p2)=>{
            return (pc[0] - p1[0]) * (p2[1] - p1[1]) - (p2[0] - p1[0]) * (pc[1] - p1[1]);
        };
    }
    static{
        this.len = (A)=>{
            return Math.hypot(A[0], A[1]);
        };
    }
    static{
        this.len2 = (A)=>{
            return A[0] * A[0] + A[1] * A[1];
        };
    }
    static{
        this.lrp = (A, B, t)=>{
            return Vec.add(A, Vec.mul(Vec.sub(B, A), t));
        };
    }
    static{
        this.max = (...v)=>{
            return [
                Math.max(...v.map((a)=>a[0])),
                Math.max(...v.map((a)=>a[1]))
            ];
        };
    }
    static{
        this.med = (A, B)=>{
            return Vec.mul(Vec.add(A, B), 0.5);
        };
    }
    static{
        this.min = (...v)=>{
            return [
                Math.min(...v.map((a)=>a[0])),
                Math.min(...v.map((a)=>a[1]))
            ];
        };
    }
    static{
        this.mul = (A, n)=>{
            return [
                A[0] * n,
                A[1] * n
            ];
        };
    }
    static{
        this.mulV = (A, B)=>{
            return [
                A[0] * B[0],
                A[1] * B[1]
            ];
        };
    }
    static{
        this.nearestPointOnBounds = (bounds, P)=>{
            return [
                Vec.clamp(P[0], bounds.minX, bounds.maxX),
                Vec.clamp(P[1], bounds.minY, bounds.maxY)
            ];
        };
    }
    static{
        this.nearestPointOnLineSegment = (A, B, P, clamp = true)=>{
            const u = Vec.uni(Vec.sub(B, A));
            const C = Vec.add(A, Vec.mul(u, Vec.pry(Vec.sub(P, A), u)));
            if (clamp) {
                if (C[0] < Math.min(A[0], B[0])) return A[0] < B[0] ? A : B;
                if (C[0] > Math.max(A[0], B[0])) return A[0] > B[0] ? A : B;
                if (C[1] < Math.min(A[1], B[1])) return A[1] < B[1] ? A : B;
                if (C[1] > Math.max(A[1], B[1])) return A[1] > B[1] ? A : B;
            }
            return C;
        };
    }
    static{
        this.nearestPointOnLineThroughPoint = (A, u, P)=>{
            return Vec.add(A, Vec.mul(u, Vec.pry(Vec.sub(P, A), u)));
        };
    }
    static{
        this.neg = (A)=>{
            return [
                -A[0],
                -A[1]
            ];
        };
    }
    static{
        this.normalize = (A)=>{
            return Vec.uni(A);
        };
    }
    static{
        this.nudge = (A, B, d)=>{
            if (Vec.isEqual(A, B)) return A;
            return Vec.add(A, Vec.mul(Vec.uni(Vec.sub(B, A)), d));
        };
    }
    static{
        this.nudgeAtAngle = (A, a, d)=>{
            return [
                Math.cos(a) * d + A[0],
                Math.sin(a) * d + A[1]
            ];
        };
    }
    static{
        this.per = (A)=>{
            return [
                A[1],
                -A[0]
            ];
        };
    }
    static{
        this.pointOffset = (A, B, offset)=>{
            let u = Vec.uni(Vec.sub(B, A));
            if (Vec.isEqual(A, B)) u = A;
            return Vec.add(A, Vec.mul(u, offset));
        };
    }
    static{
        this.pointsBetween = (A, B, steps = 6)=>{
            return Array.from({
                length: steps
            }).map((_, i)=>{
                const t = i / (steps - 1);
                const k = Math.min(1, 0.5 + Math.abs(0.5 - t));
                return [
                    ...Vec.lrp(A, B, t),
                    k
                ];
            });
        };
    }
    static{
        this.pry = (A, B)=>{
            return Vec.dpr(A, B) / Vec.len(B);
        };
    }
    static{
        this.rescale = (a, n)=>{
            const l = Vec.len(a);
            return [
                n * a[0] / l,
                n * a[1] / l
            ];
        };
    }
    static{
        this.rot = (A, r = 0)=>{
            return [
                A[0] * Math.cos(r) - A[1] * Math.sin(r),
                A[0] * Math.sin(r) + A[1] * Math.cos(r)
            ];
        };
    }
    static{
        this.rotWith = (A, C, r = 0)=>{
            if (r === 0) return A;
            const s = Math.sin(r);
            const c = Math.cos(r);
            const px = A[0] - C[0];
            const py = A[1] - C[1];
            const nx = px * c - py * s;
            const ny = px * s + py * c;
            return [
                nx + C[0],
                ny + C[1]
            ];
        };
    }
    static{
        this.slope = (A, B)=>{
            if (A[0] === B[0]) return NaN;
            return (A[1] - B[1]) / (A[0] - B[0]);
        };
    }
    static{
        this.sub = (A, B)=>{
            return [
                A[0] - B[0],
                A[1] - B[1]
            ];
        };
    }
    static{
        this.subScalar = (A, n)=>{
            return [
                A[0] - n,
                A[1] - n
            ];
        };
    }
    static{
        this.tangent = (A, B)=>{
            return Vec.uni(Vec.sub(A, B));
        };
    }
    static{
        this.toFixed = (a)=>{
            return a.map((v)=>Math.round(v * 100) / 100);
        };
    }
    static{
        this.toPoint = (v)=>{
            return {
                x: v[0],
                y: v[1]
            };
        };
    }
    static{
        this.toPrecision = (a, n = 4)=>{
            return [
                +a[0].toPrecision(n),
                +a[1].toPrecision(n)
            ];
        };
    }
    static{
        this.toVec = (v)=>[
                v.x,
                v.y
            ];
    }
    static{
        this.uni = (A)=>{
            return Vec.div(A, Vec.len(A));
        };
    }
    static{
        this.vec = (A, B)=>{
            return [
                B[0] - A[0],
                B[1] - A[1]
            ];
        };
    }
    static clamp(n, min, max) {
        return Math.max(min, max !== undefined ? Math.min(n, max) : n);
    }
    static clampV(A, min, max) {
        return A.map((n)=>max !== undefined ? Vec.clamp(n, min, max) : Vec.clamp(n, min));
    }
    static cross(x, y, z) {
        return (y[0] - x[0]) * (z[1] - x[1]) - (z[0] - x[0]) * (y[1] - x[1]);
    }
    static snap(a, step = 1) {
        return [
            Math.round(a[0] / step) * step,
            Math.round(a[1] / step) * step
        ];
    }
}
