import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { attachInstruction, extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { useEffect, useMemo, useRef, useState } from 'react';
function dropTargetGet(get, options) {
    if (get === undefined) {
        return undefined;
    }
    return (args)=>{
        if (typeof get === 'function') {
            return get({
                ...args,
                get treeInstruction () {
                    return options.treeInstruction ? extractInstruction(attachInstruction({}, {
                        input: args.input,
                        element: args.element,
                        currentLevel: options.treeInstruction.currentLevel,
                        indentPerLevel: options.treeInstruction.indentPerLevel,
                        mode: options.treeInstruction.mode,
                        block: options.treeInstruction.block
                    })) : null;
                },
                get closestEdge () {
                    return options.closestEdge ? extractClosestEdge(attachClosestEdge({}, {
                        input: args.input,
                        element: args.element,
                        allowedEdges: options.closestEdge.allowedEdges
                    })) : null;
                }
            });
        } else {
            return get;
        }
    };
}
export const useDropTarget = (getOptions = ()=>({}), deps = [])=>{
    const dropTargetRef = useRef(null);
    const [draggedOver, setDraggedOver] = useState(false);
    const [treeInstruction, setTreeInstruction] = useState(null);
    const [closestEdge, setClosestEdge] = useState(null);
    const [dropEffect, setDropEffect] = useState(null);
    const [draggedOverDraggable, setDraggedOverDraggable] = useState(null);
    const [draggedOverPosition, setDraggedOverPosition] = useState({
        relativeX: 0,
        relativeY: 0,
        clientX: 0,
        clientY: 0
    });
    const enableDraggedOver = useRef(false);
    const enableDraggedOverDraggable = useRef(false);
    const enableDraggedOverPosition = useRef(false);
    const enableDropEffect = useRef(false);
    const options = useMemo(getOptions, deps);
    useEffect(()=>{
        if (!dropTargetRef.current) {
            return;
        }
        return dropTargetForElements({
            element: dropTargetRef.current,
            canDrop: dropTargetGet(options.canDrop, options),
            getDropEffect: dropTargetGet(options.dropEffect, options),
            getIsSticky: dropTargetGet(options.isSticky, options),
            onDrop: (args)=>{
                if (enableDraggedOver.current) {
                    setDraggedOver(false);
                }
                if (enableDraggedOverDraggable.current) {
                    setDraggedOverDraggable(null);
                }
                if (enableDraggedOverPosition.current) {
                    setDraggedOverPosition({
                        relativeX: 0,
                        relativeY: 0,
                        clientX: 0,
                        clientY: 0
                    });
                }
                if (options.treeInstruction) {
                    setTreeInstruction(null);
                    if (dropTargetRef.current) {
                        delete dropTargetRef.current.dataset['treeInstruction'];
                    }
                }
                if (options.closestEdge) {
                    setClosestEdge(null);
                }
                if (enableDropEffect.current) {
                    setDropEffect(null);
                }
                if (dropTargetRef.current) {
                    delete dropTargetRef.current.dataset['draggedOver'];
                }
                if (args.location.current.dropTargets[0]?.element === dropTargetRef.current) {
                    options.onDrop?.({
                        ...args,
                        treeInstruction: extractInstruction(args.self.data),
                        closestEdge: extractClosestEdge(args.self.data)
                    });
                }
            },
            getData: (args)=>{
                const originData = dropTargetGet(options.data ?? {}, options)(args);
                const { input, element } = args;
                const withInstruction = options.treeInstruction ? attachInstruction(originData, {
                    input,
                    element,
                    currentLevel: options.treeInstruction.currentLevel,
                    indentPerLevel: options.treeInstruction.indentPerLevel,
                    mode: options.treeInstruction.mode,
                    block: options.treeInstruction.block
                }) : originData;
                const withClosestEdge = options.closestEdge ? attachClosestEdge(withInstruction, {
                    element,
                    input,
                    allowedEdges: options.closestEdge.allowedEdges
                }) : withInstruction;
                return withClosestEdge;
            },
            onDrag: (args)=>{
                if (args.location.current.dropTargets[0]?.element === dropTargetRef.current) {
                    if (enableDraggedOverDraggable.current) {
                        setDraggedOverDraggable({
                            data: args.source.data
                        });
                    }
                    let instruction = null;
                    let closestEdge = null;
                    if (options.treeInstruction) {
                        instruction = extractInstruction(args.self.data);
                        setTreeInstruction(instruction);
                        if (dropTargetRef.current) {
                            dropTargetRef.current.dataset['treeInstruction'] = instruction?.type;
                        }
                    }
                    if (options.closestEdge) {
                        closestEdge = extractClosestEdge(args.self.data);
                        setClosestEdge(closestEdge);
                    }
                    if (enableDropEffect.current) {
                        setDropEffect(args.self.dropEffect);
                    }
                    if (enableDraggedOverPosition.current) {
                        const rect = args.self.element.getBoundingClientRect();
                        const { clientX, clientY } = args.location.current.input;
                        setDraggedOverPosition({
                            relativeX: clientX - rect.x,
                            relativeY: clientY - rect.y,
                            clientX: clientX,
                            clientY: clientY
                        });
                    }
                    options.onDrag?.({
                        ...args,
                        treeInstruction: instruction,
                        closestEdge
                    });
                }
            },
            onDropTargetChange: (args)=>{
                if (args.location.current.dropTargets[0]?.element === dropTargetRef.current) {
                    if (enableDraggedOver.current) {
                        setDraggedOver(true);
                    }
                    if (options.treeInstruction) {
                        const instruction = extractInstruction(args.self.data);
                        setTreeInstruction(instruction);
                        if (dropTargetRef.current) {
                            dropTargetRef.current.dataset['treeInstruction'] = instruction?.type;
                        }
                    }
                    if (options.closestEdge) {
                        const closestEdge = extractClosestEdge(args.self.data);
                        setClosestEdge(closestEdge);
                    }
                    if (enableDropEffect.current) {
                        setDropEffect(args.self.dropEffect);
                    }
                    if (enableDraggedOverDraggable.current) {
                        setDraggedOverDraggable({
                            data: args.source.data
                        });
                    }
                    if (enableDraggedOverPosition.current) {
                        const rect = args.self.element.getBoundingClientRect();
                        setDraggedOverPosition({
                            relativeX: args.location.current.input.clientX - rect.x,
                            relativeY: args.location.current.input.clientY - rect.y,
                            clientX: args.location.current.input.clientX,
                            clientY: args.location.current.input.clientY
                        });
                    }
                    if (dropTargetRef.current) {
                        dropTargetRef.current.dataset['draggedOver'] = 'true';
                    }
                } else {
                    if (enableDraggedOver.current) {
                        setDraggedOver(false);
                    }
                    if (enableDraggedOverDraggable.current) {
                        setDraggedOverDraggable(null);
                    }
                    if (options.treeInstruction) {
                        setTreeInstruction(null);
                        if (dropTargetRef.current) {
                            delete dropTargetRef.current.dataset['treeInstruction'];
                        }
                    }
                    if (enableDropEffect.current) {
                        setDropEffect(args.self.dropEffect);
                    }
                    if (enableDraggedOverPosition.current) {
                        setDraggedOverPosition({
                            relativeX: 0,
                            relativeY: 0,
                            clientX: 0,
                            clientY: 0
                        });
                    }
                    if (options.closestEdge) {
                        setClosestEdge(null);
                    }
                    if (dropTargetRef.current) {
                        delete dropTargetRef.current.dataset['draggedOver'];
                    }
                }
            }
        });
    }, [
        options
    ]);
    return {
        dropTargetRef,
        get draggedOver () {
            enableDraggedOver.current = true;
            return draggedOver;
        },
        get draggedOverDraggable () {
            enableDraggedOverDraggable.current = true;
            return draggedOverDraggable;
        },
        get draggedOverPosition () {
            enableDraggedOverPosition.current = true;
            return draggedOverPosition;
        },
        get dropEffect () {
            enableDropEffect.current = true;
            return dropEffect;
        },
        treeInstruction,
        closestEdge
    };
};
