import { AffineSchemas } from '@blocksuite/affine/blocks';
import { DocCollection, Job, Schema } from '@blocksuite/affine/store';
const getCollection = (()=>{
    let collection = null;
    return async function() {
        if (collection) {
            return collection;
        }
        const schema = new Schema();
        schema.register(AffineSchemas);
        collection = new DocCollection({
            schema
        });
        collection.meta.initialize();
        return collection;
    };
})();
const docMap = new Map();
async function loadNote() {
    return (await import('./note.json')).default;
}
async function loadPen() {
    return (await import('./pen.json')).default;
}
async function loadShape() {
    return (await import('./shape.json')).default;
}
async function loadFlow() {
    return (await import('./flow.json')).default;
}
async function loadText() {
    return (await import('./text.json')).default;
}
async function loadConnector() {
    return (await import('./connector.json')).default;
}
async function loadMindmap() {
    return (await import('./mindmap.json')).default;
}
const loaders = {
    note: loadNote,
    pen: loadPen,
    shape: loadShape,
    flow: loadFlow,
    text: loadText,
    connector: loadConnector,
    mindmap: loadMindmap
};
export async function getDocByName(name) {
    if (docMap.get(name)) {
        return docMap.get(name);
    }
    const promise = initDoc(name);
    docMap.set(name, promise);
    return promise;
}
async function initDoc(name) {
    const snapshot = await loaders[name]();
    const collection = await getCollection();
    const job = new Job({
        collection,
        middlewares: []
    });
    return await job.snapshotToDoc(snapshot);
}
