import { atom, useAtomValue } from 'jotai';
const weakMap = new WeakMap();
export function useAllBlockSuiteDocMeta(docCollection) {
    if (!weakMap.has(docCollection)) {
        const baseAtom = atom([
            ...docCollection.meta.docMetas
        ]);
        weakMap.set(docCollection, baseAtom);
        baseAtom.onMount = (set)=>{
            set([
                ...docCollection.meta.docMetas
            ]);
            const dispose = docCollection.meta.docMetaUpdated.on(()=>{
                set([
                    ...docCollection.meta.docMetas
                ]);
            });
            return ()=>{
                dispose.dispose();
            };
        };
    }
    return useAtomValue(weakMap.get(docCollection));
}
