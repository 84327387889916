import { DocEngine, Entity } from '@toeverything/infra';
import { UserDBDocServer } from '../impls/user-db-doc-server';
export class UserDBEngine extends Entity {
    canGracefulStop() {
        return true;
    }
    constructor(userspaceStorageProvider, websocketService){
        super(), this.userspaceStorageProvider = userspaceStorageProvider, this.websocketService = websocketService, this.userId = this.props.userId, this.docEngine = new DocEngine(this.userspaceStorageProvider.getDocStorage('affine-cloud:' + this.userId), new UserDBDocServer(this.userId, this.websocketService));
        this.docEngine.start();
    }
    dispose() {
        this.docEngine.stop();
    }
}
