import { nanoid } from 'nanoid';
import { f, t } from '../../../orm';
export const AFFiNE_WORKSPACE_DB_SCHEMA = {
    folders: {
        id: f.string().primaryKey().optional().default(nanoid),
        parentId: f.string().optional(),
        data: f.string(),
        type: f.string(),
        index: f.string()
    },
    feedNodes: {
        id: f.string().primaryKey().optional().default(nanoid),
        parentId: f.string().optional(),
        type: f.string(),
        name: f.string(),
        description: f.string().optional(),
        source: f.string().optional(),
        icon: f.string().optional(),
        index: f.string(),
        createdAt: f.number(),
        updatedAt: f.number().optional(),
        unreadCount: f.number().optional()
    },
    docProperties: t.document({
        id: f.string().primaryKey(),
        primaryMode: f.string().optional(),
        edgelessColorTheme: f.string().optional(),
        journal: f.string().optional()
    }),
    docCustomPropertyInfo: {
        id: f.string().primaryKey().optional().default(nanoid),
        name: f.string().optional(),
        type: f.string(),
        show: f.string().optional(),
        index: f.string().optional(),
        additionalData: f.json().optional(),
        isDeleted: f.boolean().optional()
    }
};
export const AFFiNE_WORKSPACE_USERDATA_DB_SCHEMA = {
    favorite: {
        key: f.string().primaryKey(),
        index: f.string()
    }
};
