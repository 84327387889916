export class AsyncLock {
    async acquire() {
        let release = null;
        const nextLock = new Promise((resolve)=>{
            release = resolve;
        });
        await this._lock;
        this._lock = nextLock;
        return {
            release,
            [Symbol.dispose]: ()=>{
                release();
            }
        };
    }
    constructor(){
        this._lock = Promise.resolve();
    }
}
