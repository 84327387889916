import { DebugLogger } from '@affine/debug';
import { stableHash } from '../../utils';
import { SUB_COMPONENTS } from './consts';
import { createIdentifier } from './identifier';
export function createEvent(id) {
    return {
        id,
        _type: {}
    };
}
const logger = new DebugLogger('affine:event-bus');
export class EventBus {
    constructor(provider, parent){
        this.parent = parent;
        this.listeners = {};
        const handlers = provider.getAll(EventHandler, {
            sameScope: true
        });
        for (const handler of handlers.values()){
            this.on(handler.event.id, handler.handler);
        }
    }
    get root() {
        return this.parent?.root ?? this;
    }
    on(id, listener) {
        if (!this.listeners[id]) {
            this.listeners[id] = [];
        }
        this.listeners[id].push(listener);
        const off = this.parent?.on(id, listener);
        return ()=>{
            this.off(id, listener);
            off?.();
        };
    }
    off(id, listener) {
        if (!this.listeners[id]) {
            return;
        }
        this.listeners[id] = this.listeners[id].filter((l)=>l !== listener);
    }
    emit(event, payload) {
        logger.debug('Emitting event', event.id, payload);
        const listeners = this.listeners[event.id];
        if (!listeners) {
            return;
        }
        listeners.forEach((listener)=>{
            try {
                listener(payload);
            } catch (e) {
                console.error(e);
            }
        });
    }
}
export const EventHandler = createIdentifier('EventHandler');
export const OnEvent = (e, pick)=>{
    return (target)=>{
        const handlers = target[SUB_COMPONENTS] ?? [];
        target[SUB_COMPONENTS] = [
            ...handlers,
            {
                identifier: EventHandler(target.name + stableHash(e) + stableHash(pick)),
                factory: (provider)=>{
                    return {
                        event: e,
                        handler: (payload)=>{
                            const i = provider.get(target);
                            pick(i).apply(i, [
                                payload
                            ]);
                        }
                    };
                }
            }
        ];
        return target;
    };
};
