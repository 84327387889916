import { useServiceOptional } from '@toeverything/infra';
import { useEffect } from 'react';
import { ViewService } from '../services/view';
export const ViewTitle = ({ title })=>{
    const view = useServiceOptional(ViewService)?.view;
    useEffect(()=>{
        if (view) {
            view.setTitle(title);
        }
    }, [
        title,
        view
    ]);
    return null;
};
export const ViewIcon = ({ icon })=>{
    const view = useServiceOptional(ViewService)?.view;
    useEffect(()=>{
        if (view) {
            view.setIcon(icon);
        }
    }, [
        icon,
        view
    ]);
    return null;
};
