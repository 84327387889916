import { useCallback, useState } from 'react';
import { filterPageByRules } from '../../use-collection-manager';
export const useFilter = (list)=>{
    const [filters, changeFilters] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const clickFilter = useCallback((e)=>{
        if (showFilter || filters.length !== 0) {
            e.stopPropagation();
            e.preventDefault();
            setShowFilter(!showFilter);
        }
    }, [
        filters.length,
        showFilter
    ]);
    const onCreateFilter = useCallback((filter)=>{
        changeFilters([
            ...filters,
            filter
        ]);
        setShowFilter(true);
    }, [
        filters
    ]);
    return {
        showFilter,
        filters,
        updateFilters: changeFilters,
        clickFilter,
        createFilter: onCreateFilter,
        filteredList: list.filter((pageData)=>{
            if (pageData.meta.trash) {
                return false;
            }
            return filterPageByRules(filters, [], pageData);
        }).map((pageData)=>pageData.meta)
    };
};
