import { assertExists } from '@blocksuite/global/utils';
import { Text } from '@blocksuite/store';
export function mergeToCodeModel(models) {
    if (models.length === 0) {
        return null;
    }
    const doc = models[0].doc;
    const parent = doc.getParent(models[0]);
    if (!parent) {
        return null;
    }
    const index = parent.children.indexOf(models[0]);
    const text = models.map((model)=>{
        if (model.text instanceof Text) {
            return model.text.toString();
        }
        return null;
    }).filter(Boolean).join('\n');
    models.map((model)=>doc.deleteBlock(model));
    const id = doc.addBlock('affine:code', {
        text: new Text(text)
    }, parent, index);
    return id;
}
export function transformModel(model, flavour, props) {
    const doc = model.doc;
    const parent = doc.getParent(model);
    assertExists(parent);
    const blockProps = {
        text: model?.text?.clone(),
        children: model.children,
        ...props
    };
    const index = parent.children.indexOf(model);
    const id = doc.addBlock(flavour, blockProps, parent, index);
    doc.deleteBlock(model, {
        deleteChildren: false
    });
    return id;
}
