import { z } from 'zod';
export var FrameBackgroundColor;
(function(FrameBackgroundColor) {
    FrameBackgroundColor["Blue"] = "--affine-tag-blue";
    FrameBackgroundColor["Gray"] = "--affine-tag-gray";
    FrameBackgroundColor["Green"] = "--affine-tag-green";
    FrameBackgroundColor["Orange"] = "--affine-tag-orange";
    FrameBackgroundColor["Pink"] = "--affine-tag-pink";
    FrameBackgroundColor["Purple"] = "--affine-tag-purple";
    FrameBackgroundColor["Red"] = "--affine-tag-red";
    FrameBackgroundColor["Teal"] = "--affine-tag-teal";
    FrameBackgroundColor["Yellow"] = "--affine-tag-yellow";
})(FrameBackgroundColor || (FrameBackgroundColor = {}));
export const FRAME_BACKGROUND_COLORS = [
    "--affine-tag-gray",
    "--affine-tag-red",
    "--affine-tag-orange",
    "--affine-tag-yellow",
    "--affine-tag-green",
    "--affine-tag-teal",
    "--affine-tag-blue",
    "--affine-tag-purple",
    "--affine-tag-pink"
];
export const FrameBackgroundColorsSchema = z.nativeEnum(FrameBackgroundColor);
