function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init_host, _init_updateContext, _init_temporaryParams, _init_isEmpty, _init_cards, _initProto;
import { isInsideEdgelessEditor, NoteDisplayMode } from '@blocksuite/affine/blocks';
import { WithDisposable } from '@blocksuite/affine/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { CurrentSelectionIcon, DocIcon, SmallImageIcon } from '../_common/icons';
import { AIProvider } from '../provider';
import { getSelectedImagesAsBlobs, getSelectedTextContent, getTextContentFromBlockModels, selectedToCanvas } from '../utils/selection-utils';
const cardsStyles = css`
  .card-wrapper {
    width: 90%;
    max-height: 76px;
    border-radius: 8px;
    border: 1px solid var(--affine-border-color);
    padding: 4px 12px;
    cursor: pointer;

    .card-title {
      display: flex;
      gap: 4px;
      height: 22px;
      margin-bottom: 2px;
      font-weight: 500;
      font-size: 14px;
      color: var(--affine-text-primary-color);
    }

    .second-text {
      font-size: 14px;
      font-weight: 400;
      color: var(--affine-text-secondary-color);
    }
  }
`;
var CardType;
(function(CardType) {
    CardType[CardType["Text"] = 0] = "Text";
    CardType[CardType["Image"] = 1] = "Image";
    CardType[CardType["Block"] = 2] = "Block";
    CardType[CardType["Doc"] = 3] = "Doc";
})(CardType || (CardType = {}));
const MAX_CARDS = 3;
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = state();
export class ChatCards extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_host, _init_updateContext, _init_temporaryParams, _init_isEmpty, _init_cards, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "host"
            ],
            [
                _dec1,
                1,
                "updateContext"
            ],
            [
                _dec2,
                1,
                "temporaryParams"
            ],
            [
                _dec3,
                1,
                "isEmpty"
            ],
            [
                _dec4,
                1,
                "cards"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: none;
      flex-direction: column;
      gap: 12px;
    }

    :host([data-show]) {
      display: flex;
    }

    ${cardsStyles}
  `;
    }
    #___private_host_1;
    get host() {
        return this.#___private_host_1;
    }
    set host(_v) {
        this.#___private_host_1 = _v;
    }
    #___private_updateContext_2;
    get updateContext() {
        return this.#___private_updateContext_2;
    }
    set updateContext(_v) {
        this.#___private_updateContext_2 = _v;
    }
    #___private_temporaryParams_3;
    get temporaryParams() {
        return this.#___private_temporaryParams_3;
    }
    set temporaryParams(_v) {
        this.#___private_temporaryParams_3 = _v;
    }
    #___private_isEmpty_4;
    get isEmpty() {
        return this.#___private_isEmpty_4;
    }
    set isEmpty(_v) {
        this.#___private_isEmpty_4 = _v;
    }
    #___private_cards_5;
    get cards() {
        return this.#___private_cards_5;
    }
    set cards(_v) {
        this.#___private_cards_5 = _v;
    }
    static renderText({ text }) {
        const lines = text.split('\n');
        return html`
      <div class="card-wrapper">
        <div class="card-title">
          ${CurrentSelectionIcon}
          <div>Start with current selection</div>
        </div>
        <div class="second-text">
          ${repeat(lines.slice(0, 2), (line)=>line, (line)=>html`
              <div
                style=${styleMap({
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            })}
              >
                ${line}
              </div>
            `)}
        </div>
      </div>
    `;
    }
    static renderImage({ caption, image }) {
        return html`
      <div
        class="card-wrapper"
        style=${styleMap({
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between'
        })}
      >
        <div
          style=${styleMap({
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 'calc(100% - 72px)'
        })}
        >
          <div class="card-title">
            ${SmallImageIcon}
            <div>Start with this Image</div>
          </div>
          <div class="second-text">${caption ? caption : 'caption'}</div>
        </div>
        <img
          style=${styleMap({
            maxWidth: '72px',
            maxHeight: '46px'
        })}
          src="${URL.createObjectURL(image)}"
        />
      </div>
    `;
    }
    static renderDoc({ text, images }) {
        let textTpl = html`you've chosen within the doc`;
        let imageTpl = nothing;
        let hasImage = false;
        if (text?.length) {
            const lines = text.split('\n');
            textTpl = html`${repeat(lines.slice(0, 2), (line)=>line, (line)=>html`
          <div
            style=${styleMap({
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                })}
          >
            ${line}
          </div>
        `)}`;
        }
        if (images?.length) {
            hasImage = true;
            imageTpl = html`
        <img
          style=${styleMap({
                maxWidth: '72px',
                maxHeight: '46px'
            })}
          src="${URL.createObjectURL(images[0])}"
        />
      `;
        }
        return html`
      <div
        class="card-wrapper"
        style=${styleMap({
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between'
        })}
      >
        <div
          style=${styleMap({
            display: 'flex',
            flexDirection: 'column',
            maxWidth: hasImage ? 'calc(100% - 72px)' : '100%'
        })}
        >
          <div class="card-title">
            ${DocIcon}
            <div>Start with this doc</div>
          </div>
          <div class="second-text">${textTpl}</div>
        </div>
        ${imageTpl}
      </div>
    `;
    }
    _renderCard(card) {
        if (card.type === 0) {
            return ChatCards.renderText(card);
        }
        if (card.type === 1) {
            return ChatCards.renderImage(card);
        }
        if (card.type === 3) {
            return ChatCards.renderDoc(card);
        }
        return nothing;
    }
    _updateCards(card) {
        this.cards.unshift(card);
        if (this.cards.length > MAX_CARDS) {
            this.cards.pop();
        }
        this.requestUpdate();
    }
    async _handleDocSelection(card) {
        const { text, markdown, images } = await this._extractAll();
        card.text = text;
        card.markdown = markdown;
        card.images = images;
    }
    async _selectCard(card) {
        AIProvider.slots.toggleChatCards.emit({
            visible: false
        });
        this._selectedCardId = card.id;
        switch(card.type){
            case 0:
                {
                    this.updateContext({
                        quote: card.text,
                        markdown: card.markdown
                    });
                    break;
                }
            case 1:
                {
                    this.updateContext({
                        images: [
                            card.image
                        ]
                    });
                    break;
                }
            case 2:
                {
                    this.updateContext({
                        quote: card.text,
                        markdown: card.markdown,
                        images: card.images
                    });
                    break;
                }
            case 3:
                {
                    await this._handleDocSelection(card);
                    this.updateContext({
                        quote: card.text,
                        markdown: card.markdown,
                        images: card.images
                    });
                    break;
                }
        }
    }
    async _extract() {
        const text = await getSelectedTextContent(this.host, 'plain-text');
        const images = await getSelectedImagesAsBlobs(this.host);
        const hasText = text.length > 0;
        const hasImages = images.length > 0;
        if (hasText && !hasImages) {
            const markdown = await getSelectedTextContent(this.host, 'markdown');
            this._updateCards({
                id: Date.now(),
                type: 0,
                text,
                markdown
            });
        } else if (!hasText && hasImages && images.length === 1) {
            const [_, data] = this.host.command.chain().tryAll((chain)=>[
                    chain.getImageSelections()
                ]).getSelectedBlocks({
                types: [
                    'image'
                ]
            }).run();
            let caption = '';
            if (data.currentImageSelections?.[0]) {
                caption = (this.host.doc.getBlock(data.currentImageSelections[0].blockId)?.model).caption ?? '';
            }
            this._updateCards({
                id: Date.now(),
                type: 1,
                image: images[0],
                caption
            });
        } else {
            const markdown = (await getSelectedTextContent(this.host, 'markdown')).trim() || '';
            this._updateCards({
                id: Date.now(),
                type: 2,
                text,
                markdown,
                images
            });
        }
    }
    async _extractOnEdgeless(host) {
        if (!isInsideEdgelessEditor(host)) return;
        const canvas = await selectedToCanvas(host);
        if (!canvas) return;
        const blob = await new Promise((resolve)=>canvas.toBlob(resolve));
        if (!blob) return;
        this._updateCards({
            id: Date.now(),
            type: 1,
            image: new File([
                blob
            ], 'selected.png')
        });
    }
    async _extractAll() {
        const notes = this.host.doc.getBlocksByFlavour('affine:note').filter((note)=>note.model.displayMode !== NoteDisplayMode.EdgelessOnly).map((note)=>note.model);
        const selectedModels = notes.reduce((acc, note)=>{
            acc.push(...note.children);
            return acc;
        }, []);
        const text = await getTextContentFromBlockModels(this.host, selectedModels, 'plain-text');
        const markdown = await getTextContentFromBlockModels(this.host, selectedModels, 'markdown');
        const blobs = await Promise.all(selectedModels.map(async (s)=>{
            if (s.flavour !== 'affine:image') return null;
            const sourceId = s?.sourceId;
            if (!sourceId) return null;
            const blob = await (sourceId ? this.host.doc.blobSync.get(sourceId) : null);
            if (!blob) return null;
            return new File([
                blob
            ], sourceId);
        }) ?? []);
        const images = blobs.filter((blob)=>!!blob);
        return {
            text,
            markdown,
            images
        };
    }
    async _appendCardWithParams({ host, mode, autoSelect }) {
        if (mode === 'edgeless') {
            await this._extractOnEdgeless(host);
        } else {
            await this._extract();
        }
        if (!autoSelect) {
            return;
        }
        if (this.cards.length > 0) {
            const card = this.cards[0];
            if (card.type === 3) return;
            await this._selectCard(card);
        }
    }
    async willUpdate(changedProperties) {
        if (changedProperties.has('temporaryParams') && this.temporaryParams) {
            const params = this.temporaryParams;
            await this._appendCardWithParams(params);
            this.temporaryParams = null;
        }
        if (changedProperties.has('host')) {
            if (this._currentDocId === this.host.doc.id) return;
            this._currentDocId = this.host.doc.id;
            this.cards = [];
            const { text, images } = await this._extractAll();
            const hasText = text.length > 0;
            const hasImages = images.length > 0;
            if (hasText || hasImages) {
                const card = {
                    id: Date.now(),
                    type: 3
                };
                if (hasText) {
                    card.text = text;
                }
                if (hasImages) {
                    card.images = images;
                }
                this.cards.push(card);
                this.requestUpdate();
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this._disposables.add(AIProvider.slots.requestOpenWithChat.on(async (params)=>{
            await this._appendCardWithParams(params);
        }));
        this._disposables.add(AIProvider.slots.toggleChatCards.on(({ visible, ok })=>{
            if (visible && ok && this._selectedCardId > 0) {
                this.cards = this.cards.filter((card)=>card.id !== this._selectedCardId);
                this._selectedCardId = 0;
            }
        }));
    }
    render() {
        if (!this.isEmpty) return nothing;
        return repeat(this.cards, (card)=>card.id, (card)=>html`
        <div @click=${()=>this._selectCard(card)}>
          ${this._renderCard(card)}
        </div>
      `);
    }
    constructor(...args){
        super(...args), this.#___private_host_1 = (_initProto(this), _init_host(this)), this.#___private_updateContext_2 = _init_updateContext(this), this.#___private_temporaryParams_3 = _init_temporaryParams(this, null), this.#___private_isEmpty_4 = _init_isEmpty(this), this.#___private_cards_5 = _init_cards(this, []), this._currentDocId = null, this._selectedCardId = 0;
    }
}
