function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init__displayType, _init__dragging, _init__selectedBlocks, _init_configItems, _init_formatBarElement, _initProto;
import { HoverController } from '@blocksuite/affine-components/hover';
import { isFormatSupported } from '@blocksuite/affine-components/rich-text';
import { cloneGroups } from '@blocksuite/affine-components/toolbar';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { assertExists, DisposableGroup } from '@blocksuite/global/utils';
import { autoUpdate, computePosition, inline, offset, shift } from '@floating-ui/dom';
import { html, nothing } from 'lit';
import { query, state } from 'lit/decorators.js';
import { getMoreMenuConfig } from '../../configs/toolbar.js';
import { ConfigRenderer } from './components/config-renderer.js';
import { BUILT_IN_GROUPS, toolbarDefaultConfig, toolbarMoreButton } from './config.js';
import { formatBarStyle } from './styles.js';
export const AFFINE_FORMAT_BAR_WIDGET = 'affine-format-bar-widget';
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = query(`.${AFFINE_FORMAT_BAR_WIDGET}`);
export class AffineFormatBarWidget extends WidgetComponent {
    static{
        ({ e: [_init__displayType, _init__dragging, _init__selectedBlocks, _init_configItems, _init_formatBarElement, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_displayType"
            ],
            [
                _dec1,
                1,
                "_dragging"
            ],
            [
                _dec2,
                1,
                "_selectedBlocks"
            ],
            [
                _dec3,
                1,
                "configItems"
            ],
            [
                _dec4,
                1,
                "formatBarElement"
            ]
        ], []));
    }
    static{
        this.styles = formatBarStyle;
    }
    get _selectionManager() {
        return this.host.selection;
    }
    get displayType() {
        return this._displayType;
    }
    get nativeRange() {
        const sl = document.getSelection();
        if (!sl || sl.rangeCount === 0) return null;
        return sl.getRangeAt(0);
    }
    get selectedBlocks() {
        return this._selectedBlocks;
    }
    _calculatePlacement() {
        const rootComponent = this.block;
        this.handleEvent('dragStart', ()=>{
            this._dragging = true;
        });
        this.handleEvent('dragEnd', ()=>{
            this._dragging = false;
        });
        this.disposables.add(this.host.event.add('pointerUp', (ctx)=>{
            let targetRect = null;
            if (this.displayType === 'text' || this.displayType === 'native') {
                const range = this.nativeRange;
                if (!range) {
                    this.reset();
                    return;
                }
                targetRect = range.getBoundingClientRect();
            } else if (this.displayType === 'block') {
                const block = this._selectedBlocks[0];
                if (!block) return;
                targetRect = block.getBoundingClientRect();
            } else {
                return;
            }
            const { top: editorHostTop, bottom: editorHostBottom } = this.host.getBoundingClientRect();
            const e = ctx.get('pointerState');
            if (editorHostBottom - targetRect.bottom < 50) {
                this._placement = 'top';
            } else if (targetRect.top - Math.max(editorHostTop, 0) < 50) {
                this._placement = 'bottom';
            } else if (e.raw.y < targetRect.top + targetRect.height / 2) {
                this._placement = 'top';
            } else {
                this._placement = 'bottom';
            }
        }));
        this.disposables.add(this._selectionManager.slots.changed.on(()=>{
            const update = async ()=>{
                const textSelection = rootComponent.selection.find('text');
                const blockSelections = rootComponent.selection.filter('block');
                const cursorSelection = rootComponent.selection.find('cursor');
                if (cursorSelection) {
                    if (!this._lastCursor) {
                        this._lastCursor = cursorSelection;
                        return;
                    }
                    if (!this._selectionEqual(cursorSelection, this._lastCursor)) {
                        this._lastCursor = cursorSelection;
                        return;
                    }
                }
                await this.host.getUpdateComplete();
                if (textSelection) {
                    const block = this.host.view.getBlock(textSelection.blockId);
                    if (!textSelection.isCollapsed() && block && block.model.role === 'content') {
                        this._displayType = 'text';
                        if (!rootComponent.std.range) return;
                        this.host.std.command.chain().getTextSelection().getSelectedBlocks({
                            types: [
                                'text'
                            ]
                        }).inline((ctx)=>{
                            const { selectedBlocks } = ctx;
                            if (!selectedBlocks) return;
                            this._selectedBlocks = selectedBlocks;
                        }).run();
                        return;
                    }
                    this.reset();
                    return;
                }
                if (blockSelections.length > 0) {
                    this._displayType = 'block';
                    const selectedBlocks = blockSelections.map((selection)=>{
                        const path = selection.blockId;
                        return this.block.host.view.getBlock(path);
                    }).filter((el)=>!!el);
                    this._selectedBlocks = selectedBlocks;
                    return;
                }
                this.reset();
            };
            update().catch(console.error);
        }));
        this.disposables.addFromEvent(document, 'selectionchange', ()=>{
            if (!this.host.event.active) return;
            const databaseSelection = this.host.selection.find('database');
            if (!databaseSelection) {
                return;
            }
            const reset = ()=>{
                this.reset();
                this.requestUpdate();
            };
            const viewSelection = databaseSelection.viewSelection;
            if (viewSelection.type === 'table' && (viewSelection.selectionType !== 'area' || !viewSelection.isEditing)) return reset();
            if (viewSelection.type === 'kanban' && viewSelection.selectionType !== 'cell' || !viewSelection.isEditing) return reset();
            const range = this.nativeRange;
            if (!range || range.collapsed) return reset();
            this._displayType = 'native';
            this.requestUpdate();
        });
    }
    _listenFloatingElement() {
        const formatQuickBarElement = this.formatBarElement;
        assertExists(formatQuickBarElement, 'format quick bar should exist');
        const listenFloatingElement = (getElement)=>{
            const initialElement = getElement();
            if (!initialElement) {
                return;
            }
            assertExists(this._floatDisposables);
            HoverController.globalAbortController?.abort();
            this._floatDisposables.add(autoUpdate(initialElement, formatQuickBarElement, ()=>{
                const element = getElement();
                if (!element) return;
                computePosition(element, formatQuickBarElement, {
                    placement: this._placement,
                    middleware: [
                        offset(10),
                        inline(),
                        shift({
                            padding: 6
                        })
                    ]
                }).then(({ x, y })=>{
                    formatQuickBarElement.style.display = 'flex';
                    formatQuickBarElement.style.top = `${y}px`;
                    formatQuickBarElement.style.left = `${x}px`;
                }).catch(console.error);
            }, {
                animationFrame: true
            }));
        };
        const getReferenceElementFromBlock = ()=>{
            const firstBlock = this._selectedBlocks[0];
            let rect = firstBlock?.getBoundingClientRect();
            if (!rect) return;
            this._selectedBlocks.forEach((el)=>{
                const elRect = el.getBoundingClientRect();
                if (elRect.top < rect.top) {
                    rect = new DOMRect(rect.left, elRect.top, rect.width, rect.bottom);
                }
                if (elRect.bottom > rect.bottom) {
                    rect = new DOMRect(rect.left, rect.top, rect.width, elRect.bottom);
                }
                if (elRect.left < rect.left) {
                    rect = new DOMRect(elRect.left, rect.top, rect.right, rect.bottom);
                }
                if (elRect.right > rect.right) {
                    rect = new DOMRect(rect.left, rect.top, elRect.right, rect.bottom);
                }
            });
            return {
                getBoundingClientRect: ()=>rect,
                getClientRects: ()=>this._selectedBlocks.map((el)=>el.getBoundingClientRect())
            };
        };
        const getReferenceElementFromText = ()=>{
            const range = this.nativeRange;
            if (!range) {
                return;
            }
            return {
                getBoundingClientRect: ()=>range.getBoundingClientRect(),
                getClientRects: ()=>range.getClientRects()
            };
        };
        switch(this.displayType){
            case 'text':
            case 'native':
                return listenFloatingElement(getReferenceElementFromText);
            case 'block':
                return listenFloatingElement(getReferenceElementFromBlock);
            default:
                return;
        }
    }
    _selectionEqual(target, current) {
        if (target === current || target && current && target.equals(current)) {
            return true;
        }
        return false;
    }
    _shouldDisplay() {
        const readonly = this.doc.awarenessStore.isReadonly(this.doc.blockCollection);
        const active = this.host.event.active;
        if (readonly || !active) return false;
        if (this.displayType === 'block' && this._selectedBlocks?.[0]?.flavour === 'affine:surface-ref') {
            return false;
        }
        if (this.displayType === 'block' && this._selectedBlocks.length === 1) {
            const selectedBlock = this._selectedBlocks[0];
            if (!matchFlavours(selectedBlock.model, [
                'affine:paragraph',
                'affine:list',
                'affine:code',
                'affine:image'
            ])) {
                return false;
            }
        }
        if (this.displayType === 'none' || this._dragging) {
            return false;
        }
        if (this.displayType === 'text' && this._selectedBlocks.length === 1) {
            const isEmbed = ()=>{
                const [element] = this._selectedBlocks;
                const richText = element.querySelector('rich-text');
                const inline = richText?.inlineEditor;
                if (!richText || !inline) {
                    return false;
                }
                const range = inline.getInlineRange();
                if (!range || range.length > 1) {
                    return false;
                }
                const deltas = inline.getDeltasByInlineRange(range);
                if (deltas.length > 2) {
                    return false;
                }
                const delta = deltas?.[1]?.[0];
                if (!delta) {
                    return false;
                }
                return inline.isEmbed(delta);
            };
            if (isEmbed()) {
                return false;
            }
        }
        const rootBlockId = this.host.doc.root?.id;
        const aiPanel = rootBlockId ? this.host.view.getWidget('affine-ai-panel-widget', rootBlockId) : null;
        if (aiPanel && aiPanel?.state !== 'hidden') {
            return false;
        }
        return true;
    }
    addBlockTypeSwitch(config) {
        const { flavour, type, icon } = config;
        return this.addParagraphAction({
            id: `${flavour}/${type ?? ''}`,
            icon,
            flavour,
            name: config.name ?? camelCaseToWords(type ?? flavour),
            action: (chain)=>{
                chain.updateBlockType({
                    flavour,
                    props: type != null ? {
                        type
                    } : undefined
                }).run();
            }
        });
    }
    addDivider() {
        this.configItems.push({
            type: 'divider'
        });
        return this;
    }
    addHighlighterDropdown() {
        this.configItems.push({
            type: 'highlighter-dropdown'
        });
        return this;
    }
    addInlineAction(config) {
        this.configItems.push({
            ...config,
            type: 'inline-action'
        });
        return this;
    }
    addParagraphAction(config) {
        this.configItems.push({
            ...config,
            type: 'paragraph-action'
        });
        return this;
    }
    addParagraphDropdown() {
        this.configItems.push({
            type: 'paragraph-dropdown'
        });
        return this;
    }
    addRawConfigItems(configItems, index) {
        if (index === undefined) {
            this.configItems.push(...configItems);
        } else {
            this.configItems.splice(index, 0, ...configItems);
        }
        return this;
    }
    addTextStyleToggle(config) {
        const { key } = config;
        return this.addInlineAction({
            id: key,
            name: camelCaseToWords(key),
            icon: config.icon,
            isActive: (chain)=>{
                const [result] = chain.isTextStyleActive({
                    key
                }).run();
                return result;
            },
            action: config.action,
            showWhen: (chain)=>{
                const [result] = isFormatSupported(chain).run();
                return result;
            }
        });
    }
    clearConfig() {
        this.configItems = [];
        return this;
    }
    connectedCallback() {
        super.connectedCallback();
        this._abortController = new AbortController();
        const rootComponent = this.block;
        assertExists(rootComponent);
        const widgets = rootComponent.widgets;
        if (!Object.hasOwn(widgets, AFFINE_FORMAT_BAR_WIDGET)) {
            return;
        }
        if (rootComponent.model.flavour !== 'affine:page') {
            console.error(`format bar not support rootComponent: ${rootComponent.constructor.name} but its widgets has format bar`);
            return;
        }
        this._calculatePlacement();
        if (this.configItems.length === 0) {
            toolbarDefaultConfig(this);
        }
        this.moreGroups = getMoreMenuConfig(this.std).configure(this.moreGroups);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._abortController.abort();
        this.reset();
        this._lastCursor = undefined;
    }
    render() {
        if (!this._shouldDisplay()) {
            return nothing;
        }
        const items = ConfigRenderer(this);
        return html`
      <editor-toolbar class="${AFFINE_FORMAT_BAR_WIDGET}">
        ${items}
        <editor-toolbar-separator></editor-toolbar-separator>
        ${toolbarMoreButton(this)}
      </editor-toolbar>
    `;
    }
    reset() {
        this._displayType = 'none';
        this._selectedBlocks = [];
    }
    updated() {
        if (!this._shouldDisplay()) {
            if (this._floatDisposables) {
                this._floatDisposables.dispose();
            }
            return;
        }
        this._floatDisposables = new DisposableGroup();
        this._listenFloatingElement();
    }
    #___private__displayType_1;
    get _displayType() {
        return this.#___private__displayType_1;
    }
    set _displayType(_v) {
        this.#___private__displayType_1 = _v;
    }
    #___private__dragging_2;
    get _dragging() {
        return this.#___private__dragging_2;
    }
    set _dragging(_v) {
        this.#___private__dragging_2 = _v;
    }
    #___private__selectedBlocks_3;
    get _selectedBlocks() {
        return this.#___private__selectedBlocks_3;
    }
    set _selectedBlocks(_v) {
        this.#___private__selectedBlocks_3 = _v;
    }
    #___private_configItems_4;
    get configItems() {
        return this.#___private_configItems_4;
    }
    set configItems(_v) {
        this.#___private_configItems_4 = _v;
    }
    #___private_formatBarElement_5;
    get formatBarElement() {
        return this.#___private_formatBarElement_5;
    }
    set formatBarElement(_v) {
        this.#___private_formatBarElement_5 = _v;
    }
    constructor(...args){
        super(...args), this._abortController = new AbortController(), this._floatDisposables = null, this._lastCursor = undefined, this._placement = 'top', this.moreGroups = cloneGroups(BUILT_IN_GROUPS), this.#___private__displayType_1 = (_initProto(this), _init__displayType(this, 'none')), this.#___private__dragging_2 = _init__dragging(this, false), this.#___private__selectedBlocks_3 = _init__selectedBlocks(this, []), this.#___private_configItems_4 = _init_configItems(this, []), this.#___private_formatBarElement_5 = _init_formatBarElement(this, null);
    }
}
function camelCaseToWords(s) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}
