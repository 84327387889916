import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
export class DocPropertyList extends Entity {
    constructor(docPropertiesStore){
        super(), this.docPropertiesStore = docPropertiesStore, this.properties$ = LiveData.from(this.docPropertiesStore.watchDocPropertyInfoList(), []);
    }
    updatePropertyInfo(id, properties) {
        this.docPropertiesStore.updateDocPropertyInfo(id, properties);
    }
    createProperty(properties) {
        return this.docPropertiesStore.createDocPropertyInfo(properties);
    }
    removeProperty(id) {
        this.docPropertiesStore.removeDocPropertyInfo(id);
    }
}
