import { checkboxPropertyModelConfig } from './checkbox/define.js';
import { datePropertyModelConfig } from './date/define.js';
import { imagePropertyModelConfig } from './image/define.js';
import { multiSelectPropertyModelConfig } from './multi-select/define.js';
import { numberPropertyModelConfig } from './number/define.js';
import { progressPropertyModelConfig } from './progress/define.js';
import { selectPropertyModelConfig } from './select/define.js';
import { textPropertyModelConfig } from './text/define.js';
export const propertyModelPresets = {
    checkboxPropertyModelConfig,
    datePropertyModelConfig,
    imagePropertyModelConfig,
    multiSelectPropertyModelConfig,
    numberPropertyModelConfig,
    progressPropertyModelConfig,
    selectPropertyModelConfig,
    textPropertyModelConfig
};
