export const GeneralSettingKeys = [
    'shortcuts',
    'appearance',
    'about',
    'plans',
    'billing',
    'experimental-features',
    'editor'
];
export const WorkspaceSubTabs = [
    'preference',
    'properties'
];
