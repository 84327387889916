export { GlobalCache, GlobalState } from './providers/global';
export { GlobalCacheService, GlobalStateService } from './services/global';
import { MemoryMemento } from '../../storage';
import { GlobalCache, GlobalState } from './providers/global';
import { GlobalCacheService, GlobalStateService } from './services/global';
export const configureGlobalStorageModule = (framework)=>{
    framework.service(GlobalStateService, [
        GlobalState
    ]);
    framework.service(GlobalCacheService, [
        GlobalCache
    ]);
};
export const configureTestingGlobalStorage = (framework)=>{
    framework.impl(GlobalCache, MemoryMemento);
    framework.impl(GlobalState, MemoryMemento);
};
