import { z } from 'zod';
import { createEnumMap } from '../utils/enum.js';
export const NOTE_WIDTH = 800;
export var NoteBackgroundColor;
(function(NoteBackgroundColor) {
    NoteBackgroundColor["Black"] = "--affine-note-background-black";
    NoteBackgroundColor["Blue"] = "--affine-note-background-blue";
    NoteBackgroundColor["Green"] = "--affine-note-background-green";
    NoteBackgroundColor["Grey"] = "--affine-note-background-grey";
    NoteBackgroundColor["Magenta"] = "--affine-note-background-magenta";
    NoteBackgroundColor["Orange"] = "--affine-note-background-orange";
    NoteBackgroundColor["Purple"] = "--affine-note-background-purple";
    NoteBackgroundColor["Red"] = "--affine-note-background-red";
    NoteBackgroundColor["Teal"] = "--affine-note-background-teal";
    NoteBackgroundColor["White"] = "--affine-note-background-white";
    NoteBackgroundColor["Yellow"] = "--affine-note-background-yellow";
})(NoteBackgroundColor || (NoteBackgroundColor = {}));
export const NoteBackgroundColorMap = createEnumMap(NoteBackgroundColor);
export const NOTE_BACKGROUND_COLORS = [
    "--affine-note-background-yellow",
    "--affine-note-background-orange",
    "--affine-note-background-red",
    "--affine-note-background-magenta",
    "--affine-note-background-purple",
    "--affine-note-background-blue",
    "--affine-note-background-teal",
    "--affine-note-background-green",
    "--affine-note-background-black",
    "--affine-note-background-grey",
    "--affine-note-background-white"
];
export const DEFAULT_NOTE_BACKGROUND_COLOR = "--affine-note-background-white";
export const NoteBackgroundColorsSchema = z.nativeEnum(NoteBackgroundColor);
export var NoteShadow;
(function(NoteShadow) {
    NoteShadow["Box"] = "--affine-note-shadow-box";
    NoteShadow["Film"] = "--affine-note-shadow-film";
    NoteShadow["Float"] = "--affine-note-shadow-float";
    NoteShadow["None"] = "";
    NoteShadow["Paper"] = "--affine-note-shadow-paper";
    NoteShadow["Sticker"] = "--affine-note-shadow-sticker";
})(NoteShadow || (NoteShadow = {}));
export const NoteShadowMap = createEnumMap(NoteShadow);
export const NOTE_SHADOWS = [
    "",
    "--affine-note-shadow-box",
    "--affine-note-shadow-sticker",
    "--affine-note-shadow-paper",
    "--affine-note-shadow-float",
    "--affine-note-shadow-film"
];
export const DEFAULT_NOTE_SHADOW = "--affine-note-shadow-box";
export const NoteShadowsSchema = z.nativeEnum(NoteShadow);
export var NoteDisplayMode;
(function(NoteDisplayMode) {
    NoteDisplayMode["DocAndEdgeless"] = "both";
    NoteDisplayMode["DocOnly"] = "doc";
    NoteDisplayMode["EdgelessOnly"] = "edgeless";
})(NoteDisplayMode || (NoteDisplayMode = {}));
export var StrokeStyle;
(function(StrokeStyle) {
    StrokeStyle["Dash"] = "dash";
    StrokeStyle["None"] = "none";
    StrokeStyle["Solid"] = "solid";
})(StrokeStyle || (StrokeStyle = {}));
export const DEFAULT_NOTE_BORDER_STYLE = "none";
export const StrokeStyleMap = createEnumMap(StrokeStyle);
export var NoteCorners;
(function(NoteCorners) {
    NoteCorners[NoteCorners["Huge"] = 32] = "Huge";
    NoteCorners[NoteCorners["Large"] = 24] = "Large";
    NoteCorners[NoteCorners["Medium"] = 16] = "Medium";
    NoteCorners[NoteCorners["None"] = 0] = "None";
    NoteCorners[NoteCorners["Small"] = 8] = "Small";
})(NoteCorners || (NoteCorners = {}));
export const NoteCornersMap = createEnumMap(NoteCorners);
export const NOTE_CORNERS = [
    0,
    8,
    16,
    24,
    32
];
export const DEFAULT_NOTE_CORNER = 8;
export const NoteCornersSchema = z.nativeEnum(NoteCorners);
export const DEFAULT_NOTE_BORDER_SIZE = 4;
