function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _init__selectInput, _init_editComplete, _init_mode, _init_onChange, _init_onOptionsChange, _init_options, _init_selectedIndex, _init_text, _init_value, _initProto;
import { createPopup, popMenu } from '@blocksuite/affine-components/context-menu';
import { rangeWrap } from '@blocksuite/affine-shared/utils';
import { ShadowlessElement } from '@blocksuite/block-std';
import { WithDisposable } from '@blocksuite/global/utils';
import { CloseIcon, DeleteIcon, MoreHorizontalIcon, PlusIcon } from '@blocksuite/icons/lit';
import { nanoid } from '@blocksuite/store';
import { autoPlacement, flip, offset } from '@floating-ui/dom';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { html } from 'lit/static-html.js';
import { stopPropagation } from '../event.js';
import { getTagColor, selectOptionColors } from './colors.js';
import { styles } from './styles.js';
_dec = query('.select-input'), _dec1 = property({
    attribute: false
}), _dec2 = property(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = state(), _dec7 = state(), _dec8 = property({
    attribute: false
});
export class MultiTagSelect extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init__selectInput, _init_editComplete, _init_mode, _init_onChange, _init_onOptionsChange, _init_options, _init_selectedIndex, _init_text, _init_value, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_selectInput"
            ],
            [
                _dec1,
                1,
                "editComplete"
            ],
            [
                _dec2,
                1,
                "mode"
            ],
            [
                _dec3,
                1,
                "onChange"
            ],
            [
                _dec4,
                1,
                "onOptionsChange"
            ],
            [
                _dec5,
                1,
                "options"
            ],
            [
                _dec6,
                1,
                "selectedIndex"
            ],
            [
                _dec7,
                1,
                "text"
            ],
            [
                _dec8,
                1,
                "value"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get color() {
        if (!this._currentColor) {
            this._currentColor = getTagColor();
        }
        return this._currentColor;
    }
    get isSingleMode() {
        return this.mode === 'single';
    }
    get selectedTag() {
        return this.filteredOptions[this.selectedIndex];
    }
    _filterOptions() {
        const map = this.optionsIdMap();
        let matched = false;
        const options = this.options.map((v)=>({
                ...v,
                group: this.getTagGroup(v, map)
            })).filter((item)=>{
            if (!this.text) {
                return true;
            }
            return this.getTagFullName(item, item.group).toLocaleLowerCase().includes(this.text.toLocaleLowerCase());
        }).map((v)=>{
            const fullName = this.getTagFullName(v, v.group);
            if (fullName === this.text) {
                matched = true;
            }
            return {
                ...v,
                isCreate: false,
                select: ()=>this._onSelect(v.id)
            };
        });
        if (this.text && !matched) {
            options.push({
                id: 'create',
                color: this.color,
                value: this.text,
                isCreate: true,
                group: [],
                select: this._createOption
            });
        }
        return options;
    }
    clearColor() {
        this._currentColor = undefined;
    }
    getGroupInfoByFullName(name) {
        const strings = name.split('/');
        const names = strings.slice(0, -1);
        const result = [];
        for (const text of names){
            const parent = result[result.length - 1];
            const tag = this.options.find((v)=>v.parentId === parent?.id && v.value === text);
            if (!tag) {
                return;
            }
            result.push(tag);
        }
        return {
            name: strings[strings.length - 1],
            group: result,
            parent: result[result.length - 1]
        };
    }
    getTagFullName(tag, group) {
        return [
            ...group,
            tag
        ].map((v)=>v.value).join('/');
    }
    getTagGroup(tag, map = this.optionsIdMap()) {
        const result = [];
        let parentId = tag.parentId;
        while(parentId){
            const parent = map[parentId];
            result.unshift(parent);
            parentId = parent.parentId;
        }
        return result;
    }
    optionsIdMap() {
        return Object.fromEntries(this.options.map((v)=>[
                v.id,
                v
            ]));
    }
    setSelectedOption(index) {
        this.selectedIndex = rangeWrap(index, 0, this.filteredOptions.length);
    }
    firstUpdated() {
        requestAnimationFrame(()=>{
            this._selectInput.focus();
        });
        this._disposables.addFromEvent(this, 'click', ()=>{
            this._selectInput.focus();
        });
        this._disposables.addFromEvent(this._selectInput, 'copy', (e)=>{
            e.stopPropagation();
        });
        this._disposables.addFromEvent(this._selectInput, 'cut', (e)=>{
            e.stopPropagation();
        });
    }
    render() {
        this.filteredOptions = this._filterOptions();
        this.setSelectedOption(this.selectedIndex);
        const selectedTag = this.value;
        const map = new Map(this.options.map((v)=>[
                v.id,
                v
            ]));
        return html`
      <div class="affine-select-cell-select">
        <div class="select-input-container">
          ${selectedTag.map((id)=>{
            const option = map.get(id);
            if (!option) {
                return;
            }
            const style = styleMap({
                backgroundColor: option.color
            });
            return html` <div class="select-selected" style=${style}>
              <div class="select-selected-text">${option.value}</div>
              <span
                class="close-icon"
                @click="${()=>this._onDeleteSelected(selectedTag, id)}"
                >${CloseIcon()}</span
              >
            </div>`;
        })}
          <input
            class="select-input"
            placeholder="Type here..."
            .value="${this.text}"
            @input="${this._onInput}"
            @keydown="${this._onInputKeydown}"
            @pointerdown="${stopPropagation}"
          />
        </div>
        <div class="select-option-container">
          <div class="select-option-container-header">
            Select tag or create one
          </div>
          ${repeat(this.filteredOptions, (select)=>select.id, (select, index)=>{
            const isSelected = index === this.selectedIndex;
            const mouseenter = ()=>{
                this.setSelectedOption(index);
            };
            const classes = classMap({
                'select-option': true,
                selected: isSelected
            });
            const style = styleMap({
                backgroundColor: select.color
            });
            const clickOption = (e)=>this._clickItemOption(e, select.id);
            return html`
                <div class="${classes}" @mouseenter="${mouseenter}">
                  <div
                    class="select-option-text-container"
                    @click="${select.select}"
                  >
                    ${select.isCreate ? html` <div class="select-option-new-icon">
                          Create ${PlusIcon()}
                        </div>` : ''}
                    <div style="display:flex;flex-direction: column">
                      <div
                        style="display:flex;align-items:center;margin-bottom: 2px;opacity: 0.5;"
                      >
                        ${select.group.map((v, i)=>{
                const style = styleMap({
                    backgroundColor: v.color
                });
                return html`${i === 0 ? '' : html`<span style="margin: 0 1px">/</span>`}<span
                              class="select-option-group-name"
                              style=${style}
                              >${v.value}</span
                            >`;
            })}
                      </div>
                      <div style="display:flex;">
                        <div style=${style} class="select-option-name">
                          ${select.value}
                        </div>
                      </div>
                    </div>
                  </div>
                  ${!select.isCreate ? html` <div
                        class="select-option-icon"
                        @click="${clickOption}"
                      >
                        ${MoreHorizontalIcon()}
                      </div>` : null}
                </div>
              `;
        })}
        </div>
      </div>
    `;
    }
    #___private__selectInput_1;
    get _selectInput() {
        return this.#___private__selectInput_1;
    }
    set _selectInput(_v) {
        this.#___private__selectInput_1 = _v;
    }
    #___private_editComplete_2;
    get editComplete() {
        return this.#___private_editComplete_2;
    }
    set editComplete(_v) {
        this.#___private_editComplete_2 = _v;
    }
    #___private_mode_3;
    get mode() {
        return this.#___private_mode_3;
    }
    set mode(_v) {
        this.#___private_mode_3 = _v;
    }
    #___private_onChange_4;
    get onChange() {
        return this.#___private_onChange_4;
    }
    set onChange(_v) {
        this.#___private_onChange_4 = _v;
    }
    #___private_onOptionsChange_5;
    get onOptionsChange() {
        return this.#___private_onOptionsChange_5;
    }
    set onOptionsChange(_v) {
        this.#___private_onOptionsChange_5 = _v;
    }
    #___private_options_6;
    get options() {
        return this.#___private_options_6;
    }
    set options(_v) {
        this.#___private_options_6 = _v;
    }
    #___private_selectedIndex_7;
    get selectedIndex() {
        return this.#___private_selectedIndex_7;
    }
    set selectedIndex(_v) {
        this.#___private_selectedIndex_7 = _v;
    }
    #___private_text_8;
    get text() {
        return this.#___private_text_8;
    }
    set text(_v) {
        this.#___private_text_8 = _v;
    }
    #___private_value_9;
    get value() {
        return this.#___private_value_9;
    }
    set value(_v) {
        this.#___private_value_9 = _v;
    }
    constructor(...args){
        super(...args), this._clickItemOption = (e, id)=>{
            e.stopPropagation();
            const option = this.options.find((v)=>v.id === id);
            if (!option) {
                return;
            }
            popMenu(e.target, {
                options: {
                    input: {
                        initValue: option.value,
                        onComplete: (text)=>{
                            this.changeTag({
                                ...option,
                                value: text
                            });
                        }
                    },
                    items: [
                        {
                            type: 'action',
                            name: 'Delete',
                            icon: DeleteIcon(),
                            class: 'delete-item',
                            select: ()=>{
                                this.deleteTag(id);
                            }
                        },
                        {
                            type: 'group',
                            name: 'color',
                            children: ()=>selectOptionColors.map((item)=>{
                                    const styles = styleMap({
                                        backgroundColor: item.color,
                                        borderRadius: '50%',
                                        width: '20px',
                                        height: '20px'
                                    });
                                    return {
                                        type: 'action',
                                        name: item.name,
                                        icon: html` <div style=${styles}></div>`,
                                        isSelected: option.color === item.color,
                                        select: ()=>{
                                            this.changeTag({
                                                ...option,
                                                color: item.color
                                            });
                                        }
                                    };
                                })
                        }
                    ]
                },
                middleware: [
                    autoPlacement()
                ]
            });
        }, this._createOption = ()=>{
            const value = this.text.trim();
            if (value === '') return;
            const groupInfo = this.getGroupInfoByFullName(value);
            if (!groupInfo) {
                return;
            }
            const name = groupInfo.name;
            const tagColor = this.color;
            this.clearColor();
            const newSelect = {
                id: nanoid(),
                value: name,
                color: tagColor,
                parentId: groupInfo.parent?.id
            };
            this.newTags([
                newSelect
            ]);
            const newValue = this.isSingleMode ? [
                newSelect.id
            ] : [
                ...this.value,
                newSelect.id
            ];
            this.onChange(newValue);
            this.text = '';
            if (this.isSingleMode) {
                this.editComplete();
            }
        }, this._currentColor = undefined, this._onDeleteSelected = (selectedValue, value)=>{
            const filteredValue = selectedValue.filter((item)=>item !== value);
            this.onChange(filteredValue);
        }, this._onInput = (event)=>{
            this.text = event.target.value;
        }, this._onInputKeydown = (event)=>{
            event.stopPropagation();
            const inputValue = this.text.trim();
            if (event.key === 'Backspace' && inputValue === '') {
                this._onDeleteSelected(this.value, this.value[this.value.length - 1]);
            } else if (event.key === 'Enter' && !event.isComposing) {
                this.selectedTag?.select();
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.setSelectedOption(this.selectedIndex - 1);
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                this.setSelectedOption(this.selectedIndex + 1);
            } else if (event.key === 'Escape') {
                this.editComplete();
            } else if (event.key === 'Tab') {
                event.preventDefault();
                const selectTag = this.selectedTag;
                if (selectTag) {
                    this.text = this.getTagFullName(selectTag, selectTag.group);
                }
            }
        }, this._onSelect = (id)=>{
            const isExist = this.value.some((item)=>item === id);
            if (isExist) {
                return;
            }
            const isSelected = this.value.indexOf(id) > -1;
            if (!isSelected) {
                const newValue = this.isSingleMode ? [
                    id
                ] : [
                    ...this.value,
                    id
                ];
                this.onChange(newValue);
                if (this.isSingleMode) {
                    setTimeout(()=>{
                        this.editComplete();
                    }, 4);
                }
            }
            this.text = '';
        }, this.filteredOptions = [], this.changeTag = (tag)=>{
            this.onOptionsChange(this.options.map((v)=>v.id === tag.id ? tag : v));
        }, this.deleteTag = (id)=>{
            this.onOptionsChange(this.options.filter((v)=>v.id !== id).map((v)=>({
                    ...v,
                    parentId: v.parentId === id ? undefined : v.parentId
                })));
        }, this.newTags = (tags)=>{
            this.onOptionsChange([
                ...tags,
                ...this.options
            ]);
        }, this.#___private__selectInput_1 = (_initProto(this), _init__selectInput(this)), this.#___private_editComplete_2 = _init_editComplete(this), this.#___private_mode_3 = _init_mode(this, 'multi'), this.#___private_onChange_4 = _init_onChange(this), this.#___private_onOptionsChange_5 = _init_onOptionsChange(this), this.#___private_options_6 = _init_options(this, []), this.#___private_selectedIndex_7 = _init_selectedIndex(this, 0), this.#___private_text_8 = _init_text(this, ''), this.#___private_value_9 = _init_value(this, []);
    }
}
export const popTagSelect = (target, ops)=>{
    const component = new MultiTagSelect();
    if (ops.mode) {
        component.mode = ops.mode;
    }
    component.style.width = `${Math.max(ops.minWidth ?? target.offsetWidth, target.offsetWidth)}px`;
    component.value = ops.value;
    component.onChange = (tags)=>{
        ops.onChange(tags);
        component.value = tags;
    };
    component.options = ops.options;
    component.onOptionsChange = (options)=>{
        ops.onOptionsChange(options);
        component.options = options;
    };
    component.editComplete = ()=>{
        ops.onComplete?.();
        remove();
    };
    const remove = createPopup(target, component, {
        onClose: ops.onComplete,
        middleware: [
            flip(),
            offset({
                mainAxis: -28,
                crossAxis: 112
            })
        ],
        container: ops.container
    });
    return remove;
};
