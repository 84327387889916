import { getWorkspacePageMetaByIdQuery } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class CloudDocMetaStore extends Store {
    constructor(gqlService){
        super(), this.gqlService = gqlService;
    }
    async fetchCloudDocMeta(workspaceId, docId, abortSignal) {
        const serverConfigData = await this.gqlService.gql({
            query: getWorkspacePageMetaByIdQuery,
            variables: {
                id: workspaceId,
                pageId: docId
            },
            context: {
                signal: abortSignal
            }
        });
        return serverConfigData.workspace.pageMeta;
    }
}
