import { generateFractionalIndexingKeyBetween } from '@affine/core/utils';
import { Entity, LiveData } from '@toeverything/infra';
import { map, switchMap } from 'rxjs';
import { FeedNodeType } from '../stores/feed-node';
export class FeedNode extends Entity {
    constructor(nodesStore){
        super(), this.nodesStore = nodesStore, this.id = this.props.id, this.info$ = LiveData.from(this.nodesStore.watchNodeInfo(this.id ?? ''), null), this.type$ = this.info$.map((info)=>this.id === null ? FeedNodeType.Folder : info?.type ?? ''), this.name$ = this.info$.map((info)=>info?.name), this.description$ = this.info$.map((info)=>info?.description), this.icon$ = this.info$.map((info)=>info?.icon), this.index$ = this.info$.map((info)=>info?.index ?? ''), this.source$ = this.info$.map((info)=>info?.source ?? ''), this.unreadCount$ = LiveData.computed((get)=>{
            const info = get(this.info$);
            const type = get(this.type$);
            if (type !== FeedNodeType.Folder) {
                return info?.unreadCount ?? 0;
            }
            const children = get(this.sortedChildren$);
            return children.reduce((sum, child)=>sum + get(child.unreadCount$), 0);
        }), this.children$ = LiveData.from(this.type$.pipe(switchMap(()=>this.nodesStore.watchNodeChildren(this.id).pipe(map((children)=>children.filter((e)=>this.filterInvalidChildren(e)).map((child)=>this.framework.createEntity(FeedNode, child)))).pipe())), []), this.sortedChildren$ = LiveData.computed((get)=>{
            return get(this.children$).map((node)=>[
                    node,
                    get(node.index$)
                ]).sort((a, b)=>a[1] > b[1] ? 1 : -1).map(([node])=>node);
        });
    }
    contains(childId) {
        if (!this.id) {
            return true;
        }
        if (!childId) {
            return false;
        }
        return this.nodesStore.isAncestor(childId, this.id);
    }
    beChildOf(parentId) {
        if (!this.id) {
            return false;
        }
        if (!parentId) {
            return true;
        }
        return this.nodesStore.isAncestor(this.id, parentId);
    }
    filterInvalidChildren(child) {
        if (this.id === null && child.type !== FeedNodeType.Folder && child.type !== FeedNodeType.RSS) {
            return false;
        }
        return true;
    }
    createFolder(name, index) {
        if (this.type$.value !== FeedNodeType.Folder) {
            throw new Error('Cannot create folder on non-folder node');
        }
        return this.nodesStore.createFolder(this.id, name, index);
    }
    createFeed(id, name, source, description, icon) {
        if (this.type$.value !== FeedNodeType.Folder) {
            throw new Error('Cannot create link on non-folder node');
        }
        return this.nodesStore.createRSS(this.id, id, name, source, description, icon, this.indexAt('before'));
    }
    delete() {
        if (this.id === null) {
            throw new Error('Cannot delete root node');
        }
        if (this.type$.value === FeedNodeType.Folder) {
            this.nodesStore.removeFolder(this.id);
        } else {
            this.nodesStore.removeFeed(this.id);
        }
    }
    moveHere(childId, index) {
        this.nodesStore.moveNode(childId, this.id, index);
    }
    rename(name) {
        if (this.id === null) {
            throw new Error('Cannot rename root node');
        }
        this.nodesStore.renameNode(this.id, name);
    }
    incrUnreadCount(count) {
        if (this.id === null) {
            throw new Error('Cannot incr unread count on root node');
        }
        this.nodesStore.incrUnreadCount(this.id, count);
    }
    indexAt(at, targetId) {
        if (!targetId) {
            if (at === 'before') {
                const first = this.sortedChildren$.value.at(0);
                return generateFractionalIndexingKeyBetween(null, first?.index$.value || null);
            } else {
                const last = this.sortedChildren$.value.at(-1);
                return generateFractionalIndexingKeyBetween(last?.index$.value || null, null);
            }
        } else {
            const sortedChildren = this.sortedChildren$.value;
            const targetIndex = sortedChildren.findIndex((node)=>node.id === targetId);
            if (targetIndex === -1) {
                throw new Error('Target node not found');
            }
            const target = sortedChildren[targetIndex];
            const before = sortedChildren[targetIndex - 1] || null;
            const after = sortedChildren[targetIndex + 1] || null;
            if (at === 'before') {
                return generateFractionalIndexingKeyBetween(before?.index$.value || null, target.index$.value);
            } else {
                return generateFractionalIndexingKeyBetween(target.index$.value, after?.index$.value || null);
            }
        }
    }
}
