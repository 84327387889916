import { EmbedYoutubeBlockSchema, EmbedYoutubeStyles } from '@blocksuite/affine-model';
import { EmbedOptionProvider } from '@blocksuite/affine-shared/services';
import { BlockService } from '@blocksuite/block-std';
import { LinkPreviewer } from '../common/link-previewer.js';
import { youtubeUrlRegex } from './embed-youtube-model.js';
import { queryEmbedYoutubeData } from './utils.js';
export class EmbedYoutubeBlockService extends BlockService {
    static{
        this.flavour = EmbedYoutubeBlockSchema.model.flavour;
    }
    static{
        this.linkPreviewer = new LinkPreviewer();
    }
    static{
        this.setLinkPreviewEndpoint = EmbedYoutubeBlockService.linkPreviewer.setEndpoint;
    }
    mounted() {
        super.mounted();
        this.std.get(EmbedOptionProvider).registerEmbedBlockOptions({
            flavour: this.flavour,
            urlRegex: youtubeUrlRegex,
            styles: EmbedYoutubeStyles,
            viewType: 'embed'
        });
    }
    constructor(...args){
        super(...args), this.queryUrlData = (embedYoutubeModel, signal)=>{
            return queryEmbedYoutubeData(embedYoutubeModel, EmbedYoutubeBlockService.linkPreviewer, signal);
        };
    }
}
