import { jsx as _jsx } from "react/jsx-runtime";
import { toast } from '@affine/component';
import { CollectionService } from '@affine/core/modules/collection';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo, useState } from 'react';
import { FavoriteTag } from '../components/favorite-tag';
import { collectionHeaderColsDef } from '../header-col-def';
import { CollectionListItemRenderer } from '../page-group';
import { ListTableHeader } from '../page-header';
import { SelectorLayout } from '../selector/selector-layout';
import { VirtualizedList } from '../virtualized-list';
const FavoriteOperation = ({ collection })=>{
    const t = useI18n();
    const favAdapter = useService(CompatibleFavoriteItemsAdapter);
    const isFavorite = useLiveData(favAdapter.isFavorite$(collection.id, 'collection'));
    const onToggleFavoriteCollection = useCallback(()=>{
        favAdapter.toggle(collection.id, 'collection');
        toast(isFavorite ? t['com.affine.toastMessage.removedFavorites']() : t['com.affine.toastMessage.addedFavorites']());
    }, [
        collection.id,
        favAdapter,
        isFavorite,
        t
    ]);
    return _jsx(FavoriteTag, {
        style: {
            marginRight: 8
        },
        onClick: onToggleFavoriteCollection,
        active: isFavorite
    });
};
export const SelectCollection = ({ init = [], onCancel, onConfirm })=>{
    const t = useI18n();
    const collectionService = useService(CollectionService);
    const workspace = useService(WorkspaceService).workspace;
    const collections = useLiveData(collectionService.collections$);
    const [selection, setSelection] = useState(init);
    const [keyword, setKeyword] = useState('');
    const collectionMetas = useMemo(()=>{
        const collectionsList = collections.map((collection)=>{
            return {
                ...collection,
                title: collection.name
            };
        }).filter((meta)=>{
            const reg = new RegExp(keyword, 'i');
            return reg.test(meta.title);
        });
        return collectionsList;
    }, [
        collections,
        keyword
    ]);
    const collectionItemRenderer = useCallback((item)=>{
        return _jsx(CollectionListItemRenderer, {
            ...item
        });
    }, []);
    const collectionHeaderRenderer = useCallback(()=>{
        return _jsx(ListTableHeader, {
            headerCols: collectionHeaderColsDef
        });
    }, []);
    const collectionOperationRenderer = useCallback((item)=>{
        return _jsx(FavoriteOperation, {
            collection: item
        });
    }, []);
    return _jsx(SelectorLayout, {
        searchPlaceholder: t['com.affine.selector-collection.search.placeholder'](),
        selectedCount: selection.length,
        onSearch: setKeyword,
        onClear: ()=>setSelection([]),
        onCancel: ()=>onCancel?.(),
        onConfirm: ()=>onConfirm?.(selection),
        children: _jsx(VirtualizedList, {
            selectable: true,
            draggable: false,
            selectedIds: selection,
            onSelectedIdsChange: setSelection,
            items: collectionMetas,
            itemRenderer: collectionItemRenderer,
            rowAsLink: true,
            docCollection: workspace.docCollection,
            operationsRenderer: collectionOperationRenderer,
            headerRenderer: collectionHeaderRenderer
        })
    });
};
