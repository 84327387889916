export class MemoryDocEventBus {
    emit(event) {
        for (const listener of this.listeners){
            try {
                listener(event);
            } catch (e) {
                console.error(e);
            }
        }
    }
    on(cb) {
        this.listeners.add(cb);
        return ()=>{
            this.listeners.delete(cb);
        };
    }
    constructor(){
        this.listeners = new Set();
    }
}
export class DocEventBusInner {
    constructor(eventBusBehavior){
        this.eventBusBehavior = eventBusBehavior;
    }
    emit(event) {
        this.eventBusBehavior.emit(event);
    }
    on(cb) {
        return this.eventBusBehavior.on(cb);
    }
}
