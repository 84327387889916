import { textKeymap } from '@blocksuite/affine-components/rich-text';
import { CodeBlockSchema, ColorScheme } from '@blocksuite/affine-model';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { BlockService } from '@blocksuite/block-std';
import { signal } from '@preact/signals-core';
import { createHighlighterCore } from 'shiki';
import { bundledLanguagesInfo } from 'shiki';
import getWasm from 'shiki/wasm';
import { CODE_BLOCK_DEFAULT_DARK_THEME, CODE_BLOCK_DEFAULT_LIGHT_THEME } from './highlight/const.js';
export class CodeBlockService extends BlockService {
    static{
        this.flavour = CodeBlockSchema.model.flavour;
    }
    get langs() {
        return this.std.getConfig('affine:code')?.langs ?? bundledLanguagesInfo;
    }
    get themeKey() {
        return ThemeObserver.instance.mode$.value === ColorScheme.Dark ? this._darkThemeKey : this._lightThemeKey;
    }
    mounted() {
        super.mounted();
        this.bindHotKey(textKeymap(this.std));
        createHighlighterCore({
            loadWasm: getWasm
        }).then(async (highlighter)=>{
            const config = this.std.getConfig('affine:code');
            const darkTheme = config?.theme?.dark ?? CODE_BLOCK_DEFAULT_DARK_THEME;
            const lightTheme = config?.theme?.light ?? CODE_BLOCK_DEFAULT_LIGHT_THEME;
            this._darkThemeKey = (await normalizeGetter(darkTheme)).name;
            this._lightThemeKey = (await normalizeGetter(lightTheme)).name;
            await highlighter.loadTheme(darkTheme, lightTheme);
            this.highlighter$.value = highlighter;
            this.disposables.add(()=>{
                highlighter.dispose();
            });
        }).catch(console.error);
    }
    constructor(...args){
        super(...args), this.highlighter$ = signal(null);
    }
}
export async function normalizeGetter(p) {
    return Promise.resolve(typeof p === 'function' ? p() : p).then((r)=>r.default || r);
}
