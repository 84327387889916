import { generateKeyBetween } from 'fractional-indexing';
export function createFractionalIndexingSortableHelper(provider) {
    function getOrderedItems() {
        return provider.getItems().sort((a, b)=>{
            const oa = provider.getItemOrder(a);
            const ob = provider.getItemOrder(b);
            return oa > ob ? 1 : oa < ob ? -1 : 0;
        });
    }
    function getLargestOrder() {
        const lastItem = getOrderedItems().at(-1);
        return lastItem ? provider.getItemOrder(lastItem) : null;
    }
    function getSmallestOrder() {
        const firstItem = getOrderedItems().at(0);
        return firstItem ? provider.getItemOrder(firstItem) : null;
    }
    function getNewItemOrder() {
        return generateKeyBetween(getLargestOrder(), null);
    }
    function move(fromId, toId) {
        const items = getOrderedItems();
        const from = items.findIndex((i)=>provider.getItemId(i) === fromId);
        const to = items.findIndex((i)=>provider.getItemId(i) === toId);
        const fromItem = items[from];
        const toItem = items[to];
        const toNextItem = items[from < to ? to + 1 : to - 1];
        const toOrder = toItem ? provider.getItemOrder(toItem) : null;
        const toNextOrder = toNextItem ? provider.getItemOrder(toNextItem) : null;
        const args = from < to ? [
            toOrder,
            toNextOrder
        ] : [
            toNextOrder,
            toOrder
        ];
        provider.setItemOrder(fromItem, generateKeyBetween(...args));
    }
    function moveTo(fromId, toId, position) {
        const items = getOrderedItems();
        const from = items.findIndex((i)=>provider.getItemId(i) === fromId);
        const to = items.findIndex((i)=>provider.getItemId(i) === toId);
        const fromItem = items[from];
        if (fromItem === undefined) return;
        const toItem = items[to];
        const toItemPrev = items[to - 1];
        const toItemNext = items[to + 1];
        const toItemOrder = toItem ? provider.getItemOrder(toItem) : null;
        const toItemPrevOrder = toItemPrev ? provider.getItemOrder(toItemPrev) : null;
        const toItemNextOrder = toItemNext ? provider.getItemOrder(toItemNext) : null;
        if (position === 'before') {
            provider.setItemOrder(fromItem, generateKeyBetween(toItemPrevOrder, toItemOrder));
        } else {
            provider.setItemOrder(fromItem, generateKeyBetween(toItemOrder, toItemNextOrder));
        }
    }
    function insertBefore(id, beforeId) {
        const items = getOrderedItems();
        const item = items.find((i)=>provider.getItemId(i) === id);
        if (!item) return;
        const beforeItemIndex = items.findIndex((i)=>provider.getItemId(i) === beforeId);
        const beforeItem = beforeItemIndex !== -1 ? items[beforeItemIndex] : null;
        const beforeItemPrev = beforeItem ? items[beforeItemIndex - 1] : null;
        const beforeOrder = beforeItem ? provider.getItemOrder(beforeItem) : null;
        const beforePrevOrder = beforeItemPrev ? provider.getItemOrder(beforeItemPrev) : null;
        provider.setItemOrder(item, generateKeyBetween(beforePrevOrder, beforeOrder));
    }
    return {
        getOrderedItems,
        getLargestOrder,
        getSmallestOrder,
        getNewItemOrder,
        move,
        moveTo,
        insertBefore
    };
}
export function generateFractionalIndexingKeyBetween(a, b) {
    const randomSize = 32;
    function randomString(length = randomSize) {
        const values = new Uint8Array(length);
        crypto.getRandomValues(values);
        const chars = '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';
        for(let i = 0; i < length; i++){
            result += chars.charAt(values[i] % chars.length);
        }
        return result;
    }
    if (a !== null && b !== null && a >= b) {
        throw new Error('a should be smaller than b');
    }
    function subkey(key) {
        if (key === null) {
            return null;
        }
        if (key.length <= randomSize + 1) {
            return key;
        }
        const splitAt = key.substring(0, key.length - randomSize - 1);
        return splitAt;
    }
    const aSubkey = subkey(a);
    const bSubkey = subkey(b);
    if (aSubkey === null && bSubkey === null) {
        return generateKeyBetween(null, null) + '0' + randomString();
    } else if (aSubkey === null && bSubkey !== null) {
        return generateKeyBetween(null, bSubkey) + '0' + randomString();
    } else if (bSubkey === null && aSubkey !== null) {
        return generateKeyBetween(aSubkey, null) + '0' + randomString();
    } else if (aSubkey !== null && bSubkey !== null) {
        if (aSubkey === bSubkey && a !== null && b !== null) {
            return generateKeyBetween(a, b) + '0' + randomString();
        } else {
            return generateKeyBetween(aSubkey, bSubkey) + '0' + randomString();
        }
    }
    throw new Error('Never reach here');
}
