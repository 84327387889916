import { NoteBlockSpec, PageSurfaceBlockSpec, PageSurfaceRefBlockSpec } from '@blocksuite/affine/blocks';
import { AIBlockSpecs, DefaultBlockSpecs } from './common';
import { createPageRootBlockSpec } from './custom/root-block';
export function createPageModeSpecs(framework, enableAI) {
    return [
        ...enableAI ? AIBlockSpecs : DefaultBlockSpecs,
        PageSurfaceBlockSpec,
        PageSurfaceRefBlockSpec,
        NoteBlockSpec,
        createPageRootBlockSpec(framework, enableAI)
    ].flat();
}
