import { assertExists, assertNotExists } from '@blocksuite/global/utils';
import { Slot } from '@blocksuite/store';
export class PieManager {
    static{
        this.registeredSchemas = {};
    }
    static{
        this.schemas = new Set();
    }
    static{
        this.settings = {
            PIE_RADIUS: 150,
            SELECT_ON_RELEASE_TIMEOUT: 150,
            ACTIVATE_THRESHOLD_MIN: 60,
            SUBMENU_OPEN_TIMEOUT: 200,
            EXPANDABLE_ACTION_NODE_TIMEOUT: 300
        };
    }
    static{
        this.slots = {
            open: new Slot()
        };
    }
    static _getSchema(id) {
        const schema = this.registeredSchemas[id];
        assertExists(schema);
        return schema;
    }
    static _register(schema) {
        const { id } = schema;
        assertNotExists(this.registeredSchemas[id], `Menu with id '${id}' already exists. Please provide a unique id`);
        this.registeredSchemas[id] = schema;
    }
    static _setupTriggers(rootComponent) {
        Object.values(this.registeredSchemas).forEach((schema)=>{
            const { trigger } = schema;
            rootComponent.handleEvent('keyDown', (ctx)=>{
                const ev = ctx.get('keyboardState');
                if (trigger({
                    keyEvent: ev.raw,
                    rootComponent
                }) && !ev.raw.repeat) {
                    this.open(schema.id);
                }
            }, {
                global: true
            });
        });
    }
    static add(schema) {
        return this.schemas.add(schema);
    }
    static dispose() {
        this.registeredSchemas = {};
    }
    static open(id) {
        this.slots.open.emit(this._getSchema(id));
    }
    static remove(schema) {
        return this.schemas.delete(schema);
    }
    static setup({ rootComponent }) {
        this.schemas.forEach((schema)=>this._register(schema));
        this._setupTriggers(rootComponent);
    }
}
