import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@affine/component';
import { AffineErrorBoundary } from '@affine/core/components/affine/affine-error-boundary';
import { RightSidebarIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { Suspense, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { AppSidebarService } from '../../app-sidebar';
import { SidebarSwitch } from '../../app-sidebar/views';
import { ViewService } from '../services/view';
import { WorkbenchService } from '../services/workbench';
import * as styles from './route-container.css';
import { useViewPosition } from './use-view-position';
import { ViewBodyTarget, ViewHeaderTarget } from './view-islands';
export const ToggleButton = ({ onToggle, className, show })=>{
    return _jsx(IconButton, {
        size: "24",
        onClick: onToggle,
        className: className,
        "data-show": show,
        children: _jsx(RightSidebarIcon, {})
    });
};
export const RouteContainer = ()=>{
    const viewPosition = useViewPosition();
    const appSidebarService = useService(AppSidebarService).sidebar;
    const leftSidebarOpen = useLiveData(appSidebarService.open$);
    const workbench = useService(WorkbenchService).workbench;
    const view = useService(ViewService).view;
    const sidebarOpen = useLiveData(workbench.sidebarOpen$);
    const handleToggleSidebar = useCallback(()=>{
        workbench.toggleSidebar();
    }, [
        workbench
    ]);
    const currentPath = useLiveData(useService(WorkbenchService).workbench.location$.map((location)=>location.pathname));
    const isFeed = currentPath.includes('/feed/');
    return _jsxs("div", {
        className: styles.root,
        children: [
            _jsxs("div", {
                className: styles.header,
                style: isFeed ? {
                    display: 'none'
                } : {},
                children: [
                    !BUILD_CONFIG.isElectron && viewPosition.isFirst && _jsx(SidebarSwitch, {
                        show: !leftSidebarOpen,
                        className: styles.leftSidebarButton
                    }),
                    _jsx(ViewHeaderTarget, {
                        viewId: view.id,
                        className: styles.viewHeaderContainer
                    }),
                    !BUILD_CONFIG.isElectron && viewPosition.isLast && _jsx(ToggleButton, {
                        show: !sidebarOpen,
                        className: styles.rightSidebarButton,
                        onToggle: handleToggleSidebar
                    })
                ]
            }),
            _jsx(AffineErrorBoundary, {
                children: _jsx(Suspense, {
                    children: _jsx(Outlet, {})
                })
            }),
            _jsx(ViewBodyTarget, {
                viewId: view.id,
                className: styles.viewBodyContainer
            })
        ]
    });
};
