function number2letter(n) {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
    let s = '';
    while(n >= 0){
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
}
export function number2roman(num) {
    const lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
    };
    let romanStr = '';
    for(const i in lookup){
        while(num >= lookup[i]){
            romanStr += i;
            num -= lookup[i];
        }
    }
    return romanStr;
}
function getPrefix(depth, index) {
    const map = [
        ()=>index,
        ()=>number2letter(index - 1),
        ()=>number2roman(index)
    ];
    return map[depth % map.length]();
}
export function getNumberPrefix(index, depth) {
    const prefix = getPrefix(depth, index);
    return `${prefix}.`;
}
