import { AFFINE_AI_PANEL_WIDGET, AffineAIPanelWidget, ImageBlockModel, isInsideEdgelessEditor, matchFlavours, NoteDisplayMode } from '@blocksuite/affine/blocks';
import { assertExists, Bound } from '@blocksuite/affine/global/utils';
import { AIPenIcon, AIStarIconWithAnimation, ChatWithAIIcon, CreateIcon, DiscardIcon, InsertBelowIcon, InsertTopIcon, ReplaceIcon, RetryIcon } from './_common/icons';
import { INSERT_ABOVE_ACTIONS } from './actions/consts';
import { createTextRenderer } from './messages/text';
import { AIProvider } from './provider';
import { reportResponse } from './utils/action-reporter';
import { findNoteBlockModel, getService } from './utils/edgeless';
import { copyTextAnswer, insertAbove, insertBelow, replace } from './utils/editor-actions';
import { insertFromMarkdown } from './utils/markdown-utils';
import { getSelections } from './utils/selection-utils';
function getSelection(host) {
    const textSelection = host.selection.find('text');
    const mode = textSelection ? 'flat' : 'highest';
    const { selectedBlocks } = getSelections(host, mode);
    assertExists(selectedBlocks);
    const length = selectedBlocks.length;
    const firstBlock = selectedBlocks[0];
    const lastBlock = selectedBlocks[length - 1];
    const selectedModels = selectedBlocks.map((block)=>block.model);
    return {
        textSelection,
        selectedModels,
        firstBlock,
        lastBlock
    };
}
function asCaption(host, id) {
    return {
        name: 'Use as caption',
        icon: AIPenIcon,
        showWhen: ()=>{
            const panel = getAIPanel(host);
            return id === 'generateCaption' && !!panel.answer;
        },
        handler: ()=>{
            reportResponse('result:use-as-caption');
            const panel = getAIPanel(host);
            const caption = panel.answer;
            if (!caption) return;
            const { selectedBlocks } = getSelections(host);
            if (!selectedBlocks || selectedBlocks.length !== 1) return;
            const imageBlock = selectedBlocks[0].model;
            if (!(imageBlock instanceof ImageBlockModel)) return;
            host.doc.updateBlock(imageBlock, {
                caption
            });
            panel.hide();
        }
    };
}
function createNewNote(host) {
    return {
        name: 'Create new note',
        icon: CreateIcon,
        showWhen: ()=>{
            const panel = getAIPanel(host);
            return !!panel.answer && isInsideEdgelessEditor(host);
        },
        handler: ()=>{
            reportResponse('result:add-note');
            const { selectedBlocks } = getSelections(host);
            if (!selectedBlocks || !selectedBlocks.length) return;
            const firstBlock = selectedBlocks[0];
            const noteModel = findNoteBlockModel(firstBlock);
            if (!noteModel) return;
            const bound = Bound.deserialize(noteModel.xywh);
            const newBound = new Bound(bound.x - bound.w - 20, bound.y, bound.w, 72);
            const doc = host.doc;
            const panel = getAIPanel(host);
            const service = getService(host);
            doc.transact(()=>{
                assertExists(doc.root);
                const noteBlockId = doc.addBlock('affine:note', {
                    xywh: newBound.serialize(),
                    displayMode: NoteDisplayMode.EdgelessOnly,
                    index: service.generateIndex()
                }, doc.root.id);
                assertExists(panel.answer);
                insertFromMarkdown(host, panel.answer, doc, noteBlockId).then(()=>{
                    service.selection.set({
                        elements: [
                            noteBlockId
                        ],
                        editing: false
                    });
                    const newNote = doc.getBlock(noteBlockId)?.model;
                    if (!newNote || !matchFlavours(newNote, [
                        'affine:note'
                    ])) return;
                    const newNoteBound = Bound.deserialize(newNote.xywh);
                    const bounds = [
                        bound,
                        newNoteBound
                    ];
                    const { zoom, centerX, centerY } = service.getFitToScreenData([
                        20,
                        20,
                        20,
                        20
                    ], bounds);
                    service.viewport.setViewport(zoom, [
                        centerX,
                        centerY
                    ]);
                }).catch((err)=>{
                    console.error(err);
                });
            });
            panel.hide();
        }
    };
}
async function replaceWithAnswer(panel) {
    const { host } = panel;
    const selection = getSelection(host);
    if (!selection || !panel.answer) return;
    const { textSelection, firstBlock, selectedModels } = selection;
    await replace(host, panel.answer, firstBlock, selectedModels, textSelection);
    panel.hide();
}
async function insertAnswerBelow(panel) {
    const { host } = panel;
    const selection = getSelection(host);
    if (!selection || !panel.answer) {
        return;
    }
    const { lastBlock } = selection;
    await insertBelow(host, panel.answer, lastBlock);
    panel.hide();
}
async function insertAnswerAbove(panel) {
    const { host } = panel;
    const selection = getSelection(host);
    if (!selection || !panel.answer) return;
    const { firstBlock } = selection;
    await insertAbove(host, panel.answer, firstBlock);
    panel.hide();
}
export function buildTextResponseConfig(panel, id) {
    const host = panel.host;
    return [
        {
            name: 'Response',
            items: [
                {
                    name: 'Insert below',
                    icon: InsertBelowIcon,
                    showWhen: ()=>!!panel.answer && (!id || !INSERT_ABOVE_ACTIONS.includes(id)),
                    handler: ()=>{
                        reportResponse('result:insert');
                        insertAnswerBelow(panel).catch(console.error);
                    }
                },
                {
                    name: 'Insert above',
                    icon: InsertTopIcon,
                    showWhen: ()=>!!panel.answer && !!id && INSERT_ABOVE_ACTIONS.includes(id),
                    handler: ()=>{
                        reportResponse('result:insert');
                        insertAnswerAbove(panel).catch(console.error);
                    }
                },
                asCaption(host, id),
                {
                    name: 'Replace selection',
                    icon: ReplaceIcon,
                    showWhen: ()=>!!panel.answer,
                    handler: ()=>{
                        reportResponse('result:replace');
                        replaceWithAnswer(panel).catch(console.error);
                    }
                },
                createNewNote(host)
            ]
        },
        {
            name: '',
            items: [
                {
                    name: 'Continue in chat',
                    icon: ChatWithAIIcon,
                    handler: ()=>{
                        reportResponse('result:continue-in-chat');
                        AIProvider.slots.requestOpenWithChat.emit({
                            host
                        });
                        panel.hide();
                    }
                },
                {
                    name: 'Regenerate',
                    icon: RetryIcon,
                    handler: ()=>{
                        reportResponse('result:retry');
                        panel.generate();
                    }
                },
                {
                    name: 'Discard',
                    icon: DiscardIcon,
                    handler: ()=>{
                        panel.discard();
                    }
                }
            ]
        }
    ];
}
export function buildErrorResponseConfig(panel, id) {
    const host = panel.host;
    return [
        {
            name: 'Response',
            items: [
                {
                    name: 'Replace selection',
                    icon: ReplaceIcon,
                    showWhen: ()=>!!panel.answer,
                    handler: ()=>{
                        replaceWithAnswer(panel).catch(console.error);
                    }
                },
                {
                    name: 'Insert below',
                    icon: InsertBelowIcon,
                    showWhen: ()=>!!panel.answer && (!id || !INSERT_ABOVE_ACTIONS.includes(id)),
                    handler: ()=>{
                        insertAnswerBelow(panel).catch(console.error);
                    }
                },
                {
                    name: 'Insert above',
                    icon: InsertTopIcon,
                    showWhen: ()=>!!panel.answer && !!id && INSERT_ABOVE_ACTIONS.includes(id),
                    handler: ()=>{
                        reportResponse('result:insert');
                        insertAnswerAbove(panel).catch(console.error);
                    }
                },
                asCaption(host, id),
                createNewNote(host)
            ]
        },
        {
            name: '',
            items: [
                {
                    name: 'Retry',
                    icon: RetryIcon,
                    showWhen: ()=>true,
                    handler: ()=>{
                        reportResponse('result:retry');
                        panel.generate();
                    }
                },
                {
                    name: 'Discard',
                    icon: DiscardIcon,
                    showWhen: ()=>!!panel.answer,
                    handler: ()=>{
                        panel.discard();
                    }
                }
            ]
        }
    ];
}
export function buildFinishConfig(panel, id) {
    return {
        responses: buildTextResponseConfig(panel, id),
        actions: []
    };
}
export function buildErrorConfig(panel, id) {
    return {
        upgrade: ()=>{
            AIProvider.slots.requestUpgradePlan.emit({
                host: panel.host
            });
            panel.hide();
        },
        login: ()=>{
            AIProvider.slots.requestLogin.emit({
                host: panel.host
            });
            panel.hide();
        },
        cancel: ()=>{
            panel.hide();
        },
        responses: buildErrorResponseConfig(panel, id)
    };
}
export function buildGeneratingConfig(generatingIcon) {
    return {
        generatingIcon: generatingIcon ?? AIStarIconWithAnimation
    };
}
export function buildCopyConfig(panel) {
    return {
        allowed: true,
        onCopy: ()=>{
            return copyTextAnswer(panel);
        }
    };
}
export function buildAIPanelConfig(panel) {
    return {
        answerRenderer: createTextRenderer(panel.host, {
            maxHeight: 320
        }),
        finishStateConfig: buildFinishConfig(panel),
        generatingStateConfig: buildGeneratingConfig(),
        errorStateConfig: buildErrorConfig(panel),
        copy: buildCopyConfig(panel)
    };
}
export const getAIPanel = (host)=>{
    const rootBlockId = host.doc.root?.id;
    assertExists(rootBlockId);
    const aiPanel = host.view.getWidget(AFFINE_AI_PANEL_WIDGET, rootBlockId);
    assertExists(aiPanel);
    if (!(aiPanel instanceof AffineAIPanelWidget)) {
        throw new Error('AI panel not found');
    }
    return aiPanel;
};
