import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, IconButton } from '@affine/component';
import { SettingHeader } from '@affine/component/setting-components';
import { openSettingModalAtom } from '@affine/core/components/atoms';
import { useI18n } from '@affine/i18n';
import { ArrowRightBigIcon, ArrowUpSmallIcon } from '@blocksuite/icons/rc';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import { settingModalScrollContainerAtom } from '../../atoms';
import * as styles from './layout.css';
export const SeeAllLink = ()=>{
    const t = useI18n();
    return _jsxs("a", {
        className: styles.allPlansLink,
        href: "https://wemem.ai/pricing",
        target: "_blank",
        rel: "noopener noreferrer",
        children: [
            t['com.affine.payment.see-all-plans'](),
            _jsx(ArrowRightBigIcon, {
                width: "16",
                height: "16"
            })
        ]
    });
};
export const PricingCollapsible = ({ title, caption, children })=>{
    const [open, setOpen] = useState(true);
    const toggle = useCallback(()=>setOpen((prev)=>!prev), []);
    return _jsxs(Collapsible.Root, {
        open: open,
        onOpenChange: setOpen,
        children: [
            _jsxs("section", {
                className: styles.collapsibleHeader,
                children: [
                    _jsxs("div", {
                        className: styles.collapsibleHeaderContent,
                        children: [
                            _jsx("div", {
                                className: styles.collapsibleHeaderTitle,
                                children: title
                            }),
                            _jsx("div", {
                                className: styles.collapsibleHeaderCaption,
                                children: caption
                            })
                        ]
                    }),
                    _jsx(IconButton, {
                        onClick: toggle,
                        size: "20",
                        children: _jsx(ArrowUpSmallIcon, {
                            style: {
                                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 0.23s ease'
                            }
                        })
                    })
                ]
            }),
            _jsx(Collapsible.Content, {
                children: children
            })
        ]
    });
};
export const PlanLayout = ({ cloud, ai, cloudTip })=>{
    const t = useI18n();
    const [modal, setOpenSettingModal] = useAtom(openSettingModalAtom);
    const scrollAnchor = modal.activeTab === 'plans' ? modal.scrollAnchor : null;
    const plansRootRef = useRef(null);
    const cloudScrollTipRef = useRef(null);
    const settingModalScrollContainer = useAtomValue(settingModalScrollContainerAtom);
    const updateCloudTipState = useCallback(()=>{
        if (!cloudTip) return;
        const cloudContainer = plansRootRef.current?.querySelector('#cloudPricingPlan');
        if (!settingModalScrollContainer || !cloudContainer) return;
        const minVisibleHeight = 30;
        const containerRect = settingModalScrollContainer.getBoundingClientRect();
        const cloudTop = cloudContainer.getBoundingClientRect().top - containerRect.top;
        const cloudIntoView = cloudTop < containerRect.height - minVisibleHeight;
        if (cloudIntoView) {
            settingModalScrollContainer.dataset.cloudVisible = '';
        }
    }, [
        cloudTip,
        settingModalScrollContainer
    ]);
    useLayoutEffect(()=>{
        if (!scrollAnchor) return;
        flushSync(()=>{
            const target = plansRootRef.current?.querySelector(`#${scrollAnchor}`);
            if (target) {
                target.scrollIntoView();
                setOpenSettingModal((prev)=>({
                        ...prev,
                        scrollAnchor: undefined
                    }));
            }
        });
    }, [
        scrollAnchor,
        setOpenSettingModal
    ]);
    useEffect(()=>{
        if (!settingModalScrollContainer || !cloudScrollTipRef.current) return;
        settingModalScrollContainer.addEventListener('scroll', updateCloudTipState);
        updateCloudTipState();
        return ()=>{
            settingModalScrollContainer.removeEventListener('scroll', updateCloudTipState);
        };
    }, [
        settingModalScrollContainer,
        updateCloudTipState
    ]);
    const scrollToAnchor = useCallback((anchor)=>{
        const target = plansRootRef.current?.querySelector(`#${anchor}`);
        target && target.scrollIntoView({
            behavior: 'smooth'
        });
    }, []);
    return _jsxs("div", {
        className: styles.plansLayoutRoot,
        ref: plansRootRef,
        children: [
            _jsx(SettingHeader, {
                style: {
                    marginBottom: '0px'
                },
                title: t['com.affine.payment.title']()
            }),
            ai ? _jsxs(_Fragment, {
                children: [
                    _jsx("div", {
                        id: "aiPricingPlan",
                        children: ai
                    }),
                    _jsx(Divider, {
                        className: styles.aiDivider
                    })
                ]
            }) : null,
            _jsx("div", {
                id: "cloudPricingPlan",
                children: cloud
            }),
            cloudTip && settingModalScrollContainer ? createPortal(_jsxs("div", {
                className: styles.aiScrollTip,
                ref: cloudScrollTipRef,
                children: [
                    _jsxs("div", {
                        children: [
                            _jsx("div", {
                                className: styles.cloudScrollTipTitle,
                                children: t['com.affine.cloud-scroll-tip.title']()
                            }),
                            _jsx("div", {
                                className: styles.cloudScrollTipCaption,
                                children: t['com.affine.cloud-scroll-tip.caption']()
                            })
                        ]
                    }),
                    _jsx(Button, {
                        onClick: ()=>scrollToAnchor('cloudPricingPlan'),
                        variant: "primary",
                        children: t['com.affine.ai-scroll-tip.view']()
                    })
                ]
            }), settingModalScrollContainer, 'aiScrollTip') : null
        ]
    });
};
export const CloudPlanLayout = ({ title = 'Wemem Cloud', caption, select, toggle, scroll, lifetime, scrollRef })=>{
    return _jsxs(PricingCollapsible, {
        title: title,
        caption: caption,
        children: [
            _jsxs("div", {
                className: styles.affineCloudHeader,
                children: [
                    _jsx("div", {
                        children: select
                    }),
                    _jsx("div", {
                        children: toggle
                    })
                ]
            }),
            _jsxs(ScrollArea.Root, {
                children: [
                    _jsx(ScrollArea.Viewport, {
                        ref: scrollRef,
                        className: styles.scrollArea,
                        children: scroll
                    }),
                    _jsx(ScrollArea.Scrollbar, {
                        forceMount: true,
                        orientation: "horizontal",
                        className: styles.scrollBar,
                        children: _jsx(ScrollArea.Thumb, {
                            className: styles.scrollThumb
                        })
                    })
                ]
            }),
            lifetime ? _jsx("div", {
                style: {
                    paddingTop: 12
                },
                id: "lifetimePricingPlan",
                children: lifetime
            }) : null
        ]
    });
};
