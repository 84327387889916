import { AIEdgelessRootBlockSpec, AIPageRootBlockSpec } from '@affine/core/blocksuite/presets/ai';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { mixpanel } from '@affine/track';
import { ConfigExtension } from '@blocksuite/affine/block-std';
import { EdgelessRootBlockSpec, EditorSettingExtension, FontLoaderService, PageRootBlockSpec } from '@blocksuite/affine/blocks';
import { TelemetryProvider } from '@blocksuite/affine/blocks';
import { getFontConfigExtension } from '../font-extension';
import { createDatabaseOptionsConfig } from './database-block';
import { createLinkedWidgetConfig } from './widgets/linked';
import { createToolbarMoreMenuConfig } from './widgets/toolbar';
function getTelemetryExtension() {
    return {
        setup: (di)=>{
            di.addImpl(TelemetryProvider, ()=>({
                    track: (eventName, props)=>{
                        mixpanel.track(eventName, props);
                    }
                }));
        }
    };
}
function getEditorConfigExtension(framework) {
    const editorSettingService = framework.get(EditorSettingService);
    return [
        EditorSettingExtension(editorSettingService.editorSetting.settingSignal),
        ConfigExtension('affine:page', {
            linkedWidget: createLinkedWidgetConfig(framework),
            toolbarMoreMenu: createToolbarMoreMenuConfig(framework),
            databaseOptions: createDatabaseOptionsConfig(framework)
        })
    ];
}
export function createPageRootBlockSpec(framework, enableAI) {
    return [
        enableAI ? AIPageRootBlockSpec : PageRootBlockSpec,
        FontLoaderService,
        getFontConfigExtension(),
        getTelemetryExtension(),
        getEditorConfigExtension(framework)
    ].flat();
}
export function createEdgelessRootBlockSpec(framework, enableAI) {
    return [
        enableAI ? AIEdgelessRootBlockSpec : EdgelessRootBlockSpec,
        FontLoaderService,
        getFontConfigExtension(),
        getTelemetryExtension(),
        getEditorConfigExtension(framework)
    ].flat();
}
