function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init__curFocusIndex, _init__filterInput, _init__filterText, _init__focussedItem, _init_abortController, _init_listFilter, _init_options, _init_placement, _initProto;
import { createLitPortal } from '@blocksuite/affine-components/portal';
import { WithDisposable } from '@blocksuite/global/utils';
import { DoneIcon, SearchIcon } from '@blocksuite/icons/lit';
import { autoPlacement, offset, size } from '@floating-ui/dom';
import { html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { PAGE_HEADER_HEIGHT } from '../../consts.js';
import { filterableListStyles } from './styles.js';
export * from './types.js';
_dec = state(), _dec1 = query('#filter-input'), _dec2 = state(), _dec3 = query('.filterable-item.focussed'), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
export class FilterableListComponent extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__curFocusIndex, _init__filterInput, _init__filterText, _init__focussedItem, _init_abortController, _init_listFilter, _init_options, _init_placement, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_curFocusIndex"
            ],
            [
                _dec1,
                1,
                "_filterInput"
            ],
            [
                _dec2,
                1,
                "_filterText"
            ],
            [
                _dec3,
                1,
                "_focussedItem"
            ],
            [
                _dec4,
                1,
                "abortController"
            ],
            [
                _dec5,
                1,
                "listFilter"
            ],
            [
                _dec6,
                1,
                "options"
            ],
            [
                _dec7,
                1,
                "placement"
            ]
        ], []));
    }
    static{
        this.styles = filterableListStyles;
    }
    _buildContent(items) {
        return items.map((item, idx)=>{
            const focussed = this._curFocusIndex === idx;
            return html`
        <icon-button
          class=${classMap({
                'filterable-item': true,
                focussed
            })}
          @mouseover=${()=>this._curFocusIndex = idx}
          @click=${()=>this._select(item)}
          hover=${focussed}
          width="100%"
          height="32px"
        >
          ${item.icon ?? nothing} ${item.label ?? item.name}
          <div slot="suffix">
            ${this.options.active?.(item) ? DoneIcon() : nothing}
          </div>
        </icon-button>
      `;
        });
    }
    _filterItems() {
        const searchFilter = !this._filterText ? this.options.items : this.options.items.filter((item)=>item.name.startsWith(this._filterText.toLowerCase()) || item.aliases?.some((alias)=>alias.startsWith(this._filterText.toLowerCase())));
        return searchFilter.sort((a, b)=>{
            const isActiveA = this.options.active?.(a);
            const isActiveB = this.options.active?.(b);
            if (isActiveA && !isActiveB) return -Infinity;
            if (!isActiveA && isActiveB) return Infinity;
            return this.listFilter?.(a, b) ?? Infinity;
        });
    }
    _scrollFocusedItemIntoView() {
        this.updateComplete.then(()=>{
            this._focussedItem?.scrollIntoView({
                block: 'nearest',
                inline: 'start'
            });
        }).catch(console.error);
    }
    _select(item) {
        this.abortController?.abort();
        this.options.onSelect(item);
    }
    connectedCallback() {
        super.connectedCallback();
        requestAnimationFrame(()=>{
            this._filterInput.focus();
        });
    }
    render() {
        const filteredItems = this._filterItems();
        const content = this._buildContent(filteredItems);
        const isFlip = !!this.placement?.startsWith('top');
        const _handleInputKeydown = (ev)=>{
            switch(ev.key){
                case 'ArrowUp':
                    {
                        ev.preventDefault();
                        this._curFocusIndex = (this._curFocusIndex + content.length - 1) % content.length;
                        this._scrollFocusedItemIntoView();
                        break;
                    }
                case 'ArrowDown':
                    {
                        ev.preventDefault();
                        this._curFocusIndex = (this._curFocusIndex + 1) % content.length;
                        this._scrollFocusedItemIntoView();
                        break;
                    }
                case 'Enter':
                    {
                        if (ev.isComposing) break;
                        ev.preventDefault();
                        const item = filteredItems[this._curFocusIndex];
                        this._select(item);
                        break;
                    }
                case 'Escape':
                    {
                        ev.preventDefault();
                        this.abortController?.abort();
                        break;
                    }
            }
        };
        return html`
      <div
        class=${classMap({
            'affine-filterable-list': true,
            flipped: isFlip
        })}
      >
        <div class="input-wrapper">
          ${SearchIcon()}
          <input
            id="filter-input"
            type="text"
            placeholder=${this.options?.placeholder ?? 'Search'}
            @input="${()=>{
            this._filterText = this._filterInput?.value;
            this._curFocusIndex = 0;
        }}"
            @keydown="${_handleInputKeydown}"
          />
        </div>

        <editor-toolbar-separator
          data-orientation="horizontal"
        ></editor-toolbar-separator>
        <div class="items-container">${content}</div>
      </div>
    `;
    }
    #___private__curFocusIndex_1;
    get _curFocusIndex() {
        return this.#___private__curFocusIndex_1;
    }
    set _curFocusIndex(_v) {
        this.#___private__curFocusIndex_1 = _v;
    }
    #___private__filterInput_2;
    get _filterInput() {
        return this.#___private__filterInput_2;
    }
    set _filterInput(_v) {
        this.#___private__filterInput_2 = _v;
    }
    #___private__filterText_3;
    get _filterText() {
        return this.#___private__filterText_3;
    }
    set _filterText(_v) {
        this.#___private__filterText_3 = _v;
    }
    #___private__focussedItem_4;
    get _focussedItem() {
        return this.#___private__focussedItem_4;
    }
    set _focussedItem(_v) {
        this.#___private__focussedItem_4 = _v;
    }
    #___private_abortController_5;
    get abortController() {
        return this.#___private_abortController_5;
    }
    set abortController(_v) {
        this.#___private_abortController_5 = _v;
    }
    #___private_listFilter_6;
    get listFilter() {
        return this.#___private_listFilter_6;
    }
    set listFilter(_v) {
        this.#___private_listFilter_6 = _v;
    }
    #___private_options_7;
    get options() {
        return this.#___private_options_7;
    }
    set options(_v) {
        this.#___private_options_7 = _v;
    }
    #___private_placement_8;
    get placement() {
        return this.#___private_placement_8;
    }
    set placement(_v) {
        this.#___private_placement_8 = _v;
    }
    constructor(...args){
        super(...args), this.#___private__curFocusIndex_1 = (_initProto(this), _init__curFocusIndex(this, 0)), this.#___private__filterInput_2 = _init__filterInput(this), this.#___private__filterText_3 = _init__filterText(this, ''), this.#___private__focussedItem_4 = _init__focussedItem(this), this.#___private_abortController_5 = _init_abortController(this, null), this.#___private_listFilter_6 = _init_listFilter(this, undefined), this.#___private_options_7 = _init_options(this), this.#___private_placement_8 = _init_placement(this, undefined);
    }
}
export function showPopFilterableList({ options, filter, abortController = new AbortController(), referenceElement, container, maxHeight = 440, portalStyles }) {
    const portalPadding = {
        top: PAGE_HEADER_HEIGHT + 12,
        bottom: 12
    };
    const list = new FilterableListComponent();
    list.options = options;
    list.listFilter = filter;
    list.abortController = abortController;
    createLitPortal({
        closeOnClickAway: true,
        template: ({ positionSlot })=>{
            positionSlot.on(({ placement })=>{
                list.placement = placement;
            });
            return list;
        },
        container,
        portalStyles,
        computePosition: {
            referenceElement,
            placement: 'bottom-start',
            middleware: [
                offset(4),
                autoPlacement({
                    allowedPlacements: [
                        'top-start',
                        'bottom-start'
                    ],
                    padding: portalPadding
                }),
                size({
                    padding: portalPadding,
                    apply ({ availableHeight, elements, placement }) {
                        Object.assign(elements.floating.style, {
                            height: '100%',
                            maxHeight: `${Math.min(maxHeight, availableHeight)}px`,
                            pointerEvents: 'none',
                            ...placement.startsWith('top') ? {
                                display: 'flex',
                                alignItems: 'flex-end'
                            } : {
                                display: null,
                                alignItems: null
                            }
                        });
                    }
                })
            ],
            autoUpdate: {
                animationFrame: true
            }
        },
        abortController
    });
}
