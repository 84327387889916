import { computed, signal } from '@preact/signals-core';
import { CellBase } from './cell.js';
import { RowBase } from './row.js';
export class SingleViewBase {
    get dataSource() {
        return this.manager.dataSource;
    }
    get featureFlags$() {
        return this.dataSource.featureFlags$;
    }
    get meta() {
        return this.dataSource.viewMetaGet(this.type);
    }
    get propertyMetas() {
        return this.dataSource.propertyMetas;
    }
    constructor(manager, id){
        this.manager = manager;
        this.id = id;
        this.searchString = signal('');
        this.data$ = computed(()=>{
            return this.dataSource.viewDataGet(this.id);
        });
        this.filterVisible$ = computed(()=>{
            return (this.filter$.value?.conditions.length ?? 0) > 0;
        });
        this.name$ = computed(()=>{
            return this.data$.value?.name ?? '';
        });
        this.properties$ = computed(()=>{
            return this.propertyIds$.value.map((id)=>this.propertyGet(id));
        });
        this.rows$ = computed(()=>{
            return this.filteredRows(this.searchString.value);
        });
        this.vars$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.map((id)=>{
                const v = this.propertyGet(id);
                const propertyMeta = this.dataSource.propertyMetaGet(v.type$.value);
                return {
                    id: v.id,
                    name: v.name$.value,
                    type: propertyMeta.config.type(v.data$.value),
                    icon: v.icon
                };
            });
        });
    }
    filteredRows(searchString) {
        return this.dataSource.rows$.value.filter((id)=>{
            if (searchString) {
                const containsSearchString = this.propertyIds$.value.some((propertyId)=>{
                    return this.cellStringValueGet(id, propertyId)?.toLowerCase().includes(searchString?.toLowerCase());
                });
                if (!containsSearchString) {
                    return false;
                }
            }
            return this.isShow(id);
        });
    }
    cellGet(rowId, propertyId) {
        return new CellBase(this, propertyId, rowId);
    }
    cellJsonValueGet(rowId, propertyId) {
        const type = this.propertyTypeGet(propertyId);
        if (!type) {
            return;
        }
        return this.dataSource.propertyMetaGet(type).config.cellToJson(this.dataSource.cellValueGet(rowId, propertyId), this.propertyDataGet(propertyId));
    }
    cellRenderValueSet(rowId, propertyId, value) {
        this.dataSource.cellValueChange(rowId, propertyId, value);
    }
    cellStringValueGet(rowId, propertyId) {
        const type = this.propertyTypeGet(propertyId);
        if (!type) {
            return;
        }
        return this.dataSource.propertyMetaGet(type).config.cellToString(this.dataSource.cellValueGet(rowId, propertyId), this.propertyDataGet(propertyId)) ?? '';
    }
    cellValueGet(rowId, propertyId) {
        const type = this.propertyTypeGet(propertyId);
        if (!type) {
            return;
        }
        const cellValue = this.dataSource.cellValueGet(rowId, propertyId);
        return this.dataSource.propertyMetaGet(type).config.formatValue?.(cellValue, this.propertyDataGet(propertyId)) ?? cellValue;
    }
    cellValueSet(rowId, propertyId, value) {
        this.dataSource.cellValueChange(rowId, propertyId, value);
    }
    contextGet(key) {
        return this.dataSource.contextGet(key);
    }
    dataUpdate(updater) {
        this.dataSource.viewDataUpdate(this.id, updater);
    }
    delete() {
        this.manager.viewDelete(this.id);
    }
    duplicate() {
        this.manager.viewDuplicate(this.id);
    }
    IconGet(type) {
        return this.dataSource.propertyMetaGet(type).renderer.icon;
    }
    nameSet(name) {
        this.dataUpdate(()=>{
            return {
                name
            };
        });
    }
    propertyAdd(position, type) {
        const id = this.dataSource.propertyAdd(position, type);
        this.propertyMove(id, position);
        return id;
    }
    propertyDataGet(propertyId) {
        return this.dataSource.propertyDataGet(propertyId);
    }
    propertyDataSet(propertyId, data) {
        this.dataSource.propertyDataSet(propertyId, data);
    }
    propertyDataTypeGet(propertyId) {
        const type = this.propertyTypeGet(propertyId);
        if (!type) {
            return;
        }
        return this.dataSource.propertyMetaGet(type).config.type(this.propertyDataGet(propertyId));
    }
    propertyDelete(propertyId) {
        this.dataSource.propertyDelete(propertyId);
    }
    propertyDuplicate(propertyId) {
        const id = this.dataSource.propertyDuplicate(propertyId);
        this.propertyMove(id, {
            before: false,
            id: propertyId
        });
    }
    propertyIdGetByIndex(index) {
        return this.propertyIds$.value[index];
    }
    propertyIndexGet(propertyId) {
        return this.propertyIds$.value.indexOf(propertyId);
    }
    propertyMetaGet(type) {
        return this.dataSource.propertyMetaGet(type);
    }
    propertyNameGet(propertyId) {
        return this.dataSource.propertyNameGet(propertyId);
    }
    propertyNameSet(propertyId, name) {
        this.dataSource.propertyNameSet(propertyId, name);
    }
    propertyNextGet(propertyId) {
        return this.propertyGet(this.propertyIdGetByIndex(this.propertyIndexGet(propertyId) + 1));
    }
    propertyParseValueFromString(propertyId, cellData) {
        const type = this.propertyTypeGet(propertyId);
        if (!type) {
            return;
        }
        return this.dataSource.propertyMetaGet(type).config.cellFromString(cellData, this.propertyDataGet(propertyId)) ?? '';
    }
    propertyPreGet(propertyId) {
        return this.propertyGet(this.propertyIdGetByIndex(this.propertyIndexGet(propertyId) - 1));
    }
    propertyReadonlyGet(propertyId) {
        return this.dataSource.propertyReadonlyGet(propertyId);
    }
    propertyTypeGet(propertyId) {
        return this.dataSource.propertyTypeGet(propertyId);
    }
    propertyTypeSet(propertyId, type) {
        this.dataSource.propertyTypeSet(propertyId, type);
    }
    rowAdd(insertPosition) {
        return this.dataSource.rowAdd(insertPosition);
    }
    rowDelete(ids) {
        this.dataSource.rowDelete(ids);
    }
    rowGet(rowId) {
        return new RowBase(this, rowId);
    }
    rowMove(rowId, position) {
        this.dataSource.rowMove(rowId, position);
    }
    setSearch(str) {
        this.searchString.value = str;
    }
}
