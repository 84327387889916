import { Service } from '../../../framework';
export class WorkspaceDestroyService extends Service {
    constructor(providers){
        super(), this.providers = providers, this.deleteWorkspace = async (metadata)=>{
            const provider = this.providers.find((p)=>p.flavour === metadata.flavour);
            if (!provider) {
                throw new Error(`Unknown workspace flavour: ${metadata.flavour}`);
            }
            return provider.deleteWorkspace(metadata.id);
        };
    }
}
