import { Entity, LiveData } from '@toeverything/infra';
export class CreateWorkspaceDialog extends Entity {
    open(mode, callback) {
        this.callback(undefined);
        this.mode$.next(mode);
        this.isOpen$.next(true);
        if (callback) {
            this.callback$.next(callback);
        }
    }
    callback(payload) {
        this.callback$.value(payload);
        this.callback$.next(()=>{});
    }
    close() {
        this.isOpen$.next(false);
        this.callback(undefined);
    }
    constructor(...args){
        super(...args), this.mode$ = new LiveData('new'), this.isOpen$ = new LiveData(false), this.callback$ = new LiveData(()=>{});
    }
}
