import { EmbedEdgelessBlockComponent } from './embed-figma-block/embed-edgeless-figma-block.js';
import { EmbedFigmaBlockComponent } from './embed-figma-block/index.js';
import { EmbedEdgelessGithubBlockComponent } from './embed-github-block/embed-edgeless-github-block.js';
import { EmbedGithubBlockComponent } from './embed-github-block/index.js';
import { EmbedHtmlFullscreenToolbar } from './embed-html-block/components/fullscreen-toolbar.js';
import { EmbedEdgelessHtmlBlockComponent } from './embed-html-block/embed-edgeless-html-block.js';
import { EmbedHtmlBlockComponent } from './embed-html-block/index.js';
import { EmbedEdgelessLinkedDocBlockComponent } from './embed-linked-doc-block/embed-edgeless-linked-doc-block.js';
import { EmbedLinkedDocBlockComponent } from './embed-linked-doc-block/index.js';
import { EmbedEdgelessLoomBlockComponent } from './embed-loom-block/embed-edgeless-loom-bock.js';
import { EmbedLoomBlockComponent } from './embed-loom-block/index.js';
import { EmbedSyncedDocCard } from './embed-synced-doc-block/components/embed-synced-doc-card.js';
import { EmbedEdgelessSyncedDocBlockComponent } from './embed-synced-doc-block/embed-edgeless-synced-doc-block.js';
import { EmbedSyncedDocBlockComponent } from './embed-synced-doc-block/index.js';
import { EmbedEdgelessYoutubeBlockComponent } from './embed-youtube-block/embed-edgeless-youtube-block.js';
import { EmbedYoutubeBlockComponent } from './embed-youtube-block/index.js';
export function effects() {
    customElements.define('affine-embed-edgeless-figma-block', EmbedEdgelessBlockComponent);
    customElements.define('affine-embed-figma-block', EmbedFigmaBlockComponent);
    customElements.define('affine-embed-html-block', EmbedHtmlBlockComponent);
    customElements.define('affine-embed-edgeless-html-block', EmbedEdgelessHtmlBlockComponent);
    customElements.define('embed-html-fullscreen-toolbar', EmbedHtmlFullscreenToolbar);
    customElements.define('affine-embed-edgeless-github-block', EmbedEdgelessGithubBlockComponent);
    customElements.define('affine-embed-github-block', EmbedGithubBlockComponent);
    customElements.define('affine-embed-edgeless-youtube-block', EmbedEdgelessYoutubeBlockComponent);
    customElements.define('affine-embed-youtube-block', EmbedYoutubeBlockComponent);
    customElements.define('affine-embed-edgeless-loom-block', EmbedEdgelessLoomBlockComponent);
    customElements.define('affine-embed-loom-block', EmbedLoomBlockComponent);
    customElements.define('affine-embed-synced-doc-card', EmbedSyncedDocCard);
    customElements.define('affine-embed-edgeless-linked-doc-block', EmbedEdgelessLinkedDocBlockComponent);
    customElements.define('affine-embed-linked-doc-block', EmbedLinkedDocBlockComponent);
    customElements.define('affine-embed-edgeless-synced-doc-block', EmbedEdgelessSyncedDocBlockComponent);
    customElements.define('affine-embed-synced-doc-block', EmbedSyncedDocBlockComponent);
}
