import React from 'react';
export const AsyncCallbackContext = React.createContext((e)=>{
    console.error(e);
});
export function useAsyncCallback(callback, deps) {
    const handleAsyncError = React.useContext(AsyncCallbackContext);
    return React.useCallback((...args)=>{
        callback(...args).catch((e)=>handleAsyncError(e));
    }, [
        ...deps
    ]);
}
