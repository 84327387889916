function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init_allowInteractive, _init_arrow, _init_autoFlip, _init_hoverOptions, _init_offset, _init_placement, _init_tooltipStyle, _init_zIndex, _initProto;
import { assertExists } from '@blocksuite/global/utils';
import { arrow, flip, offset } from '@floating-ui/dom';
import { css, html, LitElement, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { HoverController } from '../hover/index.js';
const styles = css`
  .affine-tooltip {
    box-sizing: border-box;
    max-width: 280px;
    min-height: 32px;
    font-family: var(--affine-font-family);
    font-size: var(--affine-font-sm);
    border-radius: 4px;
    padding: 6px 12px;
    color: var(--affine-white);
    background: var(--affine-tooltip);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    position: absolute;

    width: 0;
    height: 0;
  }
`;
const TRIANGLE_HEIGHT = 6;
const triangleMap = {
    top: {
        bottom: '-6px',
        borderStyle: 'solid',
        borderWidth: '6px 5px 0 5px',
        borderColor: 'var(--affine-tooltip) transparent transparent transparent'
    },
    right: {
        left: '-6px',
        borderStyle: 'solid',
        borderWidth: '5px 6px 5px 0',
        borderColor: 'transparent var(--affine-tooltip) transparent transparent'
    },
    bottom: {
        top: '-6px',
        borderStyle: 'solid',
        borderWidth: '0 5px 6px 5px',
        borderColor: 'transparent transparent var(--affine-tooltip) transparent'
    },
    left: {
        right: '-6px',
        borderStyle: 'solid',
        borderWidth: '5px 0 5px 6px',
        borderColor: 'transparent transparent transparent var(--affine-tooltip)'
    }
};
const updateArrowStyles = ({ placement, middlewareData })=>{
    const arrowX = middlewareData.arrow?.x;
    const arrowY = middlewareData.arrow?.y;
    const triangleStyles = triangleMap[placement.split('-')[0]];
    return {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        ...triangleStyles
    };
};
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: 'tip-position'
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
export class Tooltip extends LitElement {
    static{
        ({ e: [_init_allowInteractive, _init_arrow, _init_autoFlip, _init_hoverOptions, _init_offset, _init_placement, _init_tooltipStyle, _init_zIndex, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "allowInteractive"
            ],
            [
                _dec1,
                1,
                "arrow"
            ],
            [
                _dec2,
                1,
                "autoFlip"
            ],
            [
                _dec3,
                1,
                "hoverOptions"
            ],
            [
                _dec4,
                1,
                "offset"
            ],
            [
                _dec5,
                1,
                "placement"
            ],
            [
                _dec6,
                1,
                "tooltipStyle"
            ],
            [
                _dec7,
                1,
                "zIndex"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: none;
    }
  `;
    }
    _getStyles() {
        return css`
      ${styles}
      :host {
        z-index: ${unsafeCSS(this.zIndex)};
        opacity: 0;
        ${unsafeCSS(this.style.cssText)}
      }

      ${this.allowInteractive ? css`` : css`
            :host {
              pointer-events: none;
            }
          `}

      ${this.tooltipStyle}
    `;
    }
    connectedCallback() {
        super.connectedCallback();
        this._setUpHoverController();
    }
    getPortal() {
        return this._hoverController.portal;
    }
    #___private_allowInteractive_1;
    get allowInteractive() {
        return this.#___private_allowInteractive_1;
    }
    set allowInteractive(_v) {
        this.#___private_allowInteractive_1 = _v;
    }
    #___private_arrow_2;
    get arrow() {
        return this.#___private_arrow_2;
    }
    set arrow(_v) {
        this.#___private_arrow_2 = _v;
    }
    #___private_autoFlip_3;
    get autoFlip() {
        return this.#___private_autoFlip_3;
    }
    set autoFlip(_v) {
        this.#___private_autoFlip_3 = _v;
    }
    #___private_hoverOptions_4;
    get hoverOptions() {
        return this.#___private_hoverOptions_4;
    }
    set hoverOptions(_v) {
        this.#___private_hoverOptions_4 = _v;
    }
    #___private_offset_5;
    get offset() {
        return this.#___private_offset_5;
    }
    set offset(_v) {
        this.#___private_offset_5 = _v;
    }
    #___private_placement_6;
    get placement() {
        return this.#___private_placement_6;
    }
    set placement(_v) {
        this.#___private_placement_6 = _v;
    }
    #___private_tooltipStyle_7;
    get tooltipStyle() {
        return this.#___private_tooltipStyle_7;
    }
    set tooltipStyle(_v) {
        this.#___private_tooltipStyle_7 = _v;
    }
    #___private_zIndex_8;
    get zIndex() {
        return this.#___private_zIndex_8;
    }
    set zIndex(_v) {
        this.#___private_zIndex_8 = _v;
    }
    constructor(...args){
        super(...args), this._setUpHoverController = ()=>{
            this._hoverController = new HoverController(this, ()=>{
                if (this.hidden) return null;
                let arrowStyles = {};
                return {
                    template: ({ positionSlot, updatePortal })=>{
                        positionSlot.on((data)=>{
                            if (this.arrow) {
                                arrowStyles = updateArrowStyles(data);
                            } else {
                                arrowStyles = {};
                            }
                            updatePortal();
                        });
                        const children = Array.from(this.childNodes).map((node)=>node.cloneNode(true));
                        return html`
              <style>
                ${this._getStyles()}
              </style>
              <div class="affine-tooltip" role="tooltip">${children}</div>
              <div class="arrow" style=${styleMap(arrowStyles)}></div>
            `;
                    },
                    computePosition: (portalRoot)=>({
                            referenceElement: this.parentElement,
                            placement: this.placement,
                            middleware: [
                                this.autoFlip && flip({
                                    padding: 12
                                }),
                                offset((this.arrow ? TRIANGLE_HEIGHT : 0) + this.offset),
                                arrow({
                                    element: portalRoot.shadowRoot.querySelector('.arrow')
                                })
                            ],
                            autoUpdate: true
                        })
                };
            }, {
                leaveDelay: 0,
                safeBridge: false,
                allowMultiple: true,
                ...this.hoverOptions
            });
            const parent = this.parentElement;
            assertExists(parent, 'Tooltip must have a parent element');
            setTimeout(()=>{
                this._hoverController.setReference(parent);
            }, 0);
        }, this.#___private_allowInteractive_1 = (_initProto(this), _init_allowInteractive(this, false)), this.#___private_arrow_2 = _init_arrow(this, true), this.#___private_autoFlip_3 = _init_autoFlip(this, true), this.#___private_hoverOptions_4 = _init_hoverOptions(this, {}), this.#___private_offset_5 = _init_offset(this, 4), this.#___private_placement_6 = _init_placement(this, 'top'), this.#___private_tooltipStyle_7 = _init_tooltipStyle(this, css``), this.#___private_zIndex_8 = _init_zIndex(this, 'var(--affine-z-index-popover)');
    }
}
