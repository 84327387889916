import { NoteDisplayMode } from '@blocksuite/affine-model';
import { EMBED_CARD_HEIGHT } from '@blocksuite/affine-shared/consts';
import { matchFlavours, SpecProvider } from '@blocksuite/affine-shared/utils';
import { BlockStdScope } from '@blocksuite/block-std';
import { assertExists, Bound, getCommonBound } from '@blocksuite/global/utils';
import { BlockViewType } from '@blocksuite/store';
import { render } from 'lit';
export function renderLinkedDocInCard(card) {
    const linkedDoc = card.linkedDoc;
    assertExists(linkedDoc, `Trying to load page ${card.model.pageId} in linked page block, but the page is not found.`);
    renderSurfaceRef(card);
    renderNoteContent(card).catch((e)=>{
        console.error(e);
        card.isError = true;
    });
}
function renderSurfaceRef(card) {
    card.isBannerEmpty = true;
    const surfaceRefService = card.std.getService('affine:surface-ref');
    assertExists(surfaceRefService, `Surface ref service not found.`);
    card.surfaceRefService = surfaceRefService;
    card.cleanUpSurfaceRefRenderer();
    const linkedDoc = card.linkedDoc;
    assertExists(linkedDoc, `Trying to load page ${card.model.pageId} in linked page block, but the page is not found.`);
    card.surfaceRefRenderer = card.surfaceRefService.getRenderer(card.model.id, linkedDoc);
    card.surfaceRefRenderer.slots.mounted.on(()=>{
        if (card.editorMode === 'edgeless') {
            renderEdgelessAbstract(card).catch((e)=>{
                console.error(e);
                card.isError = true;
            });
        } else {
            renderPageAbstract(card).catch((e)=>{
                console.error(e);
                card.isError = true;
            });
        }
    });
    card.surfaceRefRenderer.mount();
}
async function renderPageAbstract(card) {
    const linkedDoc = card.linkedDoc;
    assertExists(linkedDoc, `Trying to load page ${card.model.pageId} in linked page block, but the page is not found.`);
    const notes = getNotesFromDoc(linkedDoc);
    if (!notes) {
        card.isBannerEmpty = true;
        return;
    }
    const target = notes.flatMap((note)=>note.children.filter((child)=>matchFlavours(child, [
                'affine:image'
            ])))[0];
    if (target) {
        await renderImageAbstract(card, target);
        return;
    }
    card.isBannerEmpty = true;
}
async function renderImageAbstract(card, image) {
    const sourceId = image.sourceId;
    if (!sourceId) return;
    const storage = card.linkedDoc?.blobSync;
    if (!storage) return;
    const blob = await storage.get(sourceId);
    if (!blob) return;
    const url = URL.createObjectURL(blob);
    const $img = document.createElement('img');
    $img.src = url;
    await addCover(card, $img);
    card.isBannerEmpty = false;
}
async function addCover(card, cover) {
    const coverContainer = await card.bannerContainer;
    if (!coverContainer) return;
    while(coverContainer.firstChild){
        coverContainer.firstChild.remove();
    }
    if (cover instanceof HTMLElement) {
        coverContainer.append(cover);
    } else {
        render(cover, coverContainer);
    }
}
async function renderEdgelessAbstract(card) {
    const surfaceRefRenderer = card.surfaceRefRenderer;
    assertExists(surfaceRefRenderer, 'Surface ref renderer is not found.');
    const renderer = surfaceRefRenderer.surfaceRenderer;
    const container = document.createElement('div');
    await addCover(card, container);
    renderer.attach(container);
    const bounds = Array.from(surfaceRefRenderer.surfaceModel?.elementModels ?? []).map((element)=>Bound.deserialize(element.xywh));
    const bound = getCommonBound(bounds);
    if (bound) {
        renderer.viewport.onResize();
        renderer.viewport.setViewportByBound(bound);
    } else {
        card.isBannerEmpty = true;
    }
}
async function renderNoteContent(card) {
    card.isNoteContentEmpty = true;
    const doc = card.linkedDoc;
    assertExists(doc, `Trying to load page ${card.model.pageId} in linked page block, but the page is not found.`);
    const notes = getNotesFromDoc(doc);
    if (!notes) {
        return;
    }
    const cardStyle = card.model.style;
    const isHorizontal = cardStyle === 'horizontal';
    const allowFlavours = isHorizontal ? [] : [
        'affine:image'
    ];
    const noteChildren = notes.flatMap((note)=>note.children.filter((model)=>{
            if (matchFlavours(model, allowFlavours)) {
                return true;
            }
            return filterTextModel(model);
        }));
    if (!noteChildren.length) {
        return;
    }
    card.isNoteContentEmpty = false;
    const noteContainer = await card.noteContainer;
    while(noteContainer.firstChild){
        noteContainer.firstChild.remove();
    }
    const noteBlocksContainer = document.createElement('div');
    noteBlocksContainer.classList.add('affine-embed-doc-content-note-blocks');
    noteBlocksContainer.contentEditable = 'false';
    noteContainer.append(noteBlocksContainer);
    if (isHorizontal) {
        noteChildren.splice(1);
    } else {
        const cardHeight = EMBED_CARD_HEIGHT[cardStyle];
        const minSingleBlockHeight = 20;
        const maxBlockCount = Math.floor(cardHeight / minSingleBlockHeight);
        if (noteChildren.length > maxBlockCount) {
            noteChildren.splice(maxBlockCount);
        }
    }
    const childIds = noteChildren.map((child)=>child.id);
    const ids = [];
    childIds.map((block)=>{
        let parent = block;
        while(parent && !ids.includes(parent)){
            ids.push(parent);
            parent = doc.blockCollection.crud.getParent(parent);
        }
    });
    const query = {
        mode: 'strict',
        match: ids.map((id)=>({
                id,
                viewType: BlockViewType.Display
            }))
    };
    const previewDoc = doc.blockCollection.getDoc({
        query
    });
    const previewSpec = SpecProvider.getInstance().getSpec('page:preview');
    const previewStd = new BlockStdScope({
        doc: previewDoc,
        extensions: previewSpec.value
    });
    const previewTemplate = previewStd.render();
    const fragment = document.createDocumentFragment();
    render(previewTemplate, fragment);
    noteBlocksContainer.append(fragment);
    const contentEditableElements = noteBlocksContainer.querySelectorAll('[contenteditable="true"]');
    contentEditableElements.forEach((element)=>{
        element.contentEditable = 'false';
    });
}
function filterTextModel(model) {
    if (matchFlavours(model, [
        'affine:divider'
    ])) {
        return true;
    }
    if (!matchFlavours(model, [
        'affine:paragraph',
        'affine:list'
    ])) {
        return false;
    }
    return !!model.text?.toString().length;
}
export function getNotesFromDoc(doc) {
    const notes = doc.root?.children.filter((child)=>matchFlavours(child, [
            'affine:note'
        ]) && child.displayMode !== NoteDisplayMode.EdgelessOnly);
    if (!notes || !notes.length) {
        return null;
    }
    return notes;
}
export function isEmptyDoc(doc, mode) {
    if (!doc) {
        return true;
    }
    if (mode === 'page') {
        const notes = getNotesFromDoc(doc);
        if (!notes || !notes.length) {
            return true;
        }
        return notes.every((note)=>isEmptyNote(note));
    } else {
        const surface = getSurfaceBlock(doc);
        if (surface?.elementModels.length || doc.blockSize > 2) {
            return false;
        }
        return true;
    }
}
export function isEmptyNote(note) {
    return note.children.every((block)=>{
        return block.flavour === 'affine:paragraph' && (!block.text || block.text.length === 0);
    });
}
function getSurfaceBlock(doc) {
    const blocks = doc.getBlocksByFlavour('affine:surface');
    return blocks.length !== 0 ? blocks[0].model : null;
}
