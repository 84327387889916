import { computed } from '@preact/signals-core';
import { CellBase } from './cell.js';
export class RowBase {
    constructor(singleView, rowId){
        this.singleView = singleView;
        this.rowId = rowId;
        this.cells$ = computed(()=>{
            return this.singleView.propertyIds$.value.map((propertyId)=>{
                return new CellBase(this.singleView, propertyId, this.rowId);
            });
        });
    }
}
