import { tagColors } from '@affine/core/components/affine/page-properties/common';
import { useI18n } from '@affine/i18n';
import { useCallback } from 'react';
export const InternalTagPrefix = 'ai.wemem.internal-tags.';
export const InternalGhostTagPrefix = `${InternalTagPrefix}ghost.`;
export const FeedTag = {
    id: 'Feed',
    value: `${InternalTagPrefix}feed`,
    color: tagColors[5][0],
    createDate: Date.now(),
    updateDate: Date.now()
};
export const WeChatTag = {
    id: 'WeChat',
    value: `${InternalTagPrefix}wechat`,
    color: tagColors[4][0],
    createDate: Date.now(),
    updateDate: Date.now()
};
export const RSSTag = {
    id: 'RSS',
    value: `${InternalTagPrefix}rss`,
    color: tagColors[2][0],
    createDate: Date.now(),
    updateDate: Date.now()
};
export const isInternalTag = (tagName)=>tagName.startsWith(InternalTagPrefix);
export const useTagI18n = ()=>{
    const t = useI18n();
    return useCallback((tagName)=>{
        if (!tagName) {
            return t['Untitled']();
        }
        if (isInternalTag(tagName)) {
            return t[tagName] ? t[tagName]() : tagName;
        }
        return tagName;
    }, [
        t
    ]);
};
export const InternalTags = [
    FeedTag,
    RSSTag
];
export const isGhostTag = (tagId)=>{
    return tagId.includes(InternalGhostTagPrefix);
};
