import { DocScope, DocService, WorkspaceScope } from '@toeverything/infra';
import { DocsSearchService } from '../docs-search';
import { DocBacklinks } from './entities/doc-backlinks';
import { DocLinks } from './entities/doc-links';
import { DocLinksService } from './services/doc-links';
export { DocLinksService } from './services/doc-links';
export function configureDocLinksModule(framework) {
    framework.scope(WorkspaceScope).scope(DocScope).service(DocLinksService).entity(DocBacklinks, [
        DocsSearchService,
        DocService
    ]).entity(DocLinks, [
        DocsSearchService,
        DocService
    ]);
}
