import { effect, Entity, LiveData, onComplete, onStart } from '@toeverything/infra';
import { truncate } from 'lodash-es';
import { EMPTY, map, mergeMap, of, switchMap } from 'rxjs';
export class DocsQuickSearchSession extends Entity {
    constructor(docsSearchService, docsService, docDisplayMetaService){
        super(), this.docsSearchService = docsSearchService, this.docsService = docsService, this.docDisplayMetaService = docDisplayMetaService, this.isIndexerLoading$ = this.docsSearchService.indexer.status$.map(({ remaining })=>{
            return remaining === undefined || remaining > 0;
        }), this.isQueryLoading$ = new LiveData(false), this.isLoading$ = LiveData.computed((get)=>{
            return get(this.isIndexerLoading$) || get(this.isQueryLoading$);
        }), this.query$ = new LiveData(''), this.items$ = new LiveData([]), this.query = effect(switchMap((query)=>{
            let out;
            if (!query) {
                out = of([]);
            } else {
                out = this.docsSearchService.search$(query).pipe(map((docs)=>docs.map((doc)=>{
                        const docRecord = this.docsService.list.doc$(doc.docId).value;
                        return [
                            doc,
                            docRecord
                        ];
                    }).filter((props)=>!!props[1]).map(([doc, docRecord])=>{
                        const { title, icon, updatedDate } = this.docDisplayMetaService.getDocDisplayMeta(docRecord, 'title' in doc ? doc.title : undefined);
                        return {
                            id: 'doc:' + docRecord.id,
                            source: 'docs',
                            group: {
                                id: 'docs',
                                label: {
                                    key: 'com.affine.quicksearch.group.searchfor',
                                    options: {
                                        query: truncate(query)
                                    }
                                },
                                score: 5
                            },
                            label: {
                                title: title,
                                subTitle: doc.blockContent
                            },
                            score: doc.score,
                            icon,
                            timestamp: updatedDate,
                            payload: doc
                        };
                    })));
            }
            return out.pipe(mergeMap((items)=>{
                this.items$.next(items);
                this.isQueryLoading$.next(false);
                return EMPTY;
            }), onStart(()=>{
                this.items$.next([]);
                this.isQueryLoading$.next(true);
            }), onComplete(()=>{}));
        }));
    }
    setQuery(query) {
        this.query$.next(query);
    }
}
