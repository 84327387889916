import { asyncGetRichText } from '@blocksuite/affine-components/rich-text';
export async function onModelTextUpdated(editorHost, model, callback) {
    const richText = await asyncGetRichText(editorHost, model.id);
    if (!richText) {
        console.error('RichText is not ready yet.');
        return;
    }
    await richText.updateComplete;
    const inlineEditor = richText.inlineEditor;
    if (!inlineEditor) {
        console.error('Inline editor is not ready yet.');
        return;
    }
    inlineEditor.slots.renderComplete.once(()=>{
        if (callback) {
            callback(richText);
        }
    });
}
export async function onModelElementUpdated(editorHost, model, callback) {
    const page = model.doc;
    if (!page.root) return;
    const rootComponent = editorHost.view.getBlock(page.root.id);
    if (!rootComponent) return;
    await rootComponent.updateComplete;
    const element = editorHost.view.getBlock(model.id);
    if (element) callback(element);
}
