import { createStore, del, get, keys, set } from 'idb-keyval';
import { bufferToBlob } from '../../utils/buffer-to-blob';
export class IndexedDBBlobStorage {
    constructor(workspaceId){
        this.workspaceId = workspaceId;
        this.name = 'indexeddb';
        this.readonly = false;
        this.db = createStore(`${this.workspaceId}_blob`, 'blob');
        this.mimeTypeDb = createStore(`${this.workspaceId}_blob_mime`, 'blob_mime');
    }
    async get(key) {
        const res = await get(key, this.db);
        if (res) {
            return bufferToBlob(res);
        }
        return null;
    }
    async set(key, value) {
        await set(key, await value.arrayBuffer(), this.db);
        await set(key, value.type, this.mimeTypeDb);
        return key;
    }
    async delete(key) {
        await del(key, this.db);
        await del(key, this.mimeTypeDb);
    }
    async list() {
        return keys(this.db);
    }
}
