import { DebugLogger } from '@affine/debug';
import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, mapInto, onComplete, onStart } from '@toeverything/infra';
import { exhaustMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
const logger = new DebugLogger('affine:workspace-permission');
export class WorkspaceShareSetting extends Entity {
    constructor(workspaceService, store){
        super(), this.workspaceService = workspaceService, this.store = store, this.enableUrlPreview$ = new LiveData(null), this.isLoading$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(exhaustMap(()=>{
            return fromPromise((signal)=>this.store.fetchWorkspaceEnableUrlPreview(this.workspaceService.workspace.id, signal)).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError,
                count: 3
            }), mapInto(this.enableUrlPreview$), catchErrorInto(this.error$, (error)=>{
                logger.error('Failed to fetch enableUrlPreview', error);
            }), onStart(()=>this.isLoading$.setValue(true)), onComplete(()=>this.isLoading$.setValue(false)));
        }));
        this.revalidate();
    }
    async waitForRevalidation(signal) {
        this.revalidate();
        await this.isLoading$.waitFor((isLoading)=>!isLoading, signal);
    }
    async setEnableUrlPreview(enableUrlPreview) {
        await this.store.updateWorkspaceEnableUrlPreview(this.workspaceService.workspace.id, enableUrlPreview);
        await this.waitForRevalidation();
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
