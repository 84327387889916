import { LinkIcon } from '@blocksuite/affine-components/icons';
import { TelemetryProvider } from '@blocksuite/affine-shared/services';
export const buildLinkDenseMenu = (edgeless)=>({
        type: 'action',
        name: 'Link',
        icon: LinkIcon,
        select: ()=>{
            const { insertedLinkType } = edgeless.std.command.exec('insertLinkByQuickSearch');
            insertedLinkType?.then((type)=>{
                const flavour = type?.flavour;
                if (!flavour) return;
                edgeless.std.getOptional(TelemetryProvider)?.track('CanvasElementAdded', {
                    control: 'toolbar:general',
                    page: 'whiteboard editor',
                    module: 'toolbar',
                    type: flavour.split(':')[1]
                });
            }).catch(console.error);
        }
    });
