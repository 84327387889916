import { Service } from '../../../framework';
import { WorkspaceEngine } from '../entities/engine';
export class WorkspaceEngineService extends Service {
    get engine() {
        if (!this._engine) {
            this._engine = this.framework.createEntity(WorkspaceEngine, {
                engineProvider: this.workspaceScope.props.engineProvider
            });
        }
        return this._engine;
    }
    constructor(workspaceScope){
        super(), this.workspaceScope = workspaceScope, this._engine = null;
    }
    dispose() {
        this._engine?.dispose();
        this._engine = null;
        super.dispose();
    }
}
