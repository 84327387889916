import { DEFAULT_SERVICE_VARIANT, ROOT_SCOPE } from './consts.js';
import { DuplicateServiceDefinitionError } from './error.js';
import { parseIdentifier } from './identifier.js';
import { BasicServiceProvider } from './provider.js';
import { stringifyScope } from './scope.js';
export class Container {
    get add() {
        return new ContainerEditor(this).add;
    }
    get addImpl() {
        return new ContainerEditor(this).addImpl;
    }
    static get EMPTY() {
        return new Container();
    }
    get override() {
        return new ContainerEditor(this).override;
    }
    get scope() {
        return new ContainerEditor(this).scope;
    }
    get size() {
        let size = 0;
        for (const [, identifiers] of this.services){
            for (const [, variants] of identifiers){
                size += variants.size;
            }
        }
        return size;
    }
    addFactory(identifier, factory, { scope, override } = {}) {
        const normalizedScope = stringifyScope(scope ?? ROOT_SCOPE);
        const normalizedIdentifier = parseIdentifier(identifier);
        const normalizedVariant = normalizedIdentifier.variant ?? DEFAULT_SERVICE_VARIANT;
        const services = this.services.get(normalizedScope) ?? new Map();
        const variants = services.get(normalizedIdentifier.identifierName) ?? new Map();
        if (variants.has(normalizedVariant) && !override) {
            throw new DuplicateServiceDefinitionError(normalizedIdentifier);
        }
        variants.set(normalizedVariant, factory);
        services.set(normalizedIdentifier.identifierName, variants);
        this.services.set(normalizedScope, services);
    }
    addValue(identifier, value, { scope, override } = {}) {
        this.addFactory(parseIdentifier(identifier), ()=>value, {
            scope,
            override
        });
    }
    clone() {
        const di = new Container();
        for (const [scope, identifiers] of this.services){
            const s = new Map();
            for (const [identifier, variants] of identifiers){
                s.set(identifier, new Map(variants));
            }
            di.services.set(scope, s);
        }
        return di;
    }
    getFactory(identifier, scope = ROOT_SCOPE) {
        return this.services.get(stringifyScope(scope))?.get(identifier.identifierName)?.get(identifier.variant ?? DEFAULT_SERVICE_VARIANT);
    }
    getFactoryAll(identifier, scope = ROOT_SCOPE) {
        return new Map(this.services.get(stringifyScope(scope))?.get(identifier.identifierName));
    }
    provider(scope = ROOT_SCOPE, parent = null) {
        return new BasicServiceProvider(this, scope, parent);
    }
    constructor(){
        this.services = new Map();
    }
}
class ContainerEditor {
    constructor(container){
        this.container = container;
        this.currentScope = ROOT_SCOPE;
        this.add = (cls, ...[deps])=>{
            this.container.addFactory(cls, dependenciesToFactory(cls, deps), {
                scope: this.currentScope
            });
            return this;
        };
        this.addImpl = (identifier, arg2, ...[arg3])=>{
            if (arg2 instanceof Function) {
                this.container.addFactory(identifier, dependenciesToFactory(arg2, arg3), {
                    scope: this.currentScope
                });
            } else {
                this.container.addValue(identifier, arg2, {
                    scope: this.currentScope
                });
            }
            return this;
        };
        this.override = (identifier, arg2, ...[arg3])=>{
            if (arg2 instanceof Function) {
                this.container.addFactory(identifier, dependenciesToFactory(arg2, arg3), {
                    scope: this.currentScope,
                    override: true
                });
            } else {
                this.container.addValue(identifier, arg2, {
                    scope: this.currentScope,
                    override: true
                });
            }
            return this;
        };
        this.scope = (scope)=>{
            this.currentScope = scope;
            return this;
        };
    }
}
function dependenciesToFactory(cls, deps = []) {
    return (provider)=>{
        const args = [];
        for (const dep of deps){
            let isAll;
            let identifier;
            if (Array.isArray(dep)) {
                if (dep.length !== 1) {
                    throw new Error('Invalid dependency');
                }
                isAll = true;
                identifier = dep[0];
            } else {
                isAll = false;
                identifier = dep;
            }
            if (isAll) {
                args.push(Array.from(provider.getAll(identifier).values()));
            } else {
                args.push(provider.get(identifier));
            }
        }
        if (isConstructor(cls)) {
            return new cls(...args, provider);
        } else {
            return cls(...args, provider);
        }
    };
}
function isConstructor(cls) {
    try {
        Reflect.construct(function() {}, [], cls);
        return true;
    } catch  {
        return false;
    }
}
