import { diamond } from './diamond.js';
import { ellipse } from './ellipse.js';
import { rect } from './rect.js';
import { triangle } from './triangle.js';
export const shapeMethods = {
    rect,
    triangle,
    ellipse,
    diamond
};
