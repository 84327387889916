function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init__selectionRect, _init__visible, _init_selectionElem, _initProto;
import { MOUSE_BUTTON, requestConnectedFrame } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { Bound, getElementsBound } from '@blocksuite/global/utils';
import { autoUpdate, computePosition, flip, offset, shift, size } from '@floating-ui/dom';
import { css, html, nothing } from 'lit';
import { query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { AFFINE_AI_PANEL_WIDGET } from '../ai-panel/ai-panel.js';
import { EdgelessCopilotPanel } from '../edgeless-copilot-panel/index.js';
export const AFFINE_EDGELESS_COPILOT_WIDGET = 'affine-edgeless-copilot-widget';
_dec = state(), _dec1 = state(), _dec2 = query('.copilot-selection-rect');
export class EdgelessCopilotWidget extends WidgetComponent {
    static{
        ({ e: [_init__selectionRect, _init__visible, _init_selectionElem, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_selectionRect"
            ],
            [
                _dec1,
                1,
                "_visible"
            ],
            [
                _dec2,
                1,
                "selectionElem"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .copilot-selection-rect {
      position: absolute;
      box-sizing: border-box;
      border-radius: 4px;
      border: 2px dashed var(--affine-brand-color, #1e96eb);
    }
  `;
    }
    get edgeless() {
        return this.block;
    }
    get selectionModelRect() {
        return this._selectionModelRect;
    }
    get selectionRect() {
        return this._selectionRect;
    }
    get visible() {
        return !!(this._visible && this._selectionRect.width && this._selectionRect.height);
    }
    set visible(visible) {
        this._visible = visible;
    }
    _showCopilotPanel() {
        requestConnectedFrame(()=>{
            if (!this._copilotPanel) {
                const panel = new EdgelessCopilotPanel();
                panel.host = this.host;
                panel.groups = this.groups;
                panel.edgeless = this.edgeless;
                this.renderRoot.append(panel);
                this._copilotPanel = panel;
            }
            const referenceElement = this.selectionElem;
            const panel = this._copilotPanel;
            const viewport = this.edgeless.service.viewport;
            if (!referenceElement || !referenceElement.isConnected) return;
            autoUpdate(referenceElement, panel, ()=>{
                computePosition(referenceElement, panel, {
                    placement: 'right-start',
                    middleware: [
                        offset({
                            mainAxis: 16
                        }),
                        flip({
                            mainAxis: true,
                            crossAxis: true,
                            flipAlignment: true
                        }),
                        shift(()=>{
                            const { left, top, width, height } = viewport;
                            return {
                                padding: 20,
                                crossAxis: true,
                                rootBoundary: {
                                    x: left,
                                    y: top,
                                    width,
                                    height: height - 100
                                }
                            };
                        }),
                        size({
                            apply: ({ elements })=>{
                                const { height } = viewport;
                                elements.floating.style.maxHeight = `${height - 140}px`;
                            }
                        })
                    ]
                }).then(({ x, y })=>{
                    panel.style.left = `${x}px`;
                    panel.style.top = `${y}px`;
                }).catch((e)=>{
                    console.warn("Can't compute EdgelessCopilotPanel position", e);
                });
            });
        }, this);
    }
    _updateSelection(rect) {
        this._selectionModelRect = rect;
        const zoom = this.edgeless.service.viewport.zoom;
        const [x, y] = this.edgeless.service.viewport.toViewCoord(rect.left, rect.top);
        const [width, height] = [
            rect.width * zoom,
            rect.height * zoom
        ];
        this._selectionRect = {
            x,
            y,
            width,
            height
        };
    }
    _watchClickOutside() {
        this._clickOutsideOff?.();
        const { width, height } = this._selectionRect;
        if (width && height) {
            this._listenClickOutsideId && cancelAnimationFrame(this._listenClickOutsideId);
            this._listenClickOutsideId = requestConnectedFrame(()=>{
                if (!this.isConnected) {
                    return;
                }
                const off = this.block.dispatcher.add('pointerDown', (ctx)=>{
                    const e = ctx.get('pointerState').raw;
                    const aiPanel = this.host.view.getWidget(AFFINE_AI_PANEL_WIDGET, this.doc.root.id);
                    if (e.button === MOUSE_BUTTON.MAIN && !this.contains(e.target) && (!aiPanel || aiPanel.state === 'hidden')) {
                        off();
                        this._visible = false;
                        this.hideCopilotPanel();
                    }
                });
                this._listenClickOutsideId = null;
                this._clickOutsideOff = off;
            }, this);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const CopilotSelectionTool = this.edgeless.tools.controllers['copilot'];
        this._disposables.add(CopilotSelectionTool.draggingAreaUpdated.on((shouldShowPanel)=>{
            this._visible = true;
            this._updateSelection(CopilotSelectionTool.area);
            if (shouldShowPanel) {
                this._showCopilotPanel();
                this._watchClickOutside();
            } else {
                this.hideCopilotPanel();
            }
        }));
        this._disposables.add(this.edgeless.service.viewport.viewportUpdated.on(()=>{
            if (!this._visible) return;
            this._updateSelection(CopilotSelectionTool.area);
        }));
        this._disposables.add(this.edgeless.slots.edgelessToolUpdated.on(({ type })=>{
            if (!this._visible || type === 'copilot') return;
            this._visible = false;
            this._clickOutsideOff = null;
            this._copilotPanel?.remove();
            this._copilotPanel = null;
        }));
    }
    determineInsertionBounds(width = 800, height = 95) {
        const elements = this.edgeless.service.selection.selectedElements;
        const offsetY = 20 / this.edgeless.service.viewport.zoom;
        const bounds = new Bound(0, 0, width, height);
        if (elements.length) {
            const { x, y, h } = getElementsBound(elements.map((ele)=>ele.elementBound));
            bounds.x = x;
            bounds.y = y + h + offsetY;
        } else {
            const { x, y, height: h } = this.selectionModelRect;
            bounds.x = x;
            bounds.y = y + h + offsetY;
        }
        return bounds;
    }
    hideCopilotPanel() {
        this._copilotPanel?.hide();
        this._copilotPanel = null;
        this._clickOutsideOff = null;
    }
    lockToolbar(disabled) {
        this.edgeless.slots.toolbarLocked.emit(disabled);
    }
    render() {
        if (!this._visible) return nothing;
        const rect = this._selectionRect;
        return html`<div class="affine-edgeless-ai">
      <div
        class="copilot-selection-rect"
        style=${styleMap({
            left: `${rect.x}px`,
            top: `${rect.y}px`,
            width: `${rect.width}px`,
            height: `${rect.height}px`
        })}
      ></div>
    </div>`;
    }
    #___private__selectionRect_1;
    get _selectionRect() {
        return this.#___private__selectionRect_1;
    }
    set _selectionRect(_v) {
        this.#___private__selectionRect_1 = _v;
    }
    #___private__visible_2;
    get _visible() {
        return this.#___private__visible_2;
    }
    set _visible(_v) {
        this.#___private__visible_2 = _v;
    }
    #___private_selectionElem_3;
    get selectionElem() {
        return this.#___private_selectionElem_3;
    }
    set selectionElem(_v) {
        this.#___private_selectionElem_3 = _v;
    }
    constructor(...args){
        super(...args), this._clickOutsideOff = null, this._listenClickOutsideId = null, this.groups = [], this.#___private__selectionRect_1 = (_initProto(this), _init__selectionRect(this, {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        })), this.#___private__visible_2 = _init__visible(this, false), this.#___private_selectionElem_3 = _init_selectionElem(this);
    }
}
