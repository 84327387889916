import { ShapeType } from '@blocksuite/affine-model';
import { SignalWatcher } from '@blocksuite/global/utils';
import { css, html, LitElement } from 'lit';
import { ShapeToolController } from '../../../tools/shape-tool.js';
import { getTooltipWithShortcut } from '../../utils.js';
import { EdgelessToolbarToolMixin } from '../mixins/tool.mixin.js';
export class EdgelessShapeToolButton extends EdgelessToolbarToolMixin(SignalWatcher(LitElement)) {
    static{
        this.styles = css`
    :host {
      display: block;
      width: 100%;
      height: 100%;
    }
    edgeless-toolbar-button,
    .shapes {
      width: 100%;
      height: 64px;
    }
  `;
    }
    _toggleMenu() {
        this.createPopper('edgeless-shape-menu', this, {
            setProps: (ele)=>{
                ele.edgeless = this.edgeless;
                ele.onChange = (shapeName)=>{
                    this.setEdgelessTool({
                        type: this.type,
                        shapeName
                    });
                    this._updateOverlay();
                };
            }
        });
    }
    _updateOverlay() {
        const controller = this.edgeless.tools.currentController;
        if (controller instanceof ShapeToolController) {
            controller.createOverlay();
        }
    }
    render() {
        const { active } = this;
        return html`
      <edgeless-toolbar-button
        class="edgeless-shape-button"
        .tooltip=${this.popper ? '' : getTooltipWithShortcut('Shape', 'S')}
        .tooltipOffset=${5}
        .active=${active}
      >
        <edgeless-toolbar-shape-draggable
          .edgeless=${this.edgeless}
          .toolbarContainer=${this.toolbarContainer}
          class="shapes"
          @click=${this._handleWrapperClick}
          .onShapeClick=${this._handleShapeClick}
        >
        </edgeless-toolbar-shape-draggable>
      </edgeless-toolbar-button>
    `;
    }
    constructor(...args){
        super(...args), this._handleShapeClick = (shape)=>{
            this.setEdgelessTool({
                type: this.type,
                shapeName: shape.name
            });
            if (!this.popper) this._toggleMenu();
        }, this._handleWrapperClick = ()=>{
            if (this.tryDisposePopper()) return;
            this.setEdgelessTool({
                type: this.type,
                shapeName: ShapeType.Rect
            });
            if (!this.popper) this._toggleMenu();
        }, this.type = 'shape';
    }
}
