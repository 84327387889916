export class ClockMap {
    constructor(map){
        this.map = map;
        this.max = 0;
        for (const value of map.values()){
            if (value > this.max) {
                this.max = value;
            }
        }
    }
    get(id) {
        return this.map.get(id) ?? 0;
    }
    set(id, value) {
        this.map.set(id, value);
        if (value > this.max) {
            this.max = value;
        }
    }
    setIfBigger(id, value) {
        if (value > this.get(id)) {
            this.set(id, value);
        }
    }
    clear() {
        this.map.clear();
        this.max = 0;
    }
}
