import { BlockModel, defineBlockSchema, nanoid } from '@blocksuite/store';
export class DatabaseBlockModel extends BlockModel {
}
const migration = {
    toV3: (data)=>{
        const id = nanoid();
        const title = data['titleColumnName'];
        const width = data['titleColumnWidth'];
        delete data['titleColumnName'];
        delete data['titleColumnWidth'];
        data.columns.unshift({
            id,
            type: 'title',
            name: title,
            data: {}
        });
        data.views.forEach((view)=>{
            if (view.mode === 'table') {
                view.columns.unshift({
                    id,
                    width,
                    statCalcType: 'none'
                });
            }
        });
    }
};
export const DatabaseBlockSchema = defineBlockSchema({
    flavour: 'affine:database',
    props: (internal)=>({
            views: [],
            title: internal.Text(),
            cells: Object.create(null),
            columns: []
        }),
    metadata: {
        role: 'hub',
        version: 3,
        parent: [
            'affine:note'
        ],
        children: [
            'affine:paragraph',
            'affine:list'
        ]
    },
    toModel: ()=>new DatabaseBlockModel(),
    onUpgrade: (data, previousVersion, latestVersion)=>{
        if (previousVersion < 3 && latestVersion >= 3) {
            migration.toV3(data);
        }
    }
});
