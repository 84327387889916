export function uint8ArrayToBase64(array) {
    return new Promise((resolve)=>{
        const blob = new Blob([
            array
        ]);
        const reader = new FileReader();
        reader.onload = function() {
            const dataUrl = reader.result;
            if (!dataUrl) {
                resolve('');
                return;
            }
            const base64 = dataUrl.split(',')[1];
            resolve(base64);
        };
        reader.readAsDataURL(blob);
    });
}
export function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const binaryArray = binaryString.split('').map(function(char) {
        return char.charCodeAt(0);
    });
    return new Uint8Array(binaryArray);
}
