const DEV_MODE = process.env.NODE_ENV !== 'production';
const reservedReactProperties = new Set([
    'children',
    'localName',
    'ref',
    'style',
    'className'
]);
const listenedEvents = new WeakMap();
const addOrUpdateEventListener = (node, event, listener)=>{
    let events = listenedEvents.get(node);
    if (events === undefined) {
        listenedEvents.set(node, events = new Map());
    }
    let handler = events.get(event);
    if (listener !== undefined) {
        if (handler === undefined) {
            events.set(event, handler = {
                handleEvent: listener
            });
            node.addEventListener(event, handler);
        } else {
            handler.handleEvent = listener;
        }
    } else if (handler !== undefined) {
        events.delete(event);
        node.removeEventListener(event, handler);
    }
};
const setProperty = (node, name, value, old, events)=>{
    const event = events?.[name];
    if (event !== undefined && value !== old) {
        addOrUpdateEventListener(node, event, value);
        return;
    }
    node[name] = value;
    if ((value === undefined || value === null) && name in HTMLElement.prototype) {
        node.removeAttribute(name);
    }
};
export const createComponent = ({ react: React, tagName = 'div', elementClass, events, displayName })=>{
    const eventProps = new Set(Object.keys(events ?? {}));
    if (DEV_MODE) {
        for (const p of reservedReactProperties){
            if (p in elementClass.prototype && !(p in HTMLElement.prototype)) {
                console.warn(`${tagName} contains property ${p} which is a React reserved ` + `property. It will be used by React and not set on the element.`);
            }
        }
    }
    const ReactComponent = React.forwardRef((props, ref)=>{
        const containerRef = React.useRef(null);
        const prevPropsRef = React.useRef(null);
        const elementRef = React.useRef(null);
        const reactProps = {
            'data-lit-react-wrapper': elementClass.name
        };
        const elementProps = {};
        if (elementRef.current === null) {
            const element = new elementClass();
            elementRef.current = element;
            if (typeof ref === 'function') {
                ref(elementRef.current);
            } else if (ref) {
                ref.current = element;
            }
        }
        const element = elementRef.current;
        for (const [k, v] of Object.entries(props)){
            if (reservedReactProperties.has(k)) {
                reactProps[k] = v;
                continue;
            }
            if (eventProps.has(k) || k in elementClass.prototype) {
                elementProps[k] = v;
                continue;
            }
            reactProps[k] = v;
        }
        React.useLayoutEffect(()=>{
            if (elementRef.current === null) {
                return;
            }
            for(const prop in elementProps){
                setProperty(elementRef.current, prop, props[prop], prevPropsRef.current ? prevPropsRef.current[prop] : undefined, events);
            }
            prevPropsRef.current = props;
        });
        React.useLayoutEffect(()=>{
            const container = containerRef.current;
            if (!container) {
                return;
            }
            container.append(element);
            return ()=>{
                element.remove();
            };
        }, [
            element
        ]);
        return React.createElement(tagName, {
            ...reactProps,
            ref: React.useCallback((node)=>{
                containerRef.current = node;
            }, [
                containerRef
            ])
        });
    });
    ReactComponent.displayName = displayName ?? elementClass.name;
    return ReactComponent;
};
