import { Store } from '@toeverything/infra';
import { nanoid } from 'nanoid';
export class TagStore extends Store {
    constructor(properties){
        super(), this.properties = properties;
    }
    watchTagIds() {
        return this.properties.tagOptions$.map((tags)=>tags.map((tag)=>tag.id)).asObservable();
    }
    createNewTag(value, color) {
        const newId = nanoid();
        this.properties.updateTagOptions([
            ...this.properties.tagOptions$.value,
            {
                id: newId,
                value,
                color,
                createDate: Date.now(),
                updateDate: Date.now()
            }
        ]);
        return newId;
    }
    createNewTagWithId(newId, value, color) {
        this.properties.updateTagOptions([
            ...this.properties.tagOptions$.value,
            {
                id: newId,
                value,
                color,
                createDate: Date.now(),
                updateDate: Date.now()
            }
        ]);
        return newId;
    }
    deleteTag(id) {
        this.properties.removeTagOption(id);
    }
    watchTagInfo(id) {
        return this.properties.tagOptions$.map((tags)=>tags.find((tag)=>tag.id === id));
    }
    updateTagInfo(id, tagInfo) {
        const tag = this.properties.tagOptions$.value.find((tag)=>tag.id === id);
        if (!tag) {
            return;
        }
        this.properties.updateTagOption(id, {
            id: id,
            value: tag.value,
            color: tag.color,
            createDate: tag.createDate,
            updateDate: Date.now(),
            ...tagInfo
        });
    }
}
