import { insertPositionToIndex } from '@blocksuite/affine-shared/utils';
import { computed } from '@preact/signals-core';
import { emptyFilterGroup } from '../../core/common/ast.js';
import { defaultGroupBy } from '../../core/common/group-by.js';
import { GroupManager, sortByManually } from '../../core/common/group-by/helper.js';
import { evalFilter } from '../../core/logical/eval-filter.js';
import { PropertyBase } from '../../core/view-manager/property.js';
import { SingleViewBase } from '../../core/view-manager/single-view.js';
import { DEFAULT_COLUMN_WIDTH } from './consts.js';
export class TableSingleView extends SingleViewBase {
    get groupProperties() {
        return this.data$.value?.groupProperties ?? [];
    }
    get name() {
        return this.data$.value?.name ?? '';
    }
    get type() {
        return this.data$.value?.mode ?? 'table';
    }
    constructor(viewManager, viewId){
        super(viewManager, viewId), this.computedColumns$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.map((id)=>{
                const column = this.propertyGet(id);
                return {
                    id: column.id,
                    hide: column.hide$.value,
                    width: column.width$.value,
                    statCalcType: column.statCalcOp$.value
                };
            });
        }), this.detailProperties$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.filter((id)=>this.propertyTypeGet(id) !== 'title');
        }), this.filter$ = computed(()=>{
            return this.data$.value?.filter ?? emptyFilterGroup;
        }), this.groupBy$ = computed(()=>{
            return this.data$.value?.groupBy;
        }), this.groupManager = new GroupManager(this.groupBy$, this, {
            sortGroup: (ids)=>sortByManually(ids, (v)=>v, this.groupProperties.map((v)=>v.key)),
            sortRow: (key, ids)=>{
                const property = this.groupProperties.find((v)=>v.key === key);
                return sortByManually(ids, (v)=>v, property?.manuallyCardSort ?? []);
            },
            changeGroupSort: (keys)=>{
                const map = new Map(this.groupProperties.map((v)=>[
                        v.key,
                        v
                    ]));
                this.dataUpdate(()=>{
                    return {
                        groupProperties: keys.map((key)=>{
                            const property = map.get(key);
                            if (property) {
                                return property;
                            }
                            return {
                                key,
                                hide: false,
                                manuallyCardSort: []
                            };
                        })
                    };
                });
            },
            changeRowSort: (groupKeys, groupKey, keys)=>{
                const map = new Map(this.groupProperties.map((v)=>[
                        v.key,
                        v
                    ]));
                this.dataUpdate(()=>{
                    return {
                        groupProperties: groupKeys.map((key)=>{
                            if (key === groupKey) {
                                const group = map.get(key);
                                return group ? {
                                    ...group,
                                    manuallyCardSort: keys
                                } : {
                                    key,
                                    hide: false,
                                    manuallyCardSort: keys
                                };
                            } else {
                                return map.get(key) ?? {
                                    key,
                                    hide: false,
                                    manuallyCardSort: []
                                };
                            }
                        })
                    };
                });
            }
        }), this.mainProperties$ = computed(()=>{
            return this.data$.value?.header ?? {
                titleColumn: this.propertiesWithoutFilter$.value.find((id)=>this.propertyTypeGet(id) === 'title'),
                iconColumn: 'type'
            };
        }), this.propertiesWithoutFilter$ = computed(()=>{
            const needShow = new Set(this.dataSource.properties$.value);
            const result = [];
            this.data$.value?.columns.forEach((v)=>{
                if (needShow.has(v.id)) {
                    result.push(v.id);
                    needShow.delete(v.id);
                }
            });
            result.push(...needShow);
            return result;
        }), this.propertyIds$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.filter((id)=>!this.propertyHideGet(id));
        }), this.readonly$ = computed(()=>{
            return this.manager.readonly$.value;
        });
    }
    changeGroup(columnId) {
        if (columnId == null) {
            this.dataUpdate(()=>{
                return {
                    groupBy: undefined
                };
            });
            return;
        }
        const column = this.propertyGet(columnId);
        this.dataUpdate((_view)=>{
            return {
                groupBy: defaultGroupBy(this.propertyMetaGet(column.type$.value), column.id, column.data$.value)
            };
        });
    }
    columnGetStatCalcOp(columnId) {
        return this.data$.value?.columns.find((v)=>v.id === columnId)?.statCalcType;
    }
    columnGetWidth(columnId) {
        const column = this.data$.value?.columns.find((v)=>v.id === columnId);
        if (column?.width != null) {
            return column.width;
        }
        const type = this.propertyTypeGet(columnId);
        if (type === 'title') {
            return 260;
        }
        return DEFAULT_COLUMN_WIDTH;
    }
    columnUpdateStatCalcOp(columnId, op) {
        this.dataUpdate(()=>{
            return {
                columns: this.computedColumns$.value.map((v)=>v.id === columnId ? {
                        ...v,
                        statCalcType: op
                    } : v)
            };
        });
    }
    columnUpdateWidth(columnId, width) {
        this.dataUpdate(()=>{
            return {
                columns: this.computedColumns$.value.map((v)=>v.id === columnId ? {
                        ...v,
                        width: width
                    } : v)
            };
        });
    }
    filterSet(filter) {
        this.dataUpdate(()=>{
            return {
                filter
            };
        });
    }
    isShow(rowId) {
        if (this.filter$.value?.conditions.length) {
            const rowMap = Object.fromEntries(this.properties$.value.map((column)=>[
                    column.id,
                    column.cellGet(rowId).jsonValue$.value
                ]));
            return evalFilter(this.filter$.value, rowMap);
        }
        return true;
    }
    propertyGet(columnId) {
        return new TableColumn(this, columnId);
    }
    propertyHideGet(columnId) {
        return this.data$.value?.columns.find((v)=>v.id === columnId)?.hide ?? false;
    }
    propertyHideSet(columnId, hide) {
        this.dataUpdate(()=>{
            return {
                columns: this.computedColumns$.value.map((v)=>v.id === columnId ? {
                        ...v,
                        hide
                    } : v)
            };
        });
    }
    propertyMove(columnId, toAfterOfColumn) {
        this.dataUpdate(()=>{
            const columnIndex = this.computedColumns$.value.findIndex((v)=>v.id === columnId);
            if (columnIndex < 0) {
                return {};
            }
            const columns = [
                ...this.computedColumns$.value
            ];
            const [column] = columns.splice(columnIndex, 1);
            const index = insertPositionToIndex(toAfterOfColumn, columns);
            columns.splice(index, 0, column);
            return {
                columns
            };
        });
    }
    rowAdd(insertPosition, groupKey) {
        const id = super.rowAdd(insertPosition);
        if (!groupKey) {
            return id;
        }
        this.groupManager.addToGroup(id, groupKey);
        return id;
    }
    rowMove(rowId, position, fromGroup, toGroup) {
        if (toGroup == null) {
            super.rowMove(rowId, position);
            return;
        }
        this.groupManager.moveCardTo(rowId, fromGroup, toGroup, position);
    }
    rowNextGet(rowId) {
        const index = this.rows$.value.indexOf(rowId);
        return this.rows$.value[index + 1];
    }
    rowPrevGet(rowId) {
        const index = this.rows$.value.indexOf(rowId);
        return this.rows$.value[index - 1];
    }
}
export class TableColumn extends PropertyBase {
    constructor(tableView, columnId){
        super(tableView, columnId), this.tableView = tableView, this.statCalcOp$ = computed(()=>{
            return this.tableView.columnGetStatCalcOp(this.id);
        }), this.width$ = computed(()=>{
            return this.tableView.columnGetWidth(this.id);
        });
    }
    updateStatCalcOp(type) {
        return this.tableView.columnUpdateStatCalcOp(this.id, type);
    }
    updateWidth(width) {
        this.tableView.columnUpdateWidth(this.id, width);
    }
}
