import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogTrigger } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { createContext, useCallback, useContext, useState } from 'react';
import { Button } from '../button';
import { Modal } from './modal';
import * as styles from './styles.css';
export const ConfirmModal = ({ children, confirmButtonOptions, confirmText, cancelText = 'Cancel', cancelButtonOptions, reverseFooter, onConfirm, onCancel, width = 480, autoFocusConfirm = true, ...props })=>{
    const onConfirmClick = useCallback(()=>{
        Promise.resolve(onConfirm?.()).catch((err)=>{
            console.error(err);
        });
    }, [
        onConfirm
    ]);
    return _jsxs(Modal, {
        contentOptions: {
            className: styles.confirmModalContainer,
            onPointerDownOutside: (e)=>{
                e.stopPropagation();
                onCancel?.();
            }
        },
        width: width,
        closeButtonOptions: {
            onClick: onCancel
        },
        ...props,
        children: [
            children ? _jsx("div", {
                className: styles.confirmModalContent,
                children: children
            }) : null,
            _jsxs("div", {
                className: clsx(styles.modalFooter, {
                    modalFooterWithChildren: !!children,
                    reverse: reverseFooter
                }),
                children: [
                    _jsx(DialogTrigger, {
                        asChild: true,
                        children: _jsx(Button, {
                            onClick: onCancel,
                            "data-testid": "confirm-modal-cancel",
                            ...cancelButtonOptions,
                            children: cancelText
                        })
                    }),
                    _jsx(Button, {
                        onClick: onConfirmClick,
                        "data-testid": "confirm-modal-confirm",
                        autoFocus: autoFocusConfirm,
                        ...confirmButtonOptions,
                        children: confirmText
                    })
                ]
            })
        ]
    });
};
const ConfirmModalContext = createContext({
    modalProps: {
        open: false
    },
    openConfirmModal: ()=>{},
    closeConfirmModal: ()=>{}
});
export const ConfirmModalProvider = ({ children })=>{
    const [modalProps, setModalProps] = useState({
        open: false
    });
    const setLoading = useCallback((value)=>{
        setModalProps((prev)=>({
                ...prev,
                confirmButtonOptions: {
                    ...prev.confirmButtonOptions,
                    loading: value
                }
            }));
    }, []);
    const closeConfirmModal = useCallback(()=>{
        setModalProps({
            open: false
        });
    }, []);
    const openConfirmModal = useCallback((props, options)=>{
        const { autoClose = true, onSuccess } = options ?? {};
        if (!props) {
            setModalProps({
                open: true
            });
            return;
        }
        const { onConfirm: _onConfirm, ...otherProps } = props;
        const onConfirm = ()=>{
            setLoading(true);
            return Promise.resolve(_onConfirm?.()).then(()=>onSuccess?.()).catch(console.error).finally(()=>setLoading(false)).finally(()=>autoClose && closeConfirmModal());
        };
        setModalProps({
            ...otherProps,
            onConfirm,
            open: true
        });
    }, [
        closeConfirmModal,
        setLoading
    ]);
    const onOpenChange = useCallback((open)=>{
        modalProps.onOpenChange?.(open);
        setModalProps((props)=>({
                ...props,
                open
            }));
    }, [
        modalProps
    ]);
    return _jsxs(ConfirmModalContext.Provider, {
        value: {
            openConfirmModal,
            closeConfirmModal,
            modalProps
        },
        children: [
            children,
            _jsx(ConfirmModal, {
                ...modalProps,
                onOpenChange: onOpenChange
            })
        ]
    });
};
export const useConfirmModal = ()=>{
    const context = useContext(ConfirmModalContext);
    if (!context) {
        throw new Error('useConfirmModal must be used within a ConfirmModalProvider');
    }
    return {
        openConfirmModal: context.openConfirmModal,
        closeConfirmModal: context.closeConfirmModal
    };
};
