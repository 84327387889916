import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Logo1IconBorder } from '@affine/component';
import { Button } from '@affine/component/ui/button';
import { fetcher, getCurrentUserQuery } from '@affine/graphql';
import { Trans, useI18n } from '@affine/i18n';
import { useCallback, useMemo } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import * as styles from './open-app.css';
let lastOpened = '';
const appSchemas = z.enum([
    'affine',
    'affine-canary',
    'affine-beta',
    'affine-internal',
    'affine-dev'
]);
const appChannelSchema = z.enum([
    'stable',
    'canary',
    'beta',
    'internal'
]);
const schemaToChanel = {
    affine: 'stable',
    'affine-canary': 'canary',
    'affine-beta': 'beta',
    'affine-internal': 'internal',
    'affine-dev': 'canary'
};
export const appIconMap = {
    stable: '/imgs/wemem-icon.ico',
    canary: '/imgs/wemem-icon.ico',
    beta: '/imgs/wemem-icon.ico',
    internal: '/imgs/wemem-icon.ico'
};
export const appNames = {
    stable: 'Wemem',
    canary: 'Wemem Canary',
    beta: 'Wemem Beta',
    internal: 'Wemem Internal'
};
const OpenAppImpl = ({ urlToOpen, channel })=>{
    const t = useI18n();
    const openDownloadLink = useCallback(()=>{
        const url = `https://wemem.ai/download?channel=${channel}`;
        open(url, '_blank');
    }, [
        channel
    ]);
    const appIcon = appIconMap[channel];
    const appName = appNames[channel];
    const [params] = useSearchParams();
    const autoOpen = useMemo(()=>params.get('open') !== 'false', [
        params
    ]);
    if (urlToOpen && lastOpened !== urlToOpen && autoOpen) {
        lastOpened = urlToOpen;
        location.href = urlToOpen;
    }
    if (!urlToOpen) {
        return null;
    }
    return _jsxs("div", {
        className: styles.root,
        children: [
            _jsxs("div", {
                className: styles.topNav,
                children: [
                    _jsx("a", {
                        href: "/",
                        rel: "noreferrer",
                        className: styles.affineLogo,
                        children: _jsx(Logo1IconBorder, {})
                    }),
                    _jsxs("div", {
                        className: styles.topNavLinks,
                        children: [
                            _jsx("a", {
                                href: "https://wemem.ai",
                                target: "_blank",
                                rel: "noreferrer",
                                className: styles.topNavLink,
                                children: "Official Website"
                            }),
                            _jsx("a", {
                                href: "https://community.wemem.ai/home",
                                target: "_blank",
                                rel: "noreferrer",
                                className: styles.topNavLink,
                                children: "Wemem Community"
                            }),
                            _jsx("a", {
                                href: "https://wemem.ai/blog",
                                target: "_blank",
                                rel: "noreferrer",
                                className: styles.topNavLink,
                                children: "Blog"
                            }),
                            _jsx("a", {
                                href: "https://wemem.ai/about-us",
                                target: "_blank",
                                rel: "noreferrer",
                                className: styles.topNavLink,
                                children: "Contact us"
                            })
                        ]
                    }),
                    _jsx(Button, {
                        onClick: openDownloadLink,
                        children: t['com.affine.auth.open.affine.download-app']()
                    })
                ]
            }),
            _jsxs("div", {
                className: styles.centerContent,
                children: [
                    _jsx("img", {
                        src: appIcon,
                        alt: appName,
                        width: 120,
                        height: 120
                    }),
                    _jsx("div", {
                        className: styles.prompt,
                        children: _jsxs(Trans, {
                            i18nKey: "com.affine.auth.open.wemem.aimpt",
                            children: [
                                "Open ",
                                appName,
                                " app now"
                            ]
                        })
                    }),
                    _jsx("a", {
                        className: styles.tryAgainLink,
                        href: urlToOpen,
                        target: "_blank",
                        rel: "noreferrer",
                        children: t['com.affine.auth.open.affine.try-again']()
                    })
                ]
            })
        ]
    });
};
const OpenUrl = ()=>{
    const [params] = useSearchParams();
    const urlToOpen = params.get('url');
    params.delete('url');
    const urlObj = new URL(urlToOpen || '');
    const maybeSchema = appSchemas.safeParse(urlObj.protocol.replace(':', ''));
    const channel = schemaToChanel[maybeSchema.success ? maybeSchema.data : 'affine'];
    params.forEach((v, k)=>{
        urlObj.searchParams.set(k, v);
    });
    return _jsx(OpenAppImpl, {
        urlToOpen: urlObj.toString(),
        channel: channel
    });
};
const OpenOAuthJwt = ()=>{
    const { currentUser } = useLoaderData();
    const [params] = useSearchParams();
    const maybeSchema = appSchemas.safeParse(params.get('schema'));
    const schema = maybeSchema.success ? maybeSchema.data : 'affine';
    const next = params.get('next');
    const channel = schemaToChanel[schema];
    if (!currentUser || !currentUser?.token?.sessionToken) {
        return null;
    }
    const urlToOpen = `${schema}://signin-redirect?token=${currentUser.token.sessionToken}&next=${next || ''}`;
    return _jsx(OpenAppImpl, {
        urlToOpen: urlToOpen,
        channel: channel
    });
};
export const Component = ()=>{
    const { action } = useLoaderData();
    if (action === 'url') {
        return _jsx(OpenUrl, {});
    } else if (action === 'signin-redirect') {
        return _jsx(OpenOAuthJwt, {});
    }
    return null;
};
export const loader = async (args)=>{
    const action = args.params.action || '';
    const res = await fetcher({
        query: getCurrentUserQuery
    }).catch(console.error);
    return {
        action,
        currentUser: res?.currentUser || null
    };
};
