import { effect } from '@preact/signals-core';
export function SignalWatcher(Base) {
    class SignalWatcher extends Base {
        connectedCallback() {
            super.connectedCallback();
            this.requestUpdate();
        }
        disconnectedCallback() {
            super.disconnectedCallback();
            this.__dispose?.();
        }
        performUpdate() {
            if (this.isUpdatePending === false || this.isConnected === false) {
                return;
            }
            this.__dispose?.();
            let updateFromLit = true;
            this.__dispose = effect(()=>{
                if (updateFromLit) {
                    updateFromLit = false;
                    super.performUpdate();
                } else {
                    this.requestUpdate();
                }
            });
        }
    }
    return SignalWatcher;
}
