import { assertExists } from '@blocksuite/global/utils';
import { getNextContentBlock } from '../../utils/index.js';
function getNextBlock(std, path) {
    const view = std.view;
    const model = std.doc.getBlock(path)?.model;
    if (!model) return null;
    const nextModel = getNextContentBlock(std.host, model);
    if (!nextModel) return null;
    return view.getBlock(nextModel.id);
}
export const getNextBlockCommand = (ctx, next)=>{
    const path = ctx.path ?? ctx.currentSelectionPath;
    assertExists(path, '`path` is required, you need to pass it in args or ctx before adding this command to the pipeline.');
    const nextBlock = getNextBlock(ctx.std, path);
    if (nextBlock) {
        next({
            nextBlock
        });
    }
};
