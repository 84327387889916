import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '../../ui/skeleton';
import { blockSuiteEditorHeaderStyle, blockSuiteEditorStyle, pageDetailSkeletonStyle, pageDetailSkeletonTitleStyle } from './index.css';
export const EditorLoading = ()=>{
    return _jsxs("div", {
        className: blockSuiteEditorStyle,
        children: [
            _jsx(Skeleton, {
                className: blockSuiteEditorHeaderStyle,
                animation: "wave",
                height: 50
            }),
            _jsx(Skeleton, {
                animation: "wave",
                height: 30,
                width: "40%"
            })
        ]
    });
};
export const PageDetailSkeleton = ()=>{
    return _jsxs("div", {
        className: pageDetailSkeletonStyle,
        children: [
            _jsx("div", {
                className: pageDetailSkeletonTitleStyle
            }),
            _jsx(EditorLoading, {})
        ]
    });
};
