function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _initClass, _Service;
import { DebugLogger } from '@affine/debug';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { createWorkspaceMutation, deleteWorkspaceMutation, getIsOwnerQuery, getWorkspacesQuery } from '@affine/graphql';
import { DocCollection } from '@blocksuite/affine/store';
import { ApplicationStarted, catchErrorInto, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, OnEvent, onStart } from '@toeverything/infra';
import { effect, getAFFiNEWorkspaceSchema, Service } from '@toeverything/infra';
import { isEqual } from 'lodash-es';
import { nanoid } from 'nanoid';
import { EMPTY, map, mergeMap } from 'rxjs';
import { applyUpdate, encodeStateAsUpdate } from 'yjs';
import { AccountChanged } from '../../cloud';
import { BroadcastChannelAwarenessConnection } from './engine/awareness-broadcast-channel';
import { CloudAwarenessConnection } from './engine/awareness-cloud';
import { CloudBlobStorage } from './engine/blob-cloud';
import { StaticBlobStorage } from './engine/blob-static';
import { CloudDocEngineServer } from './engine/doc-cloud';
import { CloudStaticDocStorage } from './engine/doc-cloud-static';
const CLOUD_WORKSPACES_CACHE_KEY = 'cloud-workspace:';
const logger = new DebugLogger('affine:cloud-workspace-flavour-provider');
let _CloudWorkspaceFlavourProviderService;
_dec = OnEvent(ApplicationStarted, (e)=>e.revalidate), _dec1 = OnEvent(AccountChanged, (e)=>e.revalidate);
class CloudWorkspaceFlavourProviderService extends (_Service = Service) {
    static{
        ({ c: [_CloudWorkspaceFlavourProviderService, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec,
            _dec1
        ], _Service));
    }
    constructor(globalState, authService, storageProvider, graphqlService, webSocketService){
        super(), this.globalState = globalState, this.authService = authService, this.storageProvider = storageProvider, this.graphqlService = graphqlService, this.webSocketService = webSocketService, this.flavour = WorkspaceFlavour.AFFINE_CLOUD, this.revalidate = effect(map(()=>{
            return {
                accountId: this.authService.session.account$.value?.id
            };
        }), exhaustMapSwitchUntilChanged((a, b)=>a.accountId === b.accountId, ({ accountId })=>{
            return fromPromise(async (signal)=>{
                if (!accountId) {
                    return null;
                }
                const { workspaces } = await this.graphqlService.gql({
                    query: getWorkspacesQuery,
                    context: {
                        signal
                    }
                });
                const ids = workspaces.map(({ id, initialized })=>({
                        id,
                        initialized
                    }));
                return {
                    accountId,
                    workspaces: ids.map(({ id, initialized })=>({
                            id,
                            flavour: WorkspaceFlavour.AFFINE_CLOUD,
                            initialized
                        }))
                };
            }).pipe(mergeMap((data)=>{
                if (data) {
                    const { accountId, workspaces } = data;
                    const sorted = workspaces.sort((a, b)=>{
                        return a.id.localeCompare(b.id);
                    });
                    this.globalState.set(CLOUD_WORKSPACES_CACHE_KEY + accountId, sorted);
                    if (!isEqual(this.workspaces$.value, sorted)) {
                        this.workspaces$.next(sorted);
                    }
                } else {
                    this.workspaces$.next([]);
                }
                return EMPTY;
            }), catchErrorInto(this.error$, (err)=>{
                logger.error('error to revalidate cloud workspaces', err);
            }), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false)));
        }, ({ accountId })=>{
            if (accountId) {
                this.workspaces$.next(this.globalState.get(CLOUD_WORKSPACES_CACHE_KEY + accountId) ?? []);
            } else {
                this.workspaces$.next([]);
            }
        })), this.error$ = new LiveData(null), this.isRevalidating$ = new LiveData(false), this.workspaces$ = new LiveData([]);
    }
    async deleteWorkspace(id) {
        await this.graphqlService.gql({
            query: deleteWorkspaceMutation,
            variables: {
                id: id
            }
        });
        this.revalidate();
        await this.waitForLoaded();
    }
    async createWorkspace(initial) {
        const { createWorkspace: { id: workspaceId } } = await this.graphqlService.gql({
            query: createWorkspaceMutation
        });
        const blobStorage = this.storageProvider.getBlobStorage(workspaceId);
        const docStorage = this.storageProvider.getDocStorage(workspaceId);
        const docCollection = new DocCollection({
            id: workspaceId,
            idGenerator: ()=>nanoid(),
            schema: getAFFiNEWorkspaceSchema(),
            blobSources: {
                main: blobStorage
            }
        });
        await initial(docCollection, blobStorage, docStorage);
        await docStorage.doc.set(workspaceId, encodeStateAsUpdate(docCollection.doc));
        for (const subdocs of docCollection.doc.getSubdocs()){
            await docStorage.doc.set(subdocs.guid, encodeStateAsUpdate(subdocs));
        }
        this.revalidate();
        await this.waitForLoaded();
        return {
            id: workspaceId,
            flavour: WorkspaceFlavour.AFFINE_CLOUD
        };
    }
    async getWorkspaceProfile(id, signal) {
        const cloudStorage = new CloudStaticDocStorage(id);
        const docStorage = this.storageProvider.getDocStorage(id);
        const localData = await docStorage.doc.get(id);
        const cloudData = await cloudStorage.pull(id);
        const isOwner = await this.getIsOwner(id, signal);
        if (!cloudData && !localData) {
            return {
                isOwner
            };
        }
        const bs = new DocCollection({
            id,
            schema: getAFFiNEWorkspaceSchema()
        });
        if (localData) applyUpdate(bs.doc, localData);
        if (cloudData) applyUpdate(bs.doc, cloudData.data);
        return {
            name: bs.meta.name,
            avatar: bs.meta.avatar,
            isOwner
        };
    }
    async getWorkspaceBlob(id, blob) {
        const localBlob = await this.storageProvider.getBlobStorage(id).get(blob);
        if (localBlob) {
            return localBlob;
        }
        const cloudBlob = new CloudBlobStorage(id);
        return await cloudBlob.get(blob);
    }
    getEngineProvider(workspaceId) {
        return {
            getAwarenessConnections: ()=>{
                return [
                    new BroadcastChannelAwarenessConnection(workspaceId),
                    new CloudAwarenessConnection(workspaceId, this.webSocketService)
                ];
            },
            getDocServer: ()=>{
                return new CloudDocEngineServer(workspaceId, this.webSocketService);
            },
            getDocStorage: ()=>{
                return this.storageProvider.getDocStorage(workspaceId);
            },
            getLocalBlobStorage: ()=>{
                return this.storageProvider.getBlobStorage(workspaceId);
            },
            getRemoteBlobStorages () {
                return [
                    new CloudBlobStorage(workspaceId),
                    new StaticBlobStorage()
                ];
            }
        };
    }
    async getIsOwner(workspaceId, signal) {
        return (await this.graphqlService.gql({
            query: getIsOwnerQuery,
            variables: {
                workspaceId
            },
            context: {
                signal
            }
        })).isOwner;
    }
    waitForLoaded() {
        return this.isRevalidating$.waitFor((loading)=>!loading);
    }
    static{
        _initClass();
    }
}
export { _CloudWorkspaceFlavourProviderService as CloudWorkspaceFlavourProviderService };
