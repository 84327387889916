import { clamp } from '@blocksuite/affine-shared/utils';
export function calcSafeCoordinate({ positioningPoint, objRect = {
    width: 0,
    height: 0
}, boundaryRect = document.body.getBoundingClientRect(), offsetX = 0, offsetY = 0, edgeGap = 20 }) {
    const safeX = clamp(positioningPoint.x + offsetX, edgeGap, boundaryRect.width - objRect.width - edgeGap);
    const y = positioningPoint.y + offsetY;
    return {
        x: safeX,
        y
    };
}
export function compareTopAndBottomSpace(obj, container = document.body, gap = 20) {
    const objRect = obj.getBoundingClientRect();
    const spaceRect = container.getBoundingClientRect();
    const topSpace = objRect.top - spaceRect.top;
    const bottomSpace = spaceRect.bottom - objRect.bottom;
    const topOrBottom = topSpace > bottomSpace ? 'top' : 'bottom';
    return {
        placement: topOrBottom,
        height: (topOrBottom === 'top' ? topSpace : bottomSpace) - gap
    };
}
export function getPopperPosition(popper, reference, { gap = 12, offsetY = 5 } = {}) {
    if (!popper) {
        console.warn('The popper element is not exist. Popper position maybe incorrect');
    }
    const { placement, height } = compareTopAndBottomSpace(reference, document.body, gap + offsetY);
    const referenceRect = reference.getBoundingClientRect();
    const positioningPoint = {
        x: referenceRect.x,
        y: referenceRect.y + (placement === 'bottom' ? referenceRect.height : 0)
    };
    const boundaryRect = document.body.getBoundingClientRect();
    const popperRect = popper?.getBoundingClientRect();
    const safeCoordinate = calcSafeCoordinate({
        positioningPoint,
        objRect: popperRect,
        boundaryRect,
        offsetY: placement === 'bottom' ? offsetY : -offsetY
    });
    return {
        placement,
        height,
        x: `${safeCoordinate.x}px`,
        y: placement === 'bottom' ? `${safeCoordinate.y}px` : `calc(${safeCoordinate.y}px - 100%)`
    };
}
