import { z } from 'zod';
export const TagOptionSchema = z.object({
    id: z.string(),
    name: z.string(),
    color: z.string()
});
export var PageSystemPropertyId;
(function(PageSystemPropertyId) {
    PageSystemPropertyId["Tags"] = "tags";
    PageSystemPropertyId["Journal"] = "journal";
})(PageSystemPropertyId || (PageSystemPropertyId = {}));
export var PagePropertyType;
(function(PagePropertyType) {
    PagePropertyType["Text"] = "text";
    PagePropertyType["Number"] = "number";
    PagePropertyType["Date"] = "date";
    PagePropertyType["Progress"] = "progress";
    PagePropertyType["Checkbox"] = "checkbox";
    PagePropertyType["Tags"] = "tags";
    PagePropertyType["CreatedBy"] = "createdBy";
    PagePropertyType["UpdatedBy"] = "updatedBy";
})(PagePropertyType || (PagePropertyType = {}));
export const PagePropertyMetaBaseSchema = z.object({
    id: z.string(),
    name: z.string(),
    source: z.string(),
    type: z.nativeEnum(PagePropertyType),
    icon: z.string(),
    required: z.boolean().optional(),
    readonly: z.boolean().optional()
});
export const PageSystemPropertyMetaBaseSchema = PagePropertyMetaBaseSchema.extend({
    source: z.literal('system')
});
export const PageCustomPropertyMetaSchema = PagePropertyMetaBaseSchema.extend({
    source: z.literal('custom'),
    order: z.number()
});
export const PageInfoItemSchema = z.object({
    id: z.string(),
    visibility: z.enum([
        'visible',
        'hide',
        'hide-if-empty'
    ]),
    value: z.any()
});
export const PageInfoJournalItemSchema = PageInfoItemSchema.extend({
    id: z.literal("journal"),
    value: z.union([
        z.string(),
        z.literal(false)
    ])
});
export const PageInfoTagsItemSchema = PageInfoItemSchema.extend({
    id: z.literal("tags"),
    value: z.array(z.string())
});
export const WorkspaceFavoriteItemSchema = z.object({
    id: z.string(),
    order: z.string(),
    type: z.enum([
        'doc',
        'collection'
    ]),
    value: z.boolean()
});
const WorkspaceAffinePropertiesSchemaSchema = z.object({
    pageProperties: z.object({
        custom: z.record(PageCustomPropertyMetaSchema),
        system: z.object({
            ["journal"]: PageSystemPropertyMetaBaseSchema.extend({
                id: z.literal("journal"),
                type: z.literal("date")
            }),
            ["tags"]: PagePropertyMetaBaseSchema.extend({
                id: z.literal("tags"),
                type: z.literal("tags"),
                options: z.array(TagOptionSchema)
            })
        })
    })
});
const PageInfoCustomPropertyItemSchema = PageInfoItemSchema.extend({
    order: z.string()
});
const WorkspacePagePropertiesSchema = z.object({
    custom: z.record(PageInfoCustomPropertyItemSchema),
    system: z.object({
        ["journal"]: PageInfoJournalItemSchema,
        ["tags"]: PageInfoTagsItemSchema
    })
});
export const WorkspaceAffinePropertiesSchema = z.object({
    schema: WorkspaceAffinePropertiesSchemaSchema.optional(),
    favorites: z.record(WorkspaceFavoriteItemSchema).optional(),
    pageProperties: z.record(WorkspacePagePropertiesSchema).optional(),
    favoritesMigrated: z.boolean().optional()
});
