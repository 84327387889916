export const selectBlocksBetween = (ctx, next)=>{
    const { focusBlock, anchorBlock, tail } = ctx;
    if (!focusBlock || !anchorBlock) {
        return;
    }
    const selection = ctx.std.selection;
    if (anchorBlock.blockId === focusBlock.blockId) {
        const blockId = focusBlock.blockId;
        selection.setGroup('note', [
            selection.create('block', {
                blockId
            })
        ]);
        return next();
    }
    const selections = [
        ...selection.value
    ];
    if (selections.every((sel)=>sel.blockId !== focusBlock.blockId)) {
        if (tail) {
            selections.push(selection.create('block', {
                blockId: focusBlock.blockId
            }));
        } else {
            selections.unshift(selection.create('block', {
                blockId: focusBlock.blockId
            }));
        }
    }
    let start = false;
    const sel = selections.filter((sel)=>{
        if (sel.blockId === anchorBlock.blockId || sel.blockId === focusBlock.blockId) {
            start = !start;
            return true;
        }
        return start;
    });
    selection.setGroup('note', sel);
    return next();
};
