import { jsx as _jsx } from "react/jsx-runtime";
import { InlineEdit } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { track } from '@affine/track';
import { DocsService, useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import clsx from 'clsx';
import * as styles from './style.css';
const inputAttrs = {
    'data-testid': 'title-content'
};
export const BlocksuiteHeaderTitle = (props)=>{
    const { inputHandleRef, docId } = props;
    const workspaceService = useService(WorkspaceService);
    const isSharedMode = workspaceService.workspace.openOptions.isSharedMode;
    const docsService = useService(DocsService);
    const docRecord = useLiveData(docsService.list.doc$(docId));
    const docTitle = useLiveData(docRecord?.title$);
    const onChange = useAsyncCallback(async (v)=>{
        await docsService.changeDocTitle(docId, v);
        track.$.header.actions.renameDoc();
    }, [
        docId,
        docsService
    ]);
    return _jsx(InlineEdit, {
        className: clsx(styles.title, props.className),
        value: docTitle,
        onChange: onChange,
        editable: !isSharedMode,
        exitible: true,
        placeholder: "Untitled",
        "data-testid": "title-edit-button",
        handleRef: inputHandleRef,
        inputAttrs: inputAttrs
    });
};
