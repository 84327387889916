import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeedAvatar, Modal, Scrollable } from '@affine/component';
import { FeedsService } from '@affine/core/modules/feeds';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import * as styles from './feed-info-modal.css';
import { FeedInfoTable } from './feed-info-table';
export const FeedInfoModal = ()=>{
    const feedsService = useService(FeedsService);
    const modal = feedsService.infoModal;
    const feedId = useLiveData(modal.feedId$);
    if (!feedId) return null;
    return _jsx(InfoModalOpened, {
        feedId: feedId
    });
};
const InfoModalOpened = ({ feedId })=>{
    const feedsService = useService(FeedsService);
    const modal = feedsService.infoModal;
    const feed = useLiveData(feedsService.feedById$(feedId));
    if (!feed) return null;
    return _jsx(Modal, {
        contentOptions: {
            className: styles.container
        },
        open: true,
        onOpenChange: (v)=>modal.onOpenChange(v),
        withoutCloseButton: true,
        children: _jsxs(Scrollable.Root, {
            children: [
                _jsxs(Scrollable.Viewport, {
                    className: styles.viewport,
                    "data-testid": "info-modal",
                    children: [
                        _jsxs("div", {
                            className: clsx(styles.titleContainer, styles.titleStyle),
                            "data-testid": "info-modal-title",
                            children: [
                                _jsx(FeedAvatar, {
                                    image: feed.icon,
                                    name: feed.name
                                }),
                                feed.name
                            ]
                        }),
                        _jsx("div", {
                            children: _jsx(FeedInfoTable, {
                                className: styles.infoTable,
                                feed: feed
                            })
                        })
                    ]
                }),
                _jsx(Scrollable.Scrollbar, {
                    className: styles.scrollBar
                })
            ]
        })
    });
};
