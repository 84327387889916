import { createEnumMap } from '../utils/enum.js';
import { LineColor } from './line.js';
export var ConnectorEndpoint;
(function(ConnectorEndpoint) {
    ConnectorEndpoint["Front"] = "Front";
    ConnectorEndpoint["Rear"] = "Rear";
})(ConnectorEndpoint || (ConnectorEndpoint = {}));
export var PointStyle;
(function(PointStyle) {
    PointStyle["Arrow"] = "Arrow";
    PointStyle["Circle"] = "Circle";
    PointStyle["Diamond"] = "Diamond";
    PointStyle["None"] = "None";
    PointStyle["Triangle"] = "Triangle";
})(PointStyle || (PointStyle = {}));
export const PointStyleMap = createEnumMap(PointStyle);
export const DEFAULT_CONNECTOR_COLOR = LineColor.Grey;
export const DEFAULT_CONNECTOR_TEXT_COLOR = LineColor.Black;
export const DEFAULT_FRONT_END_POINT_STYLE = "None";
export const DEFAULT_REAR_END_POINT_STYLE = "Arrow";
export const CONNECTOR_LABEL_MAX_WIDTH = 280;
export var ConnectorLabelOffsetAnchor;
(function(ConnectorLabelOffsetAnchor) {
    ConnectorLabelOffsetAnchor["Bottom"] = "bottom";
    ConnectorLabelOffsetAnchor["Center"] = "center";
    ConnectorLabelOffsetAnchor["Top"] = "top";
})(ConnectorLabelOffsetAnchor || (ConnectorLabelOffsetAnchor = {}));
export var ConnectorMode;
(function(ConnectorMode) {
    ConnectorMode[ConnectorMode["Straight"] = 0] = "Straight";
    ConnectorMode[ConnectorMode["Orthogonal"] = 1] = "Orthogonal";
    ConnectorMode[ConnectorMode["Curve"] = 2] = "Curve";
})(ConnectorMode || (ConnectorMode = {}));
