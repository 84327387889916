import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { PlusIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import * as styles from './index.css';
const sideBottom = {
    side: 'bottom'
};
export function AddPageButton({ onClick, className, style }) {
    const t = useI18n();
    return _jsx(IconButton, {
        tooltip: t['New Page'](),
        tooltipOptions: sideBottom,
        "data-testid": "sidebar-new-page-button",
        style: style,
        className: clsx([
            styles.root,
            className
        ]),
        onClick: onClick,
        onAuxClick: onClick,
        children: _jsx(PlusIcon, {})
    });
}
