function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass, _SignalWatcher, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init__model, _init__renderers, _init__service, _init_doc, _init_std, _init_viewType, _init_widgets, _initProto;
import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { SignalWatcher, WithDisposable } from '@blocksuite/global/utils';
import { Doc } from '@blocksuite/store';
import { BlockViewType } from '@blocksuite/store';
import { consume, provide } from '@lit/context';
import { computed } from '@preact/signals-core';
import { nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { when } from 'lit/directives/when.js';
import { html } from 'lit/static-html.js';
import { PropTypes, requiredProperties } from '../decorators/index.js';
import { blockComponentSymbol, modelContext, serviceContext } from './consts.js';
import { docContext, stdContext } from './lit-host.js';
import { ShadowlessElement } from './shadowless-element.js';
let _BlockComponent;
_dec = requiredProperties({
    doc: PropTypes.instanceOf(Doc),
    std: PropTypes.object,
    widgets: PropTypes.recordOf(PropTypes.object)
}), _dec1 = provide({
    context: modelContext
}), _dec2 = state(), _dec3 = state(), _dec4 = provide({
    context: serviceContext
}), _dec5 = state(), _dec6 = consume({
    context: docContext
}), _dec7 = consume({
    context: stdContext
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false,
    hasChanged (value, oldValue) {
        if (!value || !oldValue) {
            return value !== oldValue;
        }
        if (!Object.keys(value).length && !Object.keys(oldValue).length) {
            return false;
        }
        return value !== oldValue;
    }
});
class BlockComponent extends (_SignalWatcher = SignalWatcher(WithDisposable(ShadowlessElement))) {
    static{
        ({ e: [_init__model, _init__renderers, _init__service, _init_doc, _init_std, _init_viewType, _init_widgets, _initProto], c: [_BlockComponent, _initClass] } = _apply_decs_2203_r(this, [
            [
                [
                    _dec1,
                    _dec2
                ],
                1,
                "_model"
            ],
            [
                _dec3,
                1,
                "_renderers"
            ],
            [
                [
                    _dec4,
                    _dec5
                ],
                1,
                "_service"
            ],
            [
                _dec6,
                1,
                "doc"
            ],
            [
                _dec7,
                1,
                "std"
            ],
            [
                _dec8,
                1,
                "viewType"
            ],
            [
                _dec9,
                1,
                "widgets"
            ]
        ], [
            _dec
        ], _SignalWatcher));
    }
    get blockId() {
        return this.dataset.blockId;
    }
    get childBlocks() {
        const childModels = this.model.children;
        return childModels.map((child)=>{
            return this.std.view.getBlock(child.id);
        }).filter((x)=>!!x);
    }
    get flavour() {
        return this.model.flavour;
    }
    get host() {
        return this.std.host;
    }
    get isVersionMismatch() {
        const schema = this.doc.schema.flavourSchemaMap.get(this.model.flavour);
        if (!schema) {
            console.warn(`Schema not found for block ${this.model.id}, flavour ${this.model.flavour}`);
            return true;
        }
        const expectedVersion = schema.version;
        const actualVersion = this.model.version;
        if (expectedVersion !== actualVersion) {
            console.warn(`Version mismatch for block ${this.model.id}, expected ${expectedVersion}, actual ${actualVersion}`);
            return true;
        }
        return false;
    }
    get model() {
        if (this._model) {
            return this._model;
        }
        const model = this.doc.getBlockById(this.blockId);
        if (!model) {
            throw new BlockSuiteError(ErrorCode.MissingViewModelError, `Cannot find block model for id ${this.blockId}`);
        }
        this._model = model;
        return model;
    }
    get parentComponent() {
        const parent = this.model.parent;
        if (!parent) return null;
        return this.std.view.getBlock(parent.id);
    }
    get renderChildren() {
        return this.host.renderChildren.bind(this);
    }
    get rootComponent() {
        const rootId = this.doc.root?.id;
        if (!rootId) {
            return null;
        }
        const rootComponent = this.host.view.getBlock(rootId);
        return rootComponent ?? null;
    }
    get selected() {
        return this._selected.value;
    }
    get selection() {
        return this.host.selection;
    }
    get service() {
        if (this._service) {
            return this._service;
        }
        const service = this.std.getService(this.model.flavour);
        this._service = service;
        return service;
    }
    get topContenteditableElement() {
        return this.rootComponent;
    }
    get widgetComponents() {
        return Object.keys(this.widgets).reduce((mapping, key)=>({
                ...mapping,
                [key]: this.host.view.getWidget(key, this.blockId)
            }), {});
    }
    _renderMismatchBlock(content) {
        return when(this.isVersionMismatch, ()=>{
            const actualVersion = this.model.version;
            const schema = this.doc.schema.flavourSchemaMap.get(this.model.flavour);
            const expectedVersion = schema?.version ?? -1;
            return this.renderVersionMismatch(expectedVersion, actualVersion);
        }, ()=>content);
    }
    _renderViewType(content) {
        return choose(this.viewType, [
            [
                BlockViewType.Display,
                ()=>content
            ],
            [
                BlockViewType.Hidden,
                ()=>nothing
            ],
            [
                BlockViewType.Bypass,
                ()=>this.renderChildren(this.model)
            ]
        ]);
    }
    addRenderer(renderer) {
        this._renderers.push(renderer);
    }
    bindHotKey(keymap, options) {
        const dispose = this.host.event.bindHotkey(keymap, {
            flavour: options?.global ? undefined : options?.flavour ? this.model.flavour : undefined,
            blockId: options?.global || options?.flavour ? undefined : this.blockId
        });
        this._disposables.add(dispose);
        return dispose;
    }
    connectedCallback() {
        super.connectedCallback();
        this.std.view.setBlock(this);
        const disposable = this.std.doc.slots.blockUpdated.on(({ type, id })=>{
            if (id === this.model.id && type === 'delete') {
                this.std.view.deleteBlock(this);
                disposable.dispose();
            }
        });
        this._disposables.add(disposable);
        this._disposables.add(this.model.propsUpdated.on(()=>{
            this.requestUpdate();
        }));
        this.service?.specSlots.viewConnected.emit({
            service: this.service,
            component: this
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.service?.specSlots.viewDisconnected.emit({
            service: this.service,
            component: this
        });
    }
    async getUpdateComplete() {
        const result = await super.getUpdateComplete();
        await Promise.all(this.childBlocks.map((el)=>el.updateComplete));
        return result;
    }
    render() {
        return this._renderers.reduce((acc, cur)=>cur.call(this, acc), nothing);
    }
    renderBlock() {
        return nothing;
    }
    renderVersionMismatch(expectedVersion, actualVersion) {
        return html`
      <dl class="version-mismatch-warning" contenteditable="false">
        <dt>
          <h4>Block Version Mismatched</h4>
        </dt>
        <dd>
          <p>
            We can not render this <var>${this.model.flavour}</var> block
            because the version is mismatched.
          </p>
          <p>Editor version: <var>${expectedVersion}</var></p>
          <p>Data version: <var>${actualVersion}</var></p>
        </dd>
      </dl>
    `;
    }
    #___private__model_1;
    get _model() {
        return this.#___private__model_1;
    }
    set _model(_v) {
        this.#___private__model_1 = _v;
    }
    #___private__renderers_2;
    get _renderers() {
        return this.#___private__renderers_2;
    }
    set _renderers(_v) {
        this.#___private__renderers_2 = _v;
    }
    #___private__service_3;
    get _service() {
        return this.#___private__service_3;
    }
    set _service(_v) {
        this.#___private__service_3 = _v;
    }
    #___private_doc_4;
    get doc() {
        return this.#___private_doc_4;
    }
    set doc(_v) {
        this.#___private_doc_4 = _v;
    }
    #___private_std_5;
    get std() {
        return this.#___private_std_5;
    }
    set std(_v) {
        this.#___private_std_5 = _v;
    }
    #___private_viewType_6;
    get viewType() {
        return this.#___private_viewType_6;
    }
    set viewType(_v) {
        this.#___private_viewType_6 = _v;
    }
    #___private_widgets_7;
    get widgets() {
        return this.#___private_widgets_7;
    }
    set widgets(_v) {
        this.#___private_widgets_7 = _v;
    }
    static{
        _initClass();
    }
    constructor(...args){
        super(...args), this._selected = computed(()=>{
            const selection = this.std.selection.value.find((selection)=>{
                return selection.blockId === this.model?.id;
            });
            if (!selection) {
                return null;
            }
            return selection;
        }), this[blockComponentSymbol] = true, this.handleEvent = (name, handler, options)=>{
            this._disposables.add(this.host.event.add(name, handler, {
                flavour: options?.global ? undefined : options?.flavour ? this.model?.flavour : undefined,
                blockId: options?.global || options?.flavour ? undefined : this.blockId
            }));
        }, this.#___private__model_1 = (_initProto(this), _init__model(this, null)), this.#___private__renderers_2 = _init__renderers(this, [
            this.renderBlock,
            this._renderMismatchBlock,
            this._renderViewType
        ]), this.#___private__service_3 = _init__service(this, null), this.#___private_doc_4 = _init_doc(this), this.#___private_std_5 = _init_std(this), this.#___private_viewType_6 = _init_viewType(this, BlockViewType.Display), this.#___private_widgets_7 = _init_widgets(this);
    }
}
export { _BlockComponent as BlockComponent };
