import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@affine/component';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { BlockStdScope } from '@blocksuite/affine/block-std';
import { SpecProvider } from '@blocksuite/affine/blocks';
import { Bound } from '@blocksuite/affine/global/utils';
import { useFramework } from '@toeverything/infra';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import { map, pairwise } from 'rxjs';
import { snapshotContainer, snapshotLabel, snapshotSkeleton, snapshotTitle } from '../style.css';
import { getDocByName } from './docs';
import { getFrameBlock } from './utils';
const boundMap = new Map();
export const EdgelessSnapshot = (props)=>{
    const { title, docName, keyName, height = 180, getElements, firstUpdate, children } = props;
    const wrapperRef = useRef(null);
    const docRef = useRef(null);
    const editorHostRef = useRef(null);
    const framework = useFramework();
    const { editorSetting } = framework.get(EditorSettingService);
    const updateElements = useCallback(()=>{
        const editorHost = editorHostRef.current;
        const doc = docRef.current;
        if (!editorHost || !doc) return;
        const edgelessService = editorHost.std.getService('affine:page');
        const elements = getElements(doc);
        const props = editorSetting.get(keyName);
        elements.forEach((element)=>{
            edgelessService.updateElement(element.id, props);
        });
    }, [
        editorSetting,
        getElements,
        keyName
    ]);
    const renderEditor = useCallback(async ()=>{
        if (!wrapperRef.current) return;
        const doc = await getDocByName(docName);
        if (!doc) return;
        const editorHost = new BlockStdScope({
            doc,
            extensions: SpecProvider.getInstance().getSpec('edgeless:preview').value
        }).render();
        docRef.current = doc;
        editorHostRef.current = editorHost;
        if (firstUpdate) {
            firstUpdate(doc, editorHost);
        } else {
            updateElements();
        }
        const edgelessService = editorHost.std.getService('affine:page');
        edgelessService.specSlots.viewConnected.once(({ component })=>{
            const edgelessBlock = component;
            edgelessBlock.editorViewportSelector = 'ref-viewport';
            const frame = getFrameBlock(doc);
            if (frame) {
                boundMap.set(docName, Bound.deserialize(frame.xywh));
                doc.deleteBlock(frame);
            }
            const bound = boundMap.get(docName);
            bound && edgelessService.viewport.setViewportByBound(bound);
        });
        wrapperRef.current.append(editorHost);
    }, [
        docName,
        firstUpdate,
        updateElements
    ]);
    useEffect(()=>{
        renderEditor();
        return ()=>editorHostRef.current?.remove();
    }, [
        renderEditor
    ]);
    useEffect(()=>{
        const sub = editorSetting.provider.watchAll().pipe(map((settings)=>{
            if (typeof settings[keyName] === 'string') {
                return JSON.parse(settings[keyName]);
            }
            return keyName;
        }), pairwise()).subscribe(([prev, current])=>{
            if (!isEqual(prev, current)) {
                updateElements();
            }
        });
        return ()=>sub.unsubscribe();
    }, [
        editorSetting.provider,
        keyName,
        updateElements
    ]);
    return _jsxs("div", {
        className: snapshotContainer,
        children: [
            _jsx("div", {
                className: snapshotTitle,
                children: title
            }),
            _jsx("div", {
                className: snapshotLabel,
                children: title
            }),
            _jsx("div", {
                ref: wrapperRef,
                style: {
                    position: 'relative',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    overflow: 'hidden',
                    height
                },
                children: _jsx(Skeleton, {
                    className: snapshotSkeleton,
                    variant: "rounded",
                    height: '100%'
                })
            }),
            children
        ]
    });
};
