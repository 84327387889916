import { nanoid } from '@blocksuite/store';
import { computed, signal } from '@preact/signals-core';
export class ViewManagerBase {
    get viewMetas() {
        return this.dataSource.viewMetas;
    }
    constructor(dataSource){
        this.dataSource = dataSource;
        this._currentViewId$ = signal(undefined);
        this.currentView$ = computed(()=>{
            return this.viewGet(this.currentViewId$.value);
        });
        this.currentViewId$ = computed(()=>{
            return this._currentViewId$.value ?? this.views$.value[0];
        });
        this.readonly$ = computed(()=>{
            return this.dataSource.readonly$.value;
        });
        this.views$ = computed(()=>{
            return this.dataSource.viewDataList$.value.map((data)=>data.id);
        });
    }
    moveTo(id, position) {
        this.dataSource.viewDataMoveTo(id, position);
    }
    setCurrentView(id) {
        this._currentViewId$.value = id;
    }
    viewAdd(type) {
        const meta = this.dataSource.viewMetaGet(type);
        const data = meta.model.defaultData(this);
        const id = this.dataSource.viewDataAdd({
            ...data,
            id: nanoid(),
            name: meta.model.defaultName,
            mode: type
        });
        this.setCurrentView(id);
        return id;
    }
    viewChangeType(id, type) {
        const from = this.viewGet(id).type;
        const meta = this.dataSource.viewMetaGet(type);
        this.dataSource.viewDataUpdate(id, (old)=>{
            let data = {
                ...meta.model.defaultData(this),
                id: old.id,
                name: old.name,
                mode: type
            };
            const convertFunction = this.dataSource.viewConverts.find((v)=>v.from === from && v.to === type);
            if (convertFunction) {
                data = {
                    ...data,
                    ...convertFunction.convert(old)
                };
            }
            return data;
        });
    }
    viewDataGet(id) {
        return this.dataSource.viewDataGet(id);
    }
    viewDelete(id) {
        this.dataSource.viewDataDelete(id);
        this.setCurrentView(this.views$.value[0]);
    }
    viewDuplicate(id) {
        const newId = this.dataSource.viewDataDuplicate(id);
        this.setCurrentView(newId);
    }
    viewGet(id) {
        const meta = this.dataSource.viewMetaGetById(id);
        return new meta.model.dataViewManager(this, id);
    }
}
