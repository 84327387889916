import { jsx as _jsx } from "react/jsx-runtime";
import { track } from '@affine/track';
import { ArrowRightBigIcon } from '@blocksuite/icons/rc';
import { openSettingModalAtom, openWorkspaceListModalAtom } from '../components/atoms';
import { registerAffineCommand } from './registry';
export function registerAffineNavigationCommands({ t, store, docCollection, navigationHelper }) {
    const unsubs = [];
    unsubs.push(registerAffineCommand({
        id: 'affine:goto-all-pages',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: t['com.affine.cmdk.affine.navigation.goto-all-pages'](),
        run () {
            track.$.cmdk.navigation.navigate({
                to: 'allDocs'
            });
            navigationHelper.jumpToPage(docCollection.id, 'all');
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:goto-collection-list',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: 'Go to Collection List',
        run () {
            track.$.cmdk.navigation.navigate({
                to: 'collectionList'
            });
            navigationHelper.jumpToCollections(docCollection.id);
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:goto-tag-list',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: 'Go to Tag List',
        run () {
            track.$.cmdk.navigation.navigate({
                to: 'tagList'
            });
            navigationHelper.jumpToTags(docCollection.id);
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:goto-workspace',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: t['com.affine.cmdk.affine.navigation.goto-workspace'](),
        run () {
            track.$.cmdk.navigation.navigate({
                to: 'workspace'
            });
            store.set(openWorkspaceListModalAtom, true);
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:open-settings',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: t['com.affine.cmdk.affine.navigation.open-settings'](),
        keyBinding: '$mod+,',
        run () {
            track.$.cmdk.settings.openSettings();
            store.set(openSettingModalAtom, (s)=>({
                    activeTab: 'appearance',
                    open: !s.open
                }));
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:open-account',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: t['com.affine.cmdk.affine.navigation.open-account-settings'](),
        run () {
            track.$.cmdk.settings.openSettings({
                to: 'account'
            });
            store.set(openSettingModalAtom, (s)=>({
                    activeTab: 'account',
                    open: !s.open
                }));
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:goto-trash',
        category: 'affine:navigation',
        icon: _jsx(ArrowRightBigIcon, {}),
        label: t['com.affine.cmdk.affine.navigation.goto-trash'](),
        run () {
            track.$.cmdk.navigation.navigate({
                to: 'trash'
            });
            navigationHelper.jumpToPage(docCollection.id, 'trash');
        }
    }));
    return ()=>{
        unsubs.forEach((unsub)=>unsub());
    };
}
