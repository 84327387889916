import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppSidebarFallback, ShellAppSidebarFallback } from '@affine/core/modules/app-sidebar/views';
import clsx from 'clsx';
import { useAppSettingHelper } from '../../components/hooks/affine/use-app-setting-helper';
import { AppContainer as AppContainerWithoutSettings, MainContainerFallback } from '../workspace';
import * as styles from './app-container.css';
export const AppContainer = (props)=>{
    const { appSettings } = useAppSettingHelper();
    return _jsx(AppContainerWithoutSettings, {
        useNoisyBackground: appSettings.enableNoisyBackground,
        useBlurBackground: appSettings.enableBlurBackground,
        ...props
    });
};
export const AppFallback = ({ className, children })=>{
    return _jsxs(AppContainer, {
        className: clsx(className, BUILD_CONFIG.isElectron && styles.electronFallback),
        children: [
            _jsx(AppSidebarFallback, {}),
            _jsx(MainContainerFallback, {
                children: children
            })
        ]
    });
};
export const ShellAppFallback = ({ className, children })=>{
    return _jsxs(AppContainer, {
        className: className,
        children: [
            _jsx(ShellAppSidebarFallback, {}),
            _jsx(MainContainerFallback, {
                children: children
            })
        ]
    });
};
