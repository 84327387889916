import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '@blocksuite/affine-shared/consts';
import { Bound } from '@blocksuite/global/utils';
import { toEdgelessEmbedBlock } from '../common/to-edgeless-embed-block.js';
import { EmbedLinkedDocBlockComponent } from './embed-linked-doc-block.js';
export class EmbedEdgelessLinkedDocBlockComponent extends toEdgelessEmbedBlock(EmbedLinkedDocBlockComponent) {
    get rootService() {
        return this.std.getService('affine:page');
    }
    _handleClick(evt) {
        if (this.config.handleClick) {
            this.config.handleClick(evt, this.host, this.referenceInfo);
            return;
        }
    }
    constructor(...args){
        super(...args), this.convertToEmbed = ()=>{
            const { id, doc, pageId, caption, xywh } = this.model;
            const isSyncedDocEnabled = doc.awarenessStore.getFlag('enable_synced_doc_block');
            if (!isSyncedDocEnabled) {
                return;
            }
            const style = 'syncedDoc';
            const bound = Bound.deserialize(xywh);
            bound.w = EMBED_CARD_WIDTH[style];
            bound.h = EMBED_CARD_HEIGHT[style];
            const edgelessService = this.rootService;
            if (!edgelessService) {
                return;
            }
            const newId = edgelessService.addBlock('affine:embed-synced-doc', {
                pageId,
                xywh: bound.serialize(),
                caption
            }, edgelessService.surface);
            this.std.command.exec('reassociateConnectors', {
                oldId: id,
                newId
            });
            edgelessService.selection.set({
                editing: false,
                elements: [
                    newId
                ]
            });
            doc.deleteBlock(this.model);
        };
    }
}
