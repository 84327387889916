import { withTempBlobData } from '@blocksuite/affine-shared/utils';
import { html } from 'lit';
import { transformModel } from '../root-block/utils/operations/model.js';
const MAX_EMBED_SIZE = 10 * 1024 * 1024;
const embedConfig = [
    {
        name: 'image',
        check: (model)=>model.doc.schema.flavourSchemaMap.has('affine:image') && model.type.startsWith('image/'),
        action: (model)=>turnIntoImageBlock(model)
    },
    {
        name: 'pdf',
        check: (model, maxFileSize)=>model.type === 'application/pdf' && model.size <= maxFileSize,
        template: (_, blobUrl)=>{
            const parameters = '#toolbar=0';
            return html`<iframe
        style="width: 100%; color-scheme: auto;"
        height="480"
        src=${blobUrl + parameters}
        loading="lazy"
        scrolling="no"
        frameborder="no"
        allowTransparency
        allowfullscreen
        type="application/pdf"
      ></iframe>`;
        }
    },
    {
        name: 'video',
        check: (model, maxFileSize)=>model.type.startsWith('video/') && model.size <= maxFileSize,
        template: (_, blobUrl)=>html`<video width="100%;" height="480" controls src=${blobUrl}></video>`
    },
    {
        name: 'audio',
        check: (model, maxFileSize)=>model.type.startsWith('audio/') && model.size <= maxFileSize,
        template: (_, blobUrl)=>html`<audio controls src=${blobUrl} style="margin: 4px;"></audio>`
    }
];
export function allowEmbed(model, maxFileSize = MAX_EMBED_SIZE) {
    return embedConfig.some((config)=>config.check(model, maxFileSize));
}
export function convertToEmbed(model, maxFileSize = MAX_EMBED_SIZE) {
    const config = embedConfig.find((config)=>config.check(model, maxFileSize));
    if (!config || !config.action) {
        model.doc.updateBlock(model, {
            embed: true
        });
        return;
    }
    config.action(model)?.catch(console.error);
}
export function renderEmbedView(model, blobUrl, maxFileSize = MAX_EMBED_SIZE) {
    const config = embedConfig.find((config)=>config.check(model, maxFileSize));
    if (!config || !config.template) {
        console.error('No embed view template found!', model, embedConfig);
        return null;
    }
    return config.template(model, blobUrl);
}
export function turnIntoImageBlock(model) {
    if (!model.doc.schema.flavourSchemaMap.has('affine:image')) {
        console.error('The image flavour is not supported!');
        return;
    }
    const sourceId = model.sourceId;
    if (!sourceId) return;
    const { saveAttachmentData, getImageData } = withTempBlobData();
    saveAttachmentData(sourceId, {
        name: model.name
    });
    const imageConvertData = model.sourceId ? getImageData(model.sourceId) : undefined;
    const imageProp = {
        sourceId,
        caption: model.caption,
        size: model.size,
        ...imageConvertData
    };
    transformModel(model, 'affine:image', imageProp);
}
