import { AIOnboardingType } from './type';
function createStorageEvent(key, newValue) {
    const event = new StorageEvent('storage', {
        key,
        newValue,
        oldValue: localStorage.getItem(key),
        storageArea: localStorage
    });
    window.dispatchEvent(event);
}
const setItem = function(key, value) {
    const oldValue = localStorage.getItem(key);
    localStorage.setItem.call(localStorage, key, value);
    if (oldValue !== value) createStorageEvent(key, value);
};
export const toggleGeneralAIOnboarding = (show = true)=>{
    setItem(AIOnboardingType.GENERAL, show ? 'false' : 'true');
};
export const toggleLocalAIOnboarding = (show = true)=>{
    setItem(AIOnboardingType.LOCAL, show ? 'false' : 'true');
};
export const toggleEdgelessAIOnboarding = (show = true)=>{
    setItem(AIOnboardingType.EDGELESS, show ? 'false' : 'true');
};
