import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Tooltip, useDraggable } from '@affine/component';
import { TagService } from '@affine/core/modules/tag';
import { stopPropagation } from '@affine/core/utils';
import { i18nTime } from '@affine/i18n';
import { useLiveData, useService } from '@toeverything/infra';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { WorkbenchLink } from '../../../modules/workbench/view/workbench-link';
import { anchorIndexAtom, rangeIdsAtom, selectionStateAtom, useAtom } from '../scoped-atoms';
import { useAllDocDisplayProperties } from '../use-all-doc-display-properties';
import { ColWrapper } from '../utils';
import * as styles from './page-list-item.css';
import { PageTags } from './page-tags';
const ListTitleCell = ({ title, preview })=>{
    const [displayProperties] = useAllDocDisplayProperties();
    return _jsxs("div", {
        "data-testid": "page-list-item-title",
        className: styles.titleCell,
        children: [
            _jsx("div", {
                "data-testid": "page-list-item-title-text",
                className: styles.titleCellMain,
                children: title
            }),
            preview && displayProperties.displayProperties.bodyNotes ? _jsx("div", {
                "data-testid": "page-list-item-preview-text",
                className: styles.titleCellPreview,
                children: preview
            }) : null
        ]
    });
};
const ListIconCell = ({ icon })=>{
    return _jsx("div", {
        "data-testid": "page-list-item-icon",
        className: styles.iconCell,
        children: icon
    });
};
const PageSelectionCell = ({ selectable, onSelectedChange, selected })=>{
    const onSelectionChange = useCallback((_event)=>{
        return onSelectedChange?.();
    }, [
        onSelectedChange
    ]);
    if (!selectable) {
        return null;
    }
    return _jsx("div", {
        className: styles.selectionCell,
        children: _jsx(Checkbox, {
            onClick: stopPropagation,
            checked: !!selected,
            onChange: onSelectionChange
        })
    });
};
export const PageTagsCell = ({ pageId })=>{
    const tagList = useService(TagService).tagList;
    const tags = useLiveData(tagList.tagsByPageId$(pageId));
    return _jsx("div", {
        "data-testid": "page-list-item-tags",
        className: styles.tagsCell,
        children: _jsx(PageTags, {
            tags: tags,
            hoverExpandDirection: "left",
            widthOnHover: "300%",
            maxItems: 5
        })
    });
};
const PageCreateDateCell = ({ createDate })=>{
    return _jsx(Tooltip, {
        content: i18nTime(createDate),
        children: _jsx("div", {
            "data-testid": "page-list-item-date",
            "data-date-raw": createDate,
            className: styles.dateCell,
            children: i18nTime(createDate, {
                relative: true
            })
        })
    });
};
const PageUpdatedDateCell = ({ updatedDate })=>{
    return _jsx(Tooltip, {
        content: updatedDate ? i18nTime(updatedDate) : undefined,
        children: _jsx("div", {
            "data-testid": "page-list-item-date",
            "data-date-raw": updatedDate,
            className: styles.dateCell,
            children: updatedDate ? i18nTime(updatedDate, {
                relative: true
            }) : '-'
        })
    });
};
const PageListOperationsCell = ({ operations })=>{
    return operations ? _jsx("div", {
        onClick: stopPropagation,
        className: styles.operationsCell,
        children: operations
    }) : null;
};
export const PageListItem = (props)=>{
    const [displayProperties] = useAllDocDisplayProperties();
    const pageTitleElement = useMemo(()=>{
        return _jsxs("div", {
            className: styles.dragPageItemOverlay,
            children: [
                _jsxs("div", {
                    className: styles.titleIconsWrapper,
                    children: [
                        _jsx(PageSelectionCell, {
                            onSelectedChange: props.onSelectedChange,
                            selectable: props.selectable,
                            selected: props.selected
                        }),
                        _jsx(ListIconCell, {
                            icon: props.icon
                        })
                    ]
                }),
                _jsx(ListTitleCell, {
                    title: props.title,
                    preview: props.preview
                })
            ]
        });
    }, [
        props.icon,
        props.onSelectedChange,
        props.preview,
        props.selectable,
        props.selected,
        props.title
    ]);
    const { dragRef, CustomDragPreview, dragging } = useDraggable(()=>({
            canDrag: props.draggable,
            data: {
                entity: {
                    type: 'doc',
                    id: props.pageId
                },
                from: {
                    at: 'all-docs:list'
                }
            }
        }), [
        props.draggable,
        props.pageId
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsxs(PageListItemWrapper, {
                onClick: props.onClick,
                to: props.to,
                pageId: props.pageId,
                draggable: props.draggable,
                isDragging: dragging,
                ref: dragRef,
                pageIds: props.pageIds || [],
                children: [
                    _jsxs(ColWrapper, {
                        flex: 9,
                        children: [
                            _jsxs(ColWrapper, {
                                className: styles.dndCell,
                                flex: 8,
                                children: [
                                    _jsxs("div", {
                                        className: styles.titleIconsWrapper,
                                        children: [
                                            _jsx(PageSelectionCell, {
                                                onSelectedChange: props.onSelectedChange,
                                                selectable: props.selectable,
                                                selected: props.selected
                                            }),
                                            _jsx(ListIconCell, {
                                                icon: props.icon
                                            })
                                        ]
                                    }),
                                    _jsx(ListTitleCell, {
                                        title: props.title,
                                        preview: props.preview
                                    })
                                ]
                            }),
                            _jsx(ColWrapper, {
                                flex: 4,
                                alignment: "end",
                                style: {
                                    overflow: 'visible'
                                },
                                hidden: !displayProperties.displayProperties.tags,
                                children: _jsx(PageTagsCell, {
                                    pageId: props.pageId
                                })
                            })
                        ]
                    }),
                    _jsx(ColWrapper, {
                        flex: 1,
                        alignment: "end",
                        hideInSmallContainer: true,
                        hidden: !displayProperties.displayProperties.createDate,
                        children: _jsx(PageCreateDateCell, {
                            createDate: props.createDate
                        })
                    }),
                    _jsx(ColWrapper, {
                        flex: 1,
                        alignment: "end",
                        hideInSmallContainer: true,
                        hidden: !displayProperties.displayProperties.updatedDate,
                        children: _jsx(PageUpdatedDateCell, {
                            updatedDate: props.updatedDate
                        })
                    }),
                    props.operations ? _jsx(ColWrapper, {
                        className: styles.actionsCellWrapper,
                        flex: 1,
                        alignment: "end",
                        children: _jsx(PageListOperationsCell, {
                            operations: props.operations
                        })
                    }) : null
                ]
            }),
            _jsx(CustomDragPreview, {
                position: "pointer-outside",
                children: pageTitleElement
            })
        ]
    });
};
const PageListItemWrapper = forwardRef(({ to, isDragging, pageId, pageIds, onClick, children, draggable }, ref)=>{
    const [selectionState, setSelectionActive] = useAtom(selectionStateAtom);
    const [anchorIndex, setAnchorIndex] = useAtom(anchorIndexAtom);
    const [rangeIds, setRangeIds] = useAtom(rangeIdsAtom);
    const handleShiftClick = useCallback((currentIndex)=>{
        if (anchorIndex === undefined) {
            setAnchorIndex(currentIndex);
            onClick?.();
            return;
        }
        const lowerIndex = Math.min(anchorIndex, currentIndex);
        const upperIndex = Math.max(anchorIndex, currentIndex);
        const newRangeIds = pageIds.slice(lowerIndex, upperIndex + 1);
        const currentSelected = selectionState.selectedIds || [];
        const setRange = new Set(rangeIds);
        const newSelected = new Set(currentSelected.filter((id)=>!setRange.has(id)).concat(newRangeIds));
        selectionState.onSelectedIdsChange?.([
            ...newSelected
        ]);
        setRangeIds(newRangeIds);
    }, [
        anchorIndex,
        onClick,
        pageIds,
        selectionState,
        setAnchorIndex,
        rangeIds,
        setRangeIds
    ]);
    const handleClick = useCallback((e)=>{
        if (!selectionState.selectable) {
            return;
        }
        e.stopPropagation();
        const currentIndex = pageIds.indexOf(pageId);
        if (e.shiftKey) {
            e.preventDefault();
            if (!selectionState.selectionActive) {
                setSelectionActive(true);
                setAnchorIndex(currentIndex);
                onClick?.();
            } else {
                handleShiftClick(currentIndex);
            }
        } else {
            setAnchorIndex(undefined);
            setRangeIds([]);
            onClick?.();
            return;
        }
    }, [
        handleShiftClick,
        onClick,
        pageId,
        pageIds,
        selectionState.selectable,
        selectionState.selectionActive,
        setAnchorIndex,
        setRangeIds,
        setSelectionActive
    ]);
    const commonProps = useMemo(()=>({
            'data-testid': 'page-list-item',
            'data-page-id': pageId,
            'data-draggable': draggable,
            className: styles.root,
            'data-clickable': !!onClick || !!to,
            'data-dragging': isDragging,
            onClick: onClick ? handleClick : undefined
        }), [
        pageId,
        draggable,
        onClick,
        to,
        isDragging,
        handleClick
    ]);
    useEffect(()=>{
        if (selectionState.selectionActive) {
            const handleKeyUp = (e)=>{
                if (e.key === 'Shift') {
                    setAnchorIndex(undefined);
                    setRangeIds([]);
                }
            };
            window.addEventListener('keyup', handleKeyUp);
            return ()=>{
                window.removeEventListener('keyup', handleKeyUp);
            };
        }
        return;
    }, [
        selectionState.selectionActive,
        setAnchorIndex,
        setRangeIds,
        setSelectionActive
    ]);
    if (to) {
        return _jsx(WorkbenchLink, {
            ref: ref,
            ...commonProps,
            to: to,
            children: children
        });
    } else {
        return _jsx("div", {
            ref: ref,
            ...commonProps,
            children: children
        });
    }
});
PageListItemWrapper.displayName = 'PageListItemWrapper';
