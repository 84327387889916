import { notify } from '@affine/component';
import { generateUrl } from '@affine/core/components/hooks/affine/use-share-url';
import { getAffineCloudBaseUrl } from '@affine/core/modules/cloud/services/fetch';
import { EditorService } from '@affine/core/modules/editor';
import { I18n } from '@affine/i18n';
import { track } from '@affine/track';
import { LinkIcon } from '@blocksuite/icons/lit';
export function createToolbarMoreMenuConfig(framework) {
    return {
        configure: (groups)=>{
            const clipboardGroup = groups.find((group)=>group.type === 'clipboard');
            if (clipboardGroup) {
                let copyIndex = clipboardGroup.items.findIndex((item)=>item.type === 'copy');
                if (copyIndex === -1) {
                    copyIndex = clipboardGroup.items.findIndex((item)=>item.type === 'duplicate');
                    if (copyIndex !== -1) {
                        copyIndex -= 1;
                    }
                }
                clipboardGroup.items.splice(copyIndex + 1, 0, createCopyLinkToBlockMenuItem(framework));
            }
            return groups;
        }
    };
}
function createCopyLinkToBlockMenuItem(framework, item = {
    icon: LinkIcon({
        width: '20',
        height: '20'
    }),
    label: 'Copy link to block',
    type: 'copy-link-to-block',
    when: (ctx)=>{
        if (ctx.isEmpty()) return false;
        const { editor } = framework.get(EditorService);
        const mode = editor.mode$.value;
        if (mode === 'edgeless') {
            if (ctx.selectedBlockModels.length > 0) {
                return false;
            }
            if (ctx.isMultiple()) {
                return false;
            }
        }
        return true;
    }
}) {
    return {
        ...item,
        action: (ctx)=>{
            const baseUrl = getAffineCloudBaseUrl();
            if (!baseUrl) {
                ctx.close();
                return;
            }
            const { editor } = framework.get(EditorService);
            const mode = editor.mode$.value;
            const pageId = editor.doc.id;
            const workspaceId = editor.doc.workspace.id;
            const options = {
                workspaceId,
                pageId,
                shareMode: mode
            };
            let type = '';
            if (mode === 'page') {
                const blockIds = ctx.selectedBlockModels.map((model)=>model.id);
                options.blockIds = blockIds;
                type = ctx.selectedBlockModels[0].flavour;
            } else if (mode === 'edgeless' && ctx.firstElement) {
                const id = ctx.firstElement.id;
                if (ctx.isElement()) {
                    options.elementIds = [
                        id
                    ];
                    type = ctx.firstElement.type;
                } else {
                    options.blockIds = [
                        id
                    ];
                    type = ctx.firstElement.flavour;
                }
            }
            const str = generateUrl(options);
            if (!str) {
                ctx.close();
                return;
            }
            ctx.std.clipboard.writeToClipboard((items)=>{
                items['text/plain'] = str;
                items['text/html'] = `<a href="${str}">${str}</a>`;
                return items;
            }).then(()=>{
                track.doc.editor.toolbar.copyBlockToLink({
                    type
                });
                notify.success({
                    title: I18n['Copied link to clipboard']()
                });
            }).catch(console.error);
            ctx.close();
        }
    };
}
