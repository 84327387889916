import { jsx as _jsx } from "react/jsx-runtime";
import { useMount } from '@toeverything/infra';
import { useCallback, useEffect, useState } from 'react';
import { CreateCollectionModal } from './create-collection';
import { EditCollectionModal } from './edit-collection/edit-collection';
export const useEditCollection = ()=>{
    const [data, setData] = useState();
    const close = useCallback((open)=>{
        if (!open) {
            setData(undefined);
        }
    }, []);
    const { mount } = useMount('useEditCollection');
    useEffect(()=>{
        if (!data) return;
        return mount(_jsx(EditCollectionModal, {
            init: data?.collection,
            open: !!data,
            mode: data?.mode,
            onOpenChange: close,
            onConfirm: data?.onConfirm ?? (()=>{})
        }));
    }, [
        close,
        data,
        mount
    ]);
    return {
        open: (collection, mode)=>new Promise((res)=>{
                setData({
                    collection,
                    mode,
                    onConfirm: (collection)=>{
                        res(collection);
                    }
                });
            })
    };
};
export const useEditCollectionName = ({ title, showTips })=>{
    const [data, setData] = useState();
    const close = useCallback((open)=>{
        if (!open) {
            setData(undefined);
        }
    }, []);
    const { mount } = useMount('useEditCollectionName');
    useEffect(()=>{
        if (!data) return;
        return mount(_jsx(CreateCollectionModal, {
            showTips: showTips,
            title: title,
            init: data?.name ?? '',
            open: !!data,
            onOpenChange: close,
            onConfirm: data?.onConfirm ?? (()=>{})
        }));
    }, [
        close,
        data,
        mount,
        showTips,
        title
    ]);
    return {
        open: (name)=>new Promise((res)=>{
                setData({
                    name,
                    onConfirm: (collection)=>{
                        res(collection);
                    }
                });
            })
    };
};
