import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton, MenuItem, MenuSeparator, toast, useConfirmModal } from '@affine/component';
import { usePageHelper } from '@affine/core/components/blocksuite/block-suite-page-list/utils';
import { useBlockSuiteMetaHelper } from '@affine/core/components/hooks/affine/use-block-suite-meta-helper';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { IsFavoriteIcon } from '@affine/core/components/pure/icons';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { DeleteIcon, DuplicateIcon, InformationIcon, LinkedPageIcon, OpenInNewIcon, PlusIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { DocsService, FeatureFlagService, useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
export const useExplorerDocNodeOperations = (docId, options)=>{
    const t = useI18n();
    const { workbenchService, workspaceService, docsService, compatibleFavoriteItemsAdapter, featureFlagService } = useServices({
        DocsService,
        WorkbenchService,
        WorkspaceService,
        CompatibleFavoriteItemsAdapter,
        FeatureFlagService
    });
    const enableMultiView = useLiveData(featureFlagService.flags.enable_multi_view.$);
    const { openConfirmModal } = useConfirmModal();
    const docRecord = useLiveData(docsService.list.doc$(docId));
    const { createPage } = usePageHelper(workspaceService.workspace.docCollection);
    const favorite = useLiveData(useMemo(()=>{
        return compatibleFavoriteItemsAdapter.isFavorite$(docId, 'doc');
    }, [
        docId,
        compatibleFavoriteItemsAdapter
    ]));
    const { duplicate } = useBlockSuiteMetaHelper();
    const handleDuplicate = useCallback(()=>{
        duplicate(docId, true);
        track.$.navigationPanel.docs.createDoc();
    }, [
        docId,
        duplicate
    ]);
    const handleOpenInfoModal = useCallback(()=>{
        track.$.docInfoPanel.$.open();
        options.openInfoModal();
    }, [
        options
    ]);
    const handleMoveToTrash = useCallback(()=>{
        if (!docRecord) {
            return;
        }
        openConfirmModal({
            title: t['com.affine.moveToTrash.title'](),
            description: t['com.affine.moveToTrash.confirmModal.description']({
                title: docRecord.title$.value
            }),
            confirmText: t['com.affine.moveToTrash.confirmModal.confirm'](),
            cancelText: t['com.affine.moveToTrash.confirmModal.cancel'](),
            confirmButtonOptions: {
                variant: 'error'
            },
            onConfirm () {
                docRecord.moveToTrash();
                track.$.navigationPanel.docs.deleteDoc({
                    control: 'button'
                });
                toast(t['com.affine.toastMessage.movedTrash']());
            }
        });
    }, [
        docRecord,
        openConfirmModal,
        t
    ]);
    const handleOpenInNewTab = useCallback(()=>{
        workbenchService.workbench.openDoc(docId, {
            at: 'new-tab'
        });
        track.$.navigationPanel.organize.openInNewTab({
            type: 'doc'
        });
    }, [
        docId,
        workbenchService
    ]);
    const handleOpenInSplitView = useCallback(()=>{
        workbenchService.workbench.openDoc(docId, {
            at: 'beside'
        });
        track.$.navigationPanel.organize.openInSplitView({
            type: 'doc'
        });
    }, [
        docId,
        workbenchService.workbench
    ]);
    const handleAddLinkedPage = useAsyncCallback(async ()=>{
        const newDoc = createPage();
        await docsService.addLinkedDoc(docId, newDoc.id);
        track.$.navigationPanel.docs.createDoc({
            control: 'linkDoc'
        });
        track.$.navigationPanel.docs.linkDoc({
            control: 'createDoc'
        });
        workbenchService.workbench.openDoc(newDoc.id);
        options.openNodeCollapsed();
    }, [
        createPage,
        docsService,
        docId,
        workbenchService.workbench,
        options
    ]);
    const handleToggleFavoriteDoc = useCallback(()=>{
        compatibleFavoriteItemsAdapter.toggle(docId, 'doc');
        track.$.navigationPanel.organize.toggleFavorite({
            type: 'doc'
        });
    }, [
        docId,
        compatibleFavoriteItemsAdapter
    ]);
    return useMemo(()=>[
            {
                index: 0,
                inline: true,
                view: _jsx(IconButton, {
                    size: "16",
                    icon: _jsx(PlusIcon, {}),
                    tooltip: t['com.affine.rootAppSidebar.explorer.doc-add-tooltip'](),
                    onClick: handleAddLinkedPage
                })
            },
            {
                index: 50,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(InformationIcon, {}),
                    onClick: handleOpenInfoModal,
                    children: t['com.affine.page-properties.page-info.view']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(LinkedPageIcon, {}),
                    onClick: handleAddLinkedPage,
                    children: t['com.affine.page-operation.add-linked-page']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(DuplicateIcon, {}),
                    onClick: handleDuplicate,
                    children: t['com.affine.header.option.duplicate']()
                })
            },
            {
                index: 99,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(OpenInNewIcon, {}),
                    onClick: handleOpenInNewTab,
                    children: t['com.affine.workbench.tab.page-menu-open']()
                })
            },
            ...BUILD_CONFIG.isElectron && enableMultiView ? [
                {
                    index: 100,
                    view: _jsx(MenuItem, {
                        prefixIcon: _jsx(SplitViewIcon, {}),
                        onClick: handleOpenInSplitView,
                        children: t['com.affine.workbench.split-view.page-menu-open']()
                    })
                }
            ] : [],
            {
                index: 199,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(IsFavoriteIcon, {
                        favorite: favorite
                    }),
                    onClick: handleToggleFavoriteDoc,
                    children: favorite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
                })
            },
            {
                index: 9999,
                view: _jsx(MenuSeparator, {}, "menu-separator")
            },
            {
                index: 10000,
                view: _jsx(MenuItem, {
                    type: 'danger',
                    prefixIcon: _jsx(DeleteIcon, {}),
                    onClick: handleMoveToTrash,
                    children: t['com.affine.moveToTrash.title']()
                })
            }
        ], [
        enableMultiView,
        favorite,
        handleAddLinkedPage,
        handleDuplicate,
        handleMoveToTrash,
        handleOpenInNewTab,
        handleOpenInSplitView,
        handleOpenInfoModal,
        handleToggleFavoriteDoc,
        t
    ]);
};
