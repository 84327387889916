function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init_config, _init_generatingElement, _init_state, _initProto;
import { NotificationProvider } from '@blocksuite/affine-shared/services';
import { getPageRootByElement, stopPropagation } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
import { autoPlacement, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { css, html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { PageRootService } from '../../page/page-root-service.js';
import { AFFINE_FORMAT_BAR_WIDGET } from '../format-bar/format-bar.js';
import { AFFINE_VIEWPORT_OVERLAY_WIDGET } from '../viewport-overlay/viewport-overlay.js';
export const AFFINE_AI_PANEL_WIDGET = 'affine-ai-panel-widget';
_dec = property({
    attribute: false
}), _dec1 = query('ai-panel-generating'), _dec2 = property();
export class AffineAIPanelWidget extends WidgetComponent {
    static{
        ({ e: [_init_config, _init_generatingElement, _init_state, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "config"
            ],
            [
                _dec1,
                1,
                "generatingElement"
            ],
            [
                _dec2,
                1,
                "state"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: flex;
      outline: none;
      border-radius: var(--8, 8px);
      border: 1px solid var(--affine-border-color);
      background: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-overlay-shadow);

      position: absolute;
      width: max-content;
      height: auto;
      top: 0;
      left: 0;
      overflow-y: auto;
      scrollbar-width: none !important;
      z-index: var(--affine-z-index-popover);
    }

    .ai-panel-container {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      height: fit-content;
      padding: 8px 0;
    }

    .ai-panel-container:not(:has(ai-panel-generating)) {
      gap: 8px;
    }

    .ai-panel-container:has(ai-panel-answer),
    .ai-panel-container:has(ai-panel-error),
    .ai-panel-container:has(ai-panel-generating:has(generating-placeholder)) {
      padding: 12px 0;
    }

    :host([data-state='hidden']) {
      display: none;
    }
  `;
    }
    get answer() {
        return this._answer;
    }
    get inputText() {
        return this._inputText;
    }
    get viewportOverlayWidget() {
        const rootId = this.host.doc.root?.id;
        return rootId ? this.host.view.getWidget(AFFINE_VIEWPORT_OVERLAY_WIDGET, rootId) : null;
    }
    _autoUpdatePosition(reference) {
        {
            const childrenContainer = reference.querySelector('.affine-block-children-container');
            if (childrenContainer && childrenContainer.previousElementSibling) {
                reference = childrenContainer.previousElementSibling;
            }
        }
        this._stopAutoUpdate?.();
        this._stopAutoUpdate = autoUpdate(reference, this, ()=>{
            computePosition(reference, this, this._calcPositionOptions(reference)).then(({ x, y })=>{
                this.style.left = `${x}px`;
                this.style.top = `${y}px`;
            }).catch(console.error);
        });
    }
    _calcPositionOptions(reference) {
        let rootBoundary;
        {
            const rootService = this.host.std.getService('affine:page');
            if (rootService instanceof PageRootService) {
                rootBoundary = undefined;
            } else {
                const viewport = rootService.viewport;
                rootBoundary = {
                    x: viewport.left,
                    y: viewport.top,
                    width: viewport.width,
                    height: viewport.height - 100
                };
            }
        }
        const overflowOptions = {
            padding: 20,
            rootBoundary: rootBoundary
        };
        if (getPageRootByElement(reference)) {
            return {
                placement: 'bottom-start',
                middleware: [
                    offset(8),
                    shift(overflowOptions)
                ]
            };
        } else if (reference.closest('edgeless-block-portal-note')) {
            return {
                middleware: [
                    offset(8),
                    shift(overflowOptions),
                    autoPlacement({
                        ...overflowOptions,
                        allowedPlacements: [
                            'top-start',
                            'bottom-start'
                        ]
                    })
                ]
            };
        } else {
            return {
                placement: 'right-start',
                middleware: [
                    offset({
                        mainAxis: 16
                    }),
                    flip({
                        mainAxis: true,
                        crossAxis: true,
                        flipAlignment: true,
                        ...overflowOptions
                    }),
                    shift({
                        crossAxis: true,
                        ...overflowOptions
                    })
                ]
            };
        }
    }
    _restoreSelection() {
        if (this._selection) {
            this.host.selection.set([
                ...this._selection
            ]);
            if (this.state === 'hidden') {
                this._selection = undefined;
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.tabIndex = -1;
        this.disposables.addFromEvent(document, 'pointerdown', this._onDocumentClick);
        this.disposables.add(this.block.host.event.add('pointerDown', (evtState)=>this._onDocumentClick(evtState.get('pointerState').event)));
        this.disposables.add(this.block.host.event.add('click', ()=>{
            return this.state !== 'hidden' ? true : false;
        }));
        this.disposables.addFromEvent(this, 'wheel', stopPropagation);
        this.disposables.addFromEvent(this, 'pointerdown', stopPropagation);
        this.disposables.addFromEvent(this, 'pointerup', stopPropagation);
        this.disposables.addFromEvent(this, 'keydown', this._onKeyDown);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._clearDiscardModal();
        this._stopAutoUpdate?.();
    }
    render() {
        if (this.state === 'hidden') {
            return nothing;
        }
        if (!this.config) return nothing;
        const config = this.config;
        this.updateComplete.then(()=>{
            this.focus();
        }).catch(console.error);
        const mainTemplate = choose(this.state, [
            [
                'input',
                ()=>html`<ai-panel-input
            .onBlur=${()=>this.discard()}
            .onFinish=${this._inputFinish}
            .onInput=${this.onInput}
          ></ai-panel-input>`
            ],
            [
                'generating',
                ()=>html`
          ${this.answer ? html`
                <ai-panel-answer
                  .finish=${false}
                  .config=${config.finishStateConfig}
                  .host=${this.host}
                >
                  ${this.answer && config.answerRenderer(this.answer, this.state)}
                </ai-panel-answer>
              ` : nothing}
          <ai-panel-generating
            .config=${config.generatingStateConfig}
            .stopGenerating=${this.stopGenerating}
            .withAnswer=${!!this.answer}
          ></ai-panel-generating>
        `
            ],
            [
                'finished',
                ()=>html`
          <ai-panel-answer
            .config=${config.finishStateConfig}
            .copy=${config.copy}
            .host=${this.host}
          >
            ${this.answer && config.answerRenderer(this.answer, this.state)}
          </ai-panel-answer>
        `
            ],
            [
                'error',
                ()=>html`
          <ai-panel-error
            .config=${config.errorStateConfig}
            .copy=${config.copy}
            .withAnswer=${!!this.answer}
            .host=${this.host}
          >
            ${this.answer && config.answerRenderer(this.answer, this.state)}
          </ai-panel-error>
        `
            ]
        ]);
        return html`<div class="ai-panel-container">${mainTemplate}</div>`;
    }
    willUpdate(changed) {
        const prevState = changed.get('state');
        if (prevState) {
            if (prevState === 'hidden') {
                this._selection = this.host.selection.value;
                requestAnimationFrame(()=>{
                    this.scrollIntoView({
                        block: 'center'
                    });
                });
            } else {
                this.host.updateComplete.then(()=>{
                    if (this.state !== 'hidden') {
                        this.focus();
                    }
                }).catch(console.error);
                this._restoreSelection();
            }
            const rootBlockId = this.host.doc.root?.id;
            const formatBar = rootBlockId ? this.host.view.getWidget(AFFINE_FORMAT_BAR_WIDGET, rootBlockId) : null;
            if (formatBar) {
                formatBar.requestUpdate();
            }
        }
        if (this.state !== 'hidden') {
            this.viewportOverlayWidget?.lock();
        } else {
            this.viewportOverlayWidget?.unlock();
        }
        this.dataset.state = this.state;
    }
    #___private_config_1;
    get config() {
        return this.#___private_config_1;
    }
    set config(_v) {
        this.#___private_config_1 = _v;
    }
    #___private_generatingElement_2;
    get generatingElement() {
        return this.#___private_generatingElement_2;
    }
    set generatingElement(_v) {
        this.#___private_generatingElement_2 = _v;
    }
    #___private_state_3;
    get state() {
        return this.#___private_state_3;
    }
    set state(_v) {
        this.#___private_state_3 = _v;
    }
    constructor(...args){
        super(...args), this._abortController = new AbortController(), this._answer = null, this._cancelCallback = ()=>{
            this.focus();
        }, this._clearDiscardModal = ()=>{
            if (this._discardModalAbort) {
                this._discardModalAbort.abort();
                this._discardModalAbort = null;
            }
        }, this._clickOutside = ()=>{
            switch(this.state){
                case 'hidden':
                    return;
                case 'error':
                case 'finished':
                    if (!this._answer) {
                        this.hide();
                    } else {
                        this.discard();
                    }
                    break;
                default:
                    this.discard();
            }
        }, this._discardCallback = ()=>{
            this.hide();
            this.config?.discardCallback?.();
        }, this._discardModalAbort = null, this._inputFinish = (text)=>{
            this._inputText = text;
            this.generate();
        }, this._inputText = null, this._onDocumentClick = (e)=>{
            if (this.state !== 'hidden' && e.target !== this && !this.contains(e.target)) {
                this._clickOutside();
                return true;
            }
            return false;
        }, this._onKeyDown = (event)=>{
            event.stopPropagation();
            const { state } = this;
            if (state !== 'generating' && state !== 'input') {
                return;
            }
            const { key, isComposing } = event;
            if (key === 'Escape') {
                if (state === 'generating') {
                    this.stopGenerating();
                } else {
                    this.hide();
                }
                return;
            }
            if (key === 'Delete' || key === 'Backspace') {
                if (isComposing) return;
                if (state === 'input' && !this._inputText) {
                    this.hide();
                }
            }
        }, this._resetAbortController = ()=>{
            if (this.state === 'generating') {
                this._abortController.abort();
            }
            this._abortController = new AbortController();
        }, this.ctx = null, this.discard = ()=>{
            if ((this.state === 'finished' || this.state === 'error') && !this.answer) {
                this._discardCallback();
                return;
            }
            if (this.state === 'input') {
                this.hide();
                return;
            }
            this.showDiscardModal().then((discard)=>{
                if (discard) {
                    this._discardCallback();
                } else {
                    this._cancelCallback();
                }
                this._restoreSelection();
            }).catch(console.error);
        }, this.generate = ()=>{
            assertExists(this.config);
            const text = this._inputText;
            assertExists(text);
            assertExists(this.config.generateAnswer);
            this._resetAbortController();
            this._answer = null;
            const update = (answer)=>{
                this._answer = answer;
                this.requestUpdate();
            };
            const finish = (type, err)=>{
                if (type === 'aborted') return;
                assertExists(this.config);
                if (type === 'error') {
                    this.state = 'error';
                    this.config.errorStateConfig.error = err;
                } else {
                    this.state = 'finished';
                    this.config.errorStateConfig.error = undefined;
                }
                this._resetAbortController();
            };
            this.scrollTop = 0;
            this.state = 'generating';
            this.config.generateAnswer({
                input: text,
                update,
                finish,
                signal: this._abortController.signal
            });
        }, this.hide = ()=>{
            this._resetAbortController();
            this.state = 'hidden';
            this._stopAutoUpdate?.();
            this._inputText = null;
            this._answer = null;
            this._stopAutoUpdate = undefined;
            this.config?.hideCallback?.();
            this.viewportOverlayWidget?.unlock();
        }, this.onInput = (text)=>{
            this._inputText = text;
        }, this.showDiscardModal = ()=>{
            const notification = this.host.std.getOptional(NotificationProvider);
            if (!notification) {
                return Promise.resolve(true);
            }
            this._clearDiscardModal();
            this._discardModalAbort = new AbortController();
            return notification.confirm({
                title: 'Discard the AI result',
                message: 'Do you want to discard the results the AI just generated?',
                cancelText: 'Cancel',
                confirmText: 'Discard',
                abort: this._abortController.signal
            }).finally(()=>this._discardModalAbort = null);
        }, this.stopGenerating = ()=>{
            this._abortController.abort();
            this.state = 'finished';
            if (!this.answer) {
                this.hide();
            }
        }, this.toggle = (reference, input)=>{
            if (input) {
                this._inputText = input;
                this.generate();
            } else {
                this.hide();
                this.state = 'input';
            }
            this._autoUpdatePosition(reference);
        }, this.#___private_config_1 = (_initProto(this), _init_config(this, null)), this.#___private_generatingElement_2 = _init_generatingElement(this, null), this.#___private_state_3 = _init_state(this, 'hidden');
    }
}
