import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Checkbox, DatePicker, Menu } from '@affine/component';
import { CloudDocMetaService } from '@affine/core/modules/cloud/services/cloud-doc-meta';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { i18nTime, useI18n } from '@affine/i18n';
import { DocService, useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import { noop } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { managerContext } from './common';
import * as styles from './styles.css';
import { TagsInlineEditor } from './tags-inline-editor';
export const DateValue = ({ property })=>{
    const displayValue = property.value ? i18nTime(property.value, {
        absolute: {
            accuracy: 'day'
        }
    }) : undefined;
    const manager = useContext(managerContext);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const handleChange = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e
        });
    }, [
        manager,
        property.id
    ]);
    const t = useI18n();
    return _jsx(Menu, {
        items: _jsx(DatePicker, {
            value: property.value,
            onChange: handleChange
        }),
        children: _jsx("div", {
            onClick: handleClick,
            className: styles.propertyRowValueCell,
            "data-empty": !property.value,
            children: displayValue ?? t['com.affine.page-properties.property-value-placeholder']()
        })
    });
};
export const CheckboxValue = ({ property })=>{
    const manager = useContext(managerContext);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
        manager.updateCustomProperty(property.id, {
            value: !property.value
        });
    }, [
        manager,
        property.id,
        property.value
    ]);
    return _jsx("div", {
        onClick: handleClick,
        className: styles.propertyRowValueCell,
        "data-empty": !property.value,
        children: _jsx(Checkbox, {
            className: styles.checkboxProperty,
            checked: !!property.value,
            onChange: noop
        })
    });
};
export const TextValue = ({ property })=>{
    const manager = useContext(managerContext);
    const [value, setValue] = useState(property.value);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const ref = useRef(null);
    const handleBlur = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e.currentTarget.value.trim()
        });
    }, [
        manager,
        property.id
    ]);
    useEffect(()=>{
        ref.current?.addEventListener('blur', handleBlur);
        return ()=>{
            ref.current?.removeEventListener('blur', handleBlur);
        };
    }, [
        handleBlur
    ]);
    const handleOnChange = useCallback((e)=>{
        setValue(e.target.value);
    }, []);
    const t = useI18n();
    useEffect(()=>{
        setValue(property.value);
    }, [
        property.value
    ]);
    return _jsxs("div", {
        onClick: handleClick,
        className: styles.propertyRowValueTextCell,
        children: [
            _jsx("textarea", {
                ref: ref,
                className: styles.propertyRowValueTextarea,
                value: value || '',
                onChange: handleOnChange,
                onClick: handleClick,
                "data-empty": !value,
                placeholder: t['com.affine.page-properties.property-value-placeholder']()
            }),
            _jsxs("div", {
                className: styles.propertyRowValueTextareaInvisible,
                children: [
                    value,
                    value?.endsWith('\n') || !value ? _jsx("br", {}) : null
                ]
            })
        ]
    });
};
export const NumberValue = ({ property })=>{
    const manager = useContext(managerContext);
    const [value, setValue] = useState(property.value);
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    const handleBlur = useCallback((e)=>{
        manager.updateCustomProperty(property.id, {
            value: e.target.value.trim()
        });
    }, [
        manager,
        property.id
    ]);
    const handleOnChange = useCallback((e)=>{
        setValue(e.target.value);
    }, []);
    const t = useI18n();
    useEffect(()=>{
        setValue(property.value);
    }, [
        property.value
    ]);
    return _jsx("input", {
        className: styles.propertyRowValueNumberCell,
        type: 'number',
        value: value || '',
        onChange: handleOnChange,
        onClick: handleClick,
        onBlur: handleBlur,
        "data-empty": !value,
        placeholder: t['com.affine.page-properties.property-value-placeholder']()
    });
};
export const TagsValue = ()=>{
    const doc = useService(DocService).doc;
    const t = useI18n();
    return _jsx(TagsInlineEditor, {
        className: styles.propertyRowValueCell,
        placeholder: t['com.affine.page-properties.property-value-placeholder'](),
        pageId: doc.id,
        readonly: doc.blockSuiteDoc.readonly
    });
};
const CloudUserAvatar = (props)=>{
    const cloudDocMetaService = useService(CloudDocMetaService);
    const cloudDocMeta = useLiveData(cloudDocMetaService.cloudDocMeta.meta$);
    const isRevalidating = useLiveData(cloudDocMetaService.cloudDocMeta.isRevalidating$);
    const error = useLiveData(cloudDocMetaService.cloudDocMeta.error$);
    useEffect(()=>{
        cloudDocMetaService.cloudDocMeta.revalidate();
    }, [
        cloudDocMetaService
    ]);
    const user = useMemo(()=>{
        if (!cloudDocMeta) return null;
        if (props.type === 'CreatedBy' && cloudDocMeta.createdBy) {
            return {
                name: cloudDocMeta.createdBy.name,
                avatarUrl: cloudDocMeta.createdBy.avatarUrl
            };
        } else if (props.type === 'UpdatedBy' && cloudDocMeta.updatedBy) {
            return {
                name: cloudDocMeta.updatedBy.name,
                avatarUrl: cloudDocMeta.updatedBy.avatarUrl
            };
        }
        return null;
    }, [
        cloudDocMeta,
        props.type
    ]);
    if (!cloudDocMeta) {
        if (isRevalidating) {
            return null;
        }
        if (error) {
            return;
        }
        return null;
    }
    if (user) {
        return _jsxs(_Fragment, {
            children: [
                _jsx(Avatar, {
                    url: user.avatarUrl || '',
                    name: user.name,
                    size: 20
                }),
                _jsx("span", {
                    children: user.name
                })
            ]
        });
    }
    return _jsx(NoRecordValue, {});
};
const NoRecordValue = ()=>{
    const t = useI18n();
    return _jsx("span", {
        children: t['com.affine.page-properties.property-user-avatar-no-record']()
    });
};
const LocalUserValue = ()=>{
    const t = useI18n();
    return _jsx("span", {
        children: t['com.affine.page-properties.local-user']()
    });
};
export const CreatedUserValue = ()=>{
    const workspaceService = useService(WorkspaceService);
    const isCloud = workspaceService.workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD;
    if (!isCloud) {
        return _jsx("div", {
            className: styles.propertyRowValueUserCell,
            children: _jsx(LocalUserValue, {})
        });
    }
    return _jsx("div", {
        className: styles.propertyRowValueUserCell,
        children: _jsx(CloudUserAvatar, {
            type: "CreatedBy"
        })
    });
};
export const UpdatedUserValue = ()=>{
    const workspaceService = useService(WorkspaceService);
    const isCloud = workspaceService.workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD;
    if (!isCloud) {
        return _jsx(LocalUserValue, {});
    }
    return _jsx("div", {
        className: styles.propertyRowValueUserCell,
        children: _jsx(CloudUserAvatar, {
            type: "UpdatedBy"
        })
    });
};
export const propertyValueRenderers = {
    date: DateValue,
    checkbox: CheckboxValue,
    text: TextValue,
    number: NumberValue,
    createdBy: CreatedUserValue,
    updatedBy: UpdatedUserValue,
    tags: TagsValue,
    progress: TextValue
};
