import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef } from 'react';
import * as styles from './list.css';
export const ColWrapper = forwardRef(function ColWrapper({ flex, alignment, hideInSmallContainer, hidden, className, style, children, ...rest }, ref) {
    return _jsx("div", {
        ...rest,
        ref: ref,
        "data-testid": "page-list-flex-wrapper",
        style: {
            ...style,
            flexGrow: flex,
            flexBasis: flex ? `${flex / 12 * 100}%` : 'auto',
            justifyContent: alignment
        },
        "data-hide-item": hideInSmallContainer ? true : undefined,
        className: clsx(className, styles.colWrapper, {
            [styles.hidden]: hidden,
            [styles.hideInSmallContainer]: hideInSmallContainer
        }),
        children: children
    });
});
export const withinDaysAgo = (date, days)=>{
    const startDate = new Date();
    const day = startDate.getDate();
    const month = startDate.getMonth();
    const year = startDate.getFullYear();
    return new Date(year, month, day - days + 1) <= date;
};
export const betweenDaysAgo = (date, days0, days1)=>{
    return !withinDaysAgo(date, days0) && withinDaysAgo(date, days1);
};
export function shallowEqual(objA, objB) {
    if (Object.is(objA, objB)) {
        return true;
    }
    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false;
    }
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);
    if (keysA.length !== keysB.length) {
        return false;
    }
    for (const key of keysA){
        if (!Object.prototype.hasOwnProperty.call(objB, key) || !Object.is(objA[key], objB[key])) {
            return false;
        }
    }
    return true;
}
