import { Store } from '@toeverything/infra';
export class TemplateDownloaderStore extends Store {
    constructor(fetchService){
        super(), this.fetchService = fetchService;
    }
    async download(snapshotUrl) {
        const response = await this.fetchService.fetch(snapshotUrl, {
            priority: 'high'
        });
        const arrayBuffer = await response.arrayBuffer();
        return {
            data: new Uint8Array(arrayBuffer)
        };
    }
}
