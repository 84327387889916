import { typesystem } from './typesystem';
export class Matcher {
    constructor(_match){
        this._match = _match;
        this.list = [];
    }
    register(type, data) {
        this.list.push({
            type,
            data
        });
    }
    match(type) {
        const match = this._match ?? typesystem.isSubtype.bind(typesystem);
        for (const t of this.list){
            if (match(t.type, type)) {
                return t.data;
            }
        }
        return;
    }
    allMatched(type) {
        const match = this._match ?? typesystem.isSubtype.bind(typesystem);
        const result = [];
        for (const t of this.list){
            if (match(t.type, type)) {
                result.push(t);
            }
        }
        return result;
    }
    allMatchedData(type) {
        return this.allMatched(type).map((v)=>v.data);
    }
    findData(f) {
        return this.list.find((data)=>f(data.data))?.data;
    }
    find(f) {
        return this.list.find(f);
    }
    all() {
        return this.list;
    }
}
