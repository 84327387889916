import { jsx as _jsx } from "react/jsx-runtime";
import { WorkspacePermissionService } from '@affine/core/modules/permissions';
import { useLiveData, useService, WorkspaceService } from '@toeverything/infra';
import { useEffect, useMemo } from 'react';
import * as style from './style.css';
import { useI18n } from '@affine/i18n';
const Label = ({ value, background })=>{
    return _jsx("div", {
        children: _jsx("div", {
            className: style.workspaceLabel,
            style: {
                background: background
            },
            children: value
        })
    });
};
export const LabelsPanel = ()=>{
    const workspace = useService(WorkspaceService).workspace;
    const permissionService = useService(WorkspacePermissionService);
    const isOwner = useLiveData(permissionService.permission.isOwner$);
    useEffect(()=>{
        permissionService.permission.revalidate();
    }, [
        permissionService
    ]);
    const t = useI18n();
    const labelMap = useMemo(()=>({
            local: {
                value: t['ai.wemem.workspaceInfo.localWorkspaceStatus'](),
                background: 'var(--affine-tag-orange)'
            },
            syncCloud: {
                value: t['com.affine.setting.sign.message'](),
                background: 'var(--affine-tag-blue)'
            },
            syncDocker: {
                value: 'Sync with Wemem Docker',
                background: 'var(--affine-tag-green)'
            },
            selfHosted: {
                value: 'Self-Hosted Server',
                background: 'var(--affine-tag-purple)'
            },
            joinedWorkspace: {
                value: 'Joined Workspace',
                background: 'var(--affine-tag-yellow)'
            },
            availableOffline: {
                value: 'Available Offline',
                background: 'var(--affine-tag-green)'
            },
            publishedToWeb: {
                value: 'Published to Web',
                background: 'var(--affine-tag-blue)'
            }
        }), []);
    const labelConditions = [
        {
            condition: !isOwner,
            label: 'joinedWorkspace'
        },
        {
            condition: workspace.flavour === 'local',
            label: 'local'
        },
        {
            condition: workspace.flavour === 'affine-cloud',
            label: 'syncCloud'
        }
    ];
    return _jsx("div", {
        className: style.labelWrapper,
        children: labelConditions.map(({ condition, label })=>condition && _jsx(Label, {
                value: labelMap[label].value,
                background: labelMap[label].background
            }, label))
    });
};
