import { Unreachable } from '@affine/env/constant';
export class ObjectPool {
    constructor(options = {}){
        this.options = options;
        this.objects = new Map();
        this.timeoutToGc = null;
    }
    get(key) {
        const exist = this.objects.get(key);
        if (exist) {
            exist.rc++;
            let released = false;
            return {
                obj: exist.obj,
                release: ()=>{
                    if (released) {
                        return;
                    }
                    released = true;
                    exist.rc--;
                    this.requestGc();
                }
            };
        }
        return null;
    }
    put(key, obj) {
        const ref = {
            obj,
            rc: 0
        };
        this.objects.set(key, ref);
        const r = this.get(key);
        if (!r) {
            throw new Unreachable();
        }
        return r;
    }
    requestGc() {
        if (this.timeoutToGc) {
            clearInterval(this.timeoutToGc);
        }
        this.timeoutToGc = setInterval(()=>{
            this.gc();
        }, 1000);
    }
    gc() {
        for (const [key, { obj, rc }] of new Map(this.objects)){
            if (rc === 0 && (!this.options.onDangling || this.options.onDangling(obj))) {
                this.options.onDelete?.(obj);
                this.objects.delete(key);
            }
        }
        for (const [_, { rc }] of this.objects){
            if (rc === 0) {
                return;
            }
        }
        if (this.timeoutToGc) {
            clearInterval(this.timeoutToGc);
        }
    }
    clear() {
        for (const { obj } of this.objects.values()){
            this.options.onDelete?.(obj);
        }
        this.objects.clear();
    }
}
