import { Entity, LiveData } from '@toeverything/infra';
import { defaults, isEqual, omit } from 'lodash-es';
import { paramsParseOptions, preprocessParams } from '../../navigation/utils';
import { EditorScope } from '../scopes/editor';
export class Editor extends Entity {
    togglePresentation() {
        const edgelessRootService = this.editorContainer$.value?.host?.std.getService('affine:page');
        if (!edgelessRootService) return;
        edgelessRootService.tool.setEdgelessTool({
            type: !this.isPresenting$.value ? 'frameNavigator' : 'default'
        });
    }
    setSelector(selector) {
        this.selector$.next(selector);
    }
    toggleMode() {
        this.mode$.next(this.mode$.value === 'edgeless' ? 'page' : 'edgeless');
    }
    setMode(mode) {
        this.mode$.next(mode);
    }
    setEditorContainer(editorContainer) {
        this.editorContainer$.next(editorContainer);
    }
    bindWorkbenchView(view) {
        const stablePrimaryMode = this.doc.getPrimaryMode();
        const viewParams$ = view.queryString$(paramsParseOptions).map(preprocessParams).map((params)=>defaults(params, {
                mode: stablePrimaryMode || 'page'
            }));
        const editorParams$ = LiveData.computed((get)=>{
            return {
                mode: get(this.mode$),
                ...get(this.selector$)
            };
        });
        let updating = false;
        const unsubscribeViewParams = viewParams$.subscribe((params)=>{
            if (updating) return;
            updating = true;
            try {
                const editorParams = editorParams$.value;
                if (params.mode !== editorParams.mode) {
                    this.setMode(params.mode);
                }
                const selector = omit(params, [
                    'mode'
                ]);
                if (!isEqual(selector, omit(editorParams, [
                    'mode'
                ]))) {
                    this.setSelector(selector);
                }
            } finally{
                updating = false;
            }
        });
        const unsubscribeEditorParams = editorParams$.subscribe((params)=>{
            if (updating) return;
            updating = true;
            try {
                if (!isEqual(params, viewParams$.value)) {
                    const newQueryString = {};
                    Object.entries(params).forEach(([k, v])=>{
                        newQueryString[k] = Array.isArray(v) ? v.join(',') : v;
                    });
                    view.updateQueryString(newQueryString, {
                        replace: true
                    });
                }
            } finally{
                updating = false;
            }
        });
        return ()=>{
            unsubscribeEditorParams.unsubscribe();
            unsubscribeViewParams.unsubscribe();
        };
    }
    bindEditorContainer(editorContainer, docTitle) {
        const unsubs = [];
        const focusAt$ = LiveData.computed((get)=>{
            const selector = get(this.selector$);
            const mode = get(this.mode$);
            let id = selector?.blockIds?.[0];
            let key = 'blockIds';
            if (mode === 'edgeless') {
                const elementId = selector?.elementIds?.[0];
                if (elementId) {
                    id = elementId;
                    key = 'elementIds';
                }
            }
            if (!id) return null;
            return {
                id,
                key,
                mode,
                refreshKey: selector?.refreshKey
            };
        });
        if (focusAt$.value === null && docTitle) {
            const title = docTitle.querySelector('rich-text');
            title?.inlineEditor?.focusEnd();
        }
        const subscription = focusAt$.distinctUntilChanged((a, b)=>a?.id === b?.id && a?.key === b?.key && a?.refreshKey === b?.refreshKey).subscribe((anchor)=>{
            if (!anchor) return;
            const selection = editorContainer.host?.std.selection;
            if (!selection) return;
            const { id, key, mode } = anchor;
            selection.setGroup('scene', [
                selection.create('highlight', {
                    mode,
                    [key]: [
                        id
                    ]
                })
            ]);
        });
        unsubs.push(subscription.unsubscribe.bind(subscription));
        const edgelessPage = editorContainer.host?.querySelector('affine-edgeless-root');
        if (!edgelessPage) {
            this.isPresenting$.next(false);
        } else {
            this.isPresenting$.next(edgelessPage.edgelessTool.type === 'frameNavigator');
            const disposable = edgelessPage.slots.edgelessToolUpdated.on(()=>{
                this.isPresenting$.next(edgelessPage.edgelessTool.type === 'frameNavigator');
            });
            unsubs.push(disposable.dispose.bind(disposable));
        }
        return ()=>{
            for (const unsub of unsubs){
                unsub();
            }
        };
    }
    constructor(docService, workspaceService){
        super(), this.docService = docService, this.workspaceService = workspaceService, this.scope = this.framework.createScope(EditorScope, {
            editor: this
        }), this.mode$ = new LiveData('page'), this.selector$ = new LiveData(undefined), this.doc = this.docService.doc, this.isSharedMode = this.workspaceService.workspace.openOptions.isSharedMode, this.editorContainer$ = new LiveData(null), this.isPresenting$ = new LiveData(false);
    }
}
