import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
export class WorkspaceMembers extends Entity {
    constructor(store, workspaceService){
        super(), this.store = store, this.workspaceService = workspaceService, this.pageNum$ = new LiveData(0), this.memberCount$ = new LiveData(undefined), this.pageMembers$ = new LiveData(undefined), this.isLoading$ = new LiveData(false), this.error$ = new LiveData(null), this.PAGE_SIZE = 8, this.revalidate = effect(map(()=>this.pageNum$.value), exhaustMapSwitchUntilChanged((a, b)=>a === b, (pageNum)=>{
            return fromPromise(async (signal)=>{
                return this.store.fetchMembers(this.workspaceService.workspace.id, pageNum * this.PAGE_SIZE, this.PAGE_SIZE, signal);
            }).pipe(mergeMap((data)=>{
                this.memberCount$.setValue(data.memberCount);
                this.pageMembers$.setValue(data.members);
                return EMPTY;
            }), backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), catchErrorInto(this.error$), onStart(()=>{
                this.pageMembers$.setValue(undefined);
                this.isLoading$.setValue(true);
            }), onComplete(()=>this.isLoading$.setValue(false)));
        }));
    }
    setPageNum(pageNum) {
        this.pageNum$.setValue(pageNum);
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
