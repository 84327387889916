import { matchFlavours } from '@blocksuite/affine-shared/utils';
export const dedentBlocks = (ctx, next)=>{
    let { blockIds } = ctx;
    const { std, stopCapture = true } = ctx;
    const { doc } = std;
    if (!blockIds || !blockIds.length) {
        const text = std.selection.find('text');
        if (text) {
            blockIds = [
                text.from.blockId,
                text.to?.blockId
            ].filter((x)=>!!x);
        } else {
            blockIds = std.selection.getGroup('note').map((sel)=>sel.blockId);
        }
    }
    if (!blockIds || !blockIds.length || doc.readonly) return;
    let firstOutdentIndex = -1;
    let firstParent;
    for(let i = 0; i < blockIds.length; i++){
        firstParent = doc.getParent(blockIds[i]);
        if (firstParent && !matchFlavours(firstParent, [
            'affine:note'
        ])) {
            firstOutdentIndex = i;
            break;
        }
    }
    if (firstOutdentIndex === -1) return;
    if (stopCapture) doc.captureSync();
    const outdentModels = blockIds.slice(firstOutdentIndex);
    for(let i = outdentModels.length - 1; i >= 0; i--){
        const model = outdentModels[i];
        const parent = doc.getParent(model);
        if (parent && !outdentModels.includes(parent.id)) {
            std.command.exec('dedentBlock', {
                blockId: model,
                stopCapture: false
            });
        }
    }
    return next();
};
