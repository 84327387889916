import isSvg from 'is-svg';
function fastCheckIsNotSvg(buffer) {
    for(let i = 0; i < buffer.length; i++){
        const ch = buffer[i];
        if (ch === 0x20 || ch === 0x09 || ch === 0x0b || ch === 0x0c || ch === 0x0a || ch === 0x0d || ch === 0xa0) {
            continue;
        }
        return !(buffer[i] === 0x3c && buffer[i + 1] === 0x73 && buffer[i + 2] === 0x76 && buffer[i + 3] === 0x67) && !(buffer[i] === 0x3c && buffer[i + 1] === 0x3f && buffer[i + 2] === 0x78 && buffer[i + 3] === 0x6d && buffer[i + 4] === 0x6c);
    }
    return true;
}
export function isSvgBuffer(buffer) {
    if (fastCheckIsNotSvg(buffer)) {
        return false;
    }
    const decoder = new TextDecoder('utf-8');
    const str = decoder.decode(buffer);
    return isSvg(str);
}
export function bufferToBlob(buffer) {
    const isSVG = isSvgBuffer(buffer instanceof ArrayBuffer ? new Uint8Array(buffer) : buffer);
    return isSVG ? new Blob([
        buffer
    ], {
        type: 'image/svg+xml'
    }) : new Blob([
        buffer
    ]);
}
