import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from '@affine/component/ui/menu';
import { useI18n } from '@affine/i18n';
import { ImportIcon, PlusIcon } from '@blocksuite/icons/rc';
import { FeatureFlagService, useLiveData, useService } from '@toeverything/infra';
import * as styles from './index.css';
export const AddWorkspace = ({ onAddWorkspace, onNewWorkspace })=>{
    const t = useI18n();
    const featureFlagService = useService(FeatureFlagService);
    const enableLocalWorkspace = useLiveData(featureFlagService.flags.enable_local_workspace.$);
    return _jsxs("div", {
        children: [
            BUILD_CONFIG.isElectron && _jsx(MenuItem, {
                block: true,
                prefixIcon: _jsx(ImportIcon, {}),
                onClick: onAddWorkspace,
                "data-testid": "add-workspace",
                className: styles.ItemContainer,
                children: _jsx("div", {
                    className: styles.ItemText,
                    children: t['com.affine.workspace.local.import']()
                })
            }),
            _jsx(MenuItem, {
                block: true,
                prefixIcon: _jsx(PlusIcon, {}),
                onClick: onNewWorkspace,
                "data-testid": "new-workspace",
                className: styles.ItemContainer,
                children: _jsx("div", {
                    className: styles.ItemText,
                    children: enableLocalWorkspace ? t['com.affine.workspaceList.addWorkspace.create']() : t['com.affine.workspaceList.addWorkspace.create-cloud']()
                })
            })
        ]
    });
};
