import { popMenu } from '@blocksuite/affine-components/context-menu';
import { css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { selectOptionColors } from '../../../utils/tags/colors.js';
import { BaseGroup } from './base.js';
export class SelectGroupView extends BaseGroup {
    static{
        this.styles = css`
    data-view-group-title-select-view {
      overflow: hidden;
    }
    .data-view-group-title-select-view {
      width: 100%;
      cursor: pointer;
    }

    .data-view-group-title-select-view.readonly {
      cursor: inherit;
    }

    .tag {
      padding: 0 8px;
      border-radius: 4px;
      font-size: var(--data-view-cell-text-size);
      line-height: var(--data-view-cell-text-line-height);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;
    }
    get tag() {
        return this.data.options.find((v)=>v.id === this.value);
    }
    render() {
        const tag = this.tag;
        if (!tag) {
            return html` <div
        style="font-size: 14px;color: var(--affine-text-primary-color);line-height: 22px;"
      >
        Ungroups
      </div>`;
        }
        const style = styleMap({
            backgroundColor: tag.color
        });
        const classList = classMap({
            'data-view-group-title-select-view': true,
            readonly: this.readonly
        });
        return html` <div @click="${this._click}" class="${classList}">
      <div class="tag" style="${style}">${tag.value}</div>
    </div>`;
    }
    updateTag(tag) {
        this.updateData?.({
            ...this.data,
            options: this.data.options.map((v)=>{
                if (v.id === this.value) {
                    return {
                        ...v,
                        ...tag
                    };
                }
                return v;
            })
        });
    }
    constructor(...args){
        super(...args), this._click = ()=>{
            if (this.readonly) {
                return;
            }
            popMenu(this, {
                options: {
                    input: {
                        initValue: this.tag?.value ?? '',
                        onComplete: (text)=>{
                            this.updateTag({
                                value: text
                            });
                        }
                    },
                    items: selectOptionColors.map(({ color, name })=>{
                        const styles = styleMap({
                            backgroundColor: color,
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px'
                        });
                        return {
                            type: 'action',
                            name: name,
                            isSelected: this.tag?.color === color,
                            icon: html` <div style=${styles}></div>`,
                            select: ()=>{
                                this.updateTag({
                                    color
                                });
                            }
                        };
                    })
                }
            });
        };
    }
}
