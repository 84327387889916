export const BLOCK_CHILDREN_CONTAINER_PADDING_LEFT = 24;
export const EDGELESS_BLOCK_CHILD_PADDING = 24;
export const EDGELESS_BLOCK_CHILD_BORDER_WIDTH = 2;
export const PAGE_HEADER_HEIGHT = 53;
export const EMBED_CARD_MIN_WIDTH = 450;
export const EMBED_CARD_WIDTH = {
    horizontal: 752,
    horizontalThin: 752,
    list: 752,
    vertical: 364,
    cube: 170,
    cubeThick: 170,
    video: 752,
    figma: 752,
    html: 752,
    syncedDoc: 752
};
export const EMBED_CARD_HEIGHT = {
    horizontal: 116,
    horizontalThin: 80,
    list: 46,
    vertical: 390,
    cube: 114,
    cubeThick: 132,
    video: 544,
    figma: 544,
    html: 544,
    syncedDoc: 455
};
export const EMBED_BLOCK_FLAVOUR_LIST = [
    'affine:embed-github',
    'affine:embed-youtube',
    'affine:embed-figma',
    'affine:embed-linked-doc',
    'affine:embed-synced-doc',
    'affine:embed-html',
    'affine:embed-loom'
];
export const DEFAULT_IMAGE_PROXY_ENDPOINT = 'https://worker.wemem.ai/api/worker/image-proxy';
export const DEFAULT_LINK_PREVIEW_ENDPOINT = 'https://worker.wemem.ai/api/worker/link-preview';
export const CANVAS_EXPORT_IGNORE_TAGS = [
    'EDGELESS-TOOLBAR',
    'AFFINE-DRAG-HANDLE-WIDGET',
    'AFFINE-FORMAT-BAR-WIDGET',
    'AFFINE-BLOCK-SELECTION'
];
export * from './bracket-pairs.js';
export * from './note.js';
