import { jsx as _jsx } from "react/jsx-runtime";
import { toast } from '@affine/component';
import { TagService, useTagI18n } from '@affine/core/modules/tag';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { GlobalContextService, useLiveData, useServices } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { ExplorerTreeNode } from '../../tree';
import { ExplorerDocNode } from '../doc';
import { Empty } from './empty';
import { useExplorerTagNodeOperations } from './operations';
import * as styles from './styles.css';
export const ExplorerTagNode = ({ tagId, onDrop, location, reorderable, operations: additionalOperations, dropEffect, canDrop, defaultRenaming })=>{
    const t = useI18n();
    const { tagService, globalContextService } = useServices({
        TagService,
        GlobalContextService
    });
    const active = useLiveData(globalContextService.globalContext.tagId.$) === tagId;
    const [collapsed, setCollapsed] = useState(true);
    const tagRecord = useLiveData(tagService.tagList.tagByTagId$(tagId));
    const tagColor = useLiveData(tagRecord?.color$);
    const tagName = useLiveData(tagRecord?.value$);
    const tt = useTagI18n();
    const Icon = useCallback(({ className })=>{
        return _jsx("div", {
            className: clsx(styles.tagIconContainer, className),
            children: _jsx("div", {
                className: styles.tagIcon,
                style: {
                    backgroundColor: tagColor
                }
            })
        });
    }, [
        tagColor
    ]);
    const dndData = useMemo(()=>{
        return {
            draggable: {
                entity: {
                    type: 'tag',
                    id: tagId
                },
                from: location
            },
            dropTarget: {
                at: 'explorer:tag'
            }
        };
    }, [
        location,
        tagId
    ]);
    const handleRename = useCallback((newName)=>{
        if (tagRecord && tagRecord.value$.value !== newName) {
            tagRecord.rename(newName);
            track.$.navigationPanel.organize.renameOrganizeItem({
                type: 'tag'
            });
        }
    }, [
        tagRecord
    ]);
    const handleDropOnTag = useCallback((data)=>{
        if (data.treeInstruction?.type === 'make-child' && tagRecord) {
            if (data.source.data.entity?.type === 'doc') {
                tagRecord.tag(data.source.data.entity.id);
                track.$.navigationPanel.tags.tagDoc({
                    control: 'drag'
                });
            } else {
                toast(t['com.affine.rootAppSidebar.tag.doc-only']());
            }
        } else {
            onDrop?.(data);
        }
    }, [
        onDrop,
        t,
        tagRecord
    ]);
    const handleDropEffectOnTag = useCallback((data)=>{
        if (data.treeInstruction?.type === 'make-child') {
            if (data.source.data.entity?.type === 'doc') {
                return 'link';
            }
        } else {
            return dropEffect?.(data);
        }
        return;
    }, [
        dropEffect
    ]);
    const handleDropOnPlaceholder = useCallback((data)=>{
        if (tagRecord) {
            if (data.source.data.entity?.type === 'doc') {
                tagRecord.tag(data.source.data.entity.id);
            } else {
                toast(t['com.affine.rootAppSidebar.tag.doc-only']());
            }
        }
    }, [
        t,
        tagRecord
    ]);
    const handleCanDrop = useMemo(()=>(args)=>{
            const entityType = args.source.data.entity?.type;
            return args.treeInstruction?.type !== 'make-child' ? (typeof canDrop === 'function' ? canDrop(args) : canDrop) ?? true : entityType === 'doc';
        }, [
        canDrop
    ]);
    const operations = useExplorerTagNodeOperations(tagId, useMemo(()=>({
            openNodeCollapsed: ()=>setCollapsed(false)
        }), []));
    const finalOperations = useMemo(()=>{
        if (additionalOperations) {
            return [
                ...operations,
                ...additionalOperations
            ];
        }
        return operations;
    }, [
        additionalOperations,
        operations
    ]);
    if (!tagRecord) {
        return null;
    }
    return _jsx(ExplorerTreeNode, {
        icon: Icon,
        name: tt(tagName) || t['Untitled'](),
        dndData: dndData,
        onDrop: handleDropOnTag,
        renameable: true,
        collapsed: collapsed,
        setCollapsed: setCollapsed,
        to: `/tag/${tagId}`,
        active: active,
        defaultRenaming: defaultRenaming,
        reorderable: reorderable,
        onRename: handleRename,
        canDrop: handleCanDrop,
        childrenPlaceholder: _jsx(Empty, {
            onDrop: handleDropOnPlaceholder
        }),
        operations: finalOperations,
        dropEffect: handleDropEffectOnTag,
        "data-testid": `explorer-tag-${tagId}`,
        children: _jsx(ExplorerTagNodeDocs, {
            tag: tagRecord
        })
    });
};
export const ExplorerTagNodeDocs = ({ tag })=>{
    const tagDocIds = useLiveData(tag.pageIds$);
    return tagDocIds.map((docId)=>_jsx(ExplorerDocNode, {
            docId: docId,
            reorderable: false,
            location: {
                at: 'explorer:tags:docs'
            }
        }, docId));
};
