import { assertExists } from '@blocksuite/global/utils';
import { effect } from '@preact/signals-core';
import * as Y from 'yjs';
import { isInEmbedGap } from '../utils/embed.js';
import { isMaybeInlineRangeEqual } from '../utils/inline-range.js';
import { domRangeToInlineRange, inlineRangeToDomRange } from '../utils/range-conversion.js';
import { calculateTextLength, getTextNodesFromElement } from '../utils/text.js';
export class RangeService {
    get lastEndRelativePosition() {
        return this._lastEndRelativePosition;
    }
    get lastStartRelativePosition() {
        return this._lastStartRelativePosition;
    }
    constructor(editor){
        this.editor = editor;
        this._lastEndRelativePosition = null;
        this._lastStartRelativePosition = null;
        this.focusEnd = ()=>{
            this.editor.setInlineRange({
                index: this.editor.yTextLength,
                length: 0
            });
        };
        this.focusIndex = (index)=>{
            this.editor.setInlineRange({
                index,
                length: 0
            });
        };
        this.focusStart = ()=>{
            this.editor.setInlineRange({
                index: 0,
                length: 0
            });
        };
        this.getInlineRangeFromElement = (element)=>{
            const range = document.createRange();
            const text = element.querySelector('[data-v-text]');
            if (!text) {
                return null;
            }
            const textNode = text.childNodes[1];
            assertExists(textNode instanceof Text);
            range.setStart(textNode, 0);
            range.setEnd(textNode, textNode.textContent?.length ?? 0);
            const inlineRange = this.toInlineRange(range);
            return inlineRange;
        };
        this.getLine = (rangeIndex)=>{
            const rootElement = this.editor.rootElement;
            const lineElements = Array.from(rootElement.querySelectorAll('v-line'));
            let beforeIndex = 0;
            for (const [lineIndex, lineElement] of lineElements.entries()){
                if (rangeIndex >= beforeIndex && rangeIndex < beforeIndex + lineElement.vTextLength + 1) {
                    return {
                        line: lineElement,
                        lineIndex,
                        rangeIndexRelatedToLine: rangeIndex - beforeIndex
                    };
                }
                beforeIndex += lineElement.vTextLength + 1;
            }
            console.error('failed to find line');
            return null;
        };
        this.getNativeRange = ()=>{
            const selection = this.getNativeSelection();
            if (!selection) return null;
            return selection.getRangeAt(0);
        };
        this.getNativeSelection = ()=>{
            const selection = document.getSelection();
            if (!selection) return null;
            if (selection.rangeCount === 0) return null;
            return selection;
        };
        this.getTextPoint = (rangeIndex)=>{
            const rootElement = this.editor.rootElement;
            const vLines = Array.from(rootElement.querySelectorAll('v-line'));
            let index = 0;
            for (const vLine of vLines){
                const texts = getTextNodesFromElement(vLine);
                if (texts.length === 0) {
                    return null;
                }
                for (const text of texts.filter((text)=>!isInEmbedGap(text))){
                    if (!text.textContent) {
                        return null;
                    }
                    if (index + text.textContent.length >= rangeIndex) {
                        return [
                            text,
                            rangeIndex - index
                        ];
                    }
                    index += calculateTextLength(text);
                }
                index += 1;
            }
            return null;
        };
        this.isFirstLine = (inlineRange)=>{
            if (!inlineRange || inlineRange.length > 0) return false;
            const range = this.toDomRange(inlineRange);
            if (!range) {
                console.error('failed to convert inline range to domRange');
                return false;
            }
            const beforeText = this.editor.yTextString.slice(0, inlineRange.index);
            if (beforeText.includes('\n')) {
                return false;
            }
            const container = range.commonAncestorContainer.parentElement;
            assertExists(container);
            const containerRect = container.getBoundingClientRect();
            const rangeRects = range.getClientRects();
            const rangeRect = rangeRects[rangeRects.length - 1];
            const tolerance = 1;
            return Math.abs(rangeRect.top - containerRect.top) < tolerance;
        };
        this.isLastLine = (inlineRange)=>{
            if (!inlineRange || inlineRange.length > 0) return false;
            const afterText = this.editor.yTextString.slice(inlineRange.index);
            if (afterText.includes('\n')) {
                return false;
            }
            const range = this.toDomRange(inlineRange);
            if (!range) {
                console.error('failed to convert inline range to domRange');
                return false;
            }
            const container = range.commonAncestorContainer.parentElement;
            assertExists(container);
            const containerRect = container.getBoundingClientRect();
            const rangeRects = range.getClientRects();
            const rangeRect = rangeRects[rangeRects.length - 1];
            const tolerance = 1;
            return Math.abs(rangeRect.bottom - containerRect.bottom) < tolerance;
        };
        this.isValidInlineRange = (inlineRange)=>{
            return !(inlineRange && (inlineRange.index < 0 || inlineRange.index + inlineRange.length > this.editor.yText.length));
        };
        this.mount = ()=>{
            const editor = this.editor;
            let lastInlineRange = editor.inlineRange$.value;
            editor.disposables.add(effect(()=>{
                const newInlineRange = editor.inlineRange$.value;
                if (!editor.mounted) return;
                const eq = isMaybeInlineRangeEqual(lastInlineRange, newInlineRange);
                if (eq) return;
                lastInlineRange = newInlineRange;
                const yText = editor.yText;
                if (newInlineRange) {
                    this._lastStartRelativePosition = Y.createRelativePositionFromTypeIndex(yText, newInlineRange.index);
                    this._lastEndRelativePosition = Y.createRelativePositionFromTypeIndex(yText, newInlineRange.index + newInlineRange.length);
                } else {
                    this._lastStartRelativePosition = null;
                    this._lastEndRelativePosition = null;
                }
                if (editor.inlineRangeProviderOverride) return;
                if (this.editor.renderService.rendering) {
                    editor.slots.renderComplete.once(()=>{
                        this.syncInlineRange(newInlineRange);
                    });
                } else {
                    this.syncInlineRange();
                }
            }));
        };
        this.selectAll = ()=>{
            this.editor.setInlineRange({
                index: 0,
                length: this.editor.yTextLength
            });
        };
        this._syncInlineRangeLock = false;
        this.lockSyncInlineRange = ()=>{
            this._syncInlineRangeLock = true;
        };
        this.unlockSyncInlineRange = ()=>{
            this._syncInlineRangeLock = false;
        };
        this.syncInlineRange = (inlineRange)=>{
            if (!this.editor.mounted || this._syncInlineRangeLock) return;
            inlineRange = inlineRange ?? this.editor.getInlineRange();
            const handler = ()=>{
                const selection = document.getSelection();
                if (!selection) return;
                if (inlineRange === null) {
                    if (selection.rangeCount > 0) {
                        const range = selection.getRangeAt(0);
                        if (range.intersectsNode(this.editor.rootElement)) {
                            selection.removeAllRanges();
                        }
                    }
                } else {
                    try {
                        const newRange = this.toDomRange(inlineRange);
                        if (newRange) {
                            selection.removeAllRanges();
                            selection.addRange(newRange);
                            this.editor.slots.inlineRangeSync.emit(newRange);
                        } else {
                            this.editor.slots.renderComplete.once(()=>{
                                this.syncInlineRange(inlineRange);
                            });
                        }
                    } catch (error) {
                        console.error('failed to apply inline range');
                        console.error(error);
                    }
                }
            };
            if (this.editor.renderService.rendering) {
                this.editor.slots.renderComplete.once(handler);
            } else {
                handler();
            }
        };
        this.toDomRange = (inlineRange)=>{
            const rootElement = this.editor.rootElement;
            return inlineRangeToDomRange(rootElement, inlineRange);
        };
        this.toInlineRange = (range)=>{
            const { rootElement, yText } = this.editor;
            return domRangeToInlineRange(range, rootElement, yText);
        };
    }
}
