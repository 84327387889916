import { UserFriendlyError } from '@affine/graphql';
import { effect, Entity, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { catchError, EMPTY, mergeMap, switchMap } from 'rxjs';
export class ShareReader extends Entity {
    constructor(store){
        super(), this.store = store, this.isLoading$ = new LiveData(false), this.error$ = new LiveData(null), this.data$ = new LiveData(null), this.loadShare = effect(switchMap(({ workspaceId, docId })=>{
            return fromPromise(this.store.loadShare(workspaceId, docId)).pipe(mergeMap((data)=>{
                if (!data) {
                    this.data$.next(null);
                } else {
                    this.data$.next({
                        workspaceId,
                        docId,
                        workspaceBinary: data.workspace,
                        docBinary: data.doc,
                        publishMode: data.publishMode ?? undefined
                    });
                }
                return EMPTY;
            }), catchError((error)=>{
                this.error$.next(UserFriendlyError.fromAnyError(error));
                return EMPTY;
            }), onStart(()=>{
                this.isLoading$.next(true);
                this.data$.next(null);
                this.error$.next(null);
            }), onComplete(()=>{
                this.isLoading$.next(false);
            }));
        }));
    }
}
