import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightSmallIcon } from '@blocksuite/icons/rc';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { useMemo } from 'react';
import * as styles from '../styles.css';
import { useMenuItem } from '../use-menu-item';
export const DesktopMenuSub = ({ children: propsChildren, items, portalOptions, subOptions: { defaultOpen, ...otherSubOptions } = {}, triggerOptions, subContentOptions: { className: subContentClassName = '', style: contentStyle, ...otherSubContentOptions } = {} })=>{
    const { className, children, otherProps } = useMenuItem({
        ...triggerOptions,
        children: propsChildren,
        suffixIcon: _jsx(ArrowRightSmallIcon, {})
    });
    return _jsxs(DropdownMenu.Sub, {
        defaultOpen: defaultOpen,
        ...otherSubOptions,
        children: [
            _jsx(DropdownMenu.SubTrigger, {
                className: className,
                ...otherProps,
                children: children
            }),
            _jsx(DropdownMenu.Portal, {
                ...portalOptions,
                children: _jsx(DropdownMenu.SubContent, {
                    className: useMemo(()=>clsx(styles.menuContent, subContentClassName), [
                        subContentClassName
                    ]),
                    style: {
                        zIndex: 'var(--affine-z-index-popover)',
                        ...contentStyle
                    },
                    ...otherSubContentOptions,
                    children: items
                })
            })
        ]
    });
};
