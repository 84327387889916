import { jsx as _jsx } from "react/jsx-runtime";
import { Slot } from '@blocksuite/affine/store';
import clsx from 'clsx';
import { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react';
import { BlocksuiteDocEditor, BlocksuiteEdgelessEditor } from './lit-adaper';
import * as styles from './styles.css';
export const BlocksuiteEditorContainer = forwardRef(function AffineEditorContainer({ page, mode, className, style, shared }, ref) {
    const rootRef = useRef(null);
    const docRef = useRef(null);
    const docTitleRef = useRef(null);
    const edgelessRef = useRef(null);
    const slots = useMemo(()=>{
        return {
            editorModeSwitched: new Slot(),
            docUpdated: new Slot()
        };
    }, []);
    useLayoutEffect(()=>{
        slots.docUpdated.emit({
            newDocId: page.id
        });
    }, [
        page,
        slots.docUpdated
    ]);
    const affineEditorContainerProxy = useMemo(()=>{
        const api = {
            slots,
            get page () {
                return page;
            },
            get doc () {
                return page;
            },
            get docTitle () {
                return docTitleRef.current;
            },
            get host () {
                return mode === 'page' ? docRef.current?.host : edgelessRef.current?.host;
            },
            get model () {
                return page.root;
            },
            get updateComplete () {
                return mode === 'page' ? docRef.current?.updateComplete : edgelessRef.current?.updateComplete;
            },
            get mode () {
                return mode;
            },
            get origin () {
                return rootRef.current;
            }
        };
        const proxy = new Proxy(api, {
            has (_, prop) {
                return Reflect.has(api, prop) || (rootRef.current ? Reflect.has(rootRef.current, prop) : false);
            },
            get (_, prop) {
                if (Reflect.has(api, prop)) {
                    return api[prop];
                }
                if (rootRef.current && Reflect.has(rootRef.current, prop)) {
                    const maybeFn = Reflect.get(rootRef.current, prop);
                    if (typeof maybeFn === 'function') {
                        return maybeFn.bind(rootRef.current);
                    } else {
                        return maybeFn;
                    }
                }
                return undefined;
            }
        });
        return proxy;
    }, [
        mode,
        page,
        slots
    ]);
    useImperativeHandle(ref, ()=>affineEditorContainerProxy, [
        affineEditorContainerProxy
    ]);
    const handleClickPageModeBlank = useCallback(()=>{
        if (shared || page.readonly) return;
        affineEditorContainerProxy.host?.std.command.exec('appendParagraph', {});
    }, [
        affineEditorContainerProxy,
        page,
        shared
    ]);
    return _jsx("div", {
        "data-testid": `editor-${page.id}`,
        className: clsx(`editor-wrapper ${mode}-mode`, styles.docEditorRoot, className),
        "data-affine-editor-container": true,
        style: style,
        ref: rootRef,
        children: mode === 'page' ? _jsx(BlocksuiteDocEditor, {
            shared: shared,
            page: page,
            ref: docRef,
            titleRef: docTitleRef,
            onClickBlank: handleClickPageModeBlank
        }) : _jsx(BlocksuiteEdgelessEditor, {
            shared: shared,
            page: page,
            ref: edgelessRef
        })
    });
});
