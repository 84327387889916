import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@affine/i18n';
import { SearchIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import { Spotlight } from '../spolight';
import * as styles from './index.css';
export function QuickSearchInput({ onClick, ...props }) {
    const t = useI18n();
    return _jsxs("div", {
        ...props,
        className: clsx([
            props.className,
            styles.root
        ]),
        onClick: onClick,
        children: [
            _jsx(SearchIcon, {
                className: styles.icon
            }),
            _jsx("span", {
                className: styles.quickSearchBarEllipsisStyle,
                children: t['Quick search']()
            }),
            _jsx("div", {
                className: styles.spacer
            }),
            _jsx("div", {
                className: styles.shortcutHint,
                children: environment.isMacOs ? ' ⌘ + K' : ' Ctrl + K'
            }),
            _jsx(Spotlight, {})
        ]
    });
}
