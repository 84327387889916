export function moveConnectors(originId, targetId, service) {
    const connectors = service.surface.getConnectors(originId);
    connectors.forEach((connector)=>{
        if (connector.source.id === originId) {
            service.updateElement(connector.id, {
                source: {
                    ...connector.source,
                    id: targetId
                }
            });
        }
        if (connector.target.id === originId) {
            service.updateElement(connector.id, {
                target: {
                    ...connector.target,
                    id: targetId
                }
            });
        }
    });
}
