import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Menu, toast } from '@affine/component';
import { useBlockSuiteDocMeta } from '@affine/core/components/hooks/use-block-suite-page-meta';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { ShareDocsListService } from '@affine/core/modules/share-doc';
import { PublicPageMode } from '@affine/graphql';
import { Trans, useI18n } from '@affine/i18n';
import { FilterIcon } from '@blocksuite/icons/rc';
import { useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback, useEffect, useState } from 'react';
import { FavoriteTag } from '../../components/favorite-tag';
import { FilterList } from '../../filter';
import { VariableSelect } from '../../filter/vars';
import { usePageHeaderColsDef } from '../../header-col-def';
import { PageListItemRenderer } from '../../page-group';
import { ListTableHeader } from '../../page-header';
import { SelectorLayout } from '../../selector/selector-layout';
import { VirtualizedList } from '../../virtualized-list';
import { AffineShapeIcon } from '../affine-shape';
import * as styles from './edit-collection.css';
import { useFilter } from './use-filter';
import { useSearch } from './use-search';
export const SelectPage = ({ init = [], onConfirm, onCancel, onChange: propsOnChange, header, buttons })=>{
    const t = useI18n();
    const [value, setValue] = useState(init);
    const onChange = useCallback((value)=>{
        propsOnChange?.(value);
        setValue(value);
    }, [
        propsOnChange
    ]);
    const confirm = useCallback(()=>{
        onConfirm?.(value);
    }, [
        value,
        onConfirm
    ]);
    const clearSelected = useCallback(()=>{
        onChange([]);
    }, [
        onChange
    ]);
    const { workspaceService, compatibleFavoriteItemsAdapter, shareDocsListService } = useServices({
        ShareDocsListService,
        WorkspaceService,
        CompatibleFavoriteItemsAdapter
    });
    const shareDocs = useLiveData(shareDocsListService.shareDocs?.list$);
    const workspace = workspaceService.workspace;
    const docCollection = workspace.docCollection;
    const pageMetas = useBlockSuiteDocMeta(docCollection);
    const favourites = useLiveData(compatibleFavoriteItemsAdapter.favorites$);
    useEffect(()=>{
        shareDocsListService.shareDocs?.revalidate();
    }, [
        shareDocsListService.shareDocs
    ]);
    const getPublicMode = useCallback((id)=>{
        const mode = shareDocs?.find((shareDoc)=>shareDoc.id === id)?.mode;
        if (mode === PublicPageMode.Edgeless) {
            return 'edgeless';
        } else if (mode === PublicPageMode.Page) {
            return 'page';
        } else {
            return undefined;
        }
    }, [
        shareDocs
    ]);
    const isFavorite = useCallback((meta)=>favourites.some((fav)=>fav.id === meta.id), [
        favourites
    ]);
    const onToggleFavoritePage = useCallback((page)=>{
        const status = isFavorite(page);
        compatibleFavoriteItemsAdapter.toggle(page.id, 'doc');
        toast(status ? t['com.affine.toastMessage.removedFavorites']() : t['com.affine.toastMessage.addedFavorites']());
    }, [
        compatibleFavoriteItemsAdapter,
        isFavorite,
        t
    ]);
    const pageHeaderColsDef = usePageHeaderColsDef();
    const { clickFilter, createFilter, filters, showFilter, updateFilters, filteredList } = useFilter(pageMetas.map((meta)=>({
            meta,
            publicMode: getPublicMode(meta.id),
            favorite: isFavorite(meta)
        })));
    const { searchText, updateSearchText, searchedList } = useSearch(filteredList);
    const operationsRenderer = useCallback((item)=>{
        const page = item;
        return _jsx(FavoriteTag, {
            style: {
                marginRight: 8
            },
            onClick: ()=>onToggleFavoritePage(page),
            active: isFavorite(page)
        });
    }, [
        isFavorite,
        onToggleFavoritePage
    ]);
    const pageHeaderRenderer = useCallback(()=>{
        return _jsx(ListTableHeader, {
            headerCols: pageHeaderColsDef
        });
    }, [
        pageHeaderColsDef
    ]);
    const pageItemRenderer = useCallback((item)=>{
        return _jsx(PageListItemRenderer, {
            ...item
        });
    }, []);
    return _jsx(SelectorLayout, {
        searchPlaceholder: t['com.affine.editCollection.search.placeholder'](),
        selectedCount: value.length,
        onSearch: updateSearchText,
        onClear: clearSelected,
        onCancel: onCancel,
        onConfirm: confirm,
        actions: buttons,
        children: _jsxs("div", {
            className: styles.pagesTab,
            children: [
                _jsxs("div", {
                    className: styles.pagesTabContent,
                    children: [
                        header ?? _jsx("div", {
                            style: {
                                fontSize: 12,
                                lineHeight: '20px',
                                fontWeight: 600
                            },
                            children: t['com.affine.selectPage.title']()
                        }),
                        !showFilter && filters.length === 0 ? _jsx(Menu, {
                            items: _jsx(VariableSelect, {
                                propertiesMeta: docCollection.meta.properties,
                                selected: filters,
                                onSelect: createFilter
                            }),
                            children: _jsx(IconButton, {
                                icon: _jsx(FilterIcon, {}),
                                onClick: clickFilter
                            })
                        }) : _jsx(IconButton, {
                            icon: _jsx(FilterIcon, {}),
                            onClick: clickFilter
                        })
                    ]
                }),
                showFilter ? _jsx("div", {
                    style: {
                        padding: '12px 16px 16px'
                    },
                    children: _jsx(FilterList, {
                        propertiesMeta: docCollection.meta.properties,
                        value: filters,
                        onChange: updateFilters
                    })
                }) : null,
                searchedList.length ? _jsx(VirtualizedList, {
                    className: styles.pageList,
                    items: searchedList,
                    docCollection: docCollection,
                    selectable: true,
                    onSelectedIdsChange: onChange,
                    selectedIds: value,
                    operationsRenderer: operationsRenderer,
                    itemRenderer: pageItemRenderer,
                    headerRenderer: pageHeaderRenderer
                }) : _jsx(EmptyList, {
                    search: searchText
                })
            ]
        })
    });
};
export const EmptyList = ({ search })=>{
    const t = useI18n();
    return _jsxs("div", {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flex: 1
        },
        children: [
            _jsx(AffineShapeIcon, {}),
            _jsx("div", {
                style: {
                    margin: '18px 0',
                    fontSize: 20,
                    lineHeight: '28px',
                    fontWeight: 600
                },
                children: t['com.affine.selectPage.empty']()
            }),
            search ? _jsx("div", {
                className: styles.ellipsis,
                style: {
                    maxWidth: 300,
                    fontSize: 15,
                    lineHeight: '24px'
                },
                children: _jsxs(Trans, {
                    i18nKey: "com.affine.selectPage.empty.tips",
                    values: {
                        search
                    },
                    children: [
                        "No page titles contain",
                        _jsx("span", {
                            style: {
                                fontWeight: 600,
                                color: 'var(--affine-primary-color)'
                            },
                            children: "search"
                        })
                    ]
                })
            }) : null
        ]
    });
};
