import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu, MenuItem, MenuTrigger } from '@affine/component/ui/menu';
import { calcLocaleCompleteness } from '@affine/i18n';
import { DoneIcon } from '@blocksuite/icons/rc';
import { memo } from 'react';
import { useLanguageHelper } from '../../../components/hooks/affine/use-language-helper';
import * as styles from './style.css';
const LanguageMenuContent = memo(function LanguageMenuContent() {
    const { currentLanguage, languagesList, onLanguageChange } = useLanguageHelper();
    return _jsx(_Fragment, {
        children: languagesList.map((option)=>{
            const selected = currentLanguage?.originalName === option.originalName;
            const completeness = calcLocaleCompleteness(option.tag);
            return _jsx(MenuItem, {
                title: option.name,
                lang: option.tag,
                onSelect: ()=>onLanguageChange(option.tag),
                suffix: (completeness * 100).toFixed(0) + '%',
                "data-selected": selected,
                className: styles.menuItem,
                children: _jsxs("div", {
                    className: styles.languageLabelWrapper,
                    children: [
                        _jsx("div", {
                            children: option.originalName
                        }),
                        selected && _jsx(DoneIcon, {
                            fontSize: '16px'
                        })
                    ]
                })
            }, option.name);
        })
    });
});
export const LanguageMenu = ()=>{
    const { currentLanguage } = useLanguageHelper();
    return _jsx(Menu, {
        items: _jsx(LanguageMenuContent, {}),
        contentOptions: {
            className: styles.menu,
            align: 'end'
        },
        children: _jsx(MenuTrigger, {
            "data-testid": "language-menu-button",
            style: {
                textTransform: 'capitalize',
                fontWeight: 600,
                width: '250px'
            },
            block: true,
            children: currentLanguage?.originalName || ''
        })
    });
};
