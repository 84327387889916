function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init_block, _init_cardNumber, _init_disabledIcon, _init_enableNotesSorting, _init_showPreviewIcon, _initProto;
import { noop, SignalWatcher, WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { SmallLinkedDocIcon } from '../../_common/icons.js';
import { placeholderMap, previewIconMap } from '../config.js';
import { isHeadingBlock, isRootBlock } from '../utils/query.js';
function assertType(value) {
    noop(value);
}
const styles = css`
  :host {
    display: block;
    width: 100%;
    font-family: var(--affine-font-family);
  }

  :host(:hover) {
    cursor: pointer;
    background: var(--affine-hover-color);
  }

  :host(.active) {
    color: var(--affine-text-emphasis-color);
  }

  .outline-block-preview {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 8px;
    white-space: nowrap;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    padding: 4px;
    background: var(--affine-background-secondary-color);
    border-radius: 4px;
    color: var(--affine-icon-color);
  }

  .icon.disabled {
    color: var(--affine-disabled-icon-color);
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    font-size: var(--affine-font-sm);
    line-height: 22px;
    height: 22px;
  }

  .text.general,
  .subtype.text,
  .subtype.quote {
    font-weight: 400;
    padding-left: 28px;
  }

  .subtype.title,
  .subtype.h1,
  .subtype.h2,
  .subtype.h3,
  .subtype.h4,
  .subtype.h5,
  .subtype.h6 {
    font-weight: 600;
  }

  .subtype.title {
    padding-left: 0;
  }
  .subtype.h1 {
    padding-left: 0;
  }
  .subtype.h2 {
    padding-left: 4px;
  }
  .subtype.h3 {
    padding-left: 12px;
  }
  .subtype.h4 {
    padding-left: 16px;
  }
  .subtype.h5 {
    padding-left: 20px;
  }
  .subtype.h6 {
    padding-left: 24px;
  }

  .outline-block-preview:not(:has(span)) {
    display: none;
  }

  .text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .linked-doc-preview svg {
    width: 1.1em;
    height: 1.1em;
    vertical-align: middle;
    font-size: inherit;
    margin-bottom: 0.1em;
  }

  .linked-doc-text {
    font-size: inherit;
    border-bottom: 0.5px solid var(--affine-divider-color);
    white-space: break-spaces;
    margin-right: 2px;
  }

  .linked-doc-preview.unavailable svg {
    color: var(--affine-text-disable-color);
  }

  .linked-doc-preview.unavailable .linked-doc-text {
    color: var(--affine-text-disable-color);
    text-decoration: line-through;
  }
`;
export const AFFINE_OUTLINE_BLOCK_PREVIEW = 'affine-outline-block-preview';
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
export class OutlineBlockPreview extends SignalWatcher(WithDisposable(LitElement)) {
    static{
        ({ e: [_init_block, _init_cardNumber, _init_disabledIcon, _init_enableNotesSorting, _init_showPreviewIcon, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "block"
            ],
            [
                _dec1,
                1,
                "cardNumber"
            ],
            [
                _dec2,
                1,
                "disabledIcon"
            ],
            [
                _dec3,
                1,
                "enableNotesSorting"
            ],
            [
                _dec4,
                1,
                "showPreviewIcon"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    _TextBlockPreview(block) {
        const deltas = block.text.yText.toDelta();
        if (!block.text.length) return nothing;
        const iconClass = this.disabledIcon ? 'icon disabled' : 'icon';
        const previewText = deltas.map((delta)=>{
            if (delta.attributes?.reference) {
                const refAttribute = delta.attributes.reference;
                const refMeta = block.doc.collection.meta.docMetas.find((doc)=>doc.id === refAttribute.pageId);
                const unavailable = !refMeta;
                const title = unavailable ? 'Deleted doc' : refMeta.title;
                return html`<span
          class="linked-doc-preview ${unavailable ? 'unavailable' : ''}"
          >${SmallLinkedDocIcon}
          <span class="linked-doc-text"
            >${title.length ? title : 'Untitled'}</span
          ></span
        >`;
            } else {
                return delta.insert.toString().trim().length > 0 ? html`<span>${delta.insert.toString()}</span>` : nothing;
            }
        });
        return html`<span class="text subtype ${block.type}">${previewText}</span>
      ${this.showPreviewIcon ? html`<span class=${iconClass}>${previewIconMap[block.type]}</span>` : nothing}`;
    }
    render() {
        return html`<div class="outline-block-preview">
      ${this.renderBlockByFlavour()}
    </div>`;
    }
    renderBlockByFlavour() {
        const { block } = this;
        const iconClass = this.disabledIcon ? 'icon disabled' : 'icon';
        if (!this.enableNotesSorting && !isHeadingBlock(block) && !isRootBlock(block)) return nothing;
        switch(block.flavour){
            case 'affine:page':
                assertType(block);
                return block.title.length > 0 ? html`<span class="text subtype title">
              ${block.title$.value}
            </span>` : nothing;
            case 'affine:paragraph':
                assertType(block);
                return this._TextBlockPreview(block);
            case 'affine:list':
                assertType(block);
                return this._TextBlockPreview(block);
            case 'affine:bookmark':
                assertType(block);
                return html`
          <span class="text general"
            >${block.title || block.url || placeholderMap['bookmark']}</span
          >
          ${this.showPreviewIcon ? html`<span class=${iconClass}
                >${previewIconMap['bookmark']}</span
              >` : nothing}
        `;
            case 'affine:code':
                assertType(block);
                return html`
          <span class="text general"
            >${block.language ?? placeholderMap['code']}</span
          >
          ${this.showPreviewIcon ? html`<span class=${iconClass}>${previewIconMap['code']}</span>` : nothing}
        `;
            case 'affine:database':
                assertType(block);
                return html`
          <span class="text general"
            >${block.title.toString().length ? block.title.toString() : placeholderMap['database']}</span
          >
          ${this.showPreviewIcon ? html`<span class=${iconClass}>${previewIconMap['table']}</span>` : nothing}
        `;
            case 'affine:image':
                assertType(block);
                return html`
          <span class="text general"
            >${block.caption?.length ? block.caption : placeholderMap['image']}</span
          >
          ${this.showPreviewIcon ? html`<span class=${iconClass}>${previewIconMap['image']}</span>` : nothing}
        `;
            case 'affine:attachment':
                assertType(block);
                return html`
          <span class="text general"
            >${block.name?.length ? block.name : placeholderMap['attachment']}</span
          >
          ${this.showPreviewIcon ? html`<span class=${iconClass}
                >${previewIconMap['attachment']}</span
              >` : nothing}
        `;
            default:
                return nothing;
        }
    }
    #___private_block_1;
    get block() {
        return this.#___private_block_1;
    }
    set block(_v) {
        this.#___private_block_1 = _v;
    }
    #___private_cardNumber_2;
    get cardNumber() {
        return this.#___private_cardNumber_2;
    }
    set cardNumber(_v) {
        this.#___private_cardNumber_2 = _v;
    }
    #___private_disabledIcon_3;
    get disabledIcon() {
        return this.#___private_disabledIcon_3;
    }
    set disabledIcon(_v) {
        this.#___private_disabledIcon_3 = _v;
    }
    #___private_enableNotesSorting_4;
    get enableNotesSorting() {
        return this.#___private_enableNotesSorting_4;
    }
    set enableNotesSorting(_v) {
        this.#___private_enableNotesSorting_4 = _v;
    }
    #___private_showPreviewIcon_5;
    get showPreviewIcon() {
        return this.#___private_showPreviewIcon_5;
    }
    set showPreviewIcon(_v) {
        this.#___private_showPreviewIcon_5 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_block_1 = (_initProto(this), _init_block(this)), this.#___private_cardNumber_2 = _init_cardNumber(this), this.#___private_disabledIcon_3 = _init_disabledIcon(this, false), this.#___private_enableNotesSorting_4 = _init_enableNotesSorting(this), this.#___private_showPreviewIcon_5 = _init_showPreviewIcon(this);
    }
}
