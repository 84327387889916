import { SubscriptionRecurring } from '@affine/graphql';
import { SubscriptionPlan } from '@affine/graphql';
import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class Subscription extends Entity {
    constructor(authService, serverConfigService, store){
        super(), this.authService = authService, this.serverConfigService = serverConfigService, this.store = store, this.subscription$ = new LiveData(null), this.isRevalidating$ = new LiveData(false), this.error$ = new LiveData(null), this.pro$ = this.subscription$.map((subscriptions)=>subscriptions ? subscriptions.find((sub)=>sub.plan === SubscriptionPlan.Pro) : null), this.ai$ = this.subscription$.map((subscriptions)=>subscriptions ? subscriptions.find((sub)=>sub.plan === SubscriptionPlan.AI) : null), this.isBeliever$ = this.pro$.map((sub)=>sub?.recurring === SubscriptionRecurring.Lifetime), this.revalidate = effect(map(()=>({
                accountId: this.authService.session.account$.value?.id
            })), exhaustMapSwitchUntilChanged((a, b)=>a.accountId === b.accountId, ({ accountId })=>{
            return fromPromise(async (signal)=>{
                if (!accountId) {
                    return undefined;
                }
                const serverConfig = await this.serverConfigService.serverConfig.features$.waitForNonNull(signal);
                if (!serverConfig.payment) {
                    return {
                        userId: accountId,
                        subscriptions: []
                    };
                }
                const { userId, subscriptions } = await this.store.fetchSubscriptions(signal);
                if (userId !== accountId) {
                    this.authService.session.revalidate();
                    await this.authService.session.waitForRevalidation();
                    return null;
                }
                return {
                    userId: userId,
                    subscriptions: subscriptions
                };
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((data)=>{
                if (data) {
                    this.store.setCachedSubscriptions(data.userId, data.subscriptions);
                    this.subscription$.next(data.subscriptions);
                } else {
                    this.subscription$.next(undefined);
                }
                return EMPTY;
            }), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false)));
        }, ({ accountId })=>{
            this.reset();
            if (!accountId) {
                this.subscription$.next(null);
            } else {
                this.subscription$.next(this.store.getCachedSubscriptions(accountId));
            }
        }));
    }
    async resumeSubscription(idempotencyKey, plan) {
        await this.store.mutateResumeSubscription(idempotencyKey, plan);
        await this.waitForRevalidation();
    }
    async cancelSubscription(idempotencyKey, plan) {
        await this.store.mutateCancelSubscription(idempotencyKey, plan);
        await this.waitForRevalidation();
    }
    async setSubscriptionRecurring(idempotencyKey, recurring, plan) {
        await this.store.setSubscriptionRecurring(idempotencyKey, recurring, plan);
        await this.waitForRevalidation();
    }
    async waitForRevalidation(signal) {
        this.revalidate();
        await this.isRevalidating$.waitFor((isRevalidating)=>!isRevalidating, signal);
    }
    reset() {
        this.subscription$.next(null);
        this.isRevalidating$.next(false);
        this.error$.next(null);
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
