export const reassociateConnectorsCommand = (ctx, next)=>{
    const { oldId, newId } = ctx;
    const service = ctx.std.getService('affine:surface');
    if (!oldId || !newId || !service) {
        next();
        return;
    }
    const surface = service.surface;
    const connectors = surface.getConnectors(oldId);
    for (const { id, source, target } of connectors){
        if (source.id === oldId) {
            surface.updateElement(id, {
                source: {
                    ...source,
                    id: newId
                }
            });
            continue;
        }
        if (target.id === oldId) {
            surface.updateElement(id, {
                target: {
                    ...target,
                    id: newId
                }
            });
        }
    }
    next();
};
