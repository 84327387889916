import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@affine/component';
import { useCatchEventCallback } from '@affine/core/components/hooks/use-catch-event-hook';
import { useTagI18n } from '@affine/core/modules/tag/entities/internal-tag';
import { stopPropagation } from '@affine/core/utils';
import { CloseIcon, MoreHorizontalIcon } from '@blocksuite/icons/rc';
import { LiveData, useLiveData } from '@toeverything/infra';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { useMemo } from 'react';
import * as styles from './page-tags.css';
export const TempTagItem = ({ value, color, maxWidth = '100%' })=>{
    return _jsx("div", {
        className: styles.tag,
        title: value,
        children: _jsxs("div", {
            style: {
                maxWidth: maxWidth
            },
            className: styles.tagInline,
            children: [
                _jsx("div", {
                    className: styles.tagIndicator,
                    style: {
                        backgroundColor: color
                    }
                }),
                _jsx("div", {
                    className: styles.tagLabel,
                    children: value
                })
            ]
        })
    });
};
export const TagItem = ({ tag, idx, mode, focused, onRemoved, style, maxWidth })=>{
    const value = useLiveData(tag?.value$);
    const color = useLiveData(tag?.color$);
    const t = useTagI18n();
    const handleRemove = useCatchEventCallback(()=>{
        onRemoved?.();
    }, [
        onRemoved
    ]);
    return _jsx("div", {
        "data-testid": "page-tag",
        className: styles.tag,
        "data-idx": idx,
        "data-tag-id": tag?.id,
        "data-tag-value": value,
        title: value,
        style: style,
        children: _jsxs("div", {
            style: {
                maxWidth: maxWidth
            },
            "data-focused": focused,
            className: mode === 'inline' ? styles.tagInline : styles.tagListItem,
            children: [
                _jsx("div", {
                    className: styles.tagIndicator,
                    style: {
                        backgroundColor: color
                    }
                }),
                _jsx("div", {
                    className: styles.tagLabel,
                    children: t(value)
                }),
                onRemoved ? _jsx("div", {
                    "data-testid": "remove-tag-button",
                    className: styles.tagRemove,
                    onClick: handleRemove,
                    children: _jsx(CloseIcon, {})
                }) : null
            ]
        })
    });
};
const TagItemNormal = ({ tags, maxItems })=>{
    const nTags = useMemo(()=>{
        return maxItems ? tags.slice(0, maxItems) : tags;
    }, [
        maxItems,
        tags
    ]);
    const tagsOrdered = useLiveData(useMemo(()=>{
        return LiveData.computed((get)=>[
                ...nTags
            ].sort((a, b)=>get(a.value$).length - get(b.value$).length));
    }, [
        nTags
    ]));
    return useMemo(()=>tagsOrdered.map((tag, idx)=>_jsx(TagItem, {
                tag: tag,
                idx: idx,
                mode: "inline"
            }, tag.id)), [
        tagsOrdered
    ]);
};
export const PageTags = ({ tags, widthOnHover, maxItems, hoverExpandDirection })=>{
    const sanitizedWidthOnHover = widthOnHover ? typeof widthOnHover === 'string' ? widthOnHover : `${widthOnHover}px` : 'auto';
    const tagsInPopover = useMemo(()=>{
        const lastTags = tags.slice(maxItems);
        return _jsx("div", {
            className: styles.tagsListContainer,
            children: lastTags.map((tag, idx)=>_jsx(TagItem, {
                    tag: tag,
                    idx: idx,
                    mode: "list-item"
                }, tag.id))
        });
    }, [
        maxItems,
        tags
    ]);
    return _jsx("div", {
        "data-testid": "page-tags",
        className: styles.root,
        style: assignInlineVars({
            [styles.hoverMaxWidth]: sanitizedWidthOnHover
        }),
        children: _jsxs("div", {
            style: {
                right: hoverExpandDirection === 'left' ? 0 : 'auto',
                left: hoverExpandDirection === 'right' ? 0 : 'auto'
            },
            className: clsx(styles.innerContainer),
            children: [
                _jsx("div", {
                    className: styles.innerBackdrop
                }),
                _jsx("div", {
                    className: styles.tagsScrollContainer,
                    children: _jsx(TagItemNormal, {
                        tags: tags,
                        maxItems: maxItems
                    })
                }),
                maxItems && tags.length > maxItems ? _jsx(Menu, {
                    items: tagsInPopover,
                    contentOptions: {
                        onClick: stopPropagation
                    },
                    children: _jsx("div", {
                        className: styles.showMoreTag,
                        children: _jsx(MoreHorizontalIcon, {})
                    })
                }) : null
            ]
        })
    });
};
