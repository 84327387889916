import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { i18nTime, useI18n } from '@affine/i18n';
import { DateTimeIcon, HistoryIcon, LinkIcon, TextIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import { useDebouncedValue } from 'foxact/use-debounced-value';
import { useMemo } from 'react';
import * as styles from './feed-info-table.css';
const RowComponent = ({ name, icon, value })=>{
    return _jsxs("div", {
        className: styles.rowCell,
        children: [
            _jsxs("div", {
                className: styles.rowNameContainer,
                children: [
                    _jsx("div", {
                        className: styles.icon,
                        children: icon
                    }),
                    _jsx("span", {
                        className: styles.rowName,
                        children: name
                    })
                ]
            }),
            _jsx("div", {
                className: styles.rowValue,
                children: value ? value : 'unknown'
            })
        ]
    });
};
export const FeedInfoTable = ({ feed, className })=>{
    const t = useI18n();
    const element = useMemo(()=>{
        const formatI18nTime = (time)=>i18nTime(time, {
                relative: {
                    max: [
                        1,
                        'day'
                    ],
                    accuracy: 'minute'
                },
                absolute: {
                    accuracy: 'day'
                }
            });
        return _jsxs(_Fragment, {
            children: [
                feed.description && _jsx(RowComponent, {
                    icon: _jsx(TextIcon, {}),
                    name: t['ai.wemem.rootAppSidebar.feeds.info-modal.description'](),
                    value: feed.description
                }),
                feed.source && _jsx(RowComponent, {
                    icon: _jsx(LinkIcon, {}),
                    name: t['ai.wemem.rootAppSidebar.feeds.info-modal.url'](),
                    value: feed.source
                }),
                feed.createdAt && _jsx(RowComponent, {
                    icon: _jsx(DateTimeIcon, {}),
                    name: t['Created'](),
                    value: formatI18nTime(feed.createdAt)
                }),
                feed.updatedAt && _jsx(RowComponent, {
                    icon: _jsx(HistoryIcon, {}),
                    name: t['Updated'](),
                    value: formatI18nTime(feed.updatedAt)
                })
            ]
        });
    }, [
        feed.createdAt,
        feed.description,
        feed.source,
        feed.updatedAt,
        t
    ]);
    const dElement = useDebouncedValue(element, 500);
    return _jsx("div", {
        className: clsx(styles.container, className),
        children: dElement
    });
};
