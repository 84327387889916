function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _init__activeSlicerIndex, _init__anchorNote, _init__enableNoteSlicer, _init_edgeless, _initProto;
import { SmallScissorsIcon } from '@blocksuite/affine-components/icons';
import { EDGELESS_BLOCK_CHILD_PADDING } from '@blocksuite/affine-shared/consts';
import { TelemetryProvider } from '@blocksuite/affine-shared/services';
import { getRectByBlockComponent } from '@blocksuite/affine-shared/utils';
import { deserializeXYWH, DisposableGroup, Point, serializeXYWH, WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { DEFAULT_NOTE_HEIGHT } from '../../utils/consts.js';
import { isNoteBlock } from '../../utils/query.js';
const DIVIDING_LINE_OFFSET = 4;
const NEW_NOTE_GAP = 40;
const styles = css`
  :host {
    display: flex;
  }

  .note-slicer-container {
    display: flex;
  }

  .note-slicer-button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: var(--affine-icon-color);
    border: 1px solid var(--affine-border-color);
    background-color: var(--affine-background-overlay-panel-color);
    box-shadow: var(--affine-menu-shadow);
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform-origin: left top;
    z-index: var(--affine-z-index-popover);
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .note-slicer-dividing-line-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    cursor: pointer;
  }

  .note-slicer-dividing-line {
    display: block;
    height: 1px;
    width: 100%;
    z-index: var(--affine-z-index-popover);
    background-image: linear-gradient(
      to right,
      var(--affine-black-10) 50%,
      transparent 50%
    );
    background-size: 4px 100%;
  }
  .note-slicer-dividing-line-container.active .note-slicer-dividing-line {
    background-image: linear-gradient(
      to right,
      var(--affine-black-60) 50%,
      transparent 50%
    );
    animation: slide 0.3s linear infinite;
  }
  @keyframes slide {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -4px 0;
    }
  }
`;
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
});
export class NoteSlicer extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__activeSlicerIndex, _init__anchorNote, _init__enableNoteSlicer, _init_edgeless, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_activeSlicerIndex"
            ],
            [
                _dec1,
                1,
                "_anchorNote"
            ],
            [
                _dec2,
                1,
                "_enableNoteSlicer"
            ],
            [
                _dec3,
                1,
                "edgeless"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _editorHost() {
        return this.edgeless.host;
    }
    get _noteBlock() {
        if (!this._editorHost) return null;
        const noteBlock = this._editorHost.view.getBlock(this._anchorNote?.id ?? '');
        return noteBlock ? noteBlock : null;
    }
    get _selection() {
        return this.edgeless.service.selection;
    }
    get _viewportOffset() {
        const { viewport } = this.edgeless;
        return {
            left: viewport.left ?? 0,
            top: viewport.top ?? 0
        };
    }
    get _zoom() {
        if (!this.edgeless.service?.viewport) {
            console.error('Viewport is not found, something went wrong.');
            return 1;
        }
        return this.edgeless.service.viewport.zoom;
    }
    get selectedRectEle() {
        return this.edgeless.querySelector('edgeless-selected-rect');
    }
    _sliceNote() {
        if (!this._anchorNote || !this._noteBlockIds.length) return;
        const doc = this.edgeless.doc;
        const { index: originIndex, xywh, background, children, displayMode } = this._anchorNote;
        const { collapse: _, collapsedHeight: __, ...restOfEdgeless } = this._anchorNote.edgeless;
        const anchorBlockId = this._noteBlockIds[this._activeSlicerIndex];
        if (!anchorBlockId) return;
        const sliceIndex = children.findIndex((block)=>block.id === anchorBlockId);
        const resetBlocks = children.slice(sliceIndex + 1);
        const [x, , width] = deserializeXYWH(xywh);
        const sliceVerticalPos = this._divingLinePositions[this._activeSlicerIndex].y;
        const newY = this.edgeless.service.viewport.toModelCoord(x, sliceVerticalPos)[1];
        const newNoteId = this.edgeless.service.addBlock('affine:note', {
            background,
            displayMode,
            xywh: serializeXYWH(x, newY + NEW_NOTE_GAP, width, DEFAULT_NOTE_HEIGHT),
            index: originIndex + 1,
            edgeless: restOfEdgeless
        }, doc.root?.id);
        doc.moveBlocks(resetBlocks, doc.getBlockById(newNoteId));
        this._activeSlicerIndex = 0;
        this._selection.set({
            elements: [
                newNoteId
            ],
            editing: false
        });
        this.edgeless.std.getOptional(TelemetryProvider)?.track('SplitNote', {
            control: 'NoteSlicer'
        });
    }
    _updateActiveSlicerIndex(pos) {
        const { _divingLinePositions } = this;
        const curY = pos.y + DIVIDING_LINE_OFFSET * this._zoom;
        let index = -1;
        for(let i = 0; i < _divingLinePositions.length; i++){
            const currentY = _divingLinePositions[i].y;
            const previousY = i > 0 ? _divingLinePositions[i - 1].y : 0;
            const midY = (currentY + previousY) / 2;
            if (curY < midY) {
                break;
            }
            index++;
        }
        if (index < 0) index = 0;
        this._activeSlicerIndex = index;
    }
    _updateDivingLineAndBlockIds() {
        if (!this._anchorNote || !this._noteBlock) {
            this._divingLinePositions = [];
            this._noteBlockIds = [];
            return;
        }
        const divingLinePositions = [];
        const noteBlockIds = [];
        const noteRect = this._noteBlock.getBoundingClientRect();
        const noteTop = noteRect.top;
        const noteBottom = noteRect.bottom;
        for(let i = 0; i < this._anchorNote.children.length - 1; i++){
            const child = this._anchorNote.children[i];
            const rect = this.edgeless.host.view.getBlock(child.id)?.getBoundingClientRect();
            if (rect && rect.bottom > noteTop && rect.bottom < noteBottom) {
                const x = rect.x - this._viewportOffset.left;
                const y = rect.bottom + DIVIDING_LINE_OFFSET * this._zoom - this._viewportOffset.top;
                divingLinePositions.push(new Point(x, y));
                noteBlockIds.push(child.id);
            }
        }
        this._divingLinePositions = divingLinePositions;
        this._noteBlockIds = noteBlockIds;
    }
    _updateSlicedNote() {
        const { edgeless } = this;
        const { selectedElements } = edgeless.service.selection;
        if (!edgeless.service.selection.editing && selectedElements.length === 1 && isNoteBlock(selectedElements[0])) {
            this._anchorNote = selectedElements[0];
        } else {
            this._anchorNote = null;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const { disposables, edgeless } = this;
        if (!edgeless.service) return;
        this._updateDivingLineAndBlockIds();
        disposables.add(edgeless.service.uiEventDispatcher.add('pointerMove', (ctx)=>{
            if (this._hidden) this._hidden = false;
            const state = ctx.get('pointerState');
            const pos = new Point(state.x, state.y);
            this._updateActiveSlicerIndex(pos);
        }));
        disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>{
            this._hidden = true;
            this.requestUpdate();
        }));
        disposables.add(edgeless.service.selection.slots.updated.on(()=>{
            this._enableNoteSlicer = false;
            this._updateSlicedNote();
            if (this.selectedRectEle) {
                this.selectedRectEle.autoCompleteOff = false;
            }
        }));
        disposables.add(edgeless.slots.toggleNoteSlicer.on(()=>{
            this._enableNoteSlicer = !this._enableNoteSlicer;
            if (this.selectedRectEle && this._enableNoteSlicer) {
                this.selectedRectEle.autoCompleteOff = true;
            }
        }));
        const { surface } = edgeless;
        requestAnimationFrame(()=>{
            if (surface.isConnected && edgeless.dispatcher) {
                disposables.add(edgeless.dispatcher.add('click', (ctx)=>{
                    const event = ctx.get('pointerState');
                    const { raw } = event;
                    const target = raw.target;
                    if (!target) return;
                    if (target.closest('note-slicer')) {
                        this._sliceNote();
                    }
                }));
            }
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.disposables.dispose();
        this._noteDisposables?.dispose();
        this._noteDisposables = null;
    }
    firstUpdated() {
        if (!this.edgeless.service) return;
        this.disposables.add(this.edgeless.service.uiEventDispatcher.add('wheel', ()=>{
            this._hidden = true;
            this.requestUpdate();
        }));
    }
    render() {
        if (this.edgeless.doc.readonly || this._hidden || !this._anchorNote || !this._enableNoteSlicer) {
            return nothing;
        }
        this._updateDivingLineAndBlockIds();
        const noteBlock = this._noteBlock;
        if (!noteBlock || !this._divingLinePositions.length) return nothing;
        const rect = getRectByBlockComponent(noteBlock);
        const width = rect.width - 2 * EDGELESS_BLOCK_CHILD_PADDING;
        const buttonPosition = this._divingLinePositions[this._activeSlicerIndex];
        return html`<div class="note-slicer-container">
      <div
        class="note-slicer-button"
        style=${styleMap({
            left: `${buttonPosition.x - 66 * this._zoom}px`,
            top: `${buttonPosition.y}px`,
            opacity: 1,
            scale: `${this._zoom}`,
            transform: 'translateY(-50%)'
        })}
      >
        ${SmallScissorsIcon}
      </div>
      ${this._divingLinePositions.map((pos, idx)=>{
            const dividingLineClasses = classMap({
                'note-slicer-dividing-line-container': true,
                active: idx === this._activeSlicerIndex
            });
            return html`<div
          class=${dividingLineClasses}
          style=${styleMap({
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                width: `${width}px`
            })}
        >
          <span class="note-slicer-dividing-line"></span>
        </div>`;
        })}
    </div> `;
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('anchorNote')) {
            this._noteDisposables?.dispose();
            this._noteDisposables = null;
            if (this._anchorNote) {
                this._noteDisposables = new DisposableGroup();
                this._noteDisposables.add(this._anchorNote.propsUpdated.on(({ key })=>{
                    if (key === 'children' || key === 'xywh') {
                        this.requestUpdate();
                    }
                }));
            }
        }
    }
    #___private__activeSlicerIndex_1;
    get _activeSlicerIndex() {
        return this.#___private__activeSlicerIndex_1;
    }
    set _activeSlicerIndex(_v) {
        this.#___private__activeSlicerIndex_1 = _v;
    }
    #___private__anchorNote_2;
    get _anchorNote() {
        return this.#___private__anchorNote_2;
    }
    set _anchorNote(_v) {
        this.#___private__anchorNote_2 = _v;
    }
    #___private__enableNoteSlicer_3;
    get _enableNoteSlicer() {
        return this.#___private__enableNoteSlicer_3;
    }
    set _enableNoteSlicer(_v) {
        this.#___private__enableNoteSlicer_3 = _v;
    }
    #___private_edgeless_4;
    get edgeless() {
        return this.#___private_edgeless_4;
    }
    set edgeless(_v) {
        this.#___private_edgeless_4 = _v;
    }
    constructor(...args){
        super(...args), this._divingLinePositions = [], this._hidden = false, this._noteBlockIds = [], this._noteDisposables = null, this.#___private__activeSlicerIndex_1 = (_initProto(this), _init__activeSlicerIndex(this, 0)), this.#___private__anchorNote_2 = _init__anchorNote(this, null), this.#___private__enableNoteSlicer_3 = _init__enableNoteSlicer(this, false), this.#___private_edgeless_4 = _init_edgeless(this);
    }
}
