import { applyAwarenessUpdate, encodeAwarenessUpdate } from 'y-protocols/awareness.js';
export class BroadcastChannelAwarenessConnection {
    constructor(workspaceId){
        this.workspaceId = workspaceId;
        this.channel = null;
        this.awareness = null;
        this.handleAwarenessUpdate = (changes, origin)=>{
            if (this.awareness === null) {
                return;
            }
            if (origin === 'remote') {
                return;
            }
            const changedClients = Object.values(changes).reduce((res, cur)=>res.concat(cur));
            const update = encodeAwarenessUpdate(this.awareness, changedClients);
            this.channel?.postMessage({
                type: 'update',
                update: update
            });
        };
        this.handleChannelMessage = (event)=>{
            if (this.awareness === null) {
                return;
            }
            if (event.data.type === 'update') {
                const update = event.data.update;
                applyAwarenessUpdate(this.awareness, update, 'remote');
            }
            if (event.data.type === 'connect') {
                this.channel?.postMessage({
                    type: 'update',
                    update: encodeAwarenessUpdate(this.awareness, [
                        this.awareness.clientID
                    ])
                });
            }
        };
    }
    connect(awareness) {
        this.awareness = awareness;
        this.channel = new BroadcastChannel('awareness:' + this.workspaceId);
        this.channel.postMessage({
            type: 'connect'
        });
        this.awareness.on('update', this.handleAwarenessUpdate);
        this.channel.addEventListener('message', this.handleChannelMessage);
    }
    disconnect() {
        this.channel?.close();
        this.channel = null;
        this.awareness?.off('update', this.handleAwarenessUpdate);
        this.awareness = null;
    }
}
