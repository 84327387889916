import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import { ComponentNotFoundError, Framework } from '../core';
import { parseIdentifier } from '../core/identifier';
import { MountPoint } from './scope-root-components';
export { useMount } from './scope-root-components';
export const FrameworkStackContext = React.createContext([
    Framework.EMPTY.provider()
]);
export function useFramework() {
    const stack = useContext(FrameworkStackContext);
    return stack[stack.length - 1];
}
export function useService(identifier) {
    const stack = useContext(FrameworkStackContext);
    let service = null;
    for(let i = stack.length - 1; i >= 0; i--){
        service = stack[i].getOptional(identifier, {
            sameScope: true
        });
        if (service) {
            break;
        }
    }
    if (!service) {
        throw new ComponentNotFoundError(parseIdentifier(identifier));
    }
    return service;
}
export function useServices(identifiers) {
    const stack = useContext(FrameworkStackContext);
    const services = {};
    for (const [key, value] of Object.entries(identifiers)){
        let service;
        for(let i = stack.length - 1; i >= 0; i--){
            service = stack[i].getOptional(value, {
                sameScope: true
            });
            if (service) {
                break;
            }
        }
        if (!service) {
            throw new ComponentNotFoundError(parseIdentifier(value));
        }
        services[key.charAt(0).toLowerCase() + key.slice(1)] = service;
    }
    return services;
}
export function useServiceOptional(identifier) {
    const stack = useContext(FrameworkStackContext);
    let service = null;
    for(let i = stack.length - 1; i >= 0; i--){
        service = stack[i].getOptional(identifier, {
            sameScope: true
        });
        if (service) {
            break;
        }
    }
    return service;
}
export const FrameworkRoot = ({ framework, children })=>{
    return _jsx(FrameworkStackContext.Provider, {
        value: [
            framework
        ],
        children: children
    });
};
export const FrameworkScope = ({ scope, children })=>{
    const stack = useContext(FrameworkStackContext);
    const nextStack = useMemo(()=>{
        if (!scope) return stack;
        return [
            ...stack,
            scope.framework
        ];
    }, [
        stack,
        scope
    ]);
    return _jsx(FrameworkStackContext.Provider, {
        value: nextStack,
        children: _jsx(MountPoint, {
            children: children
        })
    });
};
