import { COLORS, FIRST_COLOR } from './consts.js';
export const defaultPoint = (x = 0, y = 0)=>({
        x,
        y
    });
export const defaultHsva = ()=>({
        ...rgbToHsv(FIRST_COLOR),
        a: 1
    });
export function linearGradientAt(t) {
    if (t < 0) return COLORS[0][0];
    if (t > 1) return COLORS[COLORS.length - 1][0];
    let low = 0;
    let high = COLORS.length;
    while(low < high){
        const mid = Math.floor((low + high) / 2);
        const color = COLORS[mid];
        if (color[1] < t) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }
    if (low === 0) {
        low = 1;
    }
    const [rgb0, s0] = COLORS[low - 1];
    const [rgb1, s1] = COLORS[low];
    t = (t - s0) / (s1 - s0);
    const [r, g, b] = [
        lerp(rgb0.r, rgb1.r, t),
        lerp(rgb0.g, rgb1.g, t),
        lerp(rgb0.b, rgb1.b, t)
    ];
    return {
        r,
        g,
        b
    };
}
const lerp = (a, b, t)=>a + t * (b - a);
export const clamp = (min, val, max)=>Math.min(Math.max(min, val), max);
export const bound01 = (n, max)=>{
    n = clamp(0, n, max);
    if (Math.abs(n - max) < 0.000001) {
        return 1;
    }
    return n % max / max;
};
export const rgbToHsv = ({ r, g, b })=>{
    const v = Math.max(r, g, b);
    const d = v - Math.min(r, g, b);
    if (d === 0) {
        return {
            h: 0,
            s: 0,
            v
        };
    }
    const s = d / v;
    let h = 0;
    if (v === r) {
        h = (g - b) / d + (g < b ? 6 : 0);
    } else if (v === g) {
        h = (b - r) / d + 2;
    } else {
        h = (r - g) / d + 4;
    }
    h /= 6;
    return {
        h,
        s,
        v
    };
};
export const hsvToRgb = ({ h, s, v })=>{
    if (h < 0) h = (h + 1) % 1;
    h *= 6;
    s = clamp(0, s, 1);
    const i = Math.floor(h), f = h - i, p = v * (1 - s), q = v * (1 - f * s), t = v * (1 - (1 - f) * s), m = i % 6;
    let rgb = [
        0,
        0,
        0
    ];
    if (m === 0) rgb = [
        v,
        t,
        p
    ];
    else if (m === 1) rgb = [
        q,
        v,
        p
    ];
    else if (m === 2) rgb = [
        p,
        v,
        t
    ];
    else if (m === 3) rgb = [
        p,
        q,
        v
    ];
    else if (m === 4) rgb = [
        t,
        p,
        v
    ];
    else if (m === 5) rgb = [
        v,
        p,
        q
    ];
    const [r, g, b] = rgb;
    return {
        r,
        g,
        b
    };
};
export const rgbaToHsva = (rgba)=>({
        ...rgbToHsv(rgba),
        a: rgba.a
    });
export const hsvaToRgba = (hsva)=>({
        ...hsvToRgb(hsva),
        a: hsva.a
    });
export const rgbToHex = ({ r, g, b })=>[
        r,
        g,
        b
    ].map((n)=>n * 255).map(Math.round).map((s)=>s.toString(16).padStart(2, '0')).join('');
export const rgbaToHex8 = ({ r, g, b, a })=>{
    const hex = [
        r,
        g,
        b,
        a
    ].map((n)=>n * 255).map(Math.round).map((n)=>n.toString(16).padStart(2, '0')).join('');
    return `#${hex}`;
};
export const hsvaToHex8 = (hsva)=>rgbaToHex8(hsvaToRgba(hsva));
export const parseHexToRgba = (hex)=>{
    if (hex.startsWith('#')) {
        hex = hex.substring(1);
    }
    const len = hex.length;
    let arr = [];
    if (len === 3 || len === 4) {
        arr = hex.split('').map((s)=>s.repeat(2));
    } else if (len === 6 || len === 8) {
        arr = Array.from({
            length: len / 2
        }).fill(0).map((n, i)=>n + i * 2).map((n)=>hex.substring(n, n + 2));
    }
    const [r, g, b, a = 1] = arr.map((s)=>parseInt(s, 16)).map((n)=>bound01(n, 255));
    return {
        r,
        g,
        b,
        a
    };
};
export const parseHexToHsva = (hex)=>rgbaToHsva(parseHexToRgba(hex));
export const eq = (lhs, rhs)=>lhs.h === rhs.h && lhs.s === rhs.s && lhs.v === rhs.v;
export const renderCanvas = (canvas, rgb)=>{
    const { width, height } = canvas;
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'color';
    ctx.clearRect(0, 0, width, height);
    const s = ctx.createLinearGradient(0, 0, 0, height);
    s.addColorStop(0, '#0000');
    s.addColorStop(1, '#000');
    ctx.fillStyle = s;
    ctx.fillRect(0, 0, width, height);
    const v = ctx.createLinearGradient(0, 0, width, 0);
    v.addColorStop(0, '#fff');
    v.addColorStop(1, `#${rgbToHex(rgb)}`);
    ctx.fillStyle = v;
    ctx.fillRect(0, 0, width, height);
};
export const keepColor = (color)=>color.length > 7 && !color.endsWith('transparent') ? color.substring(0, 7) : color;
export const parseStringToRgba = (value)=>{
    value = value.trim();
    if (value.endsWith('transparent')) {
        return {
            r: 1,
            g: 1,
            b: 1,
            a: 0
        };
    }
    if (value.startsWith('#')) {
        return parseHexToRgba(value);
    }
    if (value.startsWith('rgb')) {
        const [r, g, b, a = 1] = value.replace(/^rgba?/, '').replace(/\(|\)/, '').split(',').map((s)=>parseFloat(s.trim())).map((n, i)=>bound01(n, i === 3 ? 1 : 255));
        return {
            r,
            g,
            b,
            a
        };
    }
    return {
        r: 0,
        g: 0,
        b: 0,
        a: 1
    };
};
export const preprocessColor = (style)=>{
    return ({ type, value })=>{
        if (value.startsWith('--')) {
            value = value.endsWith('transparent') ? 'transparent' : style.getPropertyValue(value);
        }
        const rgba = parseStringToRgba(value);
        return {
            type,
            rgba
        };
    };
};
export const packColor = (key, detail)=>{
    return {
        [key]: detail.palette ?? detail
    };
};
export const packColorsWithColorScheme = (colorScheme, value, oldColor)=>{
    const colors = [
        {
            type: 'normal',
            value
        },
        {
            type: 'light',
            value
        },
        {
            type: 'dark',
            value
        }
    ];
    let type = 'palette';
    if (typeof oldColor === 'object') {
        type = colorScheme in oldColor ? colorScheme : 'normal';
        colors[0].value = oldColor.normal ?? value;
        colors[1].value = oldColor.light ?? value;
        colors[2].value = oldColor.dark ?? value;
    }
    return {
        type,
        colors
    };
};
