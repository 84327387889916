import { html } from 'lit';
import { buildConnectorDenseMenu } from './connector/connector-dense-menu.js';
import { buildFrameDenseMenu } from './frame/frame-dense-menu.js';
import { buildLinkDenseMenu } from './link/link-dense-menu.js';
export const getQuickTools = ({ edgeless })=>{
    const { doc } = edgeless;
    const quickTools = [];
    quickTools.push({
        type: 'default',
        content: html`<edgeless-default-tool-button
      .edgeless=${edgeless}
    ></edgeless-default-tool-button>`
    });
    if (!doc.readonly) {
        quickTools.push({
            type: 'frame',
            content: html`<edgeless-frame-tool-button
        .edgeless=${edgeless}
      ></edgeless-frame-tool-button>`,
            menu: buildFrameDenseMenu(edgeless)
        });
    }
    quickTools.push({
        type: 'connector',
        content: html`<edgeless-connector-tool-button
      .edgeless=${edgeless}
    ></edgeless-connector-tool-button>`,
        menu: buildConnectorDenseMenu(edgeless)
    });
    quickTools.push({
        content: html`<edgeless-link-tool-button
      .edgeless=${edgeless}
    ></edgeless-link-tool-button>`,
        menu: buildLinkDenseMenu(edgeless)
    });
    return quickTools;
};
export const getSeniorTools = ({ edgeless, toolbarContainer })=>{
    const { doc } = edgeless;
    const tools = [];
    if (!doc.readonly) {
        tools.push({
            name: 'Note',
            content: html`<edgeless-note-senior-button .edgeless=${edgeless}>
      </edgeless-note-senior-button>`
        });
    }
    tools.push({
        name: 'Pen',
        content: html`<div class="brush-and-eraser">
      <edgeless-brush-tool-button
        .edgeless=${edgeless}
      ></edgeless-brush-tool-button>

      <edgeless-eraser-tool-button
        .edgeless=${edgeless}
      ></edgeless-eraser-tool-button>
    </div> `
    });
    tools.push({
        name: 'Shape',
        content: html`<edgeless-shape-tool-button
      .edgeless=${edgeless}
      .toolbarContainer=${toolbarContainer}
    ></edgeless-shape-tool-button>`
    });
    tools.push({
        name: 'Mind Map',
        content: html`<edgeless-mindmap-tool-button
      .edgeless=${edgeless}
      .toolbarContainer=${toolbarContainer}
    ></edgeless-mindmap-tool-button>`
    });
    tools.push({
        name: 'Template',
        content: html`<edgeless-template-button .edgeless=${edgeless}>
    </edgeless-template-button>`
    });
    return tools;
};
