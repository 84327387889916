import { jsx as _jsx } from "react/jsx-runtime";
import { InformationFillDuotoneIcon, SingleSelectSelectSolidIcon } from '@blocksuite/icons/rc';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useMemo } from 'react';
import { toast, Toaster } from 'sonner';
import { NotificationCard } from './notification-card';
export function NotificationCenter({ width = 380 }) {
    const style = useMemo(()=>{
        return {
            ...assignInlineVars({
                '--width': `${width}px`
            }),
            pointerEvents: 'auto'
        };
    }, [
        width
    ]);
    const toastOptions = useMemo(()=>({
            style: {
                width: '100%'
            }
        }), []);
    return _jsx(Toaster, {
        className: "affine-notification-center",
        style: style,
        toastOptions: toastOptions
    });
}
export function notify(notification, options) {
    return toast.custom((id)=>{
        const onDismiss = ()=>{
            notification.onDismiss?.();
            toast.dismiss(id);
        };
        return _jsx(NotificationCard, {
            notification: {
                ...notification,
                onDismiss
            }
        });
    }, options);
}
notify.error = (notification, options)=>{
    return notify({
        icon: _jsx(InformationFillDuotoneIcon, {}),
        style: 'normal',
        theme: 'error',
        ...notification
    }, options);
};
notify.success = (notification, options)=>{
    return notify({
        icon: _jsx(SingleSelectSelectSolidIcon, {}),
        style: 'normal',
        theme: 'success',
        ...notification
    }, options);
};
notify.warning = (notification, options)=>{
    return notify({
        icon: _jsx(InformationFillDuotoneIcon, {}),
        style: 'normal',
        theme: 'warning',
        ...notification
    }, options);
};
notify.custom = (Component, options)=>{
    return toast.custom((id)=>{
        return _jsx(Component, {
            onDismiss: ()=>toast.dismiss(id)
        });
    }, options);
};
notify.dismiss = toast.dismiss;
