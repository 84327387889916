import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scrollable } from '@affine/component';
import clsx from 'clsx';
import { selectAtom } from 'jotai/utils';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ListInnerWrapper } from './list';
import * as styles from './list.css';
import { ItemGroupHeader } from './page-group';
import { groupCollapseStateAtom, groupsAtom, listPropsAtom, ListProvider, useAtomValue } from './scoped-atoms';
export const VirtualizedList = forwardRef(function VirtualizedList(props, ref) {
    return (_jsx(ListProvider, {
        initialValues: [
            [
                listPropsAtom,
                props
            ]
        ],
        children: _jsx(ListInnerWrapper, {
            ...props,
            handleRef: ref,
            children: _jsx(ListInner, {
                ...props
            })
        })
    }));
});
const headingAtom = selectAtom(listPropsAtom, (props)=>props?.heading);
const PageListHeading = ()=>{
    const heading = useAtomValue(headingAtom);
    return _jsx("div", {
        className: styles.heading,
        children: heading
    });
};
const useVirtuosoItems = ()=>{
    const groups = useAtomValue(groupsAtom);
    const groupCollapsedState = useAtomValue(groupCollapseStateAtom);
    return useMemo(()=>{
        const items = [];
        items.push({
            type: 'sticky-header'
        });
        for (const group of groups){
            if (group.label) {
                items.push({
                    type: 'group-header',
                    data: group
                });
            }
            if (!groupCollapsedState[group.id]) {
                for (const item of group.items){
                    items.push({
                        type: 'item',
                        data: item
                    });
                    if (item !== group.items[group.items.length - 1]) {
                        items.push({
                            type: 'item-spacer',
                            data: {
                                height: 4
                            }
                        });
                    }
                }
            }
            items.push({
                type: 'item-spacer',
                data: {
                    height: 16
                }
            });
        }
        return items;
    }, [
        groupCollapsedState,
        groups
    ]);
};
const Scroller = forwardRef(({ children, ...props }, ref)=>{
    return _jsxs(Scrollable.Root, {
        children: [
            _jsx(Scrollable.Viewport, {
                ...props,
                ref: ref,
                children: children
            }),
            _jsx(Scrollable.Scrollbar, {})
        ]
    });
});
Scroller.displayName = 'Scroller';
const ListInner = ({ atTopStateChange, atTopThreshold, ...props })=>{
    const virtuosoItems = useVirtuosoItems();
    const [atTop, setAtTop] = useState(false);
    const handleAtTopStateChange = useCallback((atTop)=>{
        setAtTop(atTop);
        atTopStateChange?.(atTop);
    }, [
        atTopStateChange
    ]);
    const components = useMemo(()=>{
        return {
            Header: props.heading ? PageListHeading : undefined,
            Scroller: Scroller
        };
    }, [
        props.heading
    ]);
    const itemContentRenderer = useCallback((_index, data)=>{
        switch(data.type){
            case 'sticky-header':
                return props.headerRenderer?.(data.data);
            case 'group-header':
                return _jsx(ItemGroupHeader, {
                    ...data.data
                });
            case 'item':
                return props.itemRenderer?.(data.data);
            case 'item-spacer':
                return _jsx("div", {
                    style: {
                        height: data.data.height
                    }
                });
        }
    }, [
        props
    ]);
    return _jsx(Virtuoso, {
        "data-has-scroll-top": !atTop,
        atTopThreshold: atTopThreshold ?? 0,
        atTopStateChange: handleAtTopStateChange,
        components: components,
        data: virtuosoItems,
        "data-testid": "virtualized-page-list",
        "data-total-count": props.items.length,
        topItemCount: 1,
        totalCount: virtuosoItems.length,
        itemContent: itemContentRenderer,
        className: clsx(props.className, styles.root)
    });
};
