import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SettingHeader, SettingRow, SettingWrapper } from '@affine/component/setting-components';
import { useWorkspace } from '@affine/core/components/hooks/use-workspace';
import { useWorkspaceInfo } from '@affine/core/components/hooks/use-workspace-info';
import { UNTITLED_WORKSPACE_NAME } from '@affine/env/constant';
import { useI18n } from '@affine/i18n';
import { ArrowRightSmallIcon } from '@blocksuite/icons/rc';
import { FrameworkScope } from '@toeverything/infra';
import { useCallback } from 'react';
import { DeleteLeaveWorkspace } from './delete-leave-workspace';
import { EnableCloudPanel } from './enable-cloud';
import { ExportPanel } from './export';
import { LabelsPanel } from './labels';
import { ProfilePanel } from './profile';
import { SharingPanel } from './sharing';
export const WorkspaceSettingDetail = ({ workspaceMetadata })=>{
    const t = useI18n();
    const workspace = useWorkspace(workspaceMetadata);
    const workspaceInfo = useWorkspaceInfo(workspaceMetadata);
    const handleResetSyncStatus = useCallback(()=>{
        workspace?.engine.doc.resetSyncStatus().then(()=>{
            window.location.reload();
        }).catch((err)=>{
            console.error(err);
        });
    }, [
        workspace
    ]);
    if (!workspace) {
        return null;
    }
    return _jsxs(FrameworkScope, {
        scope: workspace.scope,
        children: [
            _jsx(SettingHeader, {
                title: t[`Workspace Settings with name`]({
                    name: workspaceInfo?.name ?? UNTITLED_WORKSPACE_NAME
                }),
                subtitle: t['com.affine.settings.workspace.description']()
            }),
            _jsx(SettingWrapper, {
                title: t['Info'](),
                children: _jsxs(SettingRow, {
                    name: t['Workspace Profile'](),
                    desc: t['com.affine.settings.workspace.not-owner'](),
                    spreadCol: false,
                    children: [
                        _jsx(ProfilePanel, {}),
                        _jsx(LabelsPanel, {})
                    ]
                })
            }),
            _jsx(SettingWrapper, {
                title: t['com.affine.brand.affineCloud'](),
                children: _jsx(EnableCloudPanel, {})
            }),
            _jsx(SharingPanel, {}),
            BUILD_CONFIG.isElectron && _jsx(SettingWrapper, {
                title: t['Storage and Export'](),
                children: _jsx(ExportPanel, {
                    workspace: workspace,
                    workspaceMetadata: workspaceMetadata
                })
            }),
            _jsxs(SettingWrapper, {
                children: [
                    _jsx(DeleteLeaveWorkspace, {}),
                    _jsx(SettingRow, {
                        name: _jsx("span", {
                            style: {
                                color: 'var(--affine-text-secondary-color)'
                            },
                            children: t['com.affine.resetSyncStatus.button']()
                        }),
                        desc: t['com.affine.resetSyncStatus.description'](),
                        style: {
                            cursor: 'pointer'
                        },
                        onClick: handleResetSyncStatus,
                        "data-testid": "reset-sync-status",
                        children: _jsx(ArrowRightSmallIcon, {})
                    })
                ]
            })
        ]
    });
};
