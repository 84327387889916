import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, Menu, MenuItem, toast, useConfirmModal } from '@affine/component';
import { useBlockSuiteMetaHelper } from '@affine/core/components/hooks/affine/use-block-suite-meta-helper';
import { useTrashModalHelper } from '@affine/core/components/hooks/affine/use-trash-modal-helper';
import { useCatchEventCallback } from '@affine/core/components/hooks/use-catch-event-hook';
import { DocInfoService } from '@affine/core/modules/doc-info';
import { FavoriteService } from '@affine/core/modules/favorite';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { DeleteIcon, DeletePermanentlyIcon, DuplicateIcon, EditIcon, FilterIcon, FilterMinusIcon, InformationIcon, MoreVerticalIcon, OpenInNewIcon, PlusIcon, ResetIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { FeatureFlagService, useLiveData, useService, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback, useState } from 'react';
import { usePageHelper } from '../blocksuite/block-suite-page-list/utils';
import { IsFavoriteIcon } from '../pure/icons';
import { FavoriteTag } from './components/favorite-tag';
import * as styles from './list.css';
import { DisablePublicSharing, MoveToTrash } from './operation-menu-items';
import { CreateOrEditTag } from './tags/create-tag';
import { ColWrapper } from './utils';
import { useEditCollection, useEditCollectionName } from './view';
const tooltipSideTop = {
    side: 'top'
};
const tooltipSideTopAlignEnd = {
    side: 'top',
    align: 'end'
};
export const PageOperationCell = ({ isInAllowList, page, onRemoveFromAllowList })=>{
    const t = useI18n();
    const { featureFlagService, workspaceService, compatibleFavoriteItemsAdapter: favAdapter, workbenchService } = useServices({
        FeatureFlagService,
        WorkspaceService,
        CompatibleFavoriteItemsAdapter,
        WorkbenchService
    });
    const enableSplitView = useLiveData(featureFlagService.flags.enable_multi_view.$);
    const currentWorkspace = workspaceService.workspace;
    const { setTrashModal } = useTrashModalHelper();
    const favourite = useLiveData(favAdapter.isFavorite$(page.id, 'doc'));
    const workbench = workbenchService.workbench;
    const { duplicate } = useBlockSuiteMetaHelper();
    const blocksuiteDoc = currentWorkspace.docCollection.getDoc(page.id);
    const docInfoModal = useService(DocInfoService).modal;
    const onOpenInfoModal = useCallback(()=>{
        track.$.docInfoPanel.$.open();
        docInfoModal.open(blocksuiteDoc?.id);
    }, [
        blocksuiteDoc?.id,
        docInfoModal
    ]);
    const onDisablePublicSharing = useCallback(()=>{
        toast('Successfully disabled', {
            portal: document.body
        });
    }, []);
    const onRemoveToTrash = useCallback(()=>{
        track.allDocs.list.docMenu.deleteDoc();
        setTrashModal({
            open: true,
            pageIds: [
                page.id
            ],
            pageTitles: [
                page.title
            ]
        });
    }, [
        page.id,
        page.title,
        setTrashModal
    ]);
    const onOpenInSplitView = useCallback(()=>{
        track.allDocs.list.docMenu.openInSplitView();
        workbench.openDoc(page.id, {
            at: 'tail'
        });
    }, [
        page.id,
        workbench
    ]);
    const onOpenInNewTab = useCallback(()=>{
        workbench.openDoc(page.id, {
            at: 'new-tab'
        });
    }, [
        page.id,
        workbench
    ]);
    const onToggleFavoritePage = useCallback(()=>{
        const status = favAdapter.isFavorite(page.id, 'doc');
        favAdapter.toggle(page.id, 'doc');
        toast(status ? t['com.affine.toastMessage.removedFavorites']() : t['com.affine.toastMessage.addedFavorites']());
    }, [
        page.id,
        favAdapter,
        t
    ]);
    const onToggleFavoritePageOption = useCallback(()=>{
        track.allDocs.list.docMenu.toggleFavorite();
        onToggleFavoritePage();
    }, [
        onToggleFavoritePage
    ]);
    const onDuplicate = useCallback(()=>{
        duplicate(page.id, false);
        track.allDocs.list.docMenu.createDoc({
            control: 'duplicate'
        });
    }, [
        duplicate,
        page.id
    ]);
    const handleRemoveFromAllowList = useCallback(()=>{
        if (onRemoveFromAllowList) {
            onRemoveFromAllowList();
            track.collection.docList.docMenu.removeOrganizeItem({
                type: 'doc'
            });
        }
    }, [
        onRemoveFromAllowList
    ]);
    const OperationMenu = _jsxs(_Fragment, {
        children: [
            page.isPublic && _jsx(DisablePublicSharing, {
                "data-testid": "disable-public-sharing",
                onSelect: onDisablePublicSharing
            }),
            isInAllowList && _jsx(MenuItem, {
                onClick: handleRemoveFromAllowList,
                prefixIcon: _jsx(FilterMinusIcon, {}),
                children: t['Remove special filter']()
            }),
            _jsx(MenuItem, {
                onClick: onToggleFavoritePageOption,
                prefixIcon: _jsx(IsFavoriteIcon, {
                    favorite: favourite
                }),
                children: favourite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
            }),
            _jsx(MenuItem, {
                onClick: onOpenInfoModal,
                prefixIcon: _jsx(InformationIcon, {}),
                children: t['com.affine.page-properties.page-info.view']()
            }),
            _jsx(MenuItem, {
                onClick: onOpenInNewTab,
                prefixIcon: _jsx(OpenInNewIcon, {}),
                children: t['com.affine.workbench.tab.page-menu-open']()
            }),
            BUILD_CONFIG.isElectron && enableSplitView ? _jsx(MenuItem, {
                onClick: onOpenInSplitView,
                prefixIcon: _jsx(SplitViewIcon, {}),
                children: t['com.affine.workbench.split-view.page-menu-open']()
            }) : null,
            _jsx(MenuItem, {
                prefixIcon: _jsx(DuplicateIcon, {}),
                onSelect: onDuplicate,
                children: t['com.affine.header.option.duplicate']()
            }),
            _jsx(MoveToTrash, {
                "data-testid": "move-to-trash",
                onSelect: onRemoveToTrash
            })
        ]
    });
    return _jsxs(_Fragment, {
        children: [
            _jsx(ColWrapper, {
                hideInSmallContainer: true,
                "data-testid": "page-list-item-favorite",
                "data-favorite": favourite ? true : undefined,
                className: styles.favoriteCell,
                children: _jsx(FavoriteTag, {
                    onClick: onToggleFavoritePage,
                    active: favourite
                })
            }),
            _jsx(ColWrapper, {
                alignment: "start",
                children: _jsx(Menu, {
                    items: OperationMenu,
                    contentOptions: {
                        align: 'end'
                    },
                    children: _jsx(IconButton, {
                        "data-testid": "page-list-operation-button",
                        size: "20",
                        children: _jsx(MoreVerticalIcon, {})
                    })
                })
            })
        ]
    });
};
export const TrashOperationCell = ({ onPermanentlyDeletePage, onRestorePage })=>{
    const t = useI18n();
    const { openConfirmModal } = useConfirmModal();
    const onConfirmPermanentlyDelete = useCatchEventCallback((e)=>{
        e.preventDefault();
        openConfirmModal({
            title: `${t['com.affine.trashOperation.deletePermanently']()}?`,
            description: t['com.affine.trashOperation.deleteDescription'](),
            cancelText: t['Cancel'](),
            confirmText: t['com.affine.trashOperation.delete'](),
            confirmButtonOptions: {
                variant: 'error'
            },
            onConfirm: onPermanentlyDeletePage
        });
    }, [
        onPermanentlyDeletePage,
        openConfirmModal,
        t
    ]);
    const handleRestorePage = useCatchEventCallback((e)=>{
        e.preventDefault();
        onRestorePage();
    }, [
        onRestorePage
    ]);
    return _jsxs(ColWrapper, {
        flex: 1,
        children: [
            _jsx(IconButton, {
                tooltip: t['com.affine.trashOperation.restoreIt'](),
                tooltipOptions: tooltipSideTop,
                "data-testid": "restore-page-button",
                style: {
                    marginRight: '12px'
                },
                onClick: handleRestorePage,
                size: "20",
                children: _jsx(ResetIcon, {})
            }),
            _jsx(IconButton, {
                tooltip: t['com.affine.trashOperation.deletePermanently'](),
                tooltipOptions: tooltipSideTopAlignEnd,
                "data-testid": "delete-page-button",
                onClick: onConfirmPermanentlyDelete,
                className: styles.deleteButton,
                iconClassName: styles.deleteIcon,
                size: "20",
                children: _jsx(DeletePermanentlyIcon, {})
            })
        ]
    });
};
export const CollectionOperationCell = ({ collection, service, info })=>{
    const t = useI18n();
    const { compatibleFavoriteItemsAdapter: favAdapter, workspaceService } = useServices({
        CompatibleFavoriteItemsAdapter,
        WorkspaceService
    });
    const docCollection = workspaceService.workspace.docCollection;
    const { createPage } = usePageHelper(docCollection);
    const { openConfirmModal } = useConfirmModal();
    const favourite = useLiveData(favAdapter.isFavorite$(collection.id, 'collection'));
    const { open: openEditCollectionModal } = useEditCollection();
    const { open: openEditCollectionNameModal } = useEditCollectionName({
        title: t['com.affine.editCollection.renameCollection']()
    });
    const handlePropagation = useCallback((event)=>{
        event.preventDefault();
        event.stopPropagation();
    }, []);
    const handleEditName = useCallback((event)=>{
        handlePropagation(event);
        openEditCollectionNameModal(collection.name).then((name)=>{
            return service.updateCollection(collection.id, (collection)=>({
                    ...collection,
                    name
                }));
        }).catch((err)=>{
            console.error(err);
        });
    }, [
        collection.id,
        collection.name,
        handlePropagation,
        openEditCollectionNameModal,
        service
    ]);
    const handleEdit = useCallback((event)=>{
        handlePropagation(event);
        openEditCollectionModal(collection).then((collection)=>{
            return service.updateCollection(collection.id, ()=>collection);
        }).catch((err)=>{
            console.error(err);
        });
    }, [
        handlePropagation,
        openEditCollectionModal,
        collection,
        service
    ]);
    const handleDelete = useCallback(()=>{
        return service.deleteCollection(info, collection.id);
    }, [
        service,
        info,
        collection
    ]);
    const onToggleFavoriteCollection = useCallback(()=>{
        const status = favAdapter.isFavorite(collection.id, 'collection');
        favAdapter.toggle(collection.id, 'collection');
        toast(status ? t['com.affine.toastMessage.removedFavorites']() : t['com.affine.toastMessage.addedFavorites']());
    }, [
        favAdapter,
        collection.id,
        t
    ]);
    const createAndAddDocument = useCallback(()=>{
        const newDoc = createPage();
        service.addPageToCollection(collection.id, newDoc.id);
    }, [
        collection.id,
        createPage,
        service
    ]);
    const onConfirmAddDocToCollection = useCallback(()=>{
        openConfirmModal({
            title: t['com.affine.collection.add-doc.confirm.title'](),
            description: t['com.affine.collection.add-doc.confirm.description'](),
            cancelText: t['Cancel'](),
            confirmText: t['Confirm'](),
            confirmButtonOptions: {
                variant: 'primary'
            },
            onConfirm: createAndAddDocument
        });
    }, [
        createAndAddDocument,
        openConfirmModal,
        t
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ColWrapper, {
                hideInSmallContainer: true,
                "data-testid": "page-list-item-favorite",
                "data-favorite": favourite ? true : undefined,
                className: styles.favoriteCell,
                children: _jsx(FavoriteTag, {
                    onClick: onToggleFavoriteCollection,
                    active: favourite
                })
            }),
            _jsx(IconButton, {
                onClick: handleEditName,
                tooltip: t['com.affine.collection.menu.rename'](),
                tooltipOptions: tooltipSideTop,
                children: _jsx(EditIcon, {})
            }),
            _jsx(IconButton, {
                onClick: handleEdit,
                tooltip: t['com.affine.collection.menu.edit'](),
                tooltipOptions: tooltipSideTop,
                children: _jsx(FilterIcon, {})
            }),
            _jsx(ColWrapper, {
                alignment: "start",
                children: _jsx(Menu, {
                    items: _jsxs(_Fragment, {
                        children: [
                            _jsx(MenuItem, {
                                onClick: onToggleFavoriteCollection,
                                prefixIcon: _jsx(IsFavoriteIcon, {
                                    favorite: favourite
                                }),
                                children: favourite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
                            }),
                            _jsx(MenuItem, {
                                onClick: onConfirmAddDocToCollection,
                                prefixIcon: _jsx(PlusIcon, {}),
                                children: t['New Page']()
                            }),
                            _jsx(MenuItem, {
                                onClick: handleDelete,
                                prefixIcon: _jsx(DeleteIcon, {}),
                                type: "danger",
                                "data-testid": "delete-collection",
                                children: t['Delete']()
                            })
                        ]
                    }),
                    contentOptions: {
                        align: 'end'
                    },
                    children: _jsx(IconButton, {
                        "data-testid": "collection-item-operation-button",
                        children: _jsx(MoreVerticalIcon, {})
                    })
                })
            })
        ]
    });
};
export const TagOperationCell = ({ tag, onTagDelete })=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const { favoriteService } = useServices({
        FavoriteService
    });
    const favourite = useLiveData(favoriteService.favoriteList.isFavorite$('tag', tag.id));
    const handleDelete = useCallback(()=>{
        onTagDelete([
            tag.id
        ]);
    }, [
        onTagDelete,
        tag.id
    ]);
    const onToggleFavoriteCollection = useCallback(()=>{
        favoriteService.favoriteList.toggle('tag', tag.id);
    }, [
        favoriteService,
        tag.id
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ColWrapper, {
                hideInSmallContainer: true,
                "data-testid": "page-list-item-favorite",
                "data-favorite": favourite ? true : undefined,
                className: styles.favoriteCell,
                children: _jsx(FavoriteTag, {
                    onClick: onToggleFavoriteCollection,
                    active: favourite
                })
            }),
            _jsx("div", {
                className: styles.editTagWrapper,
                "data-show": open,
                children: _jsx("div", {
                    style: {
                        width: '100%'
                    },
                    children: _jsx(CreateOrEditTag, {
                        open: open,
                        onOpenChange: setOpen,
                        tagMeta: tag
                    })
                })
            }),
            _jsx(IconButton, {
                tooltip: t['Rename'](),
                tooltipOptions: tooltipSideTop,
                onClick: useCallback((e)=>{
                    e.preventDefault();
                    setOpen(true);
                }, [
                    setOpen
                ]),
                children: _jsx(EditIcon, {})
            }),
            _jsx(ColWrapper, {
                alignment: "start",
                children: _jsx(Menu, {
                    items: _jsx(MenuItem, {
                        prefixIcon: _jsx(DeleteIcon, {}),
                        type: "danger",
                        onSelect: handleDelete,
                        "data-testid": "delete-tag",
                        children: t['Delete']()
                    }),
                    contentOptions: {
                        align: 'end'
                    },
                    children: _jsx(IconButton, {
                        "data-testid": "tag-item-operation-button",
                        children: _jsx(MoreVerticalIcon, {})
                    })
                })
            })
        ]
    });
};
