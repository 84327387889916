export const HISTORY_IMAGE_ACTIONS = [
    'image',
    'AI image filter clay style',
    'AI image filter sketch style',
    'AI image filter anime style',
    'AI image filter pixel style',
    'Clearer',
    'Remove background',
    'Convert to sticker'
];
