import { popupWindow } from '@affine/core/utils';
import { apis } from '@affine/electron-api';
import { createIdentifier } from '@toeverything/infra';
import { parsePath } from 'history';
export const WorkbenchNewTabHandler = createIdentifier('WorkbenchNewTabHandler');
export const BrowserWorkbenchNewTabHandler = ({ basename, to })=>{
    const link = basename + (typeof to === 'string' ? to : `${to.pathname}${to.search}${to.hash}`);
    popupWindow(link);
};
export const DesktopWorkbenchNewTabHandler = ({ basename, to })=>{
    const path = typeof to === 'string' ? parsePath(to) : to;
    apis?.ui.addTab({
        basename,
        view: {
            path
        },
        show: false
    }).catch(console.error);
};
