function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _init_embedBlock, _initProto;
import { CaptionedBlockComponent } from '@blocksuite/affine-components/caption';
import { EMBED_CARD_HEIGHT, EMBED_CARD_MIN_WIDTH, EMBED_CARD_WIDTH } from '@blocksuite/affine-shared/consts';
import { DocModeProvider, DragHandleConfigExtension } from '@blocksuite/affine-shared/services';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { captureEventTarget, convertDragPreviewDocToEdgeless, convertDragPreviewEdgelessToDoc } from '@blocksuite/affine-shared/utils';
import { isGfxBlockComponent } from '@blocksuite/block-std';
import { html } from 'lit';
import { query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { styles } from './styles.js';
export const EmbedDragHandleOption = DragHandleConfigExtension({
    flavour: /affine:embed-*/,
    edgeless: true,
    onDragEnd: (props)=>{
        const { state, draggingElements } = props;
        if (draggingElements.length !== 1 || draggingElements[0].model.flavour.match(/affine:embed-*/) === null) return false;
        const blockComponent = draggingElements[0];
        const isInSurface = isGfxBlockComponent(blockComponent);
        const target = captureEventTarget(state.raw.target);
        const isTargetEdgelessContainer = target?.classList.contains('edgeless-container');
        if (isInSurface) {
            const style = blockComponent._cardStyle;
            const targetStyle = style === 'vertical' || style === 'cube' ? 'horizontal' : style;
            return convertDragPreviewEdgelessToDoc({
                blockComponent,
                style: targetStyle,
                ...props
            });
        } else if (isTargetEdgelessContainer) {
            const style = blockComponent._cardStyle;
            return convertDragPreviewDocToEdgeless({
                blockComponent,
                cssSelector: '.embed-block-container',
                width: EMBED_CARD_WIDTH[style],
                height: EMBED_CARD_HEIGHT[style],
                ...props
            });
        }
        return false;
    }
});
_dec = query('.embed-block-container');
export class EmbedBlockComponent extends CaptionedBlockComponent {
    static{
        ({ e: [_init_embedBlock, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "embedBlock"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _cardHeight() {
        return EMBED_CARD_HEIGHT[this._cardStyle];
    }
    get _cardWidth() {
        return EMBED_CARD_WIDTH[this._cardStyle];
    }
    get fetchAbortController() {
        return this._fetchAbortController;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this._fetchAbortController.signal.aborted) this._fetchAbortController = new AbortController();
        this.contentEditable = 'false';
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._fetchAbortController.abort();
    }
    #___private_blockContainerStyles_1;
    get blockContainerStyles() {
        return this.#___private_blockContainerStyles_1;
    }
    set blockContainerStyles(_v) {
        this.#___private_blockContainerStyles_1 = _v;
    }
    #___private_embedBlock_2;
    get embedBlock() {
        return this.#___private_embedBlock_2;
    }
    set embedBlock(_v) {
        this.#___private_embedBlock_2 = _v;
    }
    #___private_showBlockSelection_3;
    get showBlockSelection() {
        return this.#___private_showBlockSelection_3;
    }
    set showBlockSelection(_v) {
        this.#___private_showBlockSelection_3 = _v;
    }
    #___private_useCaptionEditor_4;
    get useCaptionEditor() {
        return this.#___private_useCaptionEditor_4;
    }
    set useCaptionEditor(_v) {
        this.#___private_useCaptionEditor_4 = _v;
    }
    #___private_useZeroWidth_5;
    get useZeroWidth() {
        return this.#___private_useZeroWidth_5;
    }
    set useZeroWidth(_v) {
        this.#___private_useZeroWidth_5 = _v;
    }
    constructor(...args){
        super(...args), this._fetchAbortController = new AbortController(), this._cardStyle = 'horizontal', this._scale = 1, this.embedContainerStyle = {}, this.renderEmbed = (content)=>{
            const theme = ThemeObserver.mode;
            const isSelected = !!this.selected?.is('block');
            if (this._cardStyle === 'horizontal' || this._cardStyle === 'horizontalThin' || this._cardStyle === 'list') {
                this.style.display = 'block';
                const mode = this.std.get(DocModeProvider).getEditorMode();
                if (mode === 'edgeless') {
                    this.style.minWidth = `${EMBED_CARD_MIN_WIDTH}px`;
                }
            }
            return html`
      <div
        class=${classMap({
                'embed-block-container': true,
                [theme]: true,
                selected: isSelected
            })}
        style=${styleMap({
                height: `${this._cardHeight}px`,
                width: '100%',
                ...this.embedContainerStyle
            })}
      >
        ${content()}
      </div>
    `;
        }, this.#___private_blockContainerStyles_1 = {
            margin: '18px 0'
        }, this.#___private_embedBlock_2 = (_initProto(this), _init_embedBlock(this)), this.#___private_showBlockSelection_3 = false, this.#___private_useCaptionEditor_4 = true, this.#___private_useZeroWidth_5 = true;
    }
}
