import { jsx as _jsx } from "react/jsx-runtime";
import { Empty } from '@affine/component';
export const ThemeEmpty = ()=>{
    return _jsx("div", {
        style: {
            width: 0,
            flex: 1,
            display: 'flex',
            justifyContent: 'center'
        },
        children: _jsx(Empty, {
            description: "Select a variable to edit"
        })
    });
};
