function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _init_parentSessionId, _init_latestMessageId, _init_host, _init_updateChatBlock, _init_createChatBlock, _init_cleanupHistories, _init_updateContext, _init_chatContext, _init_textarea, _init__isInputEmpty, _init__focused, _init_closeWrapper, _init__curIndex, _initProto;
import { openFileOrFiles } from '@blocksuite/affine/blocks';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { ChatAbortIcon, ChatClearIcon, ChatSendIcon, CloseIcon, ImageIcon } from '../_common/icons';
import { AIProvider } from '../provider';
import { reportResponse } from '../utils/action-reporter';
import { readBlobAsURL } from '../utils/image';
const MaximumImageCount = 8;
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = query('textarea'), _dec9 = state(), _dec10 = state(), _dec11 = query('.close-wrapper'), _dec12 = state();
export class ChatBlockInput extends LitElement {
    static{
        ({ e: [_init_parentSessionId, _init_latestMessageId, _init_host, _init_updateChatBlock, _init_createChatBlock, _init_cleanupHistories, _init_updateContext, _init_chatContext, _init_textarea, _init__isInputEmpty, _init__focused, _init_closeWrapper, _init__curIndex, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "parentSessionId"
            ],
            [
                _dec1,
                1,
                "latestMessageId"
            ],
            [
                _dec2,
                1,
                "host"
            ],
            [
                _dec3,
                1,
                "updateChatBlock"
            ],
            [
                _dec4,
                1,
                "createChatBlock"
            ],
            [
                _dec5,
                1,
                "cleanupHistories"
            ],
            [
                _dec6,
                1,
                "updateContext"
            ],
            [
                _dec7,
                1,
                "chatContext"
            ],
            [
                _dec8,
                1,
                "textarea"
            ],
            [
                _dec9,
                1,
                "_isInputEmpty"
            ],
            [
                _dec10,
                1,
                "_focused"
            ],
            [
                _dec11,
                1,
                "closeWrapper"
            ],
            [
                _dec12,
                1,
                "_curIndex"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      width: 100%;
    }
    .ai-chat-input {
      display: flex;
      width: 100%;
      min-height: 100px;
      max-height: 206px;
      padding: 8px 12px;
      box-sizing: border-box;
      border: 1px solid var(--affine-border-color);
      border-radius: 4px;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      position: relative;
      background-color: var(--affine-white-10);
    }
    .ai-chat-input {
      textarea {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        line-height: 22px;
        font-size: var(--affine-font-sm);
        font-weight: 400;
        font-family: var(--affine-font-family);
        color: var(--affine-text-primary-color);
        box-sizing: border-box;
        resize: none;
        overflow-y: hidden;
        background-color: transparent;
        user-select: none;
      }
      textarea::placeholder {
        font-size: 14px;
        font-weight: 400;
        font-family: var(--affine-font-family);
        color: var(--affine-placeholder-color);
      }
      textarea:focus {
        outline: none;
      }
    }
    .chat-input-images {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      position: relative;
      .image-container {
        width: 58px;
        height: 58px;
        border-radius: 4px;
        border: 1px solid var(--affine-border-color);
        cursor: pointer;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }

    .chat-panel-send svg rect {
      fill: var(--affine-primary-color);
    }
    .chat-panel-send[aria-disabled='true'] {
      cursor: not-allowed;
    }
    .chat-panel-send[aria-disabled='true'] svg rect {
      fill: var(--affine-text-disable-color);
    }

    .close-wrapper {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid var(--affine-border-color);
      justify-content: center;
      align-items: center;
      display: none;
      position: absolute;
      background-color: var(--affine-white);
      z-index: 1;
      cursor: pointer;
    }
    .close-wrapper:hover {
      background-color: var(--affine-background-error-color);
      border: 1px solid var(--affine-error-color);
    }
    .close-wrapper:hover svg path {
      fill: var(--affine-error-color);
    }
    .chat-panel-input-actions {
      display: flex;
      gap: 8px;
      align-items: center;
      div {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      div:nth-child(2) {
        margin-left: auto;
      }
    }

    .chat-history-clear.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `;
    }
    render() {
        const { images, status, messages } = this.chatContext;
        const hasImages = images.length > 0;
        const maxHeight = hasImages ? 272 + 2 : 200 + 2;
        const disableCleanUp = status === 'loading' || status === 'transmitting' || !messages.length;
        const cleanButtonClasses = classMap({
            'chat-history-clear': true,
            disabled: disableCleanUp
        });
        return html`<style>
        .chat-panel-input {
          border-color: ${this._focused ? 'var(--affine-primary-color)' : 'var(--affine-border-color)'};
          box-shadow: ${this._focused ? 'var(--affine-active-shadow)' : 'none'};
          max-height: ${maxHeight}px;
          user-select: none;
        }
      </style>
      <div class="ai-chat-input">
        ${hasImages ? this._renderImages(images) : nothing}
        <textarea
          rows="1"
          placeholder="What are your thoughts?"
          @keydown=${async (evt)=>{
            if (evt.key === 'Enter' && !evt.shiftKey && !evt.isComposing) {
                evt.preventDefault();
                await this._send();
            }
        }}
          @input=${()=>{
            const { textarea } = this;
            this._isInputEmpty = !textarea.value.trim();
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
            if (this.scrollHeight >= 202) {
                textarea.style.height = '168px';
                textarea.style.overflowY = 'scroll';
            }
        }}
          @focus=${()=>{
            this._focused = true;
        }}
          @blur=${()=>{
            this._focused = false;
        }}
          @paste=${(event)=>{
            const items = event.clipboardData?.items;
            if (!items) return;
            for(const index in items){
                const item = items[index];
                if (item.kind === 'file' && item.type.indexOf('image') >= 0) {
                    const blob = item.getAsFile();
                    if (!blob) continue;
                    this._addImages([
                        blob
                    ]);
                }
            }
        }}
        ></textarea>
        <div class="chat-panel-input-actions">
          <div
            class=${cleanButtonClasses}
            @click=${async ()=>{
            if (disableCleanUp) {
                return;
            }
            await this.cleanupHistories();
        }}
          >
            ${ChatClearIcon}
          </div>
          ${images.length < MaximumImageCount ? html`<div
                class="image-upload"
                @click=${async ()=>{
            const images = await openFileOrFiles({
                acceptType: 'Images',
                multiple: true
            });
            if (!images) return;
            this._addImages(images);
        }}
              >
                ${ImageIcon}
              </div>` : nothing}
          ${status === 'transmitting' ? html`<div
                @click=${()=>{
            this.chatContext.abortController?.abort();
            this.updateContext({
                status: 'success'
            });
            reportResponse('aborted:stop');
        }}
              >
                ${ChatAbortIcon}
              </div>` : html`<div
                @click="${this._send}"
                class="chat-panel-send"
                aria-disabled=${this._isInputEmpty}
              >
                ${ChatSendIcon}
              </div>`}
        </div>
      </div>`;
    }
    #___private_parentSessionId_1;
    get parentSessionId() {
        return this.#___private_parentSessionId_1;
    }
    set parentSessionId(_v) {
        this.#___private_parentSessionId_1 = _v;
    }
    #___private_latestMessageId_2;
    get latestMessageId() {
        return this.#___private_latestMessageId_2;
    }
    set latestMessageId(_v) {
        this.#___private_latestMessageId_2 = _v;
    }
    #___private_host_3;
    get host() {
        return this.#___private_host_3;
    }
    set host(_v) {
        this.#___private_host_3 = _v;
    }
    #___private_updateChatBlock_4;
    get updateChatBlock() {
        return this.#___private_updateChatBlock_4;
    }
    set updateChatBlock(_v) {
        this.#___private_updateChatBlock_4 = _v;
    }
    #___private_createChatBlock_5;
    get createChatBlock() {
        return this.#___private_createChatBlock_5;
    }
    set createChatBlock(_v) {
        this.#___private_createChatBlock_5 = _v;
    }
    #___private_cleanupHistories_6;
    get cleanupHistories() {
        return this.#___private_cleanupHistories_6;
    }
    set cleanupHistories(_v) {
        this.#___private_cleanupHistories_6 = _v;
    }
    #___private_updateContext_7;
    get updateContext() {
        return this.#___private_updateContext_7;
    }
    set updateContext(_v) {
        this.#___private_updateContext_7 = _v;
    }
    #___private_chatContext_8;
    get chatContext() {
        return this.#___private_chatContext_8;
    }
    set chatContext(_v) {
        this.#___private_chatContext_8 = _v;
    }
    #___private_textarea_9;
    get textarea() {
        return this.#___private_textarea_9;
    }
    set textarea(_v) {
        this.#___private_textarea_9 = _v;
    }
    #___private__isInputEmpty_10;
    get _isInputEmpty() {
        return this.#___private__isInputEmpty_10;
    }
    set _isInputEmpty(_v) {
        this.#___private__isInputEmpty_10 = _v;
    }
    #___private__focused_11;
    get _focused() {
        return this.#___private__focused_11;
    }
    set _focused(_v) {
        this.#___private__focused_11 = _v;
    }
    #___private_closeWrapper_12;
    get closeWrapper() {
        return this.#___private_closeWrapper_12;
    }
    set closeWrapper(_v) {
        this.#___private_closeWrapper_12 = _v;
    }
    #___private__curIndex_13;
    get _curIndex() {
        return this.#___private__curIndex_13;
    }
    set _curIndex(_v) {
        this.#___private__curIndex_13 = _v;
    }
    _renderImages(images) {
        return html`
      <div
        class="chat-input-images"
        @mouseleave=${()=>{
            if (!this.closeWrapper) return;
            this.closeWrapper.style.display = 'none';
            this._curIndex = -1;
        }}
      >
        ${repeat(images, (image)=>image.name, (image, index)=>html`<div
              class="image-container"
              @mouseenter=${(evt)=>{
                const ele = evt.target;
                const rect = ele.getBoundingClientRect();
                if (!ele.parentElement) return;
                const parentRect = ele.parentElement.getBoundingClientRect();
                const left = Math.abs(rect.right - parentRect.left) - 8;
                const top = Math.abs(parentRect.top - rect.top) - 8;
                this._curIndex = index;
                if (!this.closeWrapper) return;
                this.closeWrapper.style.display = 'flex';
                this.closeWrapper.style.left = left + 'px';
                this.closeWrapper.style.top = top + 'px';
            }}
            >
              <img src="${URL.createObjectURL(image)}" alt="${image.name}" />
            </div>`)}
        <div
          class="close-wrapper"
          @click=${()=>{
            if (this._curIndex >= 0 && this._curIndex < images.length) {
                const newImages = [
                    ...images
                ];
                newImages.splice(this._curIndex, 1);
                this.updateContext({
                    images: newImages
                });
                this._curIndex = -1;
                if (!this.closeWrapper) return;
                this.closeWrapper.style.display = 'none';
            }
        }}
        >
          ${CloseIcon}
        </div>
      </div>
    `;
    }
    constructor(...args){
        super(...args), this.#___private_parentSessionId_1 = (_initProto(this), _init_parentSessionId(this)), this.#___private_latestMessageId_2 = _init_latestMessageId(this), this.#___private_host_3 = _init_host(this), this.#___private_updateChatBlock_4 = _init_updateChatBlock(this), this.#___private_createChatBlock_5 = _init_createChatBlock(this), this.#___private_cleanupHistories_6 = _init_cleanupHistories(this), this.#___private_updateContext_7 = _init_updateContext(this), this.#___private_chatContext_8 = _init_chatContext(this), this.#___private_textarea_9 = _init_textarea(this), this.#___private__isInputEmpty_10 = _init__isInputEmpty(this, true), this.#___private__focused_11 = _init__focused(this, false), this.#___private_closeWrapper_12 = _init_closeWrapper(this, null), this.#___private__curIndex_13 = _init__curIndex(this, -1), this._addImages = (images)=>{
            const oldImages = this.chatContext.images;
            this.updateContext({
                images: [
                    ...oldImages,
                    ...images
                ].slice(0, MaximumImageCount)
            });
        }, this._send = async ()=>{
            const { images, status } = this.chatContext;
            if (status === 'loading' || status === 'transmitting') return;
            const text = this.textarea.value;
            if (!text && !images.length) {
                return;
            }
            const { doc } = this.host;
            this.textarea.value = '';
            this._isInputEmpty = true;
            this.textarea.style.height = 'unset';
            this.updateContext({
                images: [],
                status: 'loading',
                error: null
            });
            const attachments = await Promise.all(images?.map((image)=>readBlobAsURL(image)));
            const userInfo = await AIProvider.userInfo;
            this.updateContext({
                messages: [
                    ...this.chatContext.messages,
                    {
                        id: '',
                        content: text,
                        role: 'user',
                        createdAt: new Date().toISOString(),
                        attachments,
                        userId: userInfo?.id,
                        userName: userInfo?.name,
                        avatarUrl: userInfo?.avatarUrl ?? undefined
                    },
                    {
                        id: '',
                        content: '',
                        role: 'assistant',
                        createdAt: new Date().toISOString()
                    }
                ]
            });
            const { currentChatBlockId, currentSessionId } = this.chatContext;
            let content = '';
            const chatBlockExists = !!currentChatBlockId;
            try {
                let chatSessionId = currentSessionId;
                if (!chatSessionId) {
                    const forkSessionId = await AIProvider.forkChat?.({
                        workspaceId: doc.collection.id,
                        docId: doc.id,
                        sessionId: this.parentSessionId,
                        latestMessageId: this.latestMessageId
                    });
                    if (!forkSessionId) return;
                    this.updateContext({
                        currentSessionId: forkSessionId
                    });
                    chatSessionId = forkSessionId;
                }
                const abortController = new AbortController();
                const stream = AIProvider.actions.chat?.({
                    input: text,
                    sessionId: chatSessionId,
                    docId: doc.id,
                    attachments: images,
                    workspaceId: doc.collection.id,
                    host: this.host,
                    stream: true,
                    signal: abortController.signal,
                    where: 'ai-chat-block',
                    control: 'chat-send'
                });
                if (stream) {
                    this.updateContext({
                        abortController
                    });
                    for await (const text of stream){
                        const messages = [
                            ...this.chatContext.messages
                        ];
                        const last = messages[messages.length - 1];
                        last.content += text;
                        this.updateContext({
                            messages,
                            status: 'transmitting'
                        });
                        content += text;
                    }
                    this.updateContext({
                        status: 'success'
                    });
                }
            } catch (error) {
                console.error(error);
                this.updateContext({
                    status: 'error',
                    error: error
                });
            } finally{
                if (content) {
                    if (!chatBlockExists) {
                        await this.createChatBlock();
                    }
                    await this.updateChatBlock();
                }
                this.updateContext({
                    abortController: null
                });
            }
        };
    }
}
