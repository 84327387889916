import { BlockFlavourIdentifier } from '../identifier.js';
export function FlavourExtension(flavour) {
    return {
        setup: (di)=>{
            di.addImpl(BlockFlavourIdentifier(flavour), ()=>({
                    flavour
                }));
        }
    };
}
