import { DebugLogger } from '@affine/debug';
import { fixWorkspaceVersion } from '../../../blocksuite';
import { Service } from '../../../framework';
import { ObjectPool } from '../../../utils';
import { WorkspaceInitialized } from '../events';
import { WorkspaceScope } from '../scopes/workspace';
import { WorkspaceService } from './workspace';
const logger = new DebugLogger('affine:workspace-repository');
export class WorkspaceRepositoryService extends Service {
    constructor(providers, profileRepo){
        super(), this.providers = providers, this.profileRepo = profileRepo, this.pool = new ObjectPool({
            onDelete (workspace) {
                workspace.scope.dispose();
            },
            onDangling (workspace) {
                return workspace.canGracefulStop;
            }
        }), this.open = (options, customProvider)=>{
            if (options.isSharedMode) {
                const workspace = this.instantiate(options, customProvider);
                return {
                    workspace,
                    dispose: ()=>{
                        workspace.dispose();
                    }
                };
            }
            const exist = this.pool.get(options.metadata.id);
            if (exist) {
                return {
                    workspace: exist.obj,
                    dispose: exist.release
                };
            }
            const workspace = this.instantiate(options, customProvider);
            const ref = this.pool.put(workspace.meta.id, workspace);
            return {
                workspace: ref.obj,
                dispose: ref.release
            };
        };
    }
    instantiate(openOptions, customProvider) {
        logger.info(`open workspace [${openOptions.metadata.flavour}] ${openOptions.metadata.id} `);
        const provider = customProvider ?? this.providers.find((p)=>p.flavour === openOptions.metadata.flavour)?.getEngineProvider(openOptions.metadata.id);
        if (!provider) {
            throw new Error(`Unknown workspace flavour: ${openOptions.metadata.flavour}`);
        }
        const workspaceScope = this.framework.createScope(WorkspaceScope, {
            openOptions,
            engineProvider: provider
        });
        const workspace = workspaceScope.get(WorkspaceService).workspace;
        workspace.engine.setRootDoc(workspace.docCollection.doc);
        workspace.engine.start();
        fixWorkspaceVersion(workspace.docCollection.doc);
        this.framework.emitEvent(WorkspaceInitialized, workspace);
        this.profileRepo.getProfile(openOptions.metadata).syncWithWorkspace(workspace);
        return workspace;
    }
}
