import { transact } from 'yjs';
export function fixWorkspaceVersion(rootDoc) {
    const meta = rootDoc.getMap('meta');
    function doFix() {
        if (meta.size === 0) {
            return;
        }
        const workspaceVersion = meta.get('workspaceVersion');
        if (typeof workspaceVersion !== 'number' || workspaceVersion < 2) {
            transact(rootDoc, ()=>{
                meta.set('workspaceVersion', 2);
            }, 'fixWorkspaceVersion', false);
        }
        const pageVersion = meta.get('pageVersion');
        if (typeof pageVersion !== 'number' || pageVersion < 2) {
            transact(rootDoc, ()=>{
                meta.set('pageVersion', 2);
            }, 'fixPageVersion', false);
        }
    }
    doFix();
    meta.observe(()=>doFix());
}
