export class CloudStaticDocStorage {
    constructor(workspaceId){
        this.workspaceId = workspaceId;
        this.name = 'cloud-static';
    }
    async pull(docId) {
        const response = await fetch(`/api/workspaces/${this.workspaceId}/docs/${docId}`, {
            priority: 'high'
        });
        if (response.ok) {
            const arrayBuffer = await response.arrayBuffer();
            return {
                data: new Uint8Array(arrayBuffer)
            };
        }
        return null;
    }
}
