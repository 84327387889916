function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init__isSelected, _init_block, _init_resizeImg, _initProto;
import { ShadowlessElement } from '@blocksuite/block-std';
import { WithDisposable } from '@blocksuite/global/utils';
import { css, html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ImageResizeManager } from '../image-resize-manager.js';
import { shouldResizeImage } from '../utils.js';
import { ImageSelectedRect } from './image-selected-rect.js';
_dec = state(), _dec1 = property({
    attribute: false
}), _dec2 = query('.resizable-img');
export class ImageBlockPageComponent extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init__isSelected, _init_block, _init_resizeImg, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_isSelected"
            ],
            [
                _dec1,
                1,
                "block"
            ],
            [
                _dec2,
                1,
                "resizeImg"
            ]
        ], []));
    }
    static{
        this.styles = css`
    affine-page-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      line-height: 0;
      cursor: pointer;
    }

    affine-page-image .resizable-img {
      position: relative;
      max-width: 100%;
    }

    affine-page-image .resizable-img img {
      width: 100%;
      height: 100%;
    }
  `;
    }
    get _doc() {
        return this.block.doc;
    }
    get _host() {
        return this.block.host;
    }
    get _model() {
        return this.block.model;
    }
    _bindKeyMap() {
        const selection = this._host.selection;
        const addParagraph = (ctx)=>{
            const parent = this._doc.getParent(this._model);
            if (!parent) return;
            const index = parent.children.indexOf(this._model);
            const blockId = this._doc.addBlock('affine:paragraph', {}, parent, index + 1);
            const event = ctx.get('defaultState').event;
            event.preventDefault();
            selection.update((selList)=>selList.filter((sel)=>!sel.is('image')).concat(selection.create('text', {
                    from: {
                        blockId,
                        index: 0,
                        length: 0
                    },
                    to: null
                })));
        };
        this.block.bindHotKey({
            Escape: ()=>{
                selection.update((selList)=>{
                    return selList.map((sel)=>{
                        const current = sel.is('image') && sel.blockId === this.block.blockId;
                        if (current) {
                            return selection.create('block', {
                                blockId: this.block.blockId
                            });
                        }
                        return sel;
                    });
                });
                return true;
            },
            Delete: (ctx)=>{
                if (this._host.doc.readonly || !this._isSelected) return;
                addParagraph(ctx);
                this._doc.deleteBlock(this._model);
                return true;
            },
            Backspace: (ctx)=>{
                if (this._host.doc.readonly || !this._isSelected) return;
                addParagraph(ctx);
                this._doc.deleteBlock(this._model);
                return true;
            },
            Enter: (ctx)=>{
                if (this._host.doc.readonly || !this._isSelected) return;
                addParagraph(ctx);
                return true;
            },
            ArrowDown: (ctx)=>{
                const std = this._host.std;
                if (!std.selection.find('image')) {
                    return false;
                }
                const event = ctx.get('keyboardState');
                event.raw.preventDefault();
                std.command.chain().getNextBlock({
                    path: this.block.blockId
                }).inline((ctx, next)=>{
                    const { nextBlock } = ctx;
                    if (!nextBlock) return;
                    return next({
                        focusBlock: nextBlock
                    });
                }).focusBlockStart().run();
                return true;
            },
            ArrowUp: (ctx)=>{
                const std = this._host.std;
                if (!std.selection.find('image')) {
                    return false;
                }
                const event = ctx.get('keyboardState');
                event.raw.preventDefault();
                std.command.chain().getPrevBlock({
                    path: this.block.blockId
                }).inline((ctx, next)=>{
                    const { prevBlock } = ctx;
                    if (!prevBlock) return;
                    return next({
                        focusBlock: prevBlock
                    });
                }).focusBlockEnd().run();
                return true;
            }
        });
    }
    _handleError() {
        this.block.error = true;
    }
    _handleSelection() {
        const selection = this._host.selection;
        this._disposables.add(selection.slots.changed.on((selList)=>{
            this._isSelected = selList.some((sel)=>sel.blockId === this.block.blockId && sel.is('image'));
        }));
        this._disposables.add(this._model.propsUpdated.on(()=>{
            this.requestUpdate();
        }));
        this._disposables.addFromEvent(this.resizeImg, 'click', (event)=>{
            if (event.shiftKey) return;
            event.stopPropagation();
            selection.update((selList)=>{
                return selList.filter((sel)=>![
                        'block',
                        'image',
                        'text'
                    ].includes(sel.type)).concat(selection.create('image', {
                    blockId: this.block.blockId
                }));
            });
            return true;
        });
        this.block.handleEvent('click', ()=>{
            if (!this._isSelected) return;
            selection.update((selList)=>selList.filter((sel)=>!(sel.is('image') && sel.blockId === this.block.blockId)));
        }, {
            global: true
        });
    }
    _normalizeImageSize() {
        if (this._isDragging && this.resizeImg) {
            return {
                width: this.resizeImg.style.width
            };
        }
        const { width, height } = this._model;
        if (!width || !height) {
            return {
                width: 'unset',
                height: 'unset'
            };
        }
        return {
            width: `${width}px`
        };
    }
    _observeDrag() {
        const imageResizeManager = new ImageResizeManager();
        this._disposables.add(this._host.event.add('dragStart', (ctx)=>{
            const pointerState = ctx.get('pointerState');
            const target = pointerState.event.target;
            if (shouldResizeImage(this, target)) {
                this._isDragging = true;
                imageResizeManager.onStart(pointerState);
                return true;
            }
            return false;
        }));
        this._disposables.add(this._host.event.add('dragMove', (ctx)=>{
            const pointerState = ctx.get('pointerState');
            if (this._isDragging) {
                imageResizeManager.onMove(pointerState);
                return true;
            }
            return false;
        }));
        this._disposables.add(this._host.event.add('dragEnd', ()=>{
            if (this._isDragging) {
                this._isDragging = false;
                imageResizeManager.onEnd();
                return true;
            }
            return false;
        }));
    }
    connectedCallback() {
        super.connectedCallback();
        this._bindKeyMap();
        this._observeDrag();
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        this._handleSelection();
        this.addEventListener('click', ()=>{
            if (document.activeElement && document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        });
    }
    render() {
        const imageSize = this._normalizeImageSize();
        const imageSelectedRect = this._isSelected ? ImageSelectedRect(this._doc.readonly) : null;
        return html`
      <div class="resizable-img" style=${styleMap(imageSize)}>
        <img
          class="drag-target"
          src=${this.block.blobUrl ?? ''}
          draggable="false"
          @error=${this._handleError}
        />

        ${imageSelectedRect}
      </div>
    `;
    }
    #___private__isSelected_1;
    get _isSelected() {
        return this.#___private__isSelected_1;
    }
    set _isSelected(_v) {
        this.#___private__isSelected_1 = _v;
    }
    #___private_block_2;
    get block() {
        return this.#___private_block_2;
    }
    set block(_v) {
        this.#___private_block_2 = _v;
    }
    #___private_resizeImg_3;
    get resizeImg() {
        return this.#___private_resizeImg_3;
    }
    set resizeImg(_v) {
        this.#___private_resizeImg_3 = _v;
    }
    constructor(...args){
        super(...args), this._isDragging = false, this.#___private__isSelected_1 = (_initProto(this), _init__isSelected(this, false)), this.#___private_block_2 = _init_block(this), this.#___private_resizeImg_3 = _init_resizeImg(this);
    }
}
