import { DebugLogger } from '@affine/debug';
import { MANUALLY_STOP, throwIfAborted } from '@toeverything/infra';
const logger = new DebugLogger('affine:indexer-worker');
export async function createWorker(abort) {
    let worker = null;
    while(throwIfAborted(abort)){
        try {
            worker = await new Promise((resolve, reject)=>{
                const worker = new Worker(new URL('./in-worker.ts', import.meta.url));
                worker.addEventListener('error', reject);
                worker.addEventListener('message', (event)=>{
                    if (event.data.type === 'init') {
                        resolve(worker);
                    }
                });
                worker.postMessage({
                    type: 'init',
                    msgId: 0
                });
                setTimeout(()=>{
                    reject('timeout');
                }, 1000 * 30);
            });
        } catch (err) {
            logger.debug(`Indexer worker init failed, ${err}, will retry in 5 seconds.`);
            await new Promise((resolve)=>setTimeout(resolve, 5000));
        }
        if (worker) {
            break;
        }
    }
    if (!worker) {
        throw new Error('Worker is not created');
    }
    const terminateAbort = new AbortController();
    let msgId = 1;
    return {
        run: async (input)=>{
            const dispose = [];
            return new Promise((resolve, reject)=>{
                const currentMsgId = msgId++;
                const msgHandler = (event)=>{
                    if (event.data.msgId === currentMsgId) {
                        if (event.data.type === 'done') {
                            resolve(event.data.output);
                        } else if (event.data.type === 'failed') {
                            reject(new Error(event.data.error));
                        } else {
                            reject(new Error('Unknown message type'));
                        }
                    }
                };
                const abortHandler = (reason)=>{
                    reject(reason);
                };
                worker.addEventListener('message', msgHandler);
                dispose.push(()=>{
                    worker?.removeEventListener('message', msgHandler);
                });
                terminateAbort.signal.addEventListener('abort', abortHandler);
                dispose.push(()=>{
                    terminateAbort.signal.removeEventListener('abort', abortHandler);
                });
                worker.postMessage({
                    type: 'run',
                    msgId: currentMsgId,
                    input
                });
            }).finally(()=>{
                for (const d of dispose){
                    d();
                }
            });
        },
        dispose: ()=>{
            worker.terminate();
            terminateAbort.abort(MANUALLY_STOP);
        }
    };
}
