import "../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T0U6DQBBF3/sVmz7Zh2kottpu45cYYxYYYBSWzTItVOO/m11ahUqjTXwiWe6euXdmdv682EQqzikQ7xMhStLQUMK5FGEYmHY7+ZjMT5KFl0CD0SsxKGPAYkaVlkJXkFiVDdShVxuVJKQzsJTlLMX6DHnrRWmlGWp6Qyn2yt4AqDQljeDP23q2dZoCW2isMq6a+w45j4liBSpm2uPDlO0Op0+eHVdFZc+4xlKp7AH8v9kAtPSXEqpNoQ7SV3XVVUGZBmIsayli1IzWHb/saqbUgTSjZilqo2KECLlB1E6ROcM+9TFmg10nVkGw/VP0nrnVd7dOmGWHGUvJ2DLUGFc6uRD2rj8iKZamFYuw85of+WHf+28jGsl3hTEhSmUz0hBVzFXp/Qzs3v+n3R53PT7z3sZ1++YMtl/PIwi6Uv0Jj3jr1dlcXEjfkYRqFRU4NqjNVevtaT92/BPcl0FW6gMAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_19bachi5';
export var headerDisplayButton = '_19bachi1';
export var listOption = '_19bachi6';
export var menu = '_19bachi0';
export var properties = '_19bachi7';
export var propertiesWrapper = '_19bachi8';
export var propertyButton = '_19bachi9';
export var subMenuItem = '_19bachi3';
export var subMenuTrigger = '_19bachi2';
export var subMenuTriggerContent = '_19bachi4';