function _using_ctx() {
    var _disposeSuppressedError = typeof SuppressedError === "function" ? SuppressedError : function(error, suppressed) {
        var err = new Error();
        err.name = "SuppressedError";
        err.suppressed = suppressed;
        err.error = error;
        return err;
    }, empty = {}, stack = [];
    function using(isAwait, value) {
        if (value != null) {
            if (Object(value) !== value) {
                throw new TypeError("using declarations can only be used with objects, functions, null, or undefined.");
            }
            if (isAwait) {
                var dispose = value[Symbol.asyncDispose || Symbol.for("Symbol.asyncDispose")];
            }
            if (dispose == null) {
                dispose = value[Symbol.dispose || Symbol.for("Symbol.dispose")];
            }
            if (typeof dispose !== "function") {
                throw new TypeError(`Property [Symbol.dispose] is not a function.`);
            }
            stack.push({
                v: value,
                d: dispose,
                a: isAwait
            });
        } else if (isAwait) {
            stack.push({
                d: value,
                a: isAwait
            });
        }
        return value;
    }
    return {
        e: empty,
        u: using.bind(null, false),
        a: using.bind(null, true),
        d: function() {
            var error = this.e;
            function next() {
                while(resource = stack.pop()){
                    try {
                        var resource, disposalResult = resource.d && resource.d.call(resource.v);
                        if (resource.a) {
                            return Promise.resolve(disposalResult).then(next, err);
                        }
                    } catch (e) {
                        return err(e);
                    }
                }
                if (error !== empty) throw error;
            }
            function err(e) {
                error = error !== empty ? new _disposeSuppressedError(error, e) : e;
                return next();
            }
            return next();
        }
    };
}
import { mergeUpdates } from 'yjs';
import { MemoryMemento, ReadonlyByteKV, wrapMemento } from '../../storage';
import { AsyncLock, throwIfAborted } from '../../utils';
import { DocEventBusInner, MemoryDocEventBus } from './event';
import { isEmptyUpdate } from './utils';
const Keys = {
    SeqNum: (docId)=>`${docId}:seqNum`,
    SeqNumPushed: (docId)=>`${docId}:seqNumPushed`,
    ServerClockPulled: (docId)=>`${docId}:serverClockPulled`,
    UpdatedTime: (docId)=>`${docId}:updateTime`
};
const Values = {
    UInt64: {
        parse: (buffer)=>{
            const view = new DataView(buffer.buffer);
            return Number(view.getBigUint64(0, false));
        },
        serialize: (value)=>{
            const buffer = new ArrayBuffer(8);
            const view = new DataView(buffer);
            view.setBigUint64(0, BigInt(value), false);
            return new Uint8Array(buffer);
        }
    }
};
export class DocStorageInner {
    constructor(behavior){
        this.behavior = behavior;
        this.eventBus = new DocEventBusInner(this.behavior.eventBus);
    }
    async loadServerClock(signal) {
        throwIfAborted(signal);
        const list = await this.behavior.serverClock.keys();
        const map = new Map();
        for (const key of list){
            const docId = key;
            const value = await this.behavior.serverClock.get(key);
            if (value) {
                map.set(docId, Values.UInt64.parse(value));
            }
        }
        return map;
    }
    async saveServerClock(map, signal) {
        throwIfAborted(signal);
        await this.behavior.serverClock.transaction(async (transaction)=>{
            for (const [docId, value] of map){
                const key = docId;
                const oldBuffer = await transaction.get(key);
                const old = oldBuffer ? Values.UInt64.parse(oldBuffer) : 0;
                if (old < value) {
                    await transaction.set(key, Values.UInt64.serialize(value));
                }
            }
        });
    }
    async loadDocSeqNum(docId, signal) {
        throwIfAborted(signal);
        const bytes = await this.behavior.syncMetadata.get(Keys.SeqNum(docId));
        if (bytes === null) {
            return 0;
        }
        return Values.UInt64.parse(bytes);
    }
    async saveDocSeqNum(docId, seqNum, signal) {
        throwIfAborted(signal);
        return await this.behavior.syncMetadata.transaction(async (transaction)=>{
            const key = Keys.SeqNum(docId);
            const oldBytes = await transaction.get(key);
            const old = oldBytes ? Values.UInt64.parse(oldBytes) : 0;
            if (seqNum === true) {
                await transaction.set(key, Values.UInt64.serialize(old + 1));
                return old + 1;
            }
            if (old < seqNum) {
                await transaction.set(key, Values.UInt64.serialize(seqNum));
                return seqNum;
            }
            return old;
        });
    }
    async loadDocSeqNumPushed(docId, signal) {
        throwIfAborted(signal);
        const bytes = await this.behavior.syncMetadata.get(Keys.SeqNumPushed(docId));
        if (bytes === null) {
            return null;
        }
        return Values.UInt64.parse(bytes);
    }
    async saveDocPushedSeqNum(docId, seqNum, signal) {
        throwIfAborted(signal);
        await this.behavior.syncMetadata.transaction(async (transaction)=>{
            const key = Keys.SeqNumPushed(docId);
            const oldBytes = await transaction.get(key);
            const old = oldBytes ? Values.UInt64.parse(oldBytes) : null;
            if (typeof seqNum === 'object') {
                return transaction.set(key, Values.UInt64.serialize((old ?? 0) + seqNum.add));
            }
            if (old === null || old < seqNum) {
                return transaction.set(key, Values.UInt64.serialize(seqNum));
            }
        });
    }
    async loadDocServerClockPulled(docId, signal) {
        throwIfAborted(signal);
        const bytes = await this.behavior.syncMetadata.get(Keys.ServerClockPulled(docId));
        if (bytes === null) {
            return null;
        }
        return bytes ? Values.UInt64.parse(bytes) : 0;
    }
    async saveDocServerClockPulled(docId, serverClock, signal) {
        throwIfAborted(signal);
        await this.behavior.syncMetadata.transaction(async (transaction)=>{
            const oldBytes = await transaction.get(Keys.ServerClockPulled(docId));
            const old = oldBytes ? Values.UInt64.parse(oldBytes) : null;
            if (old === null || old < serverClock) {
                await transaction.set(Keys.ServerClockPulled(docId), Values.UInt64.serialize(serverClock));
            }
        });
    }
    async loadDocFromLocal(docId, signal) {
        throwIfAborted(signal);
        return await this.behavior.doc.get(docId);
    }
    async commitDocAsServerUpdate(docId, update, serverClock, signal) {
        throwIfAborted(signal);
        await this.behavior.doc.transaction(async (tx)=>{
            const data = await tx.get(docId);
            await tx.set(docId, data && !isEmptyUpdate(data) ? !isEmptyUpdate(update) ? mergeUpdates([
                data,
                update
            ]) : data : update);
        });
        await this.saveDocServerClockPulled(docId, serverClock);
    }
    async commitDocAsClientUpdate(docId, update, signal) {
        throwIfAborted(signal);
        await this.behavior.doc.transaction(async (tx)=>{
            const data = await tx.get(docId);
            await tx.set(docId, data && !isEmptyUpdate(data) ? !isEmptyUpdate(update) ? mergeUpdates([
                data,
                update
            ]) : data : update);
        });
        return await this.saveDocSeqNum(docId, true);
    }
    clearSyncMetadata() {
        return this.behavior.syncMetadata.clear();
    }
    async clearServerClock() {
        return this.behavior.serverClock.clear();
    }
}
export class ReadonlyStorage {
    constructor(map){
        this.map = map;
        this.eventBus = new MemoryDocEventBus();
        this.doc = new ReadonlyByteKV(new Map(Object.entries(this.map)));
        this.serverClock = new ReadonlyByteKV();
        this.syncMetadata = new ReadonlyByteKV();
    }
}
export class MemoryStorage {
    constructor(memo = new MemoryMemento()){
        this.memo = memo;
        this.eventBus = new MemoryDocEventBus();
        this.lock = new AsyncLock();
        this.docDb = wrapMemento(this.memo, 'doc:');
        this.syncMetadataDb = wrapMemento(this.memo, 'syncMetadata:');
        this.serverClockDb = wrapMemento(this.memo, 'serverClock:');
        this.doc = {
            transaction: async (cb)=>{
                try {
                    var _usingCtx = _using_ctx();
                    const _lock = _usingCtx.u(await this.lock.acquire());
                    return await cb({
                        get: async (key)=>{
                            return this.docDb.get(key) ?? null;
                        },
                        set: async (key, value)=>{
                            this.docDb.set(key, value);
                        },
                        keys: async ()=>{
                            return Array.from(this.docDb.keys());
                        },
                        clear: ()=>{
                            this.docDb.clear();
                        },
                        del: (key)=>{
                            this.docDb.del(key);
                        }
                    });
                } catch (_) {
                    _usingCtx.e = _;
                } finally{
                    _usingCtx.d();
                }
            },
            get (key) {
                return this.transaction(async (tx)=>tx.get(key));
            },
            set (key, value) {
                return this.transaction(async (tx)=>tx.set(key, value));
            },
            keys () {
                return this.transaction(async (tx)=>tx.keys());
            },
            clear () {
                return this.transaction(async (tx)=>tx.clear());
            },
            del (key) {
                return this.transaction(async (tx)=>tx.del(key));
            }
        };
        this.syncMetadata = {
            transaction: async (cb)=>{
                try {
                    var _usingCtx = _using_ctx();
                    const _lock = _usingCtx.u(await this.lock.acquire());
                    return await cb({
                        get: async (key)=>{
                            return this.syncMetadataDb.get(key) ?? null;
                        },
                        set: async (key, value)=>{
                            this.syncMetadataDb.set(key, value);
                        },
                        keys: async ()=>{
                            return Array.from(this.syncMetadataDb.keys());
                        },
                        clear: ()=>{
                            this.syncMetadataDb.clear();
                        },
                        del: (key)=>{
                            this.syncMetadataDb.del(key);
                        }
                    });
                } catch (_) {
                    _usingCtx.e = _;
                } finally{
                    _usingCtx.d();
                }
            },
            get (key) {
                return this.transaction(async (tx)=>tx.get(key));
            },
            set (key, value) {
                return this.transaction(async (tx)=>tx.set(key, value));
            },
            keys () {
                return this.transaction(async (tx)=>tx.keys());
            },
            clear () {
                return this.transaction(async (tx)=>tx.clear());
            },
            del (key) {
                return this.transaction(async (tx)=>tx.del(key));
            }
        };
        this.serverClock = {
            transaction: async (cb)=>{
                try {
                    var _usingCtx = _using_ctx();
                    const _lock = _usingCtx.u(await this.lock.acquire());
                    return await cb({
                        get: async (key)=>{
                            return this.serverClockDb.get(key) ?? null;
                        },
                        set: async (key, value)=>{
                            this.serverClockDb.set(key, value);
                        },
                        keys: async ()=>{
                            return Array.from(this.serverClockDb.keys());
                        },
                        clear: ()=>{
                            this.serverClockDb.clear();
                        },
                        del: (key)=>{
                            this.serverClockDb.del(key);
                        }
                    });
                } catch (_) {
                    _usingCtx.e = _;
                } finally{
                    _usingCtx.d();
                }
            },
            get (key) {
                return this.transaction(async (tx)=>tx.get(key));
            },
            set (key, value) {
                return this.transaction(async (tx)=>tx.set(key, value));
            },
            keys () {
                return this.transaction(async (tx)=>tx.keys());
            },
            clear () {
                return this.transaction(async (tx)=>tx.clear());
            },
            del (key) {
                return this.transaction(async (tx)=>tx.del(key));
            }
        };
    }
}
