import { DebugLogger } from '@affine/debug';
import { css, html } from 'lit';
const logger = new DebugLogger('toast');
export const sleep = (ms = 0)=>new Promise((resolve)=>setTimeout(resolve, ms));
let ToastContainer = null;
const htmlToElement = (html)=>{
    const template = document.createElement('template');
    if (typeof html === 'string') {
        html = html.trim();
        template.innerHTML = html;
    } else {
        const { strings, values } = html;
        const v = [
            ...values,
            ''
        ];
        template.innerHTML = strings.reduce((acc, cur, i)=>acc + cur + v[i], '');
    }
    return template.content.firstChild;
};
const createToastContainer = (portal)=>{
    portal = portal || document.body;
    const styles = css`
    width: 100%;
    position: fixed;
    z-index: 9999;
    bottom: 78px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
    const template = html`<div
    style="${styles}"
    data-testid="affine-toast-container"
  ></div>`;
    const element = htmlToElement(template);
    portal.append(element);
    return element;
};
const animateToastOut = (toastElement)=>{
    toastElement.style.opacity = '0';
    setTimeout(()=>toastElement.remove(), 300);
};
const createAndShowNewToast = (message, duration, portal)=>{
    if (!ToastContainer || portal && !portal.contains(ToastContainer)) {
        ToastContainer = createToastContainer(portal);
    }
    const toastStyles = css`
    position: absolute;
    bottom: 0;
    max-width: 480px;
    text-align: center;
    font-family: var(--affine-font-family);
    font-size: var(--affine-font-sm);
    padding: 10px 16px;
    margin: 0;
    color: var(--affine-white);
    background: var(--affine-tooltip);
    box-shadow: var(--affine-float-button-shadow);
    border-radius: 8px;
    opacity: 0;
    transform: translateY(100%);
    transition:
      transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
      opacity 0.3s ease;
  `;
    const toastTemplate = html`<div
    style="${toastStyles}"
    data-testid="affine-toast"
  >
    ${message}
  </div>`;
    const toastElement = htmlToElement(toastTemplate);
    toastElement.textContent = message;
    ToastContainer.append(toastElement);
    logger.debug(`toast with message: "${message}"`);
    window.dispatchEvent(new CustomEvent('affine-toast:emit', {
        detail: message
    }));
    setTimeout(()=>{
        toastElement.style.opacity = '1';
        toastElement.style.transform = 'translateY(0)';
    }, 100);
    setTimeout(()=>{
        animateToastOut(toastElement);
    }, duration);
};
export const toast = (message, { duration = 3000, portal } = {})=>{
    if (ToastContainer && ToastContainer.children.length >= 2) {
        const oldestToast = ToastContainer.children[0];
        oldestToast.remove();
    }
    if (ToastContainer && ToastContainer.children.length === 1) {
        const currentToast = ToastContainer.children[0];
        animateToastOut(currentToast);
    }
    createAndShowNewToast(message, duration, portal);
};
export default toast;
