import { DocModeProvider } from '../../services/doc-mode-service.js';
import { matchFlavours } from './checker.js';
export function getPrevContentBlock(editorHost, model) {
    const getPrev = (model)=>{
        const parent = model.doc.getParent(model);
        if (!parent) return null;
        const index = parent.children.indexOf(model);
        if (index > 0) {
            let tmpIndex = index - 1;
            let prev = parent.children[tmpIndex];
            if (parent.role === 'root' && model.role === 'hub') {
                while(prev && prev.flavour !== 'affine:note'){
                    prev = parent.children[tmpIndex];
                    tmpIndex--;
                }
            }
            if (!prev) return null;
            while(prev.children.length > 0){
                prev = prev.children[prev.children.length - 1];
            }
            return prev;
        }
        if (editorHost.std.get(DocModeProvider).getEditorMode() === 'edgeless' && parent.role === 'hub') {
            return null;
        }
        return parent;
    };
    const map = {};
    const iterate = (model)=>{
        if (model.id in map) {
            console.error("Can't get previous block! There's a loop in the block tree!");
            return null;
        }
        map[model.id] = true;
        const prev = getPrev(model);
        if (prev) {
            if (prev.role === 'content' && !matchFlavours(prev, [
                'affine:frame'
            ])) {
                return prev;
            }
            return iterate(prev);
        }
        return null;
    };
    return iterate(model);
}
export function getNextContentBlock(editorHost, model, map = {}) {
    if (model.id in map) {
        console.error("Can't get next block! There's a loop in the block tree!");
        return null;
    }
    map[model.id] = true;
    const doc = model.doc;
    if (model.children.length) {
        return model.children[0];
    }
    let currentBlock = model;
    while(currentBlock){
        const nextSibling = doc.getNext(currentBlock);
        if (nextSibling) {
            if (nextSibling.role === 'hub') {
                if (editorHost.std.get(DocModeProvider).getEditorMode() === 'edgeless') {
                    return null;
                }
                return getNextContentBlock(editorHost, nextSibling);
            }
            return nextSibling;
        }
        currentBlock = doc.getParent(currentBlock);
    }
    return null;
}
