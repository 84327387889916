function _using_ctx() {
    var _disposeSuppressedError = typeof SuppressedError === "function" ? SuppressedError : function(error, suppressed) {
        var err = new Error();
        err.name = "SuppressedError";
        err.suppressed = suppressed;
        err.error = error;
        return err;
    }, empty = {}, stack = [];
    function using(isAwait, value) {
        if (value != null) {
            if (Object(value) !== value) {
                throw new TypeError("using declarations can only be used with objects, functions, null, or undefined.");
            }
            if (isAwait) {
                var dispose = value[Symbol.asyncDispose || Symbol.for("Symbol.asyncDispose")];
            }
            if (dispose == null) {
                dispose = value[Symbol.dispose || Symbol.for("Symbol.dispose")];
            }
            if (typeof dispose !== "function") {
                throw new TypeError(`Property [Symbol.dispose] is not a function.`);
            }
            stack.push({
                v: value,
                d: dispose,
                a: isAwait
            });
        } else if (isAwait) {
            stack.push({
                d: value,
                a: isAwait
            });
        }
        return value;
    }
    return {
        e: empty,
        u: using.bind(null, false),
        a: using.bind(null, true),
        d: function() {
            var error = this.e;
            function next() {
                while(resource = stack.pop()){
                    try {
                        var resource, disposalResult = resource.d && resource.d.call(resource.v);
                        if (resource.a) {
                            return Promise.resolve(disposalResult).then(next, err);
                        }
                    } catch (e) {
                        return err(e);
                    }
                }
                if (error !== empty) throw error;
            }
            function err(e) {
                error = error !== empty ? new _disposeSuppressedError(error, e) : e;
                return next();
            }
            return next();
        }
    };
}
import { AsyncLock } from '../utils';
export class MemoryByteKV {
    constructor(db = new Map()){
        this.db = db;
        this.lock = new AsyncLock();
    }
    async transaction(cb) {
        try {
            var _usingCtx = _using_ctx();
            const _lock = _usingCtx.u(await this.lock.acquire());
            return await cb({
                get: async (key)=>{
                    return this.db.get(key) ?? null;
                },
                set: async (key, value)=>{
                    this.db.set(key, value);
                },
                keys: async ()=>{
                    return Array.from(this.db.keys());
                },
                del: async (key)=>{
                    this.db.delete(key);
                },
                clear: async ()=>{
                    this.db.clear();
                }
            });
        } catch (_) {
            _usingCtx.e = _;
        } finally{
            _usingCtx.d();
        }
    }
    get(key) {
        return this.transaction(async (tx)=>tx.get(key));
    }
    set(key, value) {
        return this.transaction(async (tx)=>tx.set(key, value));
    }
    keys() {
        return this.transaction(async (tx)=>tx.keys());
    }
    clear() {
        return this.transaction(async (tx)=>tx.clear());
    }
    del(key) {
        return this.transaction(async (tx)=>tx.del(key));
    }
}
export class ReadonlyByteKV extends MemoryByteKV {
    transaction(cb) {
        return super.transaction((tx)=>{
            return cb({
                ...tx,
                set () {
                    return Promise.resolve();
                },
                del () {
                    return Promise.resolve();
                },
                clear () {
                    return Promise.resolve();
                }
            });
        });
    }
    set(_key, _value) {
        return Promise.resolve();
    }
    del(_key) {
        return Promise.resolve();
    }
    clear() {
        return Promise.resolve();
    }
}
