function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _init_mindmapStyle, _init_onSelect, _initProto, _dec2, _dec3, _init_mindmapLayout, _init_onSelect1, _initProto1, _dec4, _dec5, _dec6, _dec7, _init_edgeless, _init_elements, _init_layoutType, _init_nodes, _initProto2;
import { MindmapBalanceLayoutIcon, MindmapLeftLayoutIcon, MindmapRightLayoutIcon, MindmapStyleFour, MindmapStyleIcon, MindmapStyleOne, MindmapStyleThree, MindmapStyleTwo, SmallArrowDownIcon } from '@blocksuite/affine-components/icons';
import { renderToolbarSeparator } from '@blocksuite/affine-components/toolbar';
import { LayoutType, MindmapStyle } from '@blocksuite/affine-model';
import { EditPropsStore } from '@blocksuite/affine-shared/services';
import { countBy, maxBy, WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
const MINDMAP_STYLE_LIST = [
    {
        value: MindmapStyle.ONE,
        icon: MindmapStyleOne
    },
    {
        value: MindmapStyle.FOUR,
        icon: MindmapStyleFour
    },
    {
        value: MindmapStyle.THREE,
        icon: MindmapStyleThree
    },
    {
        value: MindmapStyle.TWO,
        icon: MindmapStyleTwo
    }
];
const MINDMAP_LAYOUT_LIST = [
    {
        name: 'Left',
        value: LayoutType.LEFT,
        icon: MindmapLeftLayoutIcon
    },
    {
        name: 'Radial',
        value: LayoutType.BALANCE,
        icon: MindmapBalanceLayoutIcon
    },
    {
        name: 'Right',
        value: LayoutType.RIGHT,
        icon: MindmapRightLayoutIcon
    }
];
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
});
export class EdgelessChangeMindmapStylePanel extends LitElement {
    static{
        ({ e: [_init_mindmapStyle, _init_onSelect, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "mindmapStyle"
            ],
            [
                _dec1,
                1,
                "onSelect"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 8px;
      background: var(--affine-background-overlay-panel-color);
    }

    .style-item {
      border-radius: 4px;
    }

    .style-item > svg {
      vertical-align: middle;
    }

    .style-item.active,
    .style-item:hover {
      cursor: pointer;
      background-color: var(--affine-hover-color);
    }
  `;
    }
    render() {
        return repeat(MINDMAP_STYLE_LIST, (item)=>item.value, ({ value, icon })=>html`
        <div
          role="button"
          class="style-item ${value === this.mindmapStyle ? 'active' : ''}"
          @click=${()=>this.onSelect(value)}
        >
          ${icon}
        </div>
      `);
    }
    #___private_mindmapStyle_1;
    get mindmapStyle() {
        return this.#___private_mindmapStyle_1;
    }
    set mindmapStyle(_v) {
        this.#___private_mindmapStyle_1 = _v;
    }
    #___private_onSelect_2;
    get onSelect() {
        return this.#___private_onSelect_2;
    }
    set onSelect(_v) {
        this.#___private_onSelect_2 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_mindmapStyle_1 = (_initProto(this), _init_mindmapStyle(this)), this.#___private_onSelect_2 = _init_onSelect(this);
    }
}
_dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
export class EdgelessChangeMindmapLayoutPanel extends LitElement {
    static{
        ({ e: [_init_mindmapLayout, _init_onSelect1, _initProto1] } = _apply_decs_2203_r(this, [
            [
                _dec2,
                1,
                "mindmapLayout"
            ],
            [
                _dec3,
                1,
                "onSelect"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 8px;
    }
  `;
    }
    render() {
        return repeat(MINDMAP_LAYOUT_LIST, (item)=>item.value, ({ name, value, icon })=>html`
        <editor-icon-button
          aria-label=${name}
          .tooltip=${name}
          .tipPosition=${'top'}
          .active=${this.mindmapLayout === value}
          .activeMode=${'background'}
          @click=${()=>this.onSelect(value)}
        >
          ${icon}
        </editor-icon-button>
      `);
    }
    #___private_mindmapLayout_3;
    get mindmapLayout() {
        return this.#___private_mindmapLayout_3;
    }
    set mindmapLayout(_v) {
        this.#___private_mindmapLayout_3 = _v;
    }
    #___private_onSelect_4;
    get onSelect() {
        return this.#___private_onSelect_4;
    }
    set onSelect(_v) {
        this.#___private_onSelect_4 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_mindmapLayout_3 = (_initProto1(this), _init_mindmapLayout(this)), this.#___private_onSelect_4 = _init_onSelect1(this);
    }
}
_dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = state(), _dec7 = property({
    attribute: false
});
export class EdgelessChangeMindmapButton extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_edgeless, _init_elements, _init_layoutType, _init_nodes, _initProto2] } = _apply_decs_2203_r(this, [
            [
                _dec4,
                1,
                "edgeless"
            ],
            [
                _dec5,
                1,
                "elements"
            ],
            [
                _dec6,
                1,
                "layoutType"
            ],
            [
                _dec7,
                1,
                "nodes"
            ]
        ], []));
    }
    get _mindmaps() {
        const mindmaps = new Set();
        return this.elements.reduce((_, el)=>{
            mindmaps.add(el);
            return mindmaps;
        }, mindmaps);
    }
    get layout() {
        const layoutType = this.layoutType ?? this._getCommonLayoutType();
        return MINDMAP_LAYOUT_LIST.find((item)=>item.value === layoutType);
    }
    _getCommonLayoutType() {
        const values = countBy(this.elements, (element)=>element.layoutType);
        const max = maxBy(Object.entries(values), ([_k, count])=>count);
        return max ? Number(max[0]) : LayoutType.BALANCE;
    }
    _getCommonStyle() {
        const values = countBy(this.elements, (element)=>element.style);
        const max = maxBy(Object.entries(values), ([_k, count])=>count);
        return max ? Number(max[0]) : MindmapStyle.ONE;
    }
    _isSubnode() {
        return this.nodes.length === 1 && this.nodes[0].group.tree.element !== this.nodes[0];
    }
    render() {
        return join([
            html`
          <editor-menu-button
            .contentPadding=${'8px'}
            .button=${html`
              <editor-icon-button aria-label="Style" .tooltip=${'Style'}>
                ${MindmapStyleIcon}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <edgeless-change-mindmap-style-panel
              .mindmapStyle=${this._getCommonStyle()}
              .onSelect=${this._updateStyle}
            >
            </edgeless-change-mindmap-style-panel>
          </editor-menu-button>
        `,
            this._isSubnode() ? nothing : html`
              <editor-menu-button
                .button=${html`
                  <editor-icon-button aria-label="Layout" .tooltip=${'Layout'}>
                    ${this.layout.icon}${SmallArrowDownIcon}
                  </editor-icon-button>
                `}
              >
                <edgeless-change-mindmap-layout-panel
                  .mindmapLayout=${this.layout.value}
                  .onSelect=${this._updateLayoutType}
                >
                </edgeless-change-mindmap-layout-panel>
              </editor-menu-button>
            `
        ].filter((button)=>button !== nothing), renderToolbarSeparator);
    }
    #___private_edgeless_5;
    get edgeless() {
        return this.#___private_edgeless_5;
    }
    set edgeless(_v) {
        this.#___private_edgeless_5 = _v;
    }
    #___private_elements_6;
    get elements() {
        return this.#___private_elements_6;
    }
    set elements(_v) {
        this.#___private_elements_6 = _v;
    }
    #___private_layoutType_7;
    get layoutType() {
        return this.#___private_layoutType_7;
    }
    set layoutType(_v) {
        this.#___private_layoutType_7 = _v;
    }
    #___private_nodes_8;
    get nodes() {
        return this.#___private_nodes_8;
    }
    set nodes(_v) {
        this.#___private_nodes_8 = _v;
    }
    constructor(...args){
        super(...args), this._updateLayoutType = (layoutType)=>{
            this.edgeless.std.get(EditPropsStore).recordLastProps('mindmap', {
                layoutType
            });
            this.elements.forEach((element)=>{
                element.layoutType = layoutType;
                element.layout();
            });
            this.layoutType = layoutType;
        }, this._updateStyle = (style)=>{
            this.edgeless.std.get(EditPropsStore).recordLastProps('mindmap', {
                style
            });
            this._mindmaps.forEach((element)=>element.style = style);
        }, this.#___private_edgeless_5 = (_initProto2(this), _init_edgeless(this)), this.#___private_elements_6 = _init_elements(this), this.#___private_layoutType_7 = _init_layoutType(this), this.#___private_nodes_8 = _init_nodes(this);
    }
}
export function renderMindmapButton(edgeless, elements) {
    if (!elements?.length) return nothing;
    const mindmaps = [];
    elements.forEach((e)=>{
        if (e.type === 'mindmap') {
            mindmaps.push(e);
        }
        const group = edgeless.service.surface.getGroup(e.id);
        if (group?.type === 'mindmap') {
            mindmaps.push(group);
        }
    });
    if (mindmaps.length === 0) {
        return nothing;
    }
    return html`
    <edgeless-change-mindmap-button
      .elements=${mindmaps}
      .nodes=${elements.filter((e)=>e.type === 'shape')}
      .edgeless=${edgeless}
    >
    </edgeless-change-mindmap-button>
  `;
}
