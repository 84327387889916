import { getNextContentBlock, matchFlavours } from '@blocksuite/affine-shared/utils';
export function forwardDelete(std) {
    const text = std.selection.find('text');
    if (!text) return;
    const isCollapsed = text.isCollapsed();
    const doc = std.doc;
    const model = doc.getBlock(text.from.blockId)?.model;
    if (!model || !matchFlavours(model, [
        'affine:list'
    ])) return;
    const isEnd = isCollapsed && text.from.index === model.text.length;
    if (!isEnd) return;
    const firstChild = model.firstChild();
    if (firstChild) {
        model.text.join(firstChild.text);
        const grandChildren = firstChild.children;
        if (grandChildren) {
            doc.moveBlocks(grandChildren, model);
            doc.deleteBlock(firstChild);
            return true;
        }
        doc.deleteBlock(firstChild);
        return true;
    }
    const parent = doc.getParent(model);
    const nextSibling = doc.getNext(model);
    const nextText = nextSibling?.text;
    if (nextSibling && nextText) {
        model.text.join(nextText);
        if (nextSibling.children) {
            if (!parent) return;
            doc.moveBlocks(nextSibling.children, parent, model, false);
        }
        doc.deleteBlock(nextSibling);
        return true;
    }
    const nextBlock = getNextContentBlock(std.host, model);
    const nextBlockText = nextBlock?.text;
    if (nextBlock && nextBlockText) {
        model.text.join(nextBlock.text);
        if (nextBlock.children) {
            const nextBlockParent = doc.getParent(nextBlock);
            if (!nextBlockParent) return;
            doc.moveBlocks(nextBlock.children, nextBlockParent, parent, false);
        }
        doc.deleteBlock(nextBlock);
    }
    return true;
}
