import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import bytes from 'bytes';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class UserQuota extends Entity {
    constructor(authService, store){
        super(), this.authService = authService, this.store = store, this.quota$ = new LiveData(null), this.used$ = new LiveData(null), this.usedFormatted$ = this.used$.map((used)=>used !== null ? bytes.format(used) : null), this.max$ = this.quota$.map((quota)=>quota ? quota.storageQuota : null), this.maxFormatted$ = this.max$.map((max)=>max ? bytes.format(max) : null), this.percent$ = LiveData.computed((get)=>{
            const max = get(this.max$);
            const used = get(this.used$);
            if (max === null || used === null) {
                return null;
            }
            return Math.min(100, Math.max(0.5, Number((used / max * 100).toFixed(4))));
        }), this.color$ = this.percent$.map((percent)=>percent !== null ? percent > 80 ? cssVar('errorColor') : cssVar('processingColor') : null), this.isRevalidating$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(map(()=>({
                accountId: this.authService.session.account$.value?.id
            })), exhaustMapSwitchUntilChanged((a, b)=>a.accountId === b.accountId, ({ accountId })=>fromPromise(async (signal)=>{
                if (!accountId) {
                    return;
                }
                const { quota, used } = await this.store.fetchUserQuota(signal);
                return {
                    quota,
                    used
                };
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((data)=>{
                if (data) {
                    const { quota, used } = data;
                    this.quota$.next(quota);
                    this.used$.next(used);
                } else {
                    this.quota$.next(null);
                    this.used$.next(null);
                }
                return EMPTY;
            }), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false))), ()=>{
            this.reset();
        }));
    }
    reset() {
        this.quota$.next(null);
        this.used$.next(null);
        this.error$.next(null);
        this.isRevalidating$.next(false);
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
