import { LayoutType, MindmapElementModel } from '@blocksuite/affine-model';
import { ConnectorElementModel, ConnectorMode, EdgelessTextBlockModel, GroupElementModel, NoteDisplayMode } from '@blocksuite/affine-model';
import { EditPropsStore, TelemetryProvider } from '@blocksuite/affine-shared/services';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { IS_MAC } from '@blocksuite/global/env';
import { Bound } from '@blocksuite/global/utils';
import { getNearestTranslation, isElementOutsideViewport, isSelectSingleMindMap } from '../../_common/edgeless/mindmap/index.js';
import { LassoMode } from '../../_common/types.js';
import { EdgelessTextBlockComponent } from '../../edgeless-text-block/edgeless-text-block.js';
import { PageKeyboardManager } from '../keyboard/keyboard-manager.js';
import { GfxBlockModel } from './block-model.js';
import { CopilotSelectionController } from './tools/copilot-tool.js';
import { LassoToolController } from './tools/lasso-tool.js';
import { ShapeToolController } from './tools/shape-tool.js';
import { DEFAULT_NOTE_CHILD_FLAVOUR, DEFAULT_NOTE_CHILD_TYPE, DEFAULT_NOTE_TIP } from './utils/consts.js';
import { deleteElements } from './utils/crud.js';
import { getNextShapeType } from './utils/hotkey-utils.js';
import { isCanvasElement, isNoteBlock } from './utils/query.js';
import { mountConnectorLabelEditor, mountShapeTextEditor } from './utils/text.js';
export class EdgelessPageKeyboardManager extends PageKeyboardManager {
    constructor(rootComponent){
        super(rootComponent), this.rootComponent = rootComponent;
        this.rootComponent.bindHotKey({
            v: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'default'
                });
            },
            t: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'text'
                });
            },
            c: ()=>{
                const mode = ConnectorMode.Curve;
                rootComponent.std.get(EditPropsStore).recordLastProps('connector', {
                    mode
                });
                this._setEdgelessTool(rootComponent, {
                    type: 'connector',
                    mode
                });
            },
            l: ()=>{
                if (!rootComponent.doc.awarenessStore.getFlag('enable_lasso_tool')) {
                    return;
                }
                const edgeless = rootComponent;
                const lassoController = edgeless.tools.controllers['lasso'];
                const tool = {
                    type: 'lasso',
                    mode: LassoMode.Polygonal
                };
                if (lassoController instanceof LassoToolController) tool.mode = lassoController.tool.mode;
                this._setEdgelessTool(edgeless, tool);
            },
            'Shift-l': ()=>{
                if (!rootComponent.doc.awarenessStore.getFlag('enable_lasso_tool')) {
                    return;
                }
                const edgeless = rootComponent;
                const cur = edgeless.edgelessTool;
                const tool = {
                    type: 'lasso',
                    mode: cur.type === 'lasso' ? cur.mode === LassoMode.FreeHand ? LassoMode.Polygonal : LassoMode.FreeHand : LassoMode.FreeHand
                };
                this._setEdgelessTool(edgeless, tool);
            },
            h: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'pan',
                    panning: false
                });
            },
            n: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'affine:note',
                    childFlavour: DEFAULT_NOTE_CHILD_FLAVOUR,
                    childType: DEFAULT_NOTE_CHILD_TYPE,
                    tip: DEFAULT_NOTE_TIP
                });
            },
            p: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'brush'
                });
            },
            e: ()=>{
                this._setEdgelessTool(rootComponent, {
                    type: 'eraser'
                });
            },
            k: ()=>{
                if (this.rootComponent.service.locked) return;
                const { selection } = rootComponent.service;
                if (selection.selectedElements.length === 1 && selection.firstElement instanceof GfxBlockModel && matchFlavours(selection.firstElement, [
                    'affine:note'
                ])) {
                    rootComponent.slots.toggleNoteSlicer.emit();
                }
            },
            f: ()=>{
                if (this.rootComponent.service.locked) return;
                if (this.rootComponent.service.selection.selectedElements.length !== 0 && !this.rootComponent.service.selection.editing) {
                    const frame = rootComponent.service.frame.createFrameOnSelected();
                    if (!frame) return;
                    this.rootComponent.std.getOptional(TelemetryProvider)?.track('CanvasElementAdded', {
                        control: 'shortcut',
                        page: 'whiteboard editor',
                        module: 'toolbar',
                        segment: 'toolbar',
                        type: 'frame'
                    });
                    rootComponent.surface.fitToViewport(Bound.deserialize(frame.xywh));
                } else if (!this.rootComponent.service.selection.editing) {
                    this._setEdgelessTool(rootComponent, {
                        type: 'frame'
                    });
                }
            },
            '-': ()=>{
                if (this.rootComponent.service.locked) return;
                const { selectedElements: elements } = rootComponent.service.selection;
                if (!rootComponent.service.selection.editing && elements.length === 1 && isNoteBlock(elements[0])) {
                    rootComponent.slots.toggleNoteSlicer.emit();
                }
            },
            '@': ()=>{
                const std = this.rootComponent.std;
                if (std.selection.getGroup('note').length > 0 || std.selection.find('text') || Boolean(std.selection.find('surface')?.editing)) {
                    return;
                }
                const { insertedLinkType } = std.command.exec('insertLinkByQuickSearch');
                insertedLinkType?.then((type)=>{
                    const flavour = type?.flavour;
                    if (!flavour) return;
                    rootComponent.std.getOptional(TelemetryProvider)?.track('CanvasElementAdded', {
                        control: 'shortcut',
                        page: 'whiteboard editor',
                        module: 'toolbar',
                        segment: 'toolbar',
                        type: flavour.split(':')[1]
                    });
                }).catch(console.error);
            },
            'Shift-s': ()=>{
                if (this.rootComponent.service.locked) return;
                const controller = rootComponent.tools.currentController;
                if (this.rootComponent.service.selection.editing || !(controller instanceof ShapeToolController)) {
                    return;
                }
                const { shapeName } = controller.tool;
                const nextShapeName = getNextShapeType(shapeName);
                this._setEdgelessTool(rootComponent, {
                    type: 'shape',
                    shapeName: nextShapeName
                });
                controller.createOverlay();
            },
            'Mod-g': (ctx)=>{
                if (this.rootComponent.service.locked) return;
                if (this.rootComponent.service.selection.selectedElements.length > 1 && !this.rootComponent.service.selection.editing) {
                    ctx.get('keyboardState').event.preventDefault();
                    rootComponent.service.createGroupFromSelected();
                }
            },
            'Shift-Mod-g': (ctx)=>{
                if (this.rootComponent.service.locked) return;
                const { selection } = this.rootComponent.service;
                if (selection.selectedElements.length === 1 && selection.firstElement instanceof GroupElementModel) {
                    ctx.get('keyboardState').event.preventDefault();
                    rootComponent.service.ungroup(selection.firstElement);
                }
            },
            'Mod-a': (ctx)=>{
                if (this.rootComponent.service.locked) return;
                if (this.rootComponent.service.selection.editing) {
                    return;
                }
                ctx.get('defaultState').event.preventDefault();
                const { service } = this.rootComponent;
                this.rootComponent.service.selection.set({
                    elements: [
                        ...service.blocks.filter((block)=>block.group === null && !(matchFlavours(block, [
                                'affine:note'
                            ]) && block.displayMode === NoteDisplayMode.DocOnly)).map((block)=>block.id),
                        ...service.elements.filter((el)=>el.group === null).map((el)=>el.id)
                    ],
                    editing: false
                });
            },
            'Mod-1': (ctx)=>{
                ctx.get('defaultState').event.preventDefault();
                this.rootComponent.service.setZoomByAction('fit');
            },
            'Mod--': (ctx)=>{
                ctx.get('defaultState').event.preventDefault();
                this.rootComponent.service.setZoomByAction('out');
            },
            'Mod-0': (ctx)=>{
                ctx.get('defaultState').event.preventDefault();
                this.rootComponent.service.setZoomByAction('reset');
            },
            'Mod-=': (ctx)=>{
                ctx.get('defaultState').event.preventDefault();
                this.rootComponent.service.setZoomByAction('in');
            },
            Backspace: ()=>{
                this._delete();
            },
            Delete: ()=>{
                this._delete();
            },
            'Control-d': ()=>{
                if (!IS_MAC) return;
                this._delete();
            },
            Escape: ()=>{
                const { currentController } = this.rootComponent.tools;
                if (currentController instanceof LassoToolController && currentController.isSelecting) {
                    currentController.abort();
                }
                if (currentController instanceof CopilotSelectionController) {
                    currentController.abort();
                }
                if (!this.rootComponent.service.selection.empty) {
                    rootComponent.selection.clear();
                }
            },
            ArrowUp: ()=>{
                this._move('ArrowUp');
            },
            ArrowDown: ()=>{
                this._move('ArrowDown');
            },
            ArrowLeft: ()=>{
                this._move('ArrowLeft');
            },
            ArrowRight: ()=>{
                this._move('ArrowRight');
            },
            'Shift-ArrowUp': ()=>{
                this._move('ArrowUp', true);
            },
            'Shift-ArrowDown': ()=>{
                this._move('ArrowDown', true);
            },
            'Shift-ArrowLeft': ()=>{
                this._move('ArrowLeft', true);
            },
            'Shift-ArrowRight': ()=>{
                this._move('ArrowRight', true);
            },
            Enter: ()=>{
                const { service } = rootComponent;
                const selection = service.selection;
                const elements = selection.selectedElements;
                const onlyOne = elements.length === 1;
                if (onlyOne) {
                    const element = elements[0];
                    const id = element.id;
                    if (element instanceof ConnectorElementModel) {
                        selection.set({
                            elements: [
                                id
                            ],
                            editing: true
                        });
                        requestAnimationFrame(()=>{
                            mountConnectorLabelEditor(element, rootComponent);
                        });
                        return;
                    }
                    if (element instanceof EdgelessTextBlockModel) {
                        selection.set({
                            elements: [
                                id
                            ],
                            editing: true
                        });
                        const textBlock = rootComponent.host.view.getBlock(id);
                        if (textBlock instanceof EdgelessTextBlockComponent) {
                            textBlock.tryFocusEnd();
                        }
                        return;
                    }
                }
                if (!isSelectSingleMindMap(elements)) {
                    return;
                }
                const mindmap = elements[0].group;
                const currentNode = mindmap.getNode(elements[0].id);
                const node = mindmap.getNode(elements[0].id);
                const parent = mindmap.getParentNode(node.id) ?? node;
                const id = mindmap.addNode(parent.id, currentNode.id, 'after');
                const target = service.getElementById(id);
                requestAnimationFrame(()=>{
                    mountShapeTextEditor(target, rootComponent);
                    if (isElementOutsideViewport(service.viewport, target, [
                        20,
                        20
                    ])) {
                        const { elementBound } = target;
                        service.viewport.smoothTranslate(elementBound.x + elementBound.w / 2, elementBound.y + elementBound.h / 2);
                    }
                });
            },
            Tab: ()=>{
                const { service } = rootComponent;
                const selection = service.selection;
                const elements = selection.selectedElements;
                if (!isSelectSingleMindMap(elements)) {
                    return;
                }
                const mindmap = elements[0].group;
                const node = mindmap.getNode(elements[0].id);
                const id = mindmap.addNode(node.id);
                const target = service.getElementById(id);
                requestAnimationFrame(()=>{
                    mountShapeTextEditor(target, rootComponent);
                    if (isElementOutsideViewport(service.viewport, target, [
                        20,
                        20
                    ])) {
                        const { elementBound } = target;
                        service.viewport.smoothTranslate(elementBound.x + elementBound.w / 2, elementBound.y + elementBound.h / 2);
                    }
                });
            }
        }, {
            global: true
        });
        this._bindShiftKey();
        this._bindToggleHand();
    }
    _bindShiftKey() {
        this.rootComponent.handleEvent('keyDown', (ctx)=>{
            const event = ctx.get('defaultState').event;
            if (event instanceof KeyboardEvent) {
                this._shift(event);
            }
        }, {
            global: true
        });
        this.rootComponent.handleEvent('keyUp', (ctx)=>{
            const event = ctx.get('defaultState').event;
            if (event instanceof KeyboardEvent) {
                this._shift(event);
            }
        }, {
            global: true
        });
    }
    _bindToggleHand() {
        this.rootComponent.handleEvent('keyDown', (ctx)=>{
            const event = ctx.get('keyboardState').raw;
            const service = this.rootComponent.service;
            const selection = service.selection;
            if (event.code === 'Space' && !event.repeat) {
                this._space(event);
            } else if (!selection.editing && event.key.length === 1 && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                const elements = selection.selectedElements;
                const doc = this.rootComponent.doc;
                if (isSelectSingleMindMap(elements)) {
                    const target = service.getElementById(elements[0].id);
                    if (target.text) {
                        doc.transact(()=>{
                            target.text.delete(0, target.text.length);
                            target.text.insert(0, event.key);
                        });
                    }
                    mountShapeTextEditor(target, this.rootComponent);
                    return true;
                }
            }
            return false;
        }, {
            global: true
        });
        this.rootComponent.handleEvent('keyUp', (ctx)=>{
            const event = ctx.get('keyboardState').raw;
            if (event.code === 'Space' && !event.repeat) {
                this._space(event);
            }
        }, {
            global: true
        });
    }
    _delete() {
        const edgeless = this.rootComponent;
        if (edgeless.service.locked) return;
        if (edgeless.service.selection.editing) {
            return;
        }
        const selectedElements = edgeless.service.selection.selectedElements;
        if (isSelectSingleMindMap(selectedElements)) {
            const node = selectedElements[0];
            const mindmap = node.group;
            const focusNode = mindmap.getSiblingNode(node.id, 'prev') ?? mindmap.getSiblingNode(node.id, 'next') ?? mindmap.getParentNode(node.id);
            if (focusNode) {
                edgeless.service.selection.set({
                    elements: [
                        focusNode.element.id
                    ],
                    editing: false
                });
            }
            deleteElements(edgeless, selectedElements);
        } else {
            deleteElements(edgeless, selectedElements);
            edgeless.service.selection.clear();
        }
    }
    _move(key, shift = false) {
        const edgeless = this.rootComponent;
        if (edgeless.service.locked) return;
        if (edgeless.service.selection.editing) return;
        const { selectedElements } = edgeless.service.selection;
        const inc = shift ? 10 : 1;
        const mindmapNodes = selectedElements.filter((el)=>el.group instanceof MindmapElementModel);
        if (mindmapNodes.length > 0) {
            const node = mindmapNodes[0];
            const mindmap = node.group;
            const nodeDirection = mindmap.getLayoutDir(node.id);
            let targetNode = null;
            switch(key){
                case 'ArrowUp':
                case 'ArrowDown':
                    targetNode = mindmap.getSiblingNode(node.id, key === 'ArrowDown' ? 'next' : 'prev', nodeDirection === LayoutType.RIGHT ? 'right' : nodeDirection === LayoutType.LEFT ? 'left' : undefined)?.element ?? null;
                    break;
                case 'ArrowLeft':
                    targetNode = nodeDirection === LayoutType.RIGHT ? mindmap.getParentNode(node.id)?.element ?? null : mindmap.getChildNodes(node.id, 'left')[0]?.element ?? null;
                    break;
                case 'ArrowRight':
                    targetNode = nodeDirection === LayoutType.RIGHT || nodeDirection === LayoutType.BALANCE ? mindmap.getChildNodes(node.id, 'right')[0]?.element ?? null : mindmap.getParentNode(node.id)?.element ?? null;
                    break;
            }
            if (targetNode) {
                edgeless.service.selection.set({
                    elements: [
                        targetNode.id
                    ],
                    editing: false
                });
                if (isElementOutsideViewport(edgeless.service.viewport, targetNode, [
                    90,
                    20
                ])) {
                    const [dx, dy] = getNearestTranslation(edgeless.service.viewport, targetNode, [
                        100,
                        20
                    ]);
                    edgeless.service.viewport.smoothTranslate(edgeless.service.viewport.centerX - dx, edgeless.service.viewport.centerY + dy);
                }
            }
            return;
        }
        selectedElements.forEach((element)=>{
            const bound = Bound.deserialize(element.xywh).clone();
            switch(key){
                case 'ArrowUp':
                    bound.y -= inc;
                    break;
                case 'ArrowLeft':
                    bound.x -= inc;
                    break;
                case 'ArrowRight':
                    bound.x += inc;
                    break;
                case 'ArrowDown':
                    bound.y += inc;
                    break;
            }
            if (isCanvasElement(element)) {
                if (element instanceof ConnectorElementModel) {
                    element.moveTo(bound);
                }
                element['xywh'] = bound.serialize();
            } else {
                element['xywh'] = bound.serialize();
            }
        });
    }
    _setEdgelessTool(edgeless, edgelessTool, ignoreActiveState = false) {
        if (!ignoreActiveState && edgeless.service.selection.editing) {
            return;
        }
        edgeless.tools.setEdgelessTool(edgelessTool);
    }
    _shift(event) {
        const edgeless = this.rootComponent;
        if (event.repeat) return;
        const shiftKeyPressed = event.key.toLowerCase() === 'shift' && event.shiftKey;
        if (shiftKeyPressed) {
            edgeless.slots.pressShiftKeyUpdated.emit(true);
        } else {
            edgeless.slots.pressShiftKeyUpdated.emit(false);
        }
    }
    _space(event) {
        const edgeless = this.rootComponent;
        const selection = edgeless.service.selection;
        const currentTool = edgeless.edgelessTool;
        const type = currentTool.type;
        const isKeyDown = event.type === 'keydown';
        if (isKeyDown) {
            edgeless.tools.spaceBar = true;
        } else {
            edgeless.tools.spaceBar = false;
        }
        if (edgeless.service.tool.dragging) {
            return;
        }
        const revertToPrevTool = (ev)=>{
            if (ev.code === 'Space') {
                this._setEdgelessTool(edgeless, currentTool);
                document.removeEventListener('keyup', revertToPrevTool, false);
            }
        };
        if (isKeyDown) {
            if (type === 'pan' || type === 'default' && selection.editing) {
                return;
            }
            this._setEdgelessTool(edgeless, {
                type: 'pan',
                panning: false
            });
            edgeless.dispatcher.disposables.addFromEvent(document, 'keyup', revertToPrevTool);
        }
    }
}
