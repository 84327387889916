import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Logo1IconBorder } from '../../wemem/icons';
import { modalHeaderWrapper } from './share.css';
export const ModalHeader = ({ title, subTitle })=>{
    return _jsxs("div", {
        className: modalHeaderWrapper,
        children: [
            _jsxs("p", {
                children: [
                    _jsx(Logo1IconBorder, {
                        size: "1em",
                        className: "logo"
                    }),
                    title
                ]
            }),
            _jsx("p", {
                children: subTitle
            })
        ]
    });
};
