function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init_color, _init_colors, _init_colorType, _init_hollowCircle, _init_isText, _init_label, _init_menuButton, _init_palettes, _init_pick, _init_tabType, _init_tooltip, _initProto;
import { WithDisposable } from '@blocksuite/global/utils';
import { html, LitElement } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { styleMap } from 'lit/directives/style-map.js';
import { keepColor, preprocessColor } from './utils.js';
_dec = property(), _dec1 = property({
    attribute: false
}), _dec2 = property(), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property(), _dec6 = query('editor-menu-button'), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = state(), _dec10 = property();
export class EdgelessColorPickerButton extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_color, _init_colors, _init_colorType, _init_hollowCircle, _init_isText, _init_label, _init_menuButton, _init_palettes, _init_pick, _init_tabType, _init_tooltip, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "color"
            ],
            [
                _dec1,
                1,
                "colors"
            ],
            [
                _dec2,
                1,
                "colorType"
            ],
            [
                _dec3,
                1,
                "hollowCircle"
            ],
            [
                _dec4,
                1,
                "isText"
            ],
            [
                _dec5,
                1,
                "label"
            ],
            [
                _dec6,
                1,
                "menuButton"
            ],
            [
                _dec7,
                1,
                "palettes"
            ],
            [
                _dec8,
                1,
                "pick"
            ],
            [
                _dec9,
                1,
                "tabType"
            ],
            [
                _dec10,
                1,
                "tooltip"
            ]
        ], []));
    }
    #select;
    get colorWithoutAlpha() {
        return this.isCSSVariable ? this.color : keepColor(this.color);
    }
    get customButtonStyle() {
        let b = 'transparent';
        let c = 'transparent';
        if (!this.isCSSVariable) {
            b = 'var(--affine-background-overlay-panel-color)';
            c = keepColor(this.color);
        }
        return {
            '--b': b,
            '--c': c
        };
    }
    get isCSSVariable() {
        return this.color.startsWith('--');
    }
    get tabContentPadding() {
        return `${this.tabType === 'custom' ? 0 : 8}px`;
    }
    #pick(detail) {
        this.pick?.({
            type: 'start'
        });
        this.pick?.({
            type: 'pick',
            detail
        });
        this.pick?.({
            type: 'end'
        });
    }
    firstUpdated() {
        this.disposables.addFromEvent(this.menuButton, 'toggle', (e)=>{
            const newState = e.detail;
            if (newState === 'hidden' && this.tabType !== 'normal') {
                this.tabType = 'normal';
            }
        });
    }
    render() {
        return html`
      <editor-menu-button
        .contentPadding=${this.tabContentPadding}
        .button=${html`
          <editor-icon-button
            aria-label=${this.label}
            .tooltip=${this.tooltip || this.label}
          >
            ${this.isText ? html`
                  <edgeless-text-color-icon
                    .color=${this.colorWithoutAlpha}
                  ></edgeless-text-color-icon>
                ` : html`
                  <edgeless-color-button
                    .color=${this.colorWithoutAlpha}
                    .hollowCircle=${this.hollowCircle}
                  ></edgeless-color-button>
                `}
          </editor-icon-button>
        `}
      >
        ${choose(this.tabType, [
            [
                'normal',
                ()=>html`
              <div data-orientation="vertical">
                <slot name="other"></slot>
                <slot name="separator"></slot>
                <edgeless-color-panel
                  role="listbox"
                  .value=${this.color}
                  .options=${this.palettes}
                  .hollowCircle=${this.hollowCircle}
                  .openColorPicker=${this.switchToCustomTab}
                  .hasTransparent=${false}
                  @select=${this.#select}
                >
                  <edgeless-color-custom-button
                    slot="custom"
                    style=${styleMap(this.customButtonStyle)}
                    .active=${!this.isCSSVariable}
                    @click=${this.switchToCustomTab}
                  ></edgeless-color-custom-button>
                </edgeless-color-panel>
              </div>
            `
            ],
            [
                'custom',
                ()=>html`
              <edgeless-color-picker
                class="custom"
                .pick=${this.pick}
                .colors=${{
                        type: this.colorType === 'palette' ? 'normal' : this.colorType,
                        modes: this.colors.map(preprocessColor(window.getComputedStyle(this)))
                    }}
              ></edgeless-color-picker>
            `
            ]
        ])}
      </editor-menu-button>
    `;
    }
    #___private_color_1;
    get color() {
        return this.#___private_color_1;
    }
    set color(_v) {
        this.#___private_color_1 = _v;
    }
    #___private_colors_2;
    get colors() {
        return this.#___private_colors_2;
    }
    set colors(_v) {
        this.#___private_colors_2 = _v;
    }
    #___private_colorType_3;
    get colorType() {
        return this.#___private_colorType_3;
    }
    set colorType(_v) {
        this.#___private_colorType_3 = _v;
    }
    #___private_hollowCircle_4;
    get hollowCircle() {
        return this.#___private_hollowCircle_4;
    }
    set hollowCircle(_v) {
        this.#___private_hollowCircle_4 = _v;
    }
    #___private_isText_5;
    get isText() {
        return this.#___private_isText_5;
    }
    set isText(_v) {
        this.#___private_isText_5 = _v;
    }
    #___private_label_6;
    get label() {
        return this.#___private_label_6;
    }
    set label(_v) {
        this.#___private_label_6 = _v;
    }
    #___private_menuButton_7;
    get menuButton() {
        return this.#___private_menuButton_7;
    }
    set menuButton(_v) {
        this.#___private_menuButton_7 = _v;
    }
    #___private_palettes_8;
    get palettes() {
        return this.#___private_palettes_8;
    }
    set palettes(_v) {
        this.#___private_palettes_8 = _v;
    }
    #___private_pick_9;
    get pick() {
        return this.#___private_pick_9;
    }
    set pick(_v) {
        this.#___private_pick_9 = _v;
    }
    #___private_tabType_10;
    get tabType() {
        return this.#___private_tabType_10;
    }
    set tabType(_v) {
        this.#___private_tabType_10 = _v;
    }
    #___private_tooltip_11;
    get tooltip() {
        return this.#___private_tooltip_11;
    }
    set tooltip(_v) {
        this.#___private_tooltip_11 = _v;
    }
    constructor(...args){
        super(...args), this.#select = (e)=>{
            this.#pick({
                palette: e.detail
            });
        }, this.switchToCustomTab = (e)=>{
            e.stopPropagation();
            if (this.colorType === 'palette') {
                this.colorType = 'normal';
            }
            this.tabType = 'custom';
            this.menuButton.show(true);
        }, this.#___private_color_1 = (_initProto(this), _init_color(this)), this.#___private_colors_2 = _init_colors(this, []), this.#___private_colorType_3 = _init_colorType(this, 'palette'), this.#___private_hollowCircle_4 = _init_hollowCircle(this, false), this.#___private_isText_5 = _init_isText(this), this.#___private_label_6 = _init_label(this), this.#___private_menuButton_7 = _init_menuButton(this), this.#___private_palettes_8 = _init_palettes(this, []), this.#___private_pick_9 = _init_pick(this), this.#___private_tabType_10 = _init_tabType(this, 'normal'), this.#___private_tooltip_11 = _init_tooltip(this, undefined);
    }
}
