export const htmlToElement = (html)=>{
    const template = document.createElement('template');
    if (typeof html === 'string') {
        html = html.trim();
        template.innerHTML = html;
    } else {
        const htmlString = String.raw(html.strings, ...html.values);
        template.innerHTML = htmlString;
    }
    return template.content.firstChild;
};
