import { useService } from '@toeverything/infra';
import { useDebouncedState } from 'foxact/use-debounced-state';
import { useEffect } from 'react';
import { WorkspacePropertiesAdapter } from '../../modules/properties';
function getProxy(obj) {
    return new Proxy(obj, {});
}
const useReactiveAdapter = (adapter)=>{
    const [proxy, setProxy] = useDebouncedState(adapter, 0);
    useEffect(()=>{
        function observe() {
            setProxy(getProxy(adapter));
        }
        const disposables = [];
        disposables.push(adapter.workspace.docCollection.meta.docMetaUpdated.on(observe).dispose);
        adapter.properties.observeDeep(observe);
        disposables.push(()=>adapter.properties.unobserveDeep(observe));
        return ()=>{
            for (const dispose of disposables){
                dispose();
            }
        };
    }, [
        adapter,
        setProxy
    ]);
    return proxy;
};
export function useCurrentWorkspacePropertiesAdapter() {
    const adapter = useService(WorkspacePropertiesAdapter);
    return useReactiveAdapter(adapter);
}
