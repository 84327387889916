import { z } from 'zod';
export const DocModes = [
    'edgeless',
    'page'
];
export const ReferenceParamsSchema = z.object({
    mode: z.enum(DocModes),
    blockIds: z.string().array(),
    elementIds: z.string().array()
}).partial();
export const ReferenceInfoSchema = z.object({
    pageId: z.string(),
    params: ReferenceParamsSchema.optional()
});
