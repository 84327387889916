import { FontWeight } from '@blocksuite/affine/blocks';
import { useTheme } from 'next-themes';
function getColorFromMap(color, colorMap, theme = 'light') {
    if (typeof color === 'string') {
        return {
            value: color,
            key: colorMap[color]
        };
    }
    if ('normal' in color) {
        return {
            value: color.normal,
            key: colorMap[color.normal]
        };
    }
    if ('light' in color && 'dark' in color) {
        return {
            value: color[theme],
            key: colorMap[color[theme]]
        };
    }
    return undefined;
}
export const useColor = ()=>{
    const { resolvedTheme } = useTheme();
    return (color, colorMap)=>getColorFromMap(color, colorMap, resolvedTheme);
};
export const sortedFontWeightEntries = Object.entries(FontWeight).sort((a, b)=>Number(a[1]) - Number(b[1]));
