import { Entity, LiveData } from '@toeverything/infra';
export class DocInfoModal extends Entity {
    open(docId) {
        if (docId) {
            this.docId$.next(docId);
        } else {
            this.docId$.next(null);
        }
    }
    close() {
        this.docId$.next(null);
    }
    onOpenChange(open) {
        if (!open) this.docId$.next(null);
    }
    constructor(...args){
        super(...args), this.docId$ = new LiveData(null), this.open$ = LiveData.computed((get)=>!!get(this.docId$));
    }
}
