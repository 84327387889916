import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuItem, MenuTrigger, Slider } from '@affine/component';
import { SettingRow } from '@affine/component/setting-components';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { useI18n } from '@affine/i18n';
import { LineColor, LineColorMap } from '@blocksuite/affine/blocks';
import { useFramework, useLiveData } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
import { DropdownMenu } from '../menu';
import { menuTrigger } from '../style.css';
import { useColor } from '../utils';
import { Point } from './point';
import { EdgelessSnapshot } from './snapshot';
import { getSurfaceBlock } from './utils';
export const PenSettings = ()=>{
    const t = useI18n();
    const framework = useFramework();
    const { editorSetting } = framework.get(EditorSettingService);
    const settings = useLiveData(editorSetting.settings$);
    const getColorFromMap = useColor();
    const currentColor = useMemo(()=>{
        return getColorFromMap(settings.brush.color, LineColorMap);
    }, [
        getColorFromMap,
        settings.brush.color
    ]);
    const colorItems = useMemo(()=>{
        const { color } = settings.brush;
        return Object.entries(LineColor).map(([name, value])=>{
            const handler = ()=>{
                editorSetting.set('brush', {
                    color: value
                });
            };
            const isSelected = color === value;
            return _jsx(MenuItem, {
                onSelect: handler,
                selected: isSelected,
                prefix: _jsx(Point, {
                    color: value
                }),
                children: name
            }, name);
        });
    }, [
        editorSetting,
        settings.brush
    ]);
    const borderThickness = settings.brush.lineWidth;
    const setBorderThickness = useCallback((value)=>{
        editorSetting.set('brush', {
            lineWidth: value[0]
        });
    }, [
        editorSetting
    ]);
    const getElements = useCallback((doc)=>{
        const surface = getSurfaceBlock(doc);
        return surface?.getElementsByType('brush') || [];
    }, []);
    return _jsxs(_Fragment, {
        children: [
            _jsx(EdgelessSnapshot, {
                title: t['com.affine.settings.editorSettings.edgeless.pen'](),
                docName: "pen",
                keyName: "brush",
                getElements: getElements
            }),
            _jsx(SettingRow, {
                name: t['com.affine.settings.editorSettings.edgeless.pen.color'](),
                desc: '',
                children: currentColor ? _jsx(DropdownMenu, {
                    items: colorItems,
                    trigger: _jsx(MenuTrigger, {
                        className: menuTrigger,
                        prefix: _jsx(Point, {
                            color: currentColor.value
                        }),
                        children: currentColor.key
                    })
                }) : null
            }),
            _jsx(SettingRow, {
                name: t['com.affine.settings.editorSettings.edgeless.pen.thickness'](),
                desc: '',
                children: _jsx(Slider, {
                    value: [
                        borderThickness
                    ],
                    onValueChange: setBorderThickness,
                    min: 2,
                    max: 12,
                    step: 2,
                    nodes: [
                        2,
                        4,
                        6,
                        8,
                        10,
                        12
                    ]
                })
            })
        ]
    });
};
