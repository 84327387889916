export const getSelectedModelsCommand = (ctx, next)=>{
    const types = ctx.types ?? [
        'block',
        'text',
        'image'
    ];
    const mode = ctx.mode ?? 'flat';
    const selectedModels = [];
    ctx.std.command.chain().tryAll((chain)=>[
            chain.getTextSelection(),
            chain.getBlockSelections(),
            chain.getImageSelections()
        ]).getSelectedBlocks({
        types,
        mode
    }).inline((ctx)=>{
        const { selectedBlocks = [] } = ctx;
        selectedModels.push(...selectedBlocks.map((el)=>el.model));
    }).run();
    next({
        selectedModels
    });
};
