import { toast as basicToast } from '@affine/component';
import { assertEquals } from '@blocksuite/affine/global/utils';
export const toast = (message, options)=>{
    const modal = document.querySelector('[role=presentation]');
    if (modal) {
        assertEquals(modal.constructor, HTMLDivElement, 'modal should be div');
    }
    return basicToast(message, {
        portal: modal || document.body,
        ...options
    });
};
