import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { LifeCycleWatcherIdentifier, StdIdentifier } from '../identifier.js';
import { Extension } from './extension.js';
export class LifeCycleWatcher extends Extension {
    constructor(std){
        super(), this.std = std;
    }
    static setup(di) {
        if (!this.key) {
            throw new BlockSuiteError(ErrorCode.ValueNotExists, 'Key is not defined in the LifeCycleWatcher');
        }
        di.add(this, [
            StdIdentifier
        ]);
        di.addImpl(LifeCycleWatcherIdentifier(this.key), (provider)=>provider.get(this));
    }
    created() {}
    mounted() {}
    rendered() {}
    unmounted() {}
}
