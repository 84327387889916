import { gqlFetcherFactory } from '@affine/graphql';
import { fromPromise, Service } from '@toeverything/infra';
import { BackendError } from '../error';
import { AuthService } from './auth';
export class GraphQLService extends Service {
    constructor(fetcher){
        super(), this.fetcher = fetcher, this.rawGql = gqlFetcherFactory('/graphql', this.fetcher.fetch), this.rawGqlV2 = gqlFetcherFactory('/api/v1/graphql', this.fetcher.fetch), this.rxGql = (options)=>{
            return fromPromise((signal)=>{
                return this.gql({
                    ...options,
                    context: {
                        signal,
                        ...options.context
                    }
                });
            });
        }, this.gql = async (options)=>{
            try {
                return await this.rawGql(options);
            } catch (err) {
                if (err instanceof BackendError && err.status === 403) {
                    this.framework.get(AuthService).session.revalidate();
                }
                throw err;
            }
        }, this.exec = async (options)=>{
            try {
                return await this.rawGqlV2(options);
            } catch (err) {
                const standardError = UserFriendlyError.fromAnyError(err);
                if (standardError.status === 403) {
                    this.framework.get(AuthService).session.revalidate();
                }
                throw new BackendError(standardError);
            }
        };
    }
}
