import { DebugLogger } from '@affine/debug';
import { Unreachable } from '@affine/env/constant';
import { groupBy } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { applyUpdate, encodeStateAsUpdate, mergeUpdates } from 'yjs';
import { LiveData } from '../../livedata';
import { throwIfAborted } from '../../utils';
import { AsyncPriorityQueue } from './async-priority-queue';
import { isEmptyUpdate } from './utils';
const DOC_ENGINE_ORIGIN = 'doc-engine';
const logger = new DebugLogger('doc-engine:local');
export class DocEngineLocalPart {
    docState$(docId) {
        return LiveData.from(new Observable((subscribe)=>{
            const next = ()=>{
                subscribe.next({
                    ready: this.status.readyDocs.has(docId) ?? false,
                    loading: this.status.connectedDocs.has(docId),
                    syncing: (this.status.jobMap.get(docId)?.length ?? 0) > 0 || this.status.currentJob?.docId === docId
                });
            };
            next();
            return this.statusUpdatedSubject$.subscribe((updatedId)=>{
                if (updatedId === docId) next();
            });
        }), {
            ready: false,
            loading: false,
            syncing: false
        });
    }
    constructor(clientId, storage){
        this.clientId = clientId;
        this.storage = storage;
        this.prioritySettings = new Map();
        this.statusUpdatedSubject$ = new Subject();
        this.status = {
            docs: new Map(),
            connectedDocs: new Set(),
            readyDocs: new Set(),
            jobDocQueue: new AsyncPriorityQueue(),
            jobMap: new Map(),
            currentJob: null
        };
        this.engineState$ = LiveData.from(new Observable((subscribe)=>{
            const next = ()=>{
                subscribe.next({
                    total: this.status.docs.size,
                    syncing: this.status.jobMap.size + (this.status.currentJob ? 1 : 0)
                });
            };
            next();
            return this.statusUpdatedSubject$.subscribe(()=>{
                next();
            });
        }), {
            syncing: 0,
            total: 0
        });
        this.actions = {
            addDoc: (doc)=>{
                this.schedule({
                    type: 'load',
                    docId: doc.guid
                });
                this.status.docs.set(doc.guid, doc);
                this.statusUpdatedSubject$.next(doc.guid);
            },
            markAsReady: (docId)=>{
                this.status.readyDocs.add(docId);
                this.statusUpdatedSubject$.next(docId);
            }
        };
        this.jobs = {
            load: async (job, signal)=>{
                const doc = this.status.docs.get(job.docId);
                if (!doc) {
                    throw new Unreachable('doc not found');
                }
                const existingData = encodeStateAsUpdate(doc);
                if (!isEmptyUpdate(existingData)) {
                    this.schedule({
                        type: 'save',
                        docId: doc.guid,
                        update: existingData
                    });
                }
                doc.emit('sync', [
                    true,
                    doc
                ]);
                doc.on('update', this.handleDocUpdate);
                this.status.connectedDocs.add(job.docId);
                this.statusUpdatedSubject$.next(job.docId);
                const docData = await this.storage.loadDocFromLocal(job.docId, signal);
                if (!docData || isEmptyUpdate(docData)) {
                    return;
                }
                this.applyUpdate(job.docId, docData);
                this.status.readyDocs.add(job.docId);
                this.statusUpdatedSubject$.next(job.docId);
            },
            save: async (docId, jobs, signal)=>{
                if (this.status.connectedDocs.has(docId)) {
                    const merged = mergeUpdates(jobs.map((j)=>j.update).filter((update)=>!isEmptyUpdate(update)));
                    const newSeqNum = await this.storage.commitDocAsClientUpdate(docId, merged, signal);
                    this.storage.eventBus.emit({
                        type: 'ClientUpdateCommitted',
                        seqNum: newSeqNum,
                        docId: docId,
                        clientId: this.clientId,
                        update: merged
                    });
                }
            },
            apply: async (job, signal)=>{
                throwIfAborted(signal);
                if (this.status.connectedDocs.has(job.docId)) {
                    this.applyUpdate(job.docId, job.update);
                }
                if (job.isInitialize && !isEmptyUpdate(job.update)) {
                    this.status.readyDocs.add(job.docId);
                    this.statusUpdatedSubject$.next(job.docId);
                }
            }
        };
        this.events = {
            ServerUpdateCommitted: ({ docId, update, clientId })=>{
                this.schedule({
                    type: 'apply',
                    docId,
                    update,
                    isInitialize: clientId === this.clientId
                });
            },
            ClientUpdateCommitted: ({ docId, update, clientId })=>{
                if (clientId !== this.clientId) {
                    this.schedule({
                        type: 'apply',
                        docId,
                        update,
                        isInitialize: false
                    });
                }
            }
        };
        this.handleDocUpdate = (update, origin, doc)=>{
            if (origin === DOC_ENGINE_ORIGIN) {
                return;
            }
            this.schedule({
                type: 'save',
                docId: doc.guid,
                update
            });
        };
    }
    async mainLoop(signal) {
        const dispose = this.storage.eventBus.on((event)=>{
            const handler = this.events[event.type];
            if (handler) {
                handler(event);
            }
        });
        try {
            while(true){
                throwIfAborted(signal);
                const docId = await this.status.jobDocQueue.asyncPop(signal);
                const jobs = this.status.jobMap.get(docId);
                this.status.jobMap.delete(docId);
                if (!jobs) {
                    continue;
                }
                this.status.currentJob = {
                    docId,
                    jobs
                };
                this.statusUpdatedSubject$.next(docId);
                const { apply, load, save } = groupBy(jobs, (job)=>job.type);
                if (load?.length) {
                    await this.jobs.load(load[0], signal);
                }
                for (const applyJob of apply ?? []){
                    await this.jobs.apply(applyJob, signal);
                }
                if (save?.length) {
                    await this.jobs.save(docId, save, signal);
                }
                this.status.currentJob = null;
                this.statusUpdatedSubject$.next(docId);
            }
        } finally{
            dispose();
            for (const docs of this.status.connectedDocs){
                const doc = this.status.docs.get(docs);
                if (doc) {
                    doc.off('update', this.handleDocUpdate);
                }
            }
        }
    }
    applyUpdate(docId, update) {
        const doc = this.status.docs.get(docId);
        if (doc && !isEmptyUpdate(update)) {
            try {
                applyUpdate(doc, update, DOC_ENGINE_ORIGIN);
            } catch (err) {
                logger.error('failed to apply update yjs doc', err);
            }
        }
    }
    schedule(job) {
        const priority = this.prioritySettings.get(job.docId) ?? 0;
        this.status.jobDocQueue.push(job.docId, priority);
        const existingJobs = this.status.jobMap.get(job.docId) ?? [];
        existingJobs.push(job);
        this.status.jobMap.set(job.docId, existingJobs);
        this.statusUpdatedSubject$.next(job.docId);
    }
    setPriority(docId, priority) {
        this.prioritySettings.set(docId, priority);
        this.status.jobDocQueue.updatePriority(docId, priority);
    }
}
