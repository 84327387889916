import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class Invoices extends Entity {
    constructor(store){
        super(), this.store = store, this.pageNum$ = new LiveData(0), this.invoiceCount$ = new LiveData(undefined), this.pageInvoices$ = new LiveData(undefined), this.isLoading$ = new LiveData(false), this.error$ = new LiveData(null), this.PAGE_SIZE = 8, this.revalidate = effect(map(()=>this.pageNum$.value), exhaustMapSwitchUntilChanged((a, b)=>a === b, (pageNum)=>{
            return fromPromise(async (signal)=>{
                return this.store.fetchInvoices(pageNum * this.PAGE_SIZE, this.PAGE_SIZE, signal);
            }).pipe(mergeMap((data)=>{
                this.invoiceCount$.setValue(data.invoiceCount);
                this.pageInvoices$.setValue(data.invoices);
                return EMPTY;
            }), backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), catchErrorInto(this.error$), onStart(()=>{
                this.pageInvoices$.setValue(undefined);
                this.isLoading$.setValue(true);
            }), onComplete(()=>this.isLoading$.setValue(false)));
        }));
    }
    setPageNum(pageNum) {
        this.pageNum$.setValue(pageNum);
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
