import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ScrollableContainer } from '@affine/component';
import { Divider } from '@affine/component/ui/divider';
import { openSettingModalAtom } from '@affine/core/components/atoms';
import { useEnableCloud } from '@affine/core/components/hooks/affine/use-enable-cloud';
import { AuthService } from '@affine/core/modules/cloud';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { CloudWorkspaceIcon, LocalWorkspaceIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { WorkspaceCard } from '../../workspace-card';
import * as styles from './index.css';
const CloudWorkSpaceList = ({ workspaces, onClickWorkspace, onClickWorkspaceSetting })=>{
    const t = useI18n();
    if (workspaces.length === 0) {
        return null;
    }
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            _jsxs("div", {
                className: styles.workspaceType,
                children: [
                    _jsx(CloudWorkspaceIcon, {
                        width: 14,
                        height: 14,
                        className: styles.workspaceTypeIcon
                    }),
                    t['com.affine.workspaceList.workspaceListType.cloud']()
                ]
            }),
            _jsx(WorkspaceList, {
                items: workspaces,
                onClick: onClickWorkspace,
                onSettingClick: onClickWorkspaceSetting
            })
        ]
    });
};
const LocalWorkspaces = ({ workspaces, onClickWorkspace, onClickWorkspaceSetting, onClickEnableCloud })=>{
    const t = useI18n();
    if (workspaces.length === 0) {
        return null;
    }
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            _jsxs("div", {
                className: styles.workspaceType,
                children: [
                    _jsx(LocalWorkspaceIcon, {
                        width: 14,
                        height: 14,
                        className: styles.workspaceTypeIcon
                    }),
                    t['com.affine.workspaceList.workspaceListType.local']()
                ]
            }),
            _jsx(WorkspaceList, {
                items: workspaces,
                onClick: onClickWorkspace,
                onSettingClick: onClickWorkspaceSetting,
                onEnableCloudClick: onClickEnableCloud
            })
        ]
    });
};
export const AFFiNEWorkspaceList = ({ onEventEnd, onClickWorkspace, showEnableCloudButton, showSettingsButton })=>{
    const workspacesService = useService(WorkspacesService);
    const workspaces = useLiveData(workspacesService.list.workspaces$);
    const confirmEnableCloud = useEnableCloud();
    const setOpenSettingModalAtom = useSetAtom(openSettingModalAtom);
    const session = useService(AuthService).session;
    const status = useLiveData(session.status$);
    const isAuthenticated = status === 'authenticated';
    const cloudWorkspaces = useMemo(()=>workspaces.filter(({ flavour })=>flavour === WorkspaceFlavour.AFFINE_CLOUD), [
        workspaces
    ]);
    const localWorkspaces = useMemo(()=>workspaces.filter(({ flavour })=>flavour === WorkspaceFlavour.LOCAL), [
        workspaces
    ]);
    const onClickWorkspaceSetting = useCallback((workspaceMetadata)=>{
        setOpenSettingModalAtom({
            open: true,
            activeTab: 'workspace:preference',
            workspaceMetadata
        });
        onEventEnd?.();
    }, [
        onEventEnd,
        setOpenSettingModalAtom
    ]);
    const onClickEnableCloud = useCallback((meta)=>{
        const { workspace, dispose } = workspacesService.open({
            metadata: meta
        });
        confirmEnableCloud(workspace, {
            onFinished: ()=>{
                dispose();
            }
        });
    }, [
        confirmEnableCloud,
        workspacesService
    ]);
    const handleClickWorkspace = useCallback((workspaceMetadata)=>{
        onClickWorkspace?.(workspaceMetadata);
        onEventEnd?.();
    }, [
        onClickWorkspace,
        onEventEnd
    ]);
    return _jsxs(ScrollableContainer, {
        className: styles.workspaceListsWrapper,
        scrollBarClassName: styles.scrollbar,
        children: [
            isAuthenticated ? _jsxs("div", {
                children: [
                    _jsx(CloudWorkSpaceList, {
                        workspaces: cloudWorkspaces,
                        onClickWorkspace: handleClickWorkspace,
                        onClickWorkspaceSetting: showSettingsButton ? onClickWorkspaceSetting : undefined
                    }),
                    localWorkspaces.length > 0 && cloudWorkspaces.length > 0 ? _jsx(Divider, {
                        size: "thinner"
                    }) : null
                ]
            }) : null,
            _jsx(LocalWorkspaces, {
                workspaces: localWorkspaces,
                onClickWorkspace: handleClickWorkspace,
                onClickWorkspaceSetting: showSettingsButton ? onClickWorkspaceSetting : undefined,
                onClickEnableCloud: showEnableCloudButton ? onClickEnableCloud : undefined
            })
        ]
    });
};
const SortableWorkspaceItem = ({ workspaceMetadata, onClick, onSettingClick, onEnableCloudClick })=>{
    const handleClick = useCallback(()=>{
        onClick(workspaceMetadata);
    }, [
        onClick,
        workspaceMetadata
    ]);
    return _jsx(WorkspaceCard, {
        className: styles.workspaceCard,
        workspaceMetadata: workspaceMetadata,
        onClick: handleClick,
        avatarSize: 28,
        onClickOpenSettings: onSettingClick,
        onClickEnableCloud: onEnableCloudClick
    });
};
export const WorkspaceList = (props)=>{
    const workspaceList = props.items;
    return workspaceList.map((item)=>_jsx(SortableWorkspaceItem, {
            ...props,
            workspaceMetadata: item
        }, item.id));
};
