import { DebugLogger } from '@affine/debug';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, mapInto, onComplete, onStart } from '@toeverything/infra';
import { exhaustMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
const logger = new DebugLogger('affine:workspace-permission');
export class WorkspacePermission extends Entity {
    constructor(workspaceService, store){
        super(), this.workspaceService = workspaceService, this.store = store, this.isOwner$ = new LiveData(null), this.isLoading$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(exhaustMap(()=>{
            return fromPromise(async (signal)=>{
                if (this.workspaceService.workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD) {
                    return await this.store.fetchIsOwner(this.workspaceService.workspace.id, signal);
                } else {
                    return true;
                }
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mapInto(this.isOwner$), catchErrorInto(this.error$, (error)=>{
                logger.error('Failed to fetch isOwner', error);
            }), onStart(()=>this.isLoading$.setValue(true)), onComplete(()=>this.isLoading$.setValue(false)));
        }));
    }
}
