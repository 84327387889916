function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init__editing, _init__frameTitle, _init__frameTitleEl, _init__isNavigator, _init__nestedFrame, _init__xywh, _init__zoom, _init_doc, _init_model, _init_std, _initProto, _dec10, _dec11, _dec12, _init__isNavigator1, _init_showBorder, _init_titleElement, _initProto1;
import { ColorScheme, FrameBlockModel } from '@blocksuite/affine-model';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { docContext, GfxBlockComponent, modelContext, ShadowlessElement, stdContext } from '@blocksuite/block-std';
import { GfxControllerIdentifier } from '@blocksuite/block-std/gfx';
import { SignalWatcher, WithDisposable } from '@blocksuite/global/utils';
import { Bound } from '@blocksuite/global/utils';
import { consume } from '@lit/context';
import { cssVarV2 } from '@toeverything/theme/v2';
import { css, html, nothing, unsafeCSS } from 'lit';
import { query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { parseStringToRgba } from '../root-block/edgeless/components/color-picker/utils.js';
import { isTransparent } from '../root-block/edgeless/components/panel/color-panel.js';
export const frameTitleStyleVars = {
    nestedFrameOffset: 4,
    height: 22,
    fontSize: 14
};
_dec = state(), _dec1 = state(), _dec2 = query('.affine-frame-title'), _dec3 = state(), _dec4 = state(), _dec5 = state(), _dec6 = state(), _dec7 = consume({
    context: docContext
}), _dec8 = consume({
    context: modelContext
}), _dec9 = consume({
    context: stdContext
});
export class EdgelessFrameTitle extends SignalWatcher(WithDisposable(ShadowlessElement)) {
    static{
        ({ e: [_init__editing, _init__frameTitle, _init__frameTitleEl, _init__isNavigator, _init__nestedFrame, _init__xywh, _init__zoom, _init_doc, _init_model, _init_std, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_editing"
            ],
            [
                _dec1,
                1,
                "_frameTitle"
            ],
            [
                _dec2,
                1,
                "_frameTitleEl"
            ],
            [
                _dec3,
                1,
                "_isNavigator"
            ],
            [
                _dec4,
                1,
                "_nestedFrame"
            ],
            [
                _dec5,
                1,
                "_xywh"
            ],
            [
                _dec6,
                1,
                "_zoom"
            ],
            [
                _dec7,
                1,
                "doc"
            ],
            [
                _dec8,
                1,
                "model"
            ],
            [
                _dec9,
                1,
                "std"
            ]
        ], []));
    }
    static{
        this.styles = css`
    edgeless-frame-title {
      position: relative;
    }

    .affine-frame-title {
      position: absolute;
      display: flex;
      align-items: center;
      z-index: 1;
      left: 0px;
      top: 0px;
      border: 1px solid ${unsafeCSS(cssVarV2('edgeless/frame/border/default'))};
      border-radius: 4px;
      width: fit-content;
      height: ${frameTitleStyleVars.height}px;
      padding: 0px 4px;
      transform-origin: left bottom;
      background-color: var(--bg-color);

      span {
        font-family: var(--affine-font-family);
        font-size: ${frameTitleStyleVars.fontSize}px;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .affine-frame-title:hover {
      background-color: color-mix(in srgb, var(--bg-color), #000000 7%);
    }
  `;
    }
    get colors() {
        let backgroundColor = ThemeObserver.getColorValue(this.model.background, undefined, true);
        if (isTransparent(backgroundColor)) {
            backgroundColor = ThemeObserver.getPropertyValue(cssVarV2('edgeless/frame/background/white').replace(/var\((--.*)\)/, '$1'));
        }
        const { r, g, b, a } = parseStringToRgba(backgroundColor);
        let textColor;
        {
            let rPrime, gPrime, bPrime;
            if (ThemeObserver.instance.mode$.value === ColorScheme.Light) {
                rPrime = 1 - a + a * r;
                gPrime = 1 - a + a * g;
                bPrime = 1 - a + a * b;
            } else {
                rPrime = a * r;
                gPrime = a * g;
                bPrime = a * b;
            }
            const L = 0.299 * rPrime + 0.587 * gPrime + 0.114 * bPrime;
            textColor = L > 0.5 ? 'black' : 'white';
        }
        return {
            background: backgroundColor,
            text: textColor
        };
    }
    get gfx() {
        return this.std.get(GfxControllerIdentifier);
    }
    get rootService() {
        return this.std.getService('affine:page');
    }
    _isInsideFrame() {
        return this.gfx.grid.has(this.model.elementBound, true, true, (model)=>model !== this.model && model instanceof FrameBlockModel);
    }
    _updateFrameTitleSize() {
        const { _nestedFrame, _zoom: zoom } = this;
        const { elementBound } = this.model;
        const width = this._cachedWidth / zoom;
        const height = this._cachedHeight / zoom;
        const { nestedFrameOffset } = frameTitleStyleVars;
        if (width && height) {
            this.model.externalXYWH = `[${elementBound.x + (_nestedFrame ? nestedFrameOffset / zoom : 0)},${elementBound.y + (_nestedFrame ? nestedFrameOffset / zoom : -(height + nestedFrameOffset / zoom))},${width},${height}]`;
            this.gfx.grid.update(this.model);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const { _disposables, doc, gfx, rootService } = this;
        this._nestedFrame = this._isInsideFrame();
        _disposables.add(doc.slots.blockUpdated.on((payload)=>{
            if (payload.type === 'update' && payload.props.key === 'xywh' && doc.getBlock(payload.id)?.model instanceof FrameBlockModel || payload.type === 'add' && payload.flavour === 'affine:frame') {
                this._nestedFrame = this._isInsideFrame();
            }
            if (payload.type === 'delete' && payload.model instanceof FrameBlockModel && payload.model !== this.model) {
                this._nestedFrame = this._isInsideFrame();
            }
        }));
        _disposables.add(this.model.propsUpdated.on(()=>{
            this._xywh = this.model.xywh;
            this.requestUpdate();
        }));
        _disposables.add(rootService.selection.slots.updated.on(()=>{
            this._editing = rootService.selection.selectedIds[0] === this.model.id && rootService.selection.editing;
        }));
        _disposables.add(rootService.slots.edgelessToolUpdated.on((tool)=>{
            this._isNavigator = tool.type === 'frameNavigator';
        }));
        _disposables.add(gfx.viewport.viewportUpdated.on(({ zoom })=>{
            this._zoom = zoom;
        }));
        this._zoom = gfx.viewport.zoom;
        const updateTitle = ()=>{
            this._frameTitle = this.model.title.toString().trim();
        };
        _disposables.add(()=>{
            this.model.title.yText.unobserve(updateTitle);
        });
        this.model.title.yText.observe(updateTitle);
        this._frameTitle = this.model.title.toString().trim();
        this._xywh = this.model.xywh;
    }
    firstUpdated() {
        if (!this._frameTitleEl) return;
        this._cachedWidth = this._frameTitleEl.clientWidth;
        this._cachedHeight = this._frameTitleEl.clientHeight;
        this._updateFrameTitleSize();
    }
    render() {
        const model = this.model;
        const bound = Bound.deserialize(model.xywh);
        const { _isNavigator, _editing, _zoom: zoom } = this;
        const { nestedFrameOffset, height } = frameTitleStyleVars;
        const nestedFrame = this._nestedFrame;
        const maxWidth = nestedFrame ? bound.w * zoom - nestedFrameOffset / zoom : bound.w * zoom;
        const hidden = height / zoom >= bound.h;
        const transformOperation = [
            `translate(0%, ${nestedFrame ? 0 : -100}%)`,
            `scale(${1 / zoom})`,
            `translate(${nestedFrame ? nestedFrameOffset : 0}px, ${nestedFrame ? nestedFrameOffset : -nestedFrameOffset}px)`
        ];
        return html`
      ${this._frameTitle && this._frameTitle.length !== 0 && !_isNavigator && !_editing ? html`
            <div
              style=${styleMap({
            '--bg-color': this.colors.background,
            display: hidden ? 'none' : 'flex',
            transform: transformOperation.join(' '),
            maxWidth: maxWidth + 'px',
            transformOrigin: nestedFrame ? 'top left' : 'bottom left',
            color: this.colors.text
        })}
              class="affine-frame-title"
            >
              <span>${this._frameTitle}</span>
            </div>
          ` : nothing}
    `;
    }
    updated(_changedProperties) {
        if (!this.gfx.viewport.viewportBounds.contains(this.model.elementBound) && !this.gfx.viewport.viewportBounds.isIntersectWithBound(this.model.elementBound) || !this._frameTitleEl) {
            return;
        }
        let sizeChanged = false;
        if (this._cachedWidth === 0 || this._cachedHeight === 0 || _changedProperties.has('_frameTitle') || _changedProperties.has('_nestedFrame') || _changedProperties.has('_xywh')) {
            this._cachedWidth = this._frameTitleEl.clientWidth;
            this._cachedHeight = this._frameTitleEl.clientHeight;
            sizeChanged = true;
        }
        if (sizeChanged || _changedProperties.has('_zoom')) {
            this._updateFrameTitleSize();
        }
    }
    #___private__editing_1;
    get _editing() {
        return this.#___private__editing_1;
    }
    set _editing(_v) {
        this.#___private__editing_1 = _v;
    }
    #___private__frameTitle_2;
    get _frameTitle() {
        return this.#___private__frameTitle_2;
    }
    set _frameTitle(_v) {
        this.#___private__frameTitle_2 = _v;
    }
    #___private__frameTitleEl_3;
    get _frameTitleEl() {
        return this.#___private__frameTitleEl_3;
    }
    set _frameTitleEl(_v) {
        this.#___private__frameTitleEl_3 = _v;
    }
    #___private__isNavigator_4;
    get _isNavigator() {
        return this.#___private__isNavigator_4;
    }
    set _isNavigator(_v) {
        this.#___private__isNavigator_4 = _v;
    }
    #___private__nestedFrame_5;
    get _nestedFrame() {
        return this.#___private__nestedFrame_5;
    }
    set _nestedFrame(_v) {
        this.#___private__nestedFrame_5 = _v;
    }
    #___private__xywh_6;
    get _xywh() {
        return this.#___private__xywh_6;
    }
    set _xywh(_v) {
        this.#___private__xywh_6 = _v;
    }
    #___private__zoom_7;
    get _zoom() {
        return this.#___private__zoom_7;
    }
    set _zoom(_v) {
        this.#___private__zoom_7 = _v;
    }
    #___private_doc_8;
    get doc() {
        return this.#___private_doc_8;
    }
    set doc(_v) {
        this.#___private_doc_8 = _v;
    }
    #___private_model_9;
    get model() {
        return this.#___private_model_9;
    }
    set model(_v) {
        this.#___private_model_9 = _v;
    }
    #___private_std_10;
    get std() {
        return this.#___private_std_10;
    }
    set std(_v) {
        this.#___private_std_10 = _v;
    }
    constructor(...args){
        super(...args), this._cachedHeight = 0, this._cachedWidth = 0, this.#___private__editing_1 = (_initProto(this), _init__editing(this, false)), this.#___private__frameTitle_2 = _init__frameTitle(this, ''), this.#___private__frameTitleEl_3 = _init__frameTitleEl(this), this.#___private__isNavigator_4 = _init__isNavigator(this, false), this.#___private__nestedFrame_5 = _init__nestedFrame(this, false), this.#___private__xywh_6 = _init__xywh(this, null), this.#___private__zoom_7 = _init__zoom(this), this.#___private_doc_8 = _init_doc(this), this.#___private_model_9 = _init_model(this), this.#___private_std_10 = _init_std(this);
    }
}
_dec10 = state(), _dec11 = state(), _dec12 = query('edgeless-frame-title');
export class FrameBlockComponent extends GfxBlockComponent {
    static{
        ({ e: [_init__isNavigator1, _init_showBorder, _init_titleElement, _initProto1] } = _apply_decs_2203_r(this, [
            [
                _dec10,
                1,
                "_isNavigator"
            ],
            [
                _dec11,
                1,
                "showBorder"
            ],
            [
                _dec12,
                1,
                "titleElement"
            ]
        ], []));
    }
    get rootService() {
        return this.std.getService('affine:page');
    }
    connectedCallback() {
        super.connectedCallback();
        this._disposables.add(this.doc.slots.blockUpdated.on(({ type, id })=>{
            if (id === this.model.id && type === 'update') {
                this.requestUpdate();
            }
        }));
    }
    firstUpdated() {
        this.rootService.slots.edgelessToolUpdated.on((tool)=>{
            this._isNavigator = tool.type === 'frameNavigator';
        });
    }
    renderGfxBlock() {
        const { model, _isNavigator, showBorder, rootService } = this;
        const backgroundColor = ThemeObserver.generateColorProperty(model.background, '--affine-platte-transparent');
        const frameIndex = rootService.layer.getZIndex(model);
        return html`
      <edgeless-frame-title
        style=${styleMap({
            zIndex: 2147483647 - -frameIndex
        })}
      ></edgeless-frame-title>
      <div
        class="affine-frame-container"
        style=${styleMap({
            zIndex: `${frameIndex}`,
            backgroundColor,
            height: '100%',
            width: '100%',
            borderRadius: '2px',
            border: _isNavigator || !showBorder ? 'none' : `1px solid ${cssVarV2('edgeless/frame/border/default')}`
        })}
      ></div>
    `;
    }
    toZIndex() {
        return 'auto';
    }
    #___private__isNavigator_11;
    get _isNavigator() {
        return this.#___private__isNavigator_11;
    }
    set _isNavigator(_v) {
        this.#___private__isNavigator_11 = _v;
    }
    #___private_showBorder_12;
    get showBorder() {
        return this.#___private_showBorder_12;
    }
    set showBorder(_v) {
        this.#___private_showBorder_12 = _v;
    }
    #___private_titleElement_13;
    get titleElement() {
        return this.#___private_titleElement_13;
    }
    set titleElement(_v) {
        this.#___private_titleElement_13 = _v;
    }
    constructor(...args){
        super(...args), this.#___private__isNavigator_11 = (_initProto1(this), _init__isNavigator1(this, false)), this.#___private_showBorder_12 = _init_showBorder(this, true), this.#___private_titleElement_13 = _init_titleElement(this, null);
    }
}
