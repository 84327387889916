function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _init__richTextElement, _initProto;
import { CaptionedBlockComponent } from '@blocksuite/affine-components/caption';
import { focusTextModel } from '@blocksuite/affine-components/rich-text';
import { toast } from '@blocksuite/affine-components/toast';
import { BRACKET_PAIRS, NOTE_SELECTOR } from '@blocksuite/affine-shared/consts';
import { getViewportElement } from '@blocksuite/affine-shared/utils';
import { getInlineRangeProvider } from '@blocksuite/block-std';
import { IS_MAC } from '@blocksuite/global/env';
import { noop } from '@blocksuite/global/utils';
import { INLINE_ROOT_ATTR } from '@blocksuite/inline';
import { Slice } from '@blocksuite/store';
import { computed, effect, signal } from '@preact/signals-core';
import { html, nothing } from 'lit';
import { query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { EdgelessRootBlockComponent } from '../root-block/edgeless/edgeless-root-block.js';
import { CodeClipboardController } from './clipboard/index.js';
import { CodeBlockInlineManagerExtension } from './code-block-inline.js';
import { codeBlockStyles } from './styles.js';
_dec = query('rich-text');
export class CodeBlockComponent extends CaptionedBlockComponent {
    static{
        ({ e: [_init__richTextElement, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_richTextElement"
            ]
        ], []));
    }
    static{
        this.styles = codeBlockStyles;
    }
    get inlineEditor() {
        const inlineRoot = this.querySelector(`[${INLINE_ROOT_ATTR}]`);
        return inlineRoot?.inlineEditor;
    }
    get inlineManager() {
        return this.std.get(CodeBlockInlineManagerExtension.identifier);
    }
    get readonly() {
        return this.doc.readonly;
    }
    get topContenteditableElement() {
        if (this.rootComponent instanceof EdgelessRootBlockComponent) {
            const el = this.closest(NOTE_SELECTOR);
            return el;
        }
        return this.rootComponent;
    }
    _updateHighlightTokens() {
        const modelLang = this.model.language$.value;
        if (modelLang === null) {
            this.highlightTokens$.value = [];
            return;
        }
        const matchedInfo = this.service.langs.find((info)=>info.id === modelLang || info.name === modelLang || info.aliases?.includes(modelLang));
        if (matchedInfo) {
            this.model.language$.value = matchedInfo.id;
            const langImport = matchedInfo.import;
            const lang = matchedInfo.id;
            const highlighter = this.service.highlighter$.value;
            const theme = this.service.themeKey;
            if (!theme || !highlighter) {
                this.highlightTokens$.value = [];
                return;
            }
            noop(this.model.text.deltas$.value);
            const code = this.model.text.toString();
            const loadedLanguages = highlighter.getLoadedLanguages();
            if (!loadedLanguages.includes(lang)) {
                highlighter.loadLanguage(langImport).then(()=>{
                    this.highlightTokens$.value = highlighter.codeToTokensBase(code, {
                        lang,
                        theme
                    });
                }).catch(console.error);
            } else {
                this.highlightTokens$.value = highlighter.codeToTokensBase(code, {
                    lang,
                    theme
                });
            }
        } else {
            this.highlightTokens$.value = [];
            this.model.language$.value = null;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.clipboardController.hostConnected();
        this.disposables.add(effect(()=>{
            this._updateHighlightTokens();
        }));
        this.disposables.add(effect(()=>{
            noop(this.highlightTokens$.value);
            this._richTextElement?.inlineEditor?.render();
        }));
        const selectionManager = this.host.selection;
        const INDENT_SYMBOL = '  ';
        const LINE_BREAK_SYMBOL = '\n';
        const allIndexOf = (text, symbol, start = 0, end = text.length)=>{
            const indexArr = [];
            let i = start;
            while(i < end){
                const index = text.indexOf(symbol, i);
                if (index === -1 || index > end) {
                    break;
                }
                indexArr.push(index);
                i = index + 1;
            }
            return indexArr;
        };
        this.bindHotKey({
            Backspace: (ctx)=>{
                const state = ctx.get('keyboardState');
                const textSelection = selectionManager.find('text');
                if (!textSelection) {
                    state.raw.preventDefault();
                    return;
                }
                const from = textSelection.from;
                if (from.index === 0 && from.length === 0) {
                    state.raw.preventDefault();
                    selectionManager.setGroup('note', [
                        selectionManager.create('block', {
                            blockId: this.blockId
                        })
                    ]);
                    return true;
                }
                const inlineEditor = this.inlineEditor;
                const inlineRange = inlineEditor?.getInlineRange();
                if (!inlineRange || !inlineEditor) return;
                const left = inlineEditor.yText.toString()[inlineRange.index - 1];
                const right = inlineEditor.yText.toString()[inlineRange.index];
                const leftBrackets = BRACKET_PAIRS.map((pair)=>pair.left);
                if (BRACKET_PAIRS[leftBrackets.indexOf(left)]?.right === right) {
                    const index = inlineRange.index - 1;
                    inlineEditor.deleteText({
                        index: index,
                        length: 2
                    });
                    inlineEditor.setInlineRange({
                        index: index,
                        length: 0
                    });
                    state.raw.preventDefault();
                    return true;
                }
                return;
            },
            Tab: (ctx)=>{
                if (this.doc.readonly) return;
                const state = ctx.get('keyboardState');
                const event = state.raw;
                const inlineEditor = this.inlineEditor;
                if (!inlineEditor) return;
                const inlineRange = inlineEditor.getInlineRange();
                if (inlineRange) {
                    event.stopPropagation();
                    event.preventDefault();
                    const text = this.inlineEditor.yText.toString();
                    const index = text.lastIndexOf(LINE_BREAK_SYMBOL, inlineRange.index - 1);
                    const indexArr = allIndexOf(text, LINE_BREAK_SYMBOL, inlineRange.index, inlineRange.index + inlineRange.length).map((i)=>i + 1).reverse();
                    if (index !== -1) {
                        indexArr.push(index + 1);
                    } else {
                        indexArr.push(0);
                    }
                    indexArr.forEach((i)=>{
                        if (!this.inlineEditor) return;
                        this.inlineEditor.insertText({
                            index: i,
                            length: 0
                        }, INDENT_SYMBOL);
                    });
                    this.inlineEditor.setInlineRange({
                        index: inlineRange.index + 2,
                        length: inlineRange.length + (indexArr.length - 1) * INDENT_SYMBOL.length
                    });
                    return true;
                }
                return;
            },
            'Shift-Tab': (ctx)=>{
                const state = ctx.get('keyboardState');
                const event = state.raw;
                const inlineEditor = this.inlineEditor;
                if (!inlineEditor) return;
                const inlineRange = inlineEditor.getInlineRange();
                if (inlineRange) {
                    event.stopPropagation();
                    event.preventDefault();
                    const text = this.inlineEditor.yText.toString();
                    const index = text.lastIndexOf(LINE_BREAK_SYMBOL, inlineRange.index - 1);
                    let indexArr = allIndexOf(text, LINE_BREAK_SYMBOL, inlineRange.index, inlineRange.index + inlineRange.length).map((i)=>i + 1).reverse();
                    if (index !== -1) {
                        indexArr.push(index + 1);
                    } else {
                        indexArr.push(0);
                    }
                    indexArr = indexArr.filter((i)=>text.slice(i, i + 2) === INDENT_SYMBOL);
                    indexArr.forEach((i)=>{
                        if (!this.inlineEditor) return;
                        this.inlineEditor.deleteText({
                            index: i,
                            length: 2
                        });
                    });
                    if (indexArr.length > 0) {
                        this.inlineEditor.setInlineRange({
                            index: inlineRange.index - (indexArr[indexArr.length - 1] < inlineRange.index ? 2 : 0),
                            length: inlineRange.length - (indexArr.length - 1) * INDENT_SYMBOL.length
                        });
                    }
                    return true;
                }
                return;
            },
            'Control-d': ()=>{
                if (!IS_MAC) return;
                return true;
            },
            Delete: ()=>{
                return true;
            },
            Enter: ()=>{
                this.doc.captureSync();
                return true;
            },
            'Mod-Enter': ()=>{
                const { model, std } = this;
                if (!model || !std) return;
                const inlineEditor = this.inlineEditor;
                const inlineRange = inlineEditor?.getInlineRange();
                if (!inlineRange || !inlineEditor) return;
                const isEnd = model.text.length === inlineRange.index;
                if (!isEnd) return;
                const parent = this.doc.getParent(model);
                if (!parent) return;
                const index = parent.children.indexOf(model);
                if (index === -1) return;
                const id = this.doc.addBlock('affine:paragraph', {}, parent, index + 1);
                focusTextModel(std, id);
                return true;
            }
        });
        this._inlineRangeProvider = getInlineRangeProvider(this);
    }
    copyCode() {
        const model = this.model;
        const slice = Slice.fromModels(model.doc, [
            model
        ]);
        this.std.clipboard.copySlice(slice).then(()=>{
            toast(this.host, 'Copied to clipboard');
        }).catch((e)=>{
            toast(this.host, 'Copied failed, something went wrong');
            console.error(e);
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.clipboardController.hostDisconnected();
    }
    async getUpdateComplete() {
        const result = await super.getUpdateComplete();
        await this._richTextElement?.updateComplete;
        return result;
    }
    renderBlock() {
        return html`
      <div
        class=${classMap({
            'affine-code-block-container': true,
            wrap: this.model.wrap
        })}
      >
        <rich-text
          .yText=${this.model.text.yText}
          .inlineEventSource=${this.topContenteditableElement ?? nothing}
          .undoManager=${this.doc.history}
          .attributesSchema=${this.inlineManager.getSchema()}
          .attributeRenderer=${this.inlineManager.getRenderer()}
          .readonly=${this.doc.readonly}
          .inlineRangeProvider=${this._inlineRangeProvider}
          .enableClipboard=${false}
          .enableUndoRedo=${false}
          .wrapText=${this.model.wrap}
          .verticalScrollContainerGetter=${()=>getViewportElement(this.host)}
          .vLineRenderer=${(vLine)=>{
            return html`
              <span contenteditable="false" class="line-number"
                >${vLine.index + 1}</span
              >
              ${vLine.renderVElements()}
            `;
        }}
        >
        </rich-text>

        ${this.renderChildren(this.model)} ${Object.values(this.widgets)}
      </div>
    `;
    }
    setWrap(wrap) {
        this.doc.updateBlock(this.model, {
            wrap
        });
    }
    #___private__richTextElement_1;
    get _richTextElement() {
        return this.#___private__richTextElement_1;
    }
    set _richTextElement(_v) {
        this.#___private__richTextElement_1 = _v;
    }
    #___private_blockContainerStyles_2;
    get blockContainerStyles() {
        return this.#___private_blockContainerStyles_2;
    }
    set blockContainerStyles(_v) {
        this.#___private_blockContainerStyles_2 = _v;
    }
    #___private_useCaptionEditor_3;
    get useCaptionEditor() {
        return this.#___private_useCaptionEditor_3;
    }
    set useCaptionEditor(_v) {
        this.#___private_useCaptionEditor_3 = _v;
    }
    #___private_useZeroWidth_4;
    get useZeroWidth() {
        return this.#___private_useZeroWidth_4;
    }
    set useZeroWidth(_v) {
        this.#___private_useZeroWidth_4 = _v;
    }
    constructor(...args){
        super(...args), this._inlineRangeProvider = null, this.clipboardController = new CodeClipboardController(this), this.highlightTokens$ = signal([]), this.languageName$ = computed(()=>{
            const lang = this.model.language$.value;
            if (lang === null) {
                return 'Plain Text';
            }
            const matchedInfo = this.service.langs.find((info)=>info.id === lang);
            return matchedInfo ? matchedInfo.name : 'Plain Text';
        }), this.#___private__richTextElement_1 = (_initProto(this), _init__richTextElement(this, null)), this.#___private_blockContainerStyles_2 = {
            margin: '24px 0'
        }, this.#___private_useCaptionEditor_3 = true, this.#___private_useZeroWidth_4 = true;
    }
}
