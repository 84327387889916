export const COLLECTION_VERSION = 2;
export const PAGE_VERSION = 2;
export const SCHEMA_NOT_FOUND_MESSAGE = 'Schema not found. The block flavour may not be registered.';
export const TEXT_UNIQ_IDENTIFIER = '$blocksuite:internal:text$';
export const NATIVE_UNIQ_IDENTIFIER = '$blocksuite:internal:native$';
export const SYS_KEYS = new Set([
    'id',
    'flavour',
    'children'
]);
