export class NoPageRootError extends Error {
    constructor(page){
        super('Page root not found when render editor!'), this.page = page;
        const hasExpectSpace = Array.from(page.rootDoc.spaces.values()).some((doc)=>page.spaceDoc.guid === doc.guid);
        const blocks = page.spaceDoc.getMap('blocks');
        const havePageBlock = Array.from(blocks.values()).some((block)=>block.get('sys:flavour') === 'affine:page');
        console.info('NoPageRootError current data: %s', JSON.stringify({
            expectPageId: page.id,
            expectGuid: page.spaceDoc.guid,
            hasExpectSpace,
            blockSize: blocks.size,
            havePageBlock
        }));
    }
}
