import { nanoid } from 'nanoid';
import { ZOOM_WHEEL_STEP } from '../consts.js';
export { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing';
export function generateElementId() {
    return nanoid(10);
}
export function normalizeWheelDeltaY(delta, zoom = 1) {
    const sign = Math.sign(delta);
    const abs = Math.abs(delta);
    const maxStep = ZOOM_WHEEL_STEP * 100;
    if (abs > maxStep) {
        delta = maxStep * sign;
    }
    let newZoom = zoom - delta / 100;
    newZoom += Math.log10(Math.max(1, zoom)) * -sign * Math.min(1, abs / 20);
    return newZoom;
}
