import { effect } from '@preact/signals-core';
import { SurfaceBlockModel } from '../gfx/surface/surface-model.js';
export function onSurfaceAdded(doc, callback) {
    let found = false;
    let foundId = '';
    const dispose = effect(()=>{
        if (found && doc.getBlock(foundId)) {
            return;
        }
        for (const block of Object.values(doc.blocks.value)){
            if (block.model instanceof SurfaceBlockModel) {
                callback(block.model);
                found = true;
                foundId = block.id;
                return;
            }
        }
        callback(null);
    });
    return dispose;
}
