import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import React from 'react';
import * as styles from '../styles.css';
import * as desktopStyles from './styles.css';
export const DesktopMenu = ({ children, items, noPortal, portalOptions, rootOptions: { defaultOpen, modal, ...rootOptions } = {}, contentOptions: { className = '', style: contentStyle = {}, ...otherContentOptions } = {} })=>{
    const ContentWrapper = noPortal ? React.Fragment : DropdownMenu.Portal;
    return _jsxs(DropdownMenu.Root, {
        defaultOpen: defaultOpen,
        modal: modal ?? false,
        ...rootOptions,
        children: [
            _jsx(DropdownMenu.Trigger, {
                asChild: true,
                onClick: (e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                },
                children: children
            }),
            _jsx(ContentWrapper, {
                ...portalOptions,
                children: _jsx(DropdownMenu.Content, {
                    className: clsx(styles.menuContent, desktopStyles.contentAnimation, className),
                    sideOffset: 4,
                    align: "start",
                    style: {
                        zIndex: 'var(--affine-z-index-popover)',
                        ...contentStyle
                    },
                    ...otherContentOptions,
                    children: items
                })
            })
        ]
    });
};
