import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import * as Y from 'yjs';
import { native2Y } from '../../reactive/index.js';
import { internalPrimitives } from '../../schema/index.js';
export class DocCRUD {
    get root() {
        let rootId = null;
        this._yBlocks.forEach((yBlock)=>{
            const flavour = yBlock.get('sys:flavour');
            const schema = this._schema.flavourSchemaMap.get(flavour);
            if (!schema) return;
            if (schema.model.role === 'root') {
                rootId = yBlock.get('sys:id');
            }
        });
        return rootId;
    }
    constructor(_yBlocks, _schema){
        this._yBlocks = _yBlocks;
        this._schema = _schema;
    }
    _getSiblings(id, fn) {
        const parentId = this.getParent(id);
        if (!parentId) return null;
        const parent = this._yBlocks.get(parentId);
        if (!parent) return null;
        const children = parent.get('sys:children');
        const index = children.toArray().indexOf(id);
        if (index === -1) return null;
        return fn(index, parent);
    }
    addBlock(id, flavour, initialProps = {}, parent, parentIndex) {
        const schema = this._schema.flavourSchemaMap.get(flavour);
        if (!schema) {
            throw new BlockSuiteError(ErrorCode.ModelCRUDError, `schema for flavour: ${flavour} not found`);
        }
        const parentFlavour = parent ? this._yBlocks.get(parent)?.get('sys:flavour') : undefined;
        this._schema.validate(flavour, parentFlavour);
        const yBlock = new Y.Map();
        this._yBlocks.set(id, yBlock);
        const version = schema.version;
        const children = initialProps.children?.map((child)=>typeof child === 'string' ? child : child.id);
        yBlock.set('sys:id', id);
        yBlock.set('sys:flavour', flavour);
        yBlock.set('sys:version', version);
        yBlock.set('sys:children', Y.Array.from(children ?? []));
        const defaultProps = schema.model.props?.(internalPrimitives) ?? {};
        const props = {
            ...defaultProps,
            ...initialProps
        };
        delete props.id;
        delete props.flavour;
        delete props.children;
        Object.entries(props).forEach(([key, value])=>{
            if (value === undefined) return;
            yBlock.set(`prop:${key}`, native2Y(value));
        });
        const parentId = parent ?? (schema.model.role === 'root' ? null : this.root);
        if (!parentId) return;
        const yParent = this._yBlocks.get(parentId);
        if (!yParent) return;
        const yParentChildren = yParent.get('sys:children');
        const index = parentIndex ?? yParentChildren.length;
        yParentChildren.insert(index, [
            id
        ]);
    }
    deleteBlock(id, options = {
        deleteChildren: true
    }) {
        const { bringChildrenTo, deleteChildren } = options;
        if (bringChildrenTo && deleteChildren) {
            console.error('Cannot bring children to another block and delete them at the same time');
            return;
        }
        const yModel = this._yBlocks.get(id);
        if (!yModel) return;
        const yModelChildren = yModel.get('sys:children');
        const parent = this.getParent(id);
        if (!parent) return;
        const yParent = this._yBlocks.get(parent);
        const yParentChildren = yParent.get('sys:children');
        const modelIndex = yParentChildren.toArray().indexOf(id);
        if (modelIndex > -1) {
            yParentChildren.delete(modelIndex, 1);
        }
        const apply = ()=>{
            if (bringChildrenTo) {
                const bringChildrenToModel = ()=>{
                    if (!bringChildrenTo) {
                        throw new BlockSuiteError(ErrorCode.ModelCRUDError, 'bringChildrenTo is not provided when deleting block');
                    }
                    const model = this._yBlocks.get(bringChildrenTo);
                    if (!model) return;
                    const bringFlavour = model.get('sys:flavour');
                    yModelChildren.forEach((child)=>{
                        const childModel = this._yBlocks.get(child);
                        if (!childModel) return;
                        this._schema.validate(childModel.get('sys:flavour'), bringFlavour);
                    });
                    if (bringChildrenTo === parent) {
                        yParentChildren.insert(modelIndex, yModelChildren.toArray());
                        return;
                    }
                    const yBringChildrenTo = this._yBlocks.get(bringChildrenTo);
                    if (!yBringChildrenTo) return;
                    const yBringChildrenToChildren = yBringChildrenTo.get('sys:children');
                    yBringChildrenToChildren.push(yModelChildren.toArray());
                };
                bringChildrenToModel();
                return;
            }
            if (deleteChildren) {
                const deleteById = (id)=>{
                    const yBlock = this._yBlocks.get(id);
                    const yChildren = yBlock.get('sys:children');
                    yChildren.forEach((id)=>deleteById(id));
                    this._yBlocks.delete(id);
                };
                yModelChildren.forEach((id)=>deleteById(id));
            }
        };
        apply();
        this._yBlocks.delete(id);
    }
    getNext(id) {
        return this._getSiblings(id, (index, parent)=>parent.get('sys:children').toArray().at(index + 1) ?? null);
    }
    getParent(targetId) {
        const root = this.root;
        if (!root || root === targetId) return null;
        const findParent = (parentId)=>{
            const parentYBlock = this._yBlocks.get(parentId);
            if (!parentYBlock) return null;
            const children = parentYBlock.get('sys:children');
            for (const childId of children.toArray()){
                if (childId === targetId) return parentId;
                const parent = findParent(childId);
                if (parent != null) return parent;
            }
            return null;
        };
        return findParent(root);
    }
    getPrev(id) {
        return this._getSiblings(id, (index, parent)=>parent.get('sys:children').toArray().at(index - 1) ?? null);
    }
    moveBlocks(blocksToMove, newParent, targetSibling = null, shouldInsertBeforeSibling = true) {
        const childBlocksPerParent = new Map();
        const parentBlock = this._yBlocks.get(newParent);
        if (!parentBlock) return;
        const parentFlavour = parentBlock.get('sys:flavour');
        blocksToMove.forEach((blockId)=>{
            const parent = this.getParent(blockId);
            if (!parent) return;
            const block = this._yBlocks.get(blockId);
            if (!block) return;
            this._schema.validate(block.get('sys:flavour'), parentFlavour);
            const children = childBlocksPerParent.get(parent);
            if (!children) {
                childBlocksPerParent.set(parent, [
                    blockId
                ]);
                return;
            }
            const last = children[children.length - 1];
            if (this.getNext(last) !== blockId) {
                throw new BlockSuiteError(ErrorCode.ModelCRUDError, 'The blocks to move are not contiguous under their parent');
            }
            children.push(blockId);
        });
        let insertIndex = 0;
        Array.from(childBlocksPerParent.entries()).forEach(([parentBlock, blocksToMove], index)=>{
            const targetParentBlock = this._yBlocks.get(newParent);
            if (!targetParentBlock) return;
            const targetParentChildren = targetParentBlock.get('sys:children');
            const sourceParentBlock = this._yBlocks.get(parentBlock);
            if (!sourceParentBlock) return;
            const sourceParentChildren = sourceParentBlock.get('sys:children');
            const startIndex = sourceParentChildren.toArray().findIndex((id)=>id === blocksToMove[0]);
            sourceParentChildren.delete(startIndex, blocksToMove.length);
            const updateInsertIndex = ()=>{
                const first = index === 0;
                if (!first) {
                    insertIndex++;
                    return;
                }
                if (!targetSibling) {
                    insertIndex = targetParentChildren.length;
                    return;
                }
                const targetIndex = targetParentChildren.toArray().findIndex((id)=>id === targetSibling);
                if (targetIndex === -1) {
                    throw new BlockSuiteError(ErrorCode.ModelCRUDError, 'Target sibling not found');
                }
                insertIndex = shouldInsertBeforeSibling ? targetIndex : targetIndex + 1;
            };
            updateInsertIndex();
            targetParentChildren.insert(insertIndex, blocksToMove);
        });
    }
    updateBlockChildren(id, children) {
        const yBlock = this._yBlocks.get(id);
        if (!yBlock) return;
        const yChildrenArray = yBlock.get('sys:children');
        yChildrenArray.delete(0, yChildrenArray.length);
        yChildrenArray.push(children);
    }
}
