import { filterMatcher } from './vars';
const evalRef = (ref, variableMap)=>{
    return variableMap[ref.name];
};
const evalLiteral = (lit)=>{
    return lit?.value;
};
const evalFilter = (filter, variableMap)=>{
    const impl = filterMatcher.findData((v)=>v.name === filter.funcName)?.impl;
    if (!impl) {
        throw new Error('No function implementation found');
    }
    const leftValue = evalRef(filter.left, variableMap);
    const args = filter.args.map(evalLiteral);
    return impl(leftValue, ...args);
};
export const evalFilterList = (filterList, variableMap)=>{
    return filterList.every((filter)=>evalFilter(filter, variableMap));
};
