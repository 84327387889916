import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { Loading } from '../../ui/loading';
import * as styles from './index.css';
import { globalLoadingEventsAtom } from './index.jotai';
export { pushGlobalLoadingEventAtom, resolveGlobalLoadingEventAtom } from './index.jotai';
export function GlobalLoading() {
    const globalLoadingEvents = useAtomValue(globalLoadingEventsAtom);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        if (globalLoadingEvents.length) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [
        globalLoadingEvents
    ]);
    if (!globalLoadingEvents.length) {
        return null;
    }
    return _jsx("div", {
        className: styles.globalLoadingWrapperStyle,
        "data-loading": loading,
        children: _jsx(Loading, {
            size: 20
        })
    });
}
