function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _init__isSelected, _init__showImage, _init__showOverlay, _init_loading, _initProto;
import { OpenIcon } from '@blocksuite/affine-components/icons';
import { html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EmbedBlockComponent } from '../common/embed-block-element.js';
import { getEmbedCardIcons } from '../common/utils.js';
import { youtubeUrlRegex } from './embed-youtube-model.js';
import { styles, YoutubeIcon } from './styles.js';
import { refreshEmbedYoutubeUrlData } from './utils.js';
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = property({
    attribute: false
});
export class EmbedYoutubeBlockComponent extends EmbedBlockComponent {
    static{
        ({ e: [_init__isSelected, _init__showImage, _init__showOverlay, _init_loading, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_isSelected"
            ],
            [
                _dec1,
                1,
                "_showImage"
            ],
            [
                _dec2,
                1,
                "_showOverlay"
            ],
            [
                _dec3,
                1,
                "loading"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    _handleDoubleClick(event) {
        event.stopPropagation();
        this.open();
    }
    _selectBlock() {
        const selectionManager = this.host.selection;
        const blockSelection = selectionManager.create('block', {
            blockId: this.blockId
        });
        selectionManager.setGroup('note', [
            blockSelection
        ]);
    }
    _handleClick(event) {
        event.stopPropagation();
        this._selectBlock();
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.model.videoId) {
            this.doc.withoutTransact(()=>{
                const url = this.model.url;
                const urlMatch = url.match(youtubeUrlRegex);
                if (urlMatch) {
                    const [, videoId] = urlMatch;
                    this.doc.updateBlock(this.model, {
                        videoId
                    });
                }
            });
        }
        if (!this.model.description && !this.model.title) {
            this.doc.withoutTransact(()=>{
                this.refreshData();
            });
        }
        this.disposables.add(this.model.propsUpdated.on(({ key })=>{
            this.requestUpdate();
            if (key === 'url') {
                this.refreshData();
            }
        }));
        this.disposables.add(this.std.selection.slots.changed.on(()=>{
            this._isSelected = !!this.selected?.is('block') || !!this.selected?.is('surface');
            this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
        }));
        this.handleEvent('dragStart', ()=>{
            this._isDragging = true;
            this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
        });
        this.handleEvent('dragEnd', ()=>{
            this._isDragging = false;
            this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
        });
        matchMedia('print').addEventListener('change', ()=>{
            this._showImage = matchMedia('print').matches;
        });
    }
    renderBlock() {
        const { image, title = 'YouTube', description, creator, creatorImage, videoId, style } = this.model;
        this._cardStyle = style;
        const loading = this.loading;
        const { LoadingIcon, EmbedCardBannerIcon } = getEmbedCardIcons();
        const titleIcon = loading ? LoadingIcon : YoutubeIcon;
        const titleText = loading ? 'Loading...' : title;
        const descriptionText = loading ? '' : description;
        const bannerImage = !loading && image ? html`<object type="image/webp" data=${image} draggable="false">
            ${EmbedCardBannerIcon}
          </object>` : EmbedCardBannerIcon;
        const creatorImageEl = !loading && creatorImage ? html`<object
            type="image/webp"
            data=${creatorImage}
            draggable="false"
          ></object>` : nothing;
        return this.renderEmbed(()=>html`
        <div
          class=${classMap({
                'affine-embed-youtube-block': true,
                loading,
                selected: this._isSelected
            })}
          style=${styleMap({
                transform: `scale(${this._scale})`,
                transformOrigin: '0 0'
            })}
          @click=${this._handleClick}
          @dblclick=${this._handleDoubleClick}
        >
          <div class="affine-embed-youtube-video">
            ${videoId ? html`
                  <div class="affine-embed-youtube-video-iframe-container">
                    <iframe
                      id="ytplayer"
                      type="text/html"
                      src=${`https://www.youtube.com/embed/${videoId}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                    <div
                      class=${classMap({
                'affine-embed-youtube-video-iframe-overlay': true,
                hide: !this._showOverlay
            })}
                    ></div>
                    <img
                      class=${classMap({
                'affine-embed-youtube-video-iframe-overlay': true,
                'media-print': true,
                hide: !this._showImage
            })}
                      src=${`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                      alt="YouTube Video"
                    />
                  </div>
                ` : bannerImage}
          </div>
          <div class="affine-embed-youtube-content">
            <div class="affine-embed-youtube-content-header">
              <div class="affine-embed-youtube-content-title-icon">
                ${titleIcon}
              </div>

              <div class="affine-embed-youtube-content-title-text">
                ${titleText}
              </div>

              <div class="affine-embed-youtube-content-creator-image">
                ${creatorImageEl}
              </div>

              <div class="affine-embed-youtube-content-creator-text">
                ${creator}
              </div>
            </div>

            <div class="affine-embed-youtube-content-description">
              ${descriptionText}
            </div>

            <div class="affine-embed-youtube-content-url" @click=${this.open}>
              <span>www.youtube.com</span>

              <div class="affine-embed-youtube-content-url-icon">
                ${OpenIcon}
              </div>
            </div>
          </div>
        </div>
      `);
    }
    #___private__isSelected_1;
    get _isSelected() {
        return this.#___private__isSelected_1;
    }
    set _isSelected(_v) {
        this.#___private__isSelected_1 = _v;
    }
    #___private__showImage_2;
    get _showImage() {
        return this.#___private__showImage_2;
    }
    set _showImage(_v) {
        this.#___private__showImage_2 = _v;
    }
    #___private__showOverlay_3;
    get _showOverlay() {
        return this.#___private__showOverlay_3;
    }
    set _showOverlay(_v) {
        this.#___private__showOverlay_3 = _v;
    }
    #___private_loading_4;
    get loading() {
        return this.#___private_loading_4;
    }
    set loading(_v) {
        this.#___private_loading_4 = _v;
    }
    constructor(...args){
        super(...args), this._cardStyle = 'video', this._isDragging = false, this._isResizing = false, this.open = ()=>{
            let link = this.model.url;
            if (!link.match(/^[a-zA-Z]+:\/\//)) {
                link = 'https://' + link;
            }
            window.open(link, '_blank');
        }, this.refreshData = ()=>{
            refreshEmbedYoutubeUrlData(this, this.fetchAbortController.signal).catch(console.error);
        }, this.#___private__isSelected_1 = (_initProto(this), _init__isSelected(this, false)), this.#___private__showImage_2 = _init__showImage(this, false), this.#___private__showOverlay_3 = _init__showOverlay(this, true), this.#___private_loading_4 = _init_loading(this, false);
    }
}
