import '../../_common/components/ask-ai-button';
import { I18n } from '@affine/i18n';
import { toolbarDefaultConfig } from '@blocksuite/affine/blocks';
import { html } from 'lit';
import { AIItemGroups } from '../../_common/config';
export function setupFormatBarAIEntry(formatBar) {
    toolbarDefaultConfig(formatBar);
    formatBar.addRawConfigItems([
        {
            type: 'custom',
            render (formatBar) {
                return html` <ask-ai-button
            .buttonText=${I18n['ai.wemem.ask-ai']()}
            .host=${formatBar.host}
            .actionGroups=${AIItemGroups}
            .toggleType=${'hover'}
          ></ask-ai-button>`;
            }
        },
        {
            type: 'divider'
        }
    ], 0);
}
