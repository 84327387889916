function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init__editing, _init__textContainer, _init_childrenContainer, _initProto;
import { TextUtils } from '@blocksuite/affine-block-surface';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { GfxBlockComponent } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { css, html } from 'lit';
import { query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { HandleDirection } from '../root-block/edgeless/components/resize/resize-handles.js';
import { DefaultModeDragType, DefaultToolController } from '../root-block/edgeless/tools/default-tool.js';
export const EDGELESS_TEXT_BLOCK_MIN_WIDTH = 50;
export const EDGELESS_TEXT_BLOCK_MIN_HEIGHT = 50;
_dec = state(), _dec1 = query('.edgeless-text-block-container'), _dec2 = query('.affine-block-children-container');
export class EdgelessTextBlockComponent extends GfxBlockComponent {
    static{
        ({ e: [_init__editing, _init__textContainer, _init_childrenContainer, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_editing"
            ],
            [
                _dec1,
                1,
                "_textContainer"
            ],
            [
                _dec2,
                1,
                "childrenContainer"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .edgeless-text-block-container[data-max-width='false'] .inline-editor span {
      word-break: normal !important;
      overflow-wrap: normal !important;
    }
  `;
    }
    get dragMoving() {
        const controller = this.rootService.tool.currentController;
        return controller instanceof DefaultToolController && controller.dragType === DefaultModeDragType.ContentMoving;
    }
    get rootService() {
        return this.std.getService('affine:page');
    }
    _initDragEffect() {
        const disposables = this.disposables;
        const edgelessSelection = this.rootService.selection;
        const rootComponent = this.rootComponent;
        if (!rootComponent || !edgelessSelection) return;
        const selectedRect = rootComponent.selectedRect;
        if (!selectedRect) return;
        disposables.add(selectedRect.slots.dragStart.filter(()=>edgelessSelection.selectedElements.includes(this.model)).on(()=>{
            if (selectedRect.dragDirection === HandleDirection.Left || selectedRect.dragDirection === HandleDirection.Right) {
                this._horizontalResizing = true;
            }
        }));
        disposables.add(selectedRect.slots.dragMove.filter(()=>edgelessSelection.selectedElements.includes(this.model)).on(()=>{
            if (selectedRect.dragDirection === HandleDirection.Left || selectedRect.dragDirection === HandleDirection.Right) {
                this._updateH();
            }
        }));
        disposables.add(selectedRect.slots.dragEnd.filter(()=>edgelessSelection.selectedElements.includes(this.model)).on(()=>{
            if (selectedRect.dragDirection === HandleDirection.Left || selectedRect.dragDirection === HandleDirection.Right) {
                this._horizontalResizing = false;
            }
        }));
    }
    _updateH() {
        const bound = Bound.deserialize(this.model.xywh);
        const rect = this._textContainer.getBoundingClientRect();
        bound.h = Math.max(rect.height / this.gfx.viewport.zoom, EDGELESS_TEXT_BLOCK_MIN_HEIGHT * this.gfx.viewport.zoom);
        this.doc.updateBlock(this.model, {
            xywh: bound.serialize()
        });
    }
    _updateW() {
        const bound = Bound.deserialize(this.model.xywh);
        const rect = this._textContainer.getBoundingClientRect();
        bound.w = Math.max(rect.width / this.gfx.viewport.zoom, EDGELESS_TEXT_BLOCK_MIN_WIDTH * this.gfx.viewport.zoom);
        this.doc.updateBlock(this.model, {
            xywh: bound.serialize()
        });
    }
    checkWidthOverflow(width) {
        let wValid = true;
        const oldWidthStr = this._textContainer.style.width;
        this._textContainer.style.width = `${width}px`;
        if (this.childrenContainer.scrollWidth > this.childrenContainer.offsetWidth) {
            wValid = false;
        }
        this._textContainer.style.width = oldWidthStr;
        return wValid;
    }
    connectedCallback() {
        super.connectedCallback();
        this.disposables.add(this.model.propsUpdated.on(({ key })=>{
            this.updateComplete.then(()=>{
                const command = this.host.command;
                const blockSelections = this.model.children.map((child)=>this.host.selection.create('block', {
                        blockId: child.id
                    }));
                if (key === 'fontStyle') {
                    command.exec('formatBlock', {
                        blockSelections,
                        styles: {
                            italic: null
                        }
                    });
                } else if (key === 'color') {
                    command.exec('formatBlock', {
                        blockSelections,
                        styles: {
                            color: null
                        }
                    });
                } else if (key === 'fontWeight') {
                    command.exec('formatBlock', {
                        blockSelections,
                        styles: {
                            bold: null
                        }
                    });
                }
            }).catch(console.error);
        }));
        this.style.transformOrigin = '0 0';
    }
    firstUpdated(props) {
        super.firstUpdated(props);
        const { disposables, rootService } = this;
        const edgelessSelection = rootService.selection;
        this._initDragEffect();
        disposables.add(edgelessSelection.slots.updated.on(()=>{
            if (edgelessSelection.has(this.model.id) && edgelessSelection.editing) {
                this._editing = true;
            } else {
                this._editing = false;
            }
        }));
        this._resizeObserver.observe(this._textContainer);
        disposables.add(()=>{
            this._resizeObserver.disconnect();
        });
        disposables.addFromEvent(this._textContainer, 'click', (e)=>{
            if (!this._editing) return;
            const containerRect = this._textContainer.getBoundingClientRect();
            const isTop = e.clientY < containerRect.top + containerRect.height / 2;
            let newParagraphId = null;
            if (isTop) {
                const firstChild = this.model.firstChild();
                if (!firstChild || !matchFlavours(firstChild, [
                    'affine:list',
                    'affine:paragraph'
                ])) {
                    newParagraphId = this.doc.addBlock('affine:paragraph', {}, this.model.id, 0);
                }
            } else {
                const lastChild = this.model.lastChild();
                if (!lastChild || !matchFlavours(lastChild, [
                    'affine:list',
                    'affine:paragraph'
                ])) {
                    newParagraphId = this.doc.addBlock('affine:paragraph', {}, this.model.id);
                }
            }
            if (newParagraphId) {
                this.rootService.selectionManager.setGroup('note', [
                    this.rootService.selectionManager.create('text', {
                        from: {
                            blockId: newParagraphId,
                            index: 0,
                            length: 0
                        },
                        to: null
                    })
                ]);
            }
        });
        disposables.addFromEvent(this._textContainer, 'focusout', ()=>{
            if (!this._editing) return;
            this.rootService.selectionManager.clear();
        });
    }
    getRenderingRect() {
        const { xywh, scale, rotate, hasMaxWidth } = this.model;
        const bound = Bound.deserialize(xywh);
        const w = hasMaxWidth || this._horizontalResizing || this.dragMoving ? bound.w / scale : undefined;
        return {
            x: bound.x,
            y: bound.y,
            w,
            h: bound.h / scale,
            rotate,
            zIndex: this.toZIndex()
        };
    }
    renderGfxBlock() {
        const { model } = this;
        const { scale, rotate, hasMaxWidth } = model;
        const containerStyle = {
            transform: `rotate(${rotate}deg)`,
            transformOrigin: 'center',
            padding: '5px 10px',
            border: `1px solid ${this._editing ? 'var(--affine—primary—color, #1e96eb)' : 'transparent'}`,
            borderRadius: '4px',
            boxSizing: 'border-box',
            boxShadow: this._editing ? '0px 0px 0px 2px rgba(30, 150, 235, 0.3)' : 'none',
            fontWeight: '400',
            lineHeight: 'var(--affine-line-height)'
        };
        this.style.transform = `scale(${scale})`;
        return html`
      <div
        class="edgeless-text-block-container"
        data-max-width="${hasMaxWidth}"
        style=${styleMap(containerStyle)}
      >
        <div
          style=${styleMap({
            pointerEvents: this._editing ? 'auto' : 'none'
        })}
        >
          ${this.renderPageContent()}
        </div>
      </div>
    `;
    }
    renderPageContent() {
        const { fontFamily, fontStyle, fontWeight, textAlign } = this.model;
        const color = ThemeObserver.generateColorProperty(this.model.color, '#000000');
        const style = styleMap({
            color,
            fontFamily: TextUtils.wrapFontFamily(fontFamily),
            fontStyle,
            fontWeight,
            textAlign
        });
        return html`
      <div style=${style} class="affine-edgeless-text-block-container">
        <div class="affine-block-children-container">
          ${this.renderChildren(this.model)}
        </div>
      </div>
    `;
    }
    tryFocusEnd() {
        const paragraphOrLists = Array.from(this.querySelectorAll('affine-paragraph, affine-list'));
        const last = paragraphOrLists.at(-1);
        if (last) {
            this.host.selection.setGroup('note', [
                this.host.selection.create('text', {
                    from: {
                        blockId: last.blockId,
                        index: last.model.text?.length ?? 0,
                        length: 0
                    },
                    to: null
                })
            ]);
        }
    }
    #___private__editing_1;
    get _editing() {
        return this.#___private__editing_1;
    }
    set _editing(_v) {
        this.#___private__editing_1 = _v;
    }
    #___private__textContainer_2;
    get _textContainer() {
        return this.#___private__textContainer_2;
    }
    set _textContainer(_v) {
        this.#___private__textContainer_2 = _v;
    }
    #___private_childrenContainer_3;
    get childrenContainer() {
        return this.#___private_childrenContainer_3;
    }
    set childrenContainer(_v) {
        this.#___private_childrenContainer_3 = _v;
    }
    constructor(...args){
        super(...args), this._horizontalResizing = false, this._resizeObserver = new ResizeObserver(()=>{
            if (this.doc.readonly) {
                return;
            }
            if (!this._editing) {
                return;
            }
            if (!this.model.hasMaxWidth) {
                this._updateW();
            }
            this._updateH();
        }), this.#___private__editing_1 = (_initProto(this), _init__editing(this, false)), this.#___private__textContainer_2 = _init__textContainer(this), this.#___private_childrenContainer_3 = _init_childrenContainer(this);
    }
}
