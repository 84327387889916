import { jsx as _jsx } from "react/jsx-runtime";
import { LiveData, useLiveData } from '@toeverything/infra';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { createPortal } from 'react-dom';
export const createIsland = ()=>{
    const targetLiveData$ = new LiveData(null);
    let mounted = false;
    let provided = false;
    return {
        Target: forwardRef(function IslandTarget({ ...other }, ref) {
            const target = useRef(null);
            useImperativeHandle(ref, ()=>target.current, []);
            useEffect(()=>{
                if (mounted === true) {
                    throw new Error('Island should not be mounted more than once');
                }
                mounted = true;
                targetLiveData$.next(target.current);
                return ()=>{
                    mounted = false;
                    targetLiveData$.next(null);
                };
            }, []);
            return _jsx("div", {
                ...other,
                ref: target
            });
        }),
        Provider: ({ children })=>{
            const target = useLiveData(targetLiveData$);
            useEffect(()=>{
                if (provided === true && process.env.NODE_ENV !== 'production') {
                    throw new Error('Island should not be provided more than once');
                }
                provided = true;
                return ()=>{
                    provided = false;
                };
            }, []);
            return target ? createPortal(children, target) : null;
        }
    };
};
