function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init_enableBlur, _init_mindmapElement, _init_readyToDrop, _initProto;
import { EditPropsStore } from '@blocksuite/affine-shared/services';
import { SignalWatcher } from '@blocksuite/global/utils';
import { computed } from '@preact/signals-core';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EdgelessDraggableElementController } from '../common/draggable/draggable-element.controller.js';
import { EdgelessToolbarToolMixin } from '../mixins/tool.mixin.js';
import { getMindMaps } from './assets.js';
import { getMindmapRender, mindmapConfig, textConfig, textRender, toolConfig2StyleObj } from './basket-elements.js';
import { basketIconDark, basketIconLight, textIcon } from './icons.js';
import { importMindmap } from './utils/import-mindmap.js';
_dec = property({
    type: Boolean
}), _dec1 = query('.basket-tool-item.mindmap'), _dec2 = state();
export class EdgelessMindmapToolButton extends EdgelessToolbarToolMixin(SignalWatcher(LitElement)) {
    static{
        ({ e: [_init_enableBlur, _init_mindmapElement, _init_readyToDrop, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "enableBlur"
            ],
            [
                _dec1,
                1,
                "mindmapElement"
            ],
            [
                _dec2,
                1,
                "readyToDrop"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .partial-clip {
      flex-shrink: 0;
      box-sizing: border-box;
      width: calc(100% + 20px);
      pointer-events: none;
      padding: 0 10px;
      overflow: hidden;
    }
    .basket-wrapper {
      pointer-events: auto;
      height: 64px;
      width: 96px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
    }
    .basket,
    .basket-tool-item {
      transition: transform 0.3s ease-in-out;
      position: absolute;
    }

    .basket {
      bottom: 0;
      height: 17px;
      width: 76px;
    }
    .basket > div,
    .basket > svg {
      position: absolute;
    }
    .glass {
      width: 76px;
      height: 17px;
      border-radius: 2px;
      mask: url(#mindmap-basket-body-mask);
    }
    .glass.enabled {
      backdrop-filter: blur(2px);
    }

    .basket {
      z-index: 3;
    }
    .basket-tool-item {
      cursor: grab;
    }
    .basket-tool-item svg {
      display: block;
    }
    .basket-tool-item {
      transform: translate(var(--default-x, 0), var(--default-y, 0))
        rotate(var(--default-r, 0)) scale(var(--default-s, 1));
      z-index: var(--default-z, 0);
    }

    .basket-tool-item.next {
      transform: translate(var(--next-x, 0), var(--next-y, 0))
        rotate(var(--next-r, 0)) scale(var(--next-s, 1));
      z-index: var(--next-z, 0);
    }

    /* active & hover */
    .basket-wrapper:hover .basket,
    .basket-wrapper.active .basket {
      z-index: 0;
    }
    .basket-wrapper:hover .basket-tool-item.current,
    .basket-wrapper.active .basket-tool-item.current {
      transform: translate(var(--active-x, 0), var(--active-y, 0))
        rotate(var(--active-r, 0)) scale(var(--active-s, 1));
      z-index: var(--active-z, 0);
    }

    .basket-tool-item.next.coming,
    .basket-wrapper:hover .basket-tool-item.current:hover {
      transform: translate(var(--hover-x, 0), var(--hover-y, 0))
        rotate(var(--hover-r, 0)) scale(var(--hover-s, 1));
      z-index: var(--hover-z, 0);
    }
  `;
    }
    get draggableTools() {
        const style = this._style$.value;
        const mindmap = this.mindmaps.find((m)=>m.style === style) || this.mindmaps[0];
        return [
            {
                name: 'text',
                icon: textIcon,
                config: textConfig,
                standardWidth: 100,
                render: textRender
            },
            {
                name: 'mindmap',
                icon: mindmap.icon,
                config: mindmapConfig,
                standardWidth: 350,
                render: getMindmapRender(style)
            }
        ];
    }
    get mindmaps() {
        return getMindMaps(this.theme);
    }
    _toggleMenu() {
        if (this.tryDisposePopper()) return;
        this.setEdgelessTool({
            type: 'default'
        });
        const menu = this.createPopper('edgeless-mindmap-menu', this);
        Object.assign(menu.element, {
            edgeless: this.edgeless,
            onActiveStyleChange: (style)=>{
                this.edgeless.std.get(EditPropsStore).recordLastProps('mindmap', {
                    style
                });
            },
            onImportMindMap: (bound)=>{
                return importMindmap(bound).then((mindmap)=>{
                    const id = this.edgeless.service.addElement('mindmap', {
                        children: mindmap,
                        layoutType: mindmap?.layoutType === 'left' ? 1 : 0
                    });
                    const element = this.edgeless.service.getElementById(id);
                    this.tryDisposePopper();
                    this.setEdgelessTool({
                        type: 'default'
                    }, {
                        elements: [
                            element.tree.id
                        ],
                        editing: false
                    });
                });
            }
        });
    }
    initDragController() {
        if (!this.edgeless || !this.toolbarContainer) return;
        if (this.draggableController) return;
        this.draggableController = new EdgelessDraggableElementController(this, {
            service: this.edgeless.service,
            edgeless: this.edgeless,
            scopeElement: this.toolbarContainer,
            standardWidth: 100,
            clickToDrag: true,
            onOverlayCreated: (overlay, { data })=>{
                const tool = this.draggableTools.find((t)=>t.name === data.name);
                if (!tool) return;
                const rotate = tool.config?.hover?.r ?? tool.config?.default?.r ?? 0;
                overlay.element.style.setProperty('--rotate', rotate + 'deg');
                setTimeout(()=>{
                    overlay.transitionWrapper.style.setProperty('--rotate', -rotate + 'deg');
                }, 50);
                const scale = tool.config?.hover?.s ?? tool.config?.default?.s ?? 1;
                overlay.element.style.setProperty('--scale', `${scale}`);
                const _left = parseInt(overlay.element.style.left);
                const _top = parseInt(overlay.element.style.top);
                if (data.name === 'mindmap') {
                    overlay.element.style.left = _left + 3 + 'px';
                    overlay.element.style.top = _top + 5 + 'px';
                } else if (data.name === 'text') {
                    overlay.element.style.left = _left + 0 + 'px';
                    overlay.element.style.top = _top + 3 + 'px';
                }
                this.readyToDrop = true;
            },
            onCanceled: (overlay)=>{
                overlay.transitionWrapper.style.transformOrigin = 'unset';
                overlay.transitionWrapper.style.setProperty('--rotate', '0deg');
                this.readyToDrop = false;
            },
            onDrop: (el, bound)=>{
                const id = el.data.render(bound, this.edgeless.service, this.edgeless);
                this.readyToDrop = false;
                if (el.data.name === 'mindmap') {
                    this.setEdgelessTool({
                        type: 'default'
                    }, {
                        elements: [
                            id
                        ],
                        editing: false
                    });
                } else if (el.data.name === 'text') {
                    this.setEdgelessTool({
                        type: 'default'
                    });
                }
            }
        });
        this.edgeless.bindHotKey({
            m: ()=>{
                const service = this.edgeless.service;
                if (service.locked) return;
                if (service.selection.editing) return;
                if (this.readyToDrop) {
                    const activeIndex = this.mindmaps.findIndex((m)=>m.style === this._style$.value);
                    const nextIndex = (activeIndex + 1) % this.mindmaps.length;
                    const next = this.mindmaps[nextIndex];
                    this.edgeless.std.get(EditPropsStore).recordLastProps('mindmap', {
                        style: next.style
                    });
                    const tool = this.draggableTools.find((t)=>t.name === 'mindmap');
                    this.draggableController.updateElementInfo({
                        data: tool,
                        preview: next.icon
                    });
                    return;
                }
                this.setEdgelessTool({
                    type: 'mindmap'
                });
                const icon = this.mindmapElement;
                const { x, y } = service.tool.lastMousePos;
                const { left, top } = this.edgeless.viewport;
                const clientPos = {
                    x: x + left,
                    y: y + top
                };
                this.draggableController.clickToDrag(icon, clientPos);
            }
        }, {
            global: true
        });
    }
    render() {
        const { popper, theme } = this;
        const basketIcon = theme === 'light' ? basketIconLight : basketIconDark;
        const glassBg = theme === 'light' ? 'rgba(255,255,255,0.5)' : 'rgba(74, 74, 74, 0.6)';
        const { cancelled, dragOut, draggingElement } = this.draggableController?.states || {};
        const active = popper || draggingElement;
        return html`<edgeless-toolbar-button
      class="edgeless-mindmap-button"
      ?withHover=${true}
      .tooltip=${popper ? '' : 'Others'}
      .tooltipOffset=${4}
      @click=${this._toggleMenu}
      style="width: 100%; height: 100%; display: inline-block"
    >
      <div class="partial-clip">
        <div class="basket-wrapper ${active ? 'active' : ''}">
          ${repeat(this.draggableTools, (t)=>t.name, (tool)=>{
            const isBeingDragged = draggingElement?.data.name === tool.name;
            const variables = toolConfig2StyleObj(tool.config);
            const nextStyle = styleMap({
                ...variables
            });
            const currentStyle = styleMap({
                ...variables,
                opacity: isBeingDragged ? 0 : 1,
                pointerEvents: draggingElement ? 'none' : 'auto'
            });
            return html`${isBeingDragged ? html`<div
                      class=${classMap({
                'basket-tool-item': true,
                next: true,
                coming: !!dragOut && !cancelled
            })}
                      style=${nextStyle}
                    >
                      ${tool.icon}
                    </div>` : nothing}

                <div
                  style=${currentStyle}
                  @mousedown=${(e)=>this.draggableController.onMouseDown(e, {
                    data: tool,
                    preview: tool.icon,
                    standardWidth: tool.standardWidth
                })}
                  @touchstart=${(e)=>this.draggableController.onTouchStart(e, {
                    data: tool,
                    preview: tool.icon,
                    standardWidth: tool.standardWidth
                })}
                  class="basket-tool-item current ${tool.name}"
                >
                  ${tool.icon}
                </div>`;
        })}

          <div class="basket">
            <div
              class="glass ${this.enableBlur ? 'enabled' : ''}"
              style="background: ${glassBg}"
            ></div>
            ${basketIcon}
          </div>
        </div>
      </div>

      <svg width="0" height="0" style="opacity: 0; pointer-events: none">
        <defs>
          <mask id="mindmap-basket-body-mask">
            <rect
              x="2"
              width="71.8"
              y="2"
              height="15"
              rx="1.5"
              ry="1.5"
              fill="white"
            />
            <rect
              width="32"
              height="6"
              x="22"
              y="5.9"
              fill="black"
              rx="3"
              ry="3"
            />
          </mask>
        </defs>
      </svg>
    </edgeless-toolbar-button>`;
    }
    updated(_changedProperties) {
        const controllerRequiredProps = [
            'edgeless',
            'toolbarContainer'
        ];
        if (controllerRequiredProps.some((p)=>_changedProperties.has(p)) && !this.draggableController) {
            this.initDragController();
        }
    }
    #___private_enableBlur_1;
    get enableBlur() {
        return this.#___private_enableBlur_1;
    }
    set enableBlur(_v) {
        this.#___private_enableBlur_1 = _v;
    }
    #___private_mindmapElement_2;
    get mindmapElement() {
        return this.#___private_mindmapElement_2;
    }
    set mindmapElement(_v) {
        this.#___private_mindmapElement_2 = _v;
    }
    #___private_readyToDrop_3;
    get readyToDrop() {
        return this.#___private_readyToDrop_3;
    }
    set readyToDrop(_v) {
        this.#___private_readyToDrop_3 = _v;
    }
    constructor(...args){
        super(...args), this._style$ = computed(()=>{
            const { style } = this.edgeless.std.get(EditPropsStore).lastProps$.value.mindmap;
            return style;
        }), this.enableActiveBackground = true, this.type = [
            'mindmap',
            'text'
        ], this.#___private_enableBlur_1 = (_initProto(this), _init_enableBlur(this, true)), this.#___private_mindmapElement_2 = _init_mindmapElement(this), this.#___private_readyToDrop_3 = _init_readyToDrop(this, false);
    }
}
