function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _init_active, _init_activeMode, _init_arrow, _init_coming, _init_disabled, _init_hover, _init_hoverState, _init_iconContainerPadding, _init_iconContainerWidth, _init_iconSize, _init_justify, _init_labelHeight, _init_showTooltip, _init_tipPosition, _init_tooltip, _init_tooltipOffset, _init_withHover, _initProto;
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { cache } from 'lit/directives/cache.js';
import { styleMap } from 'lit/directives/style-map.js';
_dec = property({
    type: Boolean,
    reflect: true
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    type: Boolean,
    reflect: true
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
}), _dec12 = property({
    type: Boolean
}), _dec13 = property({
    attribute: false
}), _dec14 = property({
    attribute: false
}), _dec15 = property({
    attribute: false
}), _dec16 = property({
    attribute: false
});
export class EditorIconButton extends LitElement {
    static{
        ({ e: [_init_active, _init_activeMode, _init_arrow, _init_coming, _init_disabled, _init_hover, _init_hoverState, _init_iconContainerPadding, _init_iconContainerWidth, _init_iconSize, _init_justify, _init_labelHeight, _init_showTooltip, _init_tipPosition, _init_tooltip, _init_tooltipOffset, _init_withHover, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "active"
            ],
            [
                _dec1,
                1,
                "activeMode"
            ],
            [
                _dec2,
                1,
                "arrow"
            ],
            [
                _dec3,
                1,
                "coming"
            ],
            [
                _dec4,
                1,
                "disabled"
            ],
            [
                _dec5,
                1,
                "hover"
            ],
            [
                _dec6,
                1,
                "hoverState"
            ],
            [
                _dec7,
                1,
                "iconContainerPadding"
            ],
            [
                _dec8,
                1,
                "iconContainerWidth"
            ],
            [
                _dec9,
                1,
                "iconSize"
            ],
            [
                _dec10,
                1,
                "justify"
            ],
            [
                _dec11,
                1,
                "labelHeight"
            ],
            [
                _dec12,
                1,
                "showTooltip"
            ],
            [
                _dec13,
                1,
                "tipPosition"
            ],
            [
                _dec14,
                1,
                "tooltip"
            ],
            [
                _dec15,
                1,
                "tooltipOffset"
            ],
            [
                _dec16,
                1,
                "withHover"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host([disabled]),
    :host(:disabled) {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--affine-text-disable-color);
    }

    .icon-container {
      position: relative;
      display: flex;
      align-items: center;
      padding: var(--icon-container-padding);
      color: var(--affine-icon-color);
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      box-sizing: border-box;
      width: var(--icon-container-width, unset);
      justify-content: var(--justify, unset);
      user-select: none;
    }

    :host([active]) .icon-container.active-mode-color {
      color: var(--affine-primary-color);
    }

    :host([active]) .icon-container.active-mode-background {
      background: var(--affine-hover-color);
    }

    .icon-container[coming] {
      cursor: not-allowed;
      color: var(--affine-text-disable-color);
    }

    ::slotted(svg) {
      flex-shrink: 0;
      width: var(--icon-size, unset);
      height: var(--icon-size, unset);
    }

    ::slotted(.label) {
      flex: 1;
      padding: 0 4px;
      overflow: hidden;
      white-space: nowrap;
      line-height: var(--label-height, inherit);
    }
    ::slotted(.label.padding0) {
      padding: 0;
    }
    ::slotted(.label.ellipsis) {
      text-overflow: ellipsis;
    }
    ::slotted(.label.medium) {
      font-weight: 500;
    }

    .icon-container[with-hover]::before {
      content: '';
      display: block;
      background: var(--affine-hover-color);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 4px;
    }
  `;
    }
    constructor(){
        super(), this.#___private_active_1 = (_initProto(this), _init_active(this, false)), this.#___private_activeMode_2 = _init_activeMode(this, 'color'), this.#___private_arrow_3 = _init_arrow(this, true), this.#___private_coming_4 = _init_coming(this, false), this.#___private_disabled_5 = _init_disabled(this, false), this.#___private_hover_6 = _init_hover(this, true), this.#___private_hoverState_7 = _init_hoverState(this, false), this.#___private_iconContainerPadding_8 = _init_iconContainerPadding(this, 2), this.#___private_iconContainerWidth_9 = _init_iconContainerWidth(this, undefined), this.#___private_iconSize_10 = _init_iconSize(this, undefined), this.#___private_justify_11 = _init_justify(this, undefined), this.#___private_labelHeight_12 = _init_labelHeight(this, undefined), this.#___private_showTooltip_13 = _init_showTooltip(this, true), this.#___private_tipPosition_14 = _init_tipPosition(this, 'top'), this.#___private_tooltip_15 = _init_tooltip(this), this.#___private_tooltipOffset_16 = _init_tooltipOffset(this, 8), this.#___private_withHover_17 = _init_withHover(this, undefined);
        this.addEventListener('keypress', (event)=>{
            if (this.disabled) {
                return;
            }
            if (event.key === 'Enter' && !event.isComposing) {
                this.click();
            }
        });
        this.addEventListener('click', (event)=>{
            if (this.disabled) {
                event.stopPropagation();
                event.preventDefault();
            }
        }, {
            capture: true
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.tabIndex = 0;
        this.role = 'button';
    }
    render() {
        const tooltip = this.coming ? '(Coming soon)' : this.tooltip;
        const classnames = `icon-container active-mode-${this.activeMode} ${this.hoverState ? 'hovered' : ''}`;
        const padding = this.iconContainerPadding;
        const iconContainerStyles = styleMap({
            '--icon-container-width': this.iconContainerWidth,
            '--icon-container-padding': Array.isArray(padding) ? padding.map((v)=>`${v}px`).join(' ') : `${padding}px`,
            '--icon-size': this.iconSize,
            '--justify': this.justify,
            '--label-height': this.labelHeight
        });
        return html`
      <style>
        .icon-container:hover,
        .icon-container.hovered {
          background: ${this.hover ? `var(--affine-hover-color)` : 'inherit'};
        }
      </style>
      <div
        class=${classnames}
        style=${iconContainerStyles}
        ?with-hover=${this.withHover}
        ?disabled=${this.disabled}
      >
        <slot></slot>
        ${cache(this.showTooltip && tooltip ? html`<affine-tooltip
                tip-position=${this.tipPosition}
                .arrow=${this.arrow}
                .offset=${this.tooltipOffset}
                >${tooltip}</affine-tooltip
              >` : nothing)}
      </div>
    `;
    }
    #___private_active_1;
    get active() {
        return this.#___private_active_1;
    }
    set active(_v) {
        this.#___private_active_1 = _v;
    }
    #___private_activeMode_2;
    get activeMode() {
        return this.#___private_activeMode_2;
    }
    set activeMode(_v) {
        this.#___private_activeMode_2 = _v;
    }
    #___private_arrow_3;
    get arrow() {
        return this.#___private_arrow_3;
    }
    set arrow(_v) {
        this.#___private_arrow_3 = _v;
    }
    #___private_coming_4;
    get coming() {
        return this.#___private_coming_4;
    }
    set coming(_v) {
        this.#___private_coming_4 = _v;
    }
    #___private_disabled_5;
    get disabled() {
        return this.#___private_disabled_5;
    }
    set disabled(_v) {
        this.#___private_disabled_5 = _v;
    }
    #___private_hover_6;
    get hover() {
        return this.#___private_hover_6;
    }
    set hover(_v) {
        this.#___private_hover_6 = _v;
    }
    #___private_hoverState_7;
    get hoverState() {
        return this.#___private_hoverState_7;
    }
    set hoverState(_v) {
        this.#___private_hoverState_7 = _v;
    }
    #___private_iconContainerPadding_8;
    get iconContainerPadding() {
        return this.#___private_iconContainerPadding_8;
    }
    set iconContainerPadding(_v) {
        this.#___private_iconContainerPadding_8 = _v;
    }
    #___private_iconContainerWidth_9;
    get iconContainerWidth() {
        return this.#___private_iconContainerWidth_9;
    }
    set iconContainerWidth(_v) {
        this.#___private_iconContainerWidth_9 = _v;
    }
    #___private_iconSize_10;
    get iconSize() {
        return this.#___private_iconSize_10;
    }
    set iconSize(_v) {
        this.#___private_iconSize_10 = _v;
    }
    #___private_justify_11;
    get justify() {
        return this.#___private_justify_11;
    }
    set justify(_v) {
        this.#___private_justify_11 = _v;
    }
    #___private_labelHeight_12;
    get labelHeight() {
        return this.#___private_labelHeight_12;
    }
    set labelHeight(_v) {
        this.#___private_labelHeight_12 = _v;
    }
    #___private_showTooltip_13;
    get showTooltip() {
        return this.#___private_showTooltip_13;
    }
    set showTooltip(_v) {
        this.#___private_showTooltip_13 = _v;
    }
    #___private_tipPosition_14;
    get tipPosition() {
        return this.#___private_tipPosition_14;
    }
    set tipPosition(_v) {
        this.#___private_tipPosition_14 = _v;
    }
    #___private_tooltip_15;
    get tooltip() {
        return this.#___private_tooltip_15;
    }
    set tooltip(_v) {
        this.#___private_tooltip_15 = _v;
    }
    #___private_tooltipOffset_16;
    get tooltipOffset() {
        return this.#___private_tooltipOffset_16;
    }
    set tooltipOffset(_v) {
        this.#___private_tooltipOffset_16 = _v;
    }
    #___private_withHover_17;
    get withHover() {
        return this.#___private_withHover_17;
    }
    set withHover(_v) {
        this.#___private_withHover_17 = _v;
    }
}
