import { getCurrentNativeRange } from '@blocksuite/affine-shared/utils';
import { Rect } from '@blocksuite/global/utils';
import { DRAG_HANDLE_CONTAINER_WIDTH, DRAG_HOVER_RECT_PADDING } from '../config.js';
import { containBlock, getDragHandleLeftPadding, includeTextSelection } from '../utils.js';
export class RectHelper {
    constructor(widget){
        this.widget = widget;
        this._getHoveredBlocks = ()=>{
            if (!this.widget.isHoverDragHandleVisible || !this.widget.anchorBlockId) return [];
            const hoverBlock = this.widget.anchorBlockComponent.peek();
            if (!hoverBlock) return [];
            const selections = this.widget.selectionHelper.selectedBlocks;
            let blocks = [];
            if (selections.length > 0 && includeTextSelection(selections)) {
                const range = getCurrentNativeRange();
                if (!range) return [];
                const rangeManager = this.widget.std.range;
                if (!rangeManager) return [];
                blocks = rangeManager.getSelectedBlockComponentsByRange(range, {
                    match: (el)=>el.model.role === 'content',
                    mode: 'highest'
                });
            } else {
                blocks = this.widget.selectionHelper.selectedBlockComponents;
            }
            if (containBlock(blocks.map((block)=>block.blockId), this.widget.anchorBlockId.peek())) {
                return blocks;
            }
            return [
                hoverBlock
            ];
        };
        this.getDraggingAreaRect = ()=>{
            const block = this.widget.anchorBlockComponent.value;
            if (!block) return null;
            let { left, top, right, bottom } = block.getBoundingClientRect();
            const blocks = this._getHoveredBlocks();
            blocks.forEach((block)=>{
                left = Math.min(left, block.getBoundingClientRect().left);
                top = Math.min(top, block.getBoundingClientRect().top);
                right = Math.max(right, block.getBoundingClientRect().right);
                bottom = Math.max(bottom, block.getBoundingClientRect().bottom);
            });
            const offsetLeft = getDragHandleLeftPadding(blocks);
            const offsetParentRect = this.widget.dragHandleContainerOffsetParent.getBoundingClientRect();
            if (!offsetParentRect) return null;
            left -= offsetParentRect.left;
            right -= offsetParentRect.left;
            top -= offsetParentRect.top;
            bottom -= offsetParentRect.top;
            const scaleInNote = this.widget.scaleInNote.value;
            left -= (DRAG_HANDLE_CONTAINER_WIDTH + offsetLeft) * scaleInNote;
            top -= DRAG_HOVER_RECT_PADDING * scaleInNote;
            right += DRAG_HOVER_RECT_PADDING * scaleInNote;
            bottom += DRAG_HOVER_RECT_PADDING * scaleInNote;
            return new Rect(left, top, right, bottom);
        };
    }
}
