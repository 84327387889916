import { useUpgradeNotify } from '@affine/core/components/affine/subscription-landing/notify';
import { SubscriptionPlan, SubscriptionRecurring } from '@affine/graphql';
import { track } from '@affine/track';
import { nanoid } from 'nanoid';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
const separator = '::';
const recoverSeparator = nanoid();
const localStorageKey = 'subscription-succeed-info';
const typeFormUrl = 'https://6dxre9ihosp.typeform.com/to';
const typeFormUpgradeId = 'mUMGGQS8';
const typeFormDowngradeId = 'RvD9AoRg';
const getTypeFormLink = (id, info)=>{
    const plans = Array.isArray(info.plan) ? info.plan : [
        info.plan
    ];
    const product_id = plans.map((plan)=>plan === SubscriptionPlan.AI ? 'ai' : 'cloud').join('-');
    const product_price = info.recurring === SubscriptionRecurring.Monthly ? 'monthly' : info.recurring === SubscriptionRecurring.Lifetime ? 'lifeTime' : 'annually';
    return `${typeFormUrl}/${id}#email=${info.email ?? ''}&name=${info.name ?? 'Unknown'}&user_id=${info.id}&product_id=${product_id}&product_price=${product_price}`;
};
export const getUpgradeQuestionnaireLink = (info)=>getTypeFormLink(typeFormUpgradeId, info);
export const getDowngradeQuestionnaireLink = (info)=>getTypeFormLink(typeFormDowngradeId, info);
export const generateSubscriptionCallbackLink = (account, plan, recurring)=>{
    if (account === null) {
        throw new Error('Account is required');
    }
    const baseUrl = plan === SubscriptionPlan.AI ? '/ai-upgrade-success' : '/upgrade-success';
    let name = account?.info?.name ?? '';
    if (name.includes(separator)) {
        name = name.replaceAll(separator, recoverSeparator);
    }
    const query = [
        plan,
        recurring,
        account.id,
        account.email,
        account.info?.name ?? ''
    ].join(separator);
    return `${baseUrl}?info=${encodeURIComponent(query)}`;
};
export const parseSubscriptionCallbackLink = (query)=>{
    const [plan, recurring, id, email, rawName] = decodeURIComponent(query).split(separator);
    const name = rawName.replaceAll(recoverSeparator, separator);
    return {
        plan: plan,
        recurring: recurring,
        account: {
            id,
            email,
            info: {
                name
            }
        }
    };
};
export const useSubscriptionNotifyWriter = ()=>{
    const [searchParams] = useSearchParams();
    useEffect(()=>{
        const query = searchParams.get('info');
        if (query) {
            localStorage.setItem(localStorageKey, query);
            searchParams.delete('info');
        }
    }, [
        searchParams
    ]);
};
export const useSubscriptionNotifyReader = ()=>{
    const upgradeNotify = useUpgradeNotify();
    const readAndNotify = useCallback(()=>{
        const query = localStorage.getItem(localStorageKey);
        if (!query) return;
        try {
            const { plan, recurring, account } = parseSubscriptionCallbackLink(query);
            const link = getUpgradeQuestionnaireLink({
                id: account.id,
                email: account.email,
                name: account.info?.name ?? '',
                plan,
                recurring
            });
            upgradeNotify(link);
            localStorage.removeItem(localStorageKey);
            track.$.settingsPanel.plans.subscribe({
                plan,
                recurring
            });
        } catch (err) {
            console.error('Failed to parse subscription callback link', err);
        }
    }, [
        upgradeNotify
    ]);
    useEffect(()=>{
        readAndNotify();
        window.addEventListener('focus', readAndNotify);
        return ()=>{
            window.removeEventListener('focus', readAndNotify);
        };
    }, [
        readAndNotify
    ]);
};
