function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init__bottomLine, _init__dragHandle, _init__lineWidthIcons, _init__lineWidthOverlay, _init__lineWidthPanel, _init_disable, _init_hasTooltip, _init_selectedSize, _initProto;
import { LineWidth } from '@blocksuite/affine-model';
import { requestConnectedFrame } from '@blocksuite/affine-shared/utils';
import { WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, queryAll } from 'lit/decorators.js';
export class LineWidthEvent extends Event {
    constructor(type, { detail, composed, bubbles }){
        super(type, {
            bubbles,
            composed
        });
        this.detail = detail;
    }
}
_dec = query('.bottom-line'), _dec1 = query('.drag-handle'), _dec2 = queryAll('.line-width-icon'), _dec3 = query('.line-width-overlay'), _dec4 = query('.line-width-panel'), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
export class EdgelessLineWidthPanel extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__bottomLine, _init__dragHandle, _init__lineWidthIcons, _init__lineWidthOverlay, _init__lineWidthPanel, _init_disable, _init_hasTooltip, _init_selectedSize, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_bottomLine"
            ],
            [
                _dec1,
                1,
                "_dragHandle"
            ],
            [
                _dec2,
                1,
                "_lineWidthIcons"
            ],
            [
                _dec3,
                1,
                "_lineWidthOverlay"
            ],
            [
                _dec4,
                1,
                "_lineWidthPanel"
            ],
            [
                _dec5,
                1,
                "disable"
            ],
            [
                _dec6,
                1,
                "hasTooltip"
            ],
            [
                _dec7,
                1,
                "selectedSize"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
    }

    .line-width-panel {
      width: 108px;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: default;
    }

    .line-width-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      z-index: 2;
    }

    .line-width-icon {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--affine-border-color);
    }

    .line-width-button:nth-child(1) {
      margin-right: 0;
    }

    .line-width-button:nth-child(6) {
      margin-left: 0;
    }

    .drag-handle {
      position: absolute;
      left: 0;
      top: 50%;
      width: 8px;
      height: 8px;
      transform: translateY(-50%) translateX(4px);
      border-radius: 50%;
      background-color: var(--affine-icon-color);
      z-index: 3;
    }

    .bottom-line,
    .line-width-overlay {
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: var(--affine-border-color);
      position: absolute;
    }

    .bottom-line {
      width: calc(100% - 16px);
      background-color: var(--affine-border-color);
    }

    .line-width-overlay {
      width: 0;
      background-color: var(--affine-icon-color);
      z-index: 1;
    }
  `;
    }
    _onSelect(lineWidth) {
        if (lineWidth === this.selectedSize) return;
        this.dispatchEvent(new LineWidthEvent('select', {
            detail: lineWidth,
            composed: true,
            bubbles: true
        }));
        this.selectedSize = lineWidth;
    }
    _updateLineWidthPanel(selectedSize) {
        if (!this._lineWidthOverlay) return;
        let width = 0;
        let dragHandleOffsetX = 0;
        switch(selectedSize){
            case LineWidth.Two:
                width = 0;
                break;
            case LineWidth.Four:
                width = 16;
                dragHandleOffsetX = 1;
                break;
            case LineWidth.Six:
                width = 32;
                dragHandleOffsetX = 2;
                break;
            case LineWidth.Eight:
                width = 48;
                dragHandleOffsetX = 3;
                break;
            case LineWidth.Ten:
                width = 64;
                dragHandleOffsetX = 4;
                break;
            default:
                width = 80;
                dragHandleOffsetX = 4;
        }
        dragHandleOffsetX += 4;
        this._lineWidthOverlay.style.width = `${width}%`;
        this._dragHandle.style.left = `${width}%`;
        this._dragHandle.style.transform = `translateY(-50%) translateX(${dragHandleOffsetX}px)`;
        this._updateIconsColor();
    }
    _updateLineWidthPanelByDragHandlePosition(dragHandlerPosition) {
        let selectedSize = this.selectedSize;
        if (dragHandlerPosition <= 12) {
            selectedSize = LineWidth.Two;
        } else if (dragHandlerPosition > 12 && dragHandlerPosition <= 26) {
            selectedSize = LineWidth.Four;
        } else if (dragHandlerPosition > 26 && dragHandlerPosition <= 40) {
            selectedSize = LineWidth.Six;
        } else if (dragHandlerPosition > 40 && dragHandlerPosition <= 54) {
            selectedSize = LineWidth.Eight;
        } else if (dragHandlerPosition > 54 && dragHandlerPosition <= 68) {
            selectedSize = LineWidth.Ten;
        } else {
            selectedSize = LineWidth.Twelve;
        }
        this._updateLineWidthPanel(selectedSize);
        this._onSelect(selectedSize);
    }
    disconnectedCallback() {
        this._disposables.dispose();
    }
    firstUpdated() {
        this._updateLineWidthPanel(this.selectedSize);
        this._disposables.addFromEvent(this, 'pointerdown', this._onPointerDown);
        this._disposables.addFromEvent(this, 'pointermove', this._onPointerMove);
        this._disposables.addFromEvent(this, 'pointerup', this._onPointerUp);
        this._disposables.addFromEvent(this, 'pointerout', this._onPointerOut);
    }
    render() {
        return html`<style>
        .line-width-panel {
          opacity: ${this.disable ? '0.5' : '1'};
        }
      </style>
      <div
        class="line-width-panel"
        @mousedown="${(e)=>e.preventDefault()}"
      >
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="line-width-button">
          <div class="line-width-icon"></div>
        </div>
        <div class="drag-handle"></div>
        <div class="bottom-line"></div>
        <div class="line-width-overlay"></div>
        ${this.hasTooltip ? html`<affine-tooltip .offset=${8}>Thickness</affine-tooltip>` : nothing}
      </div>`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('selectedSize')) {
            this._updateLineWidthPanel(this.selectedSize);
        }
    }
    #___private__bottomLine_1;
    get _bottomLine() {
        return this.#___private__bottomLine_1;
    }
    set _bottomLine(_v) {
        this.#___private__bottomLine_1 = _v;
    }
    #___private__dragHandle_2;
    get _dragHandle() {
        return this.#___private__dragHandle_2;
    }
    set _dragHandle(_v) {
        this.#___private__dragHandle_2 = _v;
    }
    #___private__lineWidthIcons_3;
    get _lineWidthIcons() {
        return this.#___private__lineWidthIcons_3;
    }
    set _lineWidthIcons(_v) {
        this.#___private__lineWidthIcons_3 = _v;
    }
    #___private__lineWidthOverlay_4;
    get _lineWidthOverlay() {
        return this.#___private__lineWidthOverlay_4;
    }
    set _lineWidthOverlay(_v) {
        this.#___private__lineWidthOverlay_4 = _v;
    }
    #___private__lineWidthPanel_5;
    get _lineWidthPanel() {
        return this.#___private__lineWidthPanel_5;
    }
    set _lineWidthPanel(_v) {
        this.#___private__lineWidthPanel_5 = _v;
    }
    #___private_disable_6;
    get disable() {
        return this.#___private_disable_6;
    }
    set disable(_v) {
        this.#___private_disable_6 = _v;
    }
    #___private_hasTooltip_7;
    get hasTooltip() {
        return this.#___private_hasTooltip_7;
    }
    set hasTooltip(_v) {
        this.#___private_hasTooltip_7 = _v;
    }
    #___private_selectedSize_8;
    get selectedSize() {
        return this.#___private_selectedSize_8;
    }
    set selectedSize(_v) {
        this.#___private_selectedSize_8 = _v;
    }
    constructor(...args){
        super(...args), this._dragConfig = null, this._getDragHandlePosition = (e, config)=>{
            const x = e.clientX;
            const { boundLeft, bottomLineWidth, stepWidth, containerWidth } = config;
            let steps;
            if (x <= boundLeft) {
                steps = 0;
            } else if (x - boundLeft >= containerWidth) {
                steps = 100;
            } else {
                steps = Math.floor((x - boundLeft) / stepWidth);
            }
            const bottomLineOffsetX = 4;
            const bottomLineStepWidth = (bottomLineWidth - bottomLineOffsetX) / 100;
            const dragHandlerPosition = steps * bottomLineStepWidth;
            return dragHandlerPosition;
        }, this._onPointerDown = (e)=>{
            e.preventDefault();
            if (this.disable) return;
            const { left, width } = this._lineWidthPanel.getBoundingClientRect();
            const bottomLineWidth = this._bottomLine.getBoundingClientRect().width;
            this._dragConfig = {
                stepWidth: width / 100,
                boundLeft: left,
                containerWidth: width,
                bottomLineWidth
            };
            this._onPointerMove(e);
        }, this._onPointerMove = (e)=>{
            e.preventDefault();
            if (!this._dragConfig) return;
            const dragHandlerPosition = this._getDragHandlePosition(e, this._dragConfig);
            this._dragHandle.style.left = `${dragHandlerPosition}%`;
            this._lineWidthOverlay.style.width = `${dragHandlerPosition}%`;
            this._updateIconsColor();
        }, this._onPointerOut = (e)=>{
            e.preventDefault();
            if (!this._dragConfig) return;
            const dragHandlerPosition = this._getDragHandlePosition(e, this._dragConfig);
            this._updateLineWidthPanelByDragHandlePosition(dragHandlerPosition);
            this._dragConfig = null;
        }, this._onPointerUp = (e)=>{
            e.preventDefault();
            if (!this._dragConfig) return;
            const dragHandlerPosition = this._getDragHandlePosition(e, this._dragConfig);
            this._updateLineWidthPanelByDragHandlePosition(dragHandlerPosition);
            this._dragConfig = null;
        }, this._updateIconsColor = ()=>{
            if (!this._dragHandle.offsetParent) {
                requestConnectedFrame(()=>this._updateIconsColor(), this);
                return;
            }
            const dragHandleRect = this._dragHandle.getBoundingClientRect();
            const dragHandleCenterX = dragHandleRect.left + dragHandleRect.width / 2;
            const leftIcons = [];
            const rightIcons = [];
            for (const icon of this._lineWidthIcons){
                const { left, width } = icon.getBoundingClientRect();
                const centerX = left + width / 2;
                if (centerX < dragHandleCenterX) {
                    leftIcons.push(icon);
                } else {
                    rightIcons.push(icon);
                }
            }
            leftIcons.forEach((icon)=>icon.style.backgroundColor = 'var(--affine-icon-color)');
            rightIcons.forEach((icon)=>icon.style.backgroundColor = 'var(--affine-border-color)');
        }, this.#___private__bottomLine_1 = (_initProto(this), _init__bottomLine(this)), this.#___private__dragHandle_2 = _init__dragHandle(this), this.#___private__lineWidthIcons_3 = _init__lineWidthIcons(this), this.#___private__lineWidthOverlay_4 = _init__lineWidthOverlay(this), this.#___private__lineWidthPanel_5 = _init__lineWidthPanel(this), this.#___private_disable_6 = _init_disable(this, false), this.#___private_hasTooltip_7 = _init_hasTooltip(this, true), this.#___private_selectedSize_8 = _init_selectedSize(this, LineWidth.Two);
    }
}
