import { jsx as _jsx } from "react/jsx-runtime";
import { useLiveData } from '@toeverything/infra';
import * as styles from './unread-bedge.css';
export const UnreadBadge = ({ node })=>{
    const unreadCount = useLiveData(node.unreadCount$);
    if (unreadCount === 0) {
        return null;
    }
    return _jsx("div", {
        className: styles.unreadBadge,
        children: unreadCount
    });
};
