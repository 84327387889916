import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const HomeIcon = ({ size = '1em', className, onClick })=>{
    return _jsxs("svg", {
        width: size,
        height: size,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        className: className,
        onClick: onClick,
        children: [
            _jsx("path", {
                d: "M24 0V24H0V0H24Z",
                fill: "currentColor",
                fillOpacity: "0.01"
            }),
            _jsx("path", {
                d: "M3.66581 9.94895C3.18199 9.94895 2.94008 9.94895 2.89373 9.81386C2.84738 9.67876 3.03833 9.53024 3.42024 9.23321L8.31636 5.42512C10.0859 4.0488 10.9707 3.36064 12 3.36064C13.0293 3.36064 13.9141 4.0488 15.6836 5.42511L20.5798 9.23321C20.9617 9.53024 21.1526 9.67876 21.1063 9.81386C21.0599 9.94895 20.818 9.94895 20.3342 9.94895H19.7889C19.6003 9.94895 19.506 9.94895 19.4475 10.0075C19.3889 10.0661 19.3889 10.1604 19.3889 10.3489V13.1156C19.3889 16.2963 19.3889 17.8867 18.5356 18.974C18.3378 19.226 18.1104 19.4534 17.8584 19.6512C16.7711 20.5045 15.1807 20.5045 12 20.5045V20.5045C8.81928 20.5045 7.22892 20.5045 6.1416 19.6512C5.88958 19.4534 5.66223 19.226 5.46445 18.974C4.61111 17.8867 4.61111 16.2963 4.61111 13.1156V10.3489C4.61111 10.1604 4.61111 10.0661 4.55253 10.0075C4.49395 9.94895 4.39967 9.94895 4.21111 9.94895H3.66581Z",
                stroke: "currentColor",
                strokeWidth: "1.5",
                strokeLinejoin: "round"
            }),
            _jsx("path", {
                d: "M10 14.0002C10 12.8957 10.8954 12.0002 12 12.0002V12.0002C13.1046 12.0002 14 12.8957 14 14.0002V19.4444C14 19.942 14 20.1908 13.8454 20.3454C13.6908 20.5 13.442 20.5 12.9444 20.5H11.0556C10.558 20.5 10.3092 20.5 10.1546 20.3454C10 20.1908 10 19.942 10 19.4444V14.0002Z",
                stroke: "currentColor",
                strokeWidth: "1.5",
                strokeLinejoin: "round"
            })
        ]
    });
};
