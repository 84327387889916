import { DocDisplayMetaService } from '@affine/core/modules/doc-display-meta';
import { WorkspacePropertiesAdapter } from '@affine/core/modules/properties';
import { I18n } from '@affine/i18n';
import { track } from '@affine/track';
import { LinkedWidgetUtils } from '@blocksuite/affine/blocks';
import { WorkspaceService } from '@toeverything/infra';
export function createLinkedWidgetConfig(framework) {
    return {
        getMenus: (query, abort, editorHost, inlineEditor)=>{
            const currentWorkspace = framework.get(WorkspaceService).workspace;
            const rawMetas = currentWorkspace.docCollection.meta.docMetas;
            const adapter = framework.get(WorkspacePropertiesAdapter);
            const isJournal = (d)=>!!adapter.getJournalPageDateString(d.id);
            const docDisplayMetaService = framework.get(DocDisplayMetaService);
            const docMetas = rawMetas.filter((meta)=>{
                if (isJournal(meta) && !meta.updatedDate) {
                    return false;
                }
                return !meta.trash;
            }).map((meta)=>{
                const title = docDisplayMetaService.title$(meta.id).value;
                return {
                    ...meta,
                    title: typeof title === 'string' ? title : I18n[title.key]()
                };
            }).filter(({ title })=>isFuzzyMatch(title, query));
            const MAX_DOCS = 6;
            return Promise.resolve([
                {
                    name: 'Link to Doc',
                    items: docMetas.map((doc)=>({
                            key: doc.id,
                            name: doc.title,
                            icon: docDisplayMetaService.icon$(doc.id, {
                                type: 'lit',
                                reference: true
                            }).value(),
                            action: ()=>{
                                abort();
                                LinkedWidgetUtils.insertLinkedNode({
                                    inlineEditor,
                                    docId: doc.id
                                });
                                track.doc.editor.atMenu.linkDoc();
                            }
                        })),
                    maxDisplay: MAX_DOCS,
                    overflowText: `${docMetas.length - MAX_DOCS} more docs`
                },
                LinkedWidgetUtils.createNewDocMenuGroup(query, abort, editorHost, inlineEditor)
            ]);
        }
    };
}
function isFuzzyMatch(name, query) {
    const pureName = name.trim().toLowerCase().split('').filter((char)=>char !== ' ').join('');
    const regex = new RegExp(query.split('').filter((char)=>char !== ' ').map((item)=>`${escapeRegExp(item)}.*`).join(''), 'i');
    return regex.test(pureName);
}
function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
