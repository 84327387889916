import { ColorScheme } from '@blocksuite/affine-model';
import { signal } from '@preact/signals-core';
const COLOR_SCHEMES = Object.values(ColorScheme);
const TRANSPARENT = 'transparent';
export class ThemeObserver {
    static #computedStyle;
    static #instance;
    #observer;
    static get computedStyle() {
        let computedStyle = ThemeObserver.#computedStyle;
        if (!computedStyle) {
            computedStyle = window.getComputedStyle(document.documentElement);
            ThemeObserver.#computedStyle = computedStyle;
        }
        return computedStyle;
    }
    static get instance() {
        if (!ThemeObserver.#instance) {
            const instance = new ThemeObserver();
            instance.observe(document.documentElement);
            ThemeObserver.#instance = instance;
        }
        return ThemeObserver.#instance;
    }
    static get mode() {
        return ThemeObserver.instance.mode$.peek();
    }
    static generateColorProperty(color, fallback = 'transparent', mode = ThemeObserver.mode) {
        let result = undefined;
        if (typeof color === 'object') {
            result = color[mode] ?? color.normal;
        } else {
            result = color;
        }
        if (!result) {
            result = fallback;
        }
        if (result.startsWith('--')) {
            return result.endsWith(TRANSPARENT) ? TRANSPARENT : `var(${result})`;
        }
        return result ?? TRANSPARENT;
    }
    static getColorValue(color, fallback = TRANSPARENT, real = false, mode = ThemeObserver.mode) {
        let result = undefined;
        if (typeof color === 'object') {
            result = color[mode] ?? color.normal;
        } else {
            result = color;
        }
        if (!result) {
            result = fallback;
        }
        if (real && result.startsWith('--')) {
            result = result.endsWith(TRANSPARENT) ? TRANSPARENT : ThemeObserver.getPropertyValue(result);
        }
        return result ?? TRANSPARENT;
    }
    static getPropertyValue(property) {
        if (property.startsWith('--')) {
            if (property.endsWith(TRANSPARENT)) {
                return TRANSPARENT;
            }
            return ThemeObserver.computedStyle.getPropertyValue(property).trim() || property;
        }
        return property;
    }
    static subscribe(callback) {
        return ThemeObserver.instance.mode$.subscribe(callback);
    }
    disconnect() {
        this.#observer?.disconnect();
    }
    observe(element) {
        const callback = ()=>{
            const mode = element.dataset.theme;
            if (!mode) return;
            if (!COLOR_SCHEMES.includes(mode)) return;
            if (mode === this.mode$.value) return;
            this.mode$.value = mode;
        };
        this.#observer?.disconnect();
        this.#observer = new MutationObserver(callback);
        this.#observer.observe(element, {
            attributes: true,
            attributeFilter: [
                'data-theme'
            ]
        });
        callback();
    }
    constructor(){
        this.mode$ = signal(ColorScheme.Light);
    }
}
