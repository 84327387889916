import { jsx as _jsx } from "react/jsx-runtime";
import { UserFeatureService } from '@affine/core/modules/cloud/services/user-feature';
import { useI18n } from '@affine/i18n';
import { AppearanceIcon, ExperimentIcon, InformationIcon, KeyboardIcon, PenIcon } from '@blocksuite/icons/rc';
import { FeatureFlagService, useLiveData, useServices } from '@toeverything/infra';
import { useEffect } from 'react';
import { AuthService, ServerConfigService } from '../../../../modules/cloud';
import { AboutAffine } from './about';
import { AppearanceSettings } from './appearance';
import { BillingSettings } from './billing';
import { EditorSettings } from './editor';
import { ExperimentalFeatures } from './experimental-features';
import { PaymentIcon, UpgradeIcon } from './icons';
import { AFFiNEPricingPlans } from './plans';
import { Shortcuts } from './shortcuts';
export const useGeneralSettingList = ()=>{
    const t = useI18n();
    const { authService, serverConfigService, userFeatureService, featureFlagService } = useServices({
        AuthService,
        ServerConfigService,
        UserFeatureService,
        FeatureFlagService
    });
    const status = useLiveData(authService.session.status$);
    const hasPaymentFeature = useLiveData(serverConfigService.serverConfig.features$.map((f)=>f?.payment));
    const enableEditorSettings = useLiveData(featureFlagService.flags.enable_editor_settings.$);
    useEffect(()=>{
        userFeatureService.userFeature.revalidate();
    }, [
        userFeatureService
    ]);
    const settings = [
        {
            key: 'appearance',
            title: t['com.affine.settings.appearance'](),
            icon: AppearanceIcon,
            testId: 'appearance-panel-trigger'
        },
        {
            key: 'shortcuts',
            title: t['com.affine.keyboardShortcuts.title'](),
            icon: KeyboardIcon,
            testId: 'shortcuts-panel-trigger'
        },
        {
            key: 'about',
            title: t['com.affine.aboutAFFiNE.title'](),
            icon: InformationIcon,
            testId: 'about-panel-trigger'
        }
    ];
    if (enableEditorSettings) {
        settings.splice(1, 0, {
            key: 'editor',
            title: t['com.affine.settings.editorSettings'](),
            icon: PenIcon,
            testId: 'editor-panel-trigger'
        });
    }
    if (hasPaymentFeature) {
        settings.splice(3, 0, {
            key: 'plans',
            title: t['com.affine.payment.title'](),
            icon: UpgradeIcon,
            testId: 'plans-panel-trigger'
        });
        if (status === 'authenticated') {
            settings.splice(3, 0, {
                key: 'billing',
                title: t['com.affine.payment.billing-setting.title'](),
                icon: PaymentIcon,
                testId: 'billing-panel-trigger'
            });
        }
    }
    settings.push({
        key: 'experimental-features',
        title: t['com.affine.settings.workspace.experimental-features'](),
        icon: ExperimentIcon,
        testId: 'experimental-features-trigger'
    });
    return settings;
};
export const GeneralSetting = ({ generalKey })=>{
    switch(generalKey){
        case 'shortcuts':
            return _jsx(Shortcuts, {});
        case 'editor':
            return _jsx(EditorSettings, {});
        case 'appearance':
            return _jsx(AppearanceSettings, {});
        case 'about':
            return _jsx(AboutAffine, {});
        case 'plans':
            return _jsx(AFFiNEPricingPlans, {});
        case 'billing':
            return _jsx(BillingSettings, {});
        case 'experimental-features':
            return _jsx(ExperimentalFeatures, {});
        default:
            return null;
    }
};
