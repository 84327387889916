import { jsx as _jsx } from "react/jsx-runtime";
import { CloudWorkspaceIcon, CreatedIcon, FavoriteIcon, TagsIcon, UpdatedIcon } from '@blocksuite/icons/rc';
import { tBoolean, tDate, tTag } from './logical/custom-type';
import { tArray } from './logical/typesystem';
export const toLiteral = (value)=>({
        type: 'literal',
        value
    });
export const variableDefineMap = {
    Created: {
        type: ()=>tDate.create(),
        icon: _jsx(CreatedIcon, {})
    },
    Updated: {
        type: ()=>tDate.create(),
        icon: _jsx(UpdatedIcon, {})
    },
    'Is Favourited': {
        type: ()=>tBoolean.create(),
        icon: _jsx(FavoriteIcon, {})
    },
    Tags: {
        type: (meta)=>tArray(tTag.create({
                tags: meta.tags?.options.filter((t)=>{
                    if (t.ghost === undefined) {
                        return true;
                    }
                    return !t.ghost;
                }) ?? []
            })),
        icon: _jsx(TagsIcon, {})
    },
    'Is Public': {
        type: ()=>tBoolean.create(),
        icon: _jsx(CloudWorkspaceIcon, {})
    }
};
