function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init_containerClass, _init_data, _init_onBack, _init_setData, _init_vars, _initProto;
import { popFilterableSimpleMenu } from '@blocksuite/affine-components/context-menu';
import { ShadowlessElement } from '@blocksuite/block-std';
import { WithDisposable } from '@blocksuite/global/utils';
import { ArrowDownSmallIcon, ConvertIcon, DeleteIcon, DuplicateIcon, MoreHorizontalIcon, PlusIcon } from '@blocksuite/icons/lit';
import { css, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { menuTitle } from '../../core/utils/menu-title.js';
import { popAddNewFilter } from './condition.js';
import { getDepth } from './filter-group.js';
_dec = state(), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
export class FilterRootView extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init_containerClass, _init_data, _init_onBack, _init_setData, _init_vars, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "containerClass"
            ],
            [
                _dec1,
                1,
                "data"
            ],
            [
                _dec2,
                1,
                "onBack"
            ],
            [
                _dec3,
                1,
                "setData"
            ],
            [
                _dec4,
                1,
                "vars"
            ]
        ], []));
    }
    static{
        this.styles = css`
    filter-root-view {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      user-select: none;
    }

    .filter-root-title {
      padding: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: var(--affine-text-primary-color);
    }

    .filter-root-op {
      width: 60px;
      display: flex;
      justify-content: end;
      padding: 4px;
      height: 34px;
      align-items: center;
    }

    .filter-root-op-clickable {
      border-radius: 4px;
      cursor: pointer;
    }

    .filter-root-op-clickable:hover {
      background-color: var(--affine-hover-color);
    }

    .filter-root-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: 400px;
      overflow: auto;
      padding: 0 12px 0 8px;
    }

    .filter-root-button {
      margin: 4px 8px 8px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      line-height: 22px;
      border-radius: 4px;
      cursor: pointer;
      color: var(--affine-text-secondary-color);
    }

    .filter-root-button svg {
      fill: var(--affine-text-secondary-color);
      color: var(--affine-text-secondary-color);
      width: 20px;
      height: 20px;
    }

    .filter-root-button:hover {
      background-color: var(--affine-hover-color);
      color: var(--affine-text-primary-color);
    }
    .filter-root-button:hover svg {
      fill: var(--affine-text-primary-color);
      color: var(--affine-text-primary-color);
    }

    .filter-root-item {
      padding: 4px 0;
      display: flex;
      align-items: start;
      gap: 8px;
    }

    .filter-group-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: var(--affine-text-primary-color);
      gap: 6px;
    }

    .filter-root-item-ops {
      margin-top: 2px;
      padding: 4px;
      border-radius: 4px;
      height: max-content;
      display: flex;
      cursor: pointer;
    }

    .filter-root-item-ops:hover {
      background-color: var(--affine-hover-color);
    }

    .filter-root-item-ops svg {
      fill: var(--affine-text-secondary-color);
      color: var(--affine-text-secondary-color);
      width: 18px;
      height: 18px;
    }
    .filter-root-item-ops:hover svg {
      fill: var(--affine-text-primary-color);
      color: var(--affine-text-primary-color);
    }

    .filter-root-grabber {
      cursor: grab;
      width: 4px;
      height: 12px;
      background-color: var(--affine-placeholder-color);
      border-radius: 1px;
    }

    .divider {
      height: 1px;
      background-color: var(--affine-divider-color);
      flex-shrink: 0;
      margin: 8px 0;
    }
  `;
    }
    _clickConditionOps(target, i) {
        const filter = this.data.conditions[i];
        popFilterableSimpleMenu(target, [
            {
                type: 'action',
                name: filter.type === 'filter' ? 'Turn into group' : 'Wrap in group',
                icon: ConvertIcon(),
                onHover: (hover)=>{
                    this.containerClass = hover ? {
                        index: i,
                        class: 'hover-style'
                    } : undefined;
                },
                hide: ()=>getDepth(filter) > 3,
                select: ()=>{
                    this.setData({
                        type: 'group',
                        op: 'and',
                        conditions: [
                            this.data
                        ]
                    });
                }
            },
            {
                type: 'action',
                name: 'Duplicate',
                icon: DuplicateIcon(),
                onHover: (hover)=>{
                    this.containerClass = hover ? {
                        index: i,
                        class: 'hover-style'
                    } : undefined;
                },
                select: ()=>{
                    const conditions = [
                        ...this.data.conditions
                    ];
                    conditions.splice(i + 1, 0, JSON.parse(JSON.stringify(conditions[i])));
                    this.setData({
                        ...this.data,
                        conditions: conditions
                    });
                }
            },
            {
                type: 'group',
                name: '',
                children: ()=>[
                        {
                            type: 'action',
                            name: 'Delete',
                            icon: DeleteIcon(),
                            class: 'delete-item',
                            onHover: (hover)=>{
                                this.containerClass = hover ? {
                                    index: i,
                                    class: 'delete-style'
                                } : undefined;
                            },
                            select: ()=>{
                                const conditions = [
                                    ...this.data.conditions
                                ];
                                conditions.splice(i, 1);
                                this.setData({
                                    ...this.data,
                                    conditions
                                });
                            }
                        }
                    ]
            }
        ]);
    }
    render() {
        const data = this.data;
        return html`
      <div style="padding: 15px 20px">
        ${menuTitle('FILTER', ()=>{
            this.onBack?.();
        })}
      </div>
      <div class="filter-root-container">
        ${repeat(data.conditions, (filter, i)=>{
            const clickOps = (e)=>{
                e.stopPropagation();
                e.preventDefault();
                this._clickConditionOps(e.target, i);
            };
            const ops = html`
            <div class="filter-root-item-ops" @click="${clickOps}">
              ${MoreHorizontalIcon()}
            </div>
          `;
            const content = filter.type === 'filter' ? html`
                  <div
                    style="display:flex;align-items:center;justify-content: space-between;width: 100%;gap:8px;"
                  >
                    <div style="display:flex;align-items:center;gap:6px;">
                      <div class="filter-root-grabber"></div>
                      <filter-condition-view
                        .setData="${(v)=>this._setFilter(i, v)}"
                        .vars="${this.vars}"
                        .data="${filter}"
                      ></filter-condition-view>
                    </div>
                    ${ops}
                  </div>
                ` : html`
                  <div style="width: 100%;">
                    <div
                      style="display:flex;align-items:center;justify-content: space-between;gap:8px;padding: 4px 4px 0 4px;"
                    >
                      <div class="filter-group-title">
                        <div class="filter-root-grabber"></div>
                        Filter group
                      </div>
                      ${ops}
                    </div>
                    <div style="width: 100%;padding: 12px 0 0;">
                      <filter-group-view
                        style="padding: 0"
                        .setData="${(v)=>this._setFilter(i, v)}"
                        .vars="${this.vars}"
                        .data="${filter}"
                      ></filter-group-view>
                    </div>
                  </div>
                `;
            const classList = classMap({
                'filter-root-item': true,
                'filter-exactly-hover-container': true,
                'dv-pd-4 dv-round-4': true,
                [this.containerClass?.class ?? '']: this.containerClass?.index === i
            });
            return html` ${data.conditions[i - 1]?.type === 'group' || filter.type === 'group' ? html`<div class="divider"></div>` : nothing}
            <div @contextmenu=${clickOps} class="${classList}">
              ${content}
            </div>`;
        })}
      </div>
      <div class="filter-root-button add-new" @click="${this._addNew}">
        ${PlusIcon()} Add ${ArrowDownSmallIcon()}
      </div>
    `;
    }
    #___private_containerClass_1;
    get containerClass() {
        return this.#___private_containerClass_1;
    }
    set containerClass(_v) {
        this.#___private_containerClass_1 = _v;
    }
    #___private_data_2;
    get data() {
        return this.#___private_data_2;
    }
    set data(_v) {
        this.#___private_data_2 = _v;
    }
    #___private_onBack_3;
    get onBack() {
        return this.#___private_onBack_3;
    }
    set onBack(_v) {
        this.#___private_onBack_3 = _v;
    }
    #___private_setData_4;
    get setData() {
        return this.#___private_setData_4;
    }
    set setData(_v) {
        this.#___private_setData_4 = _v;
    }
    #___private_vars_5;
    get vars() {
        return this.#___private_vars_5;
    }
    set vars(_v) {
        this.#___private_vars_5 = _v;
    }
    constructor(...args){
        super(...args), this._addNew = (e)=>{
            popAddNewFilter(e.target, {
                value: this.data,
                onChange: this.setData,
                vars: this.vars
            });
        }, this._setFilter = (index, filter)=>{
            this.setData({
                ...this.data,
                conditions: this.data.conditions.map((v, i)=>index === i ? filter : v)
            });
        }, this.#___private_containerClass_1 = (_initProto(this), _init_containerClass(this, undefined)), this.#___private_data_2 = _init_data(this), this.#___private_onBack_3 = _init_onBack(this), this.#___private_setData_4 = _init_setData(this), this.#___private_vars_5 = _init_vars(this);
    }
}
