import { Entity, LiveData } from '@toeverything/infra';
export class ImportTemplateDialog extends Entity {
    open(options) {
        this.template$.next(options);
        this.isOpen$.next(true);
    }
    close() {
        this.isOpen$.next(false);
    }
    constructor(...args){
        super(...args), this.isOpen$ = new LiveData(false), this.template$ = new LiveData(null);
    }
}
