import { Entity, LiveData } from '@toeverything/infra';
import { map } from 'rxjs';
var APP_SIDEBAR_STATE;
(function(APP_SIDEBAR_STATE) {
    APP_SIDEBAR_STATE["OPEN"] = "open";
    APP_SIDEBAR_STATE["WIDTH"] = "width";
})(APP_SIDEBAR_STATE || (APP_SIDEBAR_STATE = {}));
export class AppSidebar extends Entity {
    constructor(appSidebarState){
        super(), this.appSidebarState = appSidebarState, this.open$ = LiveData.from(this.appSidebarState.watch("open").pipe(map((value)=>value ?? true)), true), this.width$ = LiveData.from(this.appSidebarState.watch("width").pipe(map((value)=>value ?? 248)), 248), this.hovering$ = new LiveData(false), this.smallScreenMode$ = new LiveData(false), this.resizing$ = new LiveData(false), this.getCachedAppSidebarOpenState = ()=>{
            return this.appSidebarState.get("open");
        }, this.toggleSidebar = ()=>{
            this.setOpen(!this.open$.value);
        }, this.setOpen = (open)=>{
            this.appSidebarState.set("open", open);
            return;
        }, this.setSmallScreenMode = (smallScreenMode)=>{
            this.smallScreenMode$.next(smallScreenMode);
        }, this.setHovering = (hoverFloating)=>{
            this.hovering$.next(hoverFloating);
        }, this.setResizing = (resizing)=>{
            this.resizing$.next(resizing);
        }, this.setWidth = (width)=>{
            this.appSidebarState.set("width", width);
        };
    }
}
