export function countBy(items, key) {
    const count = {};
    items.forEach((item)=>{
        const k = key(item);
        if (k === null) return;
        if (!count[k]) {
            count[k] = 0;
        }
        count[k] += 1;
    });
    return count;
}
export function maxBy(items, value) {
    if (!items.length) {
        return null;
    }
    let maxItem = items[0];
    let max = value(maxItem);
    for(let i = 1; i < items.length; i++){
        const item = items[i];
        const v = value(item);
        if (v > max) {
            max = v;
            maxItem = item;
        }
    }
    return maxItem;
}
export function atLeastNMatches(arr, matchFn, n) {
    let count = 0;
    for(let i = 0; i < arr.length; i++){
        if (matchFn(arr[i])) {
            count++;
            if (count >= n) {
                return true;
            }
        }
    }
    return false;
}
export function groupBy(arr, key) {
    const result = {};
    for (const item of arr){
        const groupKey = typeof key === 'function' ? key(item) : item[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(item);
    }
    return result;
}
export function pickArray(target, keys) {
    return keys.reduce((pre, key)=>{
        pre.push(target[key]);
        return pre;
    }, []);
}
export function pick(target, keys) {
    return keys.reduce((pre, key)=>{
        pre[key] = target[key];
        return pre;
    }, {});
}
export function pickValues(target, keys) {
    return keys.reduce((pre, key)=>{
        pre.push(target[key]);
        return pre;
    }, []);
}
export function lastN(target, n) {
    return target.slice(target.length - n, target.length);
}
export function isEmpty(obj) {
    if (Object.getPrototypeOf(obj) === Object.prototype) {
        return Object.keys(obj).length === 0;
    }
    if (Array.isArray(obj) || typeof obj === 'string') {
        return obj.length === 0;
    }
    return false;
}
export function keys(obj) {
    return Object.keys(obj);
}
export function values(obj) {
    return Object.values(obj);
}
export function last(iterable) {
    if (Array.isArray(iterable)) {
        return iterable[iterable.length - 1];
    }
    let last;
    for (const item of iterable){
        last = item;
    }
    return last;
}
export function nToLast(iterable, n) {
    if (Array.isArray(iterable)) {
        return iterable[iterable.length - n];
    }
    const arr = [
        ...iterable
    ];
    return arr[arr.length - n];
}
