import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef } from 'react';
import * as styles from './style.css';
export const Divider = forwardRef(({ orientation = 'horizontal', size = 'default', dividerColor = 'var(--affine-border-color)', style, className, ...otherProps }, ref)=>{
    return _jsx("div", {
        "data-divider": true,
        ref: ref,
        className: clsx(styles.divider, {
            [styles.verticalDivider]: orientation === 'vertical',
            [styles.thinner]: size === 'thinner'
        }, className),
        style: {
            backgroundColor: dividerColor ? dividerColor : undefined,
            ...style
        },
        ...otherProps
    });
});
Divider.displayName = 'Divider';
export default Divider;
