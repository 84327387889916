import { Entity } from '../../../framework';
import { AwarenessEngine, BlobEngine, DocEngine } from '../../../sync';
import { throwIfAborted } from '../../../utils';
import { WorkspaceEngineBeforeStart } from '../events';
export class WorkspaceEngine extends Entity {
    constructor(workspaceService){
        super(), this.workspaceService = workspaceService, this.doc = new DocEngine(this.props.engineProvider.getDocStorage(), this.props.engineProvider.getDocServer()), this.blob = new BlobEngine(this.props.engineProvider.getLocalBlobStorage(), this.props.engineProvider.getRemoteBlobStorages()), this.awareness = new AwarenessEngine(this.props.engineProvider.getAwarenessConnections()), this.docEngineState$ = this.doc.engineState$, this.rootDocState$ = this.doc.docState$(this.workspaceService.workspace.id);
    }
    setRootDoc(yDoc) {
        this.doc.setPriority(yDoc.guid, 100);
        this.doc.addDoc(yDoc);
    }
    start() {
        this.eventBus.emit(WorkspaceEngineBeforeStart, this);
        this.doc.start();
        this.awareness.connect(this.workspaceService.workspace.awareness);
        this.blob.start();
    }
    canGracefulStop() {
        return this.doc.engineState$.value.saving === 0;
    }
    async waitForGracefulStop(abort) {
        await this.doc.waitForSaved();
        throwIfAborted(abort);
        this.forceStop();
    }
    forceStop() {
        this.doc.stop();
        this.awareness.disconnect();
        this.blob.stop();
    }
    waitForDocSynced() {
        return this.doc.waitForSynced();
    }
    waitForRootDocReady() {
        return this.doc.waitForReady(this.workspaceService.workspace.id);
    }
    dispose() {
        this.forceStop();
        this.doc.dispose();
        this.awareness.dispose();
    }
}
