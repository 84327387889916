import { html } from 'lit';
export const ChatWithAIIcon = html`<svg
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.59593 7.38585C2.75058 6.62196 3.25841 5.16699 4.43763 5.16699H16.3017C17.2852 5.16699 17.8664 6.23248 17.3995 7.06491L11.5806 17.4378C11.0097 18.4556 9.51091 18.2189 9.25406 17.098L7.9249 11.2976L3.59593 7.38585ZM9.20223 11.2755L10.4725 16.8188C10.4742 16.8262 10.4759 16.8301 10.4767 16.8316C10.4777 16.8321 10.4796 16.8329 10.4827 16.8334C10.4839 16.8336 10.4849 16.8337 10.4857 16.8337C10.4869 16.8321 10.4885 16.8297 10.4904 16.8263L15.7266 7.492L9.20223 11.2755ZM15.0887 6.41699H4.43763C4.4362 6.41699 4.43499 6.41703 4.434 6.41709C4.43249 6.41912 4.43033 6.42258 4.42836 6.42784C4.42439 6.43845 4.42506 6.44624 4.42551 6.44838C4.42564 6.44898 4.42571 6.4491 4.42586 6.44937L4.42588 6.44939C4.42593 6.44949 4.42768 6.4527 4.434 6.45841L8.57091 10.1967L15.0887 6.41699Z"
  />
</svg>`;
export const AffineAIIcon = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.2812 5.49104C11.2403 5.13024 10.9353 4.85751 10.5722 4.85714C10.2091 4.85677 9.90345 5.12887 9.86185 5.48959C9.59131 7.83515 8.89003 9.48448 7.75868 10.6158C6.62734 11.7472 4.97801 12.4485 2.63244 12.719C2.27173 12.7606 1.99963 13.0662 2 13.4293C2.00037 13.7924 2.2731 14.0975 2.63389 14.1383C4.94069 14.3996 6.62508 15.1006 7.78328 16.2379C8.93713 17.3709 9.65305 19.0198 9.85994 21.3489C9.89271 21.7178 10.2019 22.0004 10.5722 22C10.9425 21.9996 11.2511 21.7162 11.2831 21.3473C11.4813 19.0565 12.1966 17.3729 13.3562 16.2133C14.5157 15.0537 16.1994 14.3385 18.4902 14.1402C18.8591 14.1083 19.1424 13.7997 19.1429 13.4294C19.1433 13.0591 18.8606 12.7499 18.4918 12.7171C16.1627 12.5102 14.5137 11.7943 13.3807 10.6404C12.2435 9.48222 11.5425 7.79783 11.2812 5.49104Z"
  />
  <path
    d="M18.9427 2.24651C18.9268 2.1062 18.8082 2.00014 18.667 2C18.5257 1.99986 18.4069 2.10567 18.3907 2.24595C18.2855 3.15811 18.0128 3.79952 17.5728 4.23949C17.1329 4.67946 16.4914 4.95218 15.5793 5.05739C15.439 5.07356 15.3332 5.19241 15.3333 5.33362C15.3335 5.47482 15.4395 5.59345 15.5798 5.60935C16.4769 5.71096 17.132 5.98357 17.5824 6.42584C18.0311 6.86644 18.3095 7.50771 18.39 8.41347C18.4027 8.55691 18.523 8.66683 18.667 8.66667C18.811 8.6665 18.931 8.55632 18.9434 8.41284C19.0205 7.52199 19.2987 6.86723 19.7496 6.41629C20.2006 5.96534 20.8553 5.68719 21.7462 5.61008C21.8896 5.59766 21.9998 5.47765 22 5.33365C22.0002 5.18964 21.8902 5.06939 21.7468 5.05664C20.841 4.97619 20.1998 4.69777 19.7592 4.24905C19.3169 3.79864 19.0443 3.1436 18.9427 2.24651Z"
  />
</svg> `;
export const ImageLoadingFailedIcon = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.1665 3.99984C2.1665 2.98732 2.98732 2.1665 3.99984 2.1665H11.9998C13.0124 2.1665 13.8332 2.98732 13.8332 3.99984V7.33317C13.8332 7.60931 13.6093 7.83317 13.3332 7.83317C13.057 7.83317 12.8332 7.60931 12.8332 7.33317V3.99984C12.8332 3.5396 12.4601 3.1665 11.9998 3.1665H3.99984C3.5396 3.1665 3.1665 3.5396 3.1665 3.99984V9.4594L5.37014 7.25576C6.0861 6.5398 7.2469 6.5398 7.96287 7.25576L8.35339 7.64628C8.54865 7.84155 8.54865 8.15813 8.35339 8.35339C8.15813 8.54865 7.84155 8.54865 7.64628 8.35339L7.25576 7.96287C6.93032 7.63743 6.40268 7.63743 6.07725 7.96287L3.1665 10.8736V11.9998C3.1665 12.4601 3.5396 12.8332 3.99984 12.8332H7.33317C7.60931 12.8332 7.83317 13.057 7.83317 13.3332C7.83317 13.6093 7.60931 13.8332 7.33317 13.8332H3.99984C2.98732 13.8332 2.1665 13.0124 2.1665 11.9998V3.99984Z"
    fill="currentColor"
    fill-opacity="0.6"
  />
  <path
    d="M9.99984 5.33317C9.99984 5.70136 9.70136 5.99984 9.33317 5.99984C8.96498 5.99984 8.6665 5.70136 8.6665 5.33317C8.6665 4.96498 8.96498 4.6665 9.33317 4.6665C9.70136 4.6665 9.99984 4.96498 9.99984 5.33317Z"
    fill="currentColor"
    fill-opacity="0.6"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.97962 8.97962C9.17488 8.78435 9.49146 8.78435 9.68672 8.97962L11.3332 10.6261L12.9796 8.97962C13.1749 8.78435 13.4915 8.78435 13.6867 8.97962C13.882 9.17488 13.882 9.49146 13.6867 9.68672L12.0403 11.3332L13.6867 12.9796C13.882 13.1749 13.882 13.4915 13.6867 13.6867C13.4915 13.882 13.1749 13.882 12.9796 13.6867L11.3332 12.0403L9.68672 13.6867C9.49146 13.882 9.17488 13.882 8.97962 13.6867C8.78435 13.4915 8.78435 13.1749 8.97962 12.9796L10.6261 11.3332L8.97962 9.68672C8.78435 9.49146 8.78435 9.17488 8.97962 8.97962Z"
    fill="currentColor"
    fill-opacity="0.6"
  />
</svg>`;
export const LoadingIcon = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <style xmlns="http://www.w3.org/2000/svg">
    .spinner {
      transform-origin: center;
      animation: spinner_animate 0.75s infinite linear;
    }
    @keyframes spinner_animate {
      100% {
        transform: rotate(360deg);
      }
    }
  </style>
  <path
    d="M14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004ZM3.30003 8.00004C3.30003 10.5957 5.40424 12.6999 7.99992 12.6999C10.5956 12.6999 12.6998 10.5957 12.6998 8.00004C12.6998 5.40436 10.5956 3.30015 7.99992 3.30015C5.40424 3.30015 3.30003 5.40436 3.30003 8.00004Z"
    fill="black"
    fill-opacity="0.1"
  />
  <path
    d="M13.6833 8.00004C14.2263 8.00004 14.674 7.55745 14.5942 7.02026C14.5142 6.48183 14.3684 5.954 14.1591 5.44882C13.8241 4.63998 13.333 3.90505 12.714 3.286C12.0949 2.66694 11.36 2.17588 10.5511 1.84084C10.046 1.63159 9.51812 1.48576 8.9797 1.40576C8.44251 1.32595 7.99992 1.77363 7.99992 2.31671C7.99992 2.85979 8.44486 3.28974 8.9761 3.40253C9.25681 3.46214 9.53214 3.54746 9.79853 3.65781C10.3688 3.894 10.8869 4.2402 11.3233 4.67664C11.7598 5.11307 12.106 5.6312 12.3422 6.20143C12.4525 6.46782 12.5378 6.74315 12.5974 7.02386C12.7102 7.5551 13.1402 8.00004 13.6833 8.00004Z"
    fill="#1C9EE4"
    class="spinner"
  />
</svg>`;
export const SmallHintIcon = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.00008 3.16699C5.33071 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.33071 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699ZM2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 5.12996C8.27622 5.12996 8.50008 5.35381 8.50008 5.62996V8.00033C8.50008 8.27647 8.27622 8.50033 8.00008 8.50033C7.72394 8.50033 7.50008 8.27647 7.50008 8.00033V5.62996C7.50008 5.35381 7.72394 5.12996 8.00008 5.12996ZM7.50008 10.3707C7.50008 10.0946 7.72394 9.8707 8.00008 9.8707H8.00601C8.28215 9.8707 8.50601 10.0946 8.50601 10.3707C8.50601 10.6468 8.28215 10.8707 8.00601 10.8707H8.00008C7.72394 10.8707 7.50008 10.6468 7.50008 10.3707Z"
    fill-opacity="0.6"
  />
</svg> `;
