import { applyUpdate, Doc as YDoc, encodeStateAsUpdate, Map as YMap, transact } from 'yjs';
export const getLatestVersions = (schema)=>{
    return [
        ...schema.flavourSchemaMap.entries()
    ].reduce((record, [flavour, schema])=>{
        record[flavour] = schema.version;
        return record;
    }, {});
};
export async function migratePages(rootDoc, schema) {
    const spaces = rootDoc.getMap('spaces');
    const meta = rootDoc.getMap('meta');
    const versions = meta.get('blockVersions');
    const oldVersions = versions?.toJSON() ?? {};
    spaces.forEach((space)=>{
        try {
            schema.upgradeDoc(0, oldVersions, space);
        } catch (e) {
            console.error(e);
        }
    });
    schema.upgradeCollection(rootDoc);
    return transact(rootDoc, ()=>{
        const pageVersion = meta.get('pageVersion');
        if (typeof pageVersion !== 'number' || pageVersion < 2) {
            meta.set('pageVersion', 2);
        }
        const newVersions = getLatestVersions(schema);
        meta.set('blockVersions', new YMap(Object.entries(newVersions)));
        return Object.entries(oldVersions).some(([flavour, version])=>newVersions[flavour] !== version);
    }, 'migratePages', false);
}
export function migrateGuidCompatibility(rootDoc) {
    const meta = rootDoc.getMap('meta');
    const pages = meta.get('pages');
    pages?.forEach((page)=>{
        const pageId = page.get('id');
        if (pageId?.includes(':')) {
            page.set('id', pageId.split(':').at(-1));
        }
    });
    const spaces = rootDoc.getMap('spaces');
    spaces?.forEach((doc, pageId)=>{
        if (pageId.includes(':')) {
            const newPageId = pageId.split(':').at(-1) ?? pageId;
            const newDoc = new YDoc();
            applyUpdate(newDoc, encodeStateAsUpdate(doc));
            newDoc.guid = doc.guid;
            spaces.set(newPageId, newDoc);
            spaces.delete(pageId);
            console.debug(`fixed space id ${pageId} -> ${newPageId}, doc id: ${doc.guid}`);
        }
    });
}
