const table = new WeakMap();
let counter = 0;
export function stableHash(arg) {
    const type = typeof arg;
    const constructor = arg && arg.constructor;
    const isDate = constructor === Date;
    if (Object(arg) === arg && !isDate && constructor !== RegExp) {
        let result = table.get(arg);
        if (result) return result;
        result = ++counter + '~';
        table.set(arg, result);
        let index;
        if (constructor === Array) {
            result = '@';
            for(index = 0; index < arg.length; index++){
                result += stableHash(arg[index]) + ',';
            }
            table.set(arg, result);
        } else if (constructor === Object) {
            result = '#';
            const keys = Object.keys(arg).sort();
            while((index = keys.pop()) !== undefined){
                if (arg[index] !== undefined) {
                    result += index + ':' + stableHash(arg[index]) + ',';
                }
            }
            table.set(arg, result);
        }
        return result;
    }
    if (isDate) return arg.toJSON();
    if (type === 'symbol') return arg.toString();
    return type === 'string' ? JSON.stringify(arg) : '' + arg;
}
