import { z } from 'zod';
import { createEnumMap } from '../utils/enum.js';
export var LineWidth;
(function(LineWidth) {
    LineWidth[LineWidth["Eight"] = 8] = "Eight";
    LineWidth[LineWidth["Four"] = 4] = "Four";
    LineWidth[LineWidth["Six"] = 6] = "Six";
    LineWidth[LineWidth["Ten"] = 10] = "Ten";
    LineWidth[LineWidth["Twelve"] = 12] = "Twelve";
    LineWidth[LineWidth["Two"] = 2] = "Two";
})(LineWidth || (LineWidth = {}));
export var LineColor;
(function(LineColor) {
    LineColor["Black"] = "--affine-palette-line-black";
    LineColor["Blue"] = "--affine-palette-line-blue";
    LineColor["Green"] = "--affine-palette-line-green";
    LineColor["Grey"] = "--affine-palette-line-grey";
    LineColor["Magenta"] = "--affine-palette-line-magenta";
    LineColor["Orange"] = "--affine-palette-line-orange";
    LineColor["Purple"] = "--affine-palette-line-purple";
    LineColor["Red"] = "--affine-palette-line-red";
    LineColor["Teal"] = "--affine-palette-line-teal";
    LineColor["White"] = "--affine-palette-line-white";
    LineColor["Yellow"] = "--affine-palette-line-yellow";
})(LineColor || (LineColor = {}));
export const LineColorMap = createEnumMap(LineColor);
export const LINE_COLORS = [
    "--affine-palette-line-yellow",
    "--affine-palette-line-orange",
    "--affine-palette-line-red",
    "--affine-palette-line-magenta",
    "--affine-palette-line-purple",
    "--affine-palette-line-blue",
    "--affine-palette-line-teal",
    "--affine-palette-line-green",
    "--affine-palette-line-black",
    "--affine-palette-line-grey",
    "--affine-palette-line-white"
];
export const LineColorsSchema = z.nativeEnum(LineColor);
