import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AffinePageReference } from '../../reference-link';
import * as styles from './links-row.css';
export const LinksRow = ({ references, label, className, onClick })=>{
    return _jsxs("div", {
        className: className,
        children: [
            _jsxs("div", {
                className: styles.title,
                children: [
                    label,
                    " · ",
                    references.length
                ]
            }),
            references.map((link, index)=>_jsx(AffinePageReference, {
                    pageId: link.docId,
                    params: 'params' in link ? link.params : undefined,
                    wrapper: (props)=>_jsx("div", {
                            className: styles.wrapper,
                            onClick: onClick,
                            ...props
                        })
                }, index))
        ]
    });
};
