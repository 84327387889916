import { differenceBy, isNil, omitBy } from 'lodash-es';
import { combineLatest, map, switchMap } from 'rxjs';
import { AbstractType as YAbstractType } from 'yjs';
import { Store } from '../../../framework';
import { yjsObserveByPath, yjsObserveDeep } from '../../../utils/yjs-observable';
export class DocPropertiesStore extends Store {
    constructor(workspaceService, dbService){
        super(), this.workspaceService = workspaceService, this.dbService = dbService;
    }
    updateDocProperties(id, config) {
        return this.dbService.db.docProperties.create({
            id,
            ...config
        });
    }
    getDocPropertyInfoList() {
        const db = this.dbService.db.docCustomPropertyInfo.find();
        const legacy = this.upgradeLegacyDocPropertyInfoList(this.getLegacyDocPropertyInfoList());
        const notOverridden = differenceBy(legacy, db, (i)=>i.id);
        return [
            ...db,
            ...notOverridden
        ].filter((i)=>!i.isDeleted);
    }
    createDocPropertyInfo(config) {
        return this.dbService.db.docCustomPropertyInfo.create(config).id;
    }
    removeDocPropertyInfo(id) {
        this.updateDocPropertyInfo(id, {
            additionalData: {},
            isDeleted: true
        });
    }
    updateDocPropertyInfo(id, config) {
        const needMigration = !this.dbService.db.docCustomPropertyInfo.get(id);
        if (needMigration) {
            this.migrateLegacyDocPropertyInfo(id, config);
        } else {
            this.dbService.db.docCustomPropertyInfo.update(id, config);
        }
    }
    migrateLegacyDocPropertyInfo(id, override) {
        const legacy = this.getLegacyDocPropertyInfo(id);
        this.dbService.db.docCustomPropertyInfo.create({
            id,
            type: legacy?.type ?? 'unknown',
            name: legacy?.name,
            ...override
        });
    }
    watchDocPropertyInfoList() {
        return combineLatest([
            this.watchLegacyDocPropertyInfoList().pipe(map(this.upgradeLegacyDocPropertyInfoList)),
            this.dbService.db.docCustomPropertyInfo.find$({})
        ]).pipe(map(([legacy, db])=>{
            const notOverridden = differenceBy(legacy, db, (i)=>i.id);
            return [
                ...db,
                ...notOverridden
            ].filter((i)=>!i.isDeleted);
        }));
    }
    getDocProperties(id) {
        return {
            ...this.upgradeLegacyDocProperties(this.getLegacyDocProperties(id)),
            ...omitBy(this.dbService.db.docProperties.get(id), isNil)
        };
    }
    watchDocProperties(id) {
        return combineLatest([
            this.watchLegacyDocProperties(id).pipe(map(this.upgradeLegacyDocProperties)),
            this.dbService.db.docProperties.get$(id)
        ]).pipe(map(([legacy, db])=>({
                ...legacy,
                ...omitBy(db, isNil)
            })));
    }
    upgradeLegacyDocProperties(properties) {
        if (!properties) {
            return {};
        }
        const newProperties = {};
        for (const [key, info] of Object.entries(properties.system ?? {})){
            if (info?.value !== undefined) {
                newProperties[key] = info.value;
            }
        }
        for (const [key, info] of Object.entries(properties.custom ?? {})){
            if (info?.value !== undefined) {
                newProperties['custom:' + key] = info.value;
            }
        }
        return newProperties;
    }
    upgradeLegacyDocPropertyInfoList(infoList) {
        if (!infoList) {
            return [];
        }
        const newInfoList = [];
        for (const [id, info] of Object.entries(infoList ?? {})){
            if (info?.type) {
                newInfoList.push({
                    id,
                    name: info.name,
                    type: info.type
                });
            }
        }
        return newInfoList;
    }
    getLegacyDocProperties(id) {
        return this.workspaceService.workspace.rootYDoc.getMap('affine:workspace-properties').get('pageProperties')?.get(id)?.toJSON();
    }
    watchLegacyDocProperties(id) {
        return yjsObserveByPath(this.workspaceService.workspace.rootYDoc.getMap('affine:workspace-properties'), `pageProperties.${id}`).pipe(switchMap(yjsObserveDeep), map((p)=>p instanceof YAbstractType ? p.toJSON() : p));
    }
    getLegacyDocPropertyInfoList() {
        return this.workspaceService.workspace.rootYDoc.getMap('affine:workspace-properties').get('schema')?.get('pageProperties')?.get('custom')?.toJSON();
    }
    watchLegacyDocPropertyInfoList() {
        return yjsObserveByPath(this.workspaceService.workspace.rootYDoc.getMap('affine:workspace-properties'), 'schema.pageProperties.custom').pipe(switchMap(yjsObserveDeep), map((p)=>p instanceof YAbstractType ? p.toJSON() : p));
    }
    getLegacyDocPropertyInfo(id) {
        return this.workspaceService.workspace.rootYDoc.getMap('affine:workspace-properties').get('schema')?.get('pageProperties')?.get('custom')?.get(id)?.toJSON();
    }
}
