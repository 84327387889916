function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init__activatedItemIndex, _init__linkedDocGroup, _init__position, _init__showTooltip, _init_iconButtons, _init_linkedDocElement, _initProto;
import { MoreHorizontalIcon } from '@blocksuite/affine-components/icons';
import { WithDisposable } from '@blocksuite/global/utils';
import { html, LitElement, nothing } from 'lit';
import { query, queryAll, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { cleanSpecifiedTail, createKeydownObserver, getQuery } from '../../../_common/components/utils.js';
import { styles } from './styles.js';
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = queryAll('icon-button'), _dec5 = query('.linked-doc-popover');
export class LinkedDocPopover extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__activatedItemIndex, _init__linkedDocGroup, _init__position, _init__showTooltip, _init_iconButtons, _init_linkedDocElement, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_activatedItemIndex"
            ],
            [
                _dec1,
                1,
                "_linkedDocGroup"
            ],
            [
                _dec2,
                1,
                "_position"
            ],
            [
                _dec3,
                1,
                "_showTooltip"
            ],
            [
                _dec4,
                1,
                "iconButtons"
            ],
            [
                _dec5,
                1,
                "linkedDocElement"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _actionGroup() {
        return this._linkedDocGroup.map((group)=>{
            return {
                ...group,
                items: this._getActionItems(group)
            };
        });
    }
    get _flattenActionList() {
        return this._actionGroup.map((group)=>group.items.map((item)=>({
                    ...item,
                    groupName: group.name
                }))).flat();
    }
    get _query() {
        return getQuery(this.inlineEditor, this._startRange);
    }
    constructor(triggerKey, getMenus, editorHost, inlineEditor, abortController){
        super(), this.triggerKey = triggerKey, this.getMenus = getMenus, this.editorHost = editorHost, this.inlineEditor = inlineEditor, this.abortController = abortController, this._abort = ()=>{
            this.abortController.abort();
            cleanSpecifiedTail(this.editorHost, this.inlineEditor, this.triggerKey + (this._query || ''));
        }, this._expanded = new Map(), this._startRange = this.inlineEditor.getInlineRange(), this._updateLinkedDocGroup = async ()=>{
            const query = this._query;
            if (query === null) {
                this.abortController.abort();
                return;
            }
            this._linkedDocGroup = await this.getMenus(query, this._abort, this.editorHost, this.inlineEditor);
        }, this.#___private__activatedItemIndex_1 = (_initProto(this), _init__activatedItemIndex(this, 0)), this.#___private__linkedDocGroup_2 = _init__linkedDocGroup(this, []), this.#___private__position_3 = _init__position(this, null), this.#___private__showTooltip_4 = _init__showTooltip(this, false), this.#___private_iconButtons_5 = _init_iconButtons(this), this.#___private_linkedDocElement_6 = _init_linkedDocElement(this, null);
    }
    _getActionItems(group) {
        const isExpanded = !!this._expanded.get(group.name);
        if (isExpanded) {
            return group.items;
        }
        const isOverflow = !!group.maxDisplay && group.items.length > group.maxDisplay;
        if (isOverflow) {
            return group.items.slice(0, group.maxDisplay).concat({
                key: `${group.name} More`,
                name: group.overflowText || 'more',
                icon: MoreHorizontalIcon,
                action: ()=>{
                    this._expanded.set(group.name, true);
                    this.requestUpdate();
                }
            });
        }
        return group.items;
    }
    _isTextOverflowing(element) {
        return element.scrollWidth > element.clientWidth;
    }
    connectedCallback() {
        super.connectedCallback();
        void this._updateLinkedDocGroup();
        this._disposables.addFromEvent(this, 'mousedown', (e)=>{
            e.preventDefault();
        });
        const { eventSource } = this.inlineEditor;
        if (!eventSource) return;
        createKeydownObserver({
            target: eventSource,
            signal: this.abortController.signal,
            onInput: (isComposition)=>{
                this._activatedItemIndex = 0;
                if (isComposition) {
                    this._updateLinkedDocGroup().catch(console.error);
                } else {
                    this.inlineEditor.slots.renderComplete.once(this._updateLinkedDocGroup);
                }
            },
            onPaste: ()=>{
                this._activatedItemIndex = 0;
                setTimeout(()=>{
                    this._updateLinkedDocGroup().catch(console.error);
                }, 50);
            },
            onDelete: ()=>{
                const curRange = this.inlineEditor.getInlineRange();
                if (!this._startRange || !curRange) {
                    return;
                }
                if (curRange.index < this._startRange.index) {
                    this.abortController.abort();
                }
                this._activatedItemIndex = 0;
                this.inlineEditor.slots.renderComplete.once(this._updateLinkedDocGroup);
            },
            onMove: (step)=>{
                const itemLen = this._flattenActionList.length;
                this._activatedItemIndex = (itemLen + this._activatedItemIndex + step) % itemLen;
                const item = this._flattenActionList[this._activatedItemIndex];
                const shadowRoot = this.shadowRoot;
                if (!shadowRoot) {
                    console.warn('Failed to find the shadow root!', this);
                    return;
                }
                const ele = shadowRoot.querySelector(`icon-button[data-id="${item.key}"]`);
                if (!ele) {
                    console.warn('Failed to find the active item!', item);
                    return;
                }
                ele.scrollIntoView({
                    block: 'nearest'
                });
            },
            onConfirm: ()=>{
                this._flattenActionList[this._activatedItemIndex].action()?.catch(console.error);
            },
            onAbort: ()=>{
                this.abortController.abort();
            }
        });
    }
    render() {
        const MAX_HEIGHT = 410;
        const style = this._position ? styleMap({
            transform: `translate(${this._position.x}, ${this._position.y})`,
            maxHeight: `${Math.min(this._position.height, MAX_HEIGHT)}px`
        }) : styleMap({
            visibility: 'hidden'
        });
        let accIdx = 0;
        return html`<div class="linked-doc-popover" style="${style}">
      ${this._actionGroup.filter((group)=>group.items.length).map((group, idx)=>{
            return html`
            <div class="divider" ?hidden=${idx === 0}></div>
            <div class="group-title">${group.name}</div>
            <div class="group" style=${group.styles ?? ''}>
              ${group.items.map(({ key, name, icon, action })=>{
                accIdx++;
                const curIdx = accIdx - 1;
                const tooltip = this._showTooltip ? html`<affine-tooltip tip-position=${'right'}
                      >${name}</affine-tooltip
                    >` : nothing;
                return html`<icon-button
                  width="280px"
                  height="32px"
                  data-id=${key}
                  text=${name}
                  hover=${this._activatedItemIndex === curIdx}
                  @click=${()=>{
                    action()?.catch(console.error);
                }}
                  @mousemove=${()=>{
                    this._activatedItemIndex = curIdx;
                    for (const button of this.iconButtons.values()){
                        if (button.dataset.id == key && button.textElement) {
                            const isOverflowing = this._isTextOverflowing(button.textElement);
                            this._showTooltip = isOverflowing;
                            break;
                        }
                    }
                }}
                >
                  ${icon} ${tooltip}
                </icon-button>`;
            })}
            </div>
          `;
        })}
    </div>`;
    }
    updatePosition(position) {
        this._position = position;
    }
    #___private__activatedItemIndex_1;
    get _activatedItemIndex() {
        return this.#___private__activatedItemIndex_1;
    }
    set _activatedItemIndex(_v) {
        this.#___private__activatedItemIndex_1 = _v;
    }
    #___private__linkedDocGroup_2;
    get _linkedDocGroup() {
        return this.#___private__linkedDocGroup_2;
    }
    set _linkedDocGroup(_v) {
        this.#___private__linkedDocGroup_2 = _v;
    }
    #___private__position_3;
    get _position() {
        return this.#___private__position_3;
    }
    set _position(_v) {
        this.#___private__position_3 = _v;
    }
    #___private__showTooltip_4;
    get _showTooltip() {
        return this.#___private__showTooltip_4;
    }
    set _showTooltip(_v) {
        this.#___private__showTooltip_4 = _v;
    }
    #___private_iconButtons_5;
    get iconButtons() {
        return this.#___private_iconButtons_5;
    }
    set iconButtons(_v) {
        this.#___private_iconButtons_5 = _v;
    }
    #___private_linkedDocElement_6;
    get linkedDocElement() {
        return this.#___private_linkedDocElement_6;
    }
    set linkedDocElement(_v) {
        this.#___private_linkedDocElement_6 = _v;
    }
}
