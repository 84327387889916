function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init__loading, _init__startX, _init__startY, _init_containerEl, _init_x, _init_y, _initProto;
import { CloseIcon, ExportToHTMLIcon, ExportToMarkdownIcon, HelpIcon, NewIcon, NotionIcon } from '@blocksuite/affine-components/icons';
import { sha, WithDisposable } from '@blocksuite/global/utils';
import { extMimeMap } from '@blocksuite/store';
import { Job } from '@blocksuite/store';
import { html, LitElement } from 'lit';
import { query, state } from 'lit/decorators.js';
import { HtmlAdapter } from '../../../../_common/adapters/html.js';
import { MarkdownAdapter } from '../../../../_common/adapters/markdown.js';
import { NotionHtmlAdapter } from '../../../../_common/adapters/notion-html.js';
import { defaultImageProxyMiddleware } from '../../../../_common/transformers/middlewares.js';
import { Unzip } from '../../../../_common/transformers/utils.js';
import { openFileOrFiles } from '../../../../_common/utils/index.js';
import { styles } from './styles.js';
const SHOW_LOADING_SIZE = 1024 * 200;
export async function importMarkDown(collection, text, fileName, jobMiddleware) {
    const fileNameMiddleware = ({ slots })=>{
        slots.beforeImport.on((payload)=>{
            if (payload.type !== 'page') {
                return;
            }
            if (!fileName) {
                return;
            }
            payload.snapshot.meta.title = fileName;
            payload.snapshot.blocks.props.title = {
                '$blocksuite:internal:text$': true,
                delta: [
                    {
                        insert: fileName
                    }
                ]
            };
        });
    };
    const middlewares = [
        defaultImageProxyMiddleware,
        fileNameMiddleware
    ];
    if (jobMiddleware) {
        middlewares.push(jobMiddleware);
    }
    const job = new Job({
        collection,
        middlewares
    });
    const mdAdapter = new MarkdownAdapter(job);
    const page = await mdAdapter.toDoc({
        file: text,
        assets: job.assetsManager
    });
    if (!page) {
        return;
    }
    return page.id;
}
export async function importHtml(collection, text) {
    const job = new Job({
        collection,
        middlewares: [
            defaultImageProxyMiddleware
        ]
    });
    const htmlAdapter = new HtmlAdapter(job);
    const snapshot = await htmlAdapter.toDocSnapshot({
        file: text,
        assets: job.assetsManager
    });
    const page = await job.snapshotToDoc(snapshot);
    if (!page) {
        return;
    }
    return page.id;
}
export async function importNotion(collection, file) {
    const pageIds = [];
    let isWorkspaceFile = false;
    let hasMarkdown = false;
    let entryId;
    const parseZipFile = async (path)=>{
        const unzip = new Unzip();
        await unzip.load(path);
        const zipFile = new Map();
        const pageMap = new Map();
        const pagePaths = [];
        const promises = [];
        const pendingAssets = new Map();
        const pendingPathBlobIdMap = new Map();
        for (const { path, content, index } of unzip){
            if (path.startsWith('__MACOSX/')) continue;
            zipFile.set(path, content);
            const lastSplitIndex = path.lastIndexOf('/');
            const fileName = path.substring(lastSplitIndex + 1);
            if (fileName.endsWith('.md')) {
                hasMarkdown = true;
                continue;
            }
            if (fileName.endsWith('.html')) {
                if (path.endsWith('/index.html')) {
                    isWorkspaceFile = true;
                    continue;
                }
                if (lastSplitIndex !== -1) {
                    const text = await content.text();
                    const doc = new DOMParser().parseFromString(text, 'text/html');
                    const pageBody = doc.querySelector('.page-body');
                    if (pageBody && pageBody.children.length == 0) {
                        continue;
                    }
                }
                const id = collection.idGenerator();
                const splitPath = path.split('/');
                while(splitPath.length > 0){
                    pageMap.set(splitPath.join('/'), id);
                    splitPath.shift();
                }
                pagePaths.push(path);
                if (entryId === undefined && lastSplitIndex === -1) {
                    entryId = id;
                }
                continue;
            }
            if (index === 0 && fileName.endsWith('.csv')) {
                window.open('https://wemem.ai/blog/import-your-data-from-notion-into-affine', '_blank');
                continue;
            }
            if (fileName.endsWith('.zip')) {
                const innerZipFile = content;
                if (innerZipFile) {
                    promises.push(...await parseZipFile(innerZipFile));
                }
                continue;
            }
            const blob = content;
            const ext = path.split('.').at(-1) ?? '';
            const mime = extMimeMap.get(ext) ?? '';
            const key = await sha(await blob.arrayBuffer());
            const filePathSplit = path.split('/');
            while(filePathSplit.length > 1){
                pendingPathBlobIdMap.set(filePathSplit.join('/'), key);
                filePathSplit.shift();
            }
            pendingAssets.set(key, new File([
                blob
            ], fileName, {
                type: mime
            }));
        }
        const pagePromises = Array.from(pagePaths).map(async (path)=>{
            const job = new Job({
                collection: collection,
                middlewares: [
                    defaultImageProxyMiddleware
                ]
            });
            const htmlAdapter = new NotionHtmlAdapter(job);
            const assets = job.assetsManager.getAssets();
            const pathBlobIdMap = job.assetsManager.getPathBlobIdMap();
            for (const [key, value] of pendingAssets.entries()){
                if (!assets.has(key)) {
                    assets.set(key, value);
                }
            }
            for (const [key, value] of pendingPathBlobIdMap.entries()){
                if (!pathBlobIdMap.has(key)) {
                    pathBlobIdMap.set(key, value);
                }
            }
            const page = await htmlAdapter.toDoc({
                file: await zipFile.get(path).text(),
                pageId: pageMap.get(path),
                pageMap,
                assets: job.assetsManager
            });
            if (page) {
                pageIds.push(page.id);
            }
        });
        promises.push(...pagePromises);
        return promises;
    };
    const allPromises = await parseZipFile(file);
    await Promise.all(allPromises.flat());
    entryId = entryId ?? pageIds[0];
    return {
        entryId,
        pageIds,
        isWorkspaceFile,
        hasMarkdown
    };
}
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = query('.container'), _dec4 = state(), _dec5 = state();
export class ImportDoc extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__loading, _init__startX, _init__startY, _init_containerEl, _init_x, _init_y, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_loading"
            ],
            [
                _dec1,
                1,
                "_startX"
            ],
            [
                _dec2,
                1,
                "_startY"
            ],
            [
                _dec3,
                1,
                "containerEl"
            ],
            [
                _dec4,
                1,
                "x"
            ],
            [
                _dec5,
                1,
                "y"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    constructor(collection, onSuccess, onFail, abortController = new AbortController()){
        super(), this.collection = collection, this.onSuccess = onSuccess, this.onFail = onFail, this.abortController = abortController, this.#___private__loading_1 = (_initProto(this), _init__loading(this, false)), this.#___private__startX_2 = _init__startX(this, 0), this.#___private__startY_3 = _init__startY(this, 0), this.#___private_containerEl_4 = _init_containerEl(this), this.#___private_x_5 = _init_x(this, 0), this.#___private_y_6 = _init_y(this, 0);
        this._loading = false;
        this.x = 0;
        this.y = 0;
        this._startX = 0;
        this._startY = 0;
        this._onMouseMove = this._onMouseMove.bind(this);
    }
    async _importHtml() {
        const files = await openFileOrFiles({
            acceptType: 'Html',
            multiple: true
        });
        if (!files) return;
        const pageIds = [];
        for (const file of files){
            const text = await file.text();
            const needLoading = file.size > SHOW_LOADING_SIZE;
            if (needLoading) {
                this.hidden = false;
                this._loading = true;
            } else {
                this.abortController.abort();
            }
            const pageId = await importHtml(this.collection, text);
            needLoading && this.abortController.abort();
            if (pageId) {
                pageIds.push(pageId);
            }
        }
        this._onImportSuccess(pageIds);
    }
    async _importMarkDown() {
        const files = await openFileOrFiles({
            acceptType: 'Markdown',
            multiple: true
        });
        if (!files) return;
        const pageIds = [];
        for (const file of files){
            const text = await file.text();
            const fileName = file.name.split('.').slice(0, -1).join('.');
            const needLoading = file.size > SHOW_LOADING_SIZE;
            if (needLoading) {
                this.hidden = false;
                this._loading = true;
            } else {
                this.abortController.abort();
            }
            const pageId = await importMarkDown(this.collection, text, fileName);
            needLoading && this.abortController.abort();
            if (pageId) {
                pageIds.push(pageId);
            }
        }
        this._onImportSuccess(pageIds);
    }
    async _importNotion() {
        const file = await openFileOrFiles({
            acceptType: 'Zip'
        });
        if (!file) return;
        const needLoading = file.size > SHOW_LOADING_SIZE;
        if (needLoading) {
            this.hidden = false;
            this._loading = true;
        } else {
            this.abortController.abort();
        }
        const { entryId, pageIds, isWorkspaceFile, hasMarkdown } = await importNotion(this.collection, file);
        needLoading && this.abortController.abort();
        if (hasMarkdown) {
            this._onFail('Importing markdown files from Notion is deprecated. Please export your Notion pages as HTML.');
            return;
        }
        this._onImportSuccess([
            entryId
        ], {
            isWorkspaceFile,
            importedCount: pageIds.length
        });
    }
    _onCloseClick(event) {
        event.stopPropagation();
        this.abortController.abort();
    }
    _onFail(message) {
        this.onFail?.(message);
    }
    _onImportSuccess(pageIds, options = {}) {
        const { isWorkspaceFile = false, importedCount: pagesImportedCount = pageIds.length } = options;
        this.onSuccess?.(pageIds, {
            isWorkspaceFile,
            importedCount: pagesImportedCount
        });
    }
    _onMouseDown(event) {
        this._startX = event.clientX - this.x;
        this._startY = event.clientY - this.y;
        window.addEventListener('mousemove', this._onMouseMove);
    }
    _onMouseMove(event) {
        this.x = event.clientX - this._startX;
        this.y = event.clientY - this._startY;
    }
    _onMouseUp() {
        window.removeEventListener('mousemove', this._onMouseMove);
    }
    _openLearnImportLink(event) {
        event.stopPropagation();
        window.open('https://wemem.ai/blog/import-your-data-from-notion-into-affine', '_blank');
    }
    render() {
        if (this._loading) {
            return html`
        <div class="overlay-mask"></div>
        <div class="container">
          <header
            class="loading-header"
            @mousedown="${this._onMouseDown}"
            @mouseup="${this._onMouseUp}"
          >
            <div>Import</div>
            <loader-element .width=${'50px'}></loader-element>
          </header>
          <div>
            Importing the file may take some time. It depends on document size
            and complexity.
          </div>
        </div>
      `;
        }
        return html`
      <div
        class="overlay-mask"
        @click="${()=>this.abortController.abort()}"
      ></div>
      <div class="container">
        <header @mousedown="${this._onMouseDown}" @mouseup="${this._onMouseUp}">
          <icon-button height="28px" @click="${this._onCloseClick}">
            ${CloseIcon}
          </icon-button>
          <div>Import</div>
        </header>
        <div>
          Wemem will gradually support more file formats for import.
          <a
            href="https://community.wemem.ai/c/feature-requests/import-export"
            target="_blank"
            >Provide feedback.</a
          >
        </div>
        <div class="button-container">
          <icon-button
            class="button-item"
            text="Markdown"
            @click="${this._importMarkDown}"
          >
            ${ExportToMarkdownIcon}
          </icon-button>
          <icon-button
            class="button-item"
            text="HTML"
            @click="${this._importHtml}"
          >
            ${ExportToHTMLIcon}
          </icon-button>
        </div>
        <div class="button-container">
          <icon-button
            class="button-item"
            text="Notion"
            @click="${this._importNotion}"
          >
            ${NotionIcon}
            <div
              slot="suffix"
              class="button-suffix"
              @click="${this._openLearnImportLink}"
            >
              ${HelpIcon}
              <affine-tooltip>
                Learn how to Import your Notion pages into Wemem.
              </affine-tooltip>
            </div>
          </icon-button>
          <icon-button class="button-item" text="Coming soon..." disabled>
            ${NewIcon}
          </icon-button>
        </div>
        <!-- <div class="footer">
        <div>Migrate from other versions of Wemem?</div>
      </div> -->
      </div>
    `;
    }
    updated(changedProps) {
        if (changedProps.has('x') || changedProps.has('y')) {
            this.containerEl.style.transform = `translate(${this.x}px, ${this.y}px)`;
        }
    }
    #___private__loading_1;
    get _loading() {
        return this.#___private__loading_1;
    }
    set _loading(_v) {
        this.#___private__loading_1 = _v;
    }
    #___private__startX_2;
    get _startX() {
        return this.#___private__startX_2;
    }
    set _startX(_v) {
        this.#___private__startX_2 = _v;
    }
    #___private__startY_3;
    get _startY() {
        return this.#___private__startY_3;
    }
    set _startY(_v) {
        this.#___private__startY_3 = _v;
    }
    #___private_containerEl_4;
    get containerEl() {
        return this.#___private_containerEl_4;
    }
    set containerEl(_v) {
        this.#___private_containerEl_4 = _v;
    }
    #___private_x_5;
    get x() {
        return this.#___private_x_5;
    }
    set x(_v) {
        this.#___private_x_5 = _v;
    }
    #___private_y_6;
    get y() {
        return this.#___private_y_6;
    }
    set y(_v) {
        this.#___private_y_6 = _v;
    }
}
