import { jsx as _jsx } from "react/jsx-runtime";
import { useI18n } from '@affine/i18n';
import { ArrowLeftSmallIcon } from '@blocksuite/icons/rc';
import { cssVar } from '@toeverything/theme';
import { Button } from '../../ui/button';
export const BackButton = (props)=>{
    const t = useI18n();
    return _jsx(Button, {
        variant: "plain",
        style: {
            marginTop: 12,
            marginLeft: -5,
            paddingLeft: 0,
            paddingRight: 5,
            color: cssVar('textSecondaryColor')
        },
        prefix: _jsx(ArrowLeftSmallIcon, {}),
        ...props,
        children: t['com.affine.backButton']()
    });
};
