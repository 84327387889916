import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { wrapper } from './share.css';
export const SettingWrapper = ({ title, children })=>{
    return _jsxs("div", {
        className: wrapper,
        children: [
            title ? _jsx("div", {
                className: "title",
                children: title
            }) : null,
            children
        ]
    });
};
