import { backoffRetry, catchErrorInto, effect, Entity, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, mergeMap, switchMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../../cloud';
export class TemplateDownloader extends Entity {
    constructor(store){
        super(), this.store = store, this.isDownloading$ = new LiveData(false), this.data$ = new LiveData(null), this.error$ = new LiveData(null), this.download = effect(switchMap(({ snapshotUrl })=>{
            return fromPromise(()=>this.store.download(snapshotUrl)).pipe(mergeMap(({ data })=>{
                this.data$.next(data);
                return EMPTY;
            }), backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), catchErrorInto(this.error$), onStart(()=>{
                this.isDownloading$.next(true);
                this.data$.next(null);
                this.error$.next(null);
            }), onComplete(()=>this.isDownloading$.next(false)));
        }));
    }
}
