import { DebugLogger } from '@affine/debug';
import { MANUALLY_STOP, throwIfAborted } from '../../utils';
const logger = new DebugLogger('job-runner');
export class JobRunner {
    constructor(queue, worker, interval = async ()=>{}){
        this.queue = queue;
        this.worker = worker;
        this.interval = interval;
        this.abort = null;
    }
    start() {
        this.stop();
        this.abort = new AbortController();
        this.loop(this.abort.signal).catch((err)=>{
            if (err === MANUALLY_STOP) {
                return;
            }
            logger.error(err);
        });
    }
    stop() {
        this.abort?.abort(MANUALLY_STOP);
        this.abort = null;
    }
    async loop(signal) {
        while(throwIfAborted(signal)){
            const jobs = await this.queue.waitForAccept(signal);
            if (jobs !== null) {
                try {
                    await this.worker(jobs, signal);
                    await this.queue.return(jobs);
                } catch (err) {
                    if (err === MANUALLY_STOP) {
                        await this.queue.return(jobs, true);
                    } else {
                        await this.queue.return(jobs);
                    }
                    logger.error('Error processing jobs', err);
                }
            } else {
                await new Promise((resolve)=>setTimeout(resolve, 1000));
            }
            await this.interval();
        }
    }
}
