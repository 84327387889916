import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Dialog from '@radix-ui/react-dialog';
import { useLiveData, useService } from '@toeverything/infra';
import anime from 'animejs';
import clsx from 'clsx';
import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { EditorSettingService } from '../../editor-settting';
import * as styles from './modal-container.css';
const contentOptions = {
    ['data-testid']: 'peek-view-modal',
    onPointerDownOutside: (e)=>{
        const el = e.target;
        if (el.closest('[data-peek-view-wrapper]') || el.closest('affine-slash-menu')) {
            e.preventDefault();
        }
    },
    onEscapeKeyDown: (e)=>{
        e.preventDefault();
    }
};
export const PeekViewContext = createContext(null);
const emptyContext = {};
export const useInsidePeekView = ()=>{
    const context = useContext(PeekViewContext);
    return !!context;
};
const PeekViewModalOverlay = 'div';
export const PeekViewModalContainer = forwardRef(function PeekViewModalContainer({ onOpenChange, open, target, controls, children, onAnimationStart, onAnimateEnd, animation = 'zoom', mode = 'fit', dialogFrame = true }, ref) {
    const [vtOpen, setVtOpen] = useState(open);
    const [animeState, setAnimeState] = useState('idle');
    const contentClipRef = useRef(null);
    const contentRef = useRef(null);
    const overlayRef = useRef(null);
    const controlsRef = useRef(null);
    const prevAnimeMap = useRef({});
    const editorSettings = useService(EditorSettingService).editorSetting;
    const fullWidthLayout = useLiveData(editorSettings.settings$.selector((s)=>s.fullWidthLayout));
    const animateControls = useCallback((animateIn = false)=>{
        const controls = controlsRef.current;
        if (!controls) return;
        anime({
            targets: controls,
            opacity: animateIn ? [
                0,
                1
            ] : [
                1,
                0
            ],
            translateX: animateIn ? [
                -32,
                0
            ] : [
                0,
                -32
            ],
            easing: 'easeOutQuad',
            duration: 230
        });
    }, []);
    const zoomAnimate = useCallback(async (zoomIn, paramsMap)=>{
        return new Promise((resolve)=>{
            const contentClip = contentClipRef.current;
            const content = contentRef.current;
            const overlay = overlayRef.current;
            if (!contentClip || !content || !target || !overlay) {
                resolve();
                return;
            }
            const targets = contentClip;
            const lockSizeEl = content;
            const from = zoomIn ? target : contentClip;
            const to = zoomIn ? contentClip : target;
            const fromRect = from.getBoundingClientRect();
            const toRect = to.getBoundingClientRect();
            targets.style.position = 'fixed';
            targets.style.opacity = '1';
            lockSizeEl.style.width = zoomIn ? `${toRect.width}px` : `${fromRect.width}px`;
            lockSizeEl.style.height = zoomIn ? `${toRect.height}px` : `${fromRect.height}px`;
            lockSizeEl.style.overflow = 'hidden';
            overlay.style.pointerEvents = 'none';
            prevAnimeMap.current.overlay?.pause();
            prevAnimeMap.current.content?.pause();
            prevAnimeMap.current.contentWrapper?.pause();
            const overlayAnime = anime({
                targets: overlay,
                opacity: zoomIn ? [
                    0,
                    1
                ] : [
                    1,
                    0
                ],
                easing: 'easeOutQuad',
                duration: 230,
                ...paramsMap?.overlay
            });
            const contentAnime = paramsMap?.content && anime({
                targets: content,
                ...paramsMap.content
            });
            const contentWrapperAnime = anime({
                targets,
                left: [
                    fromRect.left,
                    toRect.left
                ],
                top: [
                    fromRect.top,
                    toRect.top
                ],
                width: [
                    fromRect.width,
                    toRect.width
                ],
                height: [
                    fromRect.height,
                    toRect.height
                ],
                easing: 'easeOutQuad',
                duration: 230,
                ...paramsMap?.contentWrapper,
                complete: (ins)=>{
                    paramsMap?.contentWrapper?.complete?.(ins);
                    setAnimeState('idle');
                    overlay.style.pointerEvents = '';
                    if (zoomIn) {
                        Object.assign(targets.style, {
                            position: '',
                            left: '',
                            top: '',
                            width: '',
                            height: ''
                        });
                        Object.assign(lockSizeEl.style, {
                            width: '100%',
                            height: '100%',
                            overflow: ''
                        });
                    }
                    resolve();
                }
            });
            prevAnimeMap.current = {
                overlay: overlayAnime,
                content: contentAnime,
                contentWrapper: contentWrapperAnime
            };
        });
    }, [
        target
    ]);
    const animateZoomIn = useCallback(()=>{
        setAnimeState('animating');
        setVtOpen(true);
        setTimeout(()=>{
            zoomAnimate(true, {
                contentWrapper: {
                    opacity: [
                        0.5,
                        1
                    ],
                    easing: 'cubicBezier(.46,.36,0,1)',
                    duration: 400
                },
                content: {
                    opacity: [
                        0,
                        1
                    ],
                    duration: 100
                }
            }).catch(console.error);
        }, 0);
        setTimeout(()=>animateControls(true), 400 - 230 + 150);
    }, [
        animateControls,
        zoomAnimate
    ]);
    const animateZoomOut = useCallback(()=>{
        setAnimeState('animating');
        animateControls(false);
        zoomAnimate(false, {
            contentWrapper: {
                easing: 'cubicBezier(.57,.25,.76,.44)',
                opacity: [
                    1,
                    0.5
                ],
                duration: 180
            },
            content: {
                opacity: [
                    1,
                    0
                ],
                duration: 180,
                easing: 'easeOutQuad'
            }
        }).then(()=>setVtOpen(false)).catch(console.error);
    }, [
        animateControls,
        zoomAnimate
    ]);
    const animateFade = useCallback((animateIn)=>{
        setAnimeState('animating');
        return new Promise((resolve)=>{
            if (animateIn) setVtOpen(true);
            setTimeout(()=>{
                const overlay = overlayRef.current;
                const contentClip = contentClipRef.current;
                if (!overlay || !contentClip) {
                    resolve();
                    return;
                }
                anime({
                    targets: [
                        overlay,
                        contentClip
                    ],
                    opacity: animateIn ? [
                        0,
                        1
                    ] : [
                        1,
                        0
                    ],
                    easing: 'easeOutQuad',
                    duration: 230,
                    complete: ()=>{
                        if (!animateIn) setVtOpen(false);
                        setAnimeState('idle');
                        resolve();
                    }
                });
            });
        });
    }, []);
    useEffect(()=>{
        const onKeyDown = (e)=>{
            if (e.key === 'Escape') {
                onOpenChange(false);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return ()=>{
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [
        onOpenChange
    ]);
    useEffect(()=>{
        if (animation === 'zoom') {
            open ? animateZoomIn() : animateZoomOut();
        } else if (animation === 'fade') {
            animateFade(open).catch(console.error);
        } else if (animation === 'none') {
            setVtOpen(open);
        }
    }, [
        animateZoomOut,
        animation,
        open,
        target,
        animateZoomIn,
        animateFade
    ]);
    return _jsx(PeekViewContext.Provider, {
        value: emptyContext,
        children: _jsx(Dialog.Root, {
            modal: true,
            open: vtOpen,
            onOpenChange: onOpenChange,
            children: _jsxs(Dialog.Portal, {
                children: [
                    _jsx(PeekViewModalOverlay, {
                        ref: overlayRef,
                        className: styles.modalOverlay,
                        onAnimationStart: onAnimationStart,
                        onAnimationEnd: onAnimateEnd,
                        "data-anime-state": animeState
                    }),
                    _jsx("div", {
                        ref: ref,
                        "data-mode": mode,
                        "data-peek-view-wrapper": true,
                        className: styles.modalContentWrapper,
                        children: _jsxs("div", {
                            "data-anime-state": animeState,
                            "data-full-width-layout": fullWidthLayout,
                            ref: contentClipRef,
                            className: styles.modalContentContainer,
                            children: [
                                _jsx("div", {
                                    className: styles.modalContentClip,
                                    children: _jsxs(Dialog.Content, {
                                        ...contentOptions,
                                        className: clsx({
                                            [styles.modalContent]: true,
                                            [styles.dialog]: dialogFrame
                                        }),
                                        children: [
                                            _jsx(Dialog.Title, {
                                                style: {
                                                    display: 'none'
                                                }
                                            }),
                                            _jsx("div", {
                                                style: {
                                                    height: '100%'
                                                },
                                                ref: contentRef,
                                                children: children
                                            })
                                        ]
                                    })
                                }),
                                controls ? _jsx("div", {
                                    style: {
                                        opacity: animation === 'zoom' ? 0 : undefined
                                    },
                                    ref: controlsRef,
                                    className: styles.modalControls,
                                    children: controls
                                }) : null
                            ]
                        })
                    })
                ]
            })
        })
    });
});
