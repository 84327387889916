import { SelectCollection } from '../collections';
import { SelectTag } from '../tags';
import { SelectPage } from '../view/edit-collection/select-page';
import { useSelectDialog } from './use-select-dialog';
export const useSelectCollection = ()=>{
    return useSelectDialog(SelectCollection, 'select-collection');
};
export const useSelectDoc = ()=>{
    return useSelectDialog(SelectPage, 'select-doc-dialog');
};
export const useSelectTag = ()=>{
    return useSelectDialog(SelectTag, 'select-tag-dialog');
};
