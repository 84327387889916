import { RootBlockSchema } from '@blocksuite/affine-model';
import { BlockService } from '@blocksuite/block-std';
import { Slot } from '@blocksuite/store';
export class MindmapService extends BlockService {
    static{
        this.flavour = RootBlockSchema.model.flavour;
    }
    center() {
        this.requestCenter.emit();
    }
    mounted() {}
    constructor(...args){
        super(...args), this.requestCenter = new Slot();
    }
}
