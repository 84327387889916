import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const SuccessIcon = ()=>{
    return _jsx("svg", {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsxs("g", {
            id: "SingleSelect",
            children: [
                _jsx("path", {
                    id: "Ellipse 2102 (Stroke)",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    d: "M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8Z",
                    fill: "#10CB86"
                }),
                _jsx("path", {
                    id: "Icon (Stroke)",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    d: "M11.0052 5.63143C11.2087 5.81802 11.2225 6.13431 11.0359 6.33787L7.36923 10.3379C7.27708 10.4384 7.14786 10.4969 7.01151 10.4999C6.87517 10.5028 6.74353 10.45 6.6471 10.3536L4.98043 8.68689C4.78517 8.49163 4.78517 8.17505 4.98043 7.97978C5.17569 7.78452 5.49228 7.78452 5.68754 7.97978L6.98495 9.27719L10.2987 5.66214C10.4853 5.45858 10.8016 5.44483 11.0052 5.63143Z",
                    fill: "white"
                })
            ]
        })
    });
};
