import { DEFAULT_SERVICE_VARIANT } from './consts.js';
import { stableHash } from './stable-hash.js';
export function createIdentifier(name, variant = DEFAULT_SERVICE_VARIANT) {
    return Object.assign((variant)=>{
        return createIdentifier(name, variant);
    }, {
        identifierName: name,
        variant
    });
}
export function createIdentifierFromConstructor(target) {
    return createIdentifier(`${target.name}${stableHash(target)}`);
}
export function parseIdentifier(input) {
    if (input.identifierName) {
        return input;
    } else if (typeof input === 'function' && input.name) {
        return createIdentifierFromConstructor(input);
    } else {
        throw new Error('Input is not a service identifier.');
    }
}
