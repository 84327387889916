import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { BlockServiceIdentifier, LifeCycleWatcherIdentifier, StdIdentifier } from '../identifier.js';
import { LifeCycleWatcher } from './lifecycle-watcher.js';
const idMap = new Map();
export class BlockServiceWatcher extends LifeCycleWatcher {
    constructor(std, blockService){
        super(std), this.blockService = blockService;
    }
    static setup(di) {
        if (!this.flavour) {
            throw new BlockSuiteError(ErrorCode.ValueNotExists, 'Flavour is not defined in the BlockServiceWatcher');
        }
        const id = idMap.get(this.flavour) ?? 0;
        idMap.set(this.flavour, id + 1);
        di.addImpl(LifeCycleWatcherIdentifier(`${this.flavour}-watcher-${id}`), this, [
            StdIdentifier,
            BlockServiceIdentifier(this.flavour)
        ]);
    }
}
