import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify } from '@affine/component';
import { AuthInput, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { Trans, useI18n } from '@affine/i18n';
import { ArrowRightBigIcon } from '@blocksuite/icons/rc';
import { useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthService } from '../../../modules/cloud';
import { emailRegex } from '../../../utils/email-regex';
import { OAuth } from './oauth';
import * as style from './style.css';
import { Captcha, useCaptcha } from './use-captcha';
function validateEmail(email) {
    return emailRegex.test(email);
}
export const SignIn = ({ setAuthData: setAuthState, onSkip })=>{
    const t = useI18n();
    const authService = useService(AuthService);
    const [searchParams] = useSearchParams();
    const [isMutating, setIsMutating] = useState(false);
    const [verifyToken, challenge] = useCaptcha();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const errorMsg = searchParams.get('error');
    const onContinue = useAsyncCallback(async ()=>{
        if (!validateEmail(email)) {
            setIsValidEmail(false);
            return;
        }
        setIsValidEmail(true);
        setIsMutating(true);
        try {
            const { hasPassword, registered } = await authService.checkUserByEmail(email);
            if (verifyToken) {
                if (registered) {
                    if (hasPassword) {
                        setAuthState({
                            state: 'signInWithPassword',
                            email
                        });
                    } else {
                        await authService.sendEmailMagicLink(email, verifyToken, challenge);
                        setAuthState({
                            state: 'afterSignInSendEmail',
                            email
                        });
                    }
                } else {
                    await authService.sendEmailMagicLink(email, verifyToken, challenge);
                    setAuthState({
                        state: 'afterSignUpSendEmail',
                        email
                    });
                }
            }
        } catch (err) {
            console.error(err);
            notify.error({
                title: 'Failed to send email. Please try again.'
            });
        }
        setIsMutating(false);
    }, [
        authService,
        challenge,
        email,
        setAuthState,
        verifyToken
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ModalHeader, {
                title: t['com.affine.auth.sign.in'](),
                subTitle: t['com.affine.brand.affineCloud']()
            }),
            _jsx(OAuth, {}),
            _jsxs("div", {
                className: style.authModalContent,
                children: [
                    _jsx(AuthInput, {
                        label: t['com.affine.settings.email'](),
                        placeholder: t['com.affine.auth.sign.email.placeholder'](),
                        onChange: setEmail,
                        error: !isValidEmail,
                        errorHint: isValidEmail ? '' : t['com.affine.auth.sign.email.error'](),
                        onEnter: onContinue
                    }),
                    verifyToken ? _jsx(Button, {
                        style: {
                            width: '100%'
                        },
                        size: "extraLarge",
                        "data-testid": "continue-login-button",
                        block: true,
                        loading: isMutating,
                        suffix: _jsx(ArrowRightBigIcon, {}),
                        suffixStyle: {
                            width: 20,
                            height: 20,
                            color: cssVar('blue')
                        },
                        onClick: onContinue,
                        children: t['com.affine.auth.sign.email.continue']()
                    }) : _jsx(Captcha, {}),
                    errorMsg && _jsx("div", {
                        className: style.errorMessage,
                        children: errorMsg
                    }),
                    _jsx("div", {
                        className: style.authMessage,
                        children: _jsxs(Trans, {
                            i18nKey: "com.affine.auth.sign.message",
                            children: [
                                'By clicking "Continue with Google/Email" above, you acknowledge that you agree to AFFiNE\'s ',
                                _jsx("a", {
                                    href: "https://wemem.ai/terms",
                                    target: "_blank",
                                    rel: "noreferrer",
                                    children: "Terms of Conditions"
                                }),
                                " and ",
                                _jsx("a", {
                                    href: "https://wemem.ai/privacy",
                                    target: "_blank",
                                    rel: "noreferrer",
                                    children: "Privacy Policy"
                                }),
                                "."
                            ]
                        })
                    })
                ]
            }),
            onSkip ? _jsxs(_Fragment, {
                children: [
                    _jsxs("div", {
                        className: style.skipDivider,
                        children: [
                            _jsx("div", {
                                className: style.skipDividerLine
                            }),
                            _jsx("span", {
                                className: style.skipDividerText,
                                children: "or"
                            }),
                            _jsx("div", {
                                className: style.skipDividerLine
                            })
                        ]
                    }),
                    _jsxs("div", {
                        className: style.skipSection,
                        children: [
                            _jsx("div", {
                                className: style.skipText,
                                children: t['com.affine.mobile.sign-in.skip.hint']()
                            }),
                            _jsx(Button, {
                                variant: "plain",
                                onClick: onSkip,
                                className: style.skipLink,
                                suffix: _jsx(ArrowRightBigIcon, {
                                    className: style.skipLinkIcon
                                }),
                                children: t['com.affine.mobile.sign-in.skip.link']()
                            })
                        ]
                    })
                ]
            }) : null
        ]
    });
};
