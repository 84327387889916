import { evalFilterList } from './filter';
export const createEmptyCollection = (id, data)=>{
    return {
        id,
        name: '',
        filterList: [],
        allowList: [],
        ...data
    };
};
export const filterByFilterList = (filterList, varMap)=>evalFilterList(filterList, varMap);
export const filterPage = (collection, page)=>{
    if (collection.filterList.length === 0) {
        return collection.allowList.includes(page.meta.id);
    }
    return filterPageByRules(collection.filterList, collection.allowList, page);
};
export const filterPageByRules = (rules, allowList, { meta, publicMode, favorite })=>{
    if (allowList?.includes(meta.id)) {
        return true;
    }
    return filterByFilterList(rules, {
        'Is Favourited': !!favorite,
        'Is Public': !!publicMode,
        Created: meta.createDate,
        Updated: meta.updatedDate ?? meta.createDate,
        Tags: meta.tags
    });
};
