import { isInsideEdgelessEditor } from '@blocksuite/affine-shared/utils';
import { PeekableController } from './controller.js';
const symbol = Symbol('peekable');
export const isPeekable = (e)=>{
    return Reflect.has(e, symbol) && e[symbol]?.peekable;
};
export const peek = (e, template)=>{
    isPeekable(e) && e[symbol]?.peek(template);
};
export const Peekable = (options = {
    action: [
        'double-click',
        'shift-click'
    ]
})=>(Class, context)=>{
        if (context.kind !== 'class') {
            console.error('@Peekable() can only be applied to a class');
            return;
        }
        if (options.action === undefined) options.action = [
            'double-click',
            'shift-click'
        ];
        const actions = Array.isArray(options.action) ? options.action : options.action ? [
            options.action
        ] : [];
        const derivedClass = class extends Class {
            connectedCallback() {
                super.connectedCallback();
                const target = (options.selector ? this.querySelector(options.selector) : this) || this;
                if (actions.includes('double-click')) {
                    this.disposables.addFromEvent(target, 'dblclick', (e)=>{
                        if (this[symbol].peekable) {
                            e.stopPropagation();
                            this[symbol].peek().catch(console.error);
                        }
                    });
                }
                if (actions.includes('shift-click') && !isInsideEdgelessEditor(this.std.host)) {
                    this.disposables.addFromEvent(target, 'click', (e)=>{
                        if (e.shiftKey && this[symbol].peekable) {
                            e.stopPropagation();
                            e.stopImmediatePropagation();
                            this[symbol].peek().catch(console.error);
                        }
                    });
                }
            }
            constructor(...args){
                super(...args), this[symbol] = new PeekableController(this, options.enableOn);
            }
        };
        return derivedClass;
    };
