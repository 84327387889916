import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapWithTrailing, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
const CACHE_KEY_PREFIX = 'cloud-doc-meta:';
export class CloudDocMeta extends Entity {
    constructor(store, docService, cache){
        super(), this.store = store, this.docService = docService, this.cache = cache, this.docId = this.docService.doc.id, this.workspaceId = this.docService.doc.workspace.id, this.cacheKey = `${CACHE_KEY_PREFIX}${this.workspaceId}:${this.docId}`, this.meta$ = LiveData.from(this.cache.watch(this.cacheKey), undefined), this.isRevalidating$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(exhaustMapWithTrailing(()=>{
            return fromPromise(this.store.fetchCloudDocMeta(this.workspaceId, this.docId)).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((meta)=>{
                this.cache.set(this.cacheKey, meta);
                return EMPTY;
            }), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false)));
        }));
    }
}
