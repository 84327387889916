export const translateLangs = [
    'Simplified Chinese',
    'Traditional Chinese',
    'English',
    'Spanish',
    'German',
    'French',
    'Italian',
    'Japanese',
    'Russian',
    'Korean'
];
export const textTones = [
    'Professional',
    'Informal',
    'Friendly',
    'Critical',
    'Humorous'
];
export const imageFilterStyles = [
    'Clay style',
    'Sketch style',
    'Anime style',
    'Pixel style'
];
export const imageProcessingTypes = [
    'Clearer',
    'Remove background',
    'Convert to sticker'
];
