import { jsx as _jsx } from "react/jsx-runtime";
import { Button, notify, useConfirmModal } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { SubscriptionService } from '@affine/core/modules/cloud';
import { SubscriptionPlan } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { SingleSelectSelectSolidIcon } from '@blocksuite/icons/rc';
import { useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { nanoid } from 'nanoid';
import { useState } from 'react';
export const AIResume = (btnProps)=>{
    const t = useI18n();
    const [idempotencyKey, setIdempotencyKey] = useState(nanoid());
    const subscription = useService(SubscriptionService).subscription;
    const [isMutating, setIsMutating] = useState(false);
    const { openConfirmModal } = useConfirmModal();
    const resume = useAsyncCallback(async ()=>{
        const aiSubscription = subscription.ai$.value;
        if (aiSubscription) {
            track.$.settingsPanel.plans.resumeSubscription({
                plan: SubscriptionPlan.AI,
                recurring: aiSubscription.recurring
            });
        }
        openConfirmModal({
            title: t['com.affine.payment.ai.action.resume.confirm.title'](),
            description: t['com.affine.payment.ai.action.resume.confirm.description'](),
            confirmText: t['com.affine.payment.ai.action.resume.confirm.confirm-text'](),
            confirmButtonOptions: {
                variant: 'primary'
            },
            cancelText: t['com.affine.payment.ai.action.resume.confirm.cancel-text'](),
            onConfirm: async ()=>{
                setIsMutating(true);
                await subscription.resumeSubscription(idempotencyKey, SubscriptionPlan.AI);
                if (aiSubscription) {
                    track.$.settingsPanel.plans.confirmResumingSubscription({
                        plan: aiSubscription.plan,
                        recurring: aiSubscription.recurring
                    });
                }
                notify({
                    icon: _jsx(SingleSelectSelectSolidIcon, {}),
                    iconColor: cssVar('processingColor'),
                    title: t['com.affine.payment.ai.action.resume.confirm.notify.title'](),
                    message: t['com.affine.payment.ai.action.resume.confirm.notify.msg']()
                });
                setIdempotencyKey(nanoid());
            }
        });
    }, [
        subscription,
        openConfirmModal,
        t,
        idempotencyKey
    ]);
    return _jsx(Button, {
        loading: isMutating,
        onClick: resume,
        variant: "primary",
        ...btnProps,
        children: t['com.affine.payment.ai.action.resume.button-label']()
    });
};
