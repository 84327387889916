import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, Scrollable } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { chunk } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { iconNames, nameToIcon } from './icons-mapping';
import * as styles from './icons-selector.css';
const iconsPerRow = 6;
const iconRows = chunk(iconNames, iconsPerRow);
export const IconsSelectorPanel = ({ selected, onSelectedChange })=>{
    const ref = useRef(null);
    useEffect(()=>{
        if (!ref.current) {
            return;
        }
        const iconButton = ref.current.querySelector(`[data-name="${selected}"]`);
        if (!iconButton) {
            return;
        }
        iconButton.scrollIntoView({
            block: 'center'
        });
    }, []);
    const t = useI18n();
    return _jsxs(Scrollable.Root, {
        children: [
            _jsx("div", {
                role: "heading",
                className: styles.menuHeader,
                children: t['com.affine.page-properties.icons']()
            }),
            _jsxs(Scrollable.Viewport, {
                className: styles.iconsContainerScrollable,
                children: [
                    _jsx("div", {
                        className: styles.iconsContainer,
                        ref: ref,
                        children: iconRows.map((iconRow, index)=>{
                            return _jsx("div", {
                                className: styles.iconsRow,
                                children: iconRow.map((iconName)=>{
                                    const Icon = nameToIcon(iconName);
                                    return _jsx("div", {
                                        onClick: ()=>onSelectedChange(iconName),
                                        className: styles.iconButton,
                                        "data-name": iconName,
                                        "data-active": selected === iconName,
                                        children: _jsx(Icon, {}, iconName)
                                    }, iconName);
                                })
                            }, index);
                        })
                    }),
                    _jsx(Scrollable.Scrollbar, {
                        className: styles.iconsContainerScroller
                    })
                ]
            })
        ]
    });
};
export const IconsSelectorButton = ({ selected, onSelectedChange })=>{
    const Icon = nameToIcon(selected);
    return _jsx(Menu, {
        items: _jsx(IconsSelectorPanel, {
            selected: selected,
            onSelectedChange: onSelectedChange
        }),
        children: _jsx("div", {
            className: styles.iconSelectorButton,
            children: _jsx(Icon, {})
        })
    });
};
