import { distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { AbstractType as YAbstractType, Array as YArray, Map as YMap } from 'yjs';
function parsePath(path) {
    const parts = path.split('.');
    return parts.map((part)=>{
        if (part.startsWith('[') && part.endsWith(']')) {
            const index = parseInt(part.slice(1, -1), 10);
            if (isNaN(index)) {
                throw new Error(`index: ${part} is not a number`);
            }
            return index;
        }
        return part;
    });
}
function _yjsDeepWatch(target, path) {
    if (path.length === 0) {
        return of(target);
    }
    const current = path[0];
    if (target instanceof YArray || target instanceof YMap) {
        return new Observable((subscriber)=>{
            const refresh = ()=>{
                if (typeof current === 'number' && target instanceof YArray) {
                    subscriber.next(target.get(current));
                } else if (typeof current === 'string' && target instanceof YMap) {
                    subscriber.next(target.get(current));
                } else {
                    subscriber.next(undefined);
                }
            };
            refresh();
            target.observe(refresh);
            return ()=>{
                target.unobserve(refresh);
            };
        }).pipe(distinctUntilChanged(), switchMap((arr)=>_yjsDeepWatch(arr, path.slice(1))));
    } else {
        return of(undefined);
    }
}
export function yjsObserveByPath(yjs, path) {
    const parsedPath = parsePath(path);
    return _yjsDeepWatch(yjs, parsedPath);
}
export function yjsObserveDeep(yjs) {
    return new Observable((subscriber)=>{
        const refresh = ()=>{
            subscriber.next(yjs);
        };
        refresh();
        if (yjs instanceof YAbstractType) {
            yjs.observeDeep(refresh);
            return ()=>{
                yjs.unobserveDeep(refresh);
            };
        }
        return;
    });
}
export function yjsObserve(yjs) {
    return new Observable((subscriber)=>{
        const refresh = ()=>{
            subscriber.next(yjs);
        };
        refresh();
        if (yjs instanceof YAbstractType) {
            yjs.observe(refresh);
            return ()=>{
                yjs.unobserve(refresh);
            };
        }
        return;
    });
}
