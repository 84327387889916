function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init__showMoreMenu, _init__moreButton, _init__moreMenu, _init_host, _init_actions, _init_content, _init_chatSessionId, _init_messageId, _init_isLast, _init_withMargin, _init_retry, _initProto;
import { createButtonPopper, NotificationProvider, Tooltip } from '@blocksuite/affine/blocks';
import { noop, WithDisposable } from '@blocksuite/affine/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { CopyIcon, MoreIcon, RetryIcon } from '../../_common/icons';
import { copyText } from '../../utils/editor-actions';
noop(Tooltip);
_dec = state(), _dec1 = query('.more-button'), _dec2 = query('.more-menu'), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
});
export class ChatCopyMore extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__showMoreMenu, _init__moreButton, _init__moreMenu, _init_host, _init_actions, _init_content, _init_chatSessionId, _init_messageId, _init_isLast, _init_withMargin, _init_retry, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_showMoreMenu"
            ],
            [
                _dec1,
                1,
                "_moreButton"
            ],
            [
                _dec2,
                1,
                "_moreMenu"
            ],
            [
                _dec3,
                1,
                "host"
            ],
            [
                _dec4,
                1,
                "actions"
            ],
            [
                _dec5,
                1,
                "content"
            ],
            [
                _dec6,
                1,
                "chatSessionId"
            ],
            [
                _dec7,
                1,
                "messageId"
            ],
            [
                _dec8,
                1,
                "isLast"
            ],
            [
                _dec9,
                1,
                "withMargin"
            ],
            [
                _dec10,
                1,
                "retry"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .copy-more {
      display: flex;
      gap: 8px;
      height: 36px;
      box-sizing: border-box;
      justify-content: flex-end;
      align-items: center;
      padding: 8px 0;

      div {
        cursor: pointer;
        border-radius: 4px;
      }

      div:hover {
        background-color: var(--affine-hover-color);
      }

      svg {
        color: var(--affine-icon-color);
      }
    }

    .more-menu {
      width: 226px;
      border-radius: 8px;
      background-color: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-menu-shadow);
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: absolute;
      z-index: 1;
      user-select: none;

      > div {
        height: 30px;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

        svg {
          margin-left: 12px;
        }
      }

      > div:hover {
        background-color: var(--affine-hover-color);
      }
    }
  `;
    }
    get _rootService() {
        return this.host.std.getService('affine:page');
    }
    get _selectionValue() {
        return this.host.selection.value;
    }
    get _currentTextSelection() {
        return this._selectionValue.find((v)=>v.type === 'text');
    }
    get _currentBlockSelections() {
        return this._selectionValue.filter((v)=>v.type === 'block');
    }
    #___private__showMoreMenu_1;
    get _showMoreMenu() {
        return this.#___private__showMoreMenu_1;
    }
    set _showMoreMenu(_v) {
        this.#___private__showMoreMenu_1 = _v;
    }
    #___private__moreButton_2;
    get _moreButton() {
        return this.#___private__moreButton_2;
    }
    set _moreButton(_v) {
        this.#___private__moreButton_2 = _v;
    }
    #___private__moreMenu_3;
    get _moreMenu() {
        return this.#___private__moreMenu_3;
    }
    set _moreMenu(_v) {
        this.#___private__moreMenu_3 = _v;
    }
    #___private_host_4;
    get host() {
        return this.#___private_host_4;
    }
    set host(_v) {
        this.#___private_host_4 = _v;
    }
    #___private_actions_5;
    get actions() {
        return this.#___private_actions_5;
    }
    set actions(_v) {
        this.#___private_actions_5 = _v;
    }
    #___private_content_6;
    get content() {
        return this.#___private_content_6;
    }
    set content(_v) {
        this.#___private_content_6 = _v;
    }
    #___private_chatSessionId_7;
    get chatSessionId() {
        return this.#___private_chatSessionId_7;
    }
    set chatSessionId(_v) {
        this.#___private_chatSessionId_7 = _v;
    }
    #___private_messageId_8;
    get messageId() {
        return this.#___private_messageId_8;
    }
    set messageId(_v) {
        this.#___private_messageId_8 = _v;
    }
    #___private_isLast_9;
    get isLast() {
        return this.#___private_isLast_9;
    }
    set isLast(_v) {
        this.#___private_isLast_9 = _v;
    }
    #___private_withMargin_10;
    get withMargin() {
        return this.#___private_withMargin_10;
    }
    set withMargin(_v) {
        this.#___private_withMargin_10 = _v;
    }
    #___private_retry_11;
    get retry() {
        return this.#___private_retry_11;
    }
    set retry(_v) {
        this.#___private_retry_11 = _v;
    }
    _toggle() {
        this._morePopper?.toggle();
    }
    updated(changed) {
        if (changed.has('isLast')) {
            if (this.isLast) {
                this._morePopper?.dispose();
                this._morePopper = null;
            } else if (!this._morePopper) {
                this._morePopper = createButtonPopper(this._moreButton, this._moreMenu, ({ display })=>this._showMoreMenu = display === 'show', {
                    mainAxis: 0,
                    crossAxis: -100
                });
            }
        }
    }
    render() {
        const { host, content, isLast, messageId, chatSessionId, actions } = this;
        return html`<style>
        .copy-more {
          margin-top: ${this.withMargin ? '8px' : '0px'};
          margin-bottom: ${this.withMargin ? '12px' : '0px'};
        }
        .more-menu {
          padding: ${this._showMoreMenu ? '8px' : '0px'};
        }
      </style>
      <div class="copy-more">
        ${content ? html`<div
              @click=${async ()=>{
            const success = await copyText(host, content);
            if (success) {
                this._notifySuccess('Copied to clipboard');
            }
        }}
            >
              ${CopyIcon}
              <affine-tooltip>Copy</affine-tooltip>
            </div>` : nothing}
        ${isLast ? html`<div @click=${()=>this.retry()}>
              ${RetryIcon}
              <affine-tooltip>Retry</affine-tooltip>
            </div>` : nothing}
        ${isLast ? nothing : html`<div class="more-button" @click=${this._toggle}>
              ${MoreIcon}
            </div> `}
      </div>

      <div class="more-menu">
        ${this._showMoreMenu ? repeat(actions.filter((action)=>action.showWhen(host)), (action)=>action.title, (action)=>{
            const currentSelections = {
                text: this._currentTextSelection,
                blocks: this._currentBlockSelections
            };
            return html`<div
                  @click=${async ()=>{
                const success = await action.handler(host, content, currentSelections, chatSessionId, messageId);
                if (success) {
                    this._notifySuccess(action.toast);
                }
            }}
                >
                  ${action.icon}
                  <div>${action.title}</div>
                </div>`;
        }) : nothing}
      </div>`;
    }
    constructor(...args){
        super(...args), this.#___private__showMoreMenu_1 = (_initProto(this), _init__showMoreMenu(this, false)), this.#___private__moreButton_2 = _init__moreButton(this), this.#___private__moreMenu_3 = _init__moreMenu(this), this._morePopper = null, this.#___private_host_4 = _init_host(this), this.#___private_actions_5 = _init_actions(this, []), this.#___private_content_6 = _init_content(this), this.#___private_chatSessionId_7 = _init_chatSessionId(this, undefined), this.#___private_messageId_8 = _init_messageId(this, undefined), this.#___private_isLast_9 = _init_isLast(this), this.#___private_withMargin_10 = _init_withMargin(this, false), this.#___private_retry_11 = _init_retry(this, ()=>{}), this._notifySuccess = (title)=>{
            if (!this._rootService) return;
            const notificationService = this.host.std.getOptional(NotificationProvider);
            notificationService?.notify({
                title: title,
                accent: 'success',
                onClose: function() {}
            });
        };
    }
}
