import { focusTextModel } from '@blocksuite/affine-components/rich-text';
export const addParagraphCommand = (ctx, next)=>{
    const { std } = ctx;
    const { doc, selection } = std;
    doc.captureSync();
    let blockId = ctx.blockId;
    if (!blockId) {
        const text = selection.find('text');
        blockId = text?.blockId;
    }
    if (!blockId) return;
    const model = doc.getBlock(blockId)?.model;
    if (!model) return;
    let id;
    if (model.children.length > 0) {
        id = doc.addBlock('affine:paragraph', {}, model, 0);
    } else {
        const parent = doc.getParent(model);
        if (!parent) return;
        const index = parent.children.indexOf(model);
        if (index < 0) return;
        id = doc.addBlock('affine:paragraph', {}, parent, index + 1);
    }
    focusTextModel(std, id);
    return next({
        paragraphConvertedId: id
    });
};
