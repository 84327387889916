import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import * as styles from './index.css';
export const Switch = ({ checked: checkedProp = false, onChange: onChangeProp, children, className, disabled, ...otherProps })=>{
    const [checkedState, setCheckedState] = useState(checkedProp);
    const checked = onChangeProp ? checkedProp : checkedState;
    const onChange = useCallback((event)=>{
        if (disabled) {
            return;
        }
        const newChecked = event.target.checked;
        if (onChangeProp) onChangeProp(newChecked);
        else setCheckedState(newChecked);
    }, [
        disabled,
        onChangeProp
    ]);
    return _jsxs("label", {
        className: clsx(styles.labelStyle, className),
        ...otherProps,
        children: [
            children,
            _jsx("input", {
                className: clsx(styles.inputStyle),
                type: "checkbox",
                value: checked ? 'on' : 'off',
                checked: checked,
                onChange: onChange
            }),
            _jsx("span", {
                className: clsx(styles.switchStyle, {
                    [styles.switchCheckedStyle]: checked,
                    [styles.switchDisabledStyle]: disabled
                })
            })
        ]
    });
};
