import { CommandIdentifier } from '../identifier.js';
export function CommandExtension(commands) {
    return {
        setup: (di)=>{
            Object.entries(commands).forEach(([name, command])=>{
                di.addImpl(CommandIdentifier(name), ()=>command);
            });
        }
    };
}
