import { IS_FIREFOX } from '@blocksuite/global/env';
export function caretRangeFromPoint(clientX, clientY) {
    if (IS_FIREFOX) {
        const caret = document.caretPositionFromPoint(clientX, clientY);
        const range = document.createRange();
        range.setStart(caret.offsetNode, caret.offset);
        return range;
    }
    const range = document.caretRangeFromPoint(clientX, clientY);
    if (!range) {
        return null;
    }
    const rangeRects = range?.getClientRects();
    if (rangeRects && rangeRects.length === 2 && range.startOffset === range.endOffset && clientY < rangeRects[0].y + rangeRects[0].height) {
        const deltaX = (rangeRects[0].x | 0) - (rangeRects[1].x | 0);
        if (deltaX > 0) {
            range.setStart(range.startContainer, range.startOffset - 1);
            range.setEnd(range.endContainer, range.endOffset - 1);
        }
    }
    return range;
}
export function resetNativeSelection(range) {
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    range && selection.addRange(range);
}
export function getCurrentNativeRange(selection = window.getSelection()) {
    if (!selection) {
        console.error('Failed to get current range, selection is null');
        return null;
    }
    if (selection.rangeCount === 0) {
        return null;
    }
    if (selection.rangeCount > 1) {
        console.warn('getCurrentNativeRange may be wrong, rangeCount > 1');
    }
    return selection.getRangeAt(0);
}
export function handleNativeRangeAtPoint(x, y) {
    const range = caretRangeFromPoint(x, y);
    const startContainer = range?.startContainer;
    if (startContainer instanceof Node) {
        resetNativeSelection(range);
    }
}
