import { useI18n } from '@affine/i18n';
import { useMemo } from 'react';
export const useNavConfig = ()=>{
    const t = useI18n();
    return useMemo(()=>[
            {
                title: t['com.affine.other-page.nav.official-website'](),
                path: 'https://wemem.ai'
            },
            {
                title: t['com.affine.other-page.nav.affine-community'](),
                path: 'https://community.wemem.ai/home'
            },
            {
                title: t['com.affine.other-page.nav.blog'](),
                path: 'https://wemem.ai/blog'
            },
            {
                title: t['com.affine.other-page.nav.contact-us'](),
                path: 'https://wemem.ai/about-us'
            }
        ], [
        t
    ]);
};
