export function stopPropagation(event) {
    event.stopPropagation();
}
export function preventDefault(event) {
    event.preventDefault();
}
export function stopEvent(event) {
    event.stopPropagation();
    event.preventDefault();
}
export function isNewTabTrigger(event) {
    return event ? event.ctrlKey || event.metaKey || event.button === 1 : false;
}
