import { DataHelper, typesystem } from './typesystem';
export const tNumber = typesystem.defineData(DataHelper.create('Number'));
export const tString = typesystem.defineData(DataHelper.create('String'));
export const tBoolean = typesystem.defineData(DataHelper.create('Boolean'));
export const tDate = typesystem.defineData(DataHelper.create('Date'));
export const tTag = typesystem.defineData({
    name: 'Tag',
    supers: []
});
export const tDateRange = typesystem.defineData(DataHelper.create('DateRange'));
