import { useDebugValue, useEffect, useState } from 'react';
const noop = ()=>{};
export const useRefEffect = (effect, dependencies = [])=>{
    const internalRef = useState(()=>{
        let currentValue = null;
        let cleanupPreviousEffect = noop;
        let currentDeps;
        const setRefValue = (newElement)=>{
            if (internalRef.dependencies_ !== currentDeps || currentValue !== newElement) {
                currentValue = newElement;
                currentDeps = internalRef.dependencies_;
                internalRef.cleanup_();
                if (newElement) {
                    cleanupPreviousEffect = internalRef.effect_(newElement) || noop;
                }
            }
        };
        return {
            cleanup_: ()=>{
                cleanupPreviousEffect();
                cleanupPreviousEffect = noop;
            },
            ref_: Object.defineProperty(setRefValue, 'current', {
                get: ()=>currentValue,
                set: setRefValue
            })
        };
    })[0];
    if (process.env.NODE_ENV !== 'production') {
        useDebugValue(internalRef.ref_.current);
    }
    internalRef.effect_ = effect;
    useEffect(()=>{
        internalRef.ref_(internalRef.ref_.current);
        return ()=>{
            if (internalRef.dependencies_ === dependencies) {
                internalRef.cleanup_();
                internalRef.dependencies_ = [];
            }
        };
    }, internalRef.dependencies_ = dependencies);
    return internalRef.ref_;
};
