import { computed } from '@preact/signals-core';
export class PropertyBase {
    get delete() {
        return ()=>this.view.propertyDelete(this.id);
    }
    get duplicate() {
        return ()=>this.view.propertyDuplicate(this.id);
    }
    get icon() {
        if (!this.type$.value) return undefined;
        return this.view.IconGet(this.type$.value);
    }
    get id() {
        return this.propertyId;
    }
    get index() {
        return this.view.propertyIndexGet(this.id);
    }
    get isFirst() {
        return this.view.propertyIndexGet(this.id) === 0;
    }
    get isLast() {
        return this.view.propertyIndexGet(this.id) === this.view.properties$.value.length - 1;
    }
    get typeSet() {
        return (type)=>this.view.propertyTypeSet(this.id, type);
    }
    constructor(view, propertyId){
        this.view = view;
        this.propertyId = propertyId;
        this.cells$ = computed(()=>{
            return this.view.rows$.value.map((id)=>this.cellGet(id));
        });
        this.data$ = computed(()=>{
            return this.view.propertyDataGet(this.id);
        });
        this.dataType$ = computed(()=>{
            return this.view.propertyDataTypeGet(this.id);
        });
        this.hide$ = computed(()=>{
            return this.view.propertyHideGet(this.id);
        });
        this.name$ = computed(()=>{
            return this.view.propertyNameGet(this.id);
        });
        this.readonly$ = computed(()=>{
            return this.view.readonly$.value || this.view.propertyReadonlyGet(this.id);
        });
        this.renderer$ = computed(()=>{
            return this.view.propertyMetaGet(this.type$.value)?.renderer.cellRenderer;
        });
        this.type$ = computed(()=>{
            return this.view.propertyTypeGet(this.id);
        });
    }
    cellGet(rowId) {
        return this.view.cellGet(rowId, this.id);
    }
    dataUpdate(updater) {
        const data = this.data$.value;
        this.view.propertyDataSet(this.id, {
            ...data,
            ...updater(data)
        });
    }
    hideSet(hide) {
        this.view.propertyHideSet(this.id, hide);
    }
    nameSet(name) {
        this.view.propertyNameSet(this.id, name);
    }
    stringValueGet(rowId) {
        return this.cellGet(rowId).stringValue$.value;
    }
    valueGet(rowId) {
        return this.cellGet(rowId).value$.value;
    }
    valueSet(rowId, value) {
        return this.cellGet(rowId).valueSet(value);
    }
    valueSetFromString(rowId, value) {
        const data = this.view.propertyParseValueFromString(this.id, value);
        if (!data) {
            return;
        }
        if (data.data) {
            this.dataUpdate(()=>data.data);
        }
        this.valueSet(rowId, data.value);
    }
}
