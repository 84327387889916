function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init__filteredItems, _init__position, _init_config, _init_context, _init_slashMenuElement, _init_triggerKey, _initProto, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _init__activeItem, _init_abortController, _init_context1, _init_depth, _init_mainMenuStyle, _init_menu, _initProto1;
import { ArrowDownIcon } from '@blocksuite/affine-components/icons';
import { createLitPortal } from '@blocksuite/affine-components/portal';
import { getInlineEditorByModel } from '@blocksuite/affine-components/rich-text';
import { isControlledKeyboardEvent, isFuzzyMatch, substringMatchScore } from '@blocksuite/affine-shared/utils';
import { assertExists, WithDisposable } from '@blocksuite/global/utils';
import { autoPlacement, offset } from '@floating-ui/dom';
import { html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { cleanSpecifiedTail, createKeydownObserver, getQuery } from '../../../_common/components/utils.js';
import { slashItemToolTipStyle, styles } from './styles.js';
import { getFirstNotDividerItem, isActionItem, isGroupDivider, isSubMenuItem, notGroupDivider, slashItemClassName } from './utils.js';
_dec = state(), _dec1 = state(), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = query('inner-slash-menu'), _dec5 = property({
    attribute: false
});
export class SlashMenu extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__filteredItems, _init__position, _init_config, _init_context, _init_slashMenuElement, _init_triggerKey, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_filteredItems"
            ],
            [
                _dec1,
                1,
                "_position"
            ],
            [
                _dec2,
                1,
                "config"
            ],
            [
                _dec3,
                1,
                "context"
            ],
            [
                _dec4,
                1,
                "slashMenuElement"
            ],
            [
                _dec5,
                1,
                "triggerKey"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _query() {
        return getQuery(this.inlineEditor, this._startRange);
    }
    get host() {
        return this.context.rootComponent.host;
    }
    constructor(inlineEditor, abortController = new AbortController()){
        super(), this.inlineEditor = inlineEditor, this.abortController = abortController, this._handleClickItem = (item)=>{
            cleanSpecifiedTail(this.host, this.context.model, this.triggerKey + (this._query || ''));
            this.inlineEditor.waitForUpdate().then(()=>{
                item.action(this.context)?.catch(console.error);
                this.abortController.abort();
            }).catch(console.error);
        }, this._initItemPathMap = ()=>{
            const traverse = (item, path)=>{
                this._itemPathMap.set(item, [
                    ...path
                ]);
                if (isSubMenuItem(item)) {
                    item.subMenu.forEach((subItem, index)=>traverse(subItem, [
                            ...path,
                            index
                        ]));
                }
            };
            this.config.items.forEach((item, index)=>traverse(item, [
                    index
                ]));
        }, this._itemPathMap = new Map(), this._queryState = 'off', this._startRange = this.inlineEditor.getInlineRange(), this._updateFilteredItems = ()=>{
            const query = this._query;
            if (query === null) {
                this.abortController.abort();
                return;
            }
            this._filteredItems = [];
            const searchStr = query.toLowerCase();
            if (searchStr === '' || searchStr.endsWith(' ')) {
                this._queryState = searchStr === '' ? 'off' : 'no_result';
                return;
            }
            let depth = 0;
            let queue = this.config.items.filter(notGroupDivider);
            while(queue.length !== 0){
                this._filteredItems = this._filteredItems.filter((item)=>!isSubMenuItem(item));
                this._filteredItems = this._filteredItems.concat(queue.filter(({ name, alias = [] })=>[
                        name,
                        ...alias
                    ].some((str)=>isFuzzyMatch(str, searchStr))));
                if (this._filteredItems.length !== 0 && depth >= 1) break;
                queue = queue.map((item)=>{
                    if (isSubMenuItem(item)) {
                        return item.subMenu.filter(notGroupDivider);
                    } else {
                        return [];
                    }
                }).flat();
                depth++;
            }
            this._filteredItems = this._filteredItems.sort((a, b)=>{
                return -(substringMatchScore(a.name, searchStr) - substringMatchScore(b.name, searchStr));
            });
            this._queryState = this._filteredItems.length === 0 ? 'no_result' : 'on';
        }, this.updatePosition = (position)=>{
            this._position = position;
        }, this.#___private__filteredItems_1 = (_initProto(this), _init__filteredItems(this, [])), this.#___private__position_2 = _init__position(this, null), this.#___private_config_3 = _init_config(this), this.#___private_context_4 = _init_context(this), this.#___private_slashMenuElement_5 = _init_slashMenuElement(this), this.#___private_triggerKey_6 = _init_triggerKey(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this._innerSlashMenuContext = {
            ...this.context,
            onClickItem: this._handleClickItem,
            tooltipTimeout: this.config.tooltipTimeout
        };
        this._initItemPathMap();
        this._disposables.addFromEvent(this, 'mousedown', (e)=>{
            e.preventDefault();
        });
        const inlineEditor = this.inlineEditor;
        if (!inlineEditor || !inlineEditor.eventSource) {
            console.error('inlineEditor or eventSource is not found');
            return;
        }
        createKeydownObserver({
            target: inlineEditor.eventSource,
            signal: this.abortController.signal,
            interceptor: (event, next)=>{
                const { key, isComposing, code } = event;
                if (key === this.triggerKey) {
                    return;
                }
                if (key === 'Process' && !isComposing && code === 'Slash') {
                    return;
                }
                if (key !== 'Backspace' && this._queryState === 'no_result') {
                    this.abortController.abort();
                    return;
                }
                if (key === 'ArrowRight' || key === 'ArrowLeft' || key === 'Escape') {
                    return;
                }
                next();
            },
            onInput: (isComposition)=>{
                if (isComposition) {
                    this._updateFilteredItems();
                } else {
                    this.inlineEditor.slots.renderComplete.once(this._updateFilteredItems);
                }
            },
            onPaste: ()=>{
                setTimeout(()=>{
                    this._updateFilteredItems();
                }, 50);
            },
            onDelete: ()=>{
                const curRange = this.inlineEditor.getInlineRange();
                if (!this._startRange || !curRange) {
                    return;
                }
                if (curRange.index < this._startRange.index) {
                    this.abortController.abort();
                }
                this.inlineEditor.slots.renderComplete.once(this._updateFilteredItems);
            },
            onAbort: ()=>this.abortController.abort()
        });
    }
    render() {
        const slashMenuStyles = this._position ? {
            transform: `translate(${this._position.x}, ${this._position.y})`,
            maxHeight: `${Math.min(this._position.height, this.config.maxHeight)}px`
        } : {
            visibility: 'hidden'
        };
        return html`${this._queryState !== 'no_result' ? html` <div
            class="overlay-mask"
            @click="${()=>this.abortController.abort()}"
          ></div>` : nothing}
      <inner-slash-menu
        .context=${this._innerSlashMenuContext}
        .menu=${this._queryState === 'off' ? this.config.items : this._filteredItems}
        .onClickItem=${this._handleClickItem}
        .mainMenuStyle=${slashMenuStyles}
        .abortController=${this.abortController}
      >
      </inner-slash-menu>`;
    }
    #___private__filteredItems_1;
    get _filteredItems() {
        return this.#___private__filteredItems_1;
    }
    set _filteredItems(_v) {
        this.#___private__filteredItems_1 = _v;
    }
    #___private__position_2;
    get _position() {
        return this.#___private__position_2;
    }
    set _position(_v) {
        this.#___private__position_2 = _v;
    }
    #___private_config_3;
    get config() {
        return this.#___private_config_3;
    }
    set config(_v) {
        this.#___private_config_3 = _v;
    }
    #___private_context_4;
    get context() {
        return this.#___private_context_4;
    }
    set context(_v) {
        this.#___private_context_4 = _v;
    }
    #___private_slashMenuElement_5;
    get slashMenuElement() {
        return this.#___private_slashMenuElement_5;
    }
    set slashMenuElement(_v) {
        this.#___private_slashMenuElement_5 = _v;
    }
    #___private_triggerKey_6;
    get triggerKey() {
        return this.#___private_triggerKey_6;
    }
    set triggerKey(_v) {
        this.#___private_triggerKey_6 = _v;
    }
}
_dec6 = state(), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
});
export class InnerSlashMenu extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__activeItem, _init_abortController, _init_context1, _init_depth, _init_mainMenuStyle, _init_menu, _initProto1] } = _apply_decs_2203_r(this, [
            [
                _dec6,
                1,
                "_activeItem"
            ],
            [
                _dec7,
                1,
                "abortController"
            ],
            [
                _dec8,
                1,
                "context"
            ],
            [
                _dec9,
                1,
                "depth"
            ],
            [
                _dec10,
                1,
                "mainMenuStyle"
            ],
            [
                _dec11,
                1,
                "menu"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    _scrollToItem(item) {
        const shadowRoot = this.shadowRoot;
        if (!shadowRoot) {
            return;
        }
        const text = isGroupDivider(item) ? item.groupName : item.name;
        const ele = shadowRoot.querySelector(`icon-button[text="${text}"]`);
        if (!ele) {
            return;
        }
        ele.scrollIntoView({
            block: 'nearest'
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.abortController?.signal?.addEventListener('abort', ()=>{
            this._subMenuAbortController?.abort();
        });
        this.addEventListener('wheel', (event)=>{
            if (this._currentSubMenu) {
                event.preventDefault();
            }
        });
        const inlineEditor = getInlineEditorByModel(this.context.rootComponent.host, this.context.model);
        if (!inlineEditor || !inlineEditor.eventSource) {
            console.error('inlineEditor or eventSource is not found');
            return;
        }
        inlineEditor.eventSource.addEventListener('keydown', (event)=>{
            if (this._currentSubMenu) return;
            if (event.isComposing) return;
            const { key, ctrlKey, metaKey, altKey, shiftKey } = event;
            const onlyCmd = (ctrlKey || metaKey) && !altKey && !shiftKey;
            const onlyShift = shiftKey && !isControlledKeyboardEvent(event);
            const notControlShift = !(ctrlKey || metaKey || altKey || shiftKey);
            let moveStep = 0;
            if (key === 'ArrowUp' && notControlShift || key === 'Tab' && onlyShift || key === 'P' && onlyCmd || key === 'p' && onlyCmd) {
                moveStep = -1;
            }
            if (key === 'ArrowDown' && notControlShift || key === 'Tab' && notControlShift || key === 'n' && onlyCmd || key === 'N' && onlyCmd) {
                moveStep = 1;
            }
            if (moveStep !== 0) {
                let itemIndex = this.menu.indexOf(this._activeItem);
                do {
                    itemIndex = (itemIndex + moveStep + this.menu.length) % this.menu.length;
                }while (isGroupDivider(this.menu[itemIndex]));
                this._activeItem = this.menu[itemIndex];
                this._scrollToItem(this._activeItem);
                event.preventDefault();
                event.stopPropagation();
            }
            if (key === 'ArrowRight' && notControlShift) {
                if (isSubMenuItem(this._activeItem)) {
                    this._openSubMenu(this._activeItem);
                }
                event.preventDefault();
                event.stopPropagation();
            }
            if ((key === 'ArrowLeft' || key === 'Escape') && notControlShift) {
                this.abortController.abort();
                event.preventDefault();
                event.stopPropagation();
            }
            if (key === 'Enter' && notControlShift) {
                if (isSubMenuItem(this._activeItem)) {
                    this._openSubMenu(this._activeItem);
                } else if (isActionItem(this._activeItem)) {
                    this.context.onClickItem(this._activeItem);
                }
                event.preventDefault();
                event.stopPropagation();
            }
        }, {
            capture: true,
            signal: this.abortController.signal
        });
    }
    disconnectedCallback() {
        this.abortController.abort();
    }
    render() {
        if (this.menu.length === 0) return nothing;
        const style = styleMap(this.mainMenuStyle ?? {
            position: 'relative'
        });
        return html`<div
      class="slash-menu"
      style=${style}
      data-testid=${`sub-menu-${this.depth}`}
    >
      ${this.menu.map(this._renderItem)}
    </div>`;
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('menu') && this.menu.length !== 0) {
            const firstItem = getFirstNotDividerItem(this.menu);
            assertExists(firstItem);
            this._activeItem = firstItem;
            this._subMenuAbortController?.abort();
        }
    }
    #___private__activeItem_7;
    get _activeItem() {
        return this.#___private__activeItem_7;
    }
    set _activeItem(_v) {
        this.#___private__activeItem_7 = _v;
    }
    #___private_abortController_8;
    get abortController() {
        return this.#___private_abortController_8;
    }
    set abortController(_v) {
        this.#___private_abortController_8 = _v;
    }
    #___private_context_9;
    get context() {
        return this.#___private_context_9;
    }
    set context(_v) {
        this.#___private_context_9 = _v;
    }
    #___private_depth_10;
    get depth() {
        return this.#___private_depth_10;
    }
    set depth(_v) {
        this.#___private_depth_10 = _v;
    }
    #___private_mainMenuStyle_11;
    get mainMenuStyle() {
        return this.#___private_mainMenuStyle_11;
    }
    set mainMenuStyle(_v) {
        this.#___private_mainMenuStyle_11 = _v;
    }
    #___private_menu_12;
    get menu() {
        return this.#___private_menu_12;
    }
    set menu(_v) {
        this.#___private_menu_12 = _v;
    }
    constructor(...args){
        super(...args), this._closeSubMenu = ()=>{
            this._subMenuAbortController?.abort();
            this._subMenuAbortController = null;
            this._currentSubMenu = null;
        }, this._currentSubMenu = null, this._openSubMenu = (item)=>{
            if (item === this._currentSubMenu) return;
            const itemElement = this.shadowRoot?.querySelector(`.${slashItemClassName(item)}`);
            if (!itemElement) return;
            this._closeSubMenu();
            this._currentSubMenu = item;
            this._subMenuAbortController = new AbortController();
            this._subMenuAbortController.signal.addEventListener('abort', ()=>{
                this._closeSubMenu();
            });
            const subMenuElement = createLitPortal({
                shadowDom: false,
                template: html`<inner-slash-menu
        .context=${this.context}
        .menu=${item.subMenu}
        .depth=${this.depth + 1}
        .abortController=${this._subMenuAbortController}
      >
        ${item.subMenu.map(this._renderItem)}
      </inner-slash-menu>`,
                computePosition: {
                    referenceElement: itemElement,
                    autoUpdate: true,
                    middleware: [
                        offset(12),
                        autoPlacement({
                            allowedPlacements: [
                                'right-start',
                                'right-end'
                            ]
                        })
                    ]
                },
                abortController: this._subMenuAbortController
            });
            subMenuElement.style.zIndex = `calc(var(--affine-z-index-popover) + ${this.depth})`;
            subMenuElement.focus();
        }, this._renderActionItem = (item)=>{
            const { name, icon, description, tooltip, customTemplate } = item;
            const hover = item === this._activeItem;
            return html`<icon-button
      class="slash-menu-item ${slashItemClassName(item)}"
      width="100%"
      height="44px"
      text=${customTemplate ?? name}
      subText=${ifDefined(description)}
      data-testid="${name}"
      hover=${hover}
      @mousemove=${()=>{
                this._activeItem = item;
                this._closeSubMenu();
            }}
      @click=${()=>this.context.onClickItem(item)}
    >
      ${icon && html`<div class="slash-menu-item-icon">${icon}</div>`}
      ${tooltip && html`<affine-tooltip
        tip-position="right"
        .offset=${22}
        .tooltipStyle=${slashItemToolTipStyle}
        .hoverOptions=${{
                enterDelay: this.context.tooltipTimeout,
                allowMultiple: false
            }}
      >
        <div class="tooltip-figure">${tooltip.figure}</div>
        <div class="tooltip-caption">${tooltip.caption}</div>
      </affine-tooltip>`}
    </icon-button>`;
        }, this._renderGroupItem = (item)=>{
            return html`<div class="slash-menu-group-name">${item.groupName}</div>`;
        }, this._renderItem = (item)=>{
            if (isGroupDivider(item)) return this._renderGroupItem(item);
            else if (isActionItem(item)) return this._renderActionItem(item);
            else if (isSubMenuItem(item)) return this._renderSubMenuItem(item);
            else {
                console.error('Unknown item type for slash menu');
                console.error(item);
                return nothing;
            }
        }, this._renderSubMenuItem = (item)=>{
            const { name, icon, description } = item;
            const hover = item === this._activeItem;
            return html`<icon-button
      class="slash-menu-item ${slashItemClassName(item)}"
      width="100%"
      height="44px"
      text=${name}
      subText=${ifDefined(description)}
      data-testid="${name}"
      hover=${hover}
      @mousemove=${()=>{
                this._activeItem = item;
                this._openSubMenu(item);
            }}
      @touchstart=${()=>{
                isSubMenuItem(item) && (this._currentSubMenu === item ? this._closeSubMenu() : this._openSubMenu(item));
            }}
    >
      ${icon && html`<div class="slash-menu-item-icon">${icon}</div>`}
      <div slot="suffix" style="transform: rotate(-90deg);">
        ${ArrowDownIcon}
      </div>
    </icon-button>`;
        }, this._subMenuAbortController = null, this.#___private__activeItem_7 = (_initProto1(this), _init__activeItem(this)), this.#___private_abortController_8 = _init_abortController(this), this.#___private_context_9 = _init_context1(this), this.#___private_depth_10 = _init_depth(this, 0), this.#___private_mainMenuStyle_11 = _init_mainMenuStyle(this, null), this.#___private_menu_12 = _init_menu(this);
    }
}
