export class AwarenessEngine {
    constructor(connections){
        this.connections = connections;
    }
    connect(awareness) {
        this.connections.forEach((connection)=>connection.connect(awareness));
    }
    disconnect() {
        this.connections.forEach((connection)=>connection.disconnect());
    }
    dispose() {
        this.connections.forEach((connection)=>connection.dispose?.());
    }
}
