function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__clone, _init__container, _init__curIndex, _init__indicatorLine, _init_edgeless, _init_embed, _init_frames, _initProto;
import { CommonUtils } from '@blocksuite/affine-block-surface';
import { DisposableGroup, WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
_dec = query('.clone'), _dec1 = query('.edgeless-frame-order-items-container'), _dec2 = state(), _dec3 = query('.indicator-line'), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
});
export class EdgelessFrameOrderMenu extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__clone, _init__container, _init__curIndex, _init__indicatorLine, _init_edgeless, _init_embed, _init_frames, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_clone"
            ],
            [
                _dec1,
                1,
                "_container"
            ],
            [
                _dec2,
                1,
                "_curIndex"
            ],
            [
                _dec3,
                1,
                "_indicatorLine"
            ],
            [
                _dec4,
                1,
                "edgeless"
            ],
            [
                _dec5,
                1,
                "embed"
            ],
            [
                _dec6,
                1,
                "frames"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      position: relative;
    }
    .edgeless-frame-order-items-container {
      max-height: 281px;
      border-radius: 8px;
      padding: 8px;
      background: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-menu-shadow);
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .edgeless-frame-order-items-container.embed {
      padding: 0;
      background: unset;
      box-shadow: unset;
      border-radius: 0;
    }

    .item {
      box-sizing: border-box;
      width: 256px;
      border-radius: 4px;
      padding: 4px;
      display: flex;
      gap: 4px;
      align-items: center;
      cursor: grab;
    }

    .draggable:hover {
      background-color: var(--affine-hover-color);
    }

    .item:hover .drag-indicator {
      opacity: 1;
    }

    .drag-indicator {
      cursor: pointer;
      width: 4px;
      height: 12px;
      border-radius: 1px;
      opacity: 0.2;
      background: var(--affine-placeholder-color);
      margin-right: 2px;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      color: var(--affine-text-primary-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .clone {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      left: 8px;
      height: 30px;
      border: 1px solid var(--affine-border-color);
      box-shadow: var(--affine-menu-shadow);
      background-color: var(--affine-white);
      pointer-events: none;
    }

    .indicator-line {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      left: 8px;
      background-color: var(--affine-primary-color);
      height: 1px;
      width: 90%;
    }
  `;
    }
    _bindEvent() {
        const { _disposables } = this;
        _disposables.addFromEvent(this._container, 'wheel', (e)=>{
            e.stopPropagation();
        });
        _disposables.addFromEvent(this._container, 'pointerdown', (e)=>{
            const ele = e.target;
            const draggable = ele.closest('.draggable');
            if (!draggable) return;
            const clone = this._clone;
            const indicatorLine = this._indicatorLine;
            clone.style.visibility = 'visible';
            const rect = draggable.getBoundingClientRect();
            const index = Number(draggable.getAttribute('index'));
            this._curIndex = index;
            let newIndex = -1;
            const containerRect = this._container.getBoundingClientRect();
            const start = containerRect.top + 8;
            const end = containerRect.bottom;
            const shiftX = e.clientX - rect.left;
            const shiftY = e.clientY - rect.top;
            function moveAt(x, y) {
                clone.style.left = x - containerRect.left - shiftX + 'px';
                clone.style.top = y - containerRect.top - shiftY + 'px';
            }
            function isInsideContainer(e) {
                return e.clientY >= start && e.clientY <= end;
            }
            moveAt(e.clientX, e.clientY);
            this._disposables.addFromEvent(document, 'pointermove', (e)=>{
                indicatorLine.style.visibility = 'visible';
                moveAt(e.clientX, e.clientY);
                if (isInsideContainer(e)) {
                    const relativeY = e.pageY + this._container.scrollTop - start;
                    let top = 0;
                    if (relativeY < rect.height / 2) {
                        newIndex = 0;
                        top = this.embed ? -2 : 4;
                    } else {
                        newIndex = Math.ceil((relativeY - rect.height / 2) / (rect.height + 10));
                        top = (this.embed ? -2 : 7.5) + newIndex * rect.height + (newIndex - 0.5) * 4;
                    }
                    indicatorLine.style.top = top - this._container.scrollTop + 'px';
                    return;
                }
                newIndex = -1;
            });
            this._disposables.addFromEvent(document, 'pointerup', ()=>{
                clone.style.visibility = 'hidden';
                indicatorLine.style.visibility = 'hidden';
                if (newIndex >= 0 && newIndex <= this.frames.length && newIndex !== index && newIndex !== index + 1) {
                    const before = this.frames[newIndex - 1]?.index || null;
                    const after = this.frames[newIndex]?.index || null;
                    const frame = this.frames[index];
                    this.edgeless.service.updateElement(frame.id, {
                        index: CommonUtils.generateKeyBetween(before, after)
                    });
                    this.edgeless.doc.captureSync();
                    this.requestUpdate();
                }
                this._disposables.dispose();
                this._disposables = new DisposableGroup();
                this._bindEvent();
            });
        });
    }
    firstUpdated() {
        this._bindEvent();
    }
    render() {
        const frame = this.frames[this._curIndex];
        return html`
      <div
        class="edgeless-frame-order-items-container ${this.embed ? 'embed' : ''}"
        @click=${(e)=>e.stopPropagation()}
      >
        ${repeat(this.frames, (frame)=>frame.id, (frame, index)=>html`
            <div class="item draggable" id=${frame.id} index=${index}>
              <div class="drag-indicator"></div>
              <div class="title">${frame.title.toString()}</div>
            </div>
          `)}
        <div class="indicator-line"></div>
        <div class="clone item">
          ${frame ? html`<div class="drag-indicator"></div>
                <div class="index">${this._curIndex + 1}</div>
                <div class="title">${frame.title.toString()}</div>` : nothing}
        </div>
      </div>
    `;
    }
    #___private__clone_1;
    get _clone() {
        return this.#___private__clone_1;
    }
    set _clone(_v) {
        this.#___private__clone_1 = _v;
    }
    #___private__container_2;
    get _container() {
        return this.#___private__container_2;
    }
    set _container(_v) {
        this.#___private__container_2 = _v;
    }
    #___private__curIndex_3;
    get _curIndex() {
        return this.#___private__curIndex_3;
    }
    set _curIndex(_v) {
        this.#___private__curIndex_3 = _v;
    }
    #___private__indicatorLine_4;
    get _indicatorLine() {
        return this.#___private__indicatorLine_4;
    }
    set _indicatorLine(_v) {
        this.#___private__indicatorLine_4 = _v;
    }
    #___private_edgeless_5;
    get edgeless() {
        return this.#___private_edgeless_5;
    }
    set edgeless(_v) {
        this.#___private_edgeless_5 = _v;
    }
    #___private_embed_6;
    get embed() {
        return this.#___private_embed_6;
    }
    set embed(_v) {
        this.#___private_embed_6 = _v;
    }
    #___private_frames_7;
    get frames() {
        return this.#___private_frames_7;
    }
    set frames(_v) {
        this.#___private_frames_7 = _v;
    }
    constructor(...args){
        super(...args), this.#___private__clone_1 = (_initProto(this), _init__clone(this)), this.#___private__container_2 = _init__container(this), this.#___private__curIndex_3 = _init__curIndex(this, -1), this.#___private__indicatorLine_4 = _init__indicatorLine(this), this.#___private_edgeless_5 = _init_edgeless(this), this.#___private_embed_6 = _init_embed(this, false), this.#___private_frames_7 = _init_frames(this);
    }
}
