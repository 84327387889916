import { getStrokeRadius } from './getStrokeRadius.js';
import { add, dist2, dpr, lrp, mul, neg, per, prj, rotAround, sub, uni } from './vec.js';
const { min, PI } = Math;
const RATE_OF_PRESSURE_CHANGE = 0.275;
const FIXED_PI = PI + 0.0001;
export function getStrokeOutlinePoints(points, options = {}) {
    const { size = 16, smoothing = 0.5, thinning = 0.5, simulatePressure = true, easing = (t)=>t, start = {}, end = {}, last: isComplete = false } = options;
    const { cap: capStart = true, easing: taperStartEase = (t)=>t * (2 - t) } = start;
    const { cap: capEnd = true, easing: taperEndEase = (t)=>--t * t * t + 1 } = end;
    if (points.length === 0 || size <= 0) {
        return [];
    }
    const totalLength = points[points.length - 1].runningLength;
    const taperStart = start.taper === false ? 0 : start.taper === true ? Math.max(size, totalLength) : start.taper;
    const taperEnd = end.taper === false ? 0 : end.taper === true ? Math.max(size, totalLength) : end.taper;
    const minDistance = Math.pow(size * smoothing, 2);
    const leftPts = [];
    const rightPts = [];
    let prevPressure = points.slice(0, 10).reduce((acc, curr)=>{
        let pressure = curr.pressure;
        if (simulatePressure) {
            const sp = min(1, curr.distance / size);
            const rp = min(1, 1 - sp);
            pressure = min(1, acc + (rp - acc) * (sp * RATE_OF_PRESSURE_CHANGE));
        }
        return (acc + pressure) / 2;
    }, points[0].pressure);
    let radius = getStrokeRadius(size, thinning, points[points.length - 1].pressure, easing);
    let firstRadius = undefined;
    let prevVector = points[0].vector;
    let pl = points[0].point;
    let pr = pl;
    let tl = pl;
    let tr = pr;
    let isPrevPointSharpCorner = false;
    for(let i = 0; i < points.length; i++){
        let { pressure } = points[i];
        const { point, vector, distance, runningLength } = points[i];
        if (i < points.length - 1 && totalLength - runningLength < 3) {
            continue;
        }
        if (thinning) {
            if (simulatePressure) {
                const sp = min(1, distance / size);
                const rp = min(1, 1 - sp);
                pressure = min(1, prevPressure + (rp - prevPressure) * (sp * RATE_OF_PRESSURE_CHANGE));
            }
            radius = getStrokeRadius(size, thinning, pressure, easing);
        } else {
            radius = size / 2;
        }
        if (firstRadius === undefined) {
            firstRadius = radius;
        }
        const ts = runningLength < taperStart ? taperStartEase(runningLength / taperStart) : 1;
        const te = totalLength - runningLength < taperEnd ? taperEndEase((totalLength - runningLength) / taperEnd) : 1;
        radius = Math.max(0.01, radius * Math.min(ts, te));
        const nextVector = (i < points.length - 1 ? points[i + 1] : points[i]).vector;
        const nextDpr = i < points.length - 1 ? dpr(vector, nextVector) : 1.0;
        const prevDpr = dpr(vector, prevVector);
        const isPointSharpCorner = prevDpr < 0 && !isPrevPointSharpCorner;
        const isNextPointSharpCorner = nextDpr !== null && nextDpr < 0;
        if (isPointSharpCorner || isNextPointSharpCorner) {
            const offset = mul(per(prevVector), radius);
            for(let step = 1 / 13, t = 0; t <= 1; t += step){
                tl = rotAround(sub(point, offset), point, FIXED_PI * t);
                leftPts.push(tl);
                tr = rotAround(add(point, offset), point, FIXED_PI * -t);
                rightPts.push(tr);
            }
            pl = tl;
            pr = tr;
            if (isNextPointSharpCorner) {
                isPrevPointSharpCorner = true;
            }
            continue;
        }
        isPrevPointSharpCorner = false;
        if (i === points.length - 1) {
            const offset = mul(per(vector), radius);
            leftPts.push(sub(point, offset));
            rightPts.push(add(point, offset));
            continue;
        }
        const offset = mul(per(lrp(nextVector, vector, nextDpr)), radius);
        tl = sub(point, offset);
        if (i <= 1 || dist2(pl, tl) > minDistance) {
            leftPts.push(tl);
            pl = tl;
        }
        tr = add(point, offset);
        if (i <= 1 || dist2(pr, tr) > minDistance) {
            rightPts.push(tr);
            pr = tr;
        }
        prevPressure = pressure;
        prevVector = vector;
    }
    const firstPoint = points[0].point.slice(0, 2);
    const lastPoint = points.length > 1 ? points[points.length - 1].point.slice(0, 2) : add(points[0].point, [
        1,
        1
    ]);
    const startCap = [];
    const endCap = [];
    if (points.length === 1) {
        if (!(taperStart || taperEnd) || isComplete) {
            const start = prj(firstPoint, uni(per(sub(firstPoint, lastPoint))), -(firstRadius || radius));
            const dotPts = [];
            for(let step = 1 / 13, t = step; t <= 1; t += step){
                dotPts.push(rotAround(start, firstPoint, FIXED_PI * 2 * t));
            }
            return dotPts;
        }
    } else {
        if (taperStart || taperEnd && points.length === 1) {} else if (capStart) {
            for(let step = 1 / 13, t = step; t <= 1; t += step){
                const pt = rotAround(rightPts[0], firstPoint, FIXED_PI * t);
                startCap.push(pt);
            }
        } else {
            const cornersVector = sub(leftPts[0], rightPts[0]);
            const offsetA = mul(cornersVector, 0.5);
            const offsetB = mul(cornersVector, 0.51);
            startCap.push(sub(firstPoint, offsetA), sub(firstPoint, offsetB), add(firstPoint, offsetB), add(firstPoint, offsetA));
        }
        const direction = per(neg(points[points.length - 1].vector));
        if (taperEnd || taperStart && points.length === 1) {
            endCap.push(lastPoint);
        } else if (capEnd) {
            const start = prj(lastPoint, direction, radius);
            for(let step = 1 / 29, t = step; t < 1; t += step){
                endCap.push(rotAround(start, lastPoint, FIXED_PI * 3 * t));
            }
        } else {
            endCap.push(add(lastPoint, mul(direction, radius)), add(lastPoint, mul(direction, radius * 0.99)), sub(lastPoint, mul(direction, radius * 0.99)), sub(lastPoint, mul(direction, radius)));
        }
    }
    return leftPts.concat(endCap, rightPts.reverse(), startCap);
}
