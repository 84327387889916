export class DataSourceBase {
    contextGet(key) {
        return this.context.get(key);
    }
    contextSet(key, value) {
        this.context.set(key, value);
    }
    propertyReadonlyGet(_propertyId) {
        return false;
    }
    constructor(){
        this.context = new Map();
    }
}
