import { DisposableGroup } from '@blocksuite/global/utils';
import { createLitPortal } from '../portal/index.js';
import { whenHover } from './when-hover.js';
const DEFAULT_HOVER_OPTIONS = {
    transition: {
        duration: 100,
        in: {
            opacity: '1',
            transition: 'opacity 0.1s ease-in-out'
        },
        out: {
            opacity: '0',
            transition: 'opacity 0.1s ease-in-out'
        }
    },
    setPortalAsFloating: true,
    allowMultiple: false
};
const abortHoverPortal = ({ portal, hoverOptions, abortController })=>{
    if (!portal || !hoverOptions.transition) {
        abortController.abort();
        return;
    }
    Object.assign(portal.style, hoverOptions.transition.out);
    portal.addEventListener('transitionend', ()=>{
        abortController.abort();
    }, {
        signal: abortController.signal
    });
    portal.addEventListener('transitioncancel', ()=>{
        abortController.abort();
    }, {
        signal: abortController.signal
    });
    setTimeout(()=>abortController.abort(), hoverOptions.transition.duration);
};
export class HoverController {
    get isHovering() {
        return this._isHovering;
    }
    get portal() {
        return this._portal;
    }
    get setReference() {
        return this._setReference;
    }
    constructor(host, onHover, hoverOptions){
        this._isHovering = false;
        this._setReference = ()=>{
            console.error('setReference is not ready');
        };
        this._disposables = new DisposableGroup();
        this.onAbort = ()=>{
            this.abort();
        };
        this._hoverOptions = {
            ...DEFAULT_HOVER_OPTIONS,
            ...hoverOptions
        };
        (this.host = host).addController(this);
        this._onHover = onHover;
    }
    abort(force = false) {
        if (!this._abortController) return;
        if (force) {
            this._abortController.abort();
            return;
        }
        abortHoverPortal({
            portal: this._portal,
            hoverOptions: this._hoverOptions,
            abortController: this._abortController
        });
    }
    hostConnected() {
        if (this._disposables.disposed) {
            this._disposables = new DisposableGroup();
        }
        const { setReference, setFloating, dispose } = whenHover((isHover)=>{
            if (!this.host.isConnected) {
                return;
            }
            this._isHovering = isHover;
            if (!isHover) {
                this.onAbort();
                return;
            }
            if (this._abortController) {
                return;
            }
            this._abortController = new AbortController();
            this._abortController.signal.addEventListener('abort', ()=>{
                this._abortController = undefined;
            });
            if (!this._hoverOptions.allowMultiple) {
                HoverController.globalAbortController?.abort();
                HoverController.globalAbortController = this._abortController;
            }
            const portalOptions = this._onHover({
                setReference,
                abortController: this._abortController
            });
            if (!portalOptions) {
                this._abortController.abort();
                return;
            }
            this._portal = createLitPortal({
                ...portalOptions,
                abortController: this._abortController
            });
            const transition = this._hoverOptions.transition;
            if (transition) {
                Object.assign(this._portal.style, transition.in);
            }
            if (this._hoverOptions.setPortalAsFloating) {
                setFloating(this._portal);
            }
        }, this._hoverOptions);
        this._setReference = setReference;
        this._disposables.add(dispose);
    }
    hostDisconnected() {
        this._abortController?.abort();
        this._disposables.dispose();
    }
}
