import { CSSResult, LitElement } from 'lit';
export class ShadowlessElement extends LitElement {
    static{
        this.disableShadowRoot = true;
    }
    static finalizeStyles(styles) {
        let elementStyles = super.finalizeStyles(styles);
        if (this.disableShadowRoot) {
            elementStyles.forEach((s)=>{
                if (s instanceof CSSResult && typeof document !== 'undefined') {
                    const styleRoot = document.head;
                    const style = document.createElement('style');
                    style.textContent = s.cssText;
                    styleRoot.append(style);
                }
            });
            elementStyles = [];
        }
        return elementStyles;
    }
    createRenderRoot() {
        return this.constructor.disableShadowRoot ? this : super.createRenderRoot();
    }
}
