import { toURLSearchParams } from '@affine/core/modules/navigation';
import { createContext, useCallback, useContext, useMemo } from 'react';
export const NavigateContext = createContext(null);
export var RouteLogic;
(function(RouteLogic) {
    RouteLogic["REPLACE"] = "replace";
    RouteLogic["PUSH"] = "push";
})(RouteLogic || (RouteLogic = {}));
export function useNavigateHelper() {
    const navigate = useContext(NavigateContext);
    if (!navigate) {
        throw new Error('useNavigateHelper must be used within a NavigateProvider');
    }
    const jumpToPage = useCallback((workspaceId, pageId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/${pageId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToPageBlock = useCallback((workspaceId, pageId, mode, blockIds, elementIds, logic = "push")=>{
        const search = toURLSearchParams({
            mode,
            blockIds,
            elementIds
        });
        const query = search?.size ? `?${search.toString()}` : '';
        return navigate(`/workspace/${workspaceId}/${pageId}${query}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToCollections = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/collection`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToTags = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/tag`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToTag = useCallback((workspaceId, tagId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/tag/${tagId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToCollection = useCallback((workspaceId, collectionId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/collection/${collectionId}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToManageFeeds = useCallback((workspaceId, logic = "push")=>{
        return navigate(`/workspace/${workspaceId}/feed/manage`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToFeedsDocs = useCallback((workspaceId, status = 'all', feedId, logic = "push")=>{
        if (feedId) {
            return navigate(`/workspace/${workspaceId}/feed/${feedId}/${status}`, {
                replace: logic === "replace"
            });
        }
        return navigate(`/workspace/${workspaceId}/feed/${status}`, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const openPage = useCallback((workspaceId, pageId, logic)=>{
        return jumpToPage(workspaceId, pageId, logic);
    }, [
        jumpToPage
    ]);
    const jumpToIndex = useCallback((logic = "push", opt)=>{
        return navigate({
            pathname: '/',
            search: opt?.search
        }, {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpTo404 = useCallback((logic = "push")=>{
        return navigate('/404', {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToExpired = useCallback((logic = "push")=>{
        return navigate('/expired', {
            replace: logic === "replace"
        });
    }, [
        navigate
    ]);
    const jumpToSignIn = useCallback((redirectUri, logic = "push", otherOptions, params)=>{
        const searchParams = new URLSearchParams();
        if (redirectUri) {
            searchParams.set('redirect_uri', redirectUri);
        }
        if (params) {
            for(const key in params)searchParams.set(key, params[key]);
        }
        return navigate('/sign-in' + (searchParams.toString() ? '?' + searchParams.toString() : ''), {
            replace: logic === "replace",
            ...otherOptions
        });
    }, [
        navigate
    ]);
    const openInApp = useCallback((schema, path)=>{
        const encodedUrl = encodeURIComponent(`${schema}://${path}`);
        return navigate(`/open-app/url?schema=${schema}&url=${encodedUrl}`);
    }, [
        navigate
    ]);
    const jumpToImportTemplate = useCallback((name, snapshotUrl)=>{
        return navigate(`/template/import?name=${encodeURIComponent(name)}&snapshotUrl=${encodeURIComponent(snapshotUrl)}`);
    }, [
        navigate
    ]);
    return useMemo(()=>({
            jumpToPage,
            jumpToPageBlock,
            jumpToIndex,
            jumpTo404,
            openPage,
            jumpToExpired,
            jumpToSignIn,
            jumpToCollection,
            jumpToFeedsDocs,
            jumpToManageFeeds,
            jumpToCollections,
            jumpToTags,
            jumpToTag,
            openInApp,
            jumpToImportTemplate
        }), [
        jumpToPage,
        jumpToPageBlock,
        jumpToIndex,
        jumpTo404,
        openPage,
        jumpToExpired,
        jumpToSignIn,
        jumpToCollection,
        jumpToFeedsDocs,
        jumpToManageFeeds,
        jumpToCollections,
        jumpToTags,
        jumpToTag,
        openInApp,
        jumpToImportTemplate
    ]);
}
