import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scrollable } from '@affine/component';
import { PageDetailSkeleton } from '@affine/component/page-detail-skeleton';
import { AIProvider } from '@affine/core/blocksuite/presets/ai';
import { AffineErrorBoundary } from '@affine/core/components/affine/affine-error-boundary';
import { BlockSuiteEditor } from '@affine/core/components/blocksuite/block-suite-editor';
import { EditorOutlineViewer } from '@affine/core/components/blocksuite/outline-viewer';
import { PageNotFound } from '@affine/core/desktop/pages/404';
import { EditorService } from '@affine/core/modules/editor';
import { DebugLogger } from '@affine/debug';
import { RefNodeSlotsProvider } from '@blocksuite/affine/blocks';
import { Bound, DisposableGroup } from '@blocksuite/affine/global/utils';
import { FrameworkScope, useLiveData, useService, useServices } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { WorkbenchService } from '../../../workbench';
import { PeekViewService } from '../../services/peek-view';
import { useEditor } from '../utils';
import * as styles from './doc-peek-view.css';
const logger = new DebugLogger('doc-peek-view');
function fitViewport(editor, xywh) {
    try {
        if (!editor.host) {
            throw new Error('editor host is not ready');
        }
        const rootService = editor.host.std.getService('affine:page');
        if (!rootService) {
            return;
        }
        rootService.viewport.onResize();
        if (xywh) {
            const viewport = {
                xywh: xywh,
                padding: [
                    60,
                    20,
                    20,
                    20
                ]
            };
            rootService.viewport.setViewportByBound(Bound.deserialize(viewport.xywh), viewport.padding, false);
        } else {
            const data = rootService.getFitToScreenData();
            rootService.viewport.setViewport(data.zoom, [
                data.centerX,
                data.centerY
            ], false);
        }
    } catch (e) {
        logger.warn('failed to fitViewPort', e);
    }
}
function DocPeekPreviewEditor({ xywh }) {
    const { editorService } = useServices({
        EditorService
    });
    const editor = editorService.editor;
    const doc = editor.doc;
    const workspace = editor.doc.workspace;
    const mode = useLiveData(editor.mode$);
    const workbench = useService(WorkbenchService).workbench;
    const peekView = useService(PeekViewService).peekView;
    const editorElement = useLiveData(editor.editorContainer$);
    const handleOnEditorReady = useCallback((editorContainer)=>{
        if (!editorContainer.host) {
            return;
        }
        const disposableGroup = new DisposableGroup();
        const refNodeSlots = editorContainer.host.std.getOptional(RefNodeSlotsProvider);
        if (!refNodeSlots) return;
        disposableGroup.add(refNodeSlots.docLinkClicked.on((options)=>{
            peekView.open({
                type: 'doc',
                docId: options.pageId,
                ...options.params
            }).catch(console.error);
        }));
        editor.setEditorContainer(editorContainer);
        const unbind = editor.bindEditorContainer(editorContainer, editorContainer.title);
        if (mode === 'edgeless') {
            fitViewport(editorContainer, xywh);
        }
        return ()=>{
            unbind();
            editor.setEditorContainer(null);
            disposableGroup.dispose();
        };
    }, [
        editor,
        mode,
        peekView,
        xywh
    ]);
    useEffect(()=>{
        const disposable = AIProvider.slots.requestOpenWithChat.on(()=>{
            if (doc) {
                workbench.openDoc(doc.id);
                peekView.close();
            }
        });
        return ()=>{
            disposable.dispose();
        };
    }, [
        doc,
        peekView,
        workbench,
        workspace.id
    ]);
    const openOutlinePanel = useCallback(()=>{
        workbench.openDoc(doc.id);
        workbench.openSidebar();
        workbench.activeView$.value.activeSidebarTab('outline');
        peekView.close();
    }, [
        doc,
        peekView,
        workbench
    ]);
    return _jsxs(AffineErrorBoundary, {
        children: [
            _jsxs(Scrollable.Root, {
                children: [
                    _jsx(Scrollable.Viewport, {
                        className: clsx('affine-page-viewport', styles.affineDocViewport),
                        children: _jsx(BlockSuiteEditor, {
                            className: styles.editor,
                            mode: mode,
                            page: doc.blockSuiteDoc,
                            onEditorReady: handleOnEditorReady
                        })
                    }),
                    _jsx(Scrollable.Scrollbar, {})
                ]
            }),
            _jsx(EditorOutlineViewer, {
                editor: editorElement,
                show: mode === 'page',
                openOutlinePanel: openOutlinePanel
            })
        ]
    });
}
export function DocPeekPreview({ docId, blockIds, elementIds, mode, xywh }) {
    const { doc, editor, loading } = useEditor(docId, mode, {
        blockIds,
        elementIds
    });
    if (!doc || !editor) {
        return loading ? _jsx(PageDetailSkeleton, {}, "current-page-is-null") : _jsx(PageNotFound, {
            noPermission: true
        });
    }
    return _jsx(FrameworkScope, {
        scope: doc.scope,
        children: _jsx(FrameworkScope, {
            scope: editor.scope,
            children: _jsx(DocPeekPreviewEditor, {
                xywh: xywh
            })
        })
    });
}
