function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init_dragHandleContainer, _init_dragHandleGrabber, _init_dragHoverRect, _initProto;
import { DocModeProvider, DragHandleConfigIdentifier } from '@blocksuite/affine-shared/services';
import { getScrollContainer, isInsideEdgelessEditor, isInsidePageEditor, matchFlavours } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { DisposableGroup, Point, Rect } from '@blocksuite/global/utils';
import { computed, signal } from '@preact/signals-core';
import { html } from 'lit';
import { query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { isTopLevelBlock } from '../../../root-block/edgeless/utils/query.js';
import { autoScroll } from '../../../root-block/text-selection/utils.js';
import { DragHandleOptionsRunner } from './config.js';
import { PreviewHelper } from './helpers/preview-helper.js';
import { RectHelper } from './helpers/rect-helper.js';
import { SelectionHelper } from './helpers/selection-helper.js';
import { styles } from './styles.js';
import { calcDropTarget, containBlock, containChildBlock, getClosestBlockByPoint, getClosestNoteBlock, isOutOfNoteBlock, updateDragHandleClassName } from './utils.js';
import { DragEventWatcher } from './watchers/drag-event-watcher.js';
import { EdgelessWatcher } from './watchers/edgeless-watcher.js';
import { HandleEventWatcher } from './watchers/handle-event-watcher.js';
import { KeyboardEventWatcher } from './watchers/keyboard-event-watcher.js';
import { PageWatcher } from './watchers/page-watcher.js';
import { PointerEventWatcher } from './watchers/pointer-event-watcher.js';
_dec = query('.affine-drag-handle-container'), _dec1 = query('.affine-drag-handle-grabber'), _dec2 = state();
export class AffineDragHandleWidget extends WidgetComponent {
    static{
        ({ e: [_init_dragHandleContainer, _init_dragHandleGrabber, _init_dragHoverRect, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "dragHandleContainer"
            ],
            [
                _dec1,
                1,
                "dragHandleGrabber"
            ],
            [
                _dec2,
                1,
                "dragHoverRect"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get dragHandleContainerOffsetParent() {
        return this.dragHandleContainer.parentElement;
    }
    get mode() {
        return this.std.get(DocModeProvider).getEditorMode();
    }
    get rootComponent() {
        return this.block;
    }
    clearRaf() {
        if (this.rafID) {
            cancelAnimationFrame(this.rafID);
            this.rafID = 0;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.std.provider.getAll(DragHandleConfigIdentifier).forEach((config)=>{
            this.optionRunner.register(config);
        });
        this.pointerEventWatcher.watch();
        this._keyboardEventWatcher.watch();
        this._dragEventWatcher.watch();
    }
    disconnectedCallback() {
        this.hide(true);
        this._disposables.dispose();
        this._anchorModelDisposables?.dispose();
        super.disconnectedCallback();
    }
    firstUpdated() {
        this.hide(true);
        this._disposables.addFromEvent(this.host, 'pointerleave', ()=>{
            this.hide();
        });
        this._handleEventWatcher.watch();
        if (isInsidePageEditor(this.host)) {
            this._pageWatcher.watch();
        } else if (isInsideEdgelessEditor(this.host)) {
            this.edgelessWatcher.watch();
        }
    }
    render() {
        const hoverRectStyle = styleMap(this.dragHoverRect ? {
            width: `${this.dragHoverRect.width}px`,
            height: `${this.dragHoverRect.height}px`,
            top: `${this.dragHoverRect.top}px`,
            left: `${this.dragHoverRect.left}px`
        } : {
            display: 'none'
        });
        return html`
      <div class="affine-drag-handle-widget">
        <div class="affine-drag-handle-container">
          <div class="affine-drag-handle-grabber"></div>
        </div>
        <div class="affine-drag-hover-rect" style=${hoverRectStyle}></div>
      </div>
    `;
    }
    #___private_dragHandleContainer_1;
    get dragHandleContainer() {
        return this.#___private_dragHandleContainer_1;
    }
    set dragHandleContainer(_v) {
        this.#___private_dragHandleContainer_1 = _v;
    }
    #___private_dragHandleGrabber_2;
    get dragHandleGrabber() {
        return this.#___private_dragHandleGrabber_2;
    }
    set dragHandleGrabber(_v) {
        this.#___private_dragHandleGrabber_2 = _v;
    }
    #___private_dragHoverRect_3;
    get dragHoverRect() {
        return this.#___private_dragHoverRect_3;
    }
    set dragHoverRect(_v) {
        this.#___private_dragHoverRect_3 = _v;
    }
    constructor(...args){
        super(...args), this._anchorModelDisposables = null, this._dragEventWatcher = new DragEventWatcher(this), this._getBlockView = (blockId)=>{
            return this.host.view.getBlock(blockId);
        }, this._getDropResult = (state)=>{
            const point = new Point(state.raw.x, state.raw.y);
            const closestBlock = getClosestBlockByPoint(this.host, this.rootComponent, point);
            if (!closestBlock) return null;
            const blockId = closestBlock.model.id;
            const model = closestBlock.model;
            const isDatabase = matchFlavours(model, [
                'affine:database'
            ]);
            if (isDatabase) return null;
            const isDraggedElementNote = this.draggingElements.length === 1 && matchFlavours(this.draggingElements[0].model, [
                'affine:note'
            ]);
            if (isDraggedElementNote) {
                const parent = this.std.doc.getParent(closestBlock.model);
                if (!parent) return null;
                const parentElement = this._getBlockView(parent.id);
                if (!parentElement) return null;
                if (!matchFlavours(parentElement.model, [
                    'affine:note'
                ])) return null;
            }
            if (containBlock(this.draggingElements.map((block)=>block.model.id), blockId) || containChildBlock(this.draggingElements, model)) {
                return null;
            }
            let rect = null;
            let dropType = 'before';
            const result = calcDropTarget(point, model, closestBlock, this.draggingElements, this.scale.peek(), isDraggedElementNote === false);
            if (result) {
                rect = result.rect;
                dropType = result.dropType;
            }
            if (isDraggedElementNote && dropType === 'in') return null;
            const dropIndicator = {
                rect,
                dropBlockId: blockId,
                dropType
            };
            return dropIndicator;
        }, this._handleEventWatcher = new HandleEventWatcher(this), this._keyboardEventWatcher = new KeyboardEventWatcher(this), this._pageWatcher = new PageWatcher(this), this._removeDropIndicator = ()=>{
            if (this.dropIndicator) {
                this.dropIndicator.remove();
                this.dropIndicator = null;
            }
        }, this._reset = ()=>{
            this.draggingElements = [];
            this.dropBlockId = '';
            this.dropType = null;
            this.lastDragPointerState = null;
            this.rafID = 0;
            this.dragging = false;
            this.dragHoverRect = null;
            this.anchorBlockId.value = null;
            this.isDragHandleHovered = false;
            this.isHoverDragHandleVisible = false;
            this.isTopLevelDragHandleVisible = false;
            this.pointerEventWatcher.reset();
            this.previewHelper.removeDragPreview();
            this._removeDropIndicator();
            this._resetCursor();
        }, this._resetCursor = ()=>{
            document.documentElement.classList.remove('affine-drag-preview-grabbing');
        }, this._resetDropResult = ()=>{
            this.dropBlockId = '';
            this.dropType = null;
            if (this.dropIndicator) this.dropIndicator.rect = null;
        }, this._updateDropResult = (dropResult)=>{
            if (!this.dropIndicator) return;
            this.dropBlockId = dropResult?.dropBlockId ?? '';
            this.dropType = dropResult?.dropType ?? null;
            if (dropResult?.rect) {
                const offsetParentRect = this.dragHandleContainerOffsetParent.getBoundingClientRect();
                let { left, top } = dropResult.rect;
                left -= offsetParentRect.left;
                top -= offsetParentRect.top;
                const { width, height } = dropResult.rect;
                const rect = Rect.fromLWTH(left, width, top, height);
                this.dropIndicator.rect = rect;
            } else {
                this.dropIndicator.rect = dropResult?.rect ?? null;
            }
        }, this.anchorBlockComponent = computed(()=>{
            if (!this.anchorBlockId.value) return null;
            return this.std.view.getBlock(this.anchorBlockId.value);
        }), this.anchorBlockId = signal(null), this.anchorEdgelessElement = computed(()=>{
            if (!this.anchorBlockId.value) return null;
            if (this.mode === 'page') return null;
            const service = this.std.getService('affine:page');
            const edgelessElement = service.getElementById(this.anchorBlockId.value);
            return isTopLevelBlock(edgelessElement) ? edgelessElement : null;
        }), this.center = [
            0,
            0
        ], this.dragging = false, this.rectHelper = new RectHelper(this), this.draggingAreaRect = computed(this.rectHelper.getDraggingAreaRect), this.draggingElements = [], this.dragPreview = null, this.dropBlockId = '', this.dropIndicator = null, this.dropType = null, this.edgelessWatcher = new EdgelessWatcher(this), this.handleAnchorModelDisposables = ()=>{
            const block = this.anchorBlockComponent.peek();
            if (!block) return;
            const blockModel = block.model;
            if (this._anchorModelDisposables) {
                this._anchorModelDisposables.dispose();
                this._anchorModelDisposables = null;
            }
            this._anchorModelDisposables = new DisposableGroup();
            this._anchorModelDisposables.add(blockModel.propsUpdated.on(()=>this.hide()));
            this._anchorModelDisposables.add(blockModel.deleted.on(()=>this.hide()));
        }, this.hide = (force = false)=>{
            updateDragHandleClassName();
            this.isHoverDragHandleVisible = false;
            this.isTopLevelDragHandleVisible = false;
            this.isDragHandleHovered = false;
            this.anchorBlockId.value = null;
            if (this.dragHandleContainer) {
                this.dragHandleContainer.style.display = 'none';
            }
            if (force) {
                this._reset();
            }
        }, this.isDragHandleHovered = false, this.isHoverDragHandleVisible = false, this.isTopLevelDragHandleVisible = false, this.lastDragPointerState = null, this.noteScale = signal(1), this.optionRunner = new DragHandleOptionsRunner(), this.pointerEventWatcher = new PointerEventWatcher(this), this.previewHelper = new PreviewHelper(this), this.rafID = 0, this.scale = signal(1), this.scaleInNote = computed(()=>this.scale.value * this.noteScale.value), this.selectionHelper = new SelectionHelper(this), this.updateDropIndicator = (state, shouldAutoScroll = false)=>{
            const point = new Point(state.raw.x, state.raw.y);
            const closestNoteBlock = getClosestNoteBlock(this.host, this.rootComponent, point);
            if (!closestNoteBlock || isOutOfNoteBlock(this.host, closestNoteBlock, point, this.scale.peek())) {
                this._resetDropResult();
            } else {
                const dropResult = this._getDropResult(state);
                this._updateDropResult(dropResult);
            }
            this.lastDragPointerState = state;
            if (this.mode === 'page') {
                if (!shouldAutoScroll) return;
                const scrollContainer = getScrollContainer(this.rootComponent);
                const result = autoScroll(scrollContainer, state.raw.y);
                if (!result) {
                    this.clearRaf();
                    return;
                }
                this.rafID = requestAnimationFrame(()=>this.updateDropIndicator(state, true));
            } else {
                this.clearRaf();
            }
        }, this.updateDropIndicatorOnScroll = ()=>{
            if (!this.dragging || this.draggingElements.length === 0 || !this.lastDragPointerState) return;
            const state = this.lastDragPointerState;
            this.rafID = requestAnimationFrame(()=>this.updateDropIndicator(state, false));
        }, this.#___private_dragHandleContainer_1 = (_initProto(this), _init_dragHandleContainer(this)), this.#___private_dragHandleGrabber_2 = _init_dragHandleGrabber(this), this.#___private_dragHoverRect_3 = _init_dragHoverRect(this, null);
    }
}
