import { AffineReference } from '@blocksuite/affine/blocks';
import { Entity, LiveData } from '@toeverything/infra';
import { firstValueFrom, map, race } from 'rxjs';
import { resolveLinkToDoc } from '../../navigation';
const isEmbedLinkedDocModel = (blockModel)=>{
    return blockModel.flavour === 'affine:embed-linked-doc';
};
const isEmbedSyncedDocModel = (blockModel)=>{
    return blockModel.flavour === 'affine:embed-synced-doc';
};
const isImageBlockModel = (blockModel)=>{
    return blockModel.flavour === 'affine:image';
};
const isSurfaceRefModel = (blockModel)=>{
    return blockModel.flavour === 'affine:surface-ref';
};
const isAIChatBlockModel = (blockModel)=>{
    return blockModel.flavour === 'affine:embed-ai-chat';
};
function resolvePeekInfoFromPeekTarget(peekTarget, template) {
    if (template) {
        return {
            type: 'template',
            template
        };
    }
    if (peekTarget instanceof AffineReference) {
        const referenceInfo = peekTarget.referenceInfo;
        if (referenceInfo) {
            const { pageId: docId } = referenceInfo;
            const info = {
                type: 'doc',
                docId
            };
            Object.assign(info, referenceInfo.params);
            return info;
        }
    } else if ('model' in peekTarget) {
        const blockModel = peekTarget.model;
        if (isEmbedLinkedDocModel(blockModel)) {
            const info = {
                type: 'doc',
                docId: blockModel.pageId
            };
            Object.assign(info, blockModel.params);
            return info;
        } else if (isEmbedSyncedDocModel(blockModel)) {
            return {
                type: 'doc',
                docId: blockModel.pageId
            };
        } else if (isSurfaceRefModel(blockModel)) {
            const refModel = peekTarget.referenceModel;
            if (refModel) {
                const docId = 'doc' in refModel ? refModel.doc.id : refModel.surface.doc.id;
                return {
                    type: 'doc',
                    docId,
                    mode: 'edgeless',
                    xywh: refModel.xywh
                };
            }
        } else if (isImageBlockModel(blockModel)) {
            return {
                type: 'image',
                docId: blockModel.doc.id,
                blockIds: [
                    blockModel.id
                ]
            };
        } else if (isAIChatBlockModel(blockModel)) {
            return {
                type: 'ai-chat-block',
                docId: blockModel.doc.id,
                model: blockModel,
                host: peekTarget.host
            };
        }
    } else if (peekTarget instanceof HTMLAnchorElement) {
        const maybeDoc = resolveLinkToDoc(peekTarget.href);
        if (maybeDoc) {
            const info = {
                type: 'doc',
                docId: maybeDoc.docId
            };
            if (maybeDoc.mode) {
                info.mode = maybeDoc.mode;
            }
            if (maybeDoc.blockIds?.length) {
                info.blockIds = maybeDoc.blockIds;
            }
            if (maybeDoc.elementIds?.length) {
                info.elementIds = maybeDoc.elementIds;
            }
            return info;
        }
    } else if ('docId' in peekTarget) {
        return {
            type: 'doc',
            docId: peekTarget.docId,
            blockIds: peekTarget.blockIds
        };
    }
    return;
}
export class PeekViewEntity extends Entity {
    constructor(workbenchService){
        super(), this.workbenchService = workbenchService, this._active$ = new LiveData(null), this._show$ = new LiveData({
            animation: 'zoom',
            value: false
        }), this.active$ = this._active$.distinctUntilChanged(), this.show$ = this._show$.map((show)=>this._active$.value !== null ? show : null).distinctUntilChanged(), this.open = async (target, template)=>{
            const resolvedInfo = resolvePeekInfoFromPeekTarget(target, template);
            if (!resolvedInfo) {
                return;
            }
            const active = this._active$.value;
            if (active?.info.type === 'doc' && this.show$.value?.value) {
                this.workbenchService.workbench.openDoc(active.info.docId);
            }
            this._active$.next({
                target,
                info: resolvedInfo
            });
            this._show$.next({
                value: true,
                animation: resolvedInfo.type === 'doc' || resolvedInfo.type === 'ai-chat-block' ? 'zoom' : 'fade'
            });
            return firstValueFrom(race(this._active$, this.show$).pipe(map(()=>{})));
        }, this.close = (animation)=>{
            this._show$.next({
                value: false,
                animation: animation ?? this._show$.value.animation
            });
        };
    }
}
