import { FontFamily, FontStyle, FontWeight } from '@blocksuite/affine-model';
export const AffineCanvasTextFonts = [
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-Light-BETA.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-SemiBold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-LightItalic-BETA.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Inter,
        url: 'https://cdn.wemem.ai/fonts/Inter-SemiBoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Kalam,
        url: 'https://cdn.wemem.ai/fonts/Kalam-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Kalam,
        url: 'https://cdn.wemem.ai/fonts/Kalam-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Kalam,
        url: 'https://cdn.wemem.ai/fonts/Kalam-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-LightItalic.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://cdn.wemem.ai/fonts/Satoshi-BoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-Medium.woff',
        weight: FontWeight.Medium,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-SemiBold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-LightItalic.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://cdn.wemem.ai/fonts/Poppins-SemiBoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Lora,
        url: 'https://cdn.wemem.ai/fonts/Lora-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Lora,
        url: 'https://cdn.wemem.ai/fonts/Lora-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Lora,
        url: 'https://cdn.wemem.ai/fonts/Lora-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Lora,
        url: 'https://cdn.wemem.ai/fonts/Lora-BoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.BebasNeue,
        url: 'https://cdn.wemem.ai/fonts/BebasNeue-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.BebasNeue,
        url: 'https://cdn.wemem.ai/fonts/BebasNeue-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.OrelegaOne,
        url: 'https://cdn.wemem.ai/fonts/OrelegaOne-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    }
];
export const CommunityCanvasTextFonts = [
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Light-BETA.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-LightItalic-BETA.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Inter,
        url: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Kalam,
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Kalam,
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Kalam,
        url: 'https://fonts.cdnfonts.com/s/13130/Kalam-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-LightItalic.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Satoshi,
        url: 'https://fonts.cdnfonts.com/s/85546/Satoshi-BoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Medium.woff',
        weight: FontWeight.Medium,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-SemiBold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-LightItalic.woff',
        weight: FontWeight.Light,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Poppins,
        url: 'https://fonts.cdnfonts.com/s/16009/Poppins-SemiBoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Lora,
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Lora,
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Bold.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.Lora,
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-Italic.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.Lora,
        url: 'https://fonts.cdnfonts.com/s/29883/Lora-BoldItalic.woff',
        weight: FontWeight.SemiBold,
        style: FontStyle.Italic
    },
    {
        font: FontFamily.BebasNeue,
        url: 'https://fonts.cdnfonts.com/s/14902/BebasNeue%20Light.woff',
        weight: FontWeight.Light,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.BebasNeue,
        url: 'https://fonts.cdnfonts.com/s/14902/BebasNeue-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    },
    {
        font: FontFamily.OrelegaOne,
        url: 'https://fonts.cdnfonts.com/s/93179/OrelegaOne-Regular.woff',
        weight: FontWeight.Regular,
        style: FontStyle.Normal
    }
];
