import { useService, WorkspacesService } from '@toeverything/infra';
import { useEffect, useState } from 'react';
export function useWorkspace(meta) {
    const workspaceManager = useService(WorkspacesService);
    const [workspace, setWorkspace] = useState(null);
    useEffect(()=>{
        if (!meta) {
            setWorkspace(null);
            return;
        }
        const ref = workspaceManager.open({
            metadata: meta
        });
        setWorkspace(ref.workspace);
        return ()=>{
            ref.dispose();
        };
    }, [
        meta,
        workspaceManager
    ]);
    return workspace;
}
