import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '@affine/i18n';
import { SignOutIcon } from '@blocksuite/icons/rc';
import { Avatar } from '../../ui/avatar';
import { Button, IconButton } from '../../ui/button';
import { AffineOtherPageLayout } from '../affine-other-page-layout';
import { NotFoundPattern } from './not-found-pattern';
import { largeButtonEffect, notFoundPageContainer, wrapper } from './styles.css';
export const NoPermissionOrNotFound = ({ user, onBack, onSignOut, signInComponent })=>{
    const t = useI18n();
    return _jsx(AffineOtherPageLayout, {
        children: _jsx("div", {
            className: notFoundPageContainer,
            "data-testid": "not-found",
            children: user ? _jsxs(_Fragment, {
                children: [
                    _jsx("div", {
                        className: wrapper,
                        children: _jsx(NotFoundPattern, {})
                    }),
                    _jsx("p", {
                        className: wrapper,
                        children: t['404.hint']()
                    }),
                    _jsx("div", {
                        className: wrapper,
                        children: _jsx(Button, {
                            variant: "primary",
                            size: "extraLarge",
                            onClick: onBack,
                            className: largeButtonEffect,
                            children: t['404.back']()
                        })
                    }),
                    _jsxs("div", {
                        className: wrapper,
                        children: [
                            _jsx(Avatar, {
                                url: user.avatar ?? user.image,
                                name: user.label
                            }),
                            _jsx("span", {
                                style: {
                                    margin: '0 12px'
                                },
                                children: user.email
                            }),
                            _jsx(IconButton, {
                                onClick: onSignOut,
                                size: "20",
                                tooltip: t['404.signOut'](),
                                children: _jsx(SignOutIcon, {})
                            })
                        ]
                    })
                ]
            }) : signInComponent
        })
    });
};
export const NotFoundPage = ({ user, onBack, onSignOut })=>{
    const t = useI18n();
    return _jsx(AffineOtherPageLayout, {
        children: _jsxs("div", {
            className: notFoundPageContainer,
            "data-testid": "not-found",
            children: [
                _jsx("div", {
                    className: wrapper,
                    children: _jsx(NotFoundPattern, {})
                }),
                _jsx("p", {
                    className: wrapper,
                    children: t['404.hint']()
                }),
                _jsx("div", {
                    className: wrapper,
                    children: _jsx(Button, {
                        variant: "primary",
                        size: "extraLarge",
                        onClick: onBack,
                        className: largeButtonEffect,
                        children: t['404.back']()
                    })
                }),
                user ? _jsxs("div", {
                    className: wrapper,
                    children: [
                        _jsx(Avatar, {
                            url: user.avatar ?? user.image,
                            name: user.label
                        }),
                        _jsx("span", {
                            style: {
                                margin: '0 12px'
                            },
                            children: user.email
                        }),
                        _jsx(IconButton, {
                            onClick: onSignOut,
                            size: "20",
                            tooltip: t['404.signOut'](),
                            children: _jsx(SignOutIcon, {})
                        })
                    ]
                }) : null
            ]
        })
    });
};
