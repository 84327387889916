import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton, Menu } from '@affine/component';
import { SettingHeader } from '@affine/component/setting-components';
import { useWorkspaceInfo } from '@affine/core/components/hooks/use-workspace-info';
import { Trans, useI18n } from '@affine/i18n';
import { DeleteIcon, FilterIcon, MoreHorizontalIcon } from '@blocksuite/icons/rc';
import { FrameworkScope } from '@toeverything/infra';
import { createContext, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCurrentWorkspacePropertiesAdapter } from '../../../../../components/hooks/use-affine-adapter';
import { useWorkspace } from '../../../../../components/hooks/use-workspace';
import { nameToIcon, PagePropertiesCreatePropertyMenuItems, PagePropertiesMetaManager } from '../../../page-properties';
import { ConfirmDeletePropertyModal } from '../../../page-properties/confirm-delete-property-modal';
import { EditPropertyNameMenuItem, PropertyTypeMenuItem, renderMenuItemOptions } from '../../../page-properties/menu-items';
import * as styles from './styles.css';
const managerContext = createContext();
const usePagePropertiesMetaManager = ()=>{
    const adapter = useCurrentWorkspacePropertiesAdapter();
    const manager = useMemo(()=>{
        return new PagePropertiesMetaManager(adapter);
    }, [
        adapter
    ]);
    return manager;
};
const Divider = ()=>{
    return _jsx("div", {
        className: styles.divider
    });
};
const EditPropertyButton = ({ property })=>{
    const t = useI18n();
    const manager = useContext(managerContext);
    const [localPropertyMeta, setLocalPropertyMeta] = useState(()=>({
            ...property
        }));
    useEffect(()=>{
        setLocalPropertyMeta(property);
    }, [
        property
    ]);
    const handleToggleRequired = useCallback(()=>{
        manager.updatePropertyMeta(localPropertyMeta.id, {
            required: !localPropertyMeta.required
        });
    }, [
        manager,
        localPropertyMeta.id,
        localPropertyMeta.required
    ]);
    const handleDelete = useCallback(()=>{
        manager.removePropertyMeta(localPropertyMeta.id);
    }, [
        manager,
        localPropertyMeta.id
    ]);
    const [open, setOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleFinishEditing = useCallback(()=>{
        setOpen(false);
        setEditing(false);
        manager.updatePropertyMeta(localPropertyMeta.id, localPropertyMeta);
    }, [
        localPropertyMeta,
        manager
    ]);
    const defaultMenuItems = useMemo(()=>{
        const options = [];
        options.push({
            text: t['com.affine.settings.workspace.properties.set-as-required'](),
            onClick: handleToggleRequired,
            checked: localPropertyMeta.required
        });
        options.push('-');
        options.push({
            text: t['com.affine.settings.workspace.properties.edit-property'](),
            onClick: (e)=>{
                e.preventDefault();
                setEditing(true);
            }
        });
        options.push({
            text: t['com.affine.settings.workspace.properties.delete-property'](),
            onClick: ()=>setShowDeleteModal(true),
            type: 'danger',
            icon: _jsx(DeleteIcon, {})
        });
        return renderMenuItemOptions(options);
    }, [
        handleToggleRequired,
        localPropertyMeta.required,
        t
    ]);
    const handleNameBlur = useCallback((e)=>{
        manager.updatePropertyMeta(localPropertyMeta.id, {
            name: e
        });
    }, [
        manager,
        localPropertyMeta.id
    ]);
    const handleNameChange = useCallback((e)=>{
        setLocalPropertyMeta((prev)=>({
                ...prev,
                name: e
            }));
    }, []);
    const handleIconChange = useCallback((icon)=>{
        setLocalPropertyMeta((prev)=>({
                ...prev,
                icon
            }));
        manager.updatePropertyMeta(localPropertyMeta.id, {
            icon
        });
    }, [
        localPropertyMeta.id,
        manager
    ]);
    const editMenuItems = useMemo(()=>{
        const options = [];
        options.push(_jsx(EditPropertyNameMenuItem, {
            property: localPropertyMeta,
            onIconChange: handleIconChange,
            onNameBlur: handleNameBlur,
            onNameChange: handleNameChange
        }));
        options.push(_jsx(PropertyTypeMenuItem, {
            property: localPropertyMeta
        }));
        options.push('-');
        options.push({
            text: t['com.affine.settings.workspace.properties.delete-property'](),
            onClick: handleDelete,
            type: 'danger',
            icon: _jsx(DeleteIcon, {})
        });
        return renderMenuItemOptions(options);
    }, [
        handleDelete,
        handleIconChange,
        handleNameBlur,
        handleNameChange,
        localPropertyMeta,
        t
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(Menu, {
                rootOptions: {
                    open,
                    onOpenChange: handleFinishEditing
                },
                items: editing ? editMenuItems : defaultMenuItems,
                contentOptions: {
                    align: 'end',
                    sideOffset: 4
                },
                children: _jsx(IconButton, {
                    onClick: ()=>setOpen(true),
                    size: "20",
                    children: _jsx(MoreHorizontalIcon, {})
                })
            }),
            _jsx(ConfirmDeletePropertyModal, {
                onConfirm: ()=>{
                    setShowDeleteModal(false);
                    handleDelete();
                },
                onCancel: ()=>setShowDeleteModal(false),
                show: showDeleteModal,
                property: property
            })
        ]
    });
};
const CustomPropertyRow = ({ property, relatedPages })=>{
    const Icon = nameToIcon(property.icon, property.type);
    const required = property.required;
    const t = useI18n();
    return _jsxs("div", {
        className: styles.propertyRow,
        "data-property-id": property.id,
        "data-testid": "custom-property-row",
        children: [
            _jsx(Icon, {
                className: styles.propertyIcon
            }),
            _jsx("div", {
                "data-unnamed": !property.name,
                className: styles.propertyName,
                children: property.name || t['unnamed']()
            }),
            relatedPages.length > 0 ? _jsxs("div", {
                className: styles.propertyDocCount,
                children: [
                    "·",
                    ' ',
                    _jsxs(Trans, {
                        i18nKey: relatedPages.length > 1 ? 'com.affine.settings.workspace.properties.doc_others' : 'com.affine.settings.workspace.properties.doc',
                        count: relatedPages.length,
                        children: [
                            _jsx("span", {
                                children: {
                                    count: relatedPages.length
                                }
                            }),
                            " doc"
                        ]
                    })
                ]
            }) : null,
            _jsx("div", {
                className: styles.spacer
            }),
            required ? _jsx("div", {
                className: styles.propertyRequired,
                children: t['com.affine.page-properties.property.required']()
            }) : null,
            _jsx(EditPropertyButton, {
                property: property
            })
        ]
    });
};
const propertyFilterModes = [
    'all',
    'in-use',
    'unused'
];
const CustomPropertyRows = ({ properties, statistics })=>{
    return _jsx("div", {
        className: styles.metaList,
        children: properties.map((property)=>{
            const pages = [
                ...statistics.get(property.id) ?? []
            ];
            return _jsxs(Fragment, {
                children: [
                    _jsx(CustomPropertyRow, {
                        property: property,
                        relatedPages: pages
                    }),
                    _jsx(Divider, {})
                ]
            }, property.id);
        })
    });
};
const CustomPropertyRowsList = ({ filterMode })=>{
    const manager = useContext(managerContext);
    const properties = manager.getOrderedPropertiesSchema();
    const statistics = manager.getPropertyStatistics();
    const t = useI18n();
    if (filterMode !== 'all') {
        const filtered = properties.filter((property)=>{
            const count = statistics.get(property.id)?.size ?? 0;
            return filterMode === 'in-use' ? count > 0 : count === 0;
        });
        return _jsx(CustomPropertyRows, {
            properties: filtered,
            statistics: statistics
        });
    } else {
        const partition = Object.groupBy(properties, (p)=>p.required ? 'required' : p.readonly ? 'readonly' : 'optional');
        return _jsxs(_Fragment, {
            children: [
                partition.required && partition.required.length > 0 ? _jsxs(_Fragment, {
                    children: [
                        _jsx("div", {
                            className: styles.subListHeader,
                            children: t['com.affine.settings.workspace.properties.required-properties']()
                        }),
                        _jsx(CustomPropertyRows, {
                            properties: partition.required,
                            statistics: statistics
                        })
                    ]
                }) : null,
                partition.optional && partition.optional.length > 0 ? _jsxs(_Fragment, {
                    children: [
                        _jsx("div", {
                            className: styles.subListHeader,
                            children: t['com.affine.settings.workspace.properties.general-properties']()
                        }),
                        _jsx(CustomPropertyRows, {
                            properties: partition.optional,
                            statistics: statistics
                        })
                    ]
                }) : null,
                partition.readonly && partition.readonly.length > 0 ? _jsxs(_Fragment, {
                    children: [
                        _jsx("div", {
                            className: styles.subListHeader,
                            children: t['com.affine.settings.workspace.properties.readonly-properties']()
                        }),
                        _jsx(CustomPropertyRows, {
                            properties: partition.readonly,
                            statistics: statistics
                        })
                    ]
                }) : null
            ]
        });
    }
};
const WorkspaceSettingPropertiesMain = ()=>{
    const t = useI18n();
    const manager = useContext(managerContext);
    const [filterMode, setFilterMode] = useState('all');
    const properties = manager.getOrderedPropertiesSchema();
    const filterMenuItems = useMemo(()=>{
        const options = [
            'all',
            '-',
            'in-use',
            'unused'
        ].map((mode)=>{
            return mode === '-' ? '-' : {
                text: t[`com.affine.settings.workspace.properties.${mode}`](),
                onClick: ()=>setFilterMode(mode),
                checked: filterMode === mode
            };
        });
        return renderMenuItemOptions(options);
    }, [
        filterMode,
        t
    ]);
    const onPropertyCreated = useCallback((_e, id)=>{
        setTimeout(()=>{
            const newRow = document.querySelector(`[data-testid="custom-property-row"][data-property-id="${id}"]`);
            if (newRow) {
                newRow.scrollIntoView({
                    behavior: 'smooth'
                });
                newRow.dataset.highlight = '';
                setTimeout(()=>{
                    delete newRow.dataset.highlight;
                }, 3000);
            }
        });
    }, []);
    return _jsxs("div", {
        className: styles.main,
        children: [
            _jsxs("div", {
                className: styles.listHeader,
                children: [
                    properties.length > 0 ? _jsx(Menu, {
                        items: filterMenuItems,
                        children: _jsx(Button, {
                            prefix: _jsx(FilterIcon, {}),
                            children: filterMode === 'all' ? t['com.affine.filter']() : t[`com.affine.settings.workspace.properties.${filterMode}`]()
                        })
                    }) : null,
                    _jsx(Menu, {
                        items: _jsx(PagePropertiesCreatePropertyMenuItems, {
                            onCreated: onPropertyCreated,
                            metaManager: manager
                        }),
                        children: _jsx(Button, {
                            variant: "primary",
                            children: t['com.affine.settings.workspace.properties.add_property']()
                        })
                    })
                ]
            }),
            _jsx(CustomPropertyRowsList, {
                filterMode: filterMode
            })
        ]
    });
};
const WorkspaceSettingPropertiesInner = ()=>{
    const manager = usePagePropertiesMetaManager();
    return _jsx(managerContext.Provider, {
        value: manager,
        children: _jsx(WorkspaceSettingPropertiesMain, {})
    });
};
export const WorkspaceSettingProperties = ({ workspaceMetadata })=>{
    const t = useI18n();
    const workspace = useWorkspace(workspaceMetadata);
    const workspaceInfo = useWorkspaceInfo(workspaceMetadata);
    const title = workspaceInfo?.name || 'untitled';
    if (workspace === null) {
        return null;
    }
    return _jsxs(FrameworkScope, {
        scope: workspace.scope,
        children: [
            _jsx(SettingHeader, {
                title: t['com.affine.settings.workspace.properties.header.title'](),
                subtitle: _jsxs(Trans, {
                    values: {
                        name: title
                    },
                    i18nKey: "com.affine.settings.workspace.properties.header.subtitle",
                    children: [
                        "Manage workspace ",
                        _jsx("strong", {
                            children: "name"
                        }),
                        " properties"
                    ]
                })
            }),
            _jsx(WorkspaceSettingPropertiesInner, {})
        ]
    });
};
