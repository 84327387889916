export class DisposableGroup {
    get disposed() {
        return this._disposed;
    }
    add(d) {
        if (typeof d === 'function') {
            if (this._disposed) d();
            else this._disposables.push({
                dispose: d
            });
        } else {
            if (this._disposed) d.dispose();
            else this._disposables.push(d);
        }
    }
    addFromEvent(target, type, handler, eventOptions) {
        this.add({
            dispose: ()=>{
                target.removeEventListener(type, handler, eventOptions);
            }
        });
        target.addEventListener(type, handler, eventOptions);
    }
    dispose() {
        disposeAll(this._disposables);
        this._disposables = [];
        this._disposed = true;
    }
    constructor(){
        this._disposables = [];
        this._disposed = false;
    }
}
export function flattenDisposables(disposables) {
    return {
        dispose: ()=>disposeAll(disposables)
    };
}
function disposeAll(disposables) {
    for (const disposable of disposables){
        try {
            disposable.dispose();
        } catch (err) {
            console.error(err);
        }
    }
}
