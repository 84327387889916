export class NoopDocSource {
    pull(_docId, _data) {
        return null;
    }
    push(_docId, _data) {}
    subscribe(_cb, _disconnect) {
        return ()=>{};
    }
    constructor(){
        this.name = 'noop';
    }
}
