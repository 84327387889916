import { jsx as _jsx } from "react/jsx-runtime";
import { track } from '@affine/track';
import { ContactWithUsIcon, NewIcon } from '@blocksuite/icons/rc';
import { openSettingModalAtom } from '../components/atoms';
import { popupWindow } from '../utils';
import { registerAffineCommand } from './registry';
export function registerAffineHelpCommands({ t, store }) {
    const unsubs = [];
    unsubs.push(registerAffineCommand({
        id: 'affine:help-whats-new',
        category: 'affine:help',
        icon: _jsx(NewIcon, {}),
        label: t['com.affine.cmdk.affine.whats-new'](),
        run () {
            track.$.cmdk.help.openChangelog();
            popupWindow(BUILD_CONFIG.changelogUrl);
        }
    }));
    unsubs.push(registerAffineCommand({
        id: 'affine:help-contact-us',
        category: 'affine:help',
        icon: _jsx(ContactWithUsIcon, {}),
        label: t['com.affine.cmdk.affine.contact-us'](),
        run () {
            track.$.cmdk.help.contactUs();
            store.set(openSettingModalAtom, {
                open: true,
                activeTab: 'about',
                workspaceMetadata: null
            });
        }
    }));
    return ()=>{
        unsubs.forEach((unsub)=>unsub());
    };
}
