import { DEFAULT_VARIANT, ROOT_SCOPE, SUB_COMPONENTS } from './consts';
import { DuplicateDefinitionError } from './error';
import { parseIdentifier } from './identifier';
import { BasicFrameworkProvider } from './provider';
import { stringifyScope } from './scope';
export class Framework {
    static get EMPTY() {
        return new Framework();
    }
    get componentCount() {
        let count = 0;
        for (const [, identifiers] of this.components){
            for (const [, variants] of identifiers){
                count += variants.size;
            }
        }
        return count;
    }
    get service() {
        return new FrameworkEditor(this).service;
    }
    get impl() {
        return new FrameworkEditor(this).impl;
    }
    get entity() {
        return new FrameworkEditor(this).entity;
    }
    get scope() {
        return new FrameworkEditor(this).scope;
    }
    get override() {
        return new FrameworkEditor(this).override;
    }
    get store() {
        return new FrameworkEditor(this).store;
    }
    addValue(identifier, value, { scope, override } = {}) {
        this.addFactory(parseIdentifier(identifier), ()=>value, {
            scope,
            override
        });
    }
    addFactory(identifier, factory, { scope, override } = {}) {
        const normalizedScope = stringifyScope(scope ?? ROOT_SCOPE);
        const normalizedIdentifier = parseIdentifier(identifier);
        const normalizedVariant = normalizedIdentifier.variant ?? DEFAULT_VARIANT;
        const services = this.components.get(normalizedScope) ?? new Map();
        const variants = services.get(normalizedIdentifier.identifierName) ?? new Map();
        if (variants.has(normalizedVariant) && !override) {
            throw new DuplicateDefinitionError(normalizedIdentifier);
        }
        variants.set(normalizedVariant, factory);
        services.set(normalizedIdentifier.identifierName, variants);
        this.components.set(normalizedScope, services);
    }
    remove(identifier, scope = ROOT_SCOPE) {
        const normalizedScope = stringifyScope(scope);
        const normalizedIdentifier = parseIdentifier(identifier);
        const normalizedVariant = normalizedIdentifier.variant ?? DEFAULT_VARIANT;
        const services = this.components.get(normalizedScope);
        if (!services) {
            return;
        }
        const variants = services.get(normalizedIdentifier.identifierName);
        if (!variants) {
            return;
        }
        variants.delete(normalizedVariant);
    }
    provider(scope = ROOT_SCOPE, parent = null) {
        return new BasicFrameworkProvider(this, scope, parent);
    }
    getFactory(identifier, scope = ROOT_SCOPE) {
        return this.components.get(stringifyScope(scope))?.get(identifier.identifierName)?.get(identifier.variant ?? DEFAULT_VARIANT);
    }
    getFactoryAll(identifier, scope = ROOT_SCOPE) {
        return new Map(this.components.get(stringifyScope(scope))?.get(identifier.identifierName));
    }
    clone() {
        const di = new Framework();
        for (const [scope, identifiers] of this.components){
            const s = new Map();
            for (const [identifier, variants] of identifiers){
                s.set(identifier, new Map(variants));
            }
            di.components.set(scope, s);
        }
        return di;
    }
    constructor(){
        this.components = new Map();
    }
}
class FrameworkEditor {
    constructor(collection){
        this.collection = collection;
        this.currentScopeStack = ROOT_SCOPE;
        this.service = (service, ...[arg2])=>{
            if (arg2 instanceof Function) {
                this.collection.addFactory(service, arg2, {
                    scope: this.currentScopeStack
                });
            } else if (arg2 instanceof Array || arg2 === undefined) {
                this.collection.addFactory(service, dependenciesToFactory(service, arg2), {
                    scope: this.currentScopeStack
                });
            } else {
                this.collection.addValue(service, arg2, {
                    scope: this.currentScopeStack
                });
            }
            if (SUB_COMPONENTS in service) {
                const subComponents = service[SUB_COMPONENTS];
                for (const { identifier, factory } of subComponents){
                    this.collection.addFactory(identifier, factory, {
                        scope: this.currentScopeStack
                    });
                }
            }
            return this;
        };
        this.store = (store, ...[arg2])=>{
            if (arg2 instanceof Function) {
                this.collection.addFactory(store, arg2, {
                    scope: this.currentScopeStack
                });
            } else if (arg2 instanceof Array || arg2 === undefined) {
                this.collection.addFactory(store, dependenciesToFactory(store, arg2), {
                    scope: this.currentScopeStack
                });
            } else {
                this.collection.addValue(store, arg2, {
                    scope: this.currentScopeStack
                });
            }
            if (SUB_COMPONENTS in store) {
                const subComponents = store[SUB_COMPONENTS];
                for (const { identifier, factory } of subComponents){
                    this.collection.addFactory(identifier, factory, {
                        scope: this.currentScopeStack
                    });
                }
            }
            return this;
        };
        this.entity = (entity, ...[arg2])=>{
            if (arg2 instanceof Function) {
                this.collection.addFactory(entity, arg2, {
                    scope: this.currentScopeStack
                });
            } else {
                this.collection.addFactory(entity, dependenciesToFactory(entity, arg2), {
                    scope: this.currentScopeStack
                });
            }
            return this;
        };
        this.impl = (identifier, arg2, ...[arg3])=>{
            if (arg2 instanceof Function) {
                this.collection.addFactory(identifier, dependenciesToFactory(arg2, arg3), {
                    scope: this.currentScopeStack
                });
            } else {
                this.collection.addValue(identifier, arg2, {
                    scope: this.currentScopeStack
                });
            }
            return this;
        };
        this.override = (identifier, arg2, ...[arg3])=>{
            if (arg2 === null) {
                this.collection.remove(parseIdentifier(identifier), this.currentScopeStack);
                return this;
            } else if (arg2 instanceof Function) {
                this.collection.addFactory(identifier, dependenciesToFactory(arg2, arg3), {
                    scope: this.currentScopeStack,
                    override: true
                });
            } else {
                this.collection.addValue(identifier, arg2, {
                    scope: this.currentScopeStack,
                    override: true
                });
            }
            return this;
        };
        this.scope = (scope)=>{
            this.currentScopeStack = [
                ...this.currentScopeStack,
                parseIdentifier(scope).identifierName
            ];
            this.collection.addFactory(scope, dependenciesToFactory(scope, []), {
                scope: this.currentScopeStack,
                override: true
            });
            return this;
        };
    }
}
function dependenciesToFactory(cls, deps = []) {
    return (provider)=>{
        const args = [];
        for (const dep of deps){
            let isAll;
            let identifier;
            if (Array.isArray(dep)) {
                if (dep.length !== 1) {
                    throw new Error('Invalid dependency');
                }
                isAll = true;
                identifier = dep[0];
            } else {
                isAll = false;
                identifier = dep;
            }
            if (isAll) {
                args.push(Array.from(provider.getAll(identifier).values()));
            } else {
                args.push(provider.get(identifier));
            }
        }
        if (isConstructor(cls)) {
            return new cls(...args, provider);
        } else {
            return cls(...args, provider);
        }
    };
}
function isConstructor(cls) {
    try {
        Reflect.construct(function() {}, [], cls);
        return true;
    } catch  {
        return false;
    }
}
