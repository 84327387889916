import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { toast } from '@affine/component';
import { Button, IconButton } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { assertExists } from '@blocksuite/affine/global/utils';
import { ArrowLeftSmallIcon, ArrowRightSmallIcon, CloseIcon, CopyIcon, DeleteIcon, DownloadIcon, MinusIcon, PlusIcon, ViewBarIcon } from '@blocksuite/icons/rc';
import { useService } from '@toeverything/infra';
import clsx from 'clsx';
import { fileTypeFromBuffer } from 'file-type';
import { useErrorBoundary } from 'foxact/use-error-boundary';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import useSWR from 'swr';
import { PeekViewService } from '../../services/peek-view';
import { useEditor } from '../utils';
import { useZoomControls } from './hooks/use-zoom';
import * as styles from './index.css';
const filterImageBlock = (block)=>{
    return block.flavour === 'affine:image';
};
async function imageUrlToBlob(url) {
    const buffer = await fetch(url).then((response)=>{
        return response.arrayBuffer();
    });
    if (!buffer) {
        console.warn('Could not get blob');
        return;
    }
    try {
        const type = await fileTypeFromBuffer(buffer);
        if (!type) {
            return;
        }
        const blob = new Blob([
            buffer
        ], {
            type: type.mime
        });
        return blob;
    } catch (error) {
        console.error('Error converting image to blob', error);
    }
    return;
}
async function copyImageToClipboard(url) {
    const blob = await imageUrlToBlob(url);
    if (!blob) {
        return;
    }
    try {
        await navigator.clipboard.write([
            new ClipboardItem({
                [blob.type]: blob
            })
        ]);
        console.log('Image copied to clipboard');
        toast('Copied to clipboard.');
    } catch (error) {
        console.error('Error copying image to clipboard', error);
    }
}
async function saveBufferToFile(url, filename) {
    const blob = await imageUrlToBlob(url);
    if (!blob) {
        return;
    }
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.append(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(blobUrl);
}
const ImagePreviewModalImpl = ({ docId, blockId, onBlockIdChange, onClose })=>{
    const { doc, workspace } = useEditor(docId);
    const blocksuiteDoc = doc?.blockSuiteDoc;
    const docCollection = workspace.docCollection;
    const blockModel = useMemo(()=>{
        const block = blocksuiteDoc?.getBlock(blockId);
        if (!block) {
            return null;
        }
        return block.model;
    }, [
        blockId,
        blocksuiteDoc
    ]);
    const caption = useMemo(()=>{
        return blockModel?.caption ?? '';
    }, [
        blockModel?.caption
    ]);
    const [blocks, setBlocks] = useState([]);
    const [cursor, setCursor] = useState(0);
    const zoomRef = useRef(null);
    const imageRef = useRef(null);
    const { isZoomedBigger, handleDrag, handleDragStart, handleDragEnd, resetZoom, zoomIn, zoomOut, resetScale, currentScale } = useZoomControls({
        zoomRef,
        imageRef
    });
    const goto = useCallback((index)=>{
        const page = docCollection.getDoc(docId);
        assertExists(page);
        const block = blocks[index];
        if (!block) return;
        setCursor(index);
        onBlockIdChange(block.id);
        resetZoom();
    }, [
        docCollection,
        docId,
        blocks,
        onBlockIdChange,
        resetZoom
    ]);
    const deleteHandler = useCallback((index)=>{
        if (!blocksuiteDoc) {
            return;
        }
        let block = blocks[index];
        if (!block) return;
        const newBlocks = blocks.toSpliced(index, 1);
        setBlocks(newBlocks);
        blocksuiteDoc.deleteBlock(block);
        block = newBlocks[index];
        if (!block) {
            index -= 1;
            block = newBlocks[index];
            if (!block) {
                onClose();
                return;
            }
            setCursor(index);
        }
        onBlockIdChange(block.id);
        resetZoom();
    }, [
        blocksuiteDoc,
        blocks,
        onBlockIdChange,
        resetZoom,
        onClose
    ]);
    const downloadHandler = useAsyncCallback(async ()=>{
        const url = imageRef.current?.src;
        if (url) {
            await saveBufferToFile(url, caption || blockModel?.id || 'image');
        }
    }, [
        caption,
        blockModel?.id
    ]);
    const copyHandler = useAsyncCallback(async ()=>{
        const url = imageRef.current?.src;
        if (url) {
            await copyImageToClipboard(url);
        }
    }, []);
    useEffect(()=>{
        if (!blockModel || !blocksuiteDoc) {
            return;
        }
        const prevs = blocksuiteDoc.getPrevs(blockModel).filter(filterImageBlock);
        const nexts = blocksuiteDoc.getNexts(blockModel).filter(filterImageBlock);
        const blocks = [
            ...prevs,
            blockModel,
            ...nexts
        ];
        setBlocks(blocks);
        setCursor(blocks.length ? prevs.length : 0);
    }, [
        setBlocks,
        blockModel,
        blocksuiteDoc
    ]);
    const { data, error } = useSWR([
        'workspace',
        'image',
        docId,
        blockId
    ], {
        fetcher: ([_, __, pageId, blockId])=>{
            const page = docCollection.getDoc(pageId);
            assertExists(page);
            const block = page.getBlock(blockId);
            if (!block) {
                return null;
            }
            const blockModel = block.model;
            return docCollection.blobSync.get(blockModel.sourceId);
        },
        suspense: true
    });
    useEffect(()=>{
        const handleKeyUp = (event)=>{
            if (!blocksuiteDoc || !blockModel) {
                return;
            }
            if (event.key === 'ArrowLeft') {
                const prevBlock = blocksuiteDoc.getPrevs(blockModel).findLast((block)=>block.flavour === 'affine:image');
                if (prevBlock) {
                    onBlockIdChange(prevBlock.id);
                }
            } else if (event.key === 'ArrowRight') {
                const nextBlock = blocksuiteDoc.getNexts(blockModel).find((block)=>block.flavour === 'affine:image');
                if (nextBlock) {
                    onBlockIdChange(nextBlock.id);
                }
            } else {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
        };
        const onCopyEvent = (event)=>{
            event.preventDefault();
            event.stopPropagation();
            copyHandler();
        };
        document.addEventListener('keyup', handleKeyUp);
        document.addEventListener('copy', onCopyEvent);
        return ()=>{
            document.removeEventListener('keyup', handleKeyUp);
            document.removeEventListener('copy', onCopyEvent);
        };
    }, [
        blockModel,
        blocksuiteDoc,
        copyHandler,
        onBlockIdChange
    ]);
    useErrorBoundary(error);
    const [prevData, setPrevData] = useState(()=>data);
    const [url, setUrl] = useState(null);
    if (data === null) {
        return null;
    } else if (prevData !== data) {
        if (url) {
            URL.revokeObjectURL(url);
        }
        setUrl(URL.createObjectURL(data));
        setPrevData(data);
    } else if (!url) {
        setUrl(URL.createObjectURL(data));
    }
    if (!url) {
        return null;
    }
    return _jsxs("div", {
        "data-testid": "image-preview-modal",
        className: styles.imagePreviewModalStyle,
        children: [
            _jsx("div", {
                className: styles.imagePreviewTrap,
                onClick: onClose
            }),
            _jsx("div", {
                className: styles.imagePreviewModalContainerStyle,
                children: _jsx("div", {
                    className: clsx('zoom-area', {
                        'zoomed-bigger': isZoomedBigger
                    }),
                    ref: zoomRef,
                    children: _jsxs("div", {
                        className: styles.imagePreviewModalCenterStyle,
                        children: [
                            _jsx("img", {
                                "data-blob-id": blockId,
                                "data-testid": "image-content",
                                src: url,
                                alt: caption,
                                tabIndex: 0,
                                ref: imageRef,
                                draggable: isZoomedBigger,
                                onMouseDown: handleDragStart,
                                onMouseMove: handleDrag,
                                onMouseUp: handleDragEnd,
                                onLoad: resetZoom
                            }),
                            isZoomedBigger ? null : _jsx("p", {
                                "data-testid": "image-caption-zoomedout",
                                className: styles.imagePreviewModalCaptionStyle,
                                children: caption
                            })
                        ]
                    })
                })
            }),
            _jsxs("div", {
                className: styles.imageBottomContainerStyle,
                children: [
                    isZoomedBigger && caption !== '' ? _jsx("p", {
                        "data-testid": 'image-caption-zoomedin',
                        className: styles.captionStyle,
                        children: caption
                    }) : null,
                    _jsxs("div", {
                        className: styles.imagePreviewActionBarStyle,
                        children: [
                            _jsx(IconButton, {
                                "data-testid": "previous-image-button",
                                tooltip: "Previous",
                                icon: _jsx(ArrowLeftSmallIcon, {}),
                                disabled: cursor < 1,
                                onClick: ()=>goto(cursor - 1)
                            }),
                            _jsx("div", {
                                className: styles.cursorStyle,
                                children: `${blocks.length ? cursor + 1 : 0}/${blocks.length}`
                            }),
                            _jsx(IconButton, {
                                "data-testid": "next-image-button",
                                tooltip: "Next",
                                icon: _jsx(ArrowRightSmallIcon, {}),
                                disabled: cursor + 1 === blocks.length,
                                onClick: ()=>goto(cursor + 1)
                            }),
                            _jsx("div", {
                                className: styles.dividerStyle
                            }),
                            _jsx(IconButton, {
                                "data-testid": "fit-to-screen-button",
                                tooltip: "Fit to screen",
                                icon: _jsx(ViewBarIcon, {}),
                                onClick: ()=>resetZoom()
                            }),
                            _jsx(IconButton, {
                                "data-testid": "zoom-out-button",
                                tooltip: "Zoom out",
                                icon: _jsx(MinusIcon, {}),
                                onClick: zoomOut
                            }),
                            _jsx(Button, {
                                "data-testid": "reset-scale-button",
                                tooltip: "Reset scale",
                                onClick: resetScale,
                                variant: "plain",
                                children: `${(currentScale * 100).toFixed(0)}%`
                            }),
                            _jsx(IconButton, {
                                "data-testid": "zoom-in-button",
                                tooltip: "Zoom in",
                                icon: _jsx(PlusIcon, {}),
                                onClick: zoomIn
                            }),
                            _jsx("div", {
                                className: styles.dividerStyle
                            }),
                            _jsx(IconButton, {
                                "data-testid": "download-button",
                                tooltip: "Download",
                                icon: _jsx(DownloadIcon, {}),
                                onClick: downloadHandler
                            }),
                            _jsx(IconButton, {
                                "data-testid": "copy-to-clipboard-button",
                                tooltip: "Copy to clipboard",
                                icon: _jsx(CopyIcon, {}),
                                onClick: copyHandler
                            }),
                            blockModel && !blockModel.doc.readonly && _jsxs(_Fragment, {
                                children: [
                                    _jsx("div", {
                                        className: styles.dividerStyle
                                    }),
                                    _jsx(IconButton, {
                                        "data-testid": "delete-button",
                                        tooltip: "Delete",
                                        icon: _jsx(DeleteIcon, {}),
                                        disabled: blocks.length === 0,
                                        onClick: ()=>deleteHandler(cursor),
                                        variant: "danger"
                                    })
                                ]
                            })
                        ]
                    })
                ]
            })
        ]
    });
};
const ErrorLogger = (props)=>{
    console.error('image preview modal error', props.error);
    return null;
};
export const ImagePreviewErrorBoundary = (props)=>{
    return _jsx(ErrorBoundary, {
        fallbackRender: ErrorLogger,
        children: props.children
    });
};
export const ImagePreviewPeekView = (props)=>{
    const [blockId, setBlockId] = useState(props.blockId);
    const peekView = useService(PeekViewService).peekView;
    const onClose = useCallback(()=>peekView.close(), [
        peekView
    ]);
    const buttonRef = useRef(null);
    useEffect(()=>{
        setBlockId(props.blockId);
    }, [
        props.blockId
    ]);
    return _jsx(ImagePreviewErrorBoundary, {
        children: _jsxs(Suspense, {
            children: [
                blockId ? _jsx(ImagePreviewModalImpl, {
                    ...props,
                    onClose: onClose,
                    blockId: blockId,
                    onBlockIdChange: setBlockId
                }) : null,
                _jsx("button", {
                    ref: buttonRef,
                    "data-testid": "image-preview-close-button",
                    onClick: onClose,
                    className: styles.imagePreviewModalCloseButtonStyle,
                    children: _jsx(CloseIcon, {})
                })
            ]
        })
    });
};
