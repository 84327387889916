import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, notify } from '@affine/component';
import { useI18n } from '@affine/i18n';
import clsx from 'clsx';
import { useCallback, useRef } from 'react';
import { actionButton, cancelButton, confirmButton, notifyFooter, notifyHeader } from './notify.css';
const SubscriptionChangedNotifyFooter = ({ to, okText, cancelText, onCancel, onConfirm })=>{
    return _jsxs("div", {
        className: notifyFooter,
        children: [
            _jsx(Button, {
                className: clsx(actionButton, cancelButton),
                size: 'default',
                onClick: onCancel,
                variant: "plain",
                children: cancelText
            }),
            _jsx("a", {
                href: to,
                target: "_blank",
                rel: "noreferrer",
                children: _jsx(Button, {
                    onClick: onConfirm,
                    className: clsx(actionButton, confirmButton),
                    variant: "plain",
                    children: okText
                })
            })
        ]
    });
};
export const useUpgradeNotify = ()=>{
    const t = useI18n();
    const prevNotifyIdRef = useRef(null);
    return useCallback((link)=>{
        prevNotifyIdRef.current && notify.dismiss(prevNotifyIdRef.current);
        const id = notify({
            title: _jsx("span", {
                className: notifyHeader,
                children: t['com.affine.payment.upgrade-success-notify.title']()
            }),
            message: t['com.affine.payment.upgrade-success-notify.content'](),
            alignMessage: 'title',
            icon: null,
            footer: _jsx(SubscriptionChangedNotifyFooter, {
                to: link,
                okText: BUILD_CONFIG.isElectron ? t['com.affine.payment.upgrade-success-notify.ok-client']() : t['com.affine.payment.upgrade-success-notify.ok-web'](),
                cancelText: t['com.affine.payment.upgrade-success-notify.later'](),
                onCancel: ()=>notify.dismiss(id),
                onConfirm: ()=>notify.dismiss(id)
            })
        }, {
            duration: 24 * 60 * 60 * 1000
        });
        prevNotifyIdRef.current = id;
    }, [
        t
    ]);
};
export const useDowngradeNotify = ()=>{
    const t = useI18n();
    const prevNotifyIdRef = useRef(null);
    return useCallback((link)=>{
        prevNotifyIdRef.current && notify.dismiss(prevNotifyIdRef.current);
        const id = notify({
            title: _jsx("span", {
                className: notifyHeader,
                children: t['com.affine.payment.downgraded-notify.title']()
            }),
            message: t['com.affine.payment.downgraded-notify.content'](),
            alignMessage: 'title',
            icon: null,
            footer: _jsx(SubscriptionChangedNotifyFooter, {
                to: link,
                okText: BUILD_CONFIG.isElectron ? t['com.affine.payment.downgraded-notify.ok-client']() : t['com.affine.payment.downgraded-notify.ok-web'](),
                cancelText: t['com.affine.payment.downgraded-notify.later'](),
                onCancel: ()=>notify.dismiss(id),
                onConfirm: ()=>notify.dismiss(id)
            })
        }, {
            duration: 24 * 60 * 60 * 1000
        });
        prevNotifyIdRef.current = id;
    }, [
        t
    ]);
};
