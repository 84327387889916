function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _initClass;
import { pick } from 'lodash-es';
import { Map as YMap } from 'yjs';
import { validators } from '../../validators';
import { HookAdapter } from '../mixins';
let _YjsTableAdapter;
_dec = HookAdapter();
class YjsTableAdapter {
    static{
        ({ c: [_YjsTableAdapter, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec
        ]));
    }
    constructor(tableName, doc){
        this.tableName = tableName;
        this.doc = doc;
        this.deleteFlagKey = '$$DELETED';
        this.keyField = 'key';
        this.fields = [];
        this.origin = 'YjsTableAdapter';
    }
    setup(opts) {
        this.keyField = opts.keyField;
        this.fields = Object.keys(opts.schema);
    }
    dispose() {
        this.doc.destroy();
    }
    insert(query) {
        const { data, select } = query;
        validators.validateYjsEntityData(this.tableName, data);
        const key = data[this.keyField];
        const record = this.doc.getMap(key.toString());
        this.doc.transact(()=>{
            for(const key in data){
                record.set(key, data[key]);
            }
            record.delete(this.deleteFlagKey);
        }, this.origin);
        return this.value(record, select);
    }
    update(query) {
        const { data, select, where } = query;
        validators.validateYjsEntityData(this.tableName, data);
        const results = [];
        this.doc.transact(()=>{
            for (const record of this.iterate(where)){
                results.push(this.value(record, select));
                for(const key in data){
                    this.setField(record, key, data[key]);
                }
            }
        }, this.origin);
        return results;
    }
    find(query) {
        const { where, select } = query;
        const records = [];
        for (const record of this.iterate(where)){
            records.push(this.value(record, select));
        }
        return records;
    }
    observe(query) {
        const { where, select, callback } = query;
        let listeningOnAll = false;
        const results = new Map();
        if (!where) {
            listeningOnAll = true;
        }
        for (const record of this.iterate(where)){
            results.set(this.keyof(record), this.value(record, select));
        }
        callback(Array.from(results.values()));
        const ob = (tx)=>{
            let hasChanged = false;
            for (const [ty] of tx.changed){
                const record = ty;
                const key = this.keyof(record);
                const isMatch = (listeningOnAll || where && this.match(record, where)) && !this.isDeleted(record);
                const prevMatch = results.get(key);
                const isPrevMatched = results.has(key);
                if (isMatch && isPrevMatched) {
                    const newValue = this.value(record, select);
                    if (prevMatch !== newValue) {
                        results.set(key, newValue);
                        hasChanged = true;
                    }
                } else if (isMatch && !isPrevMatched) {
                    results.set(this.keyof(record), this.value(record, select));
                    hasChanged = true;
                } else if (!isMatch && isPrevMatched) {
                    results.delete(key);
                    hasChanged = true;
                }
            }
            if (hasChanged) {
                callback(Array.from(results.values()));
            }
        };
        this.doc.on('afterTransaction', ob);
        return ()=>{
            this.doc.off('afterTransaction', ob);
        };
    }
    delete(query) {
        const { where } = query;
        this.doc.transact(()=>{
            for (const record of this.iterate(where)){
                this.deleteTy(record);
            }
        }, this.origin);
    }
    toObject(ty) {
        return YMap.prototype.toJSON.call(ty);
    }
    recordByKey(key) {
        if (this.doc.share.has(key)) {
            return this.doc.getMap(key);
        }
        return null;
    }
    *iterate(where) {
        if (!where) {
            for (const map of this.doc.share.values()){
                if (!this.isDeleted(map)) {
                    yield map;
                }
            }
        } else if ('byKey' in where) {
            const record = this.recordByKey(where.byKey.toString());
            if (record) {
                yield record;
            }
        } else if (Array.isArray(where)) {
            for (const map of this.doc.share.values()){
                if (this.match(map, where)) {
                    yield map;
                }
            }
        }
    }
    value(record, select = '*') {
        if (this.isDeleted(record) || this.isEmpty(record)) {
            return null;
        }
        let selectedFields;
        if (select === 'key') {
            return this.keyof(record);
        } else if (select === '*') {
            return this.toObject(record);
        } else {
            selectedFields = select;
        }
        return pick(this.toObject(record), selectedFields);
    }
    match(record, where) {
        return !this.isDeleted(record) && (Array.isArray(where) ? where.length === 0 ? false : where.every((c)=>this.field(record, c.field) === c.value) : where.byKey === this.keyof(record));
    }
    isDeleted(record) {
        return this.field(record, this.deleteFlagKey) === true || this.isEmpty(record);
    }
    keyof(record) {
        return this.field(record, this.keyField);
    }
    field(ty, field) {
        return YMap.prototype.get.call(ty, field);
    }
    setField(ty, field, value) {
        YMap.prototype.set.call(ty, field, value);
    }
    isEmpty(ty) {
        return ty._map.size === 0;
    }
    deleteTy(ty) {
        this.fields.forEach((field)=>{
            if (field !== this.keyField) {
                YMap.prototype.delete.call(ty, field);
            }
        });
        YMap.prototype.set.call(ty, this.deleteFlagKey, true);
    }
    static{
        _initClass();
    }
}
export { _YjsTableAdapter as YjsTableAdapter };
