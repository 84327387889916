import { focusTextModel } from '@blocksuite/affine-components/rich-text';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
export const dedentParagraphCommand = (ctx, next)=>{
    let { blockId, inlineIndex } = ctx;
    const { std } = ctx;
    const { selection, doc } = std;
    const text = selection.find('text');
    if (!blockId) {
        if (!text || text.to && text.from.blockId !== text.to.blockId) {
            return;
        }
        blockId = text.from.blockId;
        inlineIndex = text.from.index;
    }
    if (blockId == null || inlineIndex == null) {
        return;
    }
    const model = std.doc.getBlock(blockId)?.model;
    if (!model || !matchFlavours(model, [
        'affine:paragraph'
    ])) {
        console.error(`block ${blockId} is not a paragraph block`);
        return;
    }
    const parent = doc.getParent(model);
    if (doc.readonly || !parent || parent.role !== 'content') {
        return;
    }
    const grandParent = doc.getParent(parent);
    if (!grandParent) return;
    doc.captureSync();
    const nextSiblings = doc.getNexts(model);
    doc.moveBlocks(nextSiblings, model);
    doc.moveBlocks([
        model
    ], grandParent, parent, false);
    focusTextModel(std, model.id, inlineIndex);
    return next();
};
