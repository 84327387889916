import { styled, textEllipsis } from '../../styles';
export const StyledTable = styled('table')(({ showBorder })=>{
    return {
        fontSize: 'var(--affine-font-base)',
        color: 'var(--affine-text-primary-color)',
        tableLayout: 'fixed',
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: '0',
        ...typeof showBorder === 'boolean' ? {
            thead: {
                '::after': {
                    display: 'block',
                    position: 'absolute',
                    content: '""',
                    width: '100%',
                    height: '1px',
                    left: 0,
                    background: 'var(--affine-border-color)',
                    transition: 'opacity .15s',
                    opacity: showBorder ? 1 : 0
                }
            }
        } : {}
    };
});
export const StyledTableBody = styled('tbody')(()=>{
    return {
        fontWeight: 400
    };
});
export const StyledTableCell = styled('td')(({ align = 'left', ellipsis = false, proportion, active = false, onClick })=>{
    const width = proportion ? `${proportion * 100}%` : 'auto';
    return {
        width,
        height: '52px',
        paddingLeft: '16px',
        boxSizing: 'border-box',
        textAlign: align,
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        fontSize: 'var(--affine-font-sm)',
        ...active ? {
            color: 'var(--affine-text-primary-color)'
        } : {},
        ...ellipsis ? textEllipsis(1) : {},
        ...onClick ? {
            cursor: 'pointer'
        } : {}
    };
});
export const StyledTableHead = styled('thead')(()=>{
    return {
        fontWeight: 500,
        color: 'var(--affine-text-secondary-color)'
    };
});
export const StyledTHeadRow = styled('tr')({
    td: {
        whiteSpace: 'nowrap',
        position: 'sticky',
        top: 0,
        background: 'var(--affine-background-primary-color)'
    }
});
export const StyledTBodyRow = styled('tr')(()=>{
    return {
        td: {
            transition: 'background .15s'
        },
        'td:first-of-type': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
        },
        'td:last-of-type': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
        },
        ':hover': {
            td: {
                background: 'var(--affine-hover-color)'
            }
        }
    };
});
