import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Menu, MenuItem } from '@affine/component';
import { DateSelect } from './date-select';
import { FilterTag } from './filter-tag-translation';
import { inputStyle } from './index.css';
import { tBoolean, tDate, tDateRange, tTag } from './logical/custom-type';
import { Matcher } from './logical/matcher';
import { tArray, typesystem } from './logical/typesystem';
import { MultiSelect } from './multi-select';
export const literalMatcher = new Matcher((type, target)=>{
    return typesystem.isSubtype(type, target);
});
literalMatcher.register(tDateRange.create(), {
    render: ({ value, onChange })=>_jsx(Menu, {
            items: _jsxs("div", {
                children: [
                    _jsx(Input, {
                        type: "number",
                        onChange: (i)=>i ? onChange(parseInt(i)) : onChange(0)
                    }),
                    [
                        1,
                        2,
                        3,
                        7,
                        14,
                        30
                    ].map((i)=>_jsxs(MenuItem, {
                            onClick: ()=>{
                                onChange(i);
                            },
                            children: [
                                i,
                                " ",
                                i > 1 ? 'days' : 'day'
                            ]
                        }, i))
                ]
            }),
            children: _jsxs("div", {
                children: [
                    _jsx("span", {
                        children: value.toString()
                    }),
                    " ",
                    value > 1 ? 'days' : 'day'
                ]
            })
        })
});
literalMatcher.register(tBoolean.create(), {
    render: ({ value, onChange })=>_jsx("div", {
            className: inputStyle,
            style: {
                cursor: 'pointer'
            },
            onClick: ()=>{
                onChange(!value);
            },
            children: _jsx(FilterTag, {
                name: value?.toString()
            })
        })
});
literalMatcher.register(tDate.create(), {
    render: ({ value, onChange })=>_jsx(DateSelect, {
            value: value,
            onChange: onChange
        })
});
const getTagsOfArrayTag = (type)=>{
    if (type.type === 'array') {
        if (tTag.is(type.ele)) {
            return type.ele.data?.tags ?? [];
        }
        return [];
    } else {
        return [];
    }
};
literalMatcher.register(tArray(tTag.create()), {
    render: ({ type, value, onChange })=>{
        return _jsx(MultiSelect, {
            value: value ?? [],
            onChange: (value)=>onChange(value),
            options: getTagsOfArrayTag(type).map((v)=>({
                    label: v.value,
                    value: v.id
                }))
        });
    }
});
