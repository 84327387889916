import { deleteBlobMutation, fetcher, getBaseUrl, listBlobsQuery, setBlobMutation, UserFriendlyError } from '@affine/graphql';
import { BlobStorageOverCapacity } from '@toeverything/infra';
import { bufferToBlob } from '../../utils/buffer-to-blob';
export class CloudBlobStorage {
    constructor(workspaceId){
        this.workspaceId = workspaceId;
        this.name = 'cloud';
        this.readonly = false;
    }
    async get(key) {
        const suffix = key.startsWith('/') ? key : `/api/workspaces/${this.workspaceId}/blobs/${key}`;
        return fetch(getBaseUrl() + suffix, {
            cache: 'default'
        }).then(async (res)=>{
            if (!res.ok) {
                return null;
            }
            return bufferToBlob(await res.arrayBuffer());
        });
    }
    async set(key, value) {
        return await fetcher({
            query: setBlobMutation,
            variables: {
                workspaceId: this.workspaceId,
                blob: new File([
                    value
                ], key)
            }
        }).then((res)=>res.setBlob).catch((err)=>{
            const error = UserFriendlyError.fromAnyError(err);
            if (error.status === 413) {
                throw new BlobStorageOverCapacity(error);
            }
            throw err;
        });
    }
    async delete(key) {
        await fetcher({
            query: deleteBlobMutation,
            variables: {
                workspaceId: key,
                hash: key
            }
        });
    }
    async list() {
        const result = await fetcher({
            query: listBlobsQuery,
            variables: {
                workspaceId: this.workspaceId
            }
        });
        return result.listBlobs;
    }
}
