function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init_display, _init_editing, _init_host, _init_model, _init_zoom, _initProto, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _init__editing, _init__isHover, _init__isResizing, _init__isSelected, _init__noteFullHeight, _init__notePageContent, _initProto1;
import { MoreIndicatorIcon } from '@blocksuite/affine-components/icons';
import { DEFAULT_NOTE_BACKGROUND_COLOR, NoteDisplayMode, StrokeStyle } from '@blocksuite/affine-model';
import { EDGELESS_BLOCK_CHILD_PADDING } from '@blocksuite/affine-shared/consts';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { getClosestBlockComponentByPoint, handleNativeRangeAtPoint, matchFlavours, stopPropagation } from '@blocksuite/affine-shared/utils';
import { ShadowlessElement, toGfxBlockComponent } from '@blocksuite/block-std';
import { almostEqual, Bound, clamp, Point, WithDisposable } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { NoteBlockComponent } from './note-block.js';
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
export class EdgelessNoteMask extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init_display, _init_editing, _init_host, _init_model, _init_zoom, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "display"
            ],
            [
                _dec1,
                1,
                "editing"
            ],
            [
                _dec2,
                1,
                "host"
            ],
            [
                _dec3,
                1,
                "model"
            ],
            [
                _dec4,
                1,
                "zoom"
            ]
        ], []));
    }
    firstUpdated() {
        const maskDOM = this.renderRoot.querySelector('.affine-note-mask');
        const observer = new ResizeObserver((entries)=>{
            for (const entry of entries){
                if (!this.model.edgeless.collapse) {
                    const bound = Bound.deserialize(this.model.xywh);
                    const scale = this.model.edgeless.scale ?? 1;
                    const height = entry.contentRect.height * scale;
                    if (!height || almostEqual(bound.h, height)) {
                        return;
                    }
                    bound.h = height;
                    this.model.stash('xywh');
                    this.model.xywh = bound.serialize();
                }
            }
        });
        observer.observe(maskDOM);
        this._disposables.add(()=>{
            if (this.model.doc.getBlockById(this.model.id)) {
                this.model.pop('xywh');
            }
            observer.disconnect();
        });
    }
    render() {
        const extra = this.editing ? ACTIVE_NOTE_EXTRA_PADDING : 0;
        return html`
      <div
        class="affine-note-mask"
        style=${styleMap({
            position: 'absolute',
            top: `${-extra}px`,
            left: `${-extra}px`,
            bottom: `${-extra}px`,
            right: `${-extra}px`,
            zIndex: '1',
            pointerEvents: this.display ? 'auto' : 'none',
            borderRadius: `${this.model.edgeless.style.borderRadius * this.zoom}px`
        })}
      ></div>
    `;
    }
    #___private_display_1;
    get display() {
        return this.#___private_display_1;
    }
    set display(_v) {
        this.#___private_display_1 = _v;
    }
    #___private_editing_2;
    get editing() {
        return this.#___private_editing_2;
    }
    set editing(_v) {
        this.#___private_editing_2 = _v;
    }
    #___private_host_3;
    get host() {
        return this.#___private_host_3;
    }
    set host(_v) {
        this.#___private_host_3 = _v;
    }
    #___private_model_4;
    get model() {
        return this.#___private_model_4;
    }
    set model(_v) {
        this.#___private_model_4 = _v;
    }
    #___private_zoom_5;
    get zoom() {
        return this.#___private_zoom_5;
    }
    set zoom(_v) {
        this.#___private_zoom_5 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_display_1 = (_initProto(this), _init_display(this)), this.#___private_editing_2 = _init_editing(this), this.#___private_host_3 = _init_host(this), this.#___private_model_4 = _init_model(this), this.#___private_zoom_5 = _init_zoom(this);
    }
}
const ACTIVE_NOTE_EXTRA_PADDING = 20;
_dec5 = state(), _dec6 = state(), _dec7 = state(), _dec8 = state(), _dec9 = state(), _dec10 = query('.edgeless-note-page-content .affine-note-block-container');
export class EdgelessNoteBlockComponent extends toGfxBlockComponent(NoteBlockComponent) {
    static{
        ({ e: [_init__editing, _init__isHover, _init__isResizing, _init__isSelected, _init__noteFullHeight, _init__notePageContent, _initProto1] } = _apply_decs_2203_r(this, [
            [
                _dec5,
                1,
                "_editing"
            ],
            [
                _dec6,
                1,
                "_isHover"
            ],
            [
                _dec7,
                1,
                "_isResizing"
            ],
            [
                _dec8,
                1,
                "_isSelected"
            ],
            [
                _dec9,
                1,
                "_noteFullHeight"
            ],
            [
                _dec10,
                1,
                "_notePageContent"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .edgeless-note-collapse-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.2;
      transition: opacity 0.3s;
    }
    .edgeless-note-collapse-button:hover {
      opacity: 1;
    }
    .edgeless-note-collapse-button.flip {
      transform: translateX(-50%) rotate(180deg);
    }
    .edgeless-note-collapse-button.hide {
      display: none;
    }

    .edgeless-note-container:has(.affine-embed-synced-doc-container.editing)
      > .note-background {
      left: ${-ACTIVE_NOTE_EXTRA_PADDING}px !important;
      top: ${-ACTIVE_NOTE_EXTRA_PADDING}px !important;
      width: calc(100% + ${ACTIVE_NOTE_EXTRA_PADDING * 2}px) !important;
      height: calc(100% + ${ACTIVE_NOTE_EXTRA_PADDING * 2}px) !important;
    }

    .edgeless-note-container:has(.affine-embed-synced-doc-container.editing)
      > edgeless-note-mask {
      display: none;
    }
  `;
    }
    get _isShowCollapsedContent() {
        return this.model.edgeless.collapse && (this._isResizing || this._isHover);
    }
    get _zoom() {
        return this.gfx.viewport.zoom;
    }
    get rootService() {
        return this.std.getService('affine:page');
    }
    _collapsedContent() {
        if (!this._isShowCollapsedContent) {
            return nothing;
        }
        const { xywh, edgeless } = this.model;
        const { borderSize } = edgeless.style;
        const extraPadding = this._editing ? ACTIVE_NOTE_EXTRA_PADDING : 0;
        const extraBorder = this._editing ? borderSize : 0;
        const bound = Bound.deserialize(xywh);
        const scale = edgeless.scale ?? 1;
        const width = bound.w / scale + extraPadding * 2 + extraBorder;
        const height = bound.h / scale;
        const rect = this._notePageContent?.getBoundingClientRect();
        if (!rect) return nothing;
        const zoom = this.gfx.viewport.zoom;
        this._noteFullHeight = rect.height / scale / zoom + 2 * EDGELESS_BLOCK_CHILD_PADDING;
        if (height >= this._noteFullHeight) {
            return nothing;
        }
        return html`
      <div
        style=${styleMap({
            width: `${width}px`,
            height: `${this._noteFullHeight - height}px`,
            position: 'absolute',
            left: `${-(extraPadding + extraBorder / 2)}px`,
            top: `${height + extraPadding + extraBorder / 2}px`,
            background: 'var(--affine-white)',
            opacity: 0.5,
            pointerEvents: 'none',
            borderLeft: '2px var(--affine-blue) solid',
            borderBottom: '2px var(--affine-blue) solid',
            borderRight: '2px var(--affine-blue) solid',
            borderRadius: '0 0 8px 8px'
        })}
      ></div>
    `;
    }
    _handleClickAtBackground(e) {
        e.stopPropagation();
        if (!this._editing) return;
        const rect = this.getBoundingClientRect();
        const offsetY = 16 * this._zoom;
        const offsetX = 2 * this._zoom;
        const x = clamp(e.x, rect.left + offsetX, rect.right - offsetX);
        const y = clamp(e.y, rect.top + offsetY, rect.bottom - offsetY);
        handleNativeRangeAtPoint(x, y);
        if (this.doc.readonly) return;
        this._tryAddParagraph(x, y);
    }
    _hovered() {
        if (this.selection.value.some((sel)=>sel.type === 'surface' && sel.blockId === this.model.id)) {
            this._isHover = true;
        }
    }
    _leaved() {
        if (this._isHover) {
            this._isHover = false;
        }
    }
    _setCollapse(event) {
        event.stopImmediatePropagation();
        const { collapse, collapsedHeight } = this.model.edgeless;
        if (collapse) {
            this.model.doc.updateBlock(this.model, ()=>{
                this.model.edgeless.collapse = false;
            });
        } else if (collapsedHeight) {
            const { xywh, edgeless } = this.model;
            const bound = Bound.deserialize(xywh);
            bound.h = collapsedHeight * (edgeless.scale ?? 1);
            this.model.doc.updateBlock(this.model, ()=>{
                this.model.edgeless.collapse = true;
                this.model.xywh = bound.serialize();
            });
        }
        this.selection.clear();
    }
    _tryAddParagraph(x, y) {
        const nearest = getClosestBlockComponentByPoint(new Point(x, y));
        if (!nearest) return;
        const nearestBBox = nearest.getBoundingClientRect();
        const yRel = y - nearestBBox.top;
        const insertPos = yRel < nearestBBox.height / 2 ? 'before' : 'after';
        const nearestModel = nearest.model;
        const nearestModelIdx = this.model.children.indexOf(nearestModel);
        const children = this.model.children;
        const siblingModel = children[clamp(nearestModelIdx + (insertPos === 'before' ? -1 : 1), 0, children.length)];
        if ((!nearestModel.text || !matchFlavours(nearestModel, [
            'affine:paragraph',
            'affine:list'
        ])) && (!siblingModel || !siblingModel.text || !matchFlavours(siblingModel, [
            'affine:paragraph',
            'affine:list'
        ]))) {
            const [pId] = this.doc.addSiblingBlocks(nearestModel, [
                {
                    flavour: 'affine:paragraph'
                }
            ], insertPos);
            this.updateComplete.then(()=>{
                this.std.selection.setGroup('note', [
                    this.std.selection.create('text', {
                        from: {
                            blockId: pId,
                            index: 0,
                            length: 0
                        },
                        to: null
                    })
                ]);
            }).catch(console.error);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const selection = this.rootService.selection;
        this._editing = selection.has(this.model.id) && selection.editing;
        this._disposables.add(selection.slots.updated.on(()=>{
            if (selection.has(this.model.id) && selection.editing) {
                this._editing = true;
            } else {
                this._editing = false;
            }
        }));
    }
    firstUpdated() {
        const { _disposables } = this;
        const selection = this.rootService.selection;
        _disposables.add(this.rootService.slots.elementResizeStart.on(()=>{
            if (selection.selectedElements.includes(this.model)) {
                this._isResizing = true;
            }
        }));
        _disposables.add(this.rootService.slots.elementResizeEnd.on(()=>{
            this._isResizing = false;
        }));
        const observer = new MutationObserver(()=>{
            const rect = this._notePageContent?.getBoundingClientRect();
            if (!rect) return;
            const zoom = this.gfx.viewport.zoom;
            const scale = this.model.edgeless.scale ?? 1;
            this._noteFullHeight = rect.height / scale / zoom + 2 * EDGELESS_BLOCK_CHILD_PADDING;
        });
        if (this._notePageContent) {
            observer.observe(this, {
                childList: true,
                subtree: true
            });
            _disposables.add(()=>observer.disconnect());
        }
    }
    getRenderingRect() {
        const { xywh, edgeless } = this.model;
        const { collapse, scale = 1 } = edgeless;
        const bound = Bound.deserialize(xywh);
        const width = bound.w / scale;
        const height = bound.h / scale;
        return {
            x: bound.x,
            y: bound.y,
            w: width,
            h: collapse ? height : 'inherit',
            zIndex: this.toZIndex()
        };
    }
    renderGfxBlock() {
        const { model } = this;
        const { displayMode } = model;
        if (!!displayMode && displayMode === NoteDisplayMode.DocOnly) return nothing;
        const { xywh, edgeless } = model;
        const { borderRadius, borderSize, borderStyle, shadowType } = edgeless.style;
        const { collapse, collapsedHeight, scale = 1 } = edgeless;
        const bound = Bound.deserialize(xywh);
        const width = bound.w / scale;
        const height = bound.h / scale;
        const style = {
            height: '100%',
            padding: `${EDGELESS_BLOCK_CHILD_PADDING}px`,
            boxSizing: 'border-box',
            borderRadius: borderRadius + 'px',
            pointerEvents: 'all',
            transformOrigin: '0 0',
            transform: `scale(${scale})`,
            fontWeight: '400',
            lineHeight: 'var(--affine-line-height)'
        };
        const extra = this._editing ? ACTIVE_NOTE_EXTRA_PADDING : 0;
        const backgroundColor = ThemeObserver.generateColorProperty(model.background, DEFAULT_NOTE_BACKGROUND_COLOR);
        const backgroundStyle = {
            position: 'absolute',
            left: `${-extra}px`,
            top: `${-extra}px`,
            width: `${width + extra * 2}px`,
            height: `calc(100% + ${extra * 2}px)`,
            borderRadius: borderRadius + 'px',
            transition: this._editing ? 'left 0.3s, top 0.3s, width 0.3s, height 0.3s' : 'none',
            backgroundColor,
            border: `${borderSize}px ${borderStyle === StrokeStyle.Dash ? 'dashed' : borderStyle} var(--affine-black-10)`,
            boxShadow: this._editing ? 'var(--affine-active-shadow)' : !shadowType ? 'none' : `var(${shadowType})`
        };
        const isCollapsable = collapse != null && collapsedHeight != null && collapsedHeight !== this._noteFullHeight;
        const isCollapseArrowUp = collapse ? this._noteFullHeight < height : !!collapsedHeight && collapsedHeight < height;
        return html`
      <div
        class="edgeless-note-container"
        style=${styleMap(style)}
        data-model-height="${bound.h}"
        @mouseleave=${this._leaved}
        @mousemove=${this._hovered}
        data-scale="${scale}"
      >
        <div
          class="note-background"
          style=${styleMap(backgroundStyle)}
          @pointerdown=${stopPropagation}
          @click=${this._handleClickAtBackground}
        ></div>

        <div
          class="edgeless-note-page-content"
          style=${styleMap({
            width: '100%',
            height: '100%',
            'overflow-y': this._isShowCollapsedContent ? 'initial' : 'clip'
        })}
        >
          ${this.renderPageContent()}
        </div>

        ${isCollapsable ? html`<div
              class="${classMap({
            'edgeless-note-collapse-button': true,
            flip: isCollapseArrowUp,
            hide: this._isSelected
        })}"
              style=${styleMap({
            bottom: this._editing ? `${-extra}px` : '0'
        })}
              @mousedown=${stopPropagation}
              @mouseup=${stopPropagation}
              @click=${this._setCollapse}
            >
              ${MoreIndicatorIcon}
            </div>` : nothing}
        ${this._collapsedContent()}

        <edgeless-note-mask
          .model=${this.model}
          .display=${!this._editing}
          .host=${this.host}
          .zoom=${this.gfx.viewport.zoom ?? 1}
          .editing=${this._editing}
        ></edgeless-note-mask>
      </div>
    `;
    }
    #___private__editing_6;
    get _editing() {
        return this.#___private__editing_6;
    }
    set _editing(_v) {
        this.#___private__editing_6 = _v;
    }
    #___private__isHover_7;
    get _isHover() {
        return this.#___private__isHover_7;
    }
    set _isHover(_v) {
        this.#___private__isHover_7 = _v;
    }
    #___private__isResizing_8;
    get _isResizing() {
        return this.#___private__isResizing_8;
    }
    set _isResizing(_v) {
        this.#___private__isResizing_8 = _v;
    }
    #___private__isSelected_9;
    get _isSelected() {
        return this.#___private__isSelected_9;
    }
    set _isSelected(_v) {
        this.#___private__isSelected_9 = _v;
    }
    #___private__noteFullHeight_10;
    get _noteFullHeight() {
        return this.#___private__noteFullHeight_10;
    }
    set _noteFullHeight(_v) {
        this.#___private__noteFullHeight_10 = _v;
    }
    #___private__notePageContent_11;
    get _notePageContent() {
        return this.#___private__notePageContent_11;
    }
    set _notePageContent(_v) {
        this.#___private__notePageContent_11 = _v;
    }
    constructor(...args){
        super(...args), this.#___private__editing_6 = (_initProto1(this), _init__editing(this, false)), this.#___private__isHover_7 = _init__isHover(this, false), this.#___private__isResizing_8 = _init__isResizing(this, false), this.#___private__isSelected_9 = _init__isSelected(this, false), this.#___private__noteFullHeight_10 = _init__noteFullHeight(this, 0), this.#___private__notePageContent_11 = _init__notePageContent(this, null);
    }
}
