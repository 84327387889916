import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const EarthIcon = ({ size = '1em', className, onClick })=>{
    return _jsxs("svg", {
        width: size,
        height: size,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        className: className,
        onClick: onClick,
        children: [
            _jsx("path", {
                d: "M24 0V24H0V0H24Z",
                fill: "currentColor",
                fillOpacity: "0.01"
            }),
            _jsx("path", {
                d: "M14.1648 3.26207L13.7931 5.07276C13.7601 5.23316 13.7437 5.31336 13.7229 5.38604C13.5375 6.03566 13.0366 6.54776 12.3913 6.74757C12.3191 6.76993 12.2392 6.78817 12.0796 6.82466L11.6385 6.92548C10.7182 7.13583 10.1211 8.02674 10.2763 8.95793V8.95793C10.4941 10.2648 9.26172 11.3448 7.99474 10.9572L7.83525 10.9084C7.04185 10.6657 6.5 9.93336 6.5 9.10368V4.87555M15 20.5L14.1463 17.512C14.1424 17.4984 14.1405 17.4916 14.1385 17.4849C14.0455 17.1678 13.8754 16.8787 13.6434 16.6434C13.6385 16.6385 13.6335 16.6335 13.6235 16.6235L13.4395 16.4395C12.6716 15.6716 12.7416 14.4067 13.5896 13.7283V13.7283C14.1446 13.2843 14.9071 13.2035 15.5427 13.5214L16.1419 13.821C16.5428 14.0214 16.7432 14.1216 16.909 14.2543C17.1972 14.4851 17.416 14.7913 17.5409 15.1388C17.6128 15.3385 17.6426 15.5606 17.7024 16.0048L18.0588 18.6552M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z",
                stroke: "currentColor",
                strokeWidth: "1.5",
                strokeLinecap: "round"
            })
        ]
    });
};
