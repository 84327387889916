import { Entity, LiveData } from '@toeverything/infra';
export class FeedInfoModal extends Entity {
    open(feedId) {
        if (feedId) {
            this.feedId$.next(feedId);
        } else {
            this.feedId$.next(null);
        }
    }
    close() {
        this.feedId$.next(null);
    }
    onOpenChange(open) {
        if (!open) this.feedId$.next(null);
    }
    constructor(...args){
        super(...args), this.feedId$ = new LiveData(null), this.open$ = LiveData.computed((get)=>!!get(this.feedId$));
    }
}
