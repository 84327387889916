import { useLayoutEffect, useRef } from 'react';
export const useAutoFocus = (autoFocus)=>{
    const ref = useRef(null);
    useLayoutEffect(()=>{
        if (ref.current && autoFocus) {
            setTimeout(()=>{
                ref.current?.focus();
            }, 0);
        }
    }, [
        autoFocus
    ]);
    return ref;
};
export const useAutoSelect = (autoSelect)=>{
    const ref = useAutoFocus(autoSelect);
    useLayoutEffect(()=>{
        if (ref.current && autoSelect) {
            setTimeout(()=>{
                ref.current?.select();
            }, 0);
        }
    }, [
        autoSelect,
        ref
    ]);
    return ref;
};
