function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var _dec, _initClass, _EmbedLinkedDocBlockComponent;
import { Input, notify, toast, toReactNode } from '@affine/component';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { resolveLinkToDoc } from '@affine/core/modules/navigation';
import { CreationQuickSearchSession, DocsQuickSearchSession, LinksQuickSearchSession, QuickSearchService, RecentDocsQuickSearchSession } from '@affine/core/modules/quicksearch';
import { ExternalLinksQuickSearchSession } from '@affine/core/modules/quicksearch/impls/external-links';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { isNewTabTrigger } from '@affine/core/utils';
import { DebugLogger } from '@affine/debug';
import { track } from '@affine/track';
import { BlockViewIdentifier } from '@blocksuite/affine/block-std';
import { BlockServiceWatcher } from '@blocksuite/affine/block-std';
import { AffineSlashMenuWidget, DocModeExtension, EdgelessRootBlockComponent, EmbedLinkedDocBlockComponent, EmbedLinkedDocBlockConfigExtension, NotificationExtension, ParseDocUrlExtension, PeekViewExtension, QuickSearchExtension, ReferenceNodeConfigExtension } from '@blocksuite/affine/blocks';
import { AIChatBlockSchema } from '@blocksuite/affine/presets';
import { Text } from '@blocksuite/affine/store';
import { DocsService, WorkspaceService } from '@toeverything/infra';
import { customElement } from 'lit/decorators.js';
import { literal } from 'lit/static-html.js';
import { pick } from 'lodash-es';
const logger = new DebugLogger('affine::spec-patchers');
function patchSpecService(flavour, onMounted, onWidgetConnected) {
    class TempServiceWatcher extends BlockServiceWatcher {
        static{
            this.flavour = flavour;
        }
        mounted() {
            super.mounted();
            const disposable = onMounted(this.blockService);
            const disposableGroup = this.blockService.disposables;
            if (disposable) {
                disposableGroup.add(disposable);
            }
            if (onWidgetConnected) {
                disposableGroup.add(this.blockService.specSlots.widgetConnected.on(({ component })=>{
                    onWidgetConnected(component);
                }));
            }
        }
    }
    return TempServiceWatcher;
}
export function patchReferenceRenderer(reactToLit, reactRenderer) {
    const litRenderer = (reference)=>{
        const node = reactRenderer(reference);
        return reactToLit(node);
    };
    return ReferenceNodeConfigExtension({
        customContent: litRenderer
    });
}
export function patchNotificationService({ closeConfirmModal, openConfirmModal }) {
    return NotificationExtension({
        confirm: async ({ title, message, confirmText, cancelText, abort })=>{
            return new Promise((resolve)=>{
                openConfirmModal({
                    title: toReactNode(title),
                    description: toReactNode(message),
                    confirmText,
                    confirmButtonOptions: {
                        variant: 'primary'
                    },
                    cancelText,
                    onConfirm: ()=>{
                        resolve(true);
                    },
                    onCancel: ()=>{
                        resolve(false);
                    }
                });
                abort?.addEventListener('abort', ()=>{
                    resolve(false);
                    closeConfirmModal();
                });
            });
        },
        prompt: async ({ title, message, confirmText, placeholder, cancelText, autofill, abort })=>{
            return new Promise((resolve)=>{
                let value = autofill || '';
                const description = _jsxs("div", {
                    children: [
                        _jsx("span", {
                            style: {
                                marginBottom: 12
                            },
                            children: toReactNode(message)
                        }),
                        _jsx(Input, {
                            placeholder: placeholder,
                            defaultValue: value,
                            onChange: (e)=>value = e,
                            ref: (input)=>input?.select()
                        })
                    ]
                });
                openConfirmModal({
                    title: toReactNode(title),
                    description: description,
                    confirmText: confirmText ?? 'Confirm',
                    confirmButtonOptions: {
                        variant: 'primary'
                    },
                    cancelText: cancelText ?? 'Cancel',
                    onConfirm: ()=>{
                        resolve(value);
                    },
                    onCancel: ()=>{
                        resolve(null);
                    }
                });
                abort?.addEventListener('abort', ()=>{
                    resolve(null);
                    closeConfirmModal();
                });
            });
        },
        toast: (message, options)=>{
            return toast(message, options);
        },
        notify: (notification)=>{
            const accentToNotify = {
                error: notify.error,
                success: notify.success,
                warning: notify.warning,
                info: notify
            };
            const fn = accentToNotify[notification.accent || 'info'];
            if (!fn) {
                throw new Error('Invalid notification accent');
            }
            const toastId = fn({
                title: toReactNode(notification.title),
                message: toReactNode(notification.message),
                action: notification.action?.onClick ? {
                    label: toReactNode(notification.action?.label),
                    onClick: notification.action.onClick
                } : undefined,
                onDismiss: notification.onClose
            }, {
                duration: notification.duration || 0,
                onDismiss: notification.onClose,
                onAutoClose: notification.onClose
            });
            notification.abort?.addEventListener('abort', ()=>{
                notify.dismiss(toastId);
            });
        }
    });
}
export function patchEmbedLinkedDocBlockConfig(framework) {
    const getWorkbench = ()=>framework.get(WorkbenchService).workbench;
    return EmbedLinkedDocBlockConfigExtension({
        handleClick (e, _, refInfo) {
            if (isNewTabTrigger(e)) {
                const workbench = getWorkbench();
                workbench.openDoc(refInfo.pageId, {
                    at: 'new-tab'
                });
                e.preventDefault();
            }
        }
    });
}
export function patchPeekViewService(service) {
    return PeekViewExtension({
        peek: (target, template)=>{
            logger.debug('center peek', target, template);
            return service.peekView.open(target, template);
        }
    });
}
export function patchDocModeService(docService, docsService, editorService) {
    const DEFAULT_MODE = 'page';
    class AffineDocModeService {
        constructor(){
            this.setEditorMode = (mode)=>{
                editorService.editor.setMode(mode);
            };
            this.getEditorMode = ()=>{
                return editorService.editor.mode$.value;
            };
            this.setPrimaryMode = (mode, id)=>{
                if (id) {
                    docsService.list.setPrimaryMode(id, mode);
                } else {
                    docService.doc.setPrimaryMode(mode);
                }
            };
            this.getPrimaryMode = (id)=>{
                const mode = id ? docsService.list.getPrimaryMode(id) : docService.doc.getPrimaryMode();
                return mode || DEFAULT_MODE;
            };
            this.togglePrimaryMode = (id)=>{
                const mode = id ? docsService.list.togglePrimaryMode(id) : docService.doc.togglePrimaryMode();
                return mode || DEFAULT_MODE;
            };
            this.onPrimaryModeChange = (handler, id)=>{
                const mode$ = id ? docsService.list.primaryMode$(id) : docService.doc.primaryMode$;
                const sub = mode$.subscribe((m)=>handler(m || DEFAULT_MODE));
                return {
                    dispose: sub.unsubscribe
                };
            };
        }
    }
    const docModeExtension = DocModeExtension(new AffineDocModeService());
    return docModeExtension;
}
export function patchQuickSearchService(framework) {
    const QuickSearch = QuickSearchExtension({
        async openQuickSearch () {
            let searchResult = null;
            searchResult = await new Promise((resolve)=>framework.get(QuickSearchService).quickSearch.show([
                    framework.get(RecentDocsQuickSearchSession),
                    framework.get(CreationQuickSearchSession),
                    framework.get(DocsQuickSearchSession),
                    framework.get(LinksQuickSearchSession),
                    framework.get(ExternalLinksQuickSearchSession)
                ], (result)=>{
                    if (result === null) {
                        resolve(null);
                        return;
                    }
                    if (result.source === 'docs') {
                        resolve({
                            docId: result.payload.docId
                        });
                        return;
                    }
                    if (result.source === 'recent-doc') {
                        resolve({
                            docId: result.payload.docId
                        });
                        return;
                    }
                    if (result.source === 'link') {
                        resolve({
                            docId: result.payload.docId,
                            params: pick(result.payload, [
                                'mode',
                                'blockIds',
                                'elementIds'
                            ])
                        });
                        return;
                    }
                    if (result.source === 'external-link') {
                        const externalUrl = result.payload.url;
                        resolve({
                            externalUrl
                        });
                        return;
                    }
                    if (result.source === 'creation') {
                        const docsService = framework.get(DocsService);
                        const editorSettingService = framework.get(EditorSettingService);
                        const mode = result.id === 'creation:create-edgeless' ? 'edgeless' : 'page';
                        const docProps = {
                            page: {
                                title: new Text(result.payload.title)
                            },
                            note: editorSettingService.editorSetting.get('affine:note')
                        };
                        const newDoc = docsService.createDoc({
                            primaryMode: mode,
                            docProps
                        });
                        resolve({
                            docId: newDoc.id
                        });
                        return;
                    }
                }, {
                    label: {
                        key: 'com.affine.cmdk.insert-links'
                    },
                    placeholder: {
                        key: 'com.affine.cmdk.docs.placeholder'
                    }
                }));
            return searchResult;
        }
    });
    const SlashMenuQuickSearchExtension = patchSpecService('affine:page', ()=>{}, (component)=>{
        if (component instanceof AffineSlashMenuWidget) {
            component.config.items.forEach((item)=>{
                if ('action' in item && (item.name === 'Linked Doc' || item.name === 'Link')) {
                    item.action = async ({ rootComponent })=>{
                        const { success, insertedLinkType } = rootComponent.std.command.exec('insertLinkByQuickSearch');
                        if (!success) return;
                        insertedLinkType?.then((type)=>{
                            const flavour = type?.flavour;
                            if (!flavour) return;
                            if (flavour === 'affine:bookmark') {
                                track.doc.editor.slashMenu.bookmark();
                                return;
                            }
                            if (flavour === 'affine:embed-linked-doc') {
                                track.doc.editor.slashMenu.linkDoc({
                                    control: 'linkDoc'
                                });
                                return;
                            }
                        }).catch(console.error);
                    };
                }
            });
        }
    });
    return [
        QuickSearch,
        SlashMenuQuickSearchExtension
    ];
}
export function patchParseDocUrlExtension(framework) {
    const workspaceService = framework.get(WorkspaceService);
    const ParseDocUrl = ParseDocUrlExtension({
        parseDocUrl (url) {
            const info = resolveLinkToDoc(url);
            if (!info || info.workspaceId !== workspaceService.workspace.id) return;
            return {
                docId: info.docId,
                blockIds: info.blockIds,
                elementIds: info.elementIds,
                mode: info.mode
            };
        }
    });
    return [
        ParseDocUrl
    ];
}
export function patchEdgelessClipboard() {
    class EdgelessClipboardWatcher extends BlockServiceWatcher {
        static{
            this.flavour = 'affine:page';
        }
        mounted() {
            super.mounted();
            this.blockService.disposables.add(this.blockService.specSlots.viewConnected.on((view)=>{
                const { component } = view;
                if (component instanceof EdgelessRootBlockComponent) {
                    const AIChatBlockFlavour = AIChatBlockSchema.model.flavour;
                    const createFunc = (block)=>{
                        const { xywh, scale, messages, sessionId, rootDocId, rootWorkspaceId } = block.props;
                        const blockId = component.service.addBlock(AIChatBlockFlavour, {
                            xywh,
                            scale,
                            messages,
                            sessionId,
                            rootDocId,
                            rootWorkspaceId
                        }, component.surface.model.id);
                        return blockId;
                    };
                    component.clipboardController.registerBlock(AIChatBlockFlavour, createFunc);
                }
            }));
        }
    }
    return EdgelessClipboardWatcher;
}
let _LinkedDocBlockComponent;
_dec = customElement('affine-linked-doc-ref-block');
class LinkedDocBlockComponent extends (_EmbedLinkedDocBlockComponent = EmbedLinkedDocBlockComponent) {
    static{
        ({ c: [_LinkedDocBlockComponent, _initClass] } = _apply_decs_2203_r(this, [], [
            _dec
        ], _EmbedLinkedDocBlockComponent));
    }
    getInitialState() {
        return {
            loading: false,
            isBannerEmpty: true
        };
    }
    static{
        _initClass();
    }
}
export function patchForSharedPage() {
    const extension = {
        setup: (di)=>{
            di.override(BlockViewIdentifier('affine:embed-linked-doc'), ()=>literal`affine-linked-doc-ref-block`);
            di.override(BlockViewIdentifier('affine:embed-synced-doc'), ()=>literal`affine-linked-doc-ref-block`);
        }
    };
    return extension;
}
export { _LinkedDocBlockComponent as LinkedDocBlockComponent };
