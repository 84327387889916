import { NoteDisplayMode } from '@blocksuite/affine-model';
import { DocModeProvider, NotificationProvider } from '@blocksuite/affine-shared/services';
import { getBlockProps, matchFlavours } from '@blocksuite/affine-shared/utils';
import { assertExists } from '@blocksuite/global/utils';
import { GfxBlockModel } from '../../root-block/edgeless/block-model.js';
import { getElementProps, mapFrameIds, sortEdgelessElements } from '../../root-block/edgeless/utils/clone-utils.js';
import { isFrameBlock, isNoteBlock } from '../../root-block/edgeless/utils/query.js';
import { getSurfaceBlock } from '../../surface-ref-block/utils.js';
export function promptDocTitle(host, autofill) {
    const notification = host.std.getOptional(NotificationProvider);
    if (!notification) return Promise.resolve(undefined);
    return notification.prompt({
        title: 'Create linked doc',
        message: 'Enter a title for the new doc.',
        placeholder: 'Untitled',
        autofill,
        confirmText: 'Confirm',
        cancelText: 'Cancel'
    });
}
export function getTitleFromSelectedModels(selectedModels) {
    const firstBlock = selectedModels[0];
    if (matchFlavours(firstBlock, [
        'affine:paragraph'
    ]) && firstBlock.type.startsWith('h')) {
        return firstBlock.text.toString();
    }
    return undefined;
}
export function notifyDocCreated(host, doc) {
    const notification = host.std.getOptional(NotificationProvider);
    if (!notification) return;
    const abortController = new AbortController();
    const clear = ()=>{
        doc.history.off('stack-item-added', addHandler);
        doc.history.off('stack-item-popped', popHandler);
        disposable.dispose();
    };
    const closeNotify = ()=>{
        abortController.abort();
        clear();
    };
    const addHandler = doc.history.on('stack-item-added', closeNotify);
    const popHandler = doc.history.on('stack-item-popped', closeNotify);
    const disposable = host.slots.unmounted.on(closeNotify);
    notification.notify({
        title: 'Linked doc created',
        message: 'You can click undo to recovery block content',
        accent: 'info',
        duration: 10 * 1000,
        action: {
            label: 'Undo',
            onClick: ()=>{
                doc.undo();
                clear();
            }
        },
        abort: abortController.signal,
        onClose: clear
    });
}
export function addBlocksToDoc(targetDoc, model, parentId) {
    const blockProps = getBlockProps(model);
    const newModelId = targetDoc.addBlock(model.flavour, blockProps, parentId);
    const children = model.children;
    if (children.length > 0) {
        children.forEach((child)=>{
            addBlocksToDoc(targetDoc, child, newModelId);
        });
    }
}
export function convertSelectedBlocksToLinkedDoc(doc, selectedModels, docTitle) {
    const firstBlock = selectedModels[0];
    assertExists(firstBlock);
    const title = docTitle || getTitleFromSelectedModels(selectedModels);
    const linkedDoc = createLinkedDocFromBlocks(doc, selectedModels, title);
    doc.addSiblingBlocks(firstBlock, [
        {
            flavour: 'affine:embed-linked-doc',
            pageId: linkedDoc.id
        }
    ], 'before');
    selectedModels.forEach((model)=>doc.deleteBlock(model));
    return linkedDoc;
}
export function createLinkedDocFromBlocks(doc, blocks, docTitle) {
    const linkedDoc = doc.collection.createDoc({});
    linkedDoc.load(()=>{
        const rootId = linkedDoc.addBlock('affine:page', {
            title: new doc.Text(docTitle)
        });
        linkedDoc.addBlock('affine:surface', {}, rootId);
        const noteId = linkedDoc.addBlock('affine:note', {}, rootId);
        blocks.forEach((model)=>{
            addBlocksToDoc(linkedDoc, model, noteId);
        });
    });
    return linkedDoc;
}
export function createLinkedDocFromNote(doc, note, docTitle) {
    const linkedDoc = doc.collection.createDoc({});
    linkedDoc.load(()=>{
        const rootId = linkedDoc.addBlock('affine:page', {
            title: new doc.Text(docTitle)
        });
        linkedDoc.addBlock('affine:surface', {}, rootId);
        const blockProps = getBlockProps(note);
        const noteId = linkedDoc.addBlock('affine:note', {
            ...blockProps,
            hidden: false,
            displayMode: NoteDisplayMode.DocAndEdgeless
        }, rootId);
        note.children.forEach((model)=>{
            addBlocksToDoc(linkedDoc, model, noteId);
        });
    });
    return linkedDoc;
}
export function createLinkedDocFromEdgelessElements(host, elements, docTitle) {
    const linkedDoc = host.doc.collection.createDoc({});
    linkedDoc.load(()=>{
        const rootId = linkedDoc.addBlock('affine:page', {
            title: new host.doc.Text(docTitle)
        });
        const surfaceId = linkedDoc.addBlock('affine:surface', {}, rootId);
        const surface = getSurfaceBlock(linkedDoc);
        if (!surface) return;
        const sortedElements = sortEdgelessElements(elements);
        const ids = new Map();
        sortedElements.forEach((model)=>{
            let newId = model.id;
            if (model instanceof GfxBlockModel) {
                const blockProps = getBlockProps(model);
                if (isNoteBlock(model)) {
                    newId = linkedDoc.addBlock('affine:note', blockProps, rootId);
                    model.children.forEach((model)=>{
                        addBlocksToDoc(linkedDoc, model, newId);
                    });
                } else {
                    if (isFrameBlock(model)) {
                        mapFrameIds(blockProps, ids);
                    }
                    newId = linkedDoc.addBlock(model.flavour, blockProps, surfaceId);
                }
            } else {
                const props = getElementProps(model, ids);
                newId = surface.addElement(props);
            }
            ids.set(model.id, newId);
        });
    });
    host.std.get(DocModeProvider).setPrimaryMode('edgeless', linkedDoc.id);
    return linkedDoc;
}
