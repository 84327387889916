import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { assertExists } from '@blocksuite/global/utils';
import { html, render } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import * as Y from 'yjs';
import { deltaInsertsToChunks } from '../utils/delta-convert.js';
export class RenderService {
    get rendering() {
        return this._rendering;
    }
    constructor(editor){
        this.editor = editor;
        this._onYTextChange = (_, transaction)=>{
            this.editor.slots.textChange.emit();
            const yText = this.editor.yText;
            if (yText.toString().includes('\r')) {
                throw new BlockSuiteError(ErrorCode.InlineEditorError, 'yText must not contain "\\r" because it will break the range synchronization');
            }
            this.render();
            const inlineRange = this.editor.inlineRange$.peek();
            if (!inlineRange || transaction.local) return;
            const lastStartRelativePosition = this.editor.lastStartRelativePosition;
            const lastEndRelativePosition = this.editor.lastEndRelativePosition;
            if (!lastStartRelativePosition || !lastEndRelativePosition) return;
            const doc = this.editor.yText.doc;
            assertExists(doc);
            const absoluteStart = Y.createAbsolutePositionFromRelativePosition(lastStartRelativePosition, doc);
            const absoluteEnd = Y.createAbsolutePositionFromRelativePosition(lastEndRelativePosition, doc);
            const startIndex = absoluteStart?.index;
            const endIndex = absoluteEnd?.index;
            if (!startIndex || !endIndex) return;
            const newInlineRange = {
                index: startIndex,
                length: endIndex - startIndex
            };
            if (!this.editor.isValidInlineRange(newInlineRange)) return;
            this.editor.setInlineRange(newInlineRange);
            this.editor.syncInlineRange();
        };
        this.mount = ()=>{
            const editor = this.editor;
            const yText = editor.yText;
            yText.observe(this._onYTextChange);
            editor.disposables.add({
                dispose: ()=>{
                    yText.unobserve(this._onYTextChange);
                }
            });
        };
        this._rendering = false;
        this.render = ()=>{
            if (!this.editor.mounted) return;
            this._rendering = true;
            const rootElement = this.editor.rootElement;
            const embedDeltas = this.editor.deltaService.embedDeltas;
            const chunks = deltaInsertsToChunks(embedDeltas);
            let deltaIndex = 0;
            const lines = chunks.map((chunk, lineIndex)=>{
                if (lineIndex > 0) {
                    deltaIndex += 1;
                }
                if (chunk.length > 0) {
                    const elements = chunk.map((delta)=>{
                        const startOffset = deltaIndex;
                        deltaIndex += delta.insert.length;
                        const endOffset = deltaIndex;
                        return [
                            html`<v-element
              .inlineEditor=${this.editor}
              .delta=${{
                                insert: delta.insert,
                                attributes: this.editor.attributeService.normalizeAttributes(delta.attributes)
                            }}
              .startOffset=${startOffset}
              .endOffset=${endOffset}
              .lineIndex=${lineIndex}
            ></v-element>`,
                            delta
                        ];
                    });
                    return html`<v-line
          .elements=${elements}
          .index=${lineIndex}
        ></v-line>`;
                } else {
                    return html`<v-line .elements=${[]} .index=${lineIndex}></v-line>`;
                }
            });
            try {
                render(repeat(lines.map((line, i)=>({
                        line,
                        index: i
                    })), (entry)=>entry.index, (entry)=>entry.line), rootElement);
            } catch (_) {
                this.editor.rerenderWholeEditor();
            }
            this.editor.waitForUpdate().then(()=>{
                this._rendering = false;
                this.editor.slots.renderComplete.emit();
                this.editor.syncInlineRange();
            }).catch(console.error);
        };
        this.rerenderWholeEditor = ()=>{
            const rootElement = this.editor.rootElement;
            if (!rootElement.isConnected) return;
            rootElement.replaceChildren();
            delete rootElement['_$litPart$'];
            this.render();
        };
        this.waitForUpdate = async ()=>{
            const vLines = Array.from(this.editor.rootElement.querySelectorAll('v-line'));
            await Promise.all(vLines.map((line)=>line.updateComplete));
        };
    }
}
