import { jsx as _jsx } from "react/jsx-runtime";
import { EyeOpenIcon, MenuItem, MenuSeparator, toast, useConfirmModal } from '@affine/component';
import { useBlockSuiteDocMeta } from '@affine/core/components/hooks/use-block-suite-page-meta';
import { useFilteredPageMetas } from '@affine/core/components/page-list';
import { IsFavoriteIcon } from '@affine/core/components/pure/icons';
import { FeedNodeType } from '@affine/core/modules/feeds';
import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { useI18n } from '@affine/i18n';
import track from '@affine/track';
import { DeleteIcon, InformationIcon } from '@blocksuite/icons/rc';
import { DocsService, useLiveData, useService, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo, useState } from 'react';
export const FavoriteFolderOperation = ({ id })=>{
    const t = useI18n();
    const compatibleFavoriteItemsAdapter = useService(CompatibleFavoriteItemsAdapter);
    const favorite = useLiveData(useMemo(()=>{
        return compatibleFavoriteItemsAdapter.isFavorite$(id, FeedNodeType.Folder);
    }, [
        compatibleFavoriteItemsAdapter,
        id
    ]));
    return _jsx(MenuItem, {
        prefixIcon: _jsx(IsFavoriteIcon, {
            favorite: favorite
        }),
        onClick: ()=>compatibleFavoriteItemsAdapter.toggle(id, FeedNodeType.Folder),
        children: favorite ? t['com.affine.rootAppSidebar.organize.folder-rm-favorite']() : t['com.affine.rootAppSidebar.organize.folder-add-favorite']()
    });
};
export const useExplorerRSSNodeOperations = (rssNode, options)=>{
    const t = useI18n();
    const { workspaceService, compatibleFavoriteItemsAdapter } = useServices({
        WorkspaceService,
        CompatibleFavoriteItemsAdapter
    });
    const { openConfirmModal } = useConfirmModal();
    const favorite = useLiveData(useMemo(()=>{
        if (!rssNode) {
            return;
        }
        return compatibleFavoriteItemsAdapter.isFavorite$(rssNode.id, FeedNodeType.RSS);
    }, [
        rssNode,
        compatibleFavoriteItemsAdapter
    ]));
    const handleOpenInfoModal = useCallback(()=>{
        track.$.feedInfoPanel.$.open();
        options.openInfoModal();
    }, [
        options
    ]);
    const handleRemove = useCallback(()=>{
        if (!rssNode) {
            return;
        }
        openConfirmModal({
            title: t['ai.wemem.feeds.delete.confirmModal.title'](),
            description: t['ai.wemem.feeds.delete.confirmModal.description']({
                title: rssNode.name$.value
            }),
            confirmText: t['Confirm'](),
            cancelText: t['Cancel'](),
            confirmButtonOptions: {
                variant: 'error'
            },
            onConfirm () {
                options.removeNode();
                track.$.navigationPanel.docs.deleteDoc({
                    control: 'button'
                });
                toast(t['ai.wemem.feeds.delete.toast']());
            }
        });
    }, [
        rssNode,
        openConfirmModal,
        options,
        t
    ]);
    const handleToggleFavoriteDoc = useCallback(()=>{
        if (!rssNode) {
            return;
        }
        compatibleFavoriteItemsAdapter.toggle(rssNode.id, FeedNodeType.RSS);
        track.$.navigationPanel.feeds.toggleFavoriteFeedNode({
            type: FeedNodeType.RSS
        });
    }, [
        rssNode,
        compatibleFavoriteItemsAdapter
    ]);
    const [marking, setMarking] = useState(false);
    const docCollection = workspaceService.workspace.docCollection;
    const pageMetas = useBlockSuiteDocMeta(docCollection);
    const docRecordList = useService(DocsService).list;
    const filteredPageMetas = useFilteredPageMetas(pageMetas, {
        feedFilter: rssNode ? {
            source: rssNode.source$.value
        } : undefined
    });
    const handleMarkAllAsRead = useCallback(()=>{
        setMarking(true);
        filteredPageMetas.forEach((meta)=>{
            const record = docRecordList.doc$(meta.id).value;
            if (!record || !rssNode) {
                return;
            }
            if (record.markAsRead()) {
                rssNode.incrUnreadCount(-1);
            }
        });
        setMarking(false);
    }, [
        filteredPageMetas,
        docRecordList,
        rssNode
    ]);
    return useMemo(()=>[
            {
                index: 50,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(EyeOpenIcon, {}),
                    onClick: handleMarkAllAsRead,
                    disabled: marking,
                    children: t['ai.wemem.feed-docs.mark-all-as-read']()
                })
            },
            {
                index: 50,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(InformationIcon, {}),
                    onClick: handleOpenInfoModal,
                    children: t['com.affine.page-properties.page-info.view']()
                })
            },
            {
                index: 199,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(IsFavoriteIcon, {
                        favorite: favorite
                    }),
                    onClick: handleToggleFavoriteDoc,
                    children: favorite ? t['com.affine.favoritePageOperation.remove']() : t['com.affine.favoritePageOperation.add']()
                })
            },
            {
                index: 9999,
                view: _jsx(MenuSeparator, {}, "menu-separator")
            },
            {
                index: 10000,
                view: _jsx(MenuItem, {
                    type: 'danger',
                    prefixIcon: _jsx(DeleteIcon, {}),
                    onClick: handleRemove,
                    children: t['Delete']()
                })
            }
        ], [
        handleMarkAllAsRead,
        marking,
        t,
        handleOpenInfoModal,
        favorite,
        handleToggleFavoriteDoc,
        handleRemove
    ]);
};
