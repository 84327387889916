import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
export class WorkspaceList extends Entity {
    workspace$(id) {
        return this.workspaces$.map((workspaces)=>workspaces.find((workspace)=>workspace.id === id));
    }
    constructor(providers){
        super(), this.providers = providers, this.workspaces$ = new LiveData(this.providers.map((p)=>p.workspaces$)).map((v)=>{
            return v;
        }).flat().map((workspaces)=>{
            return workspaces.flat();
        }), this.isRevalidating$ = new LiveData(this.providers.map((p)=>p.isRevalidating$ ?? new LiveData(false))).flat().map((isLoadings)=>isLoadings.some((isLoading)=>isLoading));
    }
    revalidate() {
        this.providers.forEach((provider)=>provider.revalidate?.());
    }
    waitForRevalidation(signal) {
        this.revalidate();
        return this.isRevalidating$.waitFor((isLoading)=>!isLoading, signal);
    }
}
