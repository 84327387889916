import { from, merge, of, Subject, throttleTime } from 'rxjs';
import { exhaustMapWithTrailing } from '../../../../utils/';
import { DataStruct } from './data-struct';
export class IndexedDBIndex {
    constructor(schema, databaseName = 'indexer'){
        this.schema = schema;
        this.databaseName = databaseName;
        this.data = new DataStruct(this.databaseName, this.schema);
        this.broadcast$ = new Subject();
        const channel = new BroadcastChannel(this.databaseName + ':indexer');
        channel.onmessage = ()=>{
            this.broadcast$.next(1);
        };
    }
    async get(id) {
        return (await this.getAll([
            id
        ]))[0] ?? null;
    }
    async getAll(ids) {
        const trx = await this.data.readonly();
        return this.data.getAll(trx, ids);
    }
    async write() {
        return new IndexedDBIndexWriter(this.data, await this.data.readwrite());
    }
    async has(id) {
        const trx = await this.data.readonly();
        return this.data.has(trx, id);
    }
    async search(query, options = {}) {
        const trx = await this.data.readonly();
        return this.data.search(trx, query, options);
    }
    search$(query, options = {}) {
        return merge(of(1), this.broadcast$).pipe(throttleTime(500, undefined, {
            leading: true,
            trailing: true
        }), exhaustMapWithTrailing(()=>{
            return from((async ()=>{
                const trx = await this.data.readonly();
                return this.data.search(trx, query, options);
            })());
        }));
    }
    async aggregate(query, field, options = {}) {
        const trx = await this.data.readonly();
        return this.data.aggregate(trx, query, field, options);
    }
    aggregate$(query, field, options = {}) {
        return merge(of(1), this.broadcast$).pipe(throttleTime(500, undefined, {
            leading: true,
            trailing: true
        }), exhaustMapWithTrailing(()=>{
            return from((async ()=>{
                const trx = await this.data.readonly();
                return this.data.aggregate(trx, query, field, options);
            })());
        }));
    }
    async clear() {
        const trx = await this.data.readwrite();
        return this.data.clear(trx);
    }
}
export class IndexedDBIndexWriter {
    constructor(data, trx){
        this.data = data;
        this.trx = trx;
        this.inserts = [];
        this.deletes = [];
        this.channel = new BroadcastChannel(this.data.databaseName + ':indexer');
    }
    async get(id) {
        return (await this.getAll([
            id
        ]))[0] ?? null;
    }
    async getAll(ids) {
        const trx = await this.data.readonly();
        return this.data.getAll(trx, ids);
    }
    insert(document) {
        this.inserts.push(document);
    }
    delete(id) {
        this.deletes.push(id);
    }
    put(document) {
        this.delete(document.id);
        this.insert(document);
    }
    async commit() {
        await this.data.batchWrite(this.trx, this.deletes, this.inserts);
        this.channel.postMessage(1);
    }
    rollback() {}
    has(id) {
        return this.data.has(this.trx, id);
    }
    async search(query, options = {}) {
        return this.data.search(this.trx, query, options);
    }
    async aggregate(query, field, options = {}) {
        return this.data.aggregate(this.trx, query, field, options);
    }
}
export class IndexedDBIndexStorage {
    constructor(databaseName){
        this.databaseName = databaseName;
    }
    getIndex(name, s) {
        return new IndexedDBIndex(s, this.databaseName + ':' + name);
    }
}
