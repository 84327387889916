import { createEvent, Service } from '../../../framework';
export const ApplicationStarted = createEvent('ApplicationStartup');
export const ApplicationFocused = createEvent('ApplicationFocused');
export class LifecycleService extends Service {
    constructor(){
        super();
    }
    applicationStart() {
        this.eventBus.emit(ApplicationStarted, true);
    }
    applicationFocus() {
        this.eventBus.emit(ApplicationFocused, true);
    }
}
