export class FieldSchemaBuilder {
    constructor(type){
        this.schema = {
            type: 'string',
            optional: false,
            isPrimaryKey: false,
            default: undefined
        };
        this.schema.type = type;
    }
    optional() {
        this.schema.optional = true;
        return this;
    }
    default(value) {
        this.schema.default = value;
        this.schema.optional = true;
        return this;
    }
    primaryKey() {
        this.schema.isPrimaryKey = true;
        return this;
    }
}
export const f = {
    string: ()=>new FieldSchemaBuilder('string'),
    number: ()=>new FieldSchemaBuilder('number'),
    boolean: ()=>new FieldSchemaBuilder('boolean'),
    json: ()=>new FieldSchemaBuilder('json')
};
export const t = {
    document: (schema)=>{
        return {
            ...schema,
            __document: new FieldSchemaBuilder('boolean').optional()
        };
    }
};
