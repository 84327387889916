import { GfxControllerIdentifier } from '@blocksuite/block-std/gfx';
import { assertExists, Bound } from '@blocksuite/global/utils';
import { getBlockProps } from '../model/index.js';
function isEmbedSyncedDocBlock(element) {
    return !!element && 'flavour' in element && element.flavour === 'affine:embed-synced-doc';
}
export function convertDragPreviewDocToEdgeless({ blockComponent, dragPreview, cssSelector, width, height, noteScale, state }) {
    const edgelessRoot = blockComponent.closest('affine-edgeless-root');
    if (!edgelessRoot) {
        return false;
    }
    const previewEl = dragPreview.querySelector(cssSelector);
    if (!previewEl) {
        return false;
    }
    const rect = previewEl.getBoundingClientRect();
    const border = 2;
    const controller = blockComponent.std.get(GfxControllerIdentifier);
    const { viewport } = controller;
    const { left: viewportLeft, top: viewportTop } = viewport;
    const currentViewBound = new Bound(rect.x - viewportLeft, rect.y - viewportTop, rect.width + border / noteScale, rect.height + border / noteScale);
    const currentModelBound = viewport.toModelBound(currentViewBound);
    const newBound = isEmbedSyncedDocBlock(blockComponent.model) ? new Bound(currentModelBound.x, currentModelBound.y, (currentModelBound.w ?? width) * noteScale, (currentModelBound.h ?? height) * noteScale) : new Bound(currentModelBound.x, currentModelBound.y, (width ?? currentModelBound.w) * noteScale, (height ?? currentModelBound.h) * noteScale);
    const blockModel = blockComponent.model;
    const blockProps = getBlockProps(blockModel);
    const blockId = edgelessRoot.service.addBlock(blockComponent.flavour, {
        ...blockProps,
        xywh: newBound.serialize()
    }, edgelessRoot.surfaceBlockModel);
    const newBlock = edgelessRoot.service.getElementById(blockId);
    if (isEmbedSyncedDocBlock(newBlock)) {
        edgelessRoot.service.updateElement(newBlock.id, {
            scale: noteScale
        });
    }
    const doc = blockComponent.doc;
    const host = blockComponent.host;
    const altKey = state.raw.altKey;
    if (!altKey) {
        doc.deleteBlock(blockModel);
        host.selection.setGroup('note', []);
    }
    edgelessRoot.service.selection.set({
        elements: [
            blockId
        ],
        editing: false
    });
    return true;
}
export function convertDragPreviewEdgelessToDoc({ blockComponent, dropBlockId, dropType, state, style }) {
    const doc = blockComponent.doc;
    const host = blockComponent.host;
    const targetBlock = doc.getBlockById(dropBlockId);
    if (!targetBlock) return false;
    const shouldInsertIn = dropType === 'in';
    const parentBlock = shouldInsertIn ? targetBlock : doc.getParent(targetBlock);
    assertExists(parentBlock);
    const parentIndex = shouldInsertIn ? 0 : parentBlock.children.indexOf(targetBlock) + (dropType === 'after' ? 1 : 0);
    const blockModel = blockComponent.model;
    const { width, height, xywh, rotate, zIndex, ...blockProps } = getBlockProps(blockModel);
    if (style) {
        blockProps.style = style;
    }
    doc.addBlock(blockModel.flavour, blockProps, parentBlock, parentIndex);
    const altKey = state.raw.altKey;
    if (!altKey) {
        doc.deleteBlock(blockModel);
        host.selection.setGroup('gfx', []);
    }
    return true;
}
