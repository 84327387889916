import { jsx as _jsx } from "react/jsx-runtime";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useMenuItem } from '../use-menu-item';
export const DesktopMenuItem = (props)=>{
    const { className, children, otherProps } = useMenuItem(props);
    return _jsx(DropdownMenu.Item, {
        className: className,
        ...otherProps,
        children: children
    });
};
