function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _init__inlineEditorContainer, _init_attributeRenderer, _init_attributesSchema, _init_embedChecker, _init_enableAutoScrollHorizontally, _init_enableClipboard, _init_enableFormat, _init_enableUndoRedo, _init_inlineEventSource, _init_inlineRangeProvider, _init_markdownShortcutHandler, _init_readonly, _init_undoManager, _init_verticalScrollContainerGetter, _init_vLineRenderer, _init_wrapText, _init_yText, _initProto;
import { ShadowlessElement } from '@blocksuite/block-std';
import { assertExists, WithDisposable } from '@blocksuite/global/utils';
import { createInlineKeyDownHandler, InlineEditor } from '@blocksuite/inline';
import { DocCollection, Text } from '@blocksuite/store';
import { effect } from '@preact/signals-core';
import { css, html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { z } from 'zod';
import { onVBeforeinput, onVCompositionEnd } from './hooks.js';
_dec = query('.inline-editor'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
}), _dec12 = property({
    attribute: false
}), _dec13 = property({
    attribute: false
}), _dec14 = property({
    attribute: false
}), _dec15 = property({
    attribute: false
}), _dec16 = property({
    attribute: false
});
export class RichText extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init__inlineEditorContainer, _init_attributeRenderer, _init_attributesSchema, _init_embedChecker, _init_enableAutoScrollHorizontally, _init_enableClipboard, _init_enableFormat, _init_enableUndoRedo, _init_inlineEventSource, _init_inlineRangeProvider, _init_markdownShortcutHandler, _init_readonly, _init_undoManager, _init_verticalScrollContainerGetter, _init_vLineRenderer, _init_wrapText, _init_yText, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_inlineEditorContainer"
            ],
            [
                _dec1,
                1,
                "attributeRenderer"
            ],
            [
                _dec2,
                1,
                "attributesSchema"
            ],
            [
                _dec3,
                1,
                "embedChecker"
            ],
            [
                _dec4,
                1,
                "enableAutoScrollHorizontally"
            ],
            [
                _dec5,
                1,
                "enableClipboard"
            ],
            [
                _dec6,
                1,
                "enableFormat"
            ],
            [
                _dec7,
                1,
                "enableUndoRedo"
            ],
            [
                _dec8,
                1,
                "inlineEventSource"
            ],
            [
                _dec9,
                1,
                "inlineRangeProvider"
            ],
            [
                _dec10,
                1,
                "markdownShortcutHandler"
            ],
            [
                _dec11,
                1,
                "readonly"
            ],
            [
                _dec12,
                1,
                "undoManager"
            ],
            [
                _dec13,
                1,
                "verticalScrollContainerGetter"
            ],
            [
                _dec14,
                1,
                "vLineRenderer"
            ],
            [
                _dec15,
                1,
                "wrapText"
            ],
            [
                _dec16,
                1,
                "yText"
            ]
        ], []));
    }
    static{
        this.styles = css`
    rich-text {
      display: block;
      height: 100%;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;

      scroll-margin-top: 50px;
      scroll-margin-bottom: 30px;
    }

    .inline-editor {
      height: 100%;
      width: 100%;
      outline: none;
      cursor: text;
    }

    .inline-editor.readonly {
      cursor: default;
    }

    rich-text .nowrap-lines v-text span,
    rich-text .nowrap-lines v-element span {
      white-space: pre !important;
    }
  `;
    }
    #verticalScrollContainer;
    get _yText() {
        return this.yText instanceof Text ? this.yText.yText : this.yText;
    }
    get inlineEditor() {
        return this._inlineEditor;
    }
    get inlineEditorContainer() {
        assertExists(this._inlineEditorContainer);
        return this._inlineEditorContainer;
    }
    _init() {
        if (this._inlineEditor) {
            console.error('Inline editor already exists.');
            return;
        }
        if (!this.enableFormat) {
            this.attributesSchema = z.object({});
        }
        this._inlineEditor = new InlineEditor(this._yText, {
            isEmbed: (delta)=>this.embedChecker(delta),
            hooks: {
                beforeinput: onVBeforeinput,
                compositionEnd: onVCompositionEnd
            },
            inlineRangeProvider: this.inlineRangeProvider,
            vLineRenderer: this.vLineRenderer
        });
        if (this.attributesSchema) {
            this._inlineEditor.setAttributeSchema(this.attributesSchema);
        }
        if (this.attributeRenderer) {
            this._inlineEditor.setAttributeRenderer(this.attributeRenderer);
        }
        const inlineEditor = this._inlineEditor;
        const markdownShortcutHandler = this.markdownShortcutHandler;
        if (markdownShortcutHandler) {
            const keyDownHandler = createInlineKeyDownHandler(inlineEditor, {
                inputRule: {
                    key: [
                        ' ',
                        'Enter'
                    ],
                    handler: (context)=>markdownShortcutHandler(context, this.undoManager)
                }
            });
            inlineEditor.disposables.addFromEvent(this.inlineEventSource ?? this.inlineEditorContainer, 'keydown', keyDownHandler);
        }
        inlineEditor.disposables.add(effect(()=>{
            const inlineRange = inlineEditor.inlineRange$.value;
            if (!inlineRange) return;
            const verticalScrollContainer = this.#verticalScrollContainer || (this.#verticalScrollContainer = this.verticalScrollContainerGetter?.() || null);
            inlineEditor.waitForUpdate().then(()=>{
                if (!inlineEditor.mounted || inlineEditor.rendering) return;
                const range = inlineEditor.toDomRange(inlineRange);
                if (!range) return;
                if (verticalScrollContainer) {
                    const nativeRange = inlineEditor.getNativeRange();
                    if (!nativeRange || nativeRange.commonAncestorContainer.parentElement?.contains(inlineEditor.rootElement)) return;
                    const containerRect = verticalScrollContainer.getBoundingClientRect();
                    const rangeRect = range.getBoundingClientRect();
                    if (rangeRect.top < containerRect.top) {
                        this.scrollIntoView({
                            block: 'start'
                        });
                    } else if (rangeRect.bottom > containerRect.bottom) {
                        this.scrollIntoView({
                            block: 'end'
                        });
                    }
                }
                if (this.enableAutoScrollHorizontally) {
                    const containerRect = this.getBoundingClientRect();
                    const rangeRect = range.getBoundingClientRect();
                    let scrollLeft = this.scrollLeft;
                    if (rangeRect.left + rangeRect.width > containerRect.left + containerRect.width) {
                        scrollLeft += rangeRect.left + rangeRect.width - (containerRect.left + containerRect.width) + 2;
                    }
                    this.scrollLeft = scrollLeft;
                }
            }).catch(console.error);
        }));
        inlineEditor.mount(this.inlineEditorContainer, this.inlineEventSource, this.readonly);
    }
    _unmount() {
        if (this.inlineEditor?.mounted) {
            this.inlineEditor.unmount();
        }
        this._inlineEditor = null;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this._yText) {
            console.error('rich-text need yText to init.');
            return;
        }
        if (!this._yText.doc) {
            console.error('yText should be bind to yDoc.');
            return;
        }
        if (!this.undoManager) {
            this.undoManager = new DocCollection.Y.UndoManager(this._yText, {
                trackedOrigins: new Set([
                    this._yText.doc.clientID
                ])
            });
        }
        if (this.enableUndoRedo) {
            this.disposables.addFromEvent(this, 'keydown', (e)=>{
                if (e.ctrlKey || e.metaKey) {
                    if (e.key === 'z' || e.key === 'Z') {
                        if (e.shiftKey) {
                            this.undoManager.redo();
                        } else {
                            this.undoManager.undo();
                        }
                        e.stopPropagation();
                    }
                }
            });
            this.undoManager.on('stack-item-added', this._onStackItemAdded);
            this.undoManager.on('stack-item-popped', this._onStackItemPopped);
            this.disposables.add({
                dispose: ()=>{
                    this.undoManager.off('stack-item-added', this._onStackItemAdded);
                    this.undoManager.off('stack-item-popped', this._onStackItemPopped);
                }
            });
        }
        if (this.enableClipboard) {
            this.disposables.addFromEvent(this, 'copy', this._onCopy);
            this.disposables.addFromEvent(this, 'cut', this._onCut);
            this.disposables.addFromEvent(this, 'paste', this._onPaste);
        }
        this.updateComplete.then(()=>{
            this._unmount();
            this._init();
            this.disposables.add({
                dispose: ()=>{
                    this._unmount();
                }
            });
        }).catch(console.error);
    }
    async getUpdateComplete() {
        const result = await super.getUpdateComplete();
        await this.inlineEditor?.waitForUpdate();
        return result;
    }
    render() {
        const classes = classMap({
            'inline-editor': true,
            'nowrap-lines': !this.wrapText,
            readonly: this.readonly
        });
        return html`<div
      contenteditable=${this.readonly ? 'false' : 'true'}
      class=${classes}
    ></div>`;
    }
    updated(changedProperties) {
        if (this._inlineEditor && changedProperties.has('readonly')) {
            this._inlineEditor.setReadonly(this.readonly);
        }
    }
    #___private__inlineEditorContainer_1;
    get _inlineEditorContainer() {
        return this.#___private__inlineEditorContainer_1;
    }
    set _inlineEditorContainer(_v) {
        this.#___private__inlineEditorContainer_1 = _v;
    }
    #___private_attributeRenderer_2;
    get attributeRenderer() {
        return this.#___private_attributeRenderer_2;
    }
    set attributeRenderer(_v) {
        this.#___private_attributeRenderer_2 = _v;
    }
    #___private_attributesSchema_3;
    get attributesSchema() {
        return this.#___private_attributesSchema_3;
    }
    set attributesSchema(_v) {
        this.#___private_attributesSchema_3 = _v;
    }
    #___private_embedChecker_4;
    get embedChecker() {
        return this.#___private_embedChecker_4;
    }
    set embedChecker(_v) {
        this.#___private_embedChecker_4 = _v;
    }
    #___private_enableAutoScrollHorizontally_5;
    get enableAutoScrollHorizontally() {
        return this.#___private_enableAutoScrollHorizontally_5;
    }
    set enableAutoScrollHorizontally(_v) {
        this.#___private_enableAutoScrollHorizontally_5 = _v;
    }
    #___private_enableClipboard_6;
    get enableClipboard() {
        return this.#___private_enableClipboard_6;
    }
    set enableClipboard(_v) {
        this.#___private_enableClipboard_6 = _v;
    }
    #___private_enableFormat_7;
    get enableFormat() {
        return this.#___private_enableFormat_7;
    }
    set enableFormat(_v) {
        this.#___private_enableFormat_7 = _v;
    }
    #___private_enableUndoRedo_8;
    get enableUndoRedo() {
        return this.#___private_enableUndoRedo_8;
    }
    set enableUndoRedo(_v) {
        this.#___private_enableUndoRedo_8 = _v;
    }
    #___private_inlineEventSource_9;
    get inlineEventSource() {
        return this.#___private_inlineEventSource_9;
    }
    set inlineEventSource(_v) {
        this.#___private_inlineEventSource_9 = _v;
    }
    #___private_inlineRangeProvider_10;
    get inlineRangeProvider() {
        return this.#___private_inlineRangeProvider_10;
    }
    set inlineRangeProvider(_v) {
        this.#___private_inlineRangeProvider_10 = _v;
    }
    #___private_markdownShortcutHandler_11;
    get markdownShortcutHandler() {
        return this.#___private_markdownShortcutHandler_11;
    }
    set markdownShortcutHandler(_v) {
        this.#___private_markdownShortcutHandler_11 = _v;
    }
    #___private_readonly_12;
    get readonly() {
        return this.#___private_readonly_12;
    }
    set readonly(_v) {
        this.#___private_readonly_12 = _v;
    }
    #___private_undoManager_13;
    get undoManager() {
        return this.#___private_undoManager_13;
    }
    set undoManager(_v) {
        this.#___private_undoManager_13 = _v;
    }
    #___private_verticalScrollContainerGetter_14;
    get verticalScrollContainerGetter() {
        return this.#___private_verticalScrollContainerGetter_14;
    }
    set verticalScrollContainerGetter(_v) {
        this.#___private_verticalScrollContainerGetter_14 = _v;
    }
    #___private_vLineRenderer_15;
    get vLineRenderer() {
        return this.#___private_vLineRenderer_15;
    }
    set vLineRenderer(_v) {
        this.#___private_vLineRenderer_15 = _v;
    }
    #___private_wrapText_16;
    get wrapText() {
        return this.#___private_wrapText_16;
    }
    set wrapText(_v) {
        this.#___private_wrapText_16 = _v;
    }
    #___private_yText_17;
    get yText() {
        return this.#___private_yText_17;
    }
    set yText(_v) {
        this.#___private_yText_17 = _v;
    }
    constructor(...args){
        super(...args), this.#verticalScrollContainer = null, this._inlineEditor = null, this._onCopy = (e)=>{
            const inlineEditor = this.inlineEditor;
            if (!inlineEditor) return;
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = inlineEditor.yTextString.slice(inlineRange.index, inlineRange.index + inlineRange.length);
            e.clipboardData?.setData('text/plain', text);
            e.preventDefault();
            e.stopPropagation();
        }, this._onCut = (e)=>{
            const inlineEditor = this.inlineEditor;
            if (!inlineEditor) return;
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = inlineEditor.yTextString.slice(inlineRange.index, inlineRange.index + inlineRange.length);
            inlineEditor.deleteText(inlineRange);
            inlineEditor.setInlineRange({
                index: inlineRange.index,
                length: 0
            });
            e.clipboardData?.setData('text/plain', text);
            e.preventDefault();
            e.stopPropagation();
        }, this._onPaste = (e)=>{
            const inlineEditor = this.inlineEditor;
            if (!inlineEditor) return;
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = e.clipboardData?.getData('text/plain')?.replace(/\r?\n|\r/g, '\n');
            if (!text) return;
            inlineEditor.insertText(inlineRange, text);
            inlineEditor.setInlineRange({
                index: inlineRange.index + text.length,
                length: 0
            });
            e.preventDefault();
            e.stopPropagation();
        }, this._onStackItemAdded = (event)=>{
            const inlineRange = this.inlineEditor?.getInlineRange();
            if (inlineRange) {
                event.stackItem.meta.set('richtext-v-range', inlineRange);
            }
        }, this._onStackItemPopped = (event)=>{
            const inlineRange = event.stackItem.meta.get('richtext-v-range');
            if (inlineRange && this.inlineEditor?.isValidInlineRange(inlineRange)) {
                this.inlineEditor?.setInlineRange(inlineRange);
            }
        }, this.#___private__inlineEditorContainer_1 = (_initProto(this), _init__inlineEditorContainer(this)), this.#___private_attributeRenderer_2 = _init_attributeRenderer(this, undefined), this.#___private_attributesSchema_3 = _init_attributesSchema(this, undefined), this.#___private_embedChecker_4 = _init_embedChecker(this, ()=>false), this.#___private_enableAutoScrollHorizontally_5 = _init_enableAutoScrollHorizontally(this, true), this.#___private_enableClipboard_6 = _init_enableClipboard(this, true), this.#___private_enableFormat_7 = _init_enableFormat(this, true), this.#___private_enableUndoRedo_8 = _init_enableUndoRedo(this, true), this.#___private_inlineEventSource_9 = _init_inlineEventSource(this, undefined), this.#___private_inlineRangeProvider_10 = _init_inlineRangeProvider(this, undefined), this.#___private_markdownShortcutHandler_11 = _init_markdownShortcutHandler(this, undefined), this.#___private_readonly_12 = _init_readonly(this, false), this.#___private_undoManager_13 = _init_undoManager(this), this.#___private_verticalScrollContainerGetter_14 = _init_verticalScrollContainerGetter(this, undefined), this.#___private_vLineRenderer_15 = _init_vLineRenderer(this), this.#___private_wrapText_16 = _init_wrapText(this, true), this.#___private_yText_17 = _init_yText(this);
    }
}
