import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Logo1IconBorder } from '@affine/component';
import { WorkspaceListItemSkeleton, WorkspaceListSkeleton } from '@affine/component/setting-components';
import { Avatar } from '@affine/component/ui/avatar';
import { Tooltip } from '@affine/component/ui/tooltip';
import { WorkspaceAvatar } from '@affine/component/workspace-avatar';
import { useWorkspaceInfo } from '@affine/core/components/hooks/use-workspace-info';
import { AuthService } from '@affine/core/modules/cloud';
import { UserFeatureService } from '@affine/core/modules/cloud/services/user-feature';
import { UNTITLED_WORKSPACE_NAME } from '@affine/env/constant';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { useLiveData, useService, useServices, WorkspaceService, WorkspacesService } from '@toeverything/infra';
import clsx from 'clsx';
import { useSetAtom } from 'jotai/react';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { authAtom } from '../../../atoms';
import { UserPlanButton } from '../../auth/user-plan-button';
import { useGeneralSettingList } from '../general-setting';
import * as style from './style.css';
export const UserInfo = ({ onAccountSettingClick, active })=>{
    const account = useLiveData(useService(AuthService).session.account$);
    if (!account) {
        return;
    }
    return _jsxs("div", {
        "data-testid": "user-info-card",
        className: clsx(style.accountButton, {
            active: active
        }),
        onClick: onAccountSettingClick,
        children: [
            _jsx(Avatar, {
                size: 28,
                rounded: 2,
                name: account.label,
                url: account.avatar,
                className: "avatar"
            }),
            _jsxs("div", {
                className: "content",
                children: [
                    _jsxs("div", {
                        className: "name-container",
                        children: [
                            _jsx("div", {
                                className: "name",
                                title: account.label,
                                children: account.label
                            }),
                            _jsx(UserPlanButton, {})
                        ]
                    }),
                    _jsx("div", {
                        className: "email",
                        title: account.email,
                        children: account.email
                    })
                ]
            })
        ]
    });
};
export const SignInButton = ()=>{
    const t = useI18n();
    const setAuthModal = useSetAtom(authAtom);
    return _jsxs("div", {
        className: style.accountButton,
        onClick: useCallback(()=>{
            setAuthModal({
                openModal: true,
                state: 'signIn'
            });
        }, [
            setAuthModal
        ]),
        children: [
            _jsx("div", {
                className: "avatar not-sign",
                children: _jsx(Logo1IconBorder, {})
            }),
            _jsxs("div", {
                className: "content",
                children: [
                    _jsx("div", {
                        className: "name",
                        title: t['com.affine.settings.sign'](),
                        children: t['com.affine.settings.sign']()
                    }),
                    _jsx("div", {
                        className: "email",
                        title: t['com.affine.setting.sign.message'](),
                        children: t['com.affine.setting.sign.message']()
                    })
                ]
            })
        ]
    });
};
export const SettingSidebar = ({ activeTab, onTabChange, selectedWorkspaceId })=>{
    const t = useI18n();
    const loginStatus = useLiveData(useService(AuthService).session.status$);
    const generalList = useGeneralSettingList();
    const gotoTab = useCallback((e)=>{
        const tab = e.currentTarget.dataset.eventArg;
        if (!tab) return;
        track.$.settingsPanel.menu.openSettings({
            to: tab
        });
        onTabChange(tab, null);
    }, [
        onTabChange
    ]);
    const onAccountSettingClick = useCallback(()=>{
        track.$.settingsPanel.menu.openSettings({
            to: 'account'
        });
        onTabChange('account', null);
    }, [
        onTabChange
    ]);
    const onWorkspaceSettingClick = useCallback((subTab, workspaceMetadata)=>{
        track.$.settingsPanel.menu.openSettings({
            to: 'workspace',
            control: subTab
        });
        onTabChange(`workspace:${subTab}`, workspaceMetadata);
    }, [
        onTabChange
    ]);
    return _jsxs("div", {
        className: style.settingSlideBar,
        "data-testid": "settings-sidebar",
        children: [
            _jsx("div", {
                className: style.sidebarTitle,
                children: t['com.affine.settingSidebar.title']()
            }),
            _jsx("div", {
                className: style.sidebarSubtitle,
                children: t['com.affine.settingSidebar.settings.general']()
            }),
            _jsx("div", {
                className: style.sidebarItemsWrapper,
                children: generalList.map(({ title, icon, key, testId })=>{
                    return _jsxs("div", {
                        className: clsx(style.sidebarSelectItem, {
                            active: key === activeTab
                        }),
                        title: title,
                        "data-event-arg": key,
                        onClick: gotoTab,
                        "data-testid": testId,
                        children: [
                            icon({
                                className: 'icon'
                            }),
                            _jsx("span", {
                                className: "setting-name",
                                children: title
                            })
                        ]
                    }, key);
                })
            }),
            _jsx("div", {
                className: style.sidebarSubtitle,
                children: t['com.affine.settingSidebar.settings.workspace']()
            }),
            _jsx("div", {
                className: clsx(style.sidebarItemsWrapper, 'scroll'),
                children: _jsx(Suspense, {
                    fallback: _jsx(WorkspaceListSkeleton, {}),
                    children: _jsx(WorkspaceList, {
                        onWorkspaceSettingClick: onWorkspaceSettingClick,
                        selectedWorkspaceId: selectedWorkspaceId,
                        activeSubTab: activeTab.split(':')[1]
                    })
                })
            }),
            _jsxs("div", {
                className: style.sidebarFooter,
                children: [
                    loginStatus === 'unauthenticated' ? _jsx(SignInButton, {}) : null,
                    loginStatus === 'authenticated' ? _jsx(Suspense, {
                        children: _jsx(UserInfo, {
                            onAccountSettingClick: onAccountSettingClick,
                            active: activeTab === 'account'
                        })
                    }) : null
                ]
            })
        ]
    });
};
export const WorkspaceList = ({ onWorkspaceSettingClick, selectedWorkspaceId, activeSubTab })=>{
    const workspaces = useLiveData(useService(WorkspacesService).list.workspaces$);
    return _jsx(_Fragment, {
        children: workspaces.map((workspace)=>{
            return _jsx(Suspense, {
                fallback: _jsx(WorkspaceListItemSkeleton, {}),
                children: _jsx(WorkspaceListItem, {
                    meta: workspace,
                    onClick: (subTab)=>{
                        onWorkspaceSettingClick(subTab, workspace);
                    },
                    activeSubTab: workspace.id === selectedWorkspaceId ? activeSubTab : undefined
                })
            }, workspace.id);
        })
    });
};
const subTabConfigs = [
    {
        key: 'preference',
        title: 'com.affine.settings.workspace.preferences'
    },
    {
        key: 'properties',
        title: 'com.affine.settings.workspace.properties'
    }
];
const WorkspaceListItem = ({ activeSubTab, meta, onClick })=>{
    const { workspaceService, userFeatureService } = useServices({
        WorkspaceService,
        UserFeatureService
    });
    const information = useWorkspaceInfo(meta);
    const name = information?.name ?? UNTITLED_WORKSPACE_NAME;
    const currentWorkspace = workspaceService.workspace;
    const isCurrent = currentWorkspace.id === meta.id;
    const t = useI18n();
    useEffect(()=>{
        userFeatureService.userFeature.revalidate();
    }, [
        userFeatureService
    ]);
    const onClickPreference = useCallback(()=>{
        onClick('preference');
    }, [
        onClick
    ]);
    const subTabs = useMemo(()=>{
        return subTabConfigs.map(({ key, title })=>{
            return _jsx("div", {
                "data-testid": `workspace-list-item-${key}`,
                onClick: ()=>{
                    onClick(key);
                },
                className: clsx(style.sidebarSelectSubItem, {
                    active: activeSubTab === key
                }),
                children: t[title]()
            }, key);
        });
    }, [
        activeSubTab,
        onClick,
        t
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsxs("div", {
                className: clsx(style.sidebarSelectItem, {
                    active: !!activeSubTab
                }),
                title: name,
                onClick: onClickPreference,
                "data-testid": "workspace-list-item",
                children: [
                    _jsx(WorkspaceAvatar, {
                        meta: meta,
                        size: 16,
                        name: name,
                        colorfulFallback: true,
                        style: {
                            marginRight: '10px'
                        },
                        rounded: 2
                    }, meta.id),
                    _jsx("span", {
                        className: "setting-name",
                        children: name
                    }),
                    isCurrent ? _jsx(Tooltip, {
                        content: "Current",
                        side: "top",
                        children: _jsx("div", {
                            className: style.currentWorkspaceLabel,
                            "data-testid": "current-workspace-label"
                        })
                    }) : null
                ]
            }),
            activeSubTab && subTabs.length > 1 ? subTabs : null
        ]
    });
};
