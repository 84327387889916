import { BlockModel } from '@blocksuite/store';
import { defineEmbedModel } from '../../../utils/index.js';
export const EmbedGithubStyles = [
    'vertical',
    'horizontal',
    'list',
    'cube'
];
export class EmbedGithubModel extends defineEmbedModel(BlockModel) {
}
