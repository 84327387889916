import { getDecoratorState, getObjectPropMeta, setObjectPropMeta } from './common.js';
const deriveSymbol = Symbol('derive');
const keys = Object.keys;
function getDerivedMeta(proto, prop) {
    return getObjectPropMeta(proto, deriveSymbol, prop);
}
export function getDerivedProps(prop, propValue, receiver) {
    const prototype = Object.getPrototypeOf(receiver);
    const decoratorState = getDecoratorState(receiver.surface);
    if (decoratorState.deriving || decoratorState.creating) {
        return null;
    }
    const deriveFns = getDerivedMeta(prototype, prop);
    return deriveFns ? deriveFns.reduce((derivedProps, fn)=>{
        const props = fn(propValue, receiver);
        Object.entries(props).forEach(([key, value])=>{
            derivedProps[key] = value;
        });
        return derivedProps;
    }, {}) : null;
}
export function updateDerivedProps(derivedProps, receiver) {
    if (derivedProps) {
        const decoratorState = getDecoratorState(receiver.surface);
        decoratorState.deriving = true;
        keys(derivedProps).forEach((key)=>{
            receiver[key] = derivedProps[key];
        });
        decoratorState.deriving = false;
    }
}
export function derive(fn) {
    return function deriveDecorator(_, context) {
        const prop = String(context.name);
        return {
            init (v) {
                const proto = Object.getPrototypeOf(this);
                const derived = getDerivedMeta(proto, prop);
                if (Array.isArray(derived)) {
                    derived.push(fn);
                } else {
                    setObjectPropMeta(deriveSymbol, proto, prop, [
                        fn
                    ]);
                }
                return v;
            }
        };
    };
}
