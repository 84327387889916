import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Skeleton } from '@affine/component';
import { generateSubscriptionCallbackLink } from '@affine/core/components/hooks/affine/use-subscription-notify';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { AuthService, SubscriptionService } from '@affine/core/modules/cloud';
import { popupWindow } from '@affine/core/utils';
import { SubscriptionPlan, SubscriptionRecurring } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { useLiveData, useService } from '@toeverything/infra';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
export const AISubscribe = ({ displayedFrequency = 'yearly', ...btnProps })=>{
    const [idempotencyKey, setIdempotencyKey] = useState(nanoid());
    const [isMutating, setMutating] = useState(false);
    const [isOpenedExternalWindow, setOpenedExternalWindow] = useState(false);
    const authService = useService(AuthService);
    const subscriptionService = useService(SubscriptionService);
    const price = useLiveData(subscriptionService.prices.aiPrice$);
    useEffect(()=>{
        subscriptionService.prices.revalidate();
    }, [
        subscriptionService
    ]);
    const t = useI18n();
    useEffect(()=>{
        if (isOpenedExternalWindow) {
            window.addEventListener('focus', subscriptionService.subscription.revalidate);
            return ()=>{
                window.removeEventListener('focus', subscriptionService.subscription.revalidate);
            };
        }
        return;
    }, [
        isOpenedExternalWindow,
        subscriptionService
    ]);
    const subscribe = useAsyncCallback(async ()=>{
        setMutating(true);
        track.$.settingsPanel.plans.checkout({
            plan: SubscriptionPlan.AI,
            recurring: SubscriptionRecurring.Yearly
        });
        try {
            const session = await subscriptionService.createCheckoutSession({
                recurring: SubscriptionRecurring.Yearly,
                idempotencyKey,
                plan: SubscriptionPlan.AI,
                coupon: null,
                successCallbackLink: generateSubscriptionCallbackLink(authService.session.account$.value, SubscriptionPlan.AI, SubscriptionRecurring.Yearly)
            });
            popupWindow(session);
            setOpenedExternalWindow(true);
            setIdempotencyKey(nanoid());
        } finally{
            setMutating(false);
        }
    }, [
        authService,
        idempotencyKey,
        subscriptionService
    ]);
    if (!price || !price.yearlyAmount) {
        return _jsx(Skeleton, {
            className: btnProps.className,
            width: 160,
            height: 36,
            style: {
                borderRadius: 18,
                ...btnProps.style
            }
        });
    }
    const priceReadable = `$${(price.yearlyAmount / 100 / (displayedFrequency === 'yearly' ? 1 : 12)).toFixed(2)}`;
    const priceFrequency = displayedFrequency === 'yearly' ? t['com.affine.payment.billing-setting.year']() : t['com.affine.payment.billing-setting.month']();
    return _jsxs(Button, {
        loading: isMutating,
        onClick: subscribe,
        variant: "primary",
        ...btnProps,
        children: [
            btnProps.children ?? `${priceReadable} / ${priceFrequency}`,
            displayedFrequency === 'monthly' ? _jsx("span", {
                style: {
                    fontSize: 10,
                    opacity: 0.75,
                    letterSpacing: -0.2,
                    paddingLeft: 4
                },
                children: t['com.affine.payment.ai.subscribe.billed-annually']()
            }) : null
        ]
    });
};
