import { jsx as _jsx } from "react/jsx-runtime";
import { Loading, toast, Tooltip } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { DocDisplayMetaService } from '@affine/core/modules/doc-display-meta';
import { DocInfoService } from '@affine/core/modules/doc-info';
import { DocsSearchService } from '@affine/core/modules/docs-search';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { DocsService, GlobalContextService, LiveData, useLiveData, useService, useServices } from '@toeverything/infra';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { ExplorerTreeNode } from '../../tree';
import { Empty } from './empty';
import { useExplorerDocNodeOperations } from './operations';
import * as styles from './styles.css';
export const ExplorerDocNode = ({ docId, onDrop, location, reorderable, isLinked, canDrop, operations: additionalOperations, dropEffect })=>{
    const t = useI18n();
    const { docsSearchService, docsService, globalContextService, docDisplayMetaService } = useServices({
        DocsSearchService,
        DocsService,
        GlobalContextService,
        DocDisplayMetaService
    });
    const active = useLiveData(globalContextService.globalContext.docId.$) === docId;
    const [collapsed, setCollapsed] = useState(true);
    const docRecord = useLiveData(docsService.list.doc$(docId));
    const DocIcon = useLiveData(docDisplayMetaService.icon$(docId, {
        reference: isLinked
    }));
    const docTitle = useLiveData(docDisplayMetaService.title$(docId));
    const isInTrash = useLiveData(docRecord?.trash$);
    const Icon = useCallback(({ className })=>{
        return _jsx(DocIcon, {
            className: className
        });
    }, [
        DocIcon
    ]);
    const children = useLiveData(useMemo(()=>LiveData.from(docsSearchService.watchRefsFrom(docId), null), [
        docsSearchService,
        docId
    ]));
    const indexerLoading = useLiveData(docsSearchService.indexer.status$.map((v)=>v.remaining === undefined || v.remaining > 0));
    const [referencesLoading, setReferencesLoading] = useState(true);
    useLayoutEffect(()=>{
        setReferencesLoading((prev)=>prev && indexerLoading);
    }, [
        indexerLoading
    ]);
    const dndData = useMemo(()=>{
        return {
            draggable: {
                entity: {
                    type: 'doc',
                    id: docId
                },
                from: location
            },
            dropTarget: {
                at: 'explorer:doc'
            }
        };
    }, [
        docId,
        location
    ]);
    const handleRename = useAsyncCallback(async (newName)=>{
        await docsService.changeDocTitle(docId, newName);
        track.$.navigationPanel.organize.renameOrganizeItem({
            type: 'doc'
        });
    }, [
        docId,
        docsService
    ]);
    const handleDropOnDoc = useAsyncCallback(async (data)=>{
        if (data.treeInstruction?.type === 'make-child') {
            if (data.source.data.entity?.type === 'doc') {
                await docsService.addLinkedDoc(docId, data.source.data.entity.id);
                track.$.navigationPanel.docs.linkDoc({
                    control: 'drag'
                });
            } else {
                toast(t['com.affine.rootAppSidebar.doc.link-doc-only']());
            }
        } else {
            onDrop?.(data);
        }
    }, [
        docId,
        docsService,
        onDrop,
        t
    ]);
    const handleDropEffectOnDoc = useCallback((data)=>{
        if (data.treeInstruction?.type === 'make-child') {
            if (data.source.data.entity?.type === 'doc') {
                return 'link';
            }
        } else {
            return dropEffect?.(data);
        }
        return;
    }, [
        dropEffect
    ]);
    const handleDropOnPlaceholder = useAsyncCallback(async (data)=>{
        if (data.source.data.entity?.type === 'doc') {
            await docsService.addLinkedDoc(docId, data.source.data.entity.id);
            track.$.navigationPanel.docs.linkDoc({
                control: 'drag'
            });
        } else {
            toast(t['com.affine.rootAppSidebar.doc.link-doc-only']());
        }
    }, [
        docId,
        docsService,
        t
    ]);
    const handleCanDrop = useMemo(()=>(args)=>{
            const entityType = args.source.data.entity?.type;
            return args.treeInstruction?.type !== 'make-child' ? (typeof canDrop === 'function' ? canDrop(args) : canDrop) ?? true : entityType === 'doc';
        }, [
        canDrop
    ]);
    const docInfoModal = useService(DocInfoService).modal;
    const operations = useExplorerDocNodeOperations(docId, useMemo(()=>({
            openInfoModal: ()=>docInfoModal.open(docId),
            openNodeCollapsed: ()=>setCollapsed(false)
        }), [
        docId,
        docInfoModal
    ]));
    const finalOperations = useMemo(()=>{
        if (additionalOperations) {
            return [
                ...operations,
                ...additionalOperations
            ];
        }
        return operations;
    }, [
        additionalOperations,
        operations
    ]);
    if (isInTrash || !docRecord) {
        return null;
    }
    return _jsx(ExplorerTreeNode, {
        icon: Icon,
        name: typeof docTitle === 'string' ? docTitle : t[docTitle.key](),
        dndData: dndData,
        onDrop: handleDropOnDoc,
        renameable: true,
        collapsed: collapsed,
        setCollapsed: setCollapsed,
        canDrop: handleCanDrop,
        to: `/${docId}`,
        active: active,
        postfix: referencesLoading && !collapsed && _jsx(Tooltip, {
            content: t['com.affine.rootAppSidebar.docs.references-loading'](),
            children: _jsx("div", {
                className: styles.loadingIcon,
                children: _jsx(Loading, {})
            })
        }),
        reorderable: reorderable,
        onRename: handleRename,
        childrenPlaceholder: _jsx(Empty, {
            onDrop: handleDropOnPlaceholder
        }),
        operations: finalOperations,
        dropEffect: handleDropEffectOnDoc,
        "data-testid": `explorer-doc-${docId}`,
        children: children?.map((child)=>_jsx(ExplorerDocNode, {
                docId: child.docId,
                reorderable: false,
                location: {
                    at: 'explorer:doc:linked-docs',
                    docId
                },
                isLinked: true
            }, child.docId))
    });
};
