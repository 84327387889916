import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Skeleton, Tooltip } from '@affine/component';
import { Loading } from '@affine/component/ui/loading';
import { WorkspaceAvatar } from '@affine/component/workspace-avatar';
import { useSystemOnline } from '@affine/core/components/hooks/use-system-online';
import { useWorkspace } from '@affine/core/components/hooks/use-workspace';
import { useWorkspaceInfo } from '@affine/core/components/hooks/use-workspace-info';
import { UNTITLED_WORKSPACE_NAME } from '@affine/env/constant';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { ArrowDownSmallIcon, CloudWorkspaceIcon, CollaborationIcon, InformationFillDuotoneIcon, LocalWorkspaceIcon, NoNetworkIcon, SettingsIcon, UnsyncIcon } from '@blocksuite/icons/rc';
import { useLiveData } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useCatchEventCallback } from '../../hooks/use-catch-event-hook';
import * as styles from './styles.css';
const CloudWorkspaceStatus = ()=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(CloudWorkspaceIcon, {}),
            "Cloud"
        ]
    });
};
const SyncingWorkspaceStatus = ({ progress })=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(Loading, {
                progress: progress,
                speed: 0
            }),
            "Syncing..."
        ]
    });
};
const UnSyncWorkspaceStatus = ()=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(UnsyncIcon, {}),
            "Wait for upload"
        ]
    });
};
const LocalWorkspaceStatus = ()=>{
    return _jsxs(_Fragment, {
        children: [
            !BUILD_CONFIG.isElectron ? _jsx(InformationFillDuotoneIcon, {
                style: {
                    color: cssVar('errorColor')
                }
            }) : _jsx(LocalWorkspaceIcon, {}),
            "Local"
        ]
    });
};
const OfflineStatus = ()=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(NoNetworkIcon, {}),
            "Offline"
        ]
    });
};
const useSyncEngineSyncProgress = (meta)=>{
    const isOnline = useSystemOnline();
    const workspace = useWorkspace(meta);
    const engineState = useLiveData(workspace?.engine.docEngineState$.throttleTime(100));
    if (!engineState || !workspace) {
        return null;
    }
    const progress = (engineState.total - engineState.syncing) / engineState.total;
    const syncing = engineState.syncing > 0 || engineState.retrying;
    let content;
    if (workspace.flavour === WorkspaceFlavour.LOCAL) {
        if (!BUILD_CONFIG.isElectron) {
            content = 'This is a local demo workspace.';
        } else {
            content = 'Saved locally';
        }
    } else if (!isOnline) {
        content = 'Disconnected, please check your network connection';
    } else if (engineState.retrying && engineState.errorMessage) {
        content = `${engineState.errorMessage}, reconnecting.`;
    } else if (engineState.retrying) {
        content = 'Sync disconnected due to unexpected issues, reconnecting.';
    } else if (syncing) {
        content = `Syncing with Wemem Cloud` + (progress ? ` (${Math.floor(progress * 100)}%)` : '');
    } else {
        content = 'Synced with AFFiNE Cloud';
    }
    const CloudWorkspaceSyncStatus = ()=>{
        if (syncing) {
            return SyncingWorkspaceStatus({
                progress: progress ? Math.max(progress, 0.2) : undefined
            });
        } else if (engineState.retrying) {
            return UnSyncWorkspaceStatus();
        } else {
            return CloudWorkspaceStatus();
        }
    };
    return {
        message: content,
        icon: workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD ? !isOnline ? _jsx(OfflineStatus, {}) : _jsx(CloudWorkspaceSyncStatus, {}) : _jsx(LocalWorkspaceStatus, {}),
        progress,
        active: workspace.flavour === WorkspaceFlavour.AFFINE_CLOUD && (syncing && progress !== undefined || engineState.retrying)
    };
};
const usePauseAnimation = (timeToResume = 5000)=>{
    const [paused, setPaused] = useState(false);
    const resume = useCallback(()=>{
        setPaused(false);
    }, []);
    const pause = useCallback(()=>{
        setPaused(true);
        if (timeToResume > 0) {
            setTimeout(resume, timeToResume);
        }
    }, [
        resume,
        timeToResume
    ]);
    return {
        paused,
        pause
    };
};
const WorkspaceSyncInfo = ({ workspaceMetadata, workspaceProfile })=>{
    const syncStatus = useSyncEngineSyncProgress(workspaceMetadata);
    const isCloud = workspaceMetadata.flavour === WorkspaceFlavour.AFFINE_CLOUD;
    const { paused, pause } = usePauseAnimation();
    const [delayActive, setDelayActive] = useState(false);
    useEffect(()=>{
        if (paused || !syncStatus) {
            return;
        }
        const delayOpen = 0;
        const delayClose = 200;
        let timer;
        if (syncStatus.active) {
            timer = setTimeout(()=>{
                setDelayActive(syncStatus.active);
            }, delayOpen);
        } else {
            timer = setTimeout(()=>{
                setDelayActive(syncStatus.active);
                pause();
            }, delayClose);
        }
        return ()=>clearTimeout(timer);
    }, [
        pause,
        paused,
        syncStatus
    ]);
    if (!workspaceProfile) {
        return null;
    }
    return _jsx("div", {
        className: styles.workspaceInfoSlider,
        "data-active": delayActive,
        children: _jsxs("div", {
            className: styles.workspaceInfoSlide,
            children: [
                _jsxs("div", {
                    className: styles.workspaceInfo,
                    "data-type": "normal",
                    children: [
                        _jsx("div", {
                            className: styles.workspaceName,
                            "data-testid": "workspace-name",
                            children: workspaceProfile.name
                        }),
                        _jsx("div", {
                            className: styles.workspaceStatus,
                            children: isCloud ? _jsx(CloudWorkspaceStatus, {}) : _jsx(LocalWorkspaceStatus, {})
                        })
                    ]
                }),
                syncStatus && _jsx("div", {
                    className: styles.workspaceInfo,
                    "data-type": "events",
                    children: _jsx(Tooltip, {
                        content: syncStatus.message,
                        options: {
                            className: styles.workspaceInfoTooltip
                        },
                        children: _jsx("div", {
                            className: styles.workspaceActiveStatus,
                            children: _jsx(SyncingWorkspaceStatus, {
                                progress: syncStatus.progress
                            })
                        })
                    })
                })
            ]
        })
    });
};
export const WorkspaceCard = forwardRef(({ workspaceMetadata, showSyncStatus, showArrowDownIcon, avatarSize = 32, onClickOpenSettings, onClickEnableCloud, className, disable, hideCollaborationIcon, ...props }, ref)=>{
    const information = useWorkspaceInfo(workspaceMetadata);
    const name = information?.name ?? UNTITLED_WORKSPACE_NAME;
    const t = useI18n();
    const onEnableCloud = useCatchEventCallback(()=>{
        onClickEnableCloud?.(workspaceMetadata);
    }, [
        onClickEnableCloud,
        workspaceMetadata
    ]);
    const onOpenSettings = useCatchEventCallback(()=>{
        onClickOpenSettings?.(workspaceMetadata);
    }, [
        onClickOpenSettings,
        workspaceMetadata
    ]);
    return _jsxs("div", {
        className: clsx(styles.container, disable ? styles.disable : null, className),
        role: "button",
        tabIndex: 0,
        "data-testid": "workspace-card",
        ref: ref,
        ...props,
        children: [
            information ? _jsx(WorkspaceAvatar, {
                meta: workspaceMetadata,
                rounded: 3,
                "data-testid": "workspace-avatar",
                size: avatarSize,
                name: name,
                colorfulFallback: true
            }) : _jsx(Skeleton, {
                width: avatarSize,
                height: avatarSize
            }),
            _jsx("div", {
                className: styles.workspaceTitleContainer,
                children: information ? showSyncStatus ? _jsx(WorkspaceSyncInfo, {
                    workspaceProfile: information,
                    workspaceMetadata: workspaceMetadata
                }) : _jsx("span", {
                    className: styles.workspaceName,
                    children: information.name
                }) : _jsx(Skeleton, {
                    width: 100
                })
            }),
            _jsxs("div", {
                className: styles.showOnCardHover,
                children: [
                    onClickEnableCloud && workspaceMetadata.flavour === WorkspaceFlavour.LOCAL ? _jsx(Button, {
                        className: styles.enableCloudButton,
                        onClick: onEnableCloud,
                        children: t['ai.wemem.workspaceCard.enableCloud']()
                    }) : null,
                    hideCollaborationIcon || information?.isOwner ? null : _jsx(CollaborationIcon, {}),
                    onClickOpenSettings && _jsx("div", {
                        className: styles.settingButton,
                        onClick: onOpenSettings,
                        children: _jsx(SettingsIcon, {
                            width: 16,
                            height: 16
                        })
                    })
                ]
            }),
            showArrowDownIcon && _jsx(ArrowDownSmallIcon, {})
        ]
    });
});
WorkspaceCard.displayName = 'WorkspaceCard';
