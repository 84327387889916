import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { signal } from '@preact/signals-core';
import * as Y from 'yjs';
export class Text {
    get deltas$() {
        return this._deltas$;
    }
    get length() {
        return this._length$.value;
    }
    get yText() {
        return this._yText;
    }
    constructor(input, onChange){
        this._onChange = onChange;
        let length1 = 0;
        if (typeof input === 'string') {
            const text = input.replaceAll('\r\n', '\n');
            length1 = text.length;
            this._yText = new Y.Text(text);
        } else if (input instanceof Y.Text) {
            this._yText = input;
            length1 = input.length;
        } else if (input instanceof Array) {
            for (const delta of input){
                if (delta.insert) {
                    delta.insert = delta.insert.replaceAll('\r\n', '\n');
                    length1 += delta.insert.length;
                }
            }
            const yText = new Y.Text();
            yText.applyDelta(input);
            this._yText = yText;
        } else {
            this._yText = new Y.Text();
        }
        this._length$ = signal(length1);
        this._deltas$ = signal([]);
        this._yText.observe(()=>{
            this._length$.value = this._yText.length;
            this._deltas$.value = this._yText.toDelta();
            this._onChange?.(this._yText);
        });
    }
    static fromDelta(delta, onChange) {
        const result = new Y.Text();
        result.applyDelta(delta);
        return new Text(result, onChange);
    }
    _transact(callback) {
        const doc = this._yText.doc;
        if (!doc) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to transact text! yText is not attached to a doc');
        }
        doc.transact(()=>{
            callback();
        }, doc.clientID);
    }
    applyDelta(delta) {
        this._transact(()=>{
            this._yText?.applyDelta(delta);
        });
    }
    bind(onChange) {
        this._onChange = onChange;
    }
    clear() {
        if (!this._yText.length) {
            return;
        }
        this._transact(()=>{
            this._yText.delete(0, this._yText.length);
        });
    }
    clone() {
        return new Text(this._yText.clone(), this._onChange);
    }
    delete(index, length1) {
        if (length1 === 0) {
            return;
        }
        if (index < 0 || length1 < 0 || index + length1 > this._yText.length) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to delete text! Index or length out of range, index: ' + index + ', length: ' + length1 + ', text length: ' + this._yText.length);
        }
        this._transact(()=>{
            this._yText.delete(index, length1);
        });
    }
    format(index, length1, format) {
        if (length1 === 0) {
            return;
        }
        if (index < 0 || length1 < 0 || index + length1 > this._yText.length) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to format text! Index or length out of range, index: ' + index + ', length: ' + length1 + ', text length: ' + this._yText.length);
        }
        this._transact(()=>{
            this._yText.format(index, length1, format);
        });
    }
    insert(content, index, attributes) {
        if (!content.length) {
            return;
        }
        if (index < 0 || index > this._yText.length) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to insert text! Index or length out of range, index: ' + index + ', length: ' + length + ', text length: ' + this._yText.length);
        }
        this._transact(()=>{
            this._yText.insert(index, content, attributes);
        });
    }
    join(other) {
        if (!other || !other.toDelta().length) {
            return;
        }
        this._transact(()=>{
            const yOther = other._yText;
            const delta = yOther.toDelta();
            delta.unshift({
                retain: this._yText.length
            });
            this._yText.applyDelta(delta);
        });
    }
    replace(index, length1, content, attributes) {
        if (index < 0 || length1 < 0 || index + length1 > this._yText.length) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to replace text! The length of the text is' + this._yText.length + ', but you are trying to replace from' + index + 'to' + index + length1);
        }
        this._transact(()=>{
            this._yText.delete(index, length1);
            this._yText.insert(index, content, attributes);
        });
    }
    sliceToDelta(begin, end) {
        const result = [];
        if (end && begin >= end) {
            return result;
        }
        if (begin === 0 && end === 0) {
            return [];
        }
        const delta = this.toDelta();
        if (begin < 1 && !end) {
            return delta;
        }
        if (delta && delta instanceof Array) {
            let charNum = 0;
            for(let i = 0; i < delta.length; i++){
                const content = delta[i];
                let contentText = content.insert || '';
                const contentLen = contentText.length;
                const isLastOp = end && charNum + contentLen > end;
                const isFirstOp = charNum + contentLen > begin && result.length === 0;
                if (isFirstOp && isLastOp) {
                    contentText = contentText.slice(begin - charNum, end - charNum);
                    result.push({
                        ...content,
                        insert: contentText
                    });
                    break;
                } else if (isFirstOp || isLastOp) {
                    contentText = isLastOp ? contentText.slice(0, end - charNum) : contentText.slice(begin - charNum);
                    result.push({
                        ...content,
                        insert: contentText
                    });
                } else {
                    result.length > 0 && result.push(content);
                }
                if (end && charNum + contentLen > end) {
                    break;
                }
                charNum = charNum + contentLen;
            }
        }
        return result;
    }
    split(index, length1 = 0) {
        if (index < 0 || length1 < 0 || index + length1 > this._yText.length) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'Failed to split text! Index or length out of range, index: ' + index + ', length: ' + length1 + ', text length: ' + this._yText.length);
        }
        const deltas = this._yText.toDelta();
        if (!(deltas instanceof Array)) {
            throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'This text cannot be split because we failed to get the deltas of it.');
        }
        let tmpIndex = 0;
        const rightDeltas = [];
        for(let i = 0; i < deltas.length; i++){
            const insert = deltas[i].insert;
            if (typeof insert === 'string') {
                if (tmpIndex + insert.length >= index + length1) {
                    const insertRight = insert.slice(index + length1 - tmpIndex);
                    rightDeltas.push({
                        insert: insertRight,
                        attributes: deltas[i].attributes
                    });
                    rightDeltas.push(...deltas.slice(i + 1));
                    break;
                }
                tmpIndex += insert.length;
            } else {
                throw new BlockSuiteError(ErrorCode.ReactiveProxyError, 'This text cannot be split because it contains non-string insert.');
            }
        }
        this.delete(index, this.length - index);
        const rightYText = new Y.Text();
        rightYText.applyDelta(rightDeltas);
        const rightText = new Text(rightYText, this._onChange);
        return rightText;
    }
    toDelta() {
        return this._yText?.toDelta() || [];
    }
    toString() {
        return this._yText?.toString() || '';
    }
}
