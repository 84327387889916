export function createDefaultDoc(collection, options = {}) {
    const doc = collection.createDoc({
        id: options.id
    });
    doc.load();
    const title = options.title ?? '';
    const rootId = doc.addBlock('affine:page', {
        title: new doc.Text(title)
    });
    collection.setDocMeta(doc.id, {
        title
    });
    doc.addBlock('affine:surface', {}, rootId);
    const noteId = doc.addBlock('affine:note', {}, rootId);
    doc.addBlock('affine:paragraph', {}, noteId);
    doc.resetHistory();
    return doc;
}
