import { jsx as _jsx } from "react/jsx-runtime";
export const QuickSearchTagIcon = ({ color })=>{
    return _jsx("svg", {
        width: "1em",
        height: "1em",
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("circle", {
            cx: "12",
            cy: "12",
            fill: color,
            r: "5"
        })
    });
};
