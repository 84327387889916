function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _init_host, _init_imagesWrapper, _init_textarea, _init_closeWrapper, _init_curIndex, _init_isInputEmpty, _init_focused, _init_chatContextValue, _init_updateContext, _init_cleanupHistories, _initProto;
import { openFileOrFiles } from '@blocksuite/affine/blocks';
import { assertExists, WithDisposable } from '@blocksuite/affine/global/utils';
import { css, html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { ChatAbortIcon, ChatClearIcon, ChatSendIcon, CloseIcon, ImageIcon } from '../_common/icons';
import { AIProvider } from '../provider';
import { reportResponse } from '../utils/action-reporter';
import { readBlobAsURL } from '../utils/image';
const MaximumImageCount = 32;
function getFirstTwoLines(text) {
    const lines = text.split('\n');
    return lines.slice(0, 2);
}
_dec = property({
    attribute: false
}), _dec1 = query('.chat-panel-images'), _dec2 = query('textarea'), _dec3 = query('.close-wrapper'), _dec4 = state(), _dec5 = state(), _dec6 = state(), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
});
export class ChatPanelInput extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_host, _init_imagesWrapper, _init_textarea, _init_closeWrapper, _init_curIndex, _init_isInputEmpty, _init_focused, _init_chatContextValue, _init_updateContext, _init_cleanupHistories, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "host"
            ],
            [
                _dec1,
                1,
                "imagesWrapper"
            ],
            [
                _dec2,
                1,
                "textarea"
            ],
            [
                _dec3,
                1,
                "closeWrapper"
            ],
            [
                _dec4,
                1,
                "curIndex"
            ],
            [
                _dec5,
                1,
                "isInputEmpty"
            ],
            [
                _dec6,
                1,
                "focused"
            ],
            [
                _dec7,
                1,
                "chatContextValue"
            ],
            [
                _dec8,
                1,
                "updateContext"
            ],
            [
                _dec9,
                1,
                "cleanupHistories"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .chat-panel-input {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      position: relative;
      margin-top: 12px;
      border-radius: 4px;
      padding: 8px;
      min-height: 94px;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;

      .chat-selection-quote {
        padding: 4px 0px 8px 0px;
        padding-left: 15px;
        max-height: 56px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: var(--affine-text-secondary-color);
        position: relative;

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .chat-quote-close {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          display: none;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border: 1px solid var(--affine-border-color);
          background-color: var(--affine-white);
        }
      }

      .chat-selection-quote:hover .chat-quote-close {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .chat-selection-quote::after {
        content: '';
        width: 2px;
        height: calc(100% - 10px);
        margin-top: 5px;
        position: absolute;
        left: 0;
        top: 0;
        background: var(--affine-quote-color);
        border-radius: 18px;
      }
    }

    .chat-panel-input-actions {
      display: flex;
      gap: 8px;
      align-items: center;

      div {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      div:nth-child(2) {
        margin-left: auto;
      }

      .image-upload {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .chat-panel-input {
      textarea {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        line-height: 22px;
        font-size: var(--affine-font-sm);
        font-weight: 400;
        font-family: var(--affine-font-family);
        color: var(--affine-text-primary-color);
        box-sizing: border-box;
        resize: none;
        overflow-y: hidden;
      }

      textarea::placeholder {
        font-size: 14px;
        font-weight: 400;
        font-family: var(--affine-font-family);
        color: var(--affine-placeholder-color);
      }

      textarea:focus {
        outline: none;
      }
    }

    .chat-panel-images-wrapper {
      overflow: hidden scroll;
      max-height: 128px;

      .chat-panel-images {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        position: relative;

        .image-container {
          width: 58px;
          height: 58px;
          border-radius: 4px;
          border: 1px solid var(--affine-border-color);
          cursor: pointer;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
      }
    }

    .chat-panel-send svg rect {
      fill: var(--affine-primary-color);
    }
    .chat-panel-send[aria-disabled='true'] {
      cursor: not-allowed;
    }
    .chat-panel-send[aria-disabled='true'] svg rect {
      fill: var(--affine-text-disable-color);
    }

    .close-wrapper {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid var(--affine-border-color);
      justify-content: center;
      align-items: center;
      display: none;
      position: absolute;
      background-color: var(--affine-white);
      z-index: 1;
      cursor: pointer;
    }

    .close-wrapper:hover {
      background-color: var(--affine-background-error-color);
      border: 1px solid var(--affine-error-color);
    }

    .close-wrapper:hover svg path {
      fill: var(--affine-error-color);
    }
  `;
    }
    #___private_host_1;
    get host() {
        return this.#___private_host_1;
    }
    set host(_v) {
        this.#___private_host_1 = _v;
    }
    #___private_imagesWrapper_2;
    get imagesWrapper() {
        return this.#___private_imagesWrapper_2;
    }
    set imagesWrapper(_v) {
        this.#___private_imagesWrapper_2 = _v;
    }
    #___private_textarea_3;
    get textarea() {
        return this.#___private_textarea_3;
    }
    set textarea(_v) {
        this.#___private_textarea_3 = _v;
    }
    #___private_closeWrapper_4;
    get closeWrapper() {
        return this.#___private_closeWrapper_4;
    }
    set closeWrapper(_v) {
        this.#___private_closeWrapper_4 = _v;
    }
    #___private_curIndex_5;
    get curIndex() {
        return this.#___private_curIndex_5;
    }
    set curIndex(_v) {
        this.#___private_curIndex_5 = _v;
    }
    #___private_isInputEmpty_6;
    get isInputEmpty() {
        return this.#___private_isInputEmpty_6;
    }
    set isInputEmpty(_v) {
        this.#___private_isInputEmpty_6 = _v;
    }
    #___private_focused_7;
    get focused() {
        return this.#___private_focused_7;
    }
    set focused(_v) {
        this.#___private_focused_7 = _v;
    }
    #___private_chatContextValue_8;
    get chatContextValue() {
        return this.#___private_chatContextValue_8;
    }
    set chatContextValue(_v) {
        this.#___private_chatContextValue_8 = _v;
    }
    #___private_updateContext_9;
    get updateContext() {
        return this.#___private_updateContext_9;
    }
    set updateContext(_v) {
        this.#___private_updateContext_9 = _v;
    }
    #___private_cleanupHistories_10;
    get cleanupHistories() {
        return this.#___private_cleanupHistories_10;
    }
    set cleanupHistories(_v) {
        this.#___private_cleanupHistories_10 = _v;
    }
    _addImages(images) {
        const oldImages = this.chatContextValue.images;
        this.updateContext({
            images: [
                ...oldImages,
                ...images
            ].slice(0, MaximumImageCount)
        });
    }
    _renderImages(images) {
        return html`
      <div
        class="chat-panel-images-wrapper"
        @mouseleave=${()=>{
            this.closeWrapper.style.display = 'none';
            this.curIndex = -1;
        }}
      >
        <div class="chat-panel-images">
          ${repeat(images, (image)=>image.name, (image, index)=>html`<div
                class="image-container"
                @mouseenter=${(evt)=>{
                const ele = evt.target;
                const rect = ele.getBoundingClientRect();
                assertExists(ele.parentElement?.parentElement);
                const parentRect = ele.parentElement.parentElement.getBoundingClientRect();
                const left = Math.abs(rect.right - parentRect.left) - 8;
                const top = Math.abs(parentRect.top - rect.top);
                this.curIndex = index;
                this.closeWrapper.style.display = 'flex';
                this.closeWrapper.style.left = left + 'px';
                this.closeWrapper.style.top = top + 'px';
            }}
              >
                <img src="${URL.createObjectURL(image)}" alt="${image.name}" />
              </div>`)}
        </div>
        <div
          class="close-wrapper"
          @click=${()=>{
            AIProvider.slots.toggleChatCards.emit({
                visible: true
            });
            if (this.curIndex >= 0 && this.curIndex < images.length) {
                const newImages = [
                    ...images
                ];
                newImages.splice(this.curIndex, 1);
                this.updateContext({
                    images: newImages
                });
                this.curIndex = -1;
                this.closeWrapper.style.display = 'none';
            }
        }}
        >
          ${CloseIcon}
        </div>
      </div>
    `;
    }
    render() {
        const { images, status } = this.chatContextValue;
        const hasImages = images.length > 0;
        const maxHeight = hasImages ? 272 + 2 : 200 + 2;
        return html`<style>
        .chat-panel-input {
          border-color: ${this.focused ? 'var(--affine-primary-color)' : 'var(--affine-border-color)'};
          box-shadow: ${this.focused ? 'var(--affine-active-shadow)' : 'none'};
          max-height: ${maxHeight}px !important;
          user-select: none;
        }
      </style>
      <div class="chat-panel-input">
        ${hasImages ? this._renderImages(images) : nothing}
        ${this.chatContextValue.quote ? html`<div class="chat-selection-quote">
              ${repeat(getFirstTwoLines(this.chatContextValue.quote), (line)=>line, (line)=>html`<div>${line}</div>`)}
              <div
                class="chat-quote-close"
                @click=${()=>{
            AIProvider.slots.toggleChatCards.emit({
                visible: true
            });
            this.updateContext({
                quote: '',
                markdown: ''
            });
        }}
              >
                ${CloseIcon}
              </div>
            </div>` : nothing}
        <textarea
          rows="1"
          placeholder="What are your thoughts?"
          @input=${()=>{
            const { textarea } = this;
            this.isInputEmpty = !textarea.value.trim();
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
            let imagesHeight = this.imagesWrapper?.scrollHeight ?? 0;
            if (imagesHeight) imagesHeight += 12;
            if (this.scrollHeight >= 200 + imagesHeight) {
                textarea.style.height = '148px';
                textarea.style.overflowY = 'scroll';
            }
        }}
          @keydown=${async (evt)=>{
            if (evt.key === 'Enter' && !evt.shiftKey && !evt.isComposing) {
                evt.preventDefault();
                await this.send();
            }
        }}
          @focus=${()=>{
            this.focused = true;
        }}
          @blur=${()=>{
            this.focused = false;
        }}
          @paste=${(event)=>{
            const items = event.clipboardData?.items;
            if (!items) return;
            for(const index in items){
                const item = items[index];
                if (item.kind === 'file' && item.type.indexOf('image') >= 0) {
                    const blob = item.getAsFile();
                    if (!blob) continue;
                    this._addImages([
                        blob
                    ]);
                }
            }
        }}
        ></textarea>
        <div class="chat-panel-input-actions">
          <div
            class="chat-history-clear"
            @click=${async ()=>{
            await this.cleanupHistories();
        }}
          >
            ${ChatClearIcon}
          </div>
          ${images.length < MaximumImageCount ? html`<div
                class="image-upload"
                @click=${async ()=>{
            const images = await openFileOrFiles({
                acceptType: 'Images',
                multiple: true
            });
            if (!images) return;
            this._addImages(images);
        }}
              >
                ${ImageIcon}
              </div>` : nothing}
          ${status === 'transmitting' ? html`<div
                @click=${()=>{
            this.chatContextValue.abortController?.abort();
            this.updateContext({
                status: 'success'
            });
            reportResponse('aborted:stop');
        }}
              >
                ${ChatAbortIcon}
              </div>` : html`<div
                @click="${this.send}"
                class="chat-panel-send"
                aria-disabled=${this.isInputEmpty}
              >
                ${ChatSendIcon}
              </div>`}
        </div>
      </div>`;
    }
    constructor(...args){
        super(...args), this.#___private_host_1 = (_initProto(this), _init_host(this)), this.#___private_imagesWrapper_2 = _init_imagesWrapper(this), this.#___private_textarea_3 = _init_textarea(this), this.#___private_closeWrapper_4 = _init_closeWrapper(this), this.#___private_curIndex_5 = _init_curIndex(this, -1), this.#___private_isInputEmpty_6 = _init_isInputEmpty(this, true), this.#___private_focused_7 = _init_focused(this, false), this.#___private_chatContextValue_8 = _init_chatContextValue(this), this.#___private_updateContext_9 = _init_updateContext(this), this.#___private_cleanupHistories_10 = _init_cleanupHistories(this), this.send = async ()=>{
            const { status, markdown } = this.chatContextValue;
            if (status === 'loading' || status === 'transmitting') return;
            const text = this.textarea.value;
            const { images } = this.chatContextValue;
            if (!text && images.length === 0) {
                return;
            }
            const { doc } = this.host;
            this.textarea.value = '';
            this.isInputEmpty = true;
            this.textarea.style.height = 'unset';
            this.updateContext({
                images: [],
                status: 'loading',
                error: null,
                quote: '',
                markdown: ''
            });
            const attachments = await Promise.all(images?.map((image)=>readBlobAsURL(image)));
            const content = (markdown ? `${markdown}\n` : '') + text;
            this.updateContext({
                items: [
                    ...this.chatContextValue.items,
                    {
                        id: '',
                        role: 'user',
                        content: content,
                        createdAt: new Date().toISOString(),
                        attachments
                    },
                    {
                        id: '',
                        role: 'assistant',
                        content: '',
                        createdAt: new Date().toISOString()
                    }
                ]
            });
            try {
                const abortController = new AbortController();
                const stream = AIProvider.actions.chat?.({
                    input: content,
                    docId: doc.id,
                    attachments: images,
                    workspaceId: doc.collection.id,
                    host: this.host,
                    stream: true,
                    signal: abortController.signal,
                    where: 'chat-panel',
                    control: 'chat-send',
                    isRootSession: true
                });
                if (stream) {
                    this.updateContext({
                        abortController
                    });
                    for await (const text of stream){
                        const items = [
                            ...this.chatContextValue.items
                        ];
                        const last = items[items.length - 1];
                        last.content += text;
                        this.updateContext({
                            items,
                            status: 'transmitting'
                        });
                    }
                    this.updateContext({
                        status: 'success'
                    });
                    if (!this.chatContextValue.chatSessionId) {
                        this.updateContext({
                            chatSessionId: AIProvider.LAST_ROOT_SESSION_ID
                        });
                    }
                    const { items } = this.chatContextValue;
                    const last = items[items.length - 1];
                    if (!last.id) {
                        const historyIds = await AIProvider.histories?.ids(doc.collection.id, doc.id, {
                            sessionId: this.chatContextValue.chatSessionId
                        });
                        if (!historyIds || !historyIds[0]) return;
                        last.id = historyIds[0].messages.at(-1)?.id ?? '';
                    }
                }
            } catch (error) {
                this.updateContext({
                    status: 'error',
                    error: error
                });
            } finally{
                this.updateContext({
                    abortController: null
                });
            }
        };
    }
}
