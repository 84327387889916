import { DarkLoadingIcon, EmbedCardDarkBannerIcon, EmbedCardDarkCubeIcon, EmbedCardDarkHorizontalIcon, EmbedCardDarkListIcon, EmbedCardDarkVerticalIcon, EmbedCardLightBannerIcon, EmbedCardLightCubeIcon, EmbedCardLightHorizontalIcon, EmbedCardLightListIcon, EmbedCardLightVerticalIcon, LightLoadingIcon } from '@blocksuite/affine-components/icons';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
export function getEmbedCardIcons() {
    const theme = ThemeObserver.mode;
    if (theme === 'light') {
        return {
            LoadingIcon: LightLoadingIcon,
            EmbedCardBannerIcon: EmbedCardLightBannerIcon,
            EmbedCardHorizontalIcon: EmbedCardLightHorizontalIcon,
            EmbedCardListIcon: EmbedCardLightListIcon,
            EmbedCardVerticalIcon: EmbedCardLightVerticalIcon,
            EmbedCardCubeIcon: EmbedCardLightCubeIcon
        };
    } else {
        return {
            LoadingIcon: DarkLoadingIcon,
            EmbedCardBannerIcon: EmbedCardDarkBannerIcon,
            EmbedCardHorizontalIcon: EmbedCardDarkHorizontalIcon,
            EmbedCardListIcon: EmbedCardDarkListIcon,
            EmbedCardVerticalIcon: EmbedCardDarkVerticalIcon,
            EmbedCardCubeIcon: EmbedCardDarkCubeIcon
        };
    }
}
