import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { useNavigateHelper } from '@affine/core/components/hooks/use-navigate-helper';
import { useWorkspaceName } from '@affine/core/components/hooks/use-workspace-info';
import { WorkspaceSelector } from '@affine/core/components/workspace-selector';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { AllDocsIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useCallback, useEffect, useState } from 'react';
import { AuthService } from '../../cloud';
import { ImportTemplateDialogService } from '../services/dialog';
import { TemplateDownloaderService } from '../services/downloader';
import { ImportTemplateService } from '../services/import';
import * as styles from './dialog.css';
const Dialog = ({ templateName, templateMode, snapshotUrl, onClose })=>{
    const t = useI18n();
    const session = useService(AuthService).session;
    const notLogin = useLiveData(session.status$) === 'unauthenticated';
    const isSessionRevalidating = useLiveData(session.isRevalidating$);
    const [importing, setImporting] = useState(false);
    const [importingError, setImportingError] = useState(null);
    const workspacesService = useService(WorkspacesService);
    const templateDownloaderService = useService(TemplateDownloaderService);
    const importTemplateService = useService(ImportTemplateService);
    const templateDownloader = templateDownloaderService.downloader;
    const isDownloading = useLiveData(templateDownloader.isDownloading$);
    const downloadError = useLiveData(templateDownloader.error$);
    const workspaces = useLiveData(workspacesService.list.workspaces$);
    const [rawSelectedWorkspace, setSelectedWorkspace] = useState(null);
    const selectedWorkspace = rawSelectedWorkspace ?? workspaces.find((w)=>w.flavour === WorkspaceFlavour.AFFINE_CLOUD) ?? workspaces.at(0);
    const selectedWorkspaceName = useWorkspaceName(selectedWorkspace);
    const { openPage, jumpToSignIn } = useNavigateHelper();
    const noWorkspace = workspaces.length === 0;
    useEffect(()=>{
        workspacesService.list.revalidate();
    }, [
        workspacesService
    ]);
    useEffect(()=>{
        session.revalidate();
    }, [
        session
    ]);
    useEffect(()=>{
        if (!isSessionRevalidating && notLogin) {
            jumpToSignIn('/template/import?' + '&name=' + templateName + '&mode=' + templateMode + '&snapshotUrl=' + snapshotUrl);
            onClose?.();
        }
    }, [
        isSessionRevalidating,
        jumpToSignIn,
        notLogin,
        onClose,
        snapshotUrl,
        templateName,
        templateMode
    ]);
    useEffect(()=>{
        templateDownloader.download({
            snapshotUrl
        });
    }, [
        snapshotUrl,
        templateDownloader
    ]);
    const handleSelectedWorkspace = useCallback((workspaceMetadata)=>{
        return setSelectedWorkspace(workspaceMetadata);
    }, []);
    const handleCreatedWorkspace = useCallback((payload)=>{
        return setSelectedWorkspace(payload.meta);
    }, []);
    const handleImportToSelectedWorkspace = useAsyncCallback(async ()=>{
        if (templateDownloader.data$.value && selectedWorkspace) {
            setImporting(true);
            try {
                const docId = await importTemplateService.importToWorkspace(selectedWorkspace, templateDownloader.data$.value, templateMode);
                openPage(selectedWorkspace.id, docId);
                onClose?.();
            } catch (err) {
                setImportingError(err);
            } finally{
                setImporting(false);
            }
        }
    }, [
        importTemplateService,
        onClose,
        openPage,
        selectedWorkspace,
        templateDownloader.data$.value,
        templateMode
    ]);
    const handleImportToNewWorkspace = useAsyncCallback(async ()=>{
        if (!templateDownloader.data$.value) {
            return;
        }
        setImporting(true);
        try {
            const { workspaceId, docId } = await importTemplateService.importToNewWorkspace(WorkspaceFlavour.AFFINE_CLOUD, 'Workspace', templateDownloader.data$.value);
            openPage(workspaceId, docId);
            onClose?.();
        } catch (err) {
            setImportingError(err);
        } finally{
            setImporting(false);
        }
    }, [
        importTemplateService,
        onClose,
        openPage,
        templateDownloader.data$.value
    ]);
    const disabled = isDownloading || importing || notLogin;
    return _jsxs(_Fragment, {
        children: [
            _jsxs("div", {
                className: styles.dialogContainer,
                children: [
                    _jsx(AllDocsIcon, {
                        className: styles.mainIcon
                    }),
                    _jsx("h6", {
                        className: styles.mainTitle,
                        children: t['com.affine.import-template.dialog.createDocWithTemplate']({
                            templateName
                        })
                    }),
                    noWorkspace ? _jsx("p", {
                        className: styles.desc,
                        children: "A new workspace will be created."
                    }) : _jsxs(_Fragment, {
                        children: [
                            _jsx("p", {
                                className: styles.desc,
                                children: "Choose a workspace."
                            }),
                            _jsx(WorkspaceSelector, {
                                workspaceMetadata: selectedWorkspace,
                                onSelectWorkspace: handleSelectedWorkspace,
                                onCreatedWorkspace: handleCreatedWorkspace,
                                className: styles.workspaceSelector,
                                showArrowDownIcon: true,
                                disable: disabled
                            })
                        ]
                    })
                ]
            }),
            importingError && _jsx("span", {
                style: {
                    color: cssVar('warningColor')
                },
                children: t['com.affine.import-template.dialog.errorImport']()
            }),
            downloadError ? _jsx("span", {
                style: {
                    color: cssVar('warningColor')
                },
                children: t['com.affine.import-template.dialog.errorLoad']()
            }) : selectedWorkspace ? _jsx(Button, {
                className: styles.mainButton,
                variant: disabled ? 'secondary' : 'primary',
                loading: disabled,
                disabled: disabled,
                onClick: handleImportToSelectedWorkspace,
                children: selectedWorkspaceName && t['com.affine.import-template.dialog.createDocToWorkspace']({
                    workspace: selectedWorkspaceName
                })
            }) : _jsx(Button, {
                className: styles.mainButton,
                variant: "primary",
                loading: disabled,
                disabled: disabled,
                onClick: handleImportToNewWorkspace,
                children: t['com.affine.import-template.dialog.createDocToNewWorkspace']()
            })
        ]
    });
};
export const ImportTemplateDialogProvider = ()=>{
    const importTemplateDialogService = useService(ImportTemplateDialogService);
    const isOpen = useLiveData(importTemplateDialogService.dialog.isOpen$);
    const template = useLiveData(importTemplateDialogService.dialog.template$);
    return _jsx(Modal, {
        open: isOpen,
        modal: true,
        persistent: true,
        withoutCloseButton: true,
        contentOptions: {
            className: styles.modal
        },
        onOpenChange: ()=>importTemplateDialogService.dialog.close(),
        children: template && _jsx(Dialog, {
            templateName: template.templateName,
            templateMode: template.templateMode,
            snapshotUrl: template.snapshotUrl,
            onClose: ()=>importTemplateDialogService.dialog.close()
        })
    });
};
