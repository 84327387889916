import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@affine/component';
import { authAtom } from '@affine/core/components/atoms';
import { useI18n } from '@affine/i18n';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
export const AILogin = (btnProps)=>{
    const t = useI18n();
    const setOpen = useSetAtom(authAtom);
    const onClickSignIn = useCallback(()=>{
        setOpen((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpen
    ]);
    return _jsx(Button, {
        onClick: onClickSignIn,
        variant: "primary",
        ...btnProps,
        children: t['com.affine.payment.ai.action.login.button-label']()
    });
};
