import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const NotFoundPattern = ()=>{
    return _jsxs("svg", {
        width: "240",
        height: "209",
        viewBox: "0 0 240 209",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: [
            _jsx("path", {
                d: "M24.4197 172.91L119.045 8.64233L213.671 172.91H24.4197Z",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("path", {
                d: "M165.921 91.5342L119.045 172.161L72.1684 91.5342L165.921 91.5342Z",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("path", {
                d: "M179.022 68.1181C179.022 101.243 152.169 128.096 119.045 128.096C85.9202 128.096 59.0674 101.243 59.0674 68.1181C59.0674 34.9934 85.9202 8.14062 119.045 8.14062C152.169 8.14062 179.022 34.9934 179.022 68.1181Z",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("circle", {
                cx: "162.485",
                cy: "142.984",
                r: "59.9775",
                transform: "rotate(120 162.485 142.984)",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("circle", {
                cx: "75.2925",
                cy: "142.984",
                r: "59.9775",
                transform: "rotate(-120 75.2925 142.984)",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("path", {
                d: "M119.045 7.64062V173.158",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("path", {
                d: "M214.536 173.475L71.2998 91.0352",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("path", {
                d: "M23.5547 173.475L166.791 91.0352",
                stroke: "var(--affine-text-disable-color)",
                strokeOpacity: "0.6"
            }),
            _jsx("ellipse", {
                cx: "119.045",
                cy: "7.63971",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "214.536",
                cy: "173.155",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "166.79",
                cy: "91.0342",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "119.045",
                cy: "173.155",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "71.2999",
                cy: "91.0342",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "119.045",
                cy: "91.0342",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "95.4903",
                cy: "131.776",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "143.236",
                cy: "131.776",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            }),
            _jsx("ellipse", {
                cx: "23.5548",
                cy: "173.155",
                rx: "5.09284",
                ry: "5.09284",
                fill: "var(--affine-text-primary-color)"
            })
        ]
    });
};
