function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _init__matrix, _init__mode, _init__monthCursor, _init__monthPickYearCursor, _init__yearCursor, _init__yearMatrix, _init_gapH, _init_gapV, _init_onChange, _init_onEscape, _init_padding, _init_size, _init_value, _initProto;
import { WithDisposable } from '@blocksuite/global/utils';
import { isSameDay, isSameMonth, isToday } from 'date-fns';
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { arrowLeftIcon } from './icons.js';
import { datePickerStyle } from './style.js';
import { clamp, getMonthMatrix, toDate } from './utils.js';
const days = [
    'Su',
    'Mo',
    'Tu',
    'We',
    'Th',
    'Fr',
    'Sa'
];
const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = property({
    attribute: false
}), _dec6 = property({
    type: Number
}), _dec7 = property({
    type: Number
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    type: Number
}), _dec11 = property({
    type: Number
}), _dec12 = property({
    type: Number
});
export class DatePicker extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__matrix, _init__mode, _init__monthCursor, _init__monthPickYearCursor, _init__yearCursor, _init__yearMatrix, _init_gapH, _init_gapV, _init_onChange, _init_onEscape, _init_padding, _init_size, _init_value, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_matrix"
            ],
            [
                _dec1,
                1,
                "_mode"
            ],
            [
                _dec2,
                1,
                "_monthCursor"
            ],
            [
                _dec3,
                1,
                "_monthPickYearCursor"
            ],
            [
                _dec4,
                1,
                "_yearCursor"
            ],
            [
                _dec5,
                1,
                "_yearMatrix"
            ],
            [
                _dec6,
                1,
                "gapH"
            ],
            [
                _dec7,
                1,
                "gapV"
            ],
            [
                _dec8,
                1,
                "onChange"
            ],
            [
                _dec9,
                1,
                "onEscape"
            ],
            [
                _dec10,
                1,
                "padding"
            ],
            [
                _dec11,
                1,
                "size"
            ],
            [
                _dec12,
                1,
                "value"
            ]
        ], []));
    }
    static{
        this.styles = datePickerStyle;
    }
    get _cardStyle() {
        return {
            '--cell-size': `${this.size}px`,
            '--gap-h': `${this.gapH}px`,
            '--gap-v': `${this.gapV}px`,
            'min-width': `${this.cardWidth}px`,
            'min-height': `${this.cardHeight}px`,
            padding: `${this.padding}px`
        };
    }
    get cardHeight() {
        const rowNum = 7;
        return this.size * rowNum + this.padding * 2 + this.gapV * (rowNum - 1) - 2;
    }
    get cardWidth() {
        const colNum = 7;
        return this.size * colNum + this.padding * 2 + this.gapH * (colNum - 1);
    }
    get date() {
        return this._cursor.getDate();
    }
    get day() {
        return this._cursor.getDay();
    }
    get dayLabel() {
        return days[this.day];
    }
    get minHeight() {
        const rowNum = 8;
        return this.size * rowNum + this.padding * 2 + this.gapV * (rowNum - 1) - 2;
    }
    get month() {
        return this._cursor.getMonth();
    }
    get monthLabel() {
        return months[this.month];
    }
    get year() {
        return this._cursor.getFullYear();
    }
    get yearLabel() {
        return this.year;
    }
    _cellRenderer(cell) {
        const classes = classMap({
            interactive: true,
            'date-cell': true,
            'date-cell--today': cell.isToday,
            'date-cell--not-curr-month': cell.notCurrentMonth,
            'date-cell--selected': !!cell.selected
        });
        const dateRaw = `${cell.date.getFullYear()}-${cell.date.getMonth()}-${cell.date.getDate()}(${cell.date.getDay()})`;
        return html`<button
      tabindex=${cell.tabIndex ?? -1}
      aria-label=${dateRaw}
      data-date=${dateRaw}
      class=${classes}
      @click=${()=>{
            this._onChange(cell.date);
        }}
    >
      ${cell.label}
    </button>`;
    }
    _dateContent() {
        return html` <div class="date-picker-header">
        <div class="date-picker-header__buttons">
          <button
            class="date-picker-header__date interactive"
            @click=${()=>this.toggleMonthSelector()}
          >
            <div>${this.monthLabel}</div>
          </button>

          <button
            class="date-picker-header__date interactive"
            @click=${()=>this.toggleYearSelector()}
          >
            <div>${this.yearLabel}</div>
          </button>
        </div>

        ${this._navAction(()=>this._moveMonth(-1), ()=>this._moveMonth(1), html`<button
            tabindex="0"
            aria-label="today"
            class="action-label interactive today"
            @click=${()=>{
            this._onChange(new Date());
        }}
          >
            <span>TODAY</span>
          </button>`)}
      </div>
      ${this._dayHeaderRenderer()}
      <div class="date-picker-weeks">
        ${this._matrix.map((week)=>html`<div class="date-picker-week">
              ${week.map((cell)=>this._cellRenderer(cell))}
            </div>`)}
      </div>`;
    }
    _dayHeaderRenderer() {
        return html`<div class="days-header">
      ${days.map((day)=>html`<div class="date-cell">${day}</div>`)}
    </div>`;
    }
    _getMatrix() {
        this._matrix = getMonthMatrix(this._cursor).map((row)=>{
            return row.map((date)=>{
                const tabIndex = isSameDay(date, this._cursor) ? 0 : -1;
                return {
                    date,
                    label: date.getDate().toString(),
                    isToday: isToday(date),
                    notCurrentMonth: !isSameMonth(date, this._cursor),
                    selected: this.value ? isSameDay(date, toDate(this.value)) : false,
                    tabIndex
                };
            });
        });
    }
    _getYearMatrix() {
        const no = Math.floor((this._yearCursor - this._minYear) / 12);
        const decade = no * 12;
        const start = this._minYear + decade;
        const end = start + 12;
        this._yearMatrix = Array.from({
            length: end - start
        }, (_, i)=>start + i).filter((v)=>v >= this._minYear && v <= this._maxYear);
    }
    _modeDecade(offset) {
        this._yearCursor = clamp(this._minYear, this._maxYear, this._yearCursor + offset);
        this._getYearMatrix();
    }
    _monthContent() {
        return html` <div class="date-picker-header">
        <button
          class="date-picker-header__date interactive"
          @click=${()=>this.toggleMonthSelector()}
        >
          <div>${this._monthPickYearCursor}</div>
        </button>

        ${this._navAction({
            action: ()=>this._monthPickYearCursor--,
            disable: this._monthPickYearCursor <= this._minYear
        }, {
            action: ()=>this._monthPickYearCursor++,
            disable: this._monthPickYearCursor >= this._maxYear
        })}
      </div>
      <div class="date-picker-month">
        ${months.map((month, index)=>{
            const isActive = this.value ? isSameMonth(this.value, new Date(this._monthPickYearCursor, index, 1)) : false;
            const classes = classMap({
                'month-cell': true,
                interactive: true,
                active: isActive
            });
            return html`<button
            tabindex=${this._monthCursor === index ? 0 : -1}
            aria-label=${month}
            class=${classes}
            @click=${()=>{
                this._cursor.setMonth(index);
                this._cursor.setFullYear(this._monthPickYearCursor);
                this._mode = 'date';
                this._getMatrix();
            }}
          >
            ${month}
          </button>`;
        })}
      </div>`;
    }
    _moveMonth(offset) {
        this._cursor.setMonth(this._cursor.getMonth() + offset);
        this._getMatrix();
    }
    _navAction(prev, curr, slot) {
        const onPrev = typeof prev === 'function' ? prev : prev.action;
        const onNext = typeof curr === 'function' ? curr : curr.action;
        const prevDisable = typeof prev === 'function' ? false : prev.disable;
        const nextDisable = typeof curr === 'function' ? false : curr.disable;
        const classes = classMap({
            'date-picker-header__action': true,
            'with-slot': !!slot
        });
        return html`<div class=${classes}>
      <button
        aria-label="previous month"
        class="date-picker-small-action interactive left"
        @click=${onPrev}
        ?disabled=${prevDisable}
      >
        ${arrowLeftIcon}
      </button>
      ${slot ?? nothing}
      <button
        aria-label="next month"
        class="date-picker-small-action interactive right"
        @click=${onNext}
        ?disabled=${nextDisable}
      >
        ${arrowLeftIcon}
      </button>
    </div>`;
    }
    _onChange(date, emit = true) {
        this._cursor = date;
        this.value = date.getTime();
        this._getMatrix();
        emit && this.onChange?.(date);
    }
    _switchMode(map) {
        return map[this._mode] ?? nothing;
    }
    _yearContent() {
        const startYear = this._yearMatrix[0];
        const endYear = this._yearMatrix[this._yearMatrix.length - 1];
        return html`<div class="date-picker-header">
        <button
          class="date-picker-header__date interactive"
          @click=${()=>this.toggleYearSelector()}
        >
          <div>${startYear}-${endYear}</div>
        </button>
        ${this._navAction({
            action: ()=>this._modeDecade(-12),
            disable: startYear <= this._minYear
        }, {
            action: ()=>this._modeDecade(12),
            disable: endYear >= this._maxYear
        })}
      </div>
      <div class="date-picker-year">
        ${this._yearMatrix.map((year)=>{
            const isActive = year === this._cursor.getFullYear();
            const classes = classMap({
                'year-cell': true,
                interactive: true,
                active: isActive
            });
            return html`<button
            tabindex=${this._yearCursor === year ? 0 : -1}
            aria-label=${year}
            class=${classes}
            @click=${()=>{
                this._cursor.setFullYear(year);
                this._mode = 'date';
                this._getMatrix();
            }}
          >
            ${year}
          </button>`;
        })}
      </div>`;
    }
    closeMonthSelector() {
        this._mode = 'date';
    }
    closeYearSelector() {
        this._mode = 'date';
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.value) this._cursor = toDate(this.value);
        this._getMatrix();
    }
    firstUpdated() {
        this._disposables.addFromEvent(this, 'keydown', (e)=>{
            e.stopPropagation();
            const directions = [
                'ArrowLeft',
                'ArrowRight',
                'ArrowUp',
                'ArrowDown'
            ];
            if (directions.includes(e.key) && this.isDateCellFocused()) {
                e.preventDefault();
                if (e.key === 'ArrowLeft') {
                    this._cursor.setDate(this._cursor.getDate() - 1);
                } else if (e.key === 'ArrowRight') {
                    this._cursor.setDate(this._cursor.getDate() + 1);
                } else if (e.key === 'ArrowUp') {
                    this._cursor.setDate(this._cursor.getDate() - 7);
                } else if (e.key === 'ArrowDown') {
                    this._cursor.setDate(this._cursor.getDate() + 7);
                }
                this._getMatrix();
                setTimeout(this.focusDateCell.bind(this));
            }
            if (directions.includes(e.key) && this.isMonthCellFocused()) {
                e.preventDefault();
                if (e.key === 'ArrowLeft') {
                    this._monthCursor = (this._monthCursor - 1 + 12) % 12;
                } else if (e.key === 'ArrowRight') {
                    this._monthCursor = (this._monthCursor + 1) % 12;
                } else if (e.key === 'ArrowUp') {
                    this._monthCursor = (this._monthCursor - 3 + 12) % 12;
                } else if (e.key === 'ArrowDown') {
                    this._monthCursor = (this._monthCursor + 3) % 12;
                }
                setTimeout(this.focusMonthCell.bind(this));
            }
            if (directions.includes(e.key) && this.isYearCellFocused()) {
                e.preventDefault();
                if (e.key === 'ArrowLeft') {
                    this._modeDecade(-1);
                } else if (e.key === 'ArrowRight') {
                    this._modeDecade(1);
                } else if (e.key === 'ArrowUp') {
                    this._modeDecade(-3);
                } else if (e.key === 'ArrowDown') {
                    this._modeDecade(3);
                }
                setTimeout(this.focusYearCell.bind(this));
            }
            if (e.key === 'Tab') {
                setTimeout(()=>{
                    const focused = this.shadowRoot?.activeElement;
                    const firstEl = this.shadowRoot?.querySelector('button');
                    if (!e.shiftKey && !focused) firstEl?.focus();
                    if (e.shiftKey && !this.shadowRoot?.contains(focused)) this.focusDateCell();
                });
            }
            if (e.key === 'Escape') {
                this.onEscape?.(toDate(this.value));
            }
        }, true);
    }
    focusDateCell() {
        const lastEl = this.shadowRoot?.querySelector('button.date-cell[tabindex="0"]');
        lastEl?.focus();
    }
    focusMonthCell() {
        const lastEl = this.shadowRoot?.querySelector('button.month-cell[tabindex="0"]');
        lastEl?.focus();
    }
    focusYearCell() {
        const lastEl = this.shadowRoot?.querySelector('button.year-cell[tabindex="0"]');
        lastEl?.focus();
    }
    isDateCellFocused() {
        const focused = this.shadowRoot?.activeElement;
        return focused?.classList.contains('date-cell');
    }
    isMonthCellFocused() {
        const focused = this.shadowRoot?.activeElement;
        return focused?.classList.contains('month-cell');
    }
    isYearCellFocused() {
        const focused = this.shadowRoot?.activeElement;
        return focused?.classList.contains('year-cell');
    }
    openMonthSelector() {
        this._monthCursor = this.month;
        this._monthPickYearCursor = this.year;
        this._mode = 'month';
    }
    openYearSelector() {
        this._yearCursor = clamp(this._minYear, this._maxYear, this.year);
        this._mode = 'year';
        this._getYearMatrix();
    }
    render() {
        const classes = classMap({
            'date-picker': true,
            [`date-picker--mode-${this._mode}`]: true
        });
        const wrapperStyle = styleMap({
            'min-height': `${this.minHeight}px`
        });
        return html`<div style=${wrapperStyle} class="date-picker-height-wrapper">
      <div class=${classes} style=${styleMap(this._cardStyle)}>
        ${this._switchMode({
            date: this._dateContent(),
            month: this._monthContent(),
            year: this._yearContent()
        })}
      </div>
    </div>`;
    }
    toggleMonthSelector() {
        if (this._mode === 'month') this.closeMonthSelector();
        else this.openMonthSelector();
    }
    toggleYearSelector() {
        if (this._mode === 'year') this.closeYearSelector();
        else this.openYearSelector();
    }
    updated(_changedProperties) {
        if (_changedProperties.has('value')) {
            if (this.value) this._onChange(toDate(this.value), false);
            else this._getMatrix();
        }
    }
    #___private__matrix_1;
    get _matrix() {
        return this.#___private__matrix_1;
    }
    set _matrix(_v) {
        this.#___private__matrix_1 = _v;
    }
    #___private__mode_2;
    get _mode() {
        return this.#___private__mode_2;
    }
    set _mode(_v) {
        this.#___private__mode_2 = _v;
    }
    #___private__monthCursor_3;
    get _monthCursor() {
        return this.#___private__monthCursor_3;
    }
    set _monthCursor(_v) {
        this.#___private__monthCursor_3 = _v;
    }
    #___private__monthPickYearCursor_4;
    get _monthPickYearCursor() {
        return this.#___private__monthPickYearCursor_4;
    }
    set _monthPickYearCursor(_v) {
        this.#___private__monthPickYearCursor_4 = _v;
    }
    #___private__yearCursor_5;
    get _yearCursor() {
        return this.#___private__yearCursor_5;
    }
    set _yearCursor(_v) {
        this.#___private__yearCursor_5 = _v;
    }
    #___private__yearMatrix_6;
    get _yearMatrix() {
        return this.#___private__yearMatrix_6;
    }
    set _yearMatrix(_v) {
        this.#___private__yearMatrix_6 = _v;
    }
    #___private_gapH_7;
    get gapH() {
        return this.#___private_gapH_7;
    }
    set gapH(_v) {
        this.#___private_gapH_7 = _v;
    }
    #___private_gapV_8;
    get gapV() {
        return this.#___private_gapV_8;
    }
    set gapV(_v) {
        this.#___private_gapV_8 = _v;
    }
    #___private_onChange_9;
    get onChange() {
        return this.#___private_onChange_9;
    }
    set onChange(_v) {
        this.#___private_onChange_9 = _v;
    }
    #___private_onEscape_10;
    get onEscape() {
        return this.#___private_onEscape_10;
    }
    set onEscape(_v) {
        this.#___private_onEscape_10 = _v;
    }
    #___private_padding_11;
    get padding() {
        return this.#___private_padding_11;
    }
    set padding(_v) {
        this.#___private_padding_11 = _v;
    }
    #___private_size_12;
    get size() {
        return this.#___private_size_12;
    }
    set size(_v) {
        this.#___private_size_12 = _v;
    }
    #___private_value_13;
    get value() {
        return this.#___private_value_13;
    }
    set value(_v) {
        this.#___private_value_13 = _v;
    }
    constructor(...args){
        super(...args), this._cursor = new Date(), this._maxYear = 2099, this._minYear = 1970, this.#___private__matrix_1 = (_initProto(this), _init__matrix(this, [])), this.#___private__mode_2 = _init__mode(this, 'date'), this.#___private__monthCursor_3 = _init__monthCursor(this, 0), this.#___private__monthPickYearCursor_4 = _init__monthPickYearCursor(this, 0), this.#___private__yearCursor_5 = _init__yearCursor(this, 0), this.#___private__yearMatrix_6 = _init__yearMatrix(this, []), this.#___private_gapH_7 = _init_gapH(this, 10), this.#___private_gapV_8 = _init_gapV(this, 8), this.#___private_onChange_9 = _init_onChange(this, undefined), this.#___private_onEscape_10 = _init_onEscape(this, undefined), this.#___private_padding_11 = _init_padding(this, 20), this.#___private_size_12 = _init_size(this, 28), this.#___private_value_13 = _init_value(this, undefined);
    }
}
