import { sleep } from '@blocksuite/global/utils';
export const dedupe = (keepWhenFloatingNotReady = true)=>{
    const SKIP = false;
    const KEEP = true;
    let hoverState = false;
    return ({ event, floatingElement })=>{
        const curState = hoverState;
        if (event.type === 'mouseover') {
            hoverState = true;
            if (curState !== hoverState) return KEEP;
            if (keepWhenFloatingNotReady && (!floatingElement || !floatingElement.isConnected)) {
                return KEEP;
            }
            return SKIP;
        }
        if (event.type === 'mouseleave') {
            hoverState = false;
            if (curState !== hoverState) return KEEP;
            if (keepWhenFloatingNotReady && floatingElement?.isConnected) {
                return KEEP;
            }
            return SKIP;
        }
        console.warn('Unknown event type in hover middleware', event);
        return KEEP;
    };
};
export const delayShow = (delay)=>{
    let abortController = new AbortController();
    return async ({ event })=>{
        abortController.abort();
        const newAbortController = new AbortController();
        abortController = newAbortController;
        if (event.type !== 'mouseover') return true;
        if (delay <= 0) return true;
        await sleep(delay, newAbortController.signal);
        return !newAbortController.signal.aborted;
    };
};
export const delayHide = (delay)=>{
    let abortController = new AbortController();
    return async ({ event })=>{
        abortController.abort();
        const newAbortController = new AbortController();
        abortController = newAbortController;
        if (event.type !== 'mouseleave') return true;
        if (delay <= 0) return true;
        await sleep(delay, newAbortController.signal);
        return !newAbortController.signal.aborted;
    };
};
