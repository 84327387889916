import { assertExists, Slot } from '@blocksuite/global/utils';
import { autoUpdate, computePosition } from '@floating-ui/dom';
import { cssVar } from '@toeverything/theme';
import { render } from 'lit';
export function createSimplePortal({ template, container = document.body, signal = new AbortController().signal, renderOptions, shadowDom = true, identifyWrapper = true }) {
    const portalRoot = document.createElement('div');
    if (identifyWrapper) {
        portalRoot.classList.add('blocksuite-portal');
    }
    if (shadowDom) {
        portalRoot.attachShadow({
            mode: 'open',
            ...typeof shadowDom !== 'boolean' ? shadowDom : {}
        });
    }
    signal.addEventListener('abort', ()=>{
        portalRoot.remove();
    });
    const root = shadowDom ? portalRoot.shadowRoot : portalRoot;
    assertExists(root);
    let updateId = 0;
    const updatePortal = (id)=>{
        if (id !== updateId) {
            console.warn('Potentially infinite recursion! Please clean up the old event listeners before `updatePortal`');
            return;
        }
        updateId++;
        const curId = updateId;
        const templateResult = template instanceof Function ? template({
            updatePortal: ()=>updatePortal(curId)
        }) : template;
        assertExists(templateResult);
        render(templateResult, root, renderOptions);
    };
    updatePortal(updateId);
    container.append(portalRoot);
    portalRoot.style.pointerEvents = 'auto';
    return portalRoot;
}
function isElementVisible(el) {
    if (el.checkVisibility) {
        return el.checkVisibility();
    }
    if (!el.isConnected) return false;
    if (el instanceof HTMLElement) {
        return !(el.offsetParent === null);
    }
    return true;
}
export function createLitPortal({ computePosition: positionConfigOrFn, abortController, closeOnClickAway = false, positionStrategy = 'absolute', ...portalOptions }) {
    let positionSlot = new Slot();
    const template = portalOptions.template;
    const templateWithPosition = template instanceof Function ? ({ updatePortal })=>{
        positionSlot = new Slot();
        return template({
            updatePortal,
            positionSlot
        });
    } : template;
    const portalRoot = createSimplePortal({
        ...portalOptions,
        signal: abortController.signal,
        template: templateWithPosition
    });
    if (closeOnClickAway) {
        setTimeout(()=>document.addEventListener('click', (e)=>{
                if (portalRoot.contains(e.target)) return;
                abortController.abort();
            }, {
                signal: abortController.signal
            }));
    }
    if (!positionConfigOrFn) {
        return portalRoot;
    }
    const visibility = portalRoot.style.visibility;
    portalRoot.style.visibility = 'hidden';
    portalRoot.style.position = positionStrategy;
    portalRoot.style.left = '0';
    portalRoot.style.top = '0';
    portalRoot.style.zIndex = cssVar('zIndexPopover');
    Object.assign(portalRoot.style, portalOptions.portalStyles);
    const computePositionOptions = positionConfigOrFn instanceof Function ? positionConfigOrFn(portalRoot) : positionConfigOrFn;
    const { referenceElement, ...options } = computePositionOptions;
    assertExists(referenceElement, 'referenceElement is required');
    const update = ()=>{
        if (computePositionOptions.abortWhenRefRemoved !== false && referenceElement instanceof Element && !isElementVisible(referenceElement)) {
            abortController.abort();
        }
        computePosition(referenceElement, portalRoot, {
            strategy: positionStrategy,
            ...options
        }).then((positionReturn)=>{
            const { x, y } = positionReturn;
            portalRoot.style.left = `${x}px`;
            portalRoot.style.top = `${y}px`;
            if (portalRoot.style.visibility === 'hidden') {
                portalRoot.style.visibility = visibility;
            }
            positionSlot.emit(positionReturn);
        }).catch(console.error);
    };
    if (!computePositionOptions.autoUpdate) {
        update();
    } else {
        const autoUpdateOptions = computePositionOptions.autoUpdate === true ? {} : computePositionOptions.autoUpdate;
        const cleanup = autoUpdate(referenceElement, portalRoot, update, autoUpdateOptions);
        abortController.signal.addEventListener('abort', ()=>{
            cleanup();
        });
    }
    return portalRoot;
}
