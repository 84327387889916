import { SpecProvider } from '@blocksuite/affine/blocks';
import { AIChatBlockSpec } from '@blocksuite/affine/presets';
import { getFontConfigExtension } from './font-extension';
const CustomSpecs = [
    AIChatBlockSpec,
    getFontConfigExtension()
].flat();
function patchPreviewSpec(id, specs) {
    const specProvider = SpecProvider.getInstance();
    specProvider.extendSpec(id, specs);
}
export function effects() {
    patchPreviewSpec('edgeless:preview', CustomSpecs);
}
