import { asyncScheduler, defer, exhaustMap, finalize, scheduled, Subject, throttle } from 'rxjs';
export function exhaustMapWithTrailing(project) {
    return (source$)=>defer(()=>{
            const release$ = new Subject();
            return source$.pipe(throttle(()=>release$, {
                leading: true,
                trailing: true
            }), exhaustMap((value, index)=>scheduled(project(value, index), asyncScheduler).pipe(finalize(()=>{
                    release$.next();
                }))));
        });
}
