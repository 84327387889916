import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { AllWorkspaceModals } from '../../components/providers/modal-provider';
export const RootWrapper = ()=>{
    return _jsxs(_Fragment, {
        children: [
            _jsx(AllWorkspaceModals, {}),
            _jsx(Outlet, {})
        ]
    });
};
