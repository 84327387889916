import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@affine/component';
import { useMount } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useCallback, useEffect, useState } from 'react';
export const useSelectDialog = function useSelectDialog(Component, debugKey) {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState();
    const onOpenChanged = useCallback((open)=>{
        if (!open) setValue(undefined);
        setShow(open);
    }, []);
    const close = useCallback(()=>setShow(false), []);
    const open = useCallback((ids)=>{
        return new Promise((resolve)=>{
            setShow(true);
            setValue({
                init: ids,
                onConfirm: (list)=>{
                    close();
                    resolve(list);
                }
            });
        });
    }, [
        close
    ]);
    const { mount } = useMount(debugKey);
    useEffect(()=>{
        return mount(_jsx(Modal, {
            open: show,
            onOpenChange: onOpenChanged,
            withoutCloseButton: true,
            width: "calc(100% - 32px)",
            height: "80%",
            contentOptions: {
                style: {
                    padding: 0,
                    maxWidth: 976,
                    background: cssVar('backgroundPrimaryColor')
                }
            },
            children: value ? _jsx(Component, {
                init: value.init,
                onCancel: close,
                onConfirm: value.onConfirm
            }) : null
        }));
    }, [
        Component,
        close,
        mount,
        onOpenChanged,
        show,
        value
    ]);
    return open;
};
