function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init_edgeless, _init_elements, _init_strokeColorButton, _initProto;
import { AddTextIcon, ConnectorCWithArrowIcon, ConnectorEndpointNoneIcon, ConnectorLWithArrowIcon, ConnectorXWithArrowIcon, FlipDirectionIcon, FrontEndpointArrowIcon, FrontEndpointCircleIcon, FrontEndpointDiamondIcon, FrontEndpointTriangleIcon, GeneralStyleIcon, RearEndpointArrowIcon, RearEndpointCircleIcon, RearEndpointDiamondIcon, RearEndpointTriangleIcon, ScribbledStyleIcon, SmallArrowDownIcon } from '@blocksuite/affine-components/icons';
import { renderToolbarSeparator } from '@blocksuite/affine-components/toolbar';
import { ConnectorEndpoint, ConnectorMode, DEFAULT_FRONT_END_POINT_STYLE, DEFAULT_REAR_END_POINT_STYLE, PointStyle } from '@blocksuite/affine-model';
import { LINE_COLORS, LineWidth, StrokeStyle } from '@blocksuite/affine-model';
import { countBy, maxBy, WithDisposable } from '@blocksuite/global/utils';
import { html, LitElement, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { when } from 'lit/directives/when.js';
import { packColor, packColorsWithColorScheme } from '../../edgeless/components/color-picker/utils.js';
import { GET_DEFAULT_LINE_COLOR } from '../../edgeless/components/panel/color-panel.js';
import { LineStylesPanel } from '../../edgeless/components/panel/line-styles-panel.js';
import { mountConnectorLabelEditor } from '../../edgeless/utils/text.js';
function getMostCommonColor(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        return typeof ele.stroke === 'object' ? ele.stroke[colorScheme] ?? ele.stroke.normal ?? null : ele.stroke;
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonMode(elements) {
    const modes = countBy(elements, (ele)=>ele.mode);
    const max = maxBy(Object.entries(modes), ([_k, count])=>count);
    return max ? Number(max[0]) : null;
}
function getMostCommonLineWidth(elements) {
    const sizes = countBy(elements, (ele)=>ele.strokeWidth);
    const max = maxBy(Object.entries(sizes), ([_k, count])=>count);
    return max ? Number(max[0]) : LineWidth.Four;
}
export function getMostCommonLineStyle(elements) {
    const sizes = countBy(elements, (ele)=>ele.strokeStyle);
    const max = maxBy(Object.entries(sizes), ([_k, count])=>count);
    return max ? max[0] : null;
}
function getMostCommonRough(elements) {
    const { trueCount, falseCount } = elements.reduce((counts, ele)=>{
        if (ele.rough) {
            counts.trueCount++;
        } else {
            counts.falseCount++;
        }
        return counts;
    }, {
        trueCount: 0,
        falseCount: 0
    });
    return trueCount > falseCount;
}
function getMostCommonEndpointStyle(elements, endpoint) {
    const field = endpoint === ConnectorEndpoint.Front ? 'frontEndpointStyle' : 'rearEndpointStyle';
    const modes = countBy(elements, (ele)=>ele[field]);
    const max = maxBy(Object.entries(modes), ([_k, count])=>count);
    return max ? max[0] : null;
}
function notEqual(key, value) {
    return (element)=>element[key] !== value;
}
const STYLE_LIST = [
    {
        name: 'General',
        value: false,
        icon: GeneralStyleIcon
    },
    {
        name: 'Scribbled',
        value: true,
        icon: ScribbledStyleIcon
    }
];
const STYLE_CHOOSE = [
    [
        false,
        ()=>GeneralStyleIcon
    ],
    [
        true,
        ()=>ScribbledStyleIcon
    ]
];
const FRONT_ENDPOINT_STYLE_LIST = [
    {
        value: PointStyle.None,
        icon: ConnectorEndpointNoneIcon
    },
    {
        value: PointStyle.Arrow,
        icon: FrontEndpointArrowIcon
    },
    {
        value: PointStyle.Triangle,
        icon: FrontEndpointTriangleIcon
    },
    {
        value: PointStyle.Circle,
        icon: FrontEndpointCircleIcon
    },
    {
        value: PointStyle.Diamond,
        icon: FrontEndpointDiamondIcon
    }
];
const REAR_ENDPOINT_STYLE_LIST = [
    {
        value: PointStyle.Diamond,
        icon: RearEndpointDiamondIcon
    },
    {
        value: PointStyle.Circle,
        icon: RearEndpointCircleIcon
    },
    {
        value: PointStyle.Triangle,
        icon: RearEndpointTriangleIcon
    },
    {
        value: PointStyle.Arrow,
        icon: RearEndpointArrowIcon
    },
    {
        value: PointStyle.None,
        icon: ConnectorEndpointNoneIcon
    }
];
const MODE_LIST = [
    {
        name: 'Curve',
        icon: ConnectorCWithArrowIcon,
        value: ConnectorMode.Curve
    },
    {
        name: 'Elbowed',
        icon: ConnectorXWithArrowIcon,
        value: ConnectorMode.Orthogonal
    },
    {
        name: 'Straight',
        icon: ConnectorLWithArrowIcon,
        value: ConnectorMode.Straight
    }
];
const MODE_CHOOSE = [
    [
        ConnectorMode.Curve,
        ()=>ConnectorCWithArrowIcon
    ],
    [
        ConnectorMode.Orthogonal,
        ()=>ConnectorXWithArrowIcon
    ],
    [
        ConnectorMode.Straight,
        ()=>ConnectorLWithArrowIcon
    ]
];
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = query('edgeless-color-picker-button.stroke-color');
export class EdgelessChangeConnectorButton extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_edgeless, _init_elements, _init_strokeColorButton, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "edgeless"
            ],
            [
                _dec1,
                1,
                "elements"
            ],
            [
                _dec2,
                1,
                "strokeColorButton"
            ]
        ], []));
    }
    get doc() {
        return this.edgeless.doc;
    }
    get service() {
        return this.edgeless.service;
    }
    _addLabel() {
        mountConnectorLabelEditor(this.elements[0], this.edgeless);
    }
    _flipEndpointStyle(frontEndpointStyle, rearEndpointStyle) {
        if (frontEndpointStyle === rearEndpointStyle) return;
        this.elements.forEach((element)=>this.service.updateElement(element.id, {
                frontEndpointStyle: rearEndpointStyle,
                rearEndpointStyle: frontEndpointStyle
            }));
    }
    _getEndpointIcon(list, style) {
        return list.find(({ value })=>value === style)?.icon || ConnectorEndpointNoneIcon;
    }
    _setConnectorColor(stroke) {
        this._setConnectorProp('stroke', stroke);
    }
    _setConnectorMode(mode) {
        this._setConnectorProp('mode', mode);
    }
    _setConnectorPointStyle(end, style) {
        const props = {
            [end === ConnectorEndpoint.Front ? 'frontEndpointStyle' : 'rearEndpointStyle']: style
        };
        this.elements.forEach((element)=>this.service.updateElement(element.id, {
                ...props
            }));
    }
    _setConnectorProp(key, value) {
        this.doc.captureSync();
        this.elements.filter(notEqual(key, value)).forEach((element)=>this.service.updateElement(element.id, {
                [key]: value
            }));
    }
    _setConnectorRough(rough) {
        this._setConnectorProp('rough', rough);
    }
    _setConnectorStroke({ type, value }) {
        if (type === 'size') {
            this._setConnectorStrokeWidth(value);
            return;
        }
        this._setConnectorStrokeStyle(value);
    }
    _setConnectorStrokeStyle(strokeStyle) {
        this._setConnectorProp('strokeStyle', strokeStyle);
    }
    _setConnectorStrokeWidth(strokeWidth) {
        this._setConnectorProp('strokeWidth', strokeWidth);
    }
    _showAddButtonOrTextMenu() {
        if (this.elements.length === 1 && !this.elements[0].text) {
            return 'button';
        }
        if (!this.elements.some((e)=>!e.text)) {
            return 'menu';
        }
        return 'nothing';
    }
    render() {
        const colorScheme = this.edgeless.surface.renderer.getColorScheme();
        const elements = this.elements;
        const selectedColor = getMostCommonColor(elements, colorScheme) ?? GET_DEFAULT_LINE_COLOR();
        const selectedMode = getMostCommonMode(elements);
        const selectedLineSize = getMostCommonLineWidth(elements) ?? LineWidth.Four;
        const selectedRough = getMostCommonRough(elements);
        const selectedLineStyle = getMostCommonLineStyle(elements) ?? StrokeStyle.Solid;
        const selectedStartPointStyle = getMostCommonEndpointStyle(elements, ConnectorEndpoint.Front) ?? DEFAULT_FRONT_END_POINT_STYLE;
        const selectedEndPointStyle = getMostCommonEndpointStyle(elements, ConnectorEndpoint.Rear) ?? DEFAULT_REAR_END_POINT_STYLE;
        return join([
            when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
                const { type, colors } = packColorsWithColorScheme(colorScheme, selectedColor, elements[0].stroke);
                return html`
              <edgeless-color-picker-button
                class="stroke-color"
                .label=${'Stroke style'}
                .pick=${this.pickColor}
                .color=${selectedColor}
                .colors=${colors}
                .colorType=${type}
                .palettes=${LINE_COLORS}
                .hollowCircle=${true}
              >
                <div
                  slot="other"
                  class="line-styles"
                  style=${styleMap({
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    alignItems: 'center'
                })}
                >
                  ${LineStylesPanel({
                    selectedLineSize: selectedLineSize,
                    selectedLineStyle: selectedLineStyle,
                    onClick: (e)=>this._setConnectorStroke(e),
                    lineStyles: [
                        StrokeStyle.Solid,
                        StrokeStyle.Dash
                    ]
                })}
                </div>
                <editor-toolbar-separator
                  slot="separator"
                  data-orientation="horizontal"
                ></editor-toolbar-separator>
              </edgeless-color-picker-button>
            `;
            }, ()=>html`
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Stroke style"
                  .tooltip=${'Stroke style'}
                >
                  <edgeless-color-button
                    .color=${selectedColor}
                  ></edgeless-color-button>
                </editor-icon-button>
              `}
            >
              <stroke-style-panel
                .strokeWidth=${selectedLineSize}
                .strokeStyle=${selectedLineStyle}
                .strokeColor=${selectedColor}
                .setStrokeStyle=${(e)=>this._setConnectorStroke(e)}
                .setStrokeColor=${(e)=>this._setConnectorColor(e.detail)}
              >
              </stroke-style-panel>
            </editor-menu-button>
          `),
            html`
          <editor-menu-button
            .button=${html`
              <editor-icon-button aria-label="Style" .tooltip=${'Style'}>
                ${choose(selectedRough, STYLE_CHOOSE)}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <div>
              ${repeat(STYLE_LIST, (item)=>item.name, ({ name, value, icon })=>html`
                  <editor-icon-button
                    aria-label=${name}
                    .tooltip=${name}
                    .active=${selectedRough === value}
                    .activeMode=${'background'}
                    @click=${()=>this._setConnectorRough(value)}
                  >
                    ${icon}
                  </editor-icon-button>
                `)}
            </div>
          </editor-menu-button>
        `,
            html`
          <editor-menu-button
            .button=${html`
              <editor-icon-button
                aria-label="Start point style"
                .tooltip=${'Start point style'}
              >
                ${this._getEndpointIcon(FRONT_ENDPOINT_STYLE_LIST, selectedStartPointStyle)}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <div>
              ${repeat(FRONT_ENDPOINT_STYLE_LIST, (item)=>item.value, ({ value, icon })=>html`
                  <editor-icon-button
                    aria-label=${value}
                    .tooltip=${value}
                    .active=${selectedStartPointStyle === value}
                    .activeMode=${'background'}
                    @click=${()=>this._setConnectorPointStyle(ConnectorEndpoint.Front, value)}
                  >
                    ${icon}
                  </editor-icon-button>
                `)}
            </div>
          </editor-menu-button>

          <editor-icon-button
            aria-label="Flip direction"
            .tooltip=${'Flip direction'}
            .disabled=${false}
            @click=${()=>this._flipEndpointStyle(selectedStartPointStyle, selectedEndPointStyle)}
          >
            ${FlipDirectionIcon}
          </editor-icon-button>

          <editor-menu-button
            .button=${html`
              <editor-icon-button
                aria-label="End point style"
                .tooltip=${'End point style'}
              >
                ${this._getEndpointIcon(REAR_ENDPOINT_STYLE_LIST, selectedEndPointStyle)}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <div>
              ${repeat(REAR_ENDPOINT_STYLE_LIST, (item)=>item.value, ({ value, icon })=>html`
                  <editor-icon-button
                    aria-label=${value}
                    .tooltip=${value}
                    .active=${selectedEndPointStyle === value}
                    .activeMode=${'background'}
                    @click=${()=>this._setConnectorPointStyle(ConnectorEndpoint.Rear, value)}
                  >
                    ${icon}
                  </editor-icon-button>
                `)}
            </div>
          </editor-menu-button>

          <editor-menu-button
            .button=${html`
              <editor-icon-button
                aria-label="Shape"
                .tooltip=${'Connector shape'}
              >
                ${choose(selectedMode, MODE_CHOOSE)}${SmallArrowDownIcon}
              </editor-icon-button>
            `}
          >
            <div>
              ${repeat(MODE_LIST, (item)=>item.name, ({ name, value, icon })=>html`
                  <editor-icon-button
                    aria-label=${name}
                    .tooltip=${name}
                    .active=${selectedMode === value}
                    .activeMode=${'background'}
                    @click=${()=>this._setConnectorMode(value)}
                  >
                    ${icon}
                  </editor-icon-button>
                `)}
            </div>
          </editor-menu-button>
        `,
            choose(this._showAddButtonOrTextMenu(), [
                [
                    'button',
                    ()=>html`
                <editor-icon-button
                  aria-label="Add text"
                  .tooltip=${'Add text'}
                  @click=${this._addLabel}
                >
                  ${AddTextIcon}
                </editor-icon-button>
              `
                ],
                [
                    'menu',
                    ()=>html`
                <edgeless-change-text-menu
                  .elementType=${'connector'}
                  .elements=${this.elements}
                  .edgeless=${this.edgeless}
                ></edgeless-change-text-menu>
              `
                ],
                [
                    'nothing',
                    ()=>nothing
                ]
            ])
        ].filter((button)=>button !== nothing), renderToolbarSeparator);
    }
    #___private_edgeless_1;
    get edgeless() {
        return this.#___private_edgeless_1;
    }
    set edgeless(_v) {
        this.#___private_edgeless_1 = _v;
    }
    #___private_elements_2;
    get elements() {
        return this.#___private_elements_2;
    }
    set elements(_v) {
        this.#___private_elements_2 = _v;
    }
    #___private_strokeColorButton_3;
    get strokeColorButton() {
        return this.#___private_strokeColorButton_3;
    }
    set strokeColorButton(_v) {
        this.#___private_strokeColorButton_3 = _v;
    }
    constructor(...args){
        super(...args), this.pickColor = (event)=>{
            if (event.type === 'pick') {
                this.elements.forEach((ele)=>this.service.updateElement(ele.id, packColor('stroke', {
                        ...event.detail
                    })));
                return;
            }
            this.elements.forEach((ele)=>ele[event.type === 'start' ? 'stash' : 'pop']('stroke'));
        }, this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this)), this.#___private_elements_2 = _init_elements(this, []), this.#___private_strokeColorButton_3 = _init_strokeColorButton(this);
    }
}
export function renderConnectorButton(edgeless, elements) {
    if (!elements?.length) return nothing;
    return html`
    <edgeless-change-connector-button
      .elements=${elements}
      .edgeless=${edgeless}
    >
    </edgeless-change-connector-button>
  `;
}
