import { DebugLogger } from '@affine/debug';
import { setupGlobal } from '@affine/env/global';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomEffect } from 'jotai-effect';
setupGlobal();
const logger = new DebugLogger('affine:settings');
export const windowFrameStyleOptions = [
    'frameless',
    'NativeTitleBar'
];
const appSettingBaseAtom = atomWithStorage('affine-settings', {
    clientBorder: BUILD_CONFIG.isElectron && !environment.isWindows,
    windowFrameStyle: 'frameless',
    enableBlurBackground: true,
    enableNoisyBackground: true,
    autoCheckUpdate: true,
    autoDownloadUpdate: true,
    enableTelemetry: true
});
const appSettingEffect = atomEffect((get)=>{
    const settings = get(appSettingBaseAtom);
    if (BUILD_CONFIG.isElectron) {
        logger.debug('sync settings to electron', settings);
        window.__apis?.updater.setConfig({
            autoCheckUpdate: settings.autoCheckUpdate,
            autoDownloadUpdate: settings.autoDownloadUpdate
        }).catch((err)=>{
            console.error(err);
        });
    }
});
export const appSettingAtom = atom((get)=>{
    get(appSettingEffect);
    return get(appSettingBaseAtom);
}, (_get, set, apply)=>{
    set(appSettingBaseAtom, (prev)=>{
        const next = typeof apply === 'function' ? apply(prev) : apply;
        return {
            ...prev,
            ...next
        };
    });
});
