import { LiveData, Service } from '@toeverything/infra';
import { Observable } from 'rxjs';
import { FeedInfoModal } from '../entities/feed-info-modal';
import { FeedSearchModal } from '../entities/feed-search-modal';
import { FeedTree } from '../entities/feed-tree';
const SETTING_KEY = 'feedsSetting';
const CURSOR_KEY = 'cursor';
export class FeedsService extends Service {
    constructor(workspaceService){
        super(), this.workspaceService = workspaceService, this.feedTree = this.framework.createEntity(FeedTree), this.searchModal = this.framework.createEntity(FeedSearchModal), this.infoModal = this.framework.createEntity(FeedInfoModal), this.cursor$ = LiveData.from(new Observable((subscriber)=>{
            subscriber.next(this.cursor || '0');
            const fn = ()=>{
                subscriber.next(this.cursor || '0');
            };
            this.setting.observeDeep(fn);
            return ()=>{
                this.setting.unobserveDeep(fn);
            };
        }), '0');
    }
    get setting() {
        return this.workspaceService.workspace.docCollection.doc.getMap(SETTING_KEY);
    }
    get cursor() {
        return this.setting.get(CURSOR_KEY);
    }
    updateCursor(cursor) {
        this.setting.set(CURSOR_KEY, cursor);
    }
    feedById$(feedId) {
        return LiveData.from(this.feedTree.rootFolder.nodesStore.watchNodeInfo(feedId), null);
    }
}
