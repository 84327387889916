function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _init_controller, _initProto;
import { ShadowlessElement } from '@blocksuite/block-std';
import { WithDisposable } from '@blocksuite/global/utils';
import { effect } from '@preact/signals-core';
import { css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { startDrag } from '../../../core/utils/drag.js';
import { autoScrollOnBoundary } from '../../../core/utils/frame-loop.js';
import { RowWithGroup, TableAreaSelection, TableRowSelection } from '../types.js';
import { DragToFillElement, fillSelectionWithFocusCellData } from './drag-to-fill.js';
export class TableSelectionController {
    get areaSelectionElement() {
        return this.__selectionElement.selectionRef.value;
    }
    get dragToFillDraggable() {
        return this.__dragToFillElement.dragToFillRef.value;
    }
    get focusSelectionElement() {
        return this.__selectionElement.focusRef.value;
    }
    get selection() {
        return this._tableViewSelection;
    }
    set selection(data) {
        if (!data) {
            this.clearSelection();
            return;
        }
        const selection = {
            ...data,
            viewId: this.view.id,
            type: 'table'
        };
        if (selection.selectionType === 'area' && selection.isEditing) {
            const focus = selection.focus;
            const container = this.getCellContainer(selection.groupKey, focus.rowIndex, focus.columnIndex);
            const cell = container?.cell;
            const isEditing = cell ? cell.beforeEnterEditMode() : true;
            this.host.props.setSelection({
                ...selection,
                isEditing
            });
        } else {
            this.host.props.setSelection(selection);
        }
    }
    get tableContainer() {
        return this.host.querySelector('.affine-database-table-container');
    }
    get view() {
        return this.host.props.view;
    }
    get viewData() {
        return this.view;
    }
    constructor(host){
        this.host = host;
        this.getFocusCellContainer = ()=>{
            if (!this._tableViewSelection || this._tableViewSelection.selectionType !== 'area') return null;
            const { groupKey, focus } = this._tableViewSelection;
            const dragStartCell = this.getCellContainer(groupKey, focus.rowIndex, focus.columnIndex);
            return dragStartCell ?? null;
        };
        this.__dragToFillElement = new DragToFillElement();
        this.selectionStyleUpdateTask = 0;
        host.addController(this);
        this.__selectionElement = new SelectionElement();
        this.__selectionElement.controller = this;
    }
    clearSelection() {
        this.host.props.setSelection();
    }
    handleDragEvent() {
        this.host.disposables.add(this.host.props.handleEvent('dragStart', (context)=>{
            if (this.host.props.view.readonly$.value) {
                return;
            }
            const event = context.get('pointerState').raw;
            const target = event.target;
            if (target instanceof HTMLElement) {
                const [cell, fillValues] = this.resolveDragStartTarget(target);
                if (cell) {
                    const selection = this.selection;
                    if (selection && selection.selectionType === 'area' && selection.isEditing && selection.focus.rowIndex === cell.rowIndex && selection.focus.columnIndex === cell.columnIndex) {
                        return false;
                    }
                    this.startDrag(event, cell, fillValues);
                    event.preventDefault();
                    return true;
                }
                return false;
            }
            return false;
        }));
    }
    handleSelectionChange() {
        this.host.disposables.add(this.host.props.selection$.subscribe((tableSelection)=>{
            if (!this.isValidSelection(tableSelection)) {
                this.selection = undefined;
                return;
            }
            const old = this._tableViewSelection?.selectionType === 'area' ? this._tableViewSelection : undefined;
            const newSelection = tableSelection?.selectionType === 'area' ? tableSelection : undefined;
            if (old?.focus.rowIndex !== newSelection?.focus.rowIndex || old?.focus.columnIndex !== newSelection?.focus.columnIndex) {
                requestAnimationFrame(()=>{
                    this.scrollToFocus();
                });
            }
            if (this.isRowSelection() && (old?.rowsSelection?.start !== newSelection?.rowsSelection?.start || old?.rowsSelection?.end !== newSelection?.rowsSelection?.end)) {
                requestAnimationFrame(()=>{
                    this.scrollToAreaSelection();
                });
            }
            if (old) {
                const container = this.getCellContainer(old.groupKey, old.focus.rowIndex, old.focus.columnIndex);
                if (container) {
                    const cell = container.cell;
                    if (old.isEditing) {
                        requestAnimationFrame(()=>{
                            cell?.onExitEditMode();
                        });
                        cell?.blurCell();
                        container.isEditing = false;
                    }
                }
            }
            this._tableViewSelection = tableSelection;
            if (newSelection) {
                const container = this.getCellContainer(newSelection.groupKey, newSelection.focus.rowIndex, newSelection.focus.columnIndex);
                if (container) {
                    const cell = container.cell;
                    if (newSelection.isEditing) {
                        cell?.onEnterEditMode();
                        container.isEditing = true;
                        cell?.focusCell();
                    }
                }
            }
        }));
    }
    insertTo(groupKey, rowId, before) {
        const id = this.view.rowAdd({
            before,
            id: rowId
        });
        if (groupKey != null) {
            this.view.groupManager.moveCardTo(id, undefined, groupKey, {
                before,
                id: rowId
            });
        }
        const rows = groupKey != null ? this.view.groupManager.groupDataMap$.value?.[groupKey].rows : this.view.rows$.value;
        requestAnimationFrame(()=>{
            const index = this.host.props.view.properties$.value.findIndex((v)=>v.type$.value === 'title');
            this.selection = TableAreaSelection.create({
                groupKey: groupKey,
                focus: {
                    rowIndex: rows?.findIndex((v)=>v === id) ?? 0,
                    columnIndex: index
                },
                isEditing: true
            });
        });
    }
    resolveDragStartTarget(target) {
        let cell;
        const fillValues = !!target.dataset.dragToFill;
        if (fillValues) {
            const focusCellContainer = this.getFocusCellContainer();
            cell = focusCellContainer ?? null;
        } else {
            cell = target.closest('affine-database-cell-container');
        }
        return [
            cell,
            fillValues
        ];
    }
    scrollToAreaSelection() {
        this.areaSelectionElement?.scrollIntoView({
            block: 'nearest',
            inline: 'nearest'
        });
    }
    scrollToFocus() {
        this.focusSelectionElement?.scrollIntoView({
            block: 'nearest',
            inline: 'nearest'
        });
    }
    areaToRows(selection) {
        const rows = this.rows(selection.groupKey) ?? [];
        const ids = Array.from({
            length: selection.rowsSelection.end - selection.rowsSelection.start + 1
        }).map((_, index)=>index + selection.rowsSelection.start).map((row)=>rows[row]?.rowId);
        return ids.map((id)=>({
                id,
                groupKey: selection.groupKey
            }));
    }
    cellPosition(groupKey) {
        const rows = this.rows(groupKey);
        const cells = rows?.item(0).querySelectorAll('affine-database-cell-container');
        return (x1, x2, y1, y2)=>{
            const rowOffsets = Array.from(rows ?? []).map((v)=>v.getBoundingClientRect().top);
            const columnOffsets = Array.from(cells ?? []).map((v)=>v.getBoundingClientRect().left);
            const [startX, endX] = x1 < x2 ? [
                x1,
                x2
            ] : [
                x2,
                x1
            ];
            const [startY, endY] = y1 < y2 ? [
                y1,
                y2
            ] : [
                y2,
                y1
            ];
            const row = {
                start: 0,
                end: 0
            };
            const column = {
                start: 0,
                end: 0
            };
            for(let i = 0; i < rowOffsets.length; i++){
                const offset = rowOffsets[i];
                if (offset < startY) {
                    row.start = i;
                }
                if (offset < endY) {
                    row.end = i;
                }
            }
            for(let i = 0; i < columnOffsets.length; i++){
                const offset = columnOffsets[i];
                if (offset < startX) {
                    column.start = i;
                }
                if (offset < endX) {
                    column.end = i;
                }
            }
            return {
                row,
                column
            };
        };
    }
    deleteRow(rowId) {
        this.view.rowDelete([
            rowId
        ]);
        this.focusToCell('up');
    }
    focusFirstCell() {
        this.selection = TableAreaSelection.create({
            focus: {
                rowIndex: 0,
                columnIndex: 0
            },
            isEditing: false
        });
    }
    focusToArea(selection) {
        return {
            ...selection,
            rowsSelection: selection.rowsSelection ?? {
                start: selection.focus.rowIndex,
                end: selection.focus.rowIndex
            },
            columnsSelection: selection.columnsSelection ?? {
                start: selection.focus.columnIndex,
                end: selection.focus.columnIndex
            },
            isEditing: false
        };
    }
    focusToCell(position) {
        if (!this.selection || this.selection.selectionType !== 'area') {
            return;
        }
        const cell = this.getCellContainer(this.selection.groupKey, this.selection.focus.rowIndex, this.selection.focus.columnIndex);
        if (!cell) {
            return;
        }
        const row = cell.closest('data-view-table-row');
        const rows = Array.from(row?.closest('.affine-database-table-container')?.querySelectorAll('data-view-table-row') ?? []);
        const cells = Array.from(row?.querySelectorAll('affine-database-cell-container') ?? []);
        if (!row || !rows || !cells) {
            return;
        }
        let rowIndex = rows.indexOf(row);
        let columnIndex = cells.indexOf(cell);
        if (position === 'left') {
            if (columnIndex === 0) {
                columnIndex = cells.length - 1;
                rowIndex--;
            } else {
                columnIndex--;
            }
        }
        if (position === 'right') {
            if (columnIndex === cells.length - 1) {
                columnIndex = 0;
                rowIndex++;
            } else {
                columnIndex++;
            }
        }
        if (position === 'up') {
            if (rowIndex === 0) {} else {
                rowIndex--;
            }
        }
        if (position === 'down') {
            if (rowIndex === rows.length - 1) {} else {
                rowIndex++;
            }
        }
        rows[rowIndex]?.querySelectorAll('affine-database-cell-container')?.item(columnIndex)?.selectCurrentCell(false);
    }
    getCellContainer(groupKey, rowIndex, columnIndex) {
        const row = this.rows(groupKey)?.item(rowIndex);
        return row?.querySelectorAll('affine-database-cell-container').item(columnIndex);
    }
    getGroup(groupKey) {
        const container = groupKey != null ? this.tableContainer?.querySelector(`affine-data-view-table-group[data-group-key="${groupKey}"]`) : this.tableContainer;
        return container ?? null;
    }
    getRect(groupKey, top, bottom, left, right) {
        const rows = this.rows(groupKey);
        const topRow = rows?.item(top);
        const bottomRow = rows?.item(bottom);
        if (!topRow || !bottomRow) {
            return;
        }
        const topCells = topRow.querySelectorAll('affine-database-cell-container');
        const leftCell = topCells.item(left);
        const rightCell = topCells.item(right);
        if (!leftCell || !rightCell) {
            return;
        }
        const leftRect = leftCell.getBoundingClientRect();
        const scale = leftRect.width / leftCell.column.width$.value;
        return {
            top: leftRect.top / scale,
            left: leftRect.left / scale,
            width: (rightCell.getBoundingClientRect().right - leftRect.left) / scale,
            height: (bottomRow.getBoundingClientRect().bottom - leftRect.top) / scale,
            scale
        };
    }
    getRow(groupKey, rowId) {
        return this.getGroup(groupKey)?.querySelector(`data-view-table-row[data-row-id='${rowId}']`);
    }
    getSelectionAreaBorder(position) {
        return this.__selectionElement.selectionRef.value?.querySelector(`.area-border.area-${position}`);
    }
    hostConnected() {
        requestAnimationFrame(()=>{
            this.tableContainer?.append(this.__selectionElement);
            this.tableContainer?.append(this.__dragToFillElement);
        });
        this.handleDragEvent();
        this.handleSelectionChange();
    }
    insertRowAfter(groupKey, rowId) {
        this.insertTo(groupKey, rowId, false);
    }
    insertRowBefore(groupKey, rowId) {
        this.insertTo(groupKey, rowId, true);
    }
    isRowSelection() {
        return this.selection?.selectionType === 'row';
    }
    isValidSelection(selection) {
        if (!selection || selection.selectionType === 'row') {
            return true;
        }
        if (selection.focus.rowIndex > this.view.rows$.value.length - 1) {
            this.selection = undefined;
            return false;
        }
        if (selection.focus.columnIndex > this.view.propertyIds$.value.length - 1) {
            this.selection = undefined;
            return false;
        }
        return true;
    }
    navigateRowSelection(direction, append = false) {
        if (!TableRowSelection.is(this.selection)) return;
        const rows = this.selection.rows;
        const lastRow = rows[rows.length - 1];
        const lastRowIndex = this.getGroup(lastRow.groupKey)?.querySelector(`data-view-table-row[data-row-id='${lastRow.id}']`)?.rowIndex ?? 0;
        const getRowByIndex = (index)=>{
            const tableRow = this.rows(lastRow.groupKey)?.item(index);
            if (!tableRow) {
                return;
            }
            return {
                id: tableRow.rowId,
                groupKey: lastRow.groupKey
            };
        };
        const prevRow = getRowByIndex(lastRowIndex - 1);
        const nextRow = getRowByIndex(lastRowIndex + 1);
        const includes = (row)=>{
            if (!row) {
                return false;
            }
            return rows.some((r)=>RowWithGroup.equal(r, row));
        };
        if (append) {
            const addList = [];
            const removeList = [];
            if (direction === 'up' && prevRow != null) {
                if (includes(prevRow)) {
                    removeList.push(lastRow);
                } else {
                    addList.push(prevRow);
                }
            }
            if (direction === 'down' && nextRow != null) {
                if (includes(nextRow)) {
                    removeList.push(lastRow);
                } else {
                    addList.push(nextRow);
                }
            }
            this.rowSelectionChange({
                add: addList,
                remove: removeList
            });
        } else {
            const target = direction === 'up' ? prevRow : nextRow;
            if (target != null) {
                this.selection = TableRowSelection.create({
                    rows: [
                        target
                    ]
                });
            }
        }
    }
    rows(groupKey) {
        const container = groupKey != null ? this.tableContainer?.querySelector(`affine-data-view-table-group[data-group-key="${groupKey}"]`) : this.tableContainer;
        return container?.querySelectorAll('data-view-table-row');
    }
    rowSelectionChange({ add, remove }) {
        const key = (r)=>`${r.id}.${r.groupKey ? r.groupKey : ''}`;
        const rows = new Set(TableRowSelection.rows(this.selection).map((r)=>key(r)));
        remove.forEach((row)=>rows.delete(key(row)));
        add.forEach((row)=>rows.add(key(row)));
        const result = [
            ...rows
        ].map((r)=>r.split('.')).map(([id, groupKey])=>({
                id,
                groupKey: groupKey ? groupKey : undefined
            }));
        this.selection = TableRowSelection.create({
            rows: result
        });
    }
    rowsToArea(rows) {
        let groupKey = undefined;
        let minIndex = undefined;
        let maxIndex = undefined;
        const set = new Set(rows);
        if (!this.tableContainer) return;
        for (const row of this.tableContainer?.querySelectorAll('data-view-table-row').values() ?? []){
            if (!set.has(row.rowId)) {
                continue;
            }
            minIndex = minIndex != null ? Math.min(minIndex, row.rowIndex) : row.rowIndex;
            maxIndex = maxIndex != null ? Math.max(maxIndex, row.rowIndex) : row.rowIndex;
            if (groupKey == null) {
                groupKey = row.groupKey;
            } else if (groupKey !== row.groupKey) {
                return;
            }
        }
        if (minIndex == null || maxIndex == null) {
            return;
        }
        return {
            groupKey,
            start: minIndex,
            end: maxIndex
        };
    }
    selectionAreaDown() {
        const selection = this.selection;
        if (!selection || selection.selectionType !== 'area') {
            return;
        }
        const newSelection = this.focusToArea(selection);
        if (newSelection.rowsSelection.start === newSelection.focus.rowIndex) {
            newSelection.rowsSelection.end = Math.min((this.rows(newSelection.groupKey)?.length ?? 0) - 1, newSelection.rowsSelection.end + 1);
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('bottom')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        } else {
            newSelection.rowsSelection.start += 1;
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('top')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        }
        this.selection = newSelection;
    }
    selectionAreaLeft() {
        const selection = this.selection;
        if (!selection || selection.selectionType !== 'area') {
            return;
        }
        const newSelection = this.focusToArea(selection);
        if (newSelection.columnsSelection.end === newSelection.focus.columnIndex) {
            newSelection.columnsSelection.start = Math.max(0, newSelection.columnsSelection.start - 1);
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('left')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        } else {
            newSelection.columnsSelection.end -= 1;
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('right')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        }
        this.selection = newSelection;
    }
    selectionAreaRight() {
        const selection = this.selection;
        if (!selection || selection.selectionType !== 'area') {
            return;
        }
        const newSelection = this.focusToArea(selection);
        if (newSelection.columnsSelection.start === newSelection.focus.columnIndex) {
            const max = (this.rows(newSelection.groupKey)?.item(0).querySelectorAll('affine-database-cell-container').length ?? 0) - 1;
            newSelection.columnsSelection.end = Math.min(max, newSelection.columnsSelection.end + 1);
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('right')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        } else {
            newSelection.columnsSelection.start += 1;
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('left')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        }
        this.selection = newSelection;
    }
    selectionAreaUp() {
        const selection = this.selection;
        if (!selection || selection.selectionType !== 'area') {
            return;
        }
        const newSelection = this.focusToArea(selection);
        if (newSelection.rowsSelection.end === newSelection.focus.rowIndex) {
            newSelection.rowsSelection.start = Math.max(0, newSelection.rowsSelection.start - 1);
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('top')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        } else {
            newSelection.rowsSelection.end -= 1;
            requestAnimationFrame(()=>{
                this.getSelectionAreaBorder('bottom')?.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                    behavior: 'smooth'
                });
            });
        }
        this.selection = newSelection;
    }
    startDrag(evt, cell, fillValues) {
        const groupKey = cell.closest('affine-data-view-table-group')?.group?.key;
        const table = this.tableContainer;
        const scrollContainer = table?.parentElement;
        if (!table || !scrollContainer) {
            return;
        }
        const tableRect = table.getBoundingClientRect();
        const startOffsetX = evt.x - tableRect.left;
        const startOffsetY = evt.y - tableRect.top;
        const offsetToSelection = this.cellPosition(groupKey);
        const select = (selection)=>{
            this.selection = TableAreaSelection.create({
                groupKey: groupKey,
                rowsSelection: selection.row,
                columnsSelection: selection.column,
                focus: {
                    rowIndex: cell.rowIndex,
                    columnIndex: cell.columnIndex
                },
                isEditing: false
            });
        };
        const cancelScroll = autoScrollOnBoundary(scrollContainer, {
            onScroll () {
                drag.move({
                    x: drag.last.x,
                    y: drag.last.y
                });
            }
        });
        const drag = startDrag(evt, {
            transform: (evt)=>({
                    x: evt.x,
                    y: evt.y
                }),
            onDrag: ()=>{
                if (fillValues) this.__dragToFillElement.dragging = true;
                return undefined;
            },
            onMove: ({ x, y })=>{
                if (!table) return;
                const tableRect = table.getBoundingClientRect();
                const startX = tableRect.left + startOffsetX;
                const startY = tableRect.top + startOffsetY;
                const selection = offsetToSelection(startX, x, startY, y);
                if (fillValues) selection.column = {
                    start: cell.columnIndex,
                    end: cell.columnIndex
                };
                select(selection);
                return selection;
            },
            onDrop: (selection)=>{
                if (!selection) {
                    return;
                }
                select(selection);
                if (fillValues && this.selection) {
                    this.__dragToFillElement.dragging = false;
                    fillSelectionWithFocusCellData(this.host, TableAreaSelection.create({
                        groupKey: groupKey,
                        rowsSelection: selection.row,
                        columnsSelection: selection.column,
                        focus: {
                            rowIndex: cell.rowIndex,
                            columnIndex: cell.columnIndex
                        },
                        isEditing: false
                    }));
                }
            },
            onClear: ()=>{
                cancelScroll();
            }
        });
    }
    toggleRow(rowId, groupKey) {
        const row = {
            id: rowId,
            groupKey
        };
        const isSelected = TableRowSelection.includes(this.selection, row);
        this.rowSelectionChange({
            add: isSelected ? [] : [
                row
            ],
            remove: isSelected ? [
                row
            ] : []
        });
    }
}
_dec = property({
    attribute: false
});
export class SelectionElement extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init_controller, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "controller"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .database-selection {
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
      background: var(--affine-primary-color-04);
      pointer-events: none;
      display: none;
    }

    .database-focus {
      position: absolute;
      width: 100%;
      z-index: 2;
      box-sizing: border-box;
      border: 1px solid var(--affine-primary-color);
      border-radius: 2px;
      pointer-events: none;
      display: none;
      outline: none;
    }

    .area-border {
      position: absolute;
      pointer-events: none;
    }
    .area-left {
      left: 0;
      height: 100%;
      width: 1px;
    }
    .area-right {
      right: 0;
      height: 100%;
      width: 1px;
    }
    .area-top {
      top: 0;
      width: 100%;
      height: 1px;
    }
    .area-bottom {
      bottom: 0;
      width: 100%;
      height: 1px;
    }
    @media print {
      data-view-table-selection {
        display: none;
      }
    }
  `;
    }
    get selection$() {
        return this.controller.host.props.selection$;
    }
    clearAreaStyle() {
        const div = this.selectionRef.value;
        if (!div) return;
        div.style.display = 'none';
    }
    clearFocusStyle() {
        const div = this.focusRef.value;
        const dragToFill = this.controller.dragToFillDraggable;
        if (!div || !dragToFill) return;
        div.style.display = 'none';
        dragToFill.style.display = 'none';
    }
    connectedCallback() {
        super.connectedCallback();
        this.disposables.add(effect(()=>{
            this.startUpdate(this.selection$.value);
        }));
    }
    render() {
        return html`
      <div ${ref(this.selectionRef)} class="database-selection">
        <div class="area-border area-left"></div>
        <div class="area-border area-right"></div>
        <div class="area-border area-top"></div>
        <div class="area-border area-bottom"></div>
      </div>
      <div tabindex="0" ${ref(this.focusRef)} class="database-focus"></div>
    `;
    }
    startUpdate(selection) {
        if (this.preTask) {
            cancelAnimationFrame(this.preTask);
            this.preTask = 0;
        }
        if (selection?.selectionType === 'area' && !this.controller.host.props.view.readonly$.value) {
            this.updateAreaSelectionStyle(selection.groupKey, selection.rowsSelection, selection.columnsSelection);
            const columnSelection = selection.columnsSelection;
            const rowSelection = selection.rowsSelection;
            const isSingleRowSelection = rowSelection.end - rowSelection.start === 0;
            const isSingleColumnSelection = columnSelection.end - columnSelection.start === 0;
            const isDragElemDragging = this.controller.__dragToFillElement.dragging;
            const isEditing = selection.isEditing;
            const showDragToFillHandle = !isEditing && (isDragElemDragging || isSingleRowSelection) && isSingleColumnSelection;
            this.updateFocusSelectionStyle(selection.groupKey, selection.focus, isEditing, showDragToFillHandle);
            this.preTask = requestAnimationFrame(()=>this.startUpdate(this.selection$.value));
        } else {
            this.clearFocusStyle();
            this.clearAreaStyle();
        }
    }
    updateAreaSelectionStyle(groupKey, rowSelection, columnSelection) {
        const div = this.selectionRef.value;
        if (!div) return;
        const tableContainer = this.controller.tableContainer;
        if (!tableContainer) return;
        const tableRect = tableContainer.getBoundingClientRect();
        const rect = this.controller.getRect(groupKey, rowSelection?.start ?? 0, rowSelection?.end ?? this.controller.view.rows$.value.length - 1, columnSelection?.start ?? 0, columnSelection?.end ?? this.controller.view.properties$.value.length - 1);
        if (!rect) {
            this.clearAreaStyle();
            return;
        }
        const { left, top, width, height, scale } = rect;
        div.style.left = `${left - tableRect.left / scale}px`;
        div.style.top = `${top - tableRect.top / scale}px`;
        div.style.width = `${width}px`;
        div.style.height = `${height}px`;
        div.style.display = 'block';
    }
    updateFocusSelectionStyle(groupKey, focus, isEditing, showDragToFillHandle = false) {
        const div = this.focusRef.value;
        const dragToFill = this.controller.dragToFillDraggable;
        if (!div || !dragToFill) return;
        const rows = this.controller.rows(groupKey) ?? [];
        if (rows.length <= focus.rowIndex) return;
        const rect = this.controller.getRect(groupKey, focus.rowIndex, focus.rowIndex, focus.columnIndex, focus.columnIndex);
        if (!rect) {
            this.clearFocusStyle();
            return;
        }
        const { left, top, width, height, scale } = rect;
        const tableContainer = this.controller.tableContainer;
        if (!tableContainer) return;
        const tableRect = tableContainer?.getBoundingClientRect();
        if (!tableRect) {
            this.clearFocusStyle();
            return;
        }
        const x = left - tableRect.left / scale;
        const y = top - 1 - tableRect.top / scale;
        const w = width + 1;
        const h = height + 1;
        div.style.left = `${x}px`;
        div.style.top = `${y}px`;
        div.style.width = `${w}px`;
        div.style.height = `${h}px`;
        div.style.borderColor = 'var(--affine-primary-color)';
        div.style.borderStyle = this.controller.__dragToFillElement.dragging ? 'dashed' : 'solid';
        div.style.boxShadow = isEditing ? '0px 0px 0px 2px rgba(30, 150, 235, 0.30)' : 'unset';
        div.style.display = 'block';
        dragToFill.style.left = `${x + w}px`;
        dragToFill.style.top = `${y + h}px`;
        dragToFill.style.display = showDragToFillHandle ? 'block' : 'none';
    }
    #___private_controller_1;
    get controller() {
        return this.#___private_controller_1;
    }
    set controller(_v) {
        this.#___private_controller_1 = _v;
    }
    constructor(...args){
        super(...args), this.focusRef = createRef(), this.preTask = 0, this.selectionRef = createRef(), this.#___private_controller_1 = (_initProto(this), _init_controller(this));
    }
}
