import { Entity, LiveData } from '@toeverything/infra';
import { map } from 'rxjs';
import { FeedNode } from './feed-node';
export class FeedTree extends Entity {
    constructor(folderStore){
        super(), this.folderStore = folderStore, this.rootFolder = this.framework.createEntity(FeedNode, {
            id: null
        }), this.isLoading$ = this.folderStore.watchIsLoading();
    }
    nodeById$(id) {
        return LiveData.from(this.folderStore.watchNodeInfo(id).pipe(map((info)=>{
            if (!info) {
                return null;
            }
            return this.framework.createEntity(FeedNode, {
                ...info
            });
        })), null);
    }
    folderNodeById(id) {
        const feed = this.folderStore.folderById(id);
        if (!feed) {
            return null;
        }
        return this.framework.createEntity(FeedNode, feed);
    }
    feedNodeById(id) {
        const feed = this.folderStore.feedById(id);
        if (!feed) {
            return null;
        }
        return this.framework.createEntity(FeedNode, feed);
    }
    rssNodeBySource(feedSource) {
        const feed = this.folderStore.rssNodeBySource(feedSource);
        if (!feed) {
            return null;
        }
        return this.framework.createEntity(FeedNode, feed);
    }
}
