function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _init_richText, _init_showIcon, _initProto;
import { DefaultInlineManagerExtension } from '@blocksuite/affine-components/rich-text';
import { ParseDocUrlProvider } from '@blocksuite/affine-shared/services';
import { getViewportElement, isValidUrl } from '@blocksuite/affine-shared/utils';
import { BaseCellRenderer } from '@blocksuite/data-view';
import { IS_MAC } from '@blocksuite/global/env';
import { assertExists } from '@blocksuite/global/utils';
import { effect } from '@preact/signals-core';
import { css } from 'lit';
import { property, query } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { HostContextKey } from '../../context/host-context.js';
const styles = css`
  data-view-header-area-text {
    width: 100%;
    display: flex;
  }

  data-view-header-area-text rich-text {
    pointer-events: none;
    user-select: none;
  }

  data-view-header-area-text-editing {
    width: 100%;
    display: flex;
    cursor: text;
  }

  .data-view-header-area-rich-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    outline: none;
    word-break: break-all;
    font-size: var(--data-view-cell-text-size);
    line-height: var(--data-view-cell-text-line-height);
  }

  .data-view-header-area-rich-text v-line {
    display: flex !important;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .data-view-header-area-rich-text v-line > div {
    flex-grow: 1;
  }

  .data-view-header-area-icon {
    height: max-content;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 2px;
    border-radius: 4px;
    margin-top: 2px;
    background-color: var(--affine-background-secondary-color);
  }

  .data-view-header-area-icon svg {
    width: 14px;
    height: 14px;
    fill: var(--affine-icon-color);
    color: var(--affine-icon-color);
  }
`;
_dec = query('rich-text'), _dec1 = property({
    attribute: false
});
class BaseTextCell extends BaseCellRenderer {
    static{
        ({ e: [_init_richText, _init_showIcon, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "richText"
            ],
            [
                _dec1,
                1,
                "showIcon"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get attributeRenderer() {
        return this.inlineManager?.getRenderer();
    }
    get attributesSchema() {
        return this.inlineManager?.getSchema();
    }
    get inlineEditor() {
        assertExists(this.richText);
        const inlineEditor = this.richText.inlineEditor;
        assertExists(inlineEditor);
        return inlineEditor;
    }
    get inlineManager() {
        return this.view.contextGet(HostContextKey)?.std.get(DefaultInlineManagerExtension.identifier);
    }
    get service() {
        return this.view.contextGet(HostContextKey)?.std.getService('affine:database');
    }
    get topContenteditableElement() {
        const databaseBlock = this.closest('affine-database');
        return databaseBlock?.topContenteditableElement;
    }
    renderIcon() {
        if (!this.showIcon) {
            return;
        }
        const iconColumn = this.view.mainProperties$.value.iconColumn;
        if (!iconColumn) return;
        const icon = this.view.cellValueGet(this.cell.rowId, iconColumn);
        if (!icon) return;
        return html`<div class="data-view-header-area-icon">${icon}</div>`;
    }
    #___private_richText_1;
    get richText() {
        return this.#___private_richText_1;
    }
    set richText(_v) {
        this.#___private_richText_1 = _v;
    }
    #___private_showIcon_2;
    get showIcon() {
        return this.#___private_showIcon_2;
    }
    set showIcon(_v) {
        this.#___private_showIcon_2 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_richText_1 = (_initProto(this), _init_richText(this)), this.#___private_showIcon_2 = _init_showIcon(this, false);
    }
}
export class HeaderAreaTextCell extends BaseTextCell {
    render() {
        return html`${this.renderIcon()}
      <rich-text
        .yText=${this.value}
        .attributesSchema=${this.attributesSchema}
        .attributeRenderer=${this.attributeRenderer}
        .embedChecker=${this.inlineManager?.embedChecker}
        .markdownShortcutHandler=${this.inlineManager?.markdownShortcutHandler}
        .readonly=${true}
        class="data-view-header-area-rich-text"
      ></rich-text>`;
    }
}
export class HeaderAreaTextCellEditing extends BaseTextCell {
    get std() {
        const host = this.view.contextGet(HostContextKey);
        return host?.std;
    }
    connectedCallback() {
        super.connectedCallback();
        const selectAll = (e)=>{
            if (e.key === 'a' && (IS_MAC ? e.metaKey : e.ctrlKey)) {
                e.stopPropagation();
                e.preventDefault();
                this.inlineEditor.selectAll();
            }
        };
        this.addEventListener('keydown', selectAll);
        this.disposables.add(()=>{
            this.removeEventListener('keydown', selectAll);
        });
    }
    firstUpdated(props) {
        super.firstUpdated(props);
        this.disposables.addFromEvent(this.richText, 'copy', this._onCopy);
        this.disposables.addFromEvent(this.richText, 'cut', this._onCut);
        this.disposables.addFromEvent(this.richText, 'paste', (e)=>{
            this._onPaste(e);
        });
        this.richText.updateComplete.then(()=>{
            this.inlineEditor.focusEnd();
            this.disposables.add(effect(()=>{
                const inlineRange = this.inlineEditor.inlineRange$.value;
                if (inlineRange) {
                    if (!this.isEditing) {
                        this.selectCurrentCell(true);
                    }
                } else {
                    if (this.isEditing) {
                        this.selectCurrentCell(false);
                    }
                }
            }));
        }).catch(console.error);
    }
    render() {
        return html`${this.renderIcon()}
      <rich-text
        .yText=${this.value}
        .inlineEventSource=${this.topContenteditableElement}
        .attributesSchema=${this.attributesSchema}
        .attributeRenderer=${this.attributeRenderer}
        .embedChecker=${this.inlineManager?.embedChecker}
        .markdownShortcutHandler=${this.inlineManager?.markdownShortcutHandler}
        .readonly=${this.readonly}
        .enableClipboard=${false}
        .verticalScrollContainerGetter=${()=>this.topContenteditableElement?.host ? getViewportElement(this.topContenteditableElement.host) : null}
        class="data-view-header-area-rich-text can-link-doc"
      ></rich-text>`;
    }
    constructor(...args){
        super(...args), this._onCopy = (e)=>{
            const inlineEditor = this.inlineEditor;
            assertExists(inlineEditor);
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = inlineEditor.yTextString.slice(inlineRange.index, inlineRange.index + inlineRange.length);
            e.clipboardData?.setData('text/plain', text);
            e.preventDefault();
            e.stopPropagation();
        }, this._onCut = (e)=>{
            const inlineEditor = this.inlineEditor;
            assertExists(inlineEditor);
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = inlineEditor.yTextString.slice(inlineRange.index, inlineRange.index + inlineRange.length);
            inlineEditor.deleteText(inlineRange);
            inlineEditor.setInlineRange({
                index: inlineRange.index,
                length: 0
            });
            e.clipboardData?.setData('text/plain', text);
            e.preventDefault();
            e.stopPropagation();
        }, this._onPaste = (e)=>{
            const inlineEditor = this.inlineEditor;
            assertExists(inlineEditor);
            const inlineRange = inlineEditor.getInlineRange();
            if (!inlineRange) return;
            const text = e.clipboardData?.getData('text/plain')?.replace(/\r?\n|\r/g, '\n');
            if (!text) return;
            e.preventDefault();
            e.stopPropagation();
            if (isValidUrl(text)) {
                const std = this.std;
                const result = std?.getOptional(ParseDocUrlProvider)?.parseDocUrl(text);
                if (result) {
                    const text = ' ';
                    inlineEditor.insertText(inlineRange, text, {
                        reference: {
                            type: 'LinkedPage',
                            pageId: result.docId,
                            params: {
                                blockIds: result.blockIds,
                                elementIds: result.elementIds,
                                mode: result.mode
                            }
                        }
                    });
                    inlineEditor.setInlineRange({
                        index: inlineRange.index + text.length,
                        length: 0
                    });
                } else {
                    inlineEditor.insertText(inlineRange, text, {
                        link: text
                    });
                    inlineEditor.setInlineRange({
                        index: inlineRange.index + text.length,
                        length: 0
                    });
                }
            } else {
                inlineEditor.insertText(inlineRange, text);
                inlineEditor.setInlineRange({
                    index: inlineRange.index + text.length,
                    length: 0
                });
            }
        };
    }
}
