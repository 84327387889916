import { useMemo } from 'react';
export function useDocCollectionHelper(docCollection) {
    return useMemo(()=>({
            createDoc: (pageId)=>{
                return docCollection.createDoc({
                    id: pageId
                });
            }
        }), [
        docCollection
    ]);
}
