import { jsx as _jsx } from "react/jsx-runtime";
import { DiscordIcon, GithubIcon, RedditIcon, TelegramIcon, TwitterIcon, YouTubeIcon } from './icons';
export const relatedLinks = [
    {
        icon: _jsx(GithubIcon, {}),
        title: 'GitHub',
        link: 'https://github.com/wemem/wemem'
    },
    {
        icon: _jsx(TwitterIcon, {}),
        title: 'X',
        link: 'https://twitter.com/wememai'
    },
    {
        icon: _jsx(DiscordIcon, {}),
        title: 'Discord',
        link: 'https://discord.gg/whd5mjYqVw'
    },
    {
        icon: _jsx(YouTubeIcon, {}),
        title: 'YouTube',
        link: 'https://www.youtube.com/@affinepro'
    },
    {
        icon: _jsx(TelegramIcon, {}),
        title: 'Telegram',
        link: 'https://t.me/affineworkos'
    },
    {
        icon: _jsx(RedditIcon, {}),
        title: 'Reddit',
        link: 'https://www.reddit.com/r/Affine/'
    }
];
export const readeaseRelatedLinks = [
    {
        icon: _jsx(GithubIcon, {}),
        title: 'GitHub',
        link: 'https://github.com/wemem/wemem'
    },
    {
        icon: _jsx(TwitterIcon, {}),
        title: 'X',
        link: 'https://twitter.com/wememai'
    }
];
