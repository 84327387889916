import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, ConfirmModal, Input, Switch, toast } from '@affine/component';
import { CloudSvg } from '@affine/core/components/affine/share-page-modal/cloud-svg';
import { authAtom } from '@affine/core/components/atoms';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { DebugLogger } from '@affine/debug';
import { apis } from '@affine/electron-api';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { FeatureFlagService, useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useLayoutEffect, useState } from 'react';
import { AuthService } from '../../../modules/cloud';
import { _addLocalWorkspace } from '../../../modules/workspace-engine';
import { buildShowcaseWorkspace } from '../../../utils/first-app-data';
import { CreateWorkspaceDialogService } from '../services/dialog';
import * as styles from './dialog.css';
const logger = new DebugLogger('CreateWorkspaceModal');
const NameWorkspaceContent = ({ loading, onConfirmName, ...props })=>{
    const t = useI18n();
    const [workspaceName, setWorkspaceName] = useState('');
    const featureFlagService = useService(FeatureFlagService);
    const enableLocalWorkspace = useLiveData(featureFlagService.flags.enable_local_workspace.$);
    const [enable, setEnable] = useState(!enableLocalWorkspace);
    const session = useService(AuthService).session;
    const loginStatus = useLiveData(session.status$);
    const setOpenSignIn = useSetAtom(authAtom);
    const openSignInModal = useCallback(()=>{
        setOpenSignIn((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpenSignIn
    ]);
    const onSwitchChange = useCallback((checked)=>{
        if (loginStatus !== 'authenticated') {
            return openSignInModal();
        }
        return setEnable(checked);
    }, [
        loginStatus,
        openSignInModal
    ]);
    const handleCreateWorkspace = useCallback(()=>{
        onConfirmName(workspaceName, enable ? WorkspaceFlavour.AFFINE_CLOUD : WorkspaceFlavour.LOCAL);
    }, [
        enable,
        onConfirmName,
        workspaceName
    ]);
    const onEnter = useCallback(()=>{
        if (workspaceName) {
            handleCreateWorkspace();
        }
    }, [
        handleCreateWorkspace,
        workspaceName
    ]);
    return _jsxs(ConfirmModal, {
        defaultOpen: true,
        title: t['com.affine.nameWorkspace.title'](),
        description: t['com.affine.nameWorkspace.description'](),
        cancelText: t['com.affine.nameWorkspace.button.cancel'](),
        confirmText: t['com.affine.nameWorkspace.button.create'](),
        confirmButtonOptions: {
            variant: 'primary',
            loading,
            disabled: !workspaceName,
            ['data-testid']: 'create-workspace-create-button'
        },
        closeButtonOptions: {
            ['data-testid']: 'create-workspace-close-button'
        },
        onConfirm: handleCreateWorkspace,
        ...props,
        children: [
            _jsx("div", {
                className: styles.avatarWrapper,
                children: _jsx(Avatar, {
                    size: 56,
                    name: workspaceName,
                    colorfulFallback: true
                })
            }),
            _jsxs("div", {
                className: styles.workspaceNameWrapper,
                children: [
                    _jsx("div", {
                        className: styles.subTitle,
                        children: t['com.affine.nameWorkspace.subtitle.workspace-name']()
                    }),
                    _jsx(Input, {
                        autoFocus: true,
                        "data-testid": "create-workspace-input",
                        onEnter: onEnter,
                        placeholder: t['com.affine.nameWorkspace.placeholder'](),
                        maxLength: 64,
                        minLength: 0,
                        onChange: setWorkspaceName,
                        size: "large"
                    })
                ]
            }),
            _jsxs("div", {
                className: styles.affineCloudWrapper,
                children: [
                    _jsx("div", {
                        className: styles.subTitle,
                        children: t['AFFiNE Cloud']()
                    }),
                    _jsxs("div", {
                        className: styles.card,
                        children: [
                            _jsxs("div", {
                                className: styles.cardText,
                                children: [
                                    _jsxs("div", {
                                        className: styles.cardTitle,
                                        children: [
                                            _jsx("span", {
                                                children: t['com.affine.nameWorkspace.affine-cloud.title']()
                                            }),
                                            _jsx(Switch, {
                                                checked: enable,
                                                onChange: onSwitchChange,
                                                disabled: !enableLocalWorkspace
                                            })
                                        ]
                                    }),
                                    _jsx("div", {
                                        className: styles.cardDescription,
                                        children: t['com.affine.nameWorkspace.affine-cloud.description']()
                                    })
                                ]
                            }),
                            _jsx("div", {
                                className: styles.cloudSvgContainer,
                                children: _jsx(CloudSvg, {})
                            })
                        ]
                    }),
                    !enableLocalWorkspace ? _jsx("a", {
                        className: styles.cloudTips,
                        href: BUILD_CONFIG.downloadUrl,
                        target: "_blank",
                        rel: "noreferrer",
                        children: t['com.affine.nameWorkspace.affine-cloud.web-tips']()
                    }) : null
                ]
            })
        ]
    });
};
const CreateWorkspaceDialog = ()=>{
    const createWorkspaceDialogService = useService(CreateWorkspaceDialogService);
    const mode = useLiveData(createWorkspaceDialogService.dialog.mode$);
    const t = useI18n();
    const workspacesService = useService(WorkspacesService);
    const [loading, setLoading] = useState(false);
    useLayoutEffect(()=>{
        let canceled = false;
        if (mode === 'add') {
            (async ()=>{
                if (!apis) {
                    return;
                }
                logger.info('load db file');
                const result = await apis.dialog.loadDBFile();
                if (result.workspaceId && !canceled) {
                    _addLocalWorkspace(result.workspaceId);
                    workspacesService.list.revalidate();
                    createWorkspaceDialogService.dialog.callback({
                        meta: {
                            flavour: WorkspaceFlavour.LOCAL,
                            id: result.workspaceId
                        }
                    });
                } else if (result.error || result.canceled) {
                    if (result.error) {
                        toast(t[result.error]());
                    }
                    createWorkspaceDialogService.dialog.callback(undefined);
                    createWorkspaceDialogService.dialog.close();
                }
            })().catch((err)=>{
                console.error(err);
            });
        }
        return ()=>{
            canceled = true;
        };
    }, [
        createWorkspaceDialogService,
        mode,
        t,
        workspacesService
    ]);
    const onConfirmName = useAsyncCallback(async (name, workspaceFlavour)=>{
        track.$.$.$.createWorkspace({
            flavour: workspaceFlavour
        });
        if (loading) return;
        setLoading(true);
        const { meta, defaultDocId } = await buildShowcaseWorkspace(workspacesService, workspaceFlavour, name);
        createWorkspaceDialogService.dialog.callback({
            meta,
            defaultDocId
        });
        createWorkspaceDialogService.dialog.close();
        setLoading(false);
    }, [
        createWorkspaceDialogService.dialog,
        loading,
        workspacesService
    ]);
    const onOpenChange = useCallback((open)=>{
        if (!open) {
            createWorkspaceDialogService.dialog.close();
        }
    }, [
        createWorkspaceDialogService
    ]);
    if (mode === 'new') {
        return _jsx(NameWorkspaceContent, {
            loading: loading,
            open: true,
            onOpenChange: onOpenChange,
            onConfirmName: onConfirmName
        });
    } else {
        return null;
    }
};
export const CreateWorkspaceDialogProvider = ()=>{
    const createWorkspaceDialogService = useService(CreateWorkspaceDialogService);
    const isOpen = useLiveData(createWorkspaceDialogService.dialog.isOpen$);
    return isOpen ? _jsx(CreateWorkspaceDialog, {}) : null;
};
