function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init_abortController, _init_docTitle, _init_inlineEditor, _init_isLinkedNode, _init_popupContainer, _init_referenceInfo, _init_target, _init_targetInlineRange, _initProto;
import { isInsideBlockByFlavour } from '@blocksuite/affine-shared/utils';
import { BLOCK_ID_ATTR } from '@blocksuite/block-std';
import { assertExists, WithDisposable } from '@blocksuite/global/utils';
import { computePosition, inline, offset, shift } from '@floating-ui/dom';
import { effect } from '@preact/signals-core';
import { html, LitElement, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { join } from 'lit/directives/join.js';
import { repeat } from 'lit/directives/repeat.js';
import { CenterPeekIcon, DeleteIcon, ExpandFullSmallIcon, MoreVerticalIcon, OpenIcon, SmallArrowDownIcon } from '../../../../../icons/index.js';
import { isPeekable, peek } from '../../../../../peek/index.js';
import { renderActions, renderToolbarSeparator } from '../../../../../toolbar/index.js';
import { RefNodeSlotsProvider } from '../../../../extension/index.js';
import { styles } from './styles.js';
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = query('.affine-reference-popover-container'), _dec5 = property({
    type: Object
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
});
export class ReferencePopup extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_abortController, _init_docTitle, _init_inlineEditor, _init_isLinkedNode, _init_popupContainer, _init_referenceInfo, _init_target, _init_targetInlineRange, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "abortController"
            ],
            [
                _dec1,
                1,
                "docTitle"
            ],
            [
                _dec2,
                1,
                "inlineEditor"
            ],
            [
                _dec3,
                1,
                "isLinkedNode"
            ],
            [
                _dec4,
                1,
                "popupContainer"
            ],
            [
                _dec5,
                1,
                "referenceInfo"
            ],
            [
                _dec6,
                1,
                "target"
            ],
            [
                _dec7,
                1,
                "targetInlineRange"
            ]
        ], []));
    }
    static{
        this.styles = styles;
    }
    get _embedViewButtonDisabled() {
        if (this.block.doc.readonly || isInsideBlockByFlavour(this.block.doc, this.block.model, 'affine:edgeless-text')) {
            return true;
        }
        return !!this.block.closest('affine-embed-synced-doc-block') || this.referenceDocId === this.doc.id;
    }
    get _openButtonDisabled() {
        return this.referenceDocId === this.doc.id;
    }
    get block() {
        const block = this.inlineEditor.rootElement.closest(`[${BLOCK_ID_ATTR}]`);
        assertExists(block);
        return block;
    }
    get doc() {
        const doc = this.block.doc;
        assertExists(doc);
        return doc;
    }
    get referenceDocId() {
        const docId = this.inlineEditor.getFormat(this.targetInlineRange).reference?.pageId;
        assertExists(docId);
        return docId;
    }
    get std() {
        const std = this.block.std;
        assertExists(std);
        return std;
    }
    _convertToCardView() {
        const block = this.block;
        const doc = block.host.doc;
        const parent = doc.getParent(block.model);
        assertExists(parent);
        const index = parent.children.indexOf(block.model);
        doc.addBlock('affine:embed-linked-doc', this.referenceInfo, parent, index + 1);
        const totalTextLength = this.inlineEditor.yTextLength;
        const inlineTextLength = this.targetInlineRange.length;
        if (totalTextLength === inlineTextLength) {
            doc.deleteBlock(block.model);
        } else {
            this.inlineEditor.insertText(this.targetInlineRange, this.docTitle);
        }
        this.abortController.abort();
    }
    _convertToEmbedView() {
        const block = this.block;
        const doc = block.host.doc;
        const parent = doc.getParent(block.model);
        assertExists(parent);
        const index = parent.children.indexOf(block.model);
        const docId = this.referenceDocId;
        doc.addBlock('affine:embed-synced-doc', {
            pageId: docId
        }, parent, index + 1);
        const totalTextLength = this.inlineEditor.yTextLength;
        const inlineTextLength = this.targetInlineRange.length;
        if (totalTextLength === inlineTextLength) {
            doc.deleteBlock(block.model);
        } else {
            this.inlineEditor.insertText(this.targetInlineRange, this.docTitle);
        }
        this.abortController.abort();
    }
    _delete() {
        if (this.inlineEditor.isValidInlineRange(this.targetInlineRange)) {
            this.inlineEditor.deleteText(this.targetInlineRange);
        }
        this.abortController.abort();
    }
    _moreActions() {
        return renderActions([
            [
                {
                    type: 'delete',
                    label: 'Delete',
                    icon: DeleteIcon,
                    disabled: this.doc.readonly,
                    action: ()=>this._delete()
                }
            ]
        ]);
    }
    _openDoc() {
        this.std.getOptional(RefNodeSlotsProvider)?.docLinkClicked.emit(this.referenceInfo);
    }
    _openMenuButton() {
        const buttons = [
            {
                label: 'Open this doc',
                type: 'open-this-doc',
                icon: ExpandFullSmallIcon,
                action: ()=>this._openDoc(),
                disabled: this._openButtonDisabled
            }
        ];
        if (isPeekable(this.target)) {
            buttons.push({
                label: 'Open in center peek',
                type: 'open-in-center-peek',
                icon: CenterPeekIcon,
                action: ()=>peek(this.target)
            });
        }
        if (buttons.length === 0) {
            return nothing;
        }
        return html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button
            aria-label="Open doc"
            .justify=${'space-between'}
            .labelHeight=${'20px'}
          >
            ${OpenIcon}${SmallArrowDownIcon}
          </editor-icon-button>
        `}
      >
        <div data-size="large" data-orientation="vertical">
          ${repeat(buttons, (button)=>button.label, ({ label, icon, action, disabled })=>html`
              <editor-menu-action
                aria-label=${ifDefined(label)}
                ?disabled=${disabled}
                @click=${action}
              >
                ${icon}<span class="label">${label}</span>
              </editor-menu-action>
            `)}
        </div>
      </editor-menu-button>
    `;
    }
    _viewMenuButton() {
        const isSyncedDocEnabled = this.doc.awarenessStore.getFlag('enable_synced_doc_block');
        const buttons = [];
        buttons.push({
            type: 'inline',
            label: 'Inline view'
        });
        buttons.push({
            type: 'card',
            label: 'Card view',
            action: ()=>this._convertToCardView(),
            disabled: this.doc.readonly
        });
        if (isSyncedDocEnabled) {
            buttons.push({
                type: 'embed',
                label: 'Embed view',
                action: ()=>this._convertToEmbedView(),
                disabled: this.doc.readonly || this.isLinkedNode || this._embedViewButtonDisabled
            });
        }
        return html`
      <editor-menu-button
        .contentPadding=${'8px'}
        .button=${html`
          <editor-icon-button
            aria-label="Switch view"
            .justify=${'space-between'}
            .labelHeight=${'20px'}
            .iconContainerWidth=${'110px'}
          >
            <span class="label">Inline view</span>
            ${SmallArrowDownIcon}
          </editor-icon-button>
        `}
      >
        <div data-size="small" data-orientation="vertical">
          ${repeat(buttons, (button)=>button.type, ({ type, label, action, disabled })=>html`
              <editor-menu-action
                aria-label=${label}
                data-testid=${`link-to-${type}`}
                ?data-selected=${type === 'inline'}
                ?disabled=${disabled}
                @click=${action}
              >
                ${label}
              </editor-menu-action>
            `)}
        </div>
      </editor-menu-button>
    `;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.targetInlineRange.length === 0) {
            return;
        }
        const parent = this.block.host.doc.getParent(this.block.model);
        assertExists(parent);
        this.disposables.add(effect(()=>{
            const children = parent.children;
            if (children.includes(this.block.model)) return;
            this.abortController.abort();
        }));
    }
    render() {
        const buttons = [
            this._openMenuButton(),
            this._viewMenuButton(),
            html`
        <editor-menu-button
          .contentPadding=${'8px'}
          .button=${html`
            <editor-icon-button aria-label="More" .tooltip=${'More'}>
              ${MoreVerticalIcon}
            </editor-icon-button>
          `}
        >
          <div data-size="large" data-orientation="vertical">
            ${this._moreActions()}
          </div>
        </editor-menu-button>
      `
        ];
        return html`
      <div class="overlay-root">
        <div class="affine-reference-popover-container">
          <editor-toolbar class="affine-reference-popover view">
            ${join(buttons.filter((button)=>button !== nothing), renderToolbarSeparator)}
          </editor-toolbar>
        </div>
      </div>
    `;
    }
    updated() {
        assertExists(this.popupContainer);
        const range = this.inlineEditor.toDomRange(this.targetInlineRange);
        assertExists(range);
        const visualElement = {
            getBoundingClientRect: ()=>range.getBoundingClientRect(),
            getClientRects: ()=>range.getClientRects()
        };
        computePosition(visualElement, this.popupContainer, {
            middleware: [
                offset(10),
                inline(),
                shift({
                    padding: 6
                })
            ]
        }).then(({ x, y })=>{
            const popupContainer = this.popupContainer;
            if (!popupContainer) return;
            popupContainer.style.left = `${x}px`;
            popupContainer.style.top = `${y}px`;
        }).catch(console.error);
    }
    #___private_abortController_1;
    get abortController() {
        return this.#___private_abortController_1;
    }
    set abortController(_v) {
        this.#___private_abortController_1 = _v;
    }
    #___private_docTitle_2;
    get docTitle() {
        return this.#___private_docTitle_2;
    }
    set docTitle(_v) {
        this.#___private_docTitle_2 = _v;
    }
    #___private_inlineEditor_3;
    get inlineEditor() {
        return this.#___private_inlineEditor_3;
    }
    set inlineEditor(_v) {
        this.#___private_inlineEditor_3 = _v;
    }
    #___private_isLinkedNode_4;
    get isLinkedNode() {
        return this.#___private_isLinkedNode_4;
    }
    set isLinkedNode(_v) {
        this.#___private_isLinkedNode_4 = _v;
    }
    #___private_popupContainer_5;
    get popupContainer() {
        return this.#___private_popupContainer_5;
    }
    set popupContainer(_v) {
        this.#___private_popupContainer_5 = _v;
    }
    #___private_referenceInfo_6;
    get referenceInfo() {
        return this.#___private_referenceInfo_6;
    }
    set referenceInfo(_v) {
        this.#___private_referenceInfo_6 = _v;
    }
    #___private_target_7;
    get target() {
        return this.#___private_target_7;
    }
    set target(_v) {
        this.#___private_target_7 = _v;
    }
    #___private_targetInlineRange_8;
    get targetInlineRange() {
        return this.#___private_targetInlineRange_8;
    }
    set targetInlineRange(_v) {
        this.#___private_targetInlineRange_8 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_abortController_1 = (_initProto(this), _init_abortController(this)), this.#___private_docTitle_2 = _init_docTitle(this), this.#___private_inlineEditor_3 = _init_inlineEditor(this), this.#___private_isLinkedNode_4 = _init_isLinkedNode(this), this.#___private_popupContainer_5 = _init_popupContainer(this), this.#___private_referenceInfo_6 = _init_referenceInfo(this), this.#___private_target_7 = _init_target(this), this.#___private_targetInlineRange_8 = _init_targetInlineRange(this);
    }
}
export function toggleReferencePopup(target, isLinkedNode, referenceInfo, inlineEditor, targetInlineRange, docTitle, abortController) {
    const popup = new ReferencePopup();
    popup.target = target;
    popup.isLinkedNode = isLinkedNode;
    popup.referenceInfo = referenceInfo;
    popup.inlineEditor = inlineEditor;
    popup.targetInlineRange = targetInlineRange;
    popup.docTitle = docTitle;
    popup.abortController = abortController;
    document.body.append(popup);
    return popup;
}
