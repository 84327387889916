import { EdgelessPenDarkIcon, EdgelessPenLightIcon } from '@blocksuite/affine-components/icons';
import { EditPropsStore } from '@blocksuite/affine-shared/services';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { SignalWatcher } from '@blocksuite/global/utils';
import { computed } from '@preact/signals-core';
import { css, html, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { getTooltipWithShortcut } from '../../utils.js';
import { EdgelessToolbarToolMixin } from '../mixins/tool.mixin.js';
export class EdgelessBrushToolButton extends EdgelessToolbarToolMixin(SignalWatcher(LitElement)) {
    static{
        this.styles = css`
    :host {
      display: flex;
      height: 100%;
      overflow-y: hidden;
    }
    .edgeless-brush-button {
      height: 100%;
    }
    .pen-wrapper {
      width: 35px;
      height: 64px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    #edgeless-pen-icon {
      transition: transform 0.3s ease-in-out;
      transform: translateY(8px);
    }
    .edgeless-brush-button:hover #edgeless-pen-icon,
    .pen-wrapper.active #edgeless-pen-icon {
      transform: translateY(0);
    }
  `;
    }
    _toggleBrushMenu() {
        if (this.tryDisposePopper()) return;
        !this.active && this.setEdgelessTool({
            type: this.type
        });
        const menu = this.createPopper('edgeless-brush-menu', this);
        Object.assign(menu.element, {
            edgeless: this.edgeless,
            onChange: (props)=>{
                this.edgeless.std.get(EditPropsStore).recordLastProps('brush', props);
                this.setEdgelessTool({
                    type: 'brush'
                });
            }
        });
    }
    render() {
        const { active, theme } = this;
        const icon = theme === 'dark' ? EdgelessPenDarkIcon : EdgelessPenLightIcon;
        const color = this._color$.value;
        return html`
      <edgeless-toolbar-button
        class="edgeless-brush-button"
        .tooltip=${this.popper ? '' : getTooltipWithShortcut('Pen', 'P')}
        .tooltipOffset=${4}
        .active=${active}
        .withHover=${true}
        @click=${()=>this._toggleBrushMenu()}
      >
        <div style=${styleMap({
            color
        })} class="pen-wrapper">${icon}</div>
      </edgeless-toolbar-button>
    `;
    }
    constructor(...args){
        super(...args), this._color$ = computed(()=>{
            return ThemeObserver.generateColorProperty(this.edgeless.std.get(EditPropsStore).lastProps$.value.brush.color);
        }), this.enableActiveBackground = true, this.type = 'brush';
    }
}
