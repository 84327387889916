import { AttachmentBlockStyles } from '@blocksuite/affine-model';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '@blocksuite/affine-shared/consts';
import { toGfxBlockComponent } from '@blocksuite/block-std';
import { styleMap } from 'lit/directives/style-map.js';
import { AttachmentBlockComponent } from './attachment-block.js';
export class AttachmentEdgelessBlockComponent extends toGfxBlockComponent(AttachmentBlockComponent) {
    get embedView() {
        return undefined;
    }
    get rootService() {
        return this.std.getService('affine:page');
    }
    connectedCallback() {
        super.connectedCallback();
        const rootService = this.rootService;
        this._disposables.add(rootService.slots.elementResizeStart.on(()=>{
            this._isResizing = true;
            this._showOverlay = true;
        }));
        this._disposables.add(rootService.slots.elementResizeEnd.on(()=>{
            this._isResizing = false;
            this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
        }));
    }
    onClick(_) {
        return;
    }
    renderGfxBlock() {
        const { style$ } = this.model;
        const cardStyle = style$.value ?? AttachmentBlockStyles[1];
        const width = EMBED_CARD_WIDTH[cardStyle];
        const height = EMBED_CARD_HEIGHT[cardStyle];
        const bound = this.model.elementBound;
        const scaleX = bound.w / width;
        const scaleY = bound.h / height;
        this.containerStyleMap = styleMap({
            width: `${width}px`,
            height: `${height}px`,
            transform: `scale(${scaleX}, ${scaleY})`,
            transformOrigin: '0 0'
        });
        return this.renderPageContent();
    }
    constructor(...args){
        super(...args), this._whenHover = null;
    }
}
