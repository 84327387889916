import { insertEmbedCard } from '../../common/insert-embed-card.js';
export const insertEmbedLinkedDocCommand = (ctx, next)=>{
    const { docId, params, std } = ctx;
    const flavour = 'affine:embed-linked-doc';
    const targetStyle = 'vertical';
    const props = {
        pageId: docId
    };
    if (params) props.params = params;
    insertEmbedCard(std, {
        flavour,
        targetStyle,
        props
    });
    next();
};
