import { dedupe, delayHide, delayShow } from './middlewares/basic.js';
import { safeBridge, safeTriangle } from './middlewares/safe-area.js';
export const whenHover = (whenHoverChange, { enterDelay = 0, leaveDelay = 250, alwayRunWhenNoFloating = true, safeTriangle: triangleOptions = false, safeBridge: bridgeOptions = true } = {})=>{
    const abortController = new AbortController();
    let referenceElement;
    let floatingElement;
    const middlewares = [
        dedupe(alwayRunWhenNoFloating),
        triangleOptions && safeTriangle(typeof triangleOptions === 'boolean' ? undefined : triangleOptions),
        bridgeOptions && safeBridge(typeof bridgeOptions === 'boolean' ? undefined : bridgeOptions),
        delayShow(enterDelay),
        delayHide(leaveDelay)
    ].filter((v)=>typeof v !== 'boolean');
    let currentEvent = null;
    const onHoverChange = async (e)=>{
        currentEvent = e;
        for (const middleware of middlewares){
            const go = await middleware({
                event: e,
                floatingElement,
                referenceElement
            });
            if (!go) return;
        }
        if (e !== currentEvent) return;
        const isHover = e.type === 'mouseover' ? true : false;
        whenHoverChange(isHover, e);
    };
    const addHoverListener = (element)=>{
        if (!element) return;
        const alreadyHover = element.matches(':hover');
        if (alreadyHover && !abortController.signal.aborted) {
            onHoverChange(new MouseEvent('mouseover'));
        }
        element.addEventListener('mouseover', onHoverChange, {
            capture: true,
            signal: abortController.signal
        });
        element.addEventListener('mouseleave', onHoverChange, {
            signal: abortController.signal
        });
    };
    const removeHoverListener = (element)=>{
        if (!element) return;
        element.removeEventListener('mouseover', onHoverChange);
        element.removeEventListener('mouseleave', onHoverChange);
    };
    const setReference = (element)=>{
        removeHoverListener(referenceElement);
        addHoverListener(element);
        referenceElement = element;
    };
    const setFloating = (element)=>{
        removeHoverListener(floatingElement);
        addHoverListener(element);
        floatingElement = element;
    };
    return {
        setReference,
        setFloating,
        dispose: ()=>{
            abortController.abort();
        }
    };
};
