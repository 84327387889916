import { jsx as _jsx } from "react/jsx-runtime";
import { useRefEffect } from '@affine/component';
import { EditorLoading } from '@affine/component/page-detail-skeleton';
import { BookmarkBlockService, customImageProxyMiddleware, EmbedGithubBlockService, EmbedLoomBlockService, EmbedYoutubeBlockService, ImageBlockService } from '@blocksuite/affine/blocks';
import { DisposableGroup } from '@blocksuite/affine/global/utils';
import { use } from 'foxact/use';
import { Suspense, useEffect } from 'react';
import { BlocksuiteEditorContainer } from './blocksuite-editor-container';
import { NoPageRootError } from './no-page-error';
function usePageRoot(page) {
    if (!page.ready) {
        page.load();
    }
    if (!page.root) {
        use(new Promise((resolve, reject)=>{
            const disposable = page.slots.rootAdded.once(()=>{
                resolve();
            });
            window.setTimeout(()=>{
                disposable.dispose();
                reject(new NoPageRootError(page));
            }, 20 * 1000);
        }));
    }
    return page.root;
}
const BlockSuiteEditorImpl = ({ mode, page, className, shared, style, onEditorReady })=>{
    usePageRoot(page);
    useEffect(()=>{
        const disposable = page.slots.blockUpdated.once(()=>{
            page.collection.setDocMeta(page.id, {
                updatedDate: Date.now()
            });
        });
        return ()=>{
            disposable.dispose();
        };
    }, [
        page
    ]);
    const editorRef = useRefEffect((editor)=>{
        globalThis.currentEditor = editor;
        let canceled = false;
        const disposableGroup = new DisposableGroup();
        if (onEditorReady) {
            editor.updateComplete.then(()=>{
                if (canceled) {
                    return;
                }
                editor.host?.std.clipboard.use(customImageProxyMiddleware(BUILD_CONFIG.imageProxyUrl));
                ImageBlockService.setImageProxyURL(BUILD_CONFIG.imageProxyUrl);
                BookmarkBlockService.setLinkPreviewEndpoint(BUILD_CONFIG.linkPreviewUrl);
                EmbedGithubBlockService.setLinkPreviewEndpoint(BUILD_CONFIG.linkPreviewUrl);
                EmbedYoutubeBlockService.setLinkPreviewEndpoint(BUILD_CONFIG.linkPreviewUrl);
                EmbedLoomBlockService.setLinkPreviewEndpoint(BUILD_CONFIG.linkPreviewUrl);
                return editor.host?.updateComplete;
            }).then(()=>{
                if (canceled) {
                    return;
                }
                const dispose = onEditorReady(editor);
                if (dispose) {
                    disposableGroup.add(dispose);
                }
            }).catch(console.error);
        }
        return ()=>{
            canceled = true;
            disposableGroup.dispose();
        };
    }, [
        onEditorReady,
        page
    ]);
    return _jsx(BlocksuiteEditorContainer, {
        mode: mode,
        page: page,
        shared: shared,
        ref: editorRef,
        className: className,
        style: style
    });
};
export const BlockSuiteEditor = (props)=>{
    return _jsx(Suspense, {
        fallback: _jsx(EditorLoading, {}),
        children: _jsx(BlockSuiteEditorImpl, {
            ...props
        }, props.page.id)
    });
};
