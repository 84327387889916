import { isNil, pick, pickBy } from 'lodash-es';
import queryString from 'query-string';
function maybeAffineOrigin(origin, baseUrl) {
    return origin.startsWith('file://') || origin.startsWith('affine://') || origin.endsWith('affine.pro') || origin.endsWith('affine.fail') || origin === baseUrl;
}
export const resolveRouteLinkMeta = (href, baseUrl = location.origin)=>{
    try {
        const url = new URL(href, baseUrl);
        if (!maybeAffineOrigin(url.origin, baseUrl)) {
            return null;
        }
        const [_, workspaceId, moduleName, subModuleName] = url.pathname.match(/\/workspace\/([^/]+)\/([^/]+)(?:\/([^/]+))?/) || [];
        if (workspaceId) {
            const basename = `/workspace/${workspaceId}`;
            const pathname = url.pathname.replace(basename, '');
            const search = url.search;
            const hash = url.hash;
            const location1 = {
                pathname,
                search,
                hash
            };
            if (isRouteModulePath(moduleName)) {
                return {
                    location: location1,
                    basename,
                    workspaceId,
                    moduleName,
                    subModuleName
                };
            } else if (moduleName) {
                return {
                    location: location1,
                    basename,
                    workspaceId,
                    moduleName: 'doc',
                    docId: moduleName
                };
            }
        }
        return null;
    } catch  {
        return null;
    }
};
export const isLink = (href)=>{
    try {
        const hasScheme = href.match(/^https?:\/\//);
        if (!hasScheme) {
            const dotIdx = href.indexOf('.');
            if (dotIdx > 0 && dotIdx < href.length - 1) {
                href = `https://${href}`;
            }
        }
        return Boolean(URL.canParse?.(href) ?? new URL(href));
    } catch  {
        return null;
    }
};
export const routeModulePaths = [
    'all',
    'collection',
    'tag',
    'trash'
];
const isRouteModulePath = (path)=>routeModulePaths.includes(path);
export const resolveLinkToDoc = (href)=>{
    const meta = resolveRouteLinkMeta(href);
    if (!meta || meta.moduleName !== 'doc') return null;
    const params = preprocessParams(queryString.parse(meta.location.search, paramsParseOptions));
    return {
        ...pick(meta, [
            'workspaceId',
            'docId'
        ]),
        ...params
    };
};
export const preprocessParams = (params)=>{
    const result = pickBy(params, (value)=>{
        if (isNil(value)) return false;
        if (typeof value === 'string' && value.length === 0) return false;
        if (Array.isArray(value) && value.length === 0) return false;
        return true;
    });
    if (result.blockIds?.length) {
        result.blockIds = result.blockIds.filter((v)=>v.length);
    }
    if (result.elementIds?.length) {
        result.elementIds = result.elementIds.filter((v)=>v.length);
    }
    return pick(result, [
        'mode',
        'blockIds',
        'elementIds',
        'refreshKey'
    ]);
};
export const paramsParseOptions = {
    arrayFormat: 'none',
    types: {
        mode: (value)=>value === 'page' || value === 'edgeless' ? value : undefined,
        blockIds: (value)=>value.length ? value.split(',').filter((v)=>v.length) : [],
        elementIds: (value)=>value.length ? value.split(',').filter((v)=>v.length) : [],
        refreshKey: 'string'
    }
};
export function toURLSearchParams(params) {
    if (!params) return;
    const items = Object.entries(params).filter(([_, v])=>!isNil(v)).filter(([_, v])=>{
        if (typeof v === 'string') {
            return v.length > 0;
        }
        if (Array.isArray(v)) {
            return v.length > 0;
        }
        return false;
    }).map(([k, v])=>[
            k,
            Array.isArray(v) ? v.filter((v)=>v.length) : v
        ]);
    return new URLSearchParams(items.filter(([_, v])=>v.length).map(([k, v])=>[
            k,
            Array.isArray(v) ? v.join(',') : v
        ]));
}
