import { computed } from '@preact/signals-core';
export class CellBase {
    get property() {
        return this.property$.value;
    }
    get row() {
        return this.view.rowGet(this.rowId);
    }
    constructor(view, propertyId, rowId){
        this.view = view;
        this.propertyId = propertyId;
        this.rowId = rowId;
        this.isEmpty$ = computed(()=>{
            return this.meta$.value.config.isEmpty(this.value$.value);
        });
        this.jsonValue$ = computed(()=>{
            return this.view.cellJsonValueGet(this.rowId, this.propertyId);
        });
        this.meta$ = computed(()=>{
            return this.view.manager.dataSource.propertyMetaGet(this.property.type$.value);
        });
        this.property$ = computed(()=>{
            return this.view.propertyGet(this.propertyId);
        });
        this.stringValue$ = computed(()=>{
            return this.view.cellStringValueGet(this.rowId, this.propertyId);
        });
        this.value$ = computed(()=>{
            return this.view.manager.dataSource.cellValueGet(this.rowId, this.propertyId);
        });
    }
    valueSet(value) {
        this.view.manager.dataSource.cellValueChange(this.rowId, this.propertyId, value);
    }
}
