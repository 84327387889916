import { getSelectedPeekableBlocksCommand, peekSelectedBlockCommand } from '@blocksuite/affine-components/peek';
import { textCommands } from '@blocksuite/affine-components/rich-text';
import { copySelectedModelsCommand, deleteSelectedModelsCommand, draftSelectedModelsCommand, getSelectedModelsCommand } from '@blocksuite/affine-shared/commands';
export const commands = {
    copySelectedModels: copySelectedModelsCommand,
    deleteSelectedModels: deleteSelectedModelsCommand,
    draftSelectedModels: draftSelectedModelsCommand,
    getSelectedModels: getSelectedModelsCommand,
    ...textCommands,
    peekSelectedBlock: peekSelectedBlockCommand,
    getSelectedPeekableBlocks: getSelectedPeekableBlocksCommand
};
