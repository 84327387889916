import { IS_IOS, IS_MAC } from '@blocksuite/global/env';
export function isTouchPadPinchEvent(e) {
    if (IS_IOS || IS_MAC) {
        return e.ctrlKey || e.metaKey;
    }
    return e.ctrlKey;
}
export var MOUSE_BUTTONS;
(function(MOUSE_BUTTONS) {
    MOUSE_BUTTONS[MOUSE_BUTTONS["AUXILIARY"] = 4] = "AUXILIARY";
    MOUSE_BUTTONS[MOUSE_BUTTONS["FIFTH"] = 16] = "FIFTH";
    MOUSE_BUTTONS[MOUSE_BUTTONS["FORTH"] = 8] = "FORTH";
    MOUSE_BUTTONS[MOUSE_BUTTONS["NO_BUTTON"] = 0] = "NO_BUTTON";
    MOUSE_BUTTONS[MOUSE_BUTTONS["PRIMARY"] = 1] = "PRIMARY";
    MOUSE_BUTTONS[MOUSE_BUTTONS["SECONDARY"] = 2] = "SECONDARY";
})(MOUSE_BUTTONS || (MOUSE_BUTTONS = {}));
export var MOUSE_BUTTON;
(function(MOUSE_BUTTON) {
    MOUSE_BUTTON[MOUSE_BUTTON["AUXILIARY"] = 1] = "AUXILIARY";
    MOUSE_BUTTON[MOUSE_BUTTON["FIFTH"] = 4] = "FIFTH";
    MOUSE_BUTTON[MOUSE_BUTTON["FORTH"] = 3] = "FORTH";
    MOUSE_BUTTON[MOUSE_BUTTON["MAIN"] = 0] = "MAIN";
    MOUSE_BUTTON[MOUSE_BUTTON["SECONDARY"] = 2] = "SECONDARY";
})(MOUSE_BUTTON || (MOUSE_BUTTON = {}));
export function isMiddleButtonPressed(e) {
    return (4 & e.buttons) === 4;
}
export function isRightButtonPressed(e) {
    return (2 & e.buttons) === 2;
}
export function stopPropagation(event) {
    event.stopPropagation();
}
export function isControlledKeyboardEvent(e) {
    return e.ctrlKey || e.metaKey || e.altKey;
}
export function on(element, event, handler, options) {
    const dispose = ()=>{
        element.removeEventListener(event, handler, options);
    };
    element.addEventListener(event, handler, options);
    return dispose;
}
export function once(element, event, handler, options) {
    const onceHandler = (e)=>{
        dispose();
        handler(e);
    };
    const dispose = ()=>{
        element.removeEventListener(event, onceHandler, options);
    };
    element.addEventListener(event, onceHandler, options);
    return dispose;
}
export function delayCallback(callback, delay = 0) {
    const timeoutId = setTimeout(callback, delay);
    return ()=>clearTimeout(timeoutId);
}
export function requestConnectedFrame(callback, element) {
    return requestAnimationFrame(()=>{
        if (element === undefined) {
            callback();
            return;
        }
        if (element.isConnected) callback();
    });
}
export function requestThrottledConnectedFrame(func, element) {
    let raqId = undefined;
    let latestArgs = [];
    return (...args)=>{
        latestArgs = args;
        if (raqId) return;
        raqId = requestConnectedFrame(()=>{
            raqId = undefined;
            func(...latestArgs);
        }, element);
    };
}
export const captureEventTarget = (target)=>{
    const isElementOrNode = target instanceof Element || target instanceof Node;
    return isElementOrNode ? target instanceof Element ? target : target.parentElement : null;
};
