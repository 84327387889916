import { RootBlockSchema } from '@blocksuite/affine-model';
import { Slot } from '@blocksuite/store';
import { RootService } from '../root-service.js';
export class PageRootService extends RootService {
    static{
        this.flavour = RootBlockSchema.model.flavour;
    }
    constructor(...args){
        super(...args), this.slots = {
            viewportUpdated: new Slot()
        };
    }
}
