import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@affine/component';
import { Pagination } from '@affine/component/member-components';
import { SettingHeader, SettingRow, SettingWrapper } from '@affine/component/setting-components';
import { Button, IconButton } from '@affine/component/ui/button';
import { Loading } from '@affine/component/ui/loading';
import { getUpgradeQuestionnaireLink } from '@affine/core/components/hooks/affine/use-subscription-notify';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { AuthService, InvoicesService, SubscriptionService } from '@affine/core/modules/cloud';
import { createCustomerPortalMutation, InvoiceStatus, SubscriptionPlan, SubscriptionRecurring, SubscriptionStatus, UserFriendlyError } from '@affine/graphql';
import { i18nTime, Trans, useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { ArrowRightSmallIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '../../../../../components/hooks/use-mutation';
import { popupWindow } from '../../../../../utils';
import { openSettingModalAtom } from '../../../../atoms';
import { CancelAction, ResumeAction } from '../plans/actions';
import { AICancel, AIResume, AISubscribe } from '../plans/ai/actions';
import { BelieverCard } from '../plans/lifetime/believer-card';
import { BelieverBenefits } from '../plans/lifetime/benefits';
import * as styles from './style.css';
var DescriptionI18NKey;
(function(DescriptionI18NKey) {
    DescriptionI18NKey["Basic"] = "com.affine.payment.billing-setting.current-plan.description";
    DescriptionI18NKey["Monthly"] = "com.affine.payment.billing-setting.current-plan.description.monthly";
    DescriptionI18NKey["Yearly"] = "com.affine.payment.billing-setting.current-plan.description.yearly";
    DescriptionI18NKey["Lifetime"] = "com.affine.payment.billing-setting.current-plan.description.lifetime";
})(DescriptionI18NKey || (DescriptionI18NKey = {}));
const getMessageKey = (plan, recurring)=>{
    if (plan !== SubscriptionPlan.Pro) {
        return "com.affine.payment.billing-setting.current-plan.description";
    }
    return DescriptionI18NKey[recurring];
};
export const BillingSettings = ()=>{
    const t = useI18n();
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingHeader, {
                title: t['com.affine.payment.billing-setting.title'](),
                subtitle: t['com.affine.payment.billing-setting.subtitle']()
            }),
            _jsx(SettingWrapper, {
                title: t['com.affine.payment.billing-setting.information'](),
                children: _jsx(SubscriptionSettings, {})
            }),
            _jsx(SettingWrapper, {
                title: t['com.affine.payment.billing-setting.history'](),
                children: _jsx(BillingHistory, {})
            })
        ]
    });
};
const SubscriptionSettings = ()=>{
    const t = useI18n();
    const subscriptionService = useService(SubscriptionService);
    useEffect(()=>{
        subscriptionService.subscription.revalidate();
        subscriptionService.prices.revalidate();
    }, [
        subscriptionService
    ]);
    const proSubscription = useLiveData(subscriptionService.subscription.pro$);
    const proPrice = useLiveData(subscriptionService.prices.proPrice$);
    const isBeliever = useLiveData(subscriptionService.subscription.isBeliever$);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const setOpenSettingModalAtom = useSetAtom(openSettingModalAtom);
    const currentPlan = proSubscription?.plan ?? SubscriptionPlan.Free;
    const currentRecurring = proSubscription?.recurring ?? SubscriptionRecurring.Monthly;
    const openPlans = useCallback((scrollAnchor)=>{
        track.$.settingsPanel.billing.viewPlans();
        setOpenSettingModalAtom({
            open: true,
            activeTab: 'plans',
            scrollAnchor: scrollAnchor
        });
    }, [
        setOpenSettingModalAtom
    ]);
    const gotoCloudPlansSetting = useCallback(()=>openPlans('cloudPricingPlan'), [
        openPlans
    ]);
    const gotoAiPlanSetting = useCallback(()=>openPlans('aiPricingPlan'), [
        openPlans
    ]);
    const amount = proSubscription ? proPrice ? proSubscription.recurring === SubscriptionRecurring.Monthly ? String((proPrice.amount ?? 0) / 100) : String((proPrice.yearlyAmount ?? 0) / 100) : '?' : '0';
    return _jsxs("div", {
        className: styles.subscription,
        children: [
            _jsx(AIPlanCard, {
                onClick: gotoAiPlanSetting
            }),
            proSubscription !== null ? isBeliever ? _jsx(BelieverIdentifier, {
                onOpenPlans: gotoCloudPlansSetting
            }) : _jsxs("div", {
                className: styles.planCard,
                children: [
                    _jsxs("div", {
                        className: styles.currentPlan,
                        children: [
                            _jsx(SettingRow, {
                                spreadCol: false,
                                name: t['com.affine.payment.billing-setting.current-plan'](),
                                desc: _jsx(Trans, {
                                    i18nKey: getMessageKey(currentPlan, currentRecurring),
                                    values: {
                                        planName: currentPlan
                                    },
                                    components: {
                                        1: _jsx("span", {
                                            onClick: gotoCloudPlansSetting,
                                            className: styles.currentPlanName
                                        })
                                    }
                                })
                            }),
                            _jsx(PlanAction, {
                                plan: currentPlan,
                                gotoPlansSetting: gotoCloudPlansSetting
                            })
                        ]
                    }),
                    _jsxs("p", {
                        className: styles.planPrice,
                        children: [
                            "$",
                            amount,
                            _jsxs("span", {
                                className: styles.billingFrequency,
                                children: [
                                    "/",
                                    currentRecurring === SubscriptionRecurring.Monthly ? t['com.affine.payment.billing-setting.month']() : t['com.affine.payment.billing-setting.year']()
                                ]
                            })
                        ]
                    })
                ]
            }) : _jsx(SubscriptionSettingSkeleton, {}),
            _jsx(TypeFormLink, {}),
            proSubscription !== null ? proSubscription?.status === SubscriptionStatus.Active && _jsxs(_Fragment, {
                children: [
                    _jsx(SettingRow, {
                        className: styles.paymentMethod,
                        name: t['com.affine.payment.billing-setting.payment-method'](),
                        desc: t['com.affine.payment.billing-setting.payment-method.description'](),
                        children: _jsx(PaymentMethodUpdater, {})
                    }),
                    proSubscription.nextBillAt && _jsx(SettingRow, {
                        name: t['com.affine.payment.billing-setting.renew-date'](),
                        desc: t['com.affine.payment.billing-setting.renew-date.description']({
                            renewDate: new Date(proSubscription.nextBillAt).toLocaleDateString()
                        })
                    }),
                    isBeliever ? null : proSubscription.end && proSubscription.canceledAt ? _jsx(SettingRow, {
                        name: t['com.affine.payment.billing-setting.expiration-date'](),
                        desc: t['com.affine.payment.billing-setting.expiration-date.description']({
                            expirationDate: new Date(proSubscription.end).toLocaleDateString()
                        }),
                        children: _jsx(ResumeSubscription, {})
                    }) : _jsx(CancelAction, {
                        open: openCancelModal,
                        onOpenChange: setOpenCancelModal,
                        children: _jsx(SettingRow, {
                            style: {
                                cursor: 'pointer'
                            },
                            onClick: ()=>{
                                setOpenCancelModal(true);
                            },
                            className: "dangerous-setting",
                            name: t['com.affine.payment.billing-setting.cancel-subscription'](),
                            desc: t['com.affine.payment.billing-setting.cancel-subscription.description'](),
                            children: _jsx(CancelSubscription, {})
                        })
                    })
                ]
            }) : _jsx(SubscriptionSettingSkeleton, {})
        ]
    });
};
const TypeFormLink = ()=>{
    const t = useI18n();
    const subscriptionService = useService(SubscriptionService);
    const authService = useService(AuthService);
    const pro = useLiveData(subscriptionService.subscription.pro$);
    const ai = useLiveData(subscriptionService.subscription.ai$);
    const account = useLiveData(authService.session.account$);
    if (!account) return null;
    if (!pro && !ai) return null;
    const plan = [];
    if (pro) plan.push(SubscriptionPlan.Pro);
    if (ai) plan.push(SubscriptionPlan.AI);
    const link = getUpgradeQuestionnaireLink({
        name: account.info?.name,
        id: account.id,
        email: account.email,
        recurring: pro?.recurring ?? ai?.recurring ?? SubscriptionRecurring.Yearly,
        plan
    });
    return _jsx(SettingRow, {
        className: styles.paymentMethod,
        name: t['com.affine.payment.billing-type-form.title'](),
        desc: t['com.affine.payment.billing-type-form.description'](),
        children: _jsx("a", {
            target: "_blank",
            href: link,
            rel: "noreferrer",
            children: _jsx(Button, {
                children: t['com.affine.payment.billing-type-form.go']()
            })
        })
    });
};
const BelieverIdentifier = ({ onOpenPlans })=>{
    const t = useI18n();
    const subscriptionService = useService(SubscriptionService);
    const readableLifetimePrice = useLiveData(subscriptionService.prices.readableLifetimePrice$);
    if (!readableLifetimePrice) return null;
    return _jsxs(BelieverCard, {
        type: 2,
        style: {
            borderRadius: 8,
            padding: 12
        },
        children: [
            _jsxs("header", {
                className: styles.believerHeader,
                children: [
                    _jsxs("div", {
                        children: [
                            _jsx("div", {
                                className: styles.believerTitle,
                                children: t['com.affine.payment.billing-setting.believer.title']()
                            }),
                            _jsx("div", {
                                className: styles.believerSubtitle,
                                children: _jsx(Trans, {
                                    i18nKey: 'com.affine.payment.billing-setting.believer.description',
                                    components: {
                                        a: _jsx("a", {
                                            href: "#",
                                            onClick: onOpenPlans
                                        })
                                    }
                                })
                            })
                        ]
                    }),
                    _jsxs("div", {
                        className: styles.believerPriceWrapper,
                        children: [
                            _jsx("div", {
                                className: styles.believerPrice,
                                children: readableLifetimePrice
                            }),
                            _jsx("div", {
                                className: styles.believerPriceCaption,
                                children: t['com.affine.payment.billing-setting.believer.price-caption']()
                            })
                        ]
                    })
                ]
            }),
            _jsx(BelieverBenefits, {})
        ]
    });
};
const AIPlanCard = ({ onClick })=>{
    const t = useI18n();
    const subscriptionService = useService(SubscriptionService);
    useEffect(()=>{
        subscriptionService.subscription.revalidate();
        subscriptionService.prices.revalidate();
    }, [
        subscriptionService
    ]);
    const price = useLiveData(subscriptionService.prices.aiPrice$);
    const subscription = useLiveData(subscriptionService.subscription.ai$);
    const priceReadable = price?.yearlyAmount ? `$${(price.yearlyAmount / 100).toFixed(2)}` : '?';
    const priceFrequency = t['com.affine.payment.billing-setting.year']();
    if (subscription === null) {
        return _jsx(Skeleton, {
            height: 100
        });
    }
    const billingTip = subscription === undefined ? _jsx(Trans, {
        i18nKey: 'com.affine.payment.billing-setting.ai.free-desc',
        components: {
            a: _jsx("a", {
                href: "#",
                onClick: onClick,
                className: styles.currentPlanName
            })
        }
    }) : subscription?.nextBillAt ? t['com.affine.payment.ai.billing-tip.next-bill-at']({
        due: i18nTime(subscription.nextBillAt, {
            absolute: {
                accuracy: 'day'
            }
        })
    }) : subscription?.canceledAt && subscription.end ? t['com.affine.payment.ai.billing-tip.end-at']({
        end: i18nTime(subscription.end, {
            absolute: {
                accuracy: 'day'
            }
        })
    }) : null;
    return _jsxs("div", {
        className: styles.planCard,
        style: {
            marginBottom: 24
        },
        children: [
            _jsxs("div", {
                className: styles.currentPlan,
                children: [
                    _jsx(SettingRow, {
                        spreadCol: false,
                        name: t['com.affine.payment.billing-setting.ai-plan'](),
                        desc: billingTip
                    }),
                    price?.yearlyAmount ? subscription ? subscription.canceledAt ? _jsx(AIResume, {
                        className: styles.planAction
                    }) : _jsx(AICancel, {
                        className: styles.planAction
                    }) : _jsx(AISubscribe, {
                        className: styles.planAction,
                        children: t['com.affine.payment.billing-setting.ai.purchase']()
                    }) : null
                ]
            }),
            _jsxs("p", {
                className: styles.planPrice,
                children: [
                    subscription ? priceReadable : '$0',
                    _jsxs("span", {
                        className: styles.billingFrequency,
                        children: [
                            "/",
                            priceFrequency
                        ]
                    })
                ]
            })
        ]
    });
};
const PlanAction = ({ plan, gotoPlansSetting })=>{
    const t = useI18n();
    return _jsx(Button, {
        className: styles.planAction,
        variant: "primary",
        onClick: gotoPlansSetting,
        children: plan === SubscriptionPlan.Pro ? t['com.affine.payment.billing-setting.change-plan']() : t['com.affine.payment.billing-setting.upgrade']()
    });
};
const PaymentMethodUpdater = ()=>{
    const { isMutating, trigger } = useMutation({
        mutation: createCustomerPortalMutation
    });
    const t = useI18n();
    const update = useAsyncCallback(async ()=>{
        await trigger(null, {
            onSuccess: (data)=>{
                popupWindow(data.createCustomerPortal);
            }
        });
    }, [
        trigger
    ]);
    return _jsx(Button, {
        onClick: update,
        loading: isMutating,
        disabled: isMutating,
        children: t['com.affine.payment.billing-setting.update']()
    });
};
const ResumeSubscription = ()=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const subscription = useService(SubscriptionService).subscription;
    const handleClick = useCallback(()=>{
        setOpen(true);
    }, []);
    return _jsx(ResumeAction, {
        open: open,
        onOpenChange: setOpen,
        children: _jsx(Button, {
            onClick: handleClick,
            "data-event-props": "$.settingsPanel.plans.resumeSubscription",
            "data-event-args-type": subscription.pro$.value?.plan,
            "data-event-args-category": subscription.pro$.value?.recurring,
            children: t['com.affine.payment.billing-setting.resume-subscription']()
        })
    });
};
const CancelSubscription = ({ loading })=>{
    return _jsx(IconButton, {
        style: {
            pointerEvents: 'none'
        },
        disabled: loading,
        loading: loading,
        children: _jsx(ArrowRightSmallIcon, {})
    });
};
const BillingHistory = ()=>{
    const t = useI18n();
    const invoicesService = useService(InvoicesService);
    const pageInvoices = useLiveData(invoicesService.invoices.pageInvoices$);
    const invoiceCount = useLiveData(invoicesService.invoices.invoiceCount$);
    const isLoading = useLiveData(invoicesService.invoices.isLoading$);
    const error = useLiveData(invoicesService.invoices.error$);
    const pageNum = useLiveData(invoicesService.invoices.pageNum$);
    useEffect(()=>{
        invoicesService.invoices.revalidate();
    }, [
        invoicesService
    ]);
    const handlePageChange = useCallback((_, pageNum)=>{
        invoicesService.invoices.setPageNum(pageNum);
        invoicesService.invoices.revalidate();
    }, [
        invoicesService
    ]);
    if (invoiceCount === undefined) {
        if (isLoading) {
            return _jsx(BillingHistorySkeleton, {});
        } else {
            return _jsx("span", {
                style: {
                    color: cssVar('errorColor')
                },
                children: error ? UserFriendlyError.fromAnyError(error).message : 'Failed to load members'
            });
        }
    }
    return _jsxs("div", {
        className: styles.history,
        children: [
            _jsx("div", {
                className: styles.historyContent,
                children: invoiceCount === 0 ? _jsx("p", {
                    className: styles.noInvoice,
                    children: t['com.affine.payment.billing-setting.no-invoice']()
                }) : pageInvoices?.map((invoice)=>_jsx(InvoiceLine, {
                        invoice: invoice
                    }, invoice.id))
            }),
            invoiceCount > invoicesService.invoices.PAGE_SIZE && _jsx(Pagination, {
                totalCount: invoiceCount,
                countPerPage: invoicesService.invoices.PAGE_SIZE,
                pageNum: pageNum,
                onPageChange: handlePageChange
            })
        ]
    });
};
const InvoiceLine = ({ invoice })=>{
    const t = useI18n();
    const open = useCallback(()=>{
        if (invoice.link) {
            popupWindow(invoice.link);
        }
    }, [
        invoice.link
    ]);
    const planText = invoice.plan === SubscriptionPlan.AI ? 'Wemem AI' : invoice.plan === SubscriptionPlan.Pro ? invoice.recurring === SubscriptionRecurring.Lifetime ? 'Wemem Cloud Believer' : 'Wemem Cloud' : null;
    return _jsx(SettingRow, {
        name: new Date(invoice.createdAt).toLocaleDateString(),
        desc: `${invoice.status === InvoiceStatus.Paid ? t['com.affine.payment.billing-setting.paid']() : ''} $${invoice.amount / 100} - ${planText}`,
        children: _jsx(Button, {
            onClick: open,
            children: t['com.affine.payment.billing-setting.view-invoice']()
        })
    }, invoice.id);
};
const SubscriptionSettingSkeleton = ()=>{
    const t = useI18n();
    return _jsx(SettingWrapper, {
        title: t['com.affine.payment.billing-setting.information'](),
        children: _jsxs("div", {
            className: styles.subscriptionSettingSkeleton,
            children: [
                _jsx(Skeleton, {
                    variant: "rounded",
                    height: "104px"
                }),
                _jsx(Skeleton, {
                    variant: "rounded",
                    height: "46px"
                })
            ]
        })
    });
};
const BillingHistorySkeleton = ()=>{
    const t = useI18n();
    return _jsx(SettingWrapper, {
        title: t['com.affine.payment.billing-setting.history'](),
        children: _jsx("div", {
            className: styles.billingHistorySkeleton,
            children: _jsx(Loading, {})
        })
    });
};
