import { jsx as _jsx } from "react/jsx-runtime";
import './setup';
import { init, reactRouterV6BrowserTracingIntegration, setTags } from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { App } from './app';
function main() {
    if (BUILD_CONFIG.debug || window.SENTRY_RELEASE) {
        init({
            dsn: process.env.SENTRY_DSN,
            environment: process.env.BUILD_TYPE ?? 'development',
            integrations: [
                reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                })
            ]
        });
        setTags({
            appVersion: BUILD_CONFIG.appVersion,
            editorVersion: BUILD_CONFIG.editorVersion
        });
    }
    mountApp();
}
function mountApp() {
    const root = document.getElementById('app');
    createRoot(root).render(_jsx(StrictMode, {
        children: _jsx(App, {})
    }));
}
try {
    main();
} catch (err) {
    console.error('Failed to bootstrap app', err);
}
