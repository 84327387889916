import { useLiveData } from '@toeverything/infra';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export function useBindWorkbenchToBrowserRouter(workbench, basename) {
    const navigate = useNavigate();
    const browserLocation = useLocation();
    const view = useLiveData(workbench.activeView$);
    useEffect(()=>{
        return view.history.listen((update)=>{
            if (update.action === 'POP') {
                throw new Error('POP view history is not allowed on browser');
            }
            if (update.location.state === 'fromBrowser') {
                return;
            }
            const newBrowserLocation = viewLocationToBrowserLocation(update.location, basename);
            if (locationIsEqual(browserLocation, newBrowserLocation)) {
                return;
            }
            navigate(newBrowserLocation, {
                state: 'fromView',
                replace: update.action === 'REPLACE'
            });
        });
    }, [
        basename,
        browserLocation,
        navigate,
        view
    ]);
    useEffect(()=>{
        const newLocation = browserLocationToViewLocation(browserLocation, basename);
        if (newLocation === null) {
            return;
        }
        view.history.push(newLocation, 'fromBrowser');
    }, [
        basename,
        browserLocation,
        view
    ]);
}
function browserLocationToViewLocation(location, basename) {
    if (!location.pathname.startsWith(basename)) {
        return null;
    }
    return {
        ...location,
        pathname: location.pathname.slice(basename.length)
    };
}
function viewLocationToBrowserLocation(location, basename) {
    return {
        ...location,
        pathname: `${basename}${location.pathname}`
    };
}
function locationIsEqual(a, b) {
    return a.hash === b.hash && a.pathname === b.pathname && a.search === b.search && a.state === b.state;
}
