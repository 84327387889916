import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading, Menu, MenuItem, MenuSeparator, MenuTrigger, RadioGroup, RowInput, Scrollable, Switch, useConfirmModal } from '@affine/component';
import { SettingRow, SettingWrapper } from '@affine/component/setting-components';
import { EditorSettingService, fontStyleOptions } from '@affine/core/modules/editor-settting';
import { SystemFontFamilyService } from '@affine/core/modules/system-font-family';
import { useI18n } from '@affine/i18n';
import { DoneIcon, SearchIcon } from '@blocksuite/icons/rc';
import { FeatureFlagService, useLiveData, useServices } from '@toeverything/infra';
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { DropdownMenu } from './menu';
import * as styles from './style.css';
const getLabel = (fontKey, t)=>{
    switch(fontKey){
        case 'Sans':
            return t['com.affine.appearanceSettings.fontStyle.sans']();
        case 'Serif':
            return t['com.affine.appearanceSettings.fontStyle.serif']();
        case 'Mono':
            return t[`com.affine.appearanceSettings.fontStyle.mono`]();
        case 'Custom':
            return t['com.affine.settings.editorSettings.edgeless.custom']();
        default:
            return '';
    }
};
export const getBaseFontStyleOptions = (t)=>{
    return fontStyleOptions.map(({ key, value })=>{
        if (key === 'Custom') {
            return null;
        }
        const label = getLabel(key, t);
        return {
            value: key,
            label,
            testId: 'system-font-style-trigger',
            style: {
                fontFamily: value
            }
        };
    }).filter((item)=>item !== null);
};
const FontFamilySettings = ()=>{
    const t = useI18n();
    const { editorSettingService } = useServices({
        EditorSettingService
    });
    const settings = useLiveData(editorSettingService.editorSetting.settings$);
    const radioItems = useMemo(()=>{
        const items = getBaseFontStyleOptions(t);
        if (!BUILD_CONFIG.isElectron) return items;
        const customOption = fontStyleOptions.find((opt)=>opt.key === 'Custom');
        if (customOption) {
            const fontFamily = settings.customFontFamily ? `${settings.customFontFamily}, ${customOption.value}` : customOption.value;
            items.push({
                value: customOption.key,
                label: getLabel(customOption.key, t),
                testId: 'system-font-style-trigger',
                style: {
                    fontFamily
                }
            });
        }
        return items;
    }, [
        settings.customFontFamily,
        t
    ]);
    const handleFontFamilyChange = useCallback((value)=>{
        editorSettingService.editorSetting.set('fontFamily', value);
    }, [
        editorSettingService.editorSetting
    ]);
    return _jsx(SettingRow, {
        name: t['com.affine.appearanceSettings.font.title'](),
        desc: t['com.affine.appearanceSettings.font.description'](),
        children: _jsx(RadioGroup, {
            items: radioItems,
            value: settings.fontFamily,
            width: 250,
            className: styles.settingWrapper,
            onChange: handleFontFamilyChange
        })
    });
};
const getFontFamily = (font)=>`${font}, ${fontStyleOptions[0].value}`;
const Scroller = forwardRef(({ children, ...props }, ref)=>{
    return _jsxs(Scrollable.Root, {
        children: [
            _jsx(Scrollable.Viewport, {
                ...props,
                ref: ref,
                children: children
            }),
            _jsx(Scrollable.Scrollbar, {})
        ]
    });
});
Scroller.displayName = 'Scroller';
const FontMenuItems = ({ onSelect })=>{
    const { systemFontFamilyService, editorSettingService } = useServices({
        SystemFontFamilyService,
        EditorSettingService
    });
    const systemFontFamily = systemFontFamilyService.systemFontFamily;
    const currentCustomFont = useLiveData(editorSettingService.editorSetting.settings$).customFontFamily;
    useEffect(()=>{
        if (systemFontFamily.fontList$.value.length === 0) {
            systemFontFamily.loadFontList();
        }
        systemFontFamily.clearSearch();
    }, [
        systemFontFamily
    ]);
    const isLoading = useLiveData(systemFontFamily.isLoading$);
    const result = useLiveData(systemFontFamily.result$);
    const searchText = useLiveData(systemFontFamily.searchText$);
    const onInputChange = useCallback((value)=>{
        systemFontFamily.search(value);
    }, [
        systemFontFamily
    ]);
    const onInputKeyDown = useCallback((e)=>{
        e.stopPropagation();
    }, []);
    return _jsxs("div", {
        children: [
            _jsxs("div", {
                className: styles.InputContainer,
                children: [
                    _jsx(SearchIcon, {
                        className: styles.searchIcon
                    }),
                    _jsx(RowInput, {
                        value: searchText ?? '',
                        onChange: onInputChange,
                        onKeyDown: onInputKeyDown,
                        autoFocus: true,
                        className: styles.searchInput,
                        placeholder: "Fonts"
                    })
                ]
            }),
            _jsx(MenuSeparator, {}),
            isLoading ? _jsx(Loading, {}) : _jsxs(Scrollable.Root, {
                style: {
                    height: '330px'
                },
                children: [
                    _jsx(Scrollable.Viewport, {
                        children: result.length > 0 ? _jsx(Virtuoso, {
                            totalCount: result.length,
                            components: {
                                Scroller: Scroller
                            },
                            itemContent: (index)=>_jsx(FontMenuItem, {
                                    font: result[index],
                                    onSelect: onSelect,
                                    currentFont: currentCustomFont
                                }, result[index].fullName)
                        }) : _jsx("div", {
                            className: styles.notFound,
                            children: "No results found."
                        })
                    }),
                    _jsx(Scrollable.Scrollbar, {})
                ]
            })
        ]
    });
};
const FontMenuItem = ({ font, currentFont, onSelect })=>{
    const handleFontSelect = useCallback(()=>onSelect(font.family), [
        font,
        onSelect
    ]);
    const fontFamily = getFontFamily(font.family);
    const selected = currentFont === font.fullName;
    return _jsx("div", {
        style: {
            marginTop: '4px'
        },
        children: _jsx(MenuItem, {
            onSelect: handleFontSelect,
            children: _jsxs("div", {
                className: styles.fontItemContainer,
                children: [
                    _jsxs("div", {
                        className: styles.fontItem,
                        children: [
                            _jsx("div", {
                                className: styles.fontLabel,
                                style: {
                                    fontFamily
                                },
                                children: font.fullName
                            }),
                            _jsx("div", {
                                className: clsx(styles.fontLabel, 'secondary'),
                                children: font.fullName
                            })
                        ]
                    }),
                    selected && _jsx(DoneIcon, {
                        fontSize: 20,
                        className: styles.selectedIcon
                    })
                ]
            })
        }, font.fullName)
    });
};
const CustomFontFamilySettings = ()=>{
    const t = useI18n();
    const { editorSettingService } = useServices({
        EditorSettingService
    });
    const settings = useLiveData(editorSettingService.editorSetting.settings$);
    const fontFamily = getFontFamily(settings.customFontFamily);
    const onCustomFontFamilyChange = useCallback((fontFamily)=>{
        editorSettingService.editorSetting.set('customFontFamily', fontFamily);
    }, [
        editorSettingService.editorSetting
    ]);
    if (settings.fontFamily !== 'Custom' || !BUILD_CONFIG.isElectron) {
        return null;
    }
    return _jsx(SettingRow, {
        name: t['com.affine.settings.editorSettings.general.font-family.custom.title'](),
        desc: t['com.affine.settings.editorSettings.general.font-family.custom.description'](),
        children: _jsx(Menu, {
            items: _jsx(FontMenuItems, {
                onSelect: onCustomFontFamilyChange
            }),
            contentOptions: {
                align: 'end',
                style: {
                    width: '250px',
                    height: '380px'
                }
            },
            children: _jsx(MenuTrigger, {
                className: styles.menuTrigger,
                style: {
                    fontFamily
                },
                children: settings.customFontFamily || 'Select a font'
            })
        })
    });
};
const NewDocDefaultModeSettings = ()=>{
    const t = useI18n();
    const { editorSettingService } = useServices({
        EditorSettingService
    });
    const settings = useLiveData(editorSettingService.editorSetting.settings$);
    const radioItems = useMemo(()=>[
            {
                value: 'page',
                label: t['Page'](),
                testId: 'page-mode-trigger'
            },
            {
                value: 'edgeless',
                label: t['Edgeless'](),
                testId: 'edgeless-mode-trigger'
            }
        ], [
        t
    ]);
    const updateNewDocDefaultMode = useCallback((value)=>{
        editorSettingService.editorSetting.set('newDocDefaultMode', value);
    }, [
        editorSettingService.editorSetting
    ]);
    return _jsx(SettingRow, {
        name: t['com.affine.settings.editorSettings.general.default-new-doc.title'](),
        desc: t['com.affine.settings.editorSettings.general.default-new-doc.description'](),
        children: _jsx(RadioGroup, {
            items: radioItems,
            value: settings.newDocDefaultMode,
            width: 250,
            className: styles.settingWrapper,
            onChange: updateNewDocDefaultMode
        })
    });
};
export const DeFaultCodeBlockSettings = ()=>{
    const t = useI18n();
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingRow, {
                name: t['com.affine.settings.editorSettings.general.default-code-block.language.title'](),
                desc: t['com.affine.settings.editorSettings.general.default-code-block.language.description'](),
                children: _jsx(DropdownMenu, {
                    items: _jsx(MenuItem, {
                        children: "Plain Text"
                    }),
                    trigger: _jsx(MenuTrigger, {
                        className: styles.menuTrigger,
                        disabled: true,
                        children: "Plain Text"
                    })
                })
            }),
            _jsx(SettingRow, {
                name: t['com.affine.settings.editorSettings.general.default-code-block.wrap.title'](),
                desc: t['com.affine.settings.editorSettings.general.default-code-block.wrap.description'](),
                children: _jsx(Switch, {})
            })
        ]
    });
};
export const SpellCheckSettings = ()=>{
    const t = useI18n();
    return _jsx(SettingRow, {
        name: t['com.affine.settings.editorSettings.general.spell-check.title'](),
        desc: t['com.affine.settings.editorSettings.general.spell-check.description'](),
        children: _jsx(Switch, {})
    });
};
const AISettings = ()=>{
    const t = useI18n();
    const { openConfirmModal } = useConfirmModal();
    const { featureFlagService } = useServices({
        FeatureFlagService
    });
    const enableAI = useLiveData(featureFlagService.flags.enable_ai.$);
    const onAIChange = useCallback((checked)=>{
        featureFlagService.flags.enable_ai.set(checked);
    }, [
        featureFlagService
    ]);
    const onToggleAI = useCallback((checked)=>{
        openConfirmModal({
            title: checked ? t['com.affine.settings.editorSettings.general.ai.enable.title']() : t['com.affine.settings.editorSettings.general.ai.disable.title'](),
            description: checked ? t['com.affine.settings.editorSettings.general.ai.enable.description']() : t['com.affine.settings.editorSettings.general.ai.disable.description'](),
            confirmText: checked ? t['com.affine.settings.editorSettings.general.ai.enable.confirm']() : t['com.affine.settings.editorSettings.general.ai.disable.confirm'](),
            cancelText: t['Cancel'](),
            onConfirm: ()=>onAIChange(checked),
            confirmButtonOptions: {
                variant: checked ? 'primary' : 'error'
            }
        });
    }, [
        openConfirmModal,
        t,
        onAIChange
    ]);
    return _jsx(SettingRow, {
        name: t['com.affine.settings.editorSettings.general.ai.title'](),
        desc: t['com.affine.settings.editorSettings.general.ai.description'](),
        children: _jsx(Switch, {
            checked: enableAI,
            onChange: onToggleAI
        })
    });
};
export const General = ()=>{
    const t = useI18n();
    return _jsxs(SettingWrapper, {
        title: t['com.affine.settings.editorSettings.general'](),
        children: [
            _jsx(AISettings, {}),
            _jsx(FontFamilySettings, {}),
            _jsx(CustomFontFamilySettings, {}),
            _jsx(NewDocDefaultModeSettings, {})
        ]
    });
};
