import { DocCollection } from '@blocksuite/affine/store';
import { nanoid } from 'nanoid';
import { Observable } from 'rxjs';
import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
import { WorkspaceDBService } from '../../db';
import { getAFFiNEWorkspaceSchema } from '../global-schema';
import { WorkspaceEngineService } from '../services/engine';
import { WorkspaceUpgradeService } from '../services/upgrade';
export class Workspace extends Entity {
    constructor(scope){
        super(), this.scope = scope, this.id = this.scope.props.openOptions.metadata.id, this.openOptions = this.scope.props.openOptions, this.meta = this.scope.props.openOptions.metadata, this.flavour = this.meta.flavour, this._docCollection = null, this.name$ = LiveData.from(new Observable((subscriber)=>{
            subscriber.next(this.docCollection.meta.name);
            return this.docCollection.meta.commonFieldsUpdated.on(()=>{
                subscriber.next(this.docCollection.meta.name);
            }).dispose;
        }), undefined), this.avatar$ = LiveData.from(new Observable((subscriber)=>{
            subscriber.next(this.docCollection.meta.avatar);
            return this.docCollection.meta.commonFieldsUpdated.on(()=>{
                subscriber.next(this.docCollection.meta.avatar);
            }).dispose;
        }), undefined);
    }
    get docCollection() {
        if (!this._docCollection) {
            this._docCollection = new DocCollection({
                id: this.openOptions.metadata.id,
                blobSources: {
                    main: this.engine.blob
                },
                idGenerator: ()=>nanoid(),
                schema: getAFFiNEWorkspaceSchema()
            });
            this._docCollection.slots.docCreated.on((id)=>{
                this.engine.doc.markAsReady(id);
            });
        }
        return this._docCollection;
    }
    get db() {
        return this.framework.get(WorkspaceDBService).db;
    }
    get awareness() {
        return this.docCollection.awarenessStore.awareness;
    }
    get rootYDoc() {
        return this.docCollection.doc;
    }
    get canGracefulStop() {
        return true;
    }
    get engine() {
        return this.framework.get(WorkspaceEngineService).engine;
    }
    get upgrade() {
        return this.framework.get(WorkspaceUpgradeService).upgrade;
    }
}
