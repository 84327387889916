import { focusTextModel } from '@blocksuite/affine-components/rich-text';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { correctNumberedListsOrderToPrev } from './utils.js';
export const dedentListCommand = (ctx, next)=>{
    let { blockId, inlineIndex } = ctx;
    const { std } = ctx;
    const { selection, doc } = std;
    if (!blockId) {
        const text = selection.find('text');
        if (!text || text.to && text.from.blockId !== text.to.blockId) {
            return;
        }
        blockId = text.from.blockId;
        inlineIndex = text.from.index;
    }
    if (blockId == null || inlineIndex == null) {
        return;
    }
    const model = doc.getBlock(blockId)?.model;
    if (!model || !matchFlavours(model, [
        'affine:list'
    ])) {
        console.error(`block ${blockId} is not a list block`);
        return;
    }
    const parent = doc.getParent(model);
    if (!parent) {
        console.error(`block ${blockId} has no parent`);
        return;
    }
    if (doc.readonly || parent.role !== 'content') {
        return;
    }
    const grandParent = doc.getParent(parent);
    if (!grandParent) {
        console.error(`block ${blockId} has no grand parent`);
        return;
    }
    const modelIndex = parent.children.indexOf(model);
    if (modelIndex === -1) {
        console.error(`block ${blockId} is not a child of its parent`);
        return;
    }
    doc.captureSync();
    const nextSiblings = doc.getNexts(model);
    doc.moveBlocks(nextSiblings, model);
    const nextSibling = nextSiblings.at(0);
    if (nextSibling) correctNumberedListsOrderToPrev(doc, nextSibling);
    doc.moveBlocks([
        model
    ], grandParent, parent, false);
    correctNumberedListsOrderToPrev(doc, model);
    focusTextModel(std, model.id, inlineIndex);
    return next();
};
