import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { SaveIcon } from '@blocksuite/icons/rc';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { createEmptyCollection } from '../use-collection-manager';
import * as styles from './save-as-collection-button.css';
import { useEditCollectionName } from './use-edit-collection';
export const SaveAsCollectionButton = ({ onConfirm })=>{
    const t = useI18n();
    const { open } = useEditCollectionName({
        title: t['com.affine.editCollection.saveCollection'](),
        showTips: true
    });
    const handleClick = useCallback(()=>{
        open('').then((name)=>{
            return onConfirm(createEmptyCollection(nanoid(), {
                name
            }));
        }).catch((err)=>{
            console.error(err);
        });
    }, [
        open,
        onConfirm
    ]);
    return _jsx(Button, {
        onClick: handleClick,
        "data-testid": "save-as-collection",
        prefix: _jsx(SaveIcon, {}),
        className: styles.button,
        children: t['com.affine.editCollection.saveCollection']()
    });
};
