import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useAutoFocus, useAutoSelect } from '../../hooks';
export const RowInput = forwardRef(function RowInput({ disabled, onChange: propsOnChange, className, style = {}, onEnter, onKeyDown, onBlur, autoFocus, autoSelect, ...otherProps }, upstreamRef) {
    const [composing, setComposing] = useState(false);
    const focusRef = useAutoFocus(autoFocus);
    const selectRef = useAutoSelect(autoSelect);
    const inputRef = (el)=>{
        focusRef.current = el;
        selectRef.current = el;
        if (upstreamRef) {
            if (typeof upstreamRef === 'function') {
                upstreamRef(el);
            } else {
                upstreamRef.current = el;
            }
        }
    };
    useEffect(()=>{
        if (!onBlur) return;
        selectRef.current?.addEventListener('blur', onBlur);
        return ()=>{
            selectRef.current?.removeEventListener('blur', onBlur);
        };
    }, [
        onBlur,
        selectRef
    ]);
    const handleChange = useCallback((e)=>{
        propsOnChange?.(e.target.value);
    }, [
        propsOnChange
    ]);
    const handleKeyDown = useCallback((e)=>{
        onKeyDown?.(e);
        if (e.key !== 'Enter' || composing) {
            return;
        }
        onEnter?.();
    }, [
        onKeyDown,
        composing,
        onEnter
    ]);
    const handleCompositionStart = useCallback(()=>{
        setComposing(true);
    }, []);
    const handleCompositionEnd = useCallback(()=>{
        setComposing(false);
    }, []);
    return _jsx("input", {
        className: className,
        ref: inputRef,
        disabled: disabled,
        style: style,
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        onCompositionStart: handleCompositionStart,
        onCompositionEnd: handleCompositionEnd,
        ...otherProps
    });
});
