import { AICodeBlockSpec, AIImageBlockSpec, AIParagraphBlockSpec } from '@affine/core/blocksuite/presets/ai';
import { BookmarkBlockSpec, CodeBlockSpec, DatabaseBlockSpec, DataViewBlockSpec, DividerBlockSpec, EditPropsStore, EmbedFigmaBlockSpec, EmbedGithubBlockSpec, EmbedHtmlBlockSpec, EmbedLinkedDocBlockSpec, EmbedLoomBlockSpec, EmbedSyncedDocBlockSpec, EmbedYoutubeBlockSpec, ImageBlockSpec, LatexBlockSpec, ListBlockSpec, ParagraphBlockSpec, RefNodeSlotsExtension, RichTextExtensions } from '@blocksuite/affine/blocks';
import { AIChatBlockSpec } from '@blocksuite/affine/presets';
import { CustomAttachmentBlockSpec } from './custom/attachment-block';
const CommonBlockSpecs = [
    RefNodeSlotsExtension(),
    EditPropsStore,
    RichTextExtensions,
    LatexBlockSpec,
    ListBlockSpec,
    DatabaseBlockSpec,
    DataViewBlockSpec,
    DividerBlockSpec,
    BookmarkBlockSpec,
    EmbedFigmaBlockSpec,
    EmbedGithubBlockSpec,
    EmbedYoutubeBlockSpec,
    EmbedLoomBlockSpec,
    EmbedHtmlBlockSpec,
    EmbedSyncedDocBlockSpec,
    EmbedLinkedDocBlockSpec,
    CustomAttachmentBlockSpec
].flat();
export const DefaultBlockSpecs = [
    CodeBlockSpec,
    ImageBlockSpec,
    ParagraphBlockSpec,
    ...CommonBlockSpecs
].flat();
export const AIBlockSpecs = [
    AICodeBlockSpec,
    AIImageBlockSpec,
    AIParagraphBlockSpec,
    AIChatBlockSpec,
    ...CommonBlockSpecs
].flat();
