import { FeedNodeType } from '../feeds';
export const FavoriteSupportType = [
    'collection',
    'doc',
    'tag',
    'folder',
    FeedNodeType.RSS,
    FeedNodeType.Folder
];
export const isFavoriteSupportType = (type)=>FavoriteSupportType.includes(type);
