const currency = (currency)=>({
        style: 'currency',
        currency,
        currencyDisplay: 'symbol'
    });
const numberFormatDefaultConfig = {
    number: {
        style: 'decimal',
        useGrouping: false
    },
    numberWithCommas: {
        style: 'decimal',
        useGrouping: true
    },
    percent: {
        style: 'percent',
        useGrouping: false
    },
    currencyINR: currency('INR'),
    currencyYen: currency('JPY'),
    currencyCNY: currency('CNY'),
    currencyUSD: currency('USD'),
    currencyEUR: currency('EUR'),
    currencyGBP: currency('GBP')
};
export function formatNumber(value, format, decimals) {
    const formatterOptions = {
        ...numberFormatDefaultConfig[format]
    };
    if (decimals !== undefined) {
        Object.assign(formatterOptions, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    const formatter = new Intl.NumberFormat(navigator.language, formatterOptions);
    return formatter.format(value);
}
export function getLocaleDecimalSeparator(locale) {
    return 1.1.toLocaleString(locale ?? navigator.language).slice(1, 2);
}
export function parseNumber(value, decimalSeparator) {
    decimalSeparator = decimalSeparator ?? getLocaleDecimalSeparator();
    const normalizedValue = value.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');
    let sanitizedValue = normalizedValue.replace(/^[^\d-+eE.]+|[^\d]+$/g, '');
    sanitizedValue = sanitizedValue.replace(/[^0-9.eE+-]/g, '');
    sanitizedValue = sanitizedValue.replace(/([-+]){2,}/g, '$1');
    sanitizedValue = sanitizedValue.replace(/^([-+]?)[^eE]*([eE][-+]?\d+)?$/, (_, p1, p2)=>p1 + sanitizedValue.replace(/[eE].*/, '').replace(/[^\d.]/g, '') + (p2 || ''));
    sanitizedValue = sanitizedValue.replace(/(\..*)\./g, '$1');
    if (/[eE]/.test(sanitizedValue)) {
        const [base, exp] = sanitizedValue.split(/[eE]/);
        if (!base || !exp || exp.includes('.') || exp.includes('e') || exp.includes('E')) {
            return NaN;
        }
        return parseFloat(sanitizedValue);
    }
    return parseFloat(sanitizedValue);
}
