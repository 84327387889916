import { jsx as _jsx } from "react/jsx-runtime";
import { ProviderComposer } from '@affine/component/provider-composer';
import { ThemeProvider } from '@affine/component/theme-provider';
import { Provider } from 'jotai';
import { useMemo } from 'react';
import { ConfirmModalProvider } from '../../ui/modal';
export function AffineContext(props) {
    return _jsx(ProviderComposer, {
        contexts: useMemo(()=>[
                _jsx(Provider, {
                    store: props.store
                }, "JotaiProvider"),
                _jsx(ThemeProvider, {}, "ThemeProvider"),
                _jsx(ConfirmModalProvider, {}, "ConfirmModalProvider")
            ].filter(Boolean), [
            props.store
        ]),
        children: props.children
    });
}
