import { isNil, isObject, merge } from 'lodash-es';
import { GraphQLError } from './error';
function filterEmptyValue(vars) {
    const newVars = {};
    Object.entries(vars).forEach(([key, value])=>{
        if (isNil(value)) {
            return;
        }
        if (isObject(value) && !(value instanceof File)) {
            newVars[key] = filterEmptyValue(value);
            return;
        }
        newVars[key] = value;
    });
    return newVars;
}
export function transformToForm(body) {
    const form = new FormData();
    const gqlBody = {
        query: body.query,
        variables: body.variables,
        map: {}
    };
    if (body.operationName) {
        gqlBody.name = body.operationName;
    }
    const map = {};
    const files = [];
    if (body.variables) {
        let i = 0;
        const buildMap = (key, value)=>{
            if (value instanceof File) {
                map['' + i] = [
                    key
                ];
                files[i] = value;
                i++;
            } else if (Array.isArray(value)) {
                value.forEach((v, index)=>{
                    buildMap(`${key}.${index}`, v);
                });
            } else if (isObject(value)) {
                Object.entries(value).forEach(([k, v])=>{
                    buildMap(`${key}.${k}`, v);
                });
            }
        };
        buildMap('variables', body.variables);
    }
    form.set('operations', JSON.stringify(gqlBody));
    form.set('map', JSON.stringify(map));
    for (const [i, file] of files.entries()){
        form.set(`${i}`, file);
    }
    return form;
}
function formatRequestBody({ query, variables, keepNilVariables }) {
    const body = {
        query: query.query,
        variables: keepNilVariables ?? true ? variables : filterEmptyValue(variables)
    };
    if (query.operationName) {
        body.operationName = query.operationName;
    }
    if (query.containsFile) {
        return transformToForm(body);
    }
    return body;
}
export const gqlFetcherFactory = (endpoint, fetcher = fetch)=>{
    const gqlFetch = async (options)=>{
        const body = formatRequestBody(options);
        const isFormData = body instanceof FormData;
        const headers = {
            'x-operation-name': options.query.operationName,
            'x-definition-name': options.query.definitionName
        };
        if (!isFormData) {
            headers['content-type'] = 'application/json';
        }
        const ret = fetcher(endpoint, merge(options.context, {
            method: 'POST',
            headers,
            body: isFormData ? body : JSON.stringify(body)
        })).then(async (res)=>{
            if (res.headers.get('content-type')?.startsWith('application/json')) {
                const result = await res.json();
                if (res.status >= 400 || result.errors) {
                    if (result.errors && result.errors.length > 0) {
                        const firstError = result.errors[0];
                        throw new GraphQLError(firstError.message, firstError);
                    } else {
                        throw new GraphQLError('Empty GraphQL error body');
                    }
                } else if (result.data) {
                    return result.data;
                }
            }
            throw new GraphQLError('GraphQL query responds unexpected result, query ' + options.query.operationName);
        });
        return ret;
    };
    return gqlFetch;
};
