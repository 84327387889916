function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _init__categories, _init__currentCategory, _init__loading, _init__loadingTemplate, _init__searchKeyword, _init__templates, _init_edgeless, _init_isDragging, _initProto;
import { EditPropsStore } from '@blocksuite/affine-shared/services';
import { requestConnectedFrame, stopPropagation } from '@blocksuite/affine-shared/utils';
import { Bound, getCommonBound, WithDisposable } from '@blocksuite/global/utils';
import { baseTheme } from '@toeverything/theme';
import { css, html, LitElement, nothing, unsafeCSS } from 'lit';
import { property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { createInsertPlaceMiddleware, createRegenerateIndexMiddleware, createStickerMiddleware, replaceIdMiddleware } from '../../../services/template-middlewares.js';
import { EdgelessDraggableElementController } from '../common/draggable/draggable-element.controller.js';
import { builtInTemplates } from './builtin-templates.js';
import { ArrowIcon, defaultPreview } from './icon.js';
import { cloneDeep } from './utils.js';
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = state(), _dec5 = state(), _dec6 = property({
    attribute: false
}), _dec7 = state();
export class EdgelessTemplatePanel extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__categories, _init__currentCategory, _init__loading, _init__loadingTemplate, _init__searchKeyword, _init__templates, _init_edgeless, _init_isDragging, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_categories"
            ],
            [
                _dec1,
                1,
                "_currentCategory"
            ],
            [
                _dec2,
                1,
                "_loading"
            ],
            [
                _dec3,
                1,
                "_loadingTemplate"
            ],
            [
                _dec4,
                1,
                "_searchKeyword"
            ],
            [
                _dec5,
                1,
                "_templates"
            ],
            [
                _dec6,
                1,
                "edgeless"
            ],
            [
                _dec7,
                1,
                "isDragging"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      position: absolute;
      font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
      z-index: 1;
    }

    .edgeless-templates-panel {
      width: 467px;
      height: 568px;
      border-radius: 12px;
      background-color: var(--affine-background-overlay-panel-color);
      box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 0.2);

      display: flex;
      flex-direction: column;
    }

    .search-bar {
      padding: 21px 24px;
      font-size: 18px;
      color: var(--affine-secondary);
      border-bottom: 1px solid var(--affine-divider-color);

      flex-shrink: 0;
    }

    .search-input {
      border: 0;
      color: var(--affine-text-primary-color);
      font-size: 20px;
      background-color: inherit;
      outline: none;
      width: 100%;
    }

    .search-input::placeholder {
      color: var(--affine-text-secondary-color);
    }

    .template-categories {
      display: flex;
      padding: 6px 8px;
      gap: 4px;
      overflow-x: scroll;

      flex-shrink: 0;
    }

    .category-entry {
      color: var(--affine-text-primary-color);
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 8px;
      flex-shrink: 0;
      flex-grow: 0;
      width: fit-content;
      padding: 4px 9px;
      cursor: pointer;
    }

    .category-entry.selected,
    .category-entry:hover {
      color: var(--affine-text-primary-color);
      background-color: var(--affine-background-tertiary-color);
    }

    .template-viewport {
      position: relative;
      flex-grow: 1;
    }

    .template-scrollcontent {
      overflow: hidden;
      height: 100%;
      width: 100%;
    }

    .template-list {
      padding: 10px;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 10px 20px;
      flex-wrap: wrap;
    }

    .template-item {
      position: relative;
      width: 135px;
      height: 80px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
      background-color: var(--affine-background-primary-color);
      border-radius: 4px;
      cursor: pointer;
    }

    .template-item > svg {
      display: block;
      margin: 0 auto;
      width: 135px;
      height: 80px;
      color: var(--affine-background-primary-color);
    }

    /* .template-item:hover::before {
      content: attr(data-hover-text);
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 110px;
      border-radius: 8px;
      padding: 4px 22px;
      box-sizing: border-box;
      z-index: 1;
      text-align: center;
      font-size: 12px;

      background-color: var(--affine-primary-color);
      color: var(--affine-white);
    } */

    .template-item:hover::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid var(--affine-black-10);
      border-radius: 4px;
      background-color: var(--affine-hover-color);
    }

    .template-item.loading::before {
      display: none;
    }

    .template-item.loading > affine-template-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .template-item img.template-preview {
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: block;
    }

    .arrow {
      bottom: 0;
      position: absolute;
      transform: translateY(20px);
      color: var(--affine-background-overlay-panel-color);
    }
  `;
    }
    static{
        this.templates = builtInTemplates;
    }
    _closePanel() {
        if (this.isDragging) return;
        this.dispatchEvent(new CustomEvent('closepanel'));
    }
    _createTemplateJob(type, center) {
        const middlewares = [];
        const service = this.edgeless.service;
        if (type === 'template') {
            const currentContentBound = getCommonBound(service.blocks.map((block)=>Bound.deserialize(block.xywh)).concat(service.elements));
            if (currentContentBound) {
                currentContentBound.x += currentContentBound.w + 20 / service.viewport.zoom;
                middlewares.push(createInsertPlaceMiddleware(currentContentBound));
            }
            const idxGenerator = service.layer.createIndexGenerator();
            middlewares.push(createRegenerateIndexMiddleware(()=>idxGenerator()));
        }
        if (type === 'sticker') {
            middlewares.push(createStickerMiddleware(center, ()=>service.layer.generateIndex()));
        }
        middlewares.push(replaceIdMiddleware);
        return this.edgeless.service.TemplateJob.create({
            model: this.edgeless.surfaceBlockModel,
            type,
            middlewares
        });
    }
    _fetch(fn) {
        if (this._fetchJob) {
            this._fetchJob.cancel();
        }
        this._loading = true;
        const state = {
            canceled: false
        };
        const job = {
            cancel: ()=>{
                state.canceled = true;
            }
        };
        this._fetchJob = job;
        fn(state).catch(()=>{}).finally(()=>{
            if (!state.canceled && job === this._fetchJob) {
                this._loading = false;
                this._fetchJob = null;
            }
        });
    }
    _getLocalSelectedCategory() {
        return this.edgeless.std.get(EditPropsStore).getStorage('templateCache');
    }
    async _initCategory() {
        try {
            this._categories = await EdgelessTemplatePanel.templates.categories();
            this._currentCategory = this._getLocalSelectedCategory() ?? this._categories[0];
            this._updateTemplates();
        } catch (e) {
            console.error('Failed to load categories', e);
        }
    }
    _initDragController() {
        if (this.draggableController) return;
        this.draggableController = new EdgelessDraggableElementController(this, {
            service: this.edgeless.service,
            edgeless: this.edgeless,
            clickToDrag: true,
            standardWidth: 560,
            onOverlayCreated: (overlay)=>{
                this.isDragging = true;
                overlay.mask.style.color = 'transparent';
            },
            onDrop: (el, bound)=>{
                this._insertTemplate(el.data, bound).finally(()=>{
                    this.isDragging = false;
                }).catch(console.error);
            },
            onCanceled: ()=>{
                this.isDragging = false;
            }
        });
    }
    async _insertTemplate(template, bound) {
        this._loadingTemplate = template;
        template = cloneDeep(template);
        const center = {
            x: bound.x + bound.w / 2,
            y: bound.y + bound.h / 2
        };
        const templateJob = this._createTemplateJob(template.type, center);
        const service = this.edgeless.service;
        try {
            const { assets } = template;
            if (assets) {
                await Promise.all(Object.entries(assets).map(([key, value])=>fetch(value).then((res)=>res.blob()).then((blob)=>templateJob.job.assets.set(key, blob))));
            }
            const insertedBound = await templateJob.insertTemplate(template.content);
            if (insertedBound && template.type === 'template') {
                const padding = 20 / service.viewport.zoom;
                service.viewport.setViewportByBound(insertedBound, [
                    padding,
                    padding,
                    padding,
                    padding
                ], true);
            }
        } finally{
            this._loadingTemplate = null;
            this.edgeless.service.tool.setEdgelessTool({
                type: 'default'
            });
        }
    }
    _updateSearchKeyword(inputEvt) {
        this._searchKeyword = inputEvt.target.value;
        this._updateTemplates();
    }
    _updateTemplates() {
        this._fetch(async (state)=>{
            try {
                const templates = this._searchKeyword ? await EdgelessTemplatePanel.templates.search(this._searchKeyword) : await EdgelessTemplatePanel.templates.list(this._currentCategory);
                if (state.canceled) return;
                this._templates = templates;
            } catch (e) {
                if (state.canceled) return;
                console.error('Failed to load templates', e);
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this._initDragController();
        this.addEventListener('keydown', stopPropagation, false);
        this._disposables.add(()=>{
            if (this._currentCategory) {
                this.edgeless.std.get(EditPropsStore).setStorage('templateCache', this._currentCategory);
            }
        });
    }
    firstUpdated() {
        requestConnectedFrame(()=>{
            this._disposables.addFromEvent(document, 'click', (evt)=>{
                if (this.contains(evt.target)) {
                    return;
                }
                this._closePanel();
            });
        }, this);
        this._disposables.addFromEvent(this, 'click', stopPropagation);
        this._disposables.addFromEvent(this, 'wheel', stopPropagation);
        this._initCategory().catch(()=>{});
    }
    render() {
        const { _categories, _currentCategory, _templates } = this;
        const { draggingElement } = this.draggableController?.states || {};
        return html`
      <div
        class="edgeless-templates-panel"
        style=${styleMap({
            opacity: this.isDragging ? '0' : '1',
            transition: 'opacity 0.2s'
        })}
      >
        <div class="search-bar">
          <input
            class="search-input"
            type="text"
            placeholder="Search file or anything..."
            @input=${this._updateSearchKeyword}
            @cut=${stopPropagation}
            @copy=${stopPropagation}
            @paste=${stopPropagation}
          />
        </div>
        <div class="template-categories">
          ${repeat(_categories, (cate)=>cate, (cate)=>{
            return html`<div
                class="category-entry ${_currentCategory === cate ? 'selected' : ''}"
                @click=${()=>{
                this._currentCategory = cate;
                this._updateTemplates();
            }}
              >
                ${cate}
              </div>`;
        })}
        </div>
        <div class="template-viewport">
          <div class="template-scrollcontent" data-scrollable>
            <div class="template-list">
              ${this._loading ? html`<affine-template-loading
                    style=${styleMap({
            position: 'absolute',
            left: '50%',
            top: '50%'
        })}
                  ></affine-template-loading>` : repeat(_templates, (template)=>template.name, (template)=>{
            const preview = template.preview ? template.preview.startsWith('<svg') ? html`${unsafeSVG(template.preview)}` : html`<img
                              src="${template.preview}"
                              class="template-preview"
                            />` : defaultPreview;
            const isBeingDragged = draggingElement && draggingElement.data.name === template.name;
            return html`
                        <div
                          class=${`template-item ${template === this._loadingTemplate ? 'loading' : ''}`}
                          style=${styleMap({
                opacity: isBeingDragged ? '0' : '1'
            })}
                          data-hover-text="Add"
                          @mousedown=${(e)=>this.draggableController.onMouseDown(e, {
                    data: template,
                    preview
                })}
                          @touchstart=${(e)=>{
                this.draggableController.onTouchStart(e, {
                    data: template,
                    preview
                });
            }}
                        >
                          ${preview}
                          ${template === this._loadingTemplate ? html`<affine-template-loading></affine-template-loading>` : nothing}
                          ${template.name ? html`<affine-tooltip
                                .offset=${12}
                                tip-position="top"
                              >
                                ${template.name}
                              </affine-tooltip>` : nothing}
                        </div>
                      `;
        })}
            </div>
          </div>
          <overlay-scrollbar></overlay-scrollbar>
        </div>
        <div class="arrow">${ArrowIcon}</div>
      </div>
    `;
    }
    #___private__categories_1;
    get _categories() {
        return this.#___private__categories_1;
    }
    set _categories(_v) {
        this.#___private__categories_1 = _v;
    }
    #___private__currentCategory_2;
    get _currentCategory() {
        return this.#___private__currentCategory_2;
    }
    set _currentCategory(_v) {
        this.#___private__currentCategory_2 = _v;
    }
    #___private__loading_3;
    get _loading() {
        return this.#___private__loading_3;
    }
    set _loading(_v) {
        this.#___private__loading_3 = _v;
    }
    #___private__loadingTemplate_4;
    get _loadingTemplate() {
        return this.#___private__loadingTemplate_4;
    }
    set _loadingTemplate(_v) {
        this.#___private__loadingTemplate_4 = _v;
    }
    #___private__searchKeyword_5;
    get _searchKeyword() {
        return this.#___private__searchKeyword_5;
    }
    set _searchKeyword(_v) {
        this.#___private__searchKeyword_5 = _v;
    }
    #___private__templates_6;
    get _templates() {
        return this.#___private__templates_6;
    }
    set _templates(_v) {
        this.#___private__templates_6 = _v;
    }
    #___private_edgeless_7;
    get edgeless() {
        return this.#___private_edgeless_7;
    }
    set edgeless(_v) {
        this.#___private_edgeless_7 = _v;
    }
    #___private_isDragging_8;
    get isDragging() {
        return this.#___private_isDragging_8;
    }
    set isDragging(_v) {
        this.#___private_isDragging_8 = _v;
    }
    constructor(...args){
        super(...args), this._fetchJob = null, this.#___private__categories_1 = (_initProto(this), _init__categories(this, [])), this.#___private__currentCategory_2 = _init__currentCategory(this, ''), this.#___private__loading_3 = _init__loading(this, false), this.#___private__loadingTemplate_4 = _init__loadingTemplate(this, null), this.#___private__searchKeyword_5 = _init__searchKeyword(this, ''), this.#___private__templates_6 = _init__templates(this, []), this.#___private_edgeless_7 = _init_edgeless(this), this.#___private_isDragging_8 = _init_isDragging(this, false);
    }
}
