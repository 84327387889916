import { createAutoIncrementIdGenerator, createAutoIncrementIdGeneratorByClientId, nanoid, uuidv4 } from '../utils/id-generator.js';
export var IdGeneratorType;
(function(IdGeneratorType) {
    IdGeneratorType["AutoIncrement"] = "autoIncrement";
    IdGeneratorType["AutoIncrementByClientId"] = "autoIncrementByClientId";
    IdGeneratorType["NanoID"] = "nanoID";
    IdGeneratorType["UUIDv4"] = "uuidV4";
})(IdGeneratorType || (IdGeneratorType = {}));
export function pickIdGenerator(idGenerator, clientId) {
    if (typeof idGenerator === 'function') {
        return idGenerator;
    }
    switch(idGenerator){
        case "autoIncrement":
            {
                return createAutoIncrementIdGenerator();
            }
        case "autoIncrementByClientId":
            {
                return createAutoIncrementIdGeneratorByClientId(clientId);
            }
        case "uuidV4":
            {
                return uuidv4;
            }
        case "nanoID":
        default:
            {
                return nanoid;
            }
    }
}
