import { jsx as _jsx } from "react/jsx-runtime";
import { Menu } from '@affine/component';
import { useNavigateHelper } from '@affine/core/components/hooks/use-navigate-helper';
import { track } from '@affine/track';
import { GlobalContextService, useLiveData, useServices, WorkspacesService } from '@toeverything/infra';
import { useCallback, useEffect, useState } from 'react';
import { UserWithWorkspaceList } from './user-with-workspace-list';
import { WorkspaceCard } from './workspace-card';
export const WorkspaceSelector = ({ workspaceMetadata: outerWorkspaceMetadata, onSelectWorkspace, onCreatedWorkspace, showSettingsButton, showArrowDownIcon, disable, open: outerOpen, showEnableCloudButton, showSyncStatus, className, menuContentOptions })=>{
    const { workspacesService, globalContextService } = useServices({
        GlobalContextService,
        WorkspacesService
    });
    const [innerOpen, setOpened] = useState(false);
    const open = outerOpen ?? innerOpen;
    const closeUserWorkspaceList = useCallback(()=>{
        setOpened(false);
    }, []);
    const openUserWorkspaceList = useCallback(()=>{
        track.$.navigationPanel.workspaceList.open();
        setOpened(true);
    }, []);
    const currentWorkspaceId = useLiveData(globalContextService.globalContext.workspaceId.$);
    const currentWorkspaceMetadata = useLiveData(currentWorkspaceId ? workspacesService.list.workspace$(currentWorkspaceId) : null);
    const workspaceMetadata = outerWorkspaceMetadata ?? currentWorkspaceMetadata;
    useEffect(()=>{
        if (open) {
            workspacesService.list.revalidate();
        }
    }, [
        workspacesService,
        open
    ]);
    return _jsx(Menu, {
        rootOptions: {
            open
        },
        items: _jsx(UserWithWorkspaceList, {
            onEventEnd: closeUserWorkspaceList,
            onClickWorkspace: onSelectWorkspace,
            onCreatedWorkspace: onCreatedWorkspace,
            showEnableCloudButton: showEnableCloudButton,
            showSettingsButton: showSettingsButton
        }),
        contentOptions: {
            sideOffset: -58,
            onInteractOutside: closeUserWorkspaceList,
            onEscapeKeyDown: closeUserWorkspaceList,
            ...menuContentOptions,
            style: {
                width: '300px',
                ...menuContentOptions?.style
            }
        },
        children: workspaceMetadata ? _jsx(WorkspaceCard, {
            workspaceMetadata: workspaceMetadata,
            onClick: openUserWorkspaceList,
            showSyncStatus: showSyncStatus,
            className: className,
            showArrowDownIcon: showArrowDownIcon,
            disable: disable,
            hideCollaborationIcon: true,
            "data-testid": "current-workspace-card"
        }) : _jsx("span", {})
    });
};
export const WorkspaceNavigator = ({ onSelectWorkspace, onCreatedWorkspace, ...props })=>{
    const { jumpToPage } = useNavigateHelper();
    const handleClickWorkspace = useCallback((workspaceMetadata)=>{
        onSelectWorkspace?.(workspaceMetadata);
        if (document.startViewTransition) {
            document.startViewTransition(()=>{
                jumpToPage(workspaceMetadata.id, 'all');
                return new Promise((resolve)=>setTimeout(resolve, 150));
            });
        } else {
            jumpToPage(workspaceMetadata.id, 'all');
        }
    }, [
        onSelectWorkspace,
        jumpToPage
    ]);
    const handleCreatedWorkspace = useCallback((payload)=>{
        onCreatedWorkspace?.(payload);
        if (document.startViewTransition) {
            document.startViewTransition(()=>{
                if (payload.defaultDocId) {
                    jumpToPage(payload.meta.id, payload.defaultDocId);
                } else {
                    jumpToPage(payload.meta.id, 'all');
                }
                return new Promise((resolve)=>setTimeout(resolve, 150));
            });
        } else {
            if (payload.defaultDocId) {
                jumpToPage(payload.meta.id, payload.defaultDocId);
            } else {
                jumpToPage(payload.meta.id, 'all');
            }
        }
    }, [
        jumpToPage,
        onCreatedWorkspace
    ]);
    return _jsx(WorkspaceSelector, {
        onSelectWorkspace: handleClickWorkspace,
        onCreatedWorkspace: handleCreatedWorkspace,
        ...props
    });
};
