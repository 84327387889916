import { openDB } from 'idb';
import { mergeUpdates } from 'yjs';
class BroadcastChannelDocEventBus {
    constructor(userId){
        this.userId = userId;
        this.senderChannel = new BroadcastChannel('user-db:' + this.userId);
    }
    emit(event) {
        this.senderChannel.postMessage(event);
    }
    on(cb) {
        const listener = (event)=>{
            cb(event.data);
        };
        const channel = new BroadcastChannel('user-db:' + this.userId);
        channel.addEventListener('message', listener);
        return ()=>{
            channel.removeEventListener('message', listener);
            channel.close();
        };
    }
}
function isEmptyUpdate(binary) {
    return binary.byteLength === 0 || binary.byteLength === 2 && binary[0] === 0 && binary[1] === 0;
}
export class IndexedDBUserspaceDocStorage {
    constructor(userId){
        this.userId = userId;
        this.eventBus = new BroadcastChannelDocEventBus(this.userId);
        this.doc = new Doc(this.userId);
        this.syncMetadata = new KV(`affine-cloud:${this.userId}:sync-metadata`);
        this.serverClock = new KV(`affine-cloud:${this.userId}:server-clock`);
    }
}
class Doc {
    constructor(userId){
        this.userId = userId;
        this.dbName = 'affine-cloud:' + this.userId + ':doc';
        this.dbPromise = null;
        this.dbVersion = 1;
    }
    upgradeDB(db) {
        db.createObjectStore('userspace', {
            keyPath: 'id'
        });
    }
    getDb() {
        if (this.dbPromise === null) {
            this.dbPromise = openDB(this.dbName, this.dbVersion, {
                upgrade: (db)=>this.upgradeDB(db)
            });
        }
        return this.dbPromise;
    }
    async get(docId) {
        const db = await this.getDb();
        const store = db.transaction('userspace', 'readonly').objectStore('userspace');
        const data = await store.get(docId);
        if (!data) {
            return null;
        }
        const updates = data.updates.map(({ update })=>update).filter((update)=>!isEmptyUpdate(update));
        const update = updates.length > 0 ? mergeUpdates(updates) : null;
        return update;
    }
    async set(docId, data) {
        const db = await this.getDb();
        const store = db.transaction('userspace', 'readwrite').objectStore('userspace');
        const rows = [
            {
                timestamp: Date.now(),
                update: data
            }
        ];
        await store.put({
            id: docId,
            updates: rows
        });
    }
    async keys() {
        const db = await this.getDb();
        const store = db.transaction('userspace', 'readonly').objectStore('userspace');
        return store.getAllKeys();
    }
    clear() {
        return;
    }
    del(_key) {
        return;
    }
    async transaction(cb) {
        const db = await this.getDb();
        const store = db.transaction('userspace', 'readwrite').objectStore('userspace');
        return await cb({
            async get (docId) {
                const data = await store.get(docId);
                if (!data) {
                    return null;
                }
                const { updates } = data;
                const update = mergeUpdates(updates.map(({ update })=>update));
                return update;
            },
            keys () {
                return store.getAllKeys();
            },
            async set (docId, data) {
                const rows = [
                    {
                        timestamp: Date.now(),
                        update: data
                    }
                ];
                await store.put({
                    id: docId,
                    updates: rows
                });
            },
            async clear () {
                return await store.clear();
            },
            async del (key) {
                return store.delete(key);
            }
        });
    }
}
class KV {
    constructor(dbName){
        this.dbName = dbName;
        this.dbPromise = null;
        this.dbVersion = 1;
    }
    upgradeDB(db) {
        db.createObjectStore('kv', {
            keyPath: 'key'
        });
    }
    getDb() {
        if (this.dbPromise === null) {
            this.dbPromise = openDB(this.dbName, this.dbVersion, {
                upgrade: (db)=>this.upgradeDB(db)
            });
        }
        return this.dbPromise;
    }
    async transaction(cb) {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readwrite').objectStore('kv');
        const behavior = new KVBehavior(store);
        return await cb(behavior);
    }
    async get(key) {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readonly').objectStore('kv');
        return new KVBehavior(store).get(key);
    }
    async set(key, value) {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readwrite').objectStore('kv');
        return new KVBehavior(store).set(key, value);
    }
    async keys() {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readwrite').objectStore('kv');
        return new KVBehavior(store).keys();
    }
    async clear() {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readwrite').objectStore('kv');
        return new KVBehavior(store).clear();
    }
    async del(key) {
        const db = await this.getDb();
        const store = db.transaction('kv', 'readwrite').objectStore('kv');
        return new KVBehavior(store).del(key);
    }
}
class KVBehavior {
    constructor(store){
        this.store = store;
    }
    async get(key) {
        const value = await this.store.get(key);
        return value?.val ?? null;
    }
    async set(key, value) {
        if (this.store.put === undefined) {
            throw new Error('Cannot set in a readonly transaction');
        }
        await this.store.put({
            key: key,
            val: value
        });
    }
    async keys() {
        return await this.store.getAllKeys();
    }
    async del(key) {
        if (this.store.delete === undefined) {
            throw new Error('Cannot set in a readonly transaction');
        }
        return await this.store.delete(key);
    }
    async clear() {
        if (this.store.clear === undefined) {
            throw new Error('Cannot set in a readonly transaction');
        }
        return await this.store.clear();
    }
}
