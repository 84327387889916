import { BanIcon, DashLineIcon, StraightLineIcon } from '@blocksuite/affine-components/icons';
import { StrokeStyle } from '@blocksuite/affine-model';
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
const LINE_STYLE_LIST = [
    {
        name: 'Solid',
        value: StrokeStyle.Solid,
        icon: StraightLineIcon
    },
    {
        name: 'Dash',
        value: StrokeStyle.Dash,
        icon: DashLineIcon
    },
    {
        name: 'None',
        value: StrokeStyle.None,
        icon: BanIcon
    }
];
export function LineStylesPanel({ onClick, selectedLineSize, selectedLineStyle, lineStyles = [
    StrokeStyle.Solid,
    StrokeStyle.Dash,
    StrokeStyle.None
] } = {}) {
    const lineSizePanel = html`
    <edgeless-line-width-panel
      .selectedSize=${selectedLineSize}
      .disable=${selectedLineStyle === StrokeStyle.None}
      @select=${(e)=>{
        onClick?.({
            type: 'size',
            value: e.detail
        });
    }}
    ></edgeless-line-width-panel>
  `;
    const lineStyleButtons = repeat(LINE_STYLE_LIST.filter((item)=>lineStyles.includes(item.value)), (item)=>item.value, ({ name, icon, value })=>{
        const active = selectedLineStyle === value;
        const classes = {
            'line-style-button': true,
            [`mode-${value}`]: true
        };
        if (active) classes['active'] = true;
        return html`
        <edgeless-tool-icon-button
          class=${classMap(classes)}
          .active=${active}
          .activeMode=${'background'}
          .tooltip=${name}
          @click=${()=>onClick?.({
                type: 'lineStyle',
                value
            })}
        >
          ${icon}
        </edgeless-tool-icon-button>
      `;
    });
    return html`
    ${lineSizePanel}
    <editor-toolbar-separator></editor-toolbar-separator>
    ${lineStyleButtons}
  `;
}
