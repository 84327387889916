import "../../core/src/components/page-list/filter/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/filter/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71VwXKjMAy98xU6bg/OQNKkqfMxOwYL0K5jM7Yp6Xb67x2bZAOUNEkPPfIsS09P4nnx+7nUwqxSeEsAJLlGiVcOpcLDLgFoHVrmUGHhOWijMYBCUaUZedw7DgVqjzbA5gVtqUzHoSYpUe+S92RxTJ/F9KXRnjn6hxxehP3FmChL0sgifnAPwxvLeGMvbEWaw1NzgGzTRE4xukOqas9hnaYBUyFNfcSW6SDwy3IAhVHGTs49HjxzWBgthX1lMWTEbTXgxrxpOCz7ikcoN96b/RRVWHoO2Ri0PeVtD546yPrPXBR/K2taLScMc2Ml2hlmj3frvJ4ffF8hMgFnFMkvCZzimRWSWsfh8UoDXU0e48VGSEm6imKdZPi8SQB/WuepDNPQHrXn4BpRIMvRdzjetc18Rxe29lPe81HRWheWozF0gkajjE2e6z7dqP2k56NU3grtyJPRHIRSkC6ytQMUDhlpZlo/KsXroFEsOMPyouzx1pWpnatsb27ojr/o293fPtJwzGEFGYjWmzg20qwj6WsOy+2kyR+R8vm6lG7/cE+T31ZxbDwnT42SZZAeJRsw/xF9xHV9cuF6x5ht4L8cwbDSe4Sc210qjJ5x13zo+wMPmLxJm/5NuqxpEfPMuVz0RRatLby3nRVNHGz4l87xqBQ1jtwuef8AMRb0sMAHAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var argStyle = '_9fnao3b';
export var ellipsisTextStyle = '_9fnao3c';
export var filterContainerStyle = '_9fnao30';
export var filterItemCloseStyle = '_9fnao36';
export var filterItemStyle = '_9fnao35';
export var filterTypeIconStyle = '_9fnao3a';
export var filterTypeStyle = '_9fnao39';
export var inputStyle = '_9fnao37';
export var menuItemStyle = '_9fnao31';
export var menuItemTextStyle = '_9fnao34';
export var switchStyle = '_9fnao38';
export var variableSelectDividerStyle = '_9fnao33';
export var variableSelectTitleStyle = '_9fnao32';