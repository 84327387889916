import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
export class DocRecord extends Entity {
    constructor(docsStore, docPropertiesStore){
        super(), this.docsStore = docsStore, this.docPropertiesStore = docPropertiesStore, this.id = this.props.id, this.meta$ = LiveData.from(this.docsStore.watchDocMeta(this.id), {}), this.properties$ = LiveData.from(this.docPropertiesStore.watchDocProperties(this.id), {
            id: this.id
        }), this.primaryMode$ = LiveData.from(this.docsStore.watchDocPrimaryModeSetting(this.id), 'page').map((mode)=>mode === 'edgeless' ? 'edgeless' : 'page'), this.title$ = this.meta$.map((meta)=>meta.title ?? ''), this.trash$ = this.meta$.map((meta)=>meta.trash ?? false), this.read$ = this.meta$.map((meta)=>meta.read ?? false), this.feedSource$ = this.meta$.map((meta)=>meta.feedSource ?? null);
    }
    setProperties(properties) {
        this.docPropertiesStore.updateDocProperties(this.id, properties);
    }
    setMeta(meta) {
        this.docsStore.setDocMeta(this.id, meta);
    }
    setPrimaryMode(mode) {
        return this.docsStore.setDocPrimaryModeSetting(this.id, mode);
    }
    getPrimaryMode() {
        return this.docsStore.getDocPrimaryModeSetting(this.id);
    }
    moveToTrash() {
        return this.setMeta({
            trash: true,
            trashDate: Date.now()
        });
    }
    restoreFromTrash() {
        return this.setMeta({
            trash: false,
            trashDate: undefined
        });
    }
    toggleRead() {
        return this.read$.value ? this.setMeta({
            read: false,
            readDate: undefined
        }) : this.setMeta({
            read: true,
            readDate: Date.now()
        });
    }
    markAsRead() {
        if (this.read$.value) {
            return false;
        }
        this.setMeta({
            read: true,
            readDate: Date.now()
        });
        return true;
    }
}
