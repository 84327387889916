import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const Logo1IconBorder = ({ size = '1em', className, onClick })=>{
    return _jsxs("svg", {
        version: "1.1",
        id: "svg4191",
        width: size,
        height: size,
        viewBox: "0 0 682.66669 682.66669",
        className: className,
        xmlns: "http://www.w3.org/2000/svg",
        onClick: onClick,
        children: [
            _jsx("defs", {
                id: "defs4195",
                children: _jsx("clipPath", {
                    clipPathUnits: "userSpaceOnUse",
                    id: "clipPath4205",
                    children: _jsx("path", {
                        d: "M 0,512 H 512 V 0 H 0 Z",
                        id: "path4203"
                    })
                })
            }),
            _jsx("g", {
                id: "g4197",
                transform: "matrix(1.3333333,0,0,-1.3333333,0,682.66667)",
                children: _jsx("g", {
                    id: "g4199",
                    children: _jsxs("g", {
                        id: "g4201",
                        clipPath: "url(#clipPath4205)",
                        children: [
                            _jsx("g", {
                                id: "g4207",
                                transform: "translate(151.416,181.2563)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -4.199,9.143 -7.769,18.563 -10.683,28.19 -0.619,2.045 0.173,4.242 1.91,5.486 3.975,2.846 7.876,5.803 11.698,8.866 15.257,12.237 29.248,26.169 41.663,41.638 9.148,11.395 6.378,28.221 -5.876,36.18 -0.136,0.095 -0.283,0.182 -0.419,0.277 -10.714,6.956 -24.877,4.549 -32.885,-5.391 -6.313,-7.835 -13.088,-15.166 -20.341,-22.015 -1.938,-1.829 -5.157,-0.386 -5.058,2.278 0.929,24.882 7.424,49.373 17.193,72.499 1.036,2.451 2.545,4.668 4.428,6.549 12.437,12.42 23.711,26.103 33.614,40.903 8.156,12.189 4.011,28.758 -8.937,35.646 l -0.445,0.237 c -11.274,5.998 -25.171,2.368 -32.282,-8.239 -5.992,-8.936 -12.597,-17.372 -19.747,-25.258 -1.524,-1.682 -4.297,-1.135 -5.064,1.002 -9.006,25.079 -13.465,51.795 -12.917,78.878 0.258,12.769 -9.046,23.712 -21.723,25.269 -0.166,0.02 -0.331,0.041 -0.496,0.061 -14.506,1.781 -27.447,-9.328 -27.764,-23.94 -1.032,-47.634 10.564,-94.355 33.117,-135.574 1.289,-2.356 1.595,-5.113 0.853,-7.695 -8.483,-29.523 -11.92,-60.282 -10.052,-90.889 0.163,-2.676 -2.992,-4.23 -4.984,-2.436 -8.739,7.872 -16.882,16.475 -24.33,25.752 -7.915,9.861 -22.071,12.023 -32.677,5.138 l -0.419,-0.272 c -12.267,-7.963 -15.023,-24.799 -5.866,-36.202 24.068,-29.974 54.051,-54.208 87.726,-71.42 2.051,-1.048 3.678,-2.756 4.626,-4.854 10.885,-24.081 25.327,-46.628 43.029,-66.819 1.204,-1.374 2.525,-2.579 3.933,-3.615 8.233,-6.063 19.469,-6.345 28.029,-0.812 1.683,1.087 3.262,2.398 4.694,3.935 0.114,0.121 0.227,0.243 0.341,0.365 3.08,3.303 5.082,7.234 6.012,11.361 1.003,4.444 0.762,9.115 -0.717,13.474 -1.031,3.041 -2.664,5.931 -4.897,8.486 C 20.262,-36.916 8.765,-19.073 0,0",
                                    style: {
                                        fill: '#bd9cde',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4209"
                                })
                            }),
                            _jsx("g", {
                                id: "g4211",
                                transform: "translate(91.6758,112.5308)",
                                children: _jsx("path", {
                                    d: "m 0,0 20.863,-57.321 37.588,13.68 -20.864,57.322 C 33.81,24.06 22.333,29.412 11.953,25.634 1.574,21.856 -3.778,10.379 0,0",
                                    style: {
                                        fill: '#ace094',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4213"
                                })
                            }),
                            _jsx("g", {
                                id: "g4215",
                                transform: "translate(173.8794,157.3208)",
                                children: _jsx("path", {
                                    d: "M 0,0 10.593,-60.073 49.985,-53.127 39.393,6.946 C 37.474,17.824 27.101,25.087 16.223,23.169 5.345,21.251 -1.918,10.878 0,0",
                                    style: {
                                        fill: '#ace094',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4217"
                                })
                            }),
                            _jsx("g", {
                                id: "g4219",
                                transform: "translate(471.6436,86.3672)",
                                children: _jsx("path", {
                                    d: "m 0,0 -46.729,-39.21 25.712,-30.642 46.729,39.21 c 8.461,7.1 9.565,19.715 2.465,28.177 C 21.077,5.996 8.462,7.1 0,0",
                                    style: {
                                        fill: '#ace094',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4221"
                                })
                            }),
                            _jsx("g", {
                                id: "g4223",
                                transform: "translate(40.3564,86.3672)",
                                children: _jsx("path", {
                                    d: "m 0,0 46.729,-39.21 -25.712,-30.642 -46.729,39.21 c -8.461,7.1 -9.565,19.715 -2.465,28.177 C -21.077,5.996 -8.462,7.1 0,0",
                                    style: {
                                        fill: '#ace094',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4225"
                                })
                            }),
                            _jsx("g", {
                                id: "g4227",
                                transform: "translate(408.0605,127.2778)",
                                children: _jsx("path", {
                                    d: "m 0,0 -30.5,-52.828 34.641,-20 30.5,52.828 C 40.164,-10.434 36.886,1.797 27.32,7.32 17.755,12.843 5.523,9.566 0,0",
                                    style: {
                                        fill: '#ace094',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4229"
                                })
                            }),
                            _jsx("g", {
                                id: "g4231",
                                transform: "translate(496.6152,270.5571)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -0.122,0.113 -0.244,0.227 -0.366,0.341 -9.34,8.71 -23.705,8.803 -33.321,0.398 -11.636,-10.173 -24.23,-19.02 -37.544,-26.455 -3.616,-2.019 -7.987,0.998 -7.379,5.094 2.368,15.942 3.304,32.053 2.805,48.135 -0.049,1.569 0.637,3.062 1.858,4.049 14.912,12.041 28.601,25.714 40.78,40.856 9.219,11.462 6.606,28.359 -5.729,36.373 l -0.419,0.272 c -10.639,6.911 -24.796,4.672 -32.742,-5.217 -2.572,-3.202 -5.228,-6.324 -7.962,-9.362 -2.102,-2.336 -5.929,-1.48 -6.823,1.532 -1.718,5.787 -3.634,11.531 -5.749,17.222 -5.119,13.776 -20.246,19.587 -33.449,13.132 -0.269,-0.132 -0.538,-0.263 -0.808,-0.395 -11.479,-5.599 -16.714,-18.975 -12.312,-30.965 7.261,-19.778 11.584,-40.331 12.983,-61.026 0.267,-3.95 -3.919,-6.646 -7.397,-4.754 -36.736,19.983 -67.283,50.165 -87.696,87.231 -6.16,11.188 -19.69,16.014 -31.447,11.023 -0.153,-0.065 -0.306,-0.13 -0.46,-0.195 -13.449,-5.692 -19.123,-21.764 -12.099,-34.568 29.268,-53.354 75.635,-95.121 131.304,-118.76 2.165,-0.919 3.408,-3.196 3,-5.513 -2.225,-12.634 -5.537,-24.977 -9.864,-36.904 -1.283,-3.535 -5.83,-4.448 -8.395,-1.698 -3.649,3.912 -7.165,7.967 -10.54,12.16 -7.987,9.928 -22.147,12.273 -32.832,5.331 l -0.419,-0.273 c -12.349,-8.022 -14.935,-24.93 -5.704,-36.404 7.371,-9.16 15.294,-17.783 23.706,-25.832 3.704,-3.544 4.181,-9.292 1.035,-13.341 -10.396,-13.378 -22.373,-25.607 -35.76,-36.385 -9.947,-8.01 -12.35,-22.172 -5.394,-32.884 0.091,-0.139 0.181,-0.279 0.272,-0.418 7.984,-12.306 24.851,-14.989 36.285,-5.801 34.997,28.124 62.162,64.314 79.454,105.03 2.221,5.233 6.504,9.283 11.854,11.204 31.611,11.356 61.102,28.598 86.781,51.111 C 10.497,-27.022 10.688,-9.968 0,0",
                                    style: {
                                        fill: '#ff8f71',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4233"
                                })
                            }),
                            _jsx("g", {
                                id: "g4235",
                                transform: "translate(333.6279,441.5786)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-11.046 -8.954,-20 -20,-20 -11.045,0 -20,8.954 -20,20 0,11.045 8.955,20 20,20 C -8.954,20 0,11.045 0,0",
                                    style: {
                                        fill: '#a4e7ff',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4237"
                                })
                            }),
                            _jsx("g", {
                                id: "g4239",
                                transform: "translate(52.5068,347.8066)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-11.045 -8.954,-20 -20,-20 -11.046,0 -20,8.955 -20,20 0,11.046 8.954,20 20,20 C -8.954,20 0,11.046 0,0",
                                    style: {
                                        fill: '#a4e7ff',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4241"
                                })
                            }),
                            _jsx("g", {
                                id: "g4243",
                                transform: "translate(266.2905,236.8799)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-9.665 -7.835,-17.5 -17.5,-17.5 -9.665,0 -17.5,7.835 -17.5,17.5 0,9.665 7.835,17.5 17.5,17.5 C -7.835,17.5 0,9.665 0,0",
                                    style: {
                                        fill: '#a4e7ff',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4245"
                                })
                            }),
                            _jsx("g", {
                                id: "g4247",
                                transform: "translate(229.1401,487)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-9.665 -7.835,-17.5 -17.5,-17.5 -9.665,0 -17.5,7.835 -17.5,17.5 0,9.665 7.835,17.5 17.5,17.5 C -7.835,17.5 0,9.665 0,0",
                                    style: {
                                        fill: '#a4e7ff',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4249"
                                })
                            }),
                            _jsx("g", {
                                id: "g4251",
                                transform: "translate(504.4302,7.5)",
                                children: _jsx("path", {
                                    d: "m 0,0 h -223.9 c -46.952,18.411 -79.114,43.913 -79.114,79.223 0,14.2 28.092,42.381 39.664,53.687 26.33,3.27 54.98,-6.78 66.16,-26.27 2.16,-3.77 4.07,-7.61 8.35,-8.29 4.36,-0.69 8.67,-1.45 12.95,-2.29 4.19,-0.82 8.45,1.09 10.58,4.79 12.36,21.52 47.67,11.32 46.46,-13.64 -0.21,-4.25 2.36,-8.13 6.31,-9.71 2.45,-0.98 4.88,-1.98 7.29,-3.01 3.76,-1.61 8.04,-0.71 11,2.11 16.54,15.77 46.48,-0.63 41.63,-23.18 -0.85,-3.92 0.81,-7.96 4.1,-10.26 3.27,-2.29 6.49,-4.63 9.63,-7.03 2.34,-1.78 5.36,-2.45 8.22,-1.78 12.04,2.83 24.83,-3.76 29.16,-15.8 C 0.55,12.86 0,0 0,0",
                                    style: {
                                        fill: '#ffd9a0',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4253"
                                })
                            }),
                            _jsx("g", {
                                id: "g4255",
                                transform: "translate(280.5303,7.5)",
                                children: _jsx("path", {
                                    d: "m 0,0 h -272.91 c -1.21,7.87 6.75,41.02 30.32,34.62 3.02,-0.82 6.26,-0.25 8.75,1.65 1.9,1.44 3.81,2.86 5.75,4.26 3.43,2.48 5.02,6.82 3.82,10.87 -7.2,24.23 25.11,42.45 42.14,24.01 2.89,-3.12 7.45,-3.99 11.33,-2.28 3.09,1.35 6.2,2.67 9.35,3.93 3.86,1.56 6.4,5.31 6.29,9.47 -0.61,24.25 33.3,34.43 46.08,13.69 2.18,-3.54 6.35,-5.29 10.42,-4.48 1.85,0.37 3.7,0.72 5.56,1.07 3.58,0.65 5.99,3.44 7.66,6.68 9.11,17.75 26.94,27.07 45.99,29.42 C -44.88,120 -47.8,106.29 -47.8,92.09 -47.8,56.78 -29.75,24.54 0,0",
                                    style: {
                                        fill: '#fdcb89',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4257"
                                })
                            }),
                            _jsx("g", {
                                id: "g4259",
                                transform: "translate(472.1289,462.9526)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-16.568 -13.432,-30 -30,-30 -16.568,0 -30,13.432 -30,30 0,16.569 13.432,30 30,30 C -13.432,30 0,16.569 0,0",
                                    style: {
                                        fill: '#a4e7ff',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4261"
                                })
                            }),
                            _jsx("g", {
                                id: "g4263",
                                transform: "translate(205.7983,40.877)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-12.911 -13.143,-23.377 -29.355,-23.377 -16.212,0 -29.355,10.466 -29.355,23.377 0,12.91 13.143,23.376 29.355,23.376 C -13.143,23.376 0,12.91 0,0",
                                    style: {
                                        fill: '#ffa1ac',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4265"
                                })
                            }),
                            _jsx("g", {
                                id: "g4267",
                                transform: "translate(306.2017,40.877)",
                                children: _jsx("path", {
                                    d: "M 0,0 C 0,-12.911 13.143,-23.377 29.355,-23.377 45.567,-23.377 58.71,-12.911 58.71,0 58.71,12.91 45.567,23.376 29.355,23.376 13.143,23.376 0,12.91 0,0",
                                    style: {
                                        fill: '#ffa1ac',
                                        fillOpacity: 1,
                                        fillRule: 'nonzero',
                                        stroke: 'none'
                                    },
                                    id: "path4269"
                                })
                            }),
                            _jsx("g", {
                                id: "g4271",
                                transform: "translate(481.4888,42.4102)",
                                children: _jsx("path", {
                                    d: "m 0,0 15.87,13.32 c 8.46,7.1 9.56,19.71 2.46,28.17 -3.96,4.72 -9.63,7.15 -15.34,7.15 -4.53,0 -9.09,-1.54 -12.84,-4.68 L -29.54,27.43",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4273"
                                })
                            }),
                            _jsx("g", {
                                id: "g4275",
                                transform: "translate(56.4014,72.9102)",
                                children: _jsx("path", {
                                    d: "m 0,0 -16.04,13.46 c -3.75,3.14 -8.31,4.68 -12.84,4.68 -5.71,0 -11.38,-2.43 -15.34,-7.15 -7.1,-8.46 -6,-21.07 2.46,-28.17 l 15.39,-12.91",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4277"
                                })
                            }),
                            _jsx("g", {
                                id: "g4279",
                                transform: "translate(433.2998,89.2598)",
                                children: _jsx("path", {
                                    d: "m 0,0 10.4,18.02 c 5.52,9.56 2.25,21.8 -7.32,27.32 -3.15,1.82 -6.58,2.68 -9.98,2.68 -6.91,0 -13.64,-3.59 -17.34,-10 L -46.28,-0.16",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4281"
                                })
                            }),
                            _jsx("g", {
                                id: "g4283",
                                transform: "translate(333.6279,441.5786)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-11.046 -8.954,-20 -20,-20 -11.045,0 -20,8.954 -20,20 0,11.046 8.955,20 20,20 C -8.954,20 0,11.046 0,0 Z",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4285"
                                })
                            }),
                            _jsx("g", {
                                id: "g4287",
                                transform: "translate(52.5068,347.8066)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-11.045 -8.954,-20 -20,-20 -11.046,0 -20,8.955 -20,20 0,11.046 8.954,20 20,20 C -8.954,20 0,11.046 0,0 Z",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4289"
                                })
                            }),
                            _jsx("g", {
                                id: "g4291",
                                transform: "translate(266.2905,236.8799)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-9.665 -7.835,-17.5 -17.5,-17.5 -9.665,0 -17.5,7.835 -17.5,17.5 0,9.665 7.835,17.5 17.5,17.5 C -7.835,17.5 0,9.665 0,0 Z",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4293"
                                })
                            }),
                            _jsx("g", {
                                id: "g4295",
                                transform: "translate(229.1401,487)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-9.665 -7.835,-17.5 -17.5,-17.5 -9.665,0 -17.5,7.835 -17.5,17.5 0,9.665 7.835,17.5 17.5,17.5 C -7.835,17.5 0,9.665 0,0 Z",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4297"
                                })
                            }),
                            _jsx("g", {
                                id: "g4299",
                                transform: "translate(472.1289,462.9526)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 0,-16.568 -13.432,-30 -30,-30 -16.568,0 -30,13.432 -30,30 0,16.569 13.432,30 30,30 C -13.432,30 0,16.569 0,0 Z",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4301"
                                })
                            }),
                            _jsx("g", {
                                id: "g4303",
                                transform: "translate(282.4912,34.5649)",
                                children: _jsx("path", {
                                    d: "M 0,0 C -6.441,9.259 -15.917,15.118 -26.491,15.118 -37.065,15.118 -46.542,9.259 -52.983,0",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4305"
                                })
                            }),
                            _jsx("g", {
                                id: "g4307",
                                transform: "translate(174.3335,69.3799)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 4.143,-4.754 10.238,-7.762 17.039,-7.762 6.801,0 12.896,3.008 17.039,7.762",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4309"
                                })
                            }),
                            _jsx("g", {
                                id: "g4311",
                                transform: "translate(337.6665,69.3799)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -4.143,-4.754 -10.238,-7.762 -17.039,-7.762 -6.801,0 -12.896,3.008 -17.039,7.762",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4313"
                                })
                            }),
                            _jsx("g", {
                                id: "g4315",
                                transform: "translate(134.1099,112.8799)",
                                children: _jsx("path", {
                                    d: "M 0,0 -4.85,13.33 C -8.62,23.71 -20.1,29.06 -30.48,25.28 -38.6,22.33 -43.64,14.66 -43.64,6.49 c 0,-2.27 0.39,-4.58 1.21,-6.84 l 11.84,-32.53",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4317"
                                })
                            }),
                            _jsx("g", {
                                id: "g4319",
                                transform: "translate(218.6401,133.8301)",
                                children: _jsx("path", {
                                    d: "m 0,0 -5.37,30.44 c -1.92,10.87 -12.29,18.14 -23.17,16.22 -9.7,-1.71 -16.53,-10.15 -16.53,-19.67 0,-1.15 0.1,-2.32 0.31,-3.5 l 9.48,-53.79",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4321"
                                })
                            }),
                            _jsx("g", {
                                id: "g4323",
                                transform: "translate(282.73,134.6201)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 10.57,-4.59 19.38,-11.54 24.51,-20.48 2.16,-3.77 4.07,-7.61 8.35,-8.29 4.36,-0.69 8.67,-1.45 12.95,-2.29 4.19,-0.82 8.45,1.09 10.58,4.79 12.36,21.52 47.67,11.32 46.46,-13.64 -0.21,-4.25 2.36,-8.13 6.31,-9.71 2.45,-0.98 4.88,-1.98 7.29,-3.01 3.76,-1.61 8.04,-0.71 11,2.11 16.54,15.77 46.48,-0.63 41.63,-23.18 -0.85,-3.92 0.81,-7.96 4.1,-10.26 3.27,-2.29 6.49,-4.63 9.63,-7.03 2.34,-1.78 5.36,-2.45 8.22,-1.78 12.04,2.83 24.83,-3.76 29.16,-15.8 2.06,-5.69 1.51,-18.55 1.51,-18.55 h -496.81 c -1.21,7.87 6.75,41.02 30.32,34.62 3.02,-0.82 6.26,-0.25 8.75,1.65 1.9,1.44 3.81,2.86 5.75,4.26 3.43,2.48 5.02,6.82 3.82,10.87 -7.2,24.23 25.11,42.45 42.14,24.01 2.89,-3.12 7.45,-3.99 11.33,-2.28 3.09,1.35 6.2,2.67 9.35,3.93 3.86,1.56 6.4,5.31 6.29,9.47 -0.61,24.25 33.3,34.43 46.08,13.69 2.18,-3.54 6.35,-5.29 10.42,-4.48 1.85,0.37 3.7,0.72 5.56,1.07 3.58,0.65 5.99,3.44 7.66,6.68 10.26,19.98 31.57,29.28 53.23,29.98",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4325"
                                })
                            }),
                            _jsx("g", {
                                id: "g4327",
                                transform: "translate(177.1602,138.71)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -10.22,13.23 -18.83,27.5 -25.74,42.55 -4.2,9.14 -7.77,18.56 -10.69,28.19 -0.62,2.04 0.18,4.24 1.91,5.48 3.98,2.85 7.88,5.8 11.7,8.87 15.26,12.24 29.25,26.17 41.66,41.64 9.15,11.39 6.38,28.22 -5.87,36.18 -0.14,0.09 -0.29,0.18 -0.42,0.27 -10.72,6.96 -24.88,4.55 -32.89,-5.39 -6.31,-7.83 -13.08,-15.16 -20.34,-22.01 -1.94,-1.83 -5.15,-0.39 -5.05,2.28 0.92,24.88 7.42,49.37 17.19,72.49 1.03,2.46 2.54,4.67 4.43,6.55 12.43,12.42 23.71,26.11 33.61,40.91 8.16,12.19 4.01,28.75 -8.94,35.64 l -0.44,0.24 c -3.16,1.69 -6.54,2.61 -9.9,2.84 -3.12,0.2 -6.24,-0.19 -9.2,-1.13 -5.22,-1.67 -9.91,-5.08 -13.18,-9.95 -6,-8.94 -12.6,-17.37 -19.75,-25.26 -1.53,-1.68 -4.3,-1.13 -5.07,1 -3.34,9.31 -6.06,18.85 -8.12,28.55",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4329"
                                })
                            }),
                            _jsx("g", {
                                id: "g4331",
                                transform: "translate(117.52,138.0801)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -4.49,7.75 -8.56,15.71 -12.24,23.84 -0.95,2.1 -2.58,3.81 -4.63,4.85 -33.67,17.22 -63.66,41.45 -87.72,71.42 -9.16,11.41 -6.4,28.24 5.86,36.21 l 0.42,0.27 c 10.61,6.88 24.76,4.72 32.68,-5.14 7.45,-9.28 15.59,-17.88 24.33,-25.75 1.99,-1.8 5.15,-0.24 4.98,2.43 -1.87,30.61 1.57,61.37 10.06,90.89 0.74,2.58 0.43,5.34 -0.86,7.7 -22.55,41.22 -34.15,87.94 -33.11,135.57 0.31,14.61 13.25,25.72 27.76,23.94 0.16,-0.02 0.33,-0.04 0.5,-0.06 12.67,-1.56 21.98,-12.5 21.72,-25.27 -0.11,-5.23 -0.03,-10.44 0.24,-15.63",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4333"
                                })
                            }),
                            _jsx("g", {
                                id: "g4335",
                                transform: "translate(323.1099,265.3198)",
                                children: _jsx("path", {
                                    d: "m 0,0 c 12.53,-7.98 25.74,-14.95 39.53,-20.8 2.17,-0.92 3.41,-3.2 3,-5.51 -2.22,-12.64 -5.53,-24.98 -9.86,-36.91 -1.28,-3.53 -5.83,-4.45 -8.4,-1.7 -3.64,3.91 -7.16,7.97 -10.54,12.16 -7.98,9.93 -22.14,12.28 -32.83,5.33 l -0.42,-0.27 c -12.35,-8.02 -14.93,-24.93 -5.7,-36.4 7.37,-9.16 15.29,-17.79 23.71,-25.83 3.7,-3.55 4.18,-9.3 1.03,-13.35 -6.01,-7.72 -12.54,-15.07 -19.57,-21.96",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4337"
                                })
                            }),
                            _jsx("g", {
                                id: "g4339",
                                transform: "translate(294.8701,286)",
                                children: _jsx("path", {
                                    d: "m 0,0 c -25.59,21.4 -47.21,47.54 -63.53,77.28 -7.02,12.8 -1.35,28.87 12.1,34.57 0.15,0.06 0.31,0.13 0.46,0.19 11.75,4.99 25.29,0.17 31.45,-11.02 20.41,-37.07 50.96,-67.25 87.69,-87.23 3.48,-1.89 7.67,0.8 7.4,4.75 -1.4,20.7 -5.72,41.25 -12.99,61.03 -4.4,11.99 0.84,25.36 12.32,30.96 0.27,0.13 0.54,0.27 0.81,0.4 13.2,6.45 28.33,0.64 33.44,-13.13 2.12,-5.7 4.04,-11.44 5.75,-17.23 0.9,-3.01 4.72,-3.86 6.83,-1.53 2.73,3.04 5.39,6.16 7.96,9.36 7.94,9.89 22.1,12.13 32.74,5.22 l 0.42,-0.27 C 175.18,85.33 177.8,68.44 168.58,56.98 156.4,41.83 142.71,28.16 127.8,16.12 c -1.22,-0.99 -1.91,-2.48 -1.86,-4.05 0.5,-16.08 -0.44,-32.19 -2.8,-48.13 -0.61,-4.1 3.76,-7.12 7.37,-5.1 13.32,7.44 25.91,16.28 37.55,26.46 9.61,8.4 23.98,8.31 33.32,-0.4 0.12,-0.12 0.24,-0.23 0.37,-0.34 10.68,-9.97 10.49,-27.02 -0.5,-36.66 -25.68,-22.51 -55.17,-39.75 -86.78,-51.11 -5.35,-1.92 -9.63,-5.97 -11.85,-11.2 -7.92,-18.64 -17.9,-36.33 -29.76,-52.72",
                                    style: {
                                        fill: 'none',
                                        stroke: '#000000',
                                        strokeWidth: 15,
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                        strokeDasharray: 'none',
                                        strokeOpacity: 1
                                    },
                                    id: "path4341"
                                })
                            })
                        ]
                    })
                })
            })
        ]
    });
};
