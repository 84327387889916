import { insertPositionToIndex } from '@blocksuite/affine-shared/utils';
import { computed } from '@preact/signals-core';
import { emptyFilterGroup } from '../../core/common/ast.js';
import { defaultGroupBy } from '../../core/common/group-by.js';
import { GroupManager, sortByManually } from '../../core/common/group-by/helper.js';
import { groupByMatcher } from '../../core/common/group-by/matcher.js';
import { evalFilter } from '../../core/logical/eval-filter.js';
import { PropertyBase } from '../../core/view-manager/property.js';
import { SingleViewBase } from '../../core/view-manager/single-view.js';
export class KanbanSingleView extends SingleViewBase {
    get columns() {
        return this.propertiesWithoutFilter$.value.filter((id)=>!this.propertyHideGet(id));
    }
    get columnsWithoutFilter() {
        const needShow = new Set(this.dataSource.properties$.value);
        const result = [];
        this.view?.columns.forEach((v)=>{
            if (needShow.has(v.id)) {
                result.push(v.id);
                needShow.delete(v.id);
            }
        });
        result.push(...needShow);
        return result;
    }
    get filter() {
        return this.view?.filter ?? emptyFilterGroup;
    }
    get header() {
        return this.view?.header;
    }
    get type() {
        return this.view?.mode ?? 'kanban';
    }
    get view() {
        return this.data$.value;
    }
    addCard(position, group) {
        const id = this.rowAdd(position);
        this.groupManager.addToGroup(id, group);
        return id;
    }
    changeGroup(columnId) {
        const column = this.propertyGet(columnId);
        this.dataUpdate((_view)=>{
            return {
                groupBy: defaultGroupBy(this.propertyMetaGet(column.type$.value), column.id, column.data$.value)
            };
        });
    }
    checkGroup(columnId, type, target) {
        if (!groupByMatcher.isMatched(type, target)) {
            this.changeGroup(columnId);
            return false;
        }
        return true;
    }
    filterSet(filter) {
        this.dataUpdate(()=>{
            return {
                filter
            };
        });
    }
    getHeaderCover(_rowId) {
        const columnId = this.view?.header.coverColumn;
        if (!columnId) {
            return;
        }
        return this.propertyGet(columnId);
    }
    getHeaderIcon(_rowId) {
        const columnId = this.view?.header.iconColumn;
        if (!columnId) {
            return;
        }
        return this.propertyGet(columnId);
    }
    getHeaderTitle(_rowId) {
        const columnId = this.view?.header.titleColumn;
        if (!columnId) {
            return;
        }
        return this.propertyGet(columnId);
    }
    hasHeader(_rowId) {
        const hd = this.view?.header;
        if (!hd) {
            return false;
        }
        return !!hd.titleColumn || !!hd.iconColumn || !!hd.coverColumn;
    }
    isInHeader(columnId) {
        const hd = this.view?.header;
        if (!hd) {
            return false;
        }
        return hd.titleColumn === columnId || hd.iconColumn === columnId || hd.coverColumn === columnId;
    }
    isShow(rowId) {
        if (this.filter$.value?.conditions.length) {
            const rowMap = Object.fromEntries(this.properties$.value.map((column)=>[
                    column.id,
                    column.cellGet(rowId).jsonValue$.value
                ]));
            return evalFilter(this.filter$.value, rowMap);
        }
        return true;
    }
    propertyGet(columnId) {
        return new KanbanColumn(this, columnId);
    }
    propertyHideGet(columnId) {
        return this.view?.columns.find((v)=>v.id === columnId)?.hide ?? false;
    }
    propertyHideSet(columnId, hide) {
        this.dataUpdate((view)=>{
            return {
                columns: view.columns.map((v)=>v.id === columnId ? {
                        ...v,
                        hide
                    } : v)
            };
        });
    }
    propertyMove(columnId, toAfterOfColumn) {
        this.dataUpdate((view)=>{
            const columnIndex = view.columns.findIndex((v)=>v.id === columnId);
            if (columnIndex < 0) {
                return {};
            }
            const columns = [
                ...view.columns
            ];
            const [column] = columns.splice(columnIndex, 1);
            const index = insertPositionToIndex(toAfterOfColumn, columns);
            columns.splice(index, 0, column);
            return {
                columns
            };
        });
    }
    rowMove(rowId, position) {
        this.dataSource.rowMove(rowId, position);
    }
    rowNextGet(rowId) {
        const index = this.rows$.value.indexOf(rowId);
        return this.rows$.value[index + 1];
    }
    rowPrevGet(rowId) {
        const index = this.rows$.value.indexOf(rowId);
        return this.rows$.value[index - 1];
    }
    constructor(...args){
        super(...args), this.detailProperties$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.filter((id)=>this.propertyTypeGet(id) !== 'title');
        }), this.filter$ = computed(()=>{
            return this.data$.value?.filter ?? emptyFilterGroup;
        }), this.groupBy$ = computed(()=>{
            return this.data$.value?.groupBy;
        }), this.groupManager = new GroupManager(this.groupBy$, this, {
            sortGroup: (ids)=>sortByManually(ids, (v)=>v, this.view?.groupProperties.map((v)=>v.key) ?? []),
            sortRow: (key, ids)=>{
                const property = this.view?.groupProperties.find((v)=>v.key === key);
                return sortByManually(ids, (v)=>v, property?.manuallyCardSort ?? []);
            },
            changeGroupSort: (keys)=>{
                const map = new Map(this.view?.groupProperties.map((v)=>[
                        v.key,
                        v
                    ]));
                this.dataUpdate(()=>{
                    return {
                        groupProperties: keys.map((key)=>{
                            const property = map.get(key);
                            if (property) {
                                return property;
                            }
                            return {
                                key,
                                hide: false,
                                manuallyCardSort: []
                            };
                        })
                    };
                });
            },
            changeRowSort: (groupKeys, groupKey, keys)=>{
                const map = new Map(this.view?.groupProperties.map((v)=>[
                        v.key,
                        v
                    ]));
                this.dataUpdate(()=>{
                    return {
                        groupProperties: groupKeys.map((key)=>{
                            if (key === groupKey) {
                                const group = map.get(key);
                                return group ? {
                                    ...group,
                                    manuallyCardSort: keys
                                } : {
                                    key,
                                    hide: false,
                                    manuallyCardSort: keys
                                };
                            } else {
                                return map.get(key) ?? {
                                    key,
                                    hide: false,
                                    manuallyCardSort: []
                                };
                            }
                        })
                    };
                });
            }
        }), this.mainProperties$ = computed(()=>{
            return this.data$.value?.header ?? {
                titleColumn: this.propertiesWithoutFilter$.value.find((id)=>this.propertyTypeGet(id) === 'title'),
                iconColumn: 'type'
            };
        }), this.propertiesWithoutFilter$ = computed(()=>{
            const needShow = new Set(this.dataSource.properties$.value);
            const result = [];
            this.data$.value?.columns.forEach((v)=>{
                if (needShow.has(v.id)) {
                    result.push(v.id);
                    needShow.delete(v.id);
                }
            });
            result.push(...needShow);
            return result;
        }), this.propertyIds$ = computed(()=>{
            return this.propertiesWithoutFilter$.value.filter((id)=>!this.propertyHideGet(id));
        }), this.readonly$ = computed(()=>{
            return this.manager.readonly$.value;
        });
    }
}
export class KanbanColumn extends PropertyBase {
    constructor(dataViewManager, columnId){
        super(dataViewManager, columnId);
    }
}
