import { styled } from '../../styles';
export const Wrapper = styled('div', {
    shouldForwardProp: (prop)=>{
        return ![
            'display',
            'width',
            'height',
            'padding',
            'margin',
            'paddingTop',
            'paddingRight',
            'paddingLeft',
            'paddingBottom',
            'marginTop',
            'marginLeft',
            'marginRight',
            'marginBottom'
        ].includes(prop);
    }
})(({ display, width, height, padding, margin, paddingTop, paddingRight, paddingLeft, paddingBottom, marginTop, marginLeft, marginRight, marginBottom })=>{
    return {
        display,
        width,
        height,
        padding,
        margin,
        paddingTop,
        paddingRight,
        paddingLeft,
        paddingBottom,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom
    };
});
export const FlexWrapper = styled(Wrapper, {
    shouldForwardProp: (prop)=>{
        return ![
            'justifyContent',
            'alignItems',
            'wrap',
            'flexDirection',
            'flexShrink',
            'flexGrow',
            'gap'
        ].includes(prop);
    }
})(({ justifyContent, alignItems, wrap = false, flexDirection, flexShrink, flexGrow, gap })=>{
    return {
        display: 'flex',
        justifyContent,
        alignItems,
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexDirection,
        flexShrink,
        flexGrow,
        gap
    };
});
export default Wrapper;
