import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@affine/component';
import { SettingRow } from '@affine/component/setting-components';
import { ThemeEditorService } from '@affine/core/modules/theme-editor';
import { popupWindow } from '@affine/core/utils';
import { apis } from '@affine/electron-api';
import { DeleteIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { cssVar } from '@toeverything/theme';
import { useCallback } from 'react';
export const ThemeEditorSetting = ()=>{
    const themeEditor = useService(ThemeEditorService);
    const modified = useLiveData(themeEditor.modified$);
    const open = useCallback(()=>{
        if (BUILD_CONFIG.isElectron) {
            apis?.ui.openThemeEditor().catch(console.error);
        } else {
            popupWindow('/theme-editor');
        }
    }, []);
    return _jsx(SettingRow, {
        name: "Customize Theme",
        desc: "Edit all Wemem theme variables here",
        children: _jsxs("div", {
            style: {
                display: 'flex',
                gap: 16
            },
            children: [
                modified ? _jsx(Button, {
                    style: {
                        color: cssVar('errorColor'),
                        borderColor: cssVar('errorColor')
                    },
                    prefixStyle: {
                        color: cssVar('errorColor')
                    },
                    onClick: ()=>themeEditor.reset(),
                    variant: "secondary",
                    prefix: _jsx(DeleteIcon, {}),
                    children: "Reset all"
                }) : null,
                _jsx(Button, {
                    onClick: open,
                    children: "Open Theme Editor"
                })
            ]
        })
    });
};
