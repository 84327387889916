import { distinctUntilChanged, map, switchMap } from 'rxjs';
import { Array as YArray, Map as YMap } from 'yjs';
import { Store } from '../../../framework';
import { yjsObserve, yjsObserveByPath, yjsObserveDeep } from '../../../utils';
export class DocsStore extends Store {
    constructor(workspaceService, docPropertiesStore){
        super(), this.workspaceService = workspaceService, this.docPropertiesStore = docPropertiesStore;
    }
    getBlockSuiteDoc(id) {
        return this.workspaceService.workspace.docCollection.getDoc(id);
    }
    createBlockSuiteDoc() {
        return this.workspaceService.workspace.docCollection.createDoc();
    }
    watchDocIds() {
        return yjsObserveByPath(this.workspaceService.workspace.rootYDoc.getMap('meta'), 'pages').pipe(switchMap(yjsObserve), map((meta)=>{
            if (meta instanceof YArray) {
                return meta.map((v)=>v.get('id'));
            } else {
                return [];
            }
        }));
    }
    watchTrashDocIds() {
        return yjsObserveByPath(this.workspaceService.workspace.rootYDoc.getMap('meta'), 'pages').pipe(switchMap(yjsObserveDeep), map((meta)=>{
            if (meta instanceof YArray) {
                return meta.map((v)=>v.get('trash') ? v.get('id') : null).filter(Boolean);
            } else {
                return [];
            }
        }));
    }
    watchDocMeta(id) {
        return yjsObserveByPath(this.workspaceService.workspace.rootYDoc.getMap('meta'), 'pages').pipe(switchMap(yjsObserve), map((meta)=>{
            if (meta instanceof YArray) {
                let docMetaYMap = null;
                meta.forEach((doc)=>{
                    if (doc.get('id') === id) {
                        docMetaYMap = doc;
                    }
                });
                return docMetaYMap;
            } else {
                return null;
            }
        }), switchMap(yjsObserveDeep), map((meta)=>{
            if (meta instanceof YMap) {
                return meta.toJSON();
            } else {
                return {};
            }
        }));
    }
    watchDocListReady() {
        return this.workspaceService.workspace.engine.rootDocState$.map((state)=>!state.syncing).asObservable();
    }
    setDocMeta(id, meta) {
        this.workspaceService.workspace.docCollection.setDocMeta(id, meta);
    }
    setDocPrimaryModeSetting(id, mode) {
        return this.docPropertiesStore.updateDocProperties(id, {
            primaryMode: mode
        });
    }
    getDocPrimaryModeSetting(id) {
        return this.docPropertiesStore.getDocProperties(id)?.primaryMode;
    }
    watchDocPrimaryModeSetting(id) {
        return this.docPropertiesStore.watchDocProperties(id).pipe(map((config)=>config?.primaryMode), distinctUntilChanged((p, c)=>p === c));
    }
    waitForDocLoadReady(id) {
        return this.workspaceService.workspace.engine.doc.waitForReady(id);
    }
    setPriorityLoad(id, priority) {
        return this.workspaceService.workspace.engine.doc.setPriority(id, priority);
    }
    markDocSyncStateAsReady(id) {
        this.workspaceService.workspace.engine.doc.markAsReady(id);
    }
}
