import { jsx as _jsx } from "react/jsx-runtime";
import { Button, FlexWrapper, Menu } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { FilterIcon } from '@blocksuite/icons/rc';
import { CreateFilterMenu } from '../filter/vars';
import * as styles from './collection-list.css';
export const AllPageListOperationsMenu = ({ propertiesMeta, filterList, onChangeFilterList })=>{
    const t = useI18n();
    return _jsx(FlexWrapper, {
        alignItems: "center",
        children: _jsx(Menu, {
            items: _jsx(CreateFilterMenu, {
                propertiesMeta: propertiesMeta,
                value: filterList,
                onChange: onChangeFilterList
            }),
            children: _jsx(Button, {
                className: styles.filterMenuTrigger,
                prefix: _jsx(FilterIcon, {}),
                "data-testid": "create-first-filter",
                children: t['com.affine.filter']()
            })
        })
    });
};
