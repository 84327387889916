import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar, RssIcon } from '@affine/component';
import { memo } from 'react';
export const FeedAvatar = memo(({ image, name, size = 20 })=>{
    return image || name ? _jsx(Avatar, {
        url: image,
        name: name,
        size: size
    }) : _jsx(RssIcon, {
        size: size
    });
});
