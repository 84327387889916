import { createIdentifier } from '@blocksuite/global/di';
import { NodePropsSchema } from '../utils/index.js';
export const EditorSettingSchema = NodePropsSchema;
export const EditorSettingProvider = createIdentifier('AffineEditorSettingProvider');
export function EditorSettingExtension(signal) {
    return {
        setup: (di)=>{
            di.addImpl(EditorSettingProvider, ()=>signal);
        }
    };
}
