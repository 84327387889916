import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, useDropTarget } from '@affine/component';
import { usePageHelper } from '@affine/core/components/blocksuite/block-suite-page-list/utils';
import { DropEffect, ExplorerTreeRoot } from '@affine/core/modules/explorer/views/tree';
import { FavoriteService, isFavoriteSupportType } from '@affine/core/modules/favorite';
import { FeedNodeType } from '@affine/core/modules/feeds';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { isNewTabTrigger } from '@affine/core/utils';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { PlusIcon } from '@blocksuite/icons/rc';
import { useLiveData, useServices, WorkspaceService } from '@toeverything/infra';
import { useCallback } from 'react';
import { ExplorerService } from '../../../services/explorer';
import { CollapsibleSection } from '../../layouts/collapsible-section';
import { ExplorerCollectionNode } from '../../nodes/collection';
import { ExplorerDocNode } from '../../nodes/doc';
import { ExplorerFeedFolderNode, ExplorerFeedRSSNode } from '../../nodes/feed';
import { ExplorerFolderNode } from '../../nodes/folder';
import { ExplorerTagNode } from '../../nodes/tag';
import { favoriteChildrenCanDrop, favoriteChildrenDropEffect, favoriteRootCanDrop, favoriteRootDropEffect } from './dnd';
import { RootEmpty } from './empty';
export const ExplorerFavorites = ()=>{
    const { favoriteService, workspaceService, workbenchService, explorerService } = useServices({
        FavoriteService,
        WorkbenchService,
        WorkspaceService,
        ExplorerService
    });
    const explorerSection = explorerService.sections.favorites;
    const favorites = useLiveData(favoriteService.favoriteList.sortedList$);
    const isLoading = useLiveData(favoriteService.favoriteList.isLoading$);
    const t = useI18n();
    const { createPage } = usePageHelper(workspaceService.workspace.docCollection);
    const handleDrop = useCallback((data)=>{
        if (data.source.data.entity?.type && isFavoriteSupportType(data.source.data.entity.type)) {
            favoriteService.favoriteList.add(data.source.data.entity.type, data.source.data.entity.id, favoriteService.favoriteList.indexAt('before'));
            track.$.navigationPanel.organize.toggleFavorite({
                type: data.source.data.entity.type,
                on: true
            });
            explorerSection.setCollapsed(false);
        }
    }, [
        explorerSection,
        favoriteService.favoriteList
    ]);
    const handleCreateNewFavoriteDoc = useCallback((e)=>{
        const newDoc = createPage();
        favoriteService.favoriteList.add('doc', newDoc.id, favoriteService.favoriteList.indexAt('before'));
        workbenchService.workbench.openDoc(newDoc.id, {
            at: isNewTabTrigger(e) ? 'new-tab' : 'active'
        });
        explorerSection.setCollapsed(false);
    }, [
        createPage,
        explorerSection,
        favoriteService.favoriteList,
        workbenchService.workbench
    ]);
    const handleOnChildrenDrop = useCallback((favorite, data)=>{
        if (data.treeInstruction?.type === 'reorder-above' || data.treeInstruction?.type === 'reorder-below') {
            if (data.source.data.from?.at === 'explorer:favorite:list' && data.source.data.entity?.type && isFavoriteSupportType(data.source.data.entity.type)) {
                favoriteService.favoriteList.reorder(data.source.data.entity.type, data.source.data.entity.id, favoriteService.favoriteList.indexAt(data.treeInstruction?.type === 'reorder-above' ? 'before' : 'after', favorite));
                track.$.navigationPanel.organize.orderOrganizeItem({
                    type: data.source.data.entity.type
                });
            } else if (data.source.data.entity?.type && isFavoriteSupportType(data.source.data.entity.type)) {
                favoriteService.favoriteList.add(data.source.data.entity.type, data.source.data.entity.id, favoriteService.favoriteList.indexAt(data.treeInstruction?.type === 'reorder-above' ? 'before' : 'after', favorite));
                track.$.navigationPanel.organize.toggleFavorite({
                    type: data.source.data.entity.type,
                    on: true
                });
            } else {
                return;
            }
        }
    }, [
        favoriteService
    ]);
    const { dropTargetRef, draggedOverDraggable, draggedOverPosition } = useDropTarget(()=>({
            data: {
                at: 'explorer:favorite:root'
            },
            onDrop: handleDrop,
            canDrop: favoriteRootCanDrop
        }), [
        handleDrop
    ]);
    return _jsx(CollapsibleSection, {
        name: "favorites",
        title: t['com.affine.rootAppSidebar.favorites'](),
        headerRef: dropTargetRef,
        testId: "explorer-favorites",
        headerTestId: "explorer-favorite-category-divider",
        actions: _jsxs(_Fragment, {
            children: [
                _jsx(IconButton, {
                    "data-testid": "explorer-bar-add-favorite-button",
                    "data-event-props": "$.navigationPanel.favorites.createDoc",
                    "data-event-args-control": "addFavorite",
                    onClick: handleCreateNewFavoriteDoc,
                    onAuxClick: handleCreateNewFavoriteDoc,
                    size: "16",
                    tooltip: t['com.affine.rootAppSidebar.explorer.fav-section-add-tooltip'](),
                    children: _jsx(PlusIcon, {})
                }),
                draggedOverDraggable && _jsx(DropEffect, {
                    position: draggedOverPosition,
                    dropEffect: favoriteRootDropEffect({
                        source: draggedOverDraggable,
                        treeInstruction: null
                    })
                })
            ]
        }),
        children: _jsx(ExplorerTreeRoot, {
            placeholder: _jsx(RootEmpty, {
                onDrop: handleDrop,
                isLoading: isLoading
            }),
            children: favorites.map((favorite)=>_jsx(ExplorerFavoriteNode, {
                    favorite: favorite,
                    onDrop: handleOnChildrenDrop
                }, favorite.id))
        })
    });
};
const childLocation = {
    at: 'explorer:favorite:list'
};
const ExplorerFavoriteNode = ({ favorite, onDrop })=>{
    const handleOnChildrenDrop = useCallback((data)=>{
        onDrop(favorite, data);
    }, [
        favorite,
        onDrop
    ]);
    return favorite.type === 'doc' ? _jsx(ExplorerDocNode, {
        docId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id) : favorite.type === 'tag' ? _jsx(ExplorerTagNode, {
        tagId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id) : favorite.type === 'folder' ? _jsx(ExplorerFolderNode, {
        nodeId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id) : favorite.type === FeedNodeType.Folder ? _jsx(ExplorerFeedFolderNode, {
        nodeId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id) : favorite.type === FeedNodeType.RSS ? _jsx(ExplorerFeedRSSNode, {
        nodeId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id) : _jsx(ExplorerCollectionNode, {
        collectionId: favorite.id,
        location: childLocation,
        onDrop: handleOnChildrenDrop,
        dropEffect: favoriteChildrenDropEffect,
        canDrop: favoriteChildrenCanDrop
    }, favorite.id);
};
