import { HighlightSelectionExtension } from '@blocksuite/affine-shared/selection';
import { BlockViewExtension, CommandExtension, FlavourExtension } from '@blocksuite/block-std';
import { literal } from 'lit/static-html.js';
import { commands } from './commands/index.js';
import { SurfaceBlockService } from './surface-service.js';
const CommonSurfaceBlockSpec = [
    FlavourExtension('affine:surface'),
    SurfaceBlockService,
    CommandExtension(commands),
    HighlightSelectionExtension
];
export const PageSurfaceBlockSpec = [
    ...CommonSurfaceBlockSpec,
    BlockViewExtension('affine:surface', literal`affine-surface-void`)
];
export const EdgelessSurfaceBlockSpec = [
    ...CommonSurfaceBlockSpec,
    BlockViewExtension('affine:surface', literal`affine-surface`)
];
