import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon, InformationFillDuotoneIcon } from '@blocksuite/icons/rc';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import { useCallback } from 'react';
import { Button, IconButton } from '../button';
import * as styles from './styles.css';
import { getActionTextColor, getCardBorderColor, getCardColor, getCardForegroundColor, getCloseIconColor, getIconColor } from './utils';
export const NotificationCard = ({ notification })=>{
    const { theme = 'info', style = 'normal', icon = _jsx(InformationFillDuotoneIcon, {}), iconColor, thumb, action, title, footer, alignMessage = 'title', onDismiss, rootAttrs } = notification;
    const onActionClicked = useCallback(()=>{
        action?.onClick()?.catch(console.error);
        if (action?.autoClose !== false) {
            onDismiss?.();
        }
    }, [
        action,
        onDismiss
    ]);
    return _jsxs("div", {
        style: assignInlineVars({
            [styles.cardColor]: getCardColor(style, theme),
            [styles.cardBorderColor]: getCardBorderColor(style),
            [styles.cardForeground]: getCardForegroundColor(style),
            [styles.actionTextColor]: getActionTextColor(style, theme),
            [styles.iconColor]: getIconColor(style, theme, iconColor),
            [styles.closeIconColor]: getCloseIconColor(style)
        }),
        "data-with-icon": icon ? '' : undefined,
        ...rootAttrs,
        className: clsx(styles.card, rootAttrs?.className),
        children: [
            thumb,
            _jsxs("div", {
                className: styles.cardInner,
                children: [
                    _jsxs("header", {
                        className: styles.header,
                        children: [
                            icon ? _jsx("div", {
                                className: clsx(styles.icon, styles.headAlignWrapper),
                                children: icon
                            }) : null,
                            _jsx("div", {
                                className: styles.title,
                                children: title
                            }),
                            action ? _jsx("div", {
                                className: clsx(styles.headAlignWrapper, styles.action),
                                children: _jsx(Button, {
                                    className: styles.actionButton,
                                    onClick: onActionClicked,
                                    ...action.buttonProps,
                                    children: action.label
                                })
                            }) : null,
                            _jsx("div", {
                                "data-float": !!thumb,
                                className: clsx(styles.headAlignWrapper, styles.closeButton),
                                children: _jsx(IconButton, {
                                    "data-testid": "notification-close-button",
                                    onClick: onDismiss,
                                    children: _jsx(CloseIcon, {
                                        className: styles.closeIcon,
                                        width: 16,
                                        height: 16
                                    })
                                })
                            })
                        ]
                    }),
                    _jsx("main", {
                        "data-align": alignMessage,
                        className: styles.main,
                        children: notification.message
                    }),
                    _jsx("footer", {
                        children: footer
                    })
                ]
            })
        ]
    });
};
