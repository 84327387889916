import { BlockViewExtension } from '@blocksuite/block-std';
import { literal } from 'lit/static-html.js';
export const AIChatBlockSpec = [
    BlockViewExtension('affine:embed-ai-chat', (model)=>{
        const parent = model.doc.getParent(model.id);
        if (parent?.flavour === 'affine:surface') {
            return literal`affine-edgeless-ai-chat`;
        }
        return literal`affine-ai-chat`;
    })
];
