function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init_answer, _init_ctx, _init_height, _init_host, _init_mindmapStyle, _init_portalHost, _init_templateShow, _initProto;
import { MindmapStyleFour, MindmapStyleOne, MindmapStyleThree, MindmapStyleTwo } from '@blocksuite/affine-components/icons';
import { MindmapStyle } from '@blocksuite/affine-model';
import { BlockStdScope } from '@blocksuite/block-std';
import { WithDisposable } from '@blocksuite/global/utils';
import { DocCollection, IdGeneratorType, Job, Schema } from '@blocksuite/store';
import { css, html, LitElement, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { MarkdownAdapter } from '../../_common/adapters/markdown.js';
import { MiniMindmapSchema, MiniMindmapSpecs } from './spec.js';
const mindmapStyles = [
    [
        MindmapStyle.ONE,
        MindmapStyleOne
    ],
    [
        MindmapStyle.TWO,
        MindmapStyleTwo
    ],
    [
        MindmapStyle.THREE,
        MindmapStyleThree
    ],
    [
        MindmapStyle.FOUR,
        MindmapStyleFour
    ]
];
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
}), _dec5 = query('editor-host'), _dec6 = property({
    attribute: false
});
export class MiniMindmapPreview extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_answer, _init_ctx, _init_height, _init_host, _init_mindmapStyle, _init_portalHost, _init_templateShow, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "answer"
            ],
            [
                _dec1,
                1,
                "ctx"
            ],
            [
                _dec2,
                1,
                "height"
            ],
            [
                _dec3,
                1,
                "host"
            ],
            [
                _dec4,
                1,
                "mindmapStyle"
            ],
            [
                _dec5,
                1,
                "portalHost"
            ],
            [
                _dec6,
                1,
                "templateShow"
            ]
        ], []));
    }
    static{
        this.styles = css`
    mini-mindmap-root-block,
    mini-mindmap-surface-block,
    editor-host {
      display: block;
      width: 100%;
      height: 100%;
    }

    .select-template-title {
      align-self: stretch;

      color: var(
        --light-textColor-textSecondaryColor,
        var(--textColor-textSecondaryColor, #8e8d91)
      );

      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      margin-bottom: 4px;
    }

    .template {
      display: flex;
      gap: 12px;
    }

    .template-item {
      box-sizing: border-box;
      border: 2px solid var(--affine-border-color);
      border-radius: 4px;
      padding: 4px 6px;
    }

    .template-item.active,
    .template-item:hover {
      border-color: var(--affine-brand-color);
    }

    .template-item > svg {
      display: block;
    }
  `;
    }
    get _mindmap() {
        return this.surface?.getElementById(this.mindmapId || '') ?? null;
    }
    _createTemporaryDoc() {
        const schema = new Schema();
        schema.register(MiniMindmapSchema);
        const options = {
            id: 'MINI_MINDMAP_TEMPORARY',
            schema,
            idGenerator: IdGeneratorType.NanoID,
            awarenessSources: []
        };
        const collection = new DocCollection(options);
        collection.meta.initialize();
        collection.start();
        const doc = collection.createDoc({
            id: 'doc:home'
        }).load();
        const rootId = doc.addBlock('affine:page', {});
        const surfaceId = doc.addBlock('affine:surface', {}, rootId);
        const surface = doc.getBlockById(surfaceId);
        doc.resetHistory();
        return {
            doc,
            surface
        };
    }
    _switchStyle(style) {
        if (!this._mindmap || !this.doc) {
            return;
        }
        this.doc.transact(()=>{
            this._mindmap.style = style;
        });
        this.ctx.set({
            ...this.ctx.get(),
            style
        });
        this.requestUpdate();
    }
    _toMindmapNode(answer, doc) {
        return markdownToMindmap(answer, doc);
    }
    connectedCallback() {
        super.connectedCallback();
        const tempDoc = this._createTemporaryDoc();
        const mindmapNode = this._toMindmapNode(this.answer, tempDoc.doc);
        if (!mindmapNode) {
            return;
        }
        this.doc = tempDoc.doc;
        this.surface = tempDoc.surface;
        this.mindmapId = this.surface.addElement({
            type: 'mindmap',
            children: mindmapNode,
            style: this.mindmapStyle ?? MindmapStyle.FOUR
        });
        this.surface.getElementById(this.mindmapId);
        const centerPosition = this._mindmap?.tree.element.xywh;
        this.ctx.set({
            node: mindmapNode,
            style: MindmapStyle.FOUR,
            centerPosition
        });
    }
    render() {
        if (!this.doc || !this.surface || !this._mindmap) return nothing;
        const curStyle = this._mindmap.style;
        return html` <div>
      <div
        style=${styleMap({
            height: this.height + 'px',
            border: '1px solid var(--affine-border-color)',
            borderRadius: '4px'
        })}
      >
        ${new BlockStdScope({
            doc: this.doc,
            extensions: MiniMindmapSpecs
        }).render()}
      </div>

      ${this.templateShow ? html` <div class="select-template-title">Select template</div>
            <div class="template">
              ${repeat(mindmapStyles, ([style])=>style, ([style, icon])=>{
            return html`<div
                    class=${`template-item ${curStyle === style ? 'active' : ''}`}
                    @click=${()=>this._switchStyle(style)}
                  >
                    ${icon}
                  </div>`;
        })}
            </div>` : nothing}
    </div>`;
    }
    #___private_answer_1;
    get answer() {
        return this.#___private_answer_1;
    }
    set answer(_v) {
        this.#___private_answer_1 = _v;
    }
    #___private_ctx_2;
    get ctx() {
        return this.#___private_ctx_2;
    }
    set ctx(_v) {
        this.#___private_ctx_2 = _v;
    }
    #___private_height_3;
    get height() {
        return this.#___private_height_3;
    }
    set height(_v) {
        this.#___private_height_3 = _v;
    }
    #___private_host_4;
    get host() {
        return this.#___private_host_4;
    }
    set host(_v) {
        this.#___private_host_4 = _v;
    }
    #___private_mindmapStyle_5;
    get mindmapStyle() {
        return this.#___private_mindmapStyle_5;
    }
    set mindmapStyle(_v) {
        this.#___private_mindmapStyle_5 = _v;
    }
    #___private_portalHost_6;
    get portalHost() {
        return this.#___private_portalHost_6;
    }
    set portalHost(_v) {
        this.#___private_portalHost_6 = _v;
    }
    #___private_templateShow_7;
    get templateShow() {
        return this.#___private_templateShow_7;
    }
    set templateShow(_v) {
        this.#___private_templateShow_7 = _v;
    }
    constructor(...args){
        super(...args), this.#___private_answer_1 = (_initProto(this), _init_answer(this)), this.#___private_ctx_2 = _init_ctx(this), this.#___private_height_3 = _init_height(this, 400), this.#___private_host_4 = _init_host(this), this.#___private_mindmapStyle_5 = _init_mindmapStyle(this, undefined), this.#___private_portalHost_6 = _init_portalHost(this), this.#___private_templateShow_7 = _init_templateShow(this, true);
    }
}
export const markdownToMindmap = (answer, doc)=>{
    let result = null;
    const job = new Job({
        collection: doc.collection
    });
    const markdown = new MarkdownAdapter(job);
    const ast = markdown['_markdownToAst'](answer);
    const traverse = (markdownNode, firstLevel = false)=>{
        switch(markdownNode.type){
            case 'list':
                {
                    const listItems = markdownNode.children.map((child)=>traverse(child)).filter((val)=>val);
                    if (firstLevel) {
                        return listItems[0];
                    }
                }
                break;
            case 'listItem':
                {
                    const paragraph = markdownNode.children[0];
                    const list = markdownNode.children[1];
                    const node = {
                        text: '',
                        children: []
                    };
                    if (paragraph?.type === 'paragraph') {
                        if (paragraph.children[0]?.type === 'text') {
                            node.text = paragraph.children[0].value;
                        }
                    }
                    if (list?.type === 'list') {
                        node.children = list.children.map((child)=>traverse(child)).filter((val)=>val);
                    }
                    return node;
                }
        }
        return null;
    };
    if (ast?.children?.[0]?.type === 'list') {
        result = traverse(ast.children[0], true);
    }
    return result;
};
