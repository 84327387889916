import { deserializeXYWH, Point } from '@blocksuite/global/utils';
import { matchFlavours } from './_common/utils/index.js';
import { splitElements } from './root-block/edgeless/utils/clipboard-utils.js';
import { isCanvasElement } from './root-block/edgeless/utils/query.js';
export * from './_common/adapters/index.js';
export * from './_common/components/ai-item/index.js';
export { scrollbarStyle } from './_common/components/index.js';
export { ExportManager, ExportManagerExtension } from './_common/export-manager/export-manager.js';
export * from './_common/test-utils/test-utils.js';
export * from './_common/transformers/index.js';
export * from './_specs/index.js';
export * from './attachment-block/index.js';
export * from './bookmark-block/index.js';
export * from './code-block/index.js';
export * from './data-view-block/index.js';
export * from './database-block/index.js';
export * from './divider-block/index.js';
export * from './edgeless-text-block/index.js';
export * from './frame-block/index.js';
export * from './image-block/index.js';
export * from './latex-block/index.js';
export * from './note-block/index.js';
export { EdgelessTemplatePanel } from './root-block/edgeless/components/toolbar/template/template-panel.js';
export { CopilotSelectionController } from './root-block/edgeless/tools/copilot-tool.js';
export * from './root-block/index.js';
export * from './schemas.js';
export { markdownToMindmap, MindmapSurfaceBlock, MiniMindmapPreview } from './surface-block/mini-mindmap/index.js';
export * from './surface-ref-block/index.js';
export * from '@blocksuite/affine-block-embed';
export * from '@blocksuite/affine-block-list';
export * from '@blocksuite/affine-block-paragraph';
export * from '@blocksuite/affine-block-surface';
export { HoverController, whenHover } from '@blocksuite/affine-components/hover';
export { ArrowDownSmallIcon, CloseIcon, DocIcon, DualLinkIcon16, LinkedDocIcon, PlusIcon, TagsIcon } from '@blocksuite/affine-components/icons';
export * from '@blocksuite/affine-components/icons';
export * from '@blocksuite/affine-components/peek';
export { createLitPortal, createSimplePortal } from '@blocksuite/affine-components/portal';
export * from '@blocksuite/affine-components/rich-text';
export { toast } from '@blocksuite/affine-components/toast';
export { groupsToActions, renderActions, renderGroups, renderToolbarSeparator, Tooltip } from '@blocksuite/affine-components/toolbar';
export * from '@blocksuite/affine-model';
export * from '@blocksuite/affine-shared/services';
export { ColorVariables, FontFamilyVariables, SizeVariables, StyleVariables, ThemeObserver } from '@blocksuite/affine-shared/theme';
export { createButtonPopper, createDefaultDoc, findNoteBlockModel, isInsideEdgelessEditor, isInsidePageEditor, matchFlavours, on, once, openFileOrFiles, printToPdf } from '@blocksuite/affine-shared/utils';
export const BlocksUtils = {
    splitElements,
    matchFlavours,
    deserializeXYWH,
    isCanvasElement,
    Point
};
const env = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : {};
const importIdentifier = '__ $BLOCKSUITE_BLOCKS$ __';
if (env[importIdentifier] === true) {
    console.error('@blocksuite/blocks was already imported. This breaks constructor checks and will lead to issues!');
}
env[importIdentifier] = true;
