import { HoverController } from '@blocksuite/affine-components/hover';
import { cloneGroups } from '@blocksuite/affine-components/toolbar';
import { WidgetComponent } from '@blocksuite/block-std';
import { limitShift, shift } from '@floating-ui/dom';
import { html } from 'lit';
import { PAGE_HEADER_HEIGHT } from '../../../_common/consts.js';
import { getMoreMenuConfig } from '../../configs/toolbar.js';
import { MORE_GROUPS, PRIMARY_GROUPS } from './config.js';
import { ImageToolbarContext } from './context.js';
export const AFFINE_IMAGE_TOOLBAR_WIDGET = 'affine-image-toolbar-widget';
export class AffineImageToolbarWidget extends WidgetComponent {
    firstUpdated() {
        if (this.doc.getParent(this.model.id)?.flavour === 'affine:surface') {
            return;
        }
        this.moreGroups = getMoreMenuConfig(this.std).configure(this.moreGroups);
        this._setHoverController();
    }
    constructor(...args){
        super(...args), this._hoverController = null, this._isActivated = false, this._setHoverController = ()=>{
            this._hoverController = null;
            this._hoverController = new HoverController(this, ({ abortController })=>{
                const imageBlock = this.block;
                const selection = this.host.selection;
                const textSelection = selection.find('text');
                if (!!textSelection && (!!textSelection.to || !!textSelection.from.length)) {
                    return null;
                }
                const blockSelections = selection.filter('block');
                if (blockSelections.length > 1 || blockSelections.length === 1 && blockSelections[0].blockId !== imageBlock.blockId) {
                    return null;
                }
                const imageContainer = imageBlock.resizableImg ?? imageBlock.fallbackCard;
                if (!imageContainer) {
                    return null;
                }
                const context = new ImageToolbarContext(imageBlock, abortController);
                return {
                    template: html`<affine-image-toolbar
            .context=${context}
            .primaryGroups=${this.primaryGroups}
            .moreGroups=${this.moreGroups}
            .onActiveStatusChange=${(active)=>{
                        this._isActivated = active;
                        if (!active && !this._hoverController?.isHovering) {
                            this._hoverController?.abort();
                        }
                    }}
          ></affine-image-toolbar>`,
                    container: this.block,
                    portalStyles: {
                        zIndex: 'var(--affine-z-index-popover)'
                    },
                    computePosition: {
                        referenceElement: imageContainer,
                        placement: 'right-start',
                        middleware: [
                            shift({
                                crossAxis: true,
                                padding: {
                                    top: PAGE_HEADER_HEIGHT + 12,
                                    bottom: 12,
                                    right: 12
                                },
                                limiter: limitShift()
                            })
                        ],
                        autoUpdate: true
                    }
                };
            }, {
                allowMultiple: true
            });
            const imageBlock = this.block;
            this._hoverController.setReference(imageBlock);
            this._hoverController.onAbort = ()=>{
                if (this._isActivated) return;
                this._hoverController?.abort();
                return;
            };
        }, this.addMoreItems = (items, index, type)=>{
            let group;
            if (type) {
                group = this.moreGroups.find((g)=>g.type === type);
            }
            if (!group) {
                group = this.moreGroups[0];
            }
            if (index === undefined) {
                group.items.push(...items);
                return this;
            }
            group.items.splice(index, 0, ...items);
            return this;
        }, this.addPrimaryItems = (items, index)=>{
            if (index === undefined) {
                this.primaryGroups[0].items.push(...items);
                return this;
            }
            this.primaryGroups[0].items.splice(index, 0, ...items);
            return this;
        }, this.moreGroups = cloneGroups(MORE_GROUPS), this.primaryGroups = cloneGroups(PRIMARY_GROUPS);
    }
}
