import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const ScopeRootComponentsContext = React.createContext({
    nodes: new Map(),
    setNodes: ()=>{}
});
let _id = 0;
export const useMount = (debugKey)=>{
    const [id] = React.useState(_id++);
    const { setNodes } = React.useContext(ScopeRootComponentsContext);
    const unmount = React.useCallback(()=>{
        setNodes((prev)=>{
            if (!prev.has(id)) {
                return prev;
            }
            const next = new Map(prev);
            next.delete(id);
            return next;
        });
    }, [
        id,
        setNodes
    ]);
    const mount = React.useCallback((node)=>{
        setNodes((prev)=>new Map(prev).set(id, {
                node,
                debugKey
            }));
        return unmount;
    }, [
        setNodes,
        id,
        debugKey,
        unmount
    ]);
    return React.useMemo(()=>{
        return {
            mount
        };
    }, [
        mount
    ]);
};
export const MountPoint = ({ children })=>{
    const [nodes, setNodes] = React.useState(new Map());
    return _jsxs(ScopeRootComponentsContext.Provider, {
        value: {
            nodes,
            setNodes
        },
        children: [
            children,
            Array.from(nodes.entries()).map(([id, { node, debugKey }])=>_jsx("div", {
                    "data-testid": debugKey,
                    style: {
                        display: 'contents'
                    },
                    children: node
                }, id))
        ]
    });
};
