import { BlockSuiteError, ErrorCode } from '@blocksuite/global/exceptions';
import { VElement } from '../components/v-element.js';
import { isInEmbedElement } from './embed.js';
import { nativePointToTextPoint, textPointToDomPoint } from './point-conversion.js';
import { calculateTextLength, getTextNodesFromElement } from './text.js';
const rangeHasAnchorAndFocus = ({ rootElement, startText, endText })=>{
    return rootElement.contains(startText) && rootElement.contains(endText);
};
const rangeHasAnchorAndFocusHandler = ({ rootElement, startText, endText, startTextOffset, endTextOffset })=>{
    const anchorDomPoint = textPointToDomPoint(startText, startTextOffset, rootElement);
    const focusDomPoint = textPointToDomPoint(endText, endTextOffset, rootElement);
    if (!anchorDomPoint || !focusDomPoint) {
        return null;
    }
    return {
        index: Math.min(anchorDomPoint.index, focusDomPoint.index),
        length: Math.abs(anchorDomPoint.index - focusDomPoint.index)
    };
};
const rangeOnlyHasFocus = ({ rootElement, startText, endText })=>{
    return !rootElement.contains(startText) && rootElement.contains(endText);
};
const rangeOnlyHasFocusHandler = ({ rootElement, endText, endTextOffset })=>{
    const focusDomPoint = textPointToDomPoint(endText, endTextOffset, rootElement);
    if (!focusDomPoint) {
        return null;
    }
    return {
        index: 0,
        length: focusDomPoint.index
    };
};
const rangeOnlyHasAnchor = ({ rootElement, startText, endText })=>{
    return rootElement.contains(startText) && !rootElement.contains(endText);
};
const rangeOnlyHasAnchorHandler = ({ yText, rootElement, startText, startTextOffset })=>{
    const startDomPoint = textPointToDomPoint(startText, startTextOffset, rootElement);
    if (!startDomPoint) {
        return null;
    }
    return {
        index: startDomPoint.index,
        length: yText.length - startDomPoint.index
    };
};
const rangeHasNoAnchorAndFocus = ({ rootElement, startText, endText, range })=>{
    return !rootElement.contains(startText) && !rootElement.contains(endText) && range.intersectsNode(rootElement);
};
const rangeHasNoAnchorAndFocusHandler = ({ yText })=>{
    return {
        index: 0,
        length: yText.length
    };
};
const buildContext = (range, rootElement, yText)=>{
    const { startContainer, startOffset, endContainer, endOffset } = range;
    const startTextPoint = nativePointToTextPoint(startContainer, startOffset);
    const endTextPoint = nativePointToTextPoint(endContainer, endOffset);
    if (!startTextPoint || !endTextPoint) {
        return null;
    }
    const [startText, startTextOffset] = startTextPoint;
    const [endText, endTextOffset] = endTextPoint;
    return {
        rootElement,
        range,
        yText,
        startNode: startContainer,
        startOffset,
        endNode: endContainer,
        endOffset,
        startText,
        startTextOffset,
        endText,
        endTextOffset
    };
};
export function domRangeToInlineRange(range, rootElement, yText) {
    const context = buildContext(range, rootElement, yText);
    if (!context) return null;
    if (context.startNode && context.startNode === context.endNode && isInEmbedElement(context.startNode)) {
        const anchorDomPoint = textPointToDomPoint(context.startText, context.startTextOffset, rootElement);
        if (anchorDomPoint) {
            return {
                index: anchorDomPoint.index,
                length: 1
            };
        }
    }
    if (rangeHasAnchorAndFocus(context)) {
        return rangeHasAnchorAndFocusHandler(context);
    }
    if (rangeOnlyHasFocus(context)) {
        return rangeOnlyHasFocusHandler(context);
    }
    if (rangeOnlyHasAnchor(context)) {
        return rangeOnlyHasAnchorHandler(context);
    }
    if (rangeHasNoAnchorAndFocus(context)) {
        return rangeHasNoAnchorAndFocusHandler(context);
    }
    return null;
}
export function inlineRangeToDomRange(rootElement, inlineRange) {
    const lineElements = Array.from(rootElement.querySelectorAll('v-line'));
    let startText = null;
    let endText = null;
    let anchorOffset = 0;
    let focusOffset = 0;
    let index = 0;
    for(let i = 0; i < lineElements.length; i++){
        if (startText && endText) {
            break;
        }
        const texts = getTextNodesFromElement(lineElements[i]);
        if (texts.length === 0) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'text node in v-text not found');
        }
        for (const text of texts){
            const textLength = calculateTextLength(text);
            if (!startText && index + textLength >= inlineRange.index) {
                startText = text;
                anchorOffset = inlineRange.index - index;
            }
            if (!endText && index + textLength >= inlineRange.index + inlineRange.length) {
                endText = text;
                focusOffset = inlineRange.index + inlineRange.length - index;
            }
            if (startText && endText) {
                break;
            }
            index += textLength;
        }
        index += 1;
    }
    if (!startText || !endText) {
        return null;
    }
    if (isInEmbedElement(startText)) {
        const anchorVElement = startText.parentElement?.closest('v-element');
        if (!anchorVElement) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'failed to find vElement for a text note in an embed element');
        }
        const nextSibling = anchorVElement.nextElementSibling;
        if (!nextSibling) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'failed to find nextSibling sibling of an embed element');
        }
        const texts = getTextNodesFromElement(nextSibling);
        if (texts.length === 0) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'text node in v-text not found');
        }
        if (nextSibling instanceof VElement) {
            startText = texts[texts.length - 1];
            anchorOffset = calculateTextLength(startText);
        } else {
            startText = texts[0];
            anchorOffset = 0;
        }
    }
    if (isInEmbedElement(endText)) {
        const focusVElement = endText.parentElement?.closest('v-element');
        if (!focusVElement) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'failed to find vElement for a text note in an embed element');
        }
        const nextSibling = focusVElement.nextElementSibling;
        if (!nextSibling) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'failed to find nextSibling sibling of an embed element');
        }
        const texts = getTextNodesFromElement(nextSibling);
        if (texts.length === 0) {
            throw new BlockSuiteError(ErrorCode.InlineEditorError, 'text node in v-text not found');
        }
        endText = texts[0];
        focusOffset = 0;
    }
    const range = document.createRange();
    range.setStart(startText, anchorOffset);
    range.setEnd(endText, focusOffset);
    return range;
}
