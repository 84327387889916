import { getObjectPropMeta, setObjectPropMeta } from './common.js';
const observeSymbol = Symbol('observe');
const observerDisposableSymbol = Symbol('observerDisposable');
export function observe(fn) {
    return function observeDecorator(_, context) {
        const prop = context.name;
        return {
            init (v) {
                setObjectPropMeta(observeSymbol, Object.getPrototypeOf(this), prop, fn);
                return v;
            }
        };
    };
}
function getObserveMeta(proto, prop) {
    return getObjectPropMeta(proto, observeSymbol, prop);
}
export function startObserve(prop, receiver) {
    const proto = Object.getPrototypeOf(receiver);
    const observeFn = getObserveMeta(proto, prop);
    const observerDisposable = receiver[observerDisposableSymbol] ?? {};
    receiver[observerDisposableSymbol] = observerDisposable;
    if (observerDisposable[prop]) {
        observerDisposable[prop]();
        delete observerDisposable[prop];
    }
    if (!observeFn) {
        return;
    }
    const value = receiver[prop];
    observeFn(null, receiver, null);
    const fn = (event, transaction)=>{
        observeFn(event, receiver, transaction);
    };
    if (value && 'observe' in value) {
        value.observe(fn);
        observerDisposable[prop] = ()=>{
            value.unobserve(fn);
        };
    } else {
        console.warn(`Failed to observe "${prop.toString()}" of ${receiver.type} element, make sure it's a Y type.`);
    }
}
export function initializeObservers(proto, receiver) {
    const observers = getObjectPropMeta(proto, observeSymbol);
    Object.keys(observers).forEach((prop)=>{
        startObserve(prop, receiver);
    });
    receiver['_disposable'].add(()=>{
        Object.values(receiver[observerDisposableSymbol] ?? {}).forEach((dispose)=>dispose());
    });
}
