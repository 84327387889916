import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@affine/component';
import clsx from 'clsx';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import * as styles from './indicator.css';
export const SplitViewMenuIndicator = memo(forwardRef(function SplitViewMenuIndicator({ className, active, open, setPressed, onOpenMenu, ...attrs }, ref) {
    const onMouseDown = useCallback(()=>{
        const t = setTimeout(()=>setPressed(true), 100);
        window.addEventListener('mouseup', ()=>{
            clearTimeout(t);
            setPressed(false);
        }, {
            once: true
        });
    }, [
        setPressed
    ]);
    const onClick = useCallback(()=>{
        !open && onOpenMenu?.();
    }, [
        onOpenMenu,
        open
    ]);
    return _jsx("div", {
        ref: ref,
        "data-active": active,
        "data-testid": "split-view-indicator",
        className: clsx(className, styles.indicator),
        onClick: onClick,
        onMouseDown: onMouseDown,
        ...attrs,
        children: _jsx("div", {
            className: styles.indicatorInner
        })
    });
}));
export const SplitViewIndicator = ({ isDragging, isActive, menuItems, listeners, setPressed })=>{
    const active = isActive || isDragging;
    const [menuOpen, setMenuOpen] = useState(false);
    const setOpenMenuManually = useCallback((open)=>{
        if (open) return;
        setMenuOpen(open);
    }, []);
    const openMenu = useCallback(()=>{
        setMenuOpen(true);
    }, []);
    const menuRootOptions = useMemo(()=>({
            open: menuOpen,
            onOpenChange: setOpenMenuManually
        }), [
        menuOpen,
        setOpenMenuManually
    ]);
    const menuContentOptions = useMemo(()=>({
            align: 'center'
        }), []);
    return _jsxs("div", {
        "data-is-dragging": isDragging,
        className: styles.indicatorWrapper,
        children: [
            _jsx(Menu, {
                contentOptions: menuContentOptions,
                items: menuItems,
                rootOptions: menuRootOptions,
                children: _jsx("div", {
                    className: styles.menuTrigger
                })
            }),
            _jsx(SplitViewMenuIndicator, {
                open: menuOpen,
                onOpenMenu: openMenu,
                active: active,
                setPressed: setPressed,
                ...listeners
            })
        ]
    });
};
