import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import * as styles from './split-view.css';
export const ResizeHandle = ({ resizing, onResizing, onResizeStart, onResizeEnd })=>{
    const onMouseDown = useCallback((e)=>{
        e.preventDefault();
        onResizeStart();
        const prevPos = {
            x: e.clientX,
            y: e.clientY
        };
        function onMouseMove(e) {
            e.preventDefault();
            const dx = e.clientX - prevPos.x;
            const dy = e.clientY - prevPos.y;
            onResizing({
                x: dx,
                y: dy
            });
            prevPos.x = e.clientX;
            prevPos.y = e.clientY;
        }
        function onMouseUp(e) {
            e.preventDefault();
            onResizeEnd();
            document.removeEventListener('mousemove', onMouseMove);
        }
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {
            once: true
        });
    }, [
        onResizeEnd,
        onResizeStart,
        onResizing
    ]);
    return _jsx("div", {
        onMouseDown: onMouseDown,
        "data-resizing": resizing || null,
        className: styles.resizeHandle
    });
};
