import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useBlockSuitePagePreview } from './use-block-suite-page-preview';
import { useDocCollectionPage } from './use-block-suite-workspace-page';
const PagePreviewInner = ({ docCollection: workspace, pageId, emptyFallback })=>{
    const page = useDocCollectionPage(workspace, pageId);
    const previewAtom = useBlockSuitePagePreview(page);
    const preview = useAtomValue(previewAtom);
    const res = preview ? preview : null;
    return res || emptyFallback;
};
export const PagePreview = (props)=>{
    return _jsx(Suspense, {
        children: _jsx(PagePreviewInner, {
            ...props
        })
    });
};
