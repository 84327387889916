function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init__chatMessagesContainer, _init_parentModel, _init_host, _init__historyMessages, _init_chatContext, _initProto;
import './chat-block-input';
import './date-time';
import '../_common/components/chat-action-list';
import '../_common/components/copy-more';
import { CanvasElementType, ConnectorMode, DocModeProvider, TelemetryProvider } from '@blocksuite/affine/blocks';
import { NotificationProvider } from '@blocksuite/affine/blocks';
import { ChatMessagesSchema } from '@blocksuite/affine/presets';
import { html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { ChatBlockPeekViewActions, constructUserInfoWithMessages, queryHistoryMessages } from '../_common/chat-actions-handle';
import { SmallHintIcon } from '../_common/icons';
import { AIChatErrorRenderer } from '../messages/error';
import { AIProvider } from '../provider';
import { PeekViewStyles } from './styles';
import { calcChildBound } from './utils';
_dec = query('.ai-chat-messages-container'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = state(), _dec4 = state();
export class AIChatBlockPeekView extends LitElement {
    static{
        ({ e: [_init__chatMessagesContainer, _init_parentModel, _init_host, _init__historyMessages, _init_chatContext, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_chatMessagesContainer"
            ],
            [
                _dec1,
                1,
                "parentModel"
            ],
            [
                _dec2,
                1,
                "host"
            ],
            [
                _dec3,
                1,
                "_historyMessages"
            ],
            [
                _dec4,
                1,
                "chatContext"
            ]
        ], []));
    }
    static{
        this.styles = PeekViewStyles;
    }
    get _rootService() {
        return this.host.std.getService('affine:page');
    }
    get _modeService() {
        return this.host.std.get(DocModeProvider);
    }
    get parentSessionId() {
        return this.parentModel.sessionId;
    }
    get historyMessagesString() {
        return this.parentModel.messages;
    }
    get parentChatBlockId() {
        return this.parentModel.id;
    }
    get parentRootDocId() {
        return this.parentModel.rootDocId;
    }
    get parentRootWorkspaceId() {
        return this.parentModel.rootWorkspaceId;
    }
    connectedCallback() {
        super.connectedCallback();
        this._historyMessages = this._deserializeHistoryChatMessages(this.historyMessagesString);
        const { parentRootWorkspaceId, parentRootDocId, parentSessionId } = this;
        queryHistoryMessages(parentRootWorkspaceId, parentRootDocId, parentSessionId).then((messages)=>{
            this._historyMessages = this._historyMessages.map((message, idx)=>{
                return {
                    ...message,
                    attachments: messages[idx]?.attachments ?? []
                };
            });
        }).catch((err)=>{
            console.error('Query history messages failed', err);
        });
    }
    firstUpdated() {
        requestAnimationFrame(()=>{
            if (this._chatMessagesContainer) {
                this._chatMessagesContainer.scrollTop = this._chatMessagesContainer.scrollHeight;
            }
        });
    }
    render() {
        const { host, _historyMessages } = this;
        if (!_historyMessages.length) {
            return nothing;
        }
        const latestHistoryMessage = _historyMessages[_historyMessages.length - 1];
        const latestMessageCreatedAt = latestHistoryMessage.createdAt;
        const latestHistoryMessageId = latestHistoryMessage.id;
        const { parentSessionId, updateChatBlockMessages, createAIChatBlock, cleanCurrentChatHistories, chatContext, updateContext } = this;
        const { messages: currentChatMessages } = chatContext;
        return html`<div class="ai-chat-block-peek-view-container">
      <div class="ai-chat-messages-container">
        <ai-chat-messages
          .host=${host}
          .messages=${_historyMessages}
        ></ai-chat-messages>
        <date-time .date=${latestMessageCreatedAt}></date-time>
        <div class="new-chat-messages-container">
          ${this.CurrentMessages(currentChatMessages)}
        </div>
      </div>
      <chat-block-input
        .host=${host}
        .parentSessionId=${parentSessionId}
        .latestMessageId=${latestHistoryMessageId}
        .updateChatBlock=${updateChatBlockMessages}
        .createChatBlock=${createAIChatBlock}
        .cleanupHistories=${cleanCurrentChatHistories}
        .chatContext=${chatContext}
        .updateContext=${updateContext}
      ></chat-block-input>
      <div class="peek-view-footer">
        ${SmallHintIcon}
        <div>AI outputs can be misleading or wrong</div>
      </div>
    </div> `;
    }
    #___private__chatMessagesContainer_1;
    get _chatMessagesContainer() {
        return this.#___private__chatMessagesContainer_1;
    }
    set _chatMessagesContainer(_v) {
        this.#___private__chatMessagesContainer_1 = _v;
    }
    #___private_parentModel_2;
    get parentModel() {
        return this.#___private_parentModel_2;
    }
    set parentModel(_v) {
        this.#___private_parentModel_2 = _v;
    }
    #___private_host_3;
    get host() {
        return this.#___private_host_3;
    }
    set host(_v) {
        this.#___private_host_3 = _v;
    }
    #___private__historyMessages_4;
    get _historyMessages() {
        return this.#___private__historyMessages_4;
    }
    set _historyMessages(_v) {
        this.#___private__historyMessages_4 = _v;
    }
    #___private_chatContext_5;
    get chatContext() {
        return this.#___private_chatContext_5;
    }
    set chatContext(_v) {
        this.#___private_chatContext_5 = _v;
    }
    constructor(...args){
        super(...args), this._deserializeHistoryChatMessages = (historyMessagesString)=>{
            try {
                const result = ChatMessagesSchema.safeParse(JSON.parse(historyMessagesString));
                if (result.success) {
                    return result.data;
                } else {
                    return [];
                }
            } catch  {
                return [];
            }
        }, this._constructBranchChatBlockMessages = async (rootWorkspaceId, rootDocId, forkSessionId)=>{
            const currentUserInfo = await AIProvider.userInfo;
            const forkMessages = await queryHistoryMessages(rootWorkspaceId, rootDocId, forkSessionId);
            const forkLength = forkMessages.length;
            const historyLength = this._historyMessages.length;
            if (!forkLength || forkLength <= historyLength) {
                return constructUserInfoWithMessages(forkMessages, currentUserInfo);
            }
            const historyMessages = this._historyMessages.map((message, idx)=>{
                return {
                    ...message,
                    id: forkMessages[idx]?.id ?? message.id,
                    attachments: []
                };
            });
            const currentChatMessages = constructUserInfoWithMessages(forkMessages.slice(historyLength), currentUserInfo);
            return [
                ...historyMessages,
                ...currentChatMessages
            ];
        }, this._resetContext = ()=>{
            const { abortController } = this.chatContext;
            if (abortController) {
                abortController.abort();
            }
            this.updateContext({
                status: 'idle',
                error: null,
                images: [],
                abortController: null,
                messages: [],
                currentSessionId: null,
                currentChatBlockId: null
            });
        }, this.createAIChatBlock = async ()=>{
            const mode = this._modeService.getEditorMode();
            if (mode !== 'edgeless') {
                return;
            }
            if (this.chatContext.currentChatBlockId) {
                return;
            }
            if (!this.chatContext.currentSessionId || !this.chatContext.messages.length) {
                return;
            }
            const { doc } = this.host;
            const surfaceBlock = doc.getBlocks().find((block)=>block.flavour === 'affine:surface');
            if (!surfaceBlock) {
                return;
            }
            const { parentRootWorkspaceId, parentRootDocId } = this;
            const messages = await this._constructBranchChatBlockMessages(parentRootWorkspaceId, parentRootDocId, this.chatContext.currentSessionId);
            if (!messages.length) {
                return;
            }
            const edgelessService = this._rootService;
            const bound = calcChildBound(this.parentModel, edgelessService);
            const aiChatBlockId = edgelessService.addBlock('affine:embed-ai-chat', {
                xywh: bound.serialize(),
                messages: JSON.stringify(messages),
                sessionId: this.chatContext.currentSessionId,
                rootWorkspaceId: parentRootWorkspaceId,
                rootDocId: parentRootDocId
            }, surfaceBlock.id);
            if (!aiChatBlockId) {
                return;
            }
            this.updateContext({
                currentChatBlockId: aiChatBlockId
            });
            edgelessService.addElement(CanvasElementType.CONNECTOR, {
                mode: ConnectorMode.Curve,
                controllers: [],
                source: {
                    id: this.parentChatBlockId
                },
                target: {
                    id: aiChatBlockId
                }
            });
            const telemetryService = this.host.std.getOptional(TelemetryProvider);
            telemetryService?.track('CanvasElementAdded', {
                control: 'conversation',
                page: 'whiteboard editor',
                module: 'canvas',
                segment: 'whiteboard',
                type: 'chat block',
                category: 'branch'
            });
        }, this.updateChatBlockMessages = async ()=>{
            if (!this.chatContext.currentChatBlockId || !this.chatContext.currentSessionId) {
                return;
            }
            const { doc } = this.host;
            const chatBlock = doc.getBlock(this.chatContext.currentChatBlockId);
            if (!chatBlock) return;
            const { parentRootWorkspaceId, parentRootDocId } = this;
            const messages = await this._constructBranchChatBlockMessages(parentRootWorkspaceId, parentRootDocId, this.chatContext.currentSessionId);
            if (!messages.length) {
                return;
            }
            doc.updateBlock(chatBlock.model, {
                messages: JSON.stringify(messages)
            });
        }, this.updateContext = (context)=>{
            this.chatContext = {
                ...this.chatContext,
                ...context
            };
        }, this.cleanCurrentChatHistories = async ()=>{
            if (!this._rootService) return;
            const notificationService = this.host.std.getOptional(NotificationProvider);
            if (!notificationService) return;
            const { currentChatBlockId, currentSessionId } = this.chatContext;
            if (!currentChatBlockId && !currentSessionId) {
                return;
            }
            if (await notificationService.confirm({
                title: 'Clear History',
                message: 'Are you sure you want to clear all history? This action will permanently delete all content, including all chat logs and data, and cannot be undone.',
                confirmText: 'Confirm',
                cancelText: 'Cancel'
            })) {
                const { doc } = this.host;
                if (currentSessionId) {
                    await AIProvider.histories?.cleanup(doc.collection.id, doc.id, [
                        currentSessionId
                    ]);
                }
                if (currentChatBlockId) {
                    const edgelessService = this._rootService;
                    const chatBlock = doc.getBlock(currentChatBlockId)?.model;
                    if (chatBlock) {
                        const connectors = edgelessService.getConnectors(chatBlock);
                        doc.transact(()=>{
                            edgelessService.removeElement(currentChatBlockId);
                            connectors.forEach((connector)=>{
                                edgelessService.removeElement(connector.id);
                            });
                        });
                    }
                }
                notificationService.toast('History cleared');
                this._resetContext();
            }
        }, this.retry = async ()=>{
            const { doc } = this.host;
            const { currentChatBlockId, currentSessionId } = this.chatContext;
            if (!currentChatBlockId || !currentSessionId) {
                return;
            }
            let content = '';
            try {
                const abortController = new AbortController();
                const messages = [
                    ...this.chatContext.messages
                ];
                const last = messages[messages.length - 1];
                if ('content' in last) {
                    last.content = '';
                    last.createdAt = new Date().toISOString();
                }
                this.updateContext({
                    messages,
                    status: 'loading',
                    error: null
                });
                const stream = AIProvider.actions.chat?.({
                    sessionId: currentSessionId,
                    retry: true,
                    docId: doc.id,
                    workspaceId: doc.collection.id,
                    host: this.host,
                    stream: true,
                    signal: abortController.signal,
                    where: 'ai-chat-block',
                    control: 'chat-send'
                });
                if (stream) {
                    this.updateContext({
                        abortController
                    });
                    for await (const text of stream){
                        const messages = [
                            ...this.chatContext.messages
                        ];
                        const last = messages[messages.length - 1];
                        last.content += text;
                        this.updateContext({
                            messages,
                            status: 'transmitting'
                        });
                        content += text;
                    }
                    this.updateContext({
                        status: 'success'
                    });
                }
            } catch (error) {
                this.updateContext({
                    status: 'error',
                    error: error
                });
            } finally{
                this.updateContext({
                    abortController: null
                });
                if (content) {
                    await this.updateChatBlockMessages();
                }
            }
        }, this.CurrentMessages = (currentMessages)=>{
            if (!currentMessages.length) {
                return nothing;
            }
            const { host } = this;
            const actions = ChatBlockPeekViewActions;
            return html`${repeat(currentMessages, (message, idx)=>{
                const { status, error } = this.chatContext;
                const isAssistantMessage = message.role === 'assistant';
                const isLastReply = idx === currentMessages.length - 1 && isAssistantMessage;
                const messageState = isLastReply && (status === 'transmitting' || status === 'loading') ? 'generating' : 'finished';
                const shouldRenderError = isLastReply && status === 'error' && !!error;
                const isNotReady = status === 'transmitting' || status === 'loading';
                const shouldRenderCopyMore = isAssistantMessage && !(isLastReply && isNotReady);
                const shouldRenderActions = isLastReply && !!message.content && !isNotReady;
                const messageClasses = classMap({
                    'assistant-message-container': isAssistantMessage
                });
                const { attachments, role, content } = message;
                const userInfo = {
                    userId: message.userId,
                    userName: message.userName,
                    avatarUrl: message.avatarUrl
                };
                return html`<div class=${messageClasses}>
        <ai-chat-message
          .host=${host}
          .state=${messageState}
          .content=${content}
          .attachments=${attachments}
          .messageRole=${role}
          .userInfo=${userInfo}
        ></ai-chat-message>
        ${shouldRenderError ? AIChatErrorRenderer(host, error) : nothing}
        ${shouldRenderCopyMore ? html` <chat-copy-more
              .host=${host}
              .actions=${actions}
              .content=${message.content}
              .isLast=${isLastReply}
              .chatSessionId=${this.chatContext.currentSessionId ?? undefined}
              .messageId=${message.id ?? undefined}
              .retry=${()=>this.retry()}
            ></chat-copy-more>` : nothing}
        ${shouldRenderActions ? html`<chat-action-list
              .host=${host}
              .actions=${actions}
              .content=${message.content}
              .chatSessionId=${this.chatContext.currentSessionId ?? undefined}
              .messageId=${message.id ?? undefined}
              .layoutDirection=${'horizontal'}
            ></chat-action-list>` : nothing}
      </div>`;
            })}`;
        }, this.#___private__chatMessagesContainer_1 = (_initProto(this), _init__chatMessagesContainer(this)), this.#___private_parentModel_2 = _init_parentModel(this), this.#___private_host_3 = _init_host(this), this.#___private__historyMessages_4 = _init__historyMessages(this, []), this.#___private_chatContext_5 = _init_chatContext(this, {
            status: 'idle',
            error: null,
            images: [],
            abortController: null,
            messages: [],
            currentSessionId: null,
            currentChatBlockId: null
        });
    }
}
export const AIChatBlockPeekViewTemplate = (parentModel, host)=>{
    return html`<ai-chat-block-peek-view
    .parentModel=${parentModel}
    .host=${host}
  ></ai-chat-block-peek-view>`;
};
