import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Menu, MenuItem, Tooltip } from '@affine/component';
import clsx from 'clsx';
import { useMemo } from 'react';
import { FilterTag } from './filter-tag-translation';
import * as styles from './index.css';
import { literalMatcher } from './literal-matcher';
import { tBoolean } from './logical/custom-type';
import { typesystem } from './logical/typesystem';
import { variableDefineMap } from './shared-types';
import { filterMatcher, VariableSelect, vars } from './vars';
export const Condition = ({ value, onChange, propertiesMeta })=>{
    const data = useMemo(()=>{
        const data = filterMatcher.find((v)=>v.data.name === value.funcName);
        if (!data) {
            return;
        }
        const instance = typesystem.instance({}, [
            variableDefineMap[value.left.name].type(propertiesMeta)
        ], tBoolean.create(), data.type);
        return {
            render: data.data.render,
            type: instance
        };
    }, [
        propertiesMeta,
        value.funcName,
        value.left.name
    ]);
    if (!data) {
        return null;
    }
    const render = data.render ?? (({ ast })=>{
        const args = renderArgs(value, onChange, data.type);
        return _jsxs("div", {
            className: styles.filterContainerStyle,
            children: [
                _jsx(Menu, {
                    items: _jsx(VariableSelect, {
                        propertiesMeta: propertiesMeta,
                        selected: [],
                        onSelect: onChange
                    }),
                    children: _jsxs("div", {
                        "data-testid": "variable-name",
                        className: clsx(styles.filterTypeStyle, styles.ellipsisTextStyle),
                        children: [
                            _jsx(Tooltip, {
                                content: ast.left.name,
                                children: _jsx("div", {
                                    className: styles.filterTypeIconStyle,
                                    children: variableDefineMap[ast.left.name].icon
                                })
                            }),
                            _jsx(FilterTag, {
                                name: ast.left.name
                            })
                        ]
                    })
                }),
                _jsx(Menu, {
                    items: _jsx(FunctionSelect, {
                        propertiesMeta: propertiesMeta,
                        value: value,
                        onChange: onChange
                    }),
                    children: _jsx("div", {
                        className: clsx(styles.switchStyle, styles.ellipsisTextStyle),
                        "data-testid": "filter-name",
                        children: _jsx(FilterTag, {
                            name: ast.funcName
                        })
                    })
                }),
                args
            ]
        });
    });
    return _jsx(_Fragment, {
        children: render({
            ast: value
        })
    });
};
const FunctionSelect = ({ value, onChange, propertiesMeta })=>{
    const list = useMemo(()=>{
        const type = vars.find((v)=>v.name === value.left.name)?.type;
        if (!type) {
            return [];
        }
        return filterMatcher.allMatchedData(type(propertiesMeta));
    }, [
        propertiesMeta,
        value.left.name
    ]);
    return _jsx("div", {
        "data-testid": "filter-name-select",
        children: list.map((v)=>_jsx(MenuItem, {
                onClick: ()=>{
                    onChange({
                        ...value,
                        funcName: v.name,
                        args: v.defaultArgs().map((v)=>({
                                type: 'literal',
                                value: v
                            }))
                    });
                },
                children: _jsx(FilterTag, {
                    name: v.name
                })
            }, v.name))
    });
};
export const Arg = ({ type, value, onChange })=>{
    const data = useMemo(()=>literalMatcher.match(type), [
        type
    ]);
    if (!data) {
        return null;
    }
    return _jsx("div", {
        "data-testid": "filter-arg",
        className: clsx(styles.argStyle, styles.ellipsisTextStyle),
        children: data.render({
            type,
            value: value?.value,
            onChange: (v)=>onChange({
                    type: 'literal',
                    value: v
                })
        })
    });
};
export const renderArgs = (filter, onChange, type)=>{
    const rest = type.args.slice(1);
    return rest.map((argType, i)=>{
        const value = filter.args[i];
        return _jsx(Arg, {
            type: argType,
            value: value,
            onChange: (value)=>{
                const args = type.args.map((_, index)=>i === index ? value : filter.args[index]);
                onChange({
                    ...filter,
                    args
                });
            }
        }, i);
    });
};
