import { DocsService, useService, WorkspaceService } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from './affine-async-hooks';
import { useAllBlockSuiteDocMeta } from './use-all-block-suite-page-meta';
import { useJournalInfoHelper } from './use-journal';
export function useBlockSuiteDocMeta(docCollection) {
    const pageMetas = useAllBlockSuiteDocMeta(docCollection);
    const { isPageJournal } = useJournalInfoHelper();
    return useMemo(()=>pageMetas.filter((pageMeta)=>!isPageJournal(pageMeta.id) || !!pageMeta.updatedDate), [
        isPageJournal,
        pageMetas
    ]);
}
export function useDocMetaHelper() {
    const workspaceService = useService(WorkspaceService);
    const docsService = useService(DocsService);
    const setDocTitle = useAsyncCallback(async (docId, newTitle)=>{
        await docsService.changeDocTitle(docId, newTitle);
    }, [
        docsService
    ]);
    const setDocMeta = useCallback((docId, docMeta)=>{
        const doc = docsService.list.doc$(docId).value;
        if (doc) {
            doc.setMeta(docMeta);
        }
    }, [
        docsService
    ]);
    const getDocMeta = useCallback((docId)=>{
        const doc = docsService.list.doc$(docId).value;
        return doc?.meta$.value;
    }, [
        docsService
    ]);
    const setDocReadonly = useCallback((docId, readonly)=>{
        const doc = workspaceService.workspace.docCollection.getDoc(docId);
        if (doc?.blockCollection) {
            workspaceService.workspace.docCollection.awarenessStore.setReadonly(doc.blockCollection, readonly);
        }
    }, [
        workspaceService
    ]);
    return useMemo(()=>({
            setDocTitle,
            setDocMeta,
            getDocMeta,
            setDocReadonly
        }), [
        getDocMeta,
        setDocMeta,
        setDocReadonly,
        setDocTitle
    ]);
}
