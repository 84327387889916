import { searchSubscriptionsQuery } from '@affine/graphql';
import { Entity, LiveData } from '@toeverything/infra';
export class FeedSearchModal extends Entity {
    constructor(graphQLService){
        super(), this.graphQLService = graphQLService, this.state$ = new LiveData(null), this.show$ = this.state$.map((s)=>!!s), this.show = (currentFolder, opts = {})=>{
            if (this.state$.value?.callback) {
                this.state$.value.callback(null);
            }
            this.state$.next({
                query: opts.query ?? '',
                currentFolder: currentFolder,
                callback: opts.callback
            });
        }, this.query$ = this.state$.map((s)=>s?.query || ''), this.currentFolder$ = this.state$.map((s)=>s?.currentFolder), this.setQuery = (query)=>{
            if (!this.state$.value) return;
            this.state$.next({
                ...this.state$.value,
                query
            });
        };
    }
    hide() {
        return this.state$.next(null);
    }
    toggle() {
        return this.show$.value ? this.hide() : this.show();
    }
    search(query) {
        const { promise, resolve } = Promise.withResolvers();
        this.show(this.currentFolder$.value, {
            callback: resolve,
            query
        });
        return promise;
    }
    setSearchCallbackResult(result) {
        if (this.state$.value?.callback) {
            this.state$.value.callback(result);
        }
    }
    async searchFeeds(query) {
        if (!query) {
            return [];
        }
        const res = await this.graphQLService.exec({
            query: searchSubscriptionsQuery,
            variables: {
                keyword: query
            }
        });
        return res.searchSubscriptions || [];
    }
}
