import { FilterIcon } from '@blocksuite/icons/lit';
import { css, html, nothing } from 'lit';
import { emptyFilterGroup } from '../../../../core/common/ast.js';
import { popCreateFilter } from '../../../../core/common/ref/ref.js';
import { WidgetBase } from '../../../../core/widget/widget-base.js';
const styles = css`
  .affine-database-filter-button {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .affine-database-filter-button:hover {
    background-color: var(--affine-hover-color);
  }

  .affine-database-filter-button svg {
    width: 20px;
    height: 20px;
  }
`;
export class DataViewHeaderToolsFilter extends WidgetBase {
    static{
        this.styles = styles;
    }
    get _filter() {
        return this.view.filter$.value ?? emptyFilterGroup;
    }
    set _filter(filter) {
        this.view.filterSet(filter);
    }
    get readonly() {
        return this.view.readonly$.value;
    }
    addFilter(event) {
        this.showToolBar(true);
        popCreateFilter(event.target, {
            vars: this.view.vars$.value,
            onSelect: (filter)=>{
                this._filter = {
                    ...this._filter,
                    conditions: [
                        filter
                    ]
                };
            },
            onClose: ()=>{
                this.showToolBar(false);
            }
        });
        return;
    }
    render() {
        if (this.readonly) return nothing;
        return html`<div
      @click="${this.addFilter}"
      class="affine-database-filter-button dv-icon-20"
    >
      ${FilterIcon()} Filter
    </div>`;
    }
    showToolBar(show) {
        const tools = this.closest('data-view-header-tools');
        if (tools) {
            tools.showToolBar = show;
        }
    }
}
