import { SurfaceBlockSchema } from '@blocksuite/affine-block-surface';
import { AttachmentBlockSchema, BookmarkBlockSchema, CodeBlockSchema, DatabaseBlockSchema, DividerBlockSchema, EdgelessTextBlockSchema, EmbedFigmaBlockSchema, EmbedGithubBlockSchema, EmbedHtmlBlockSchema, EmbedLinkedDocBlockSchema, EmbedLoomBlockSchema, EmbedSyncedDocBlockSchema, EmbedYoutubeBlockSchema, FrameBlockSchema, ImageBlockSchema, LatexBlockSchema, ListBlockSchema, NoteBlockSchema, ParagraphBlockSchema, RootBlockSchema, SurfaceRefBlockSchema } from '@blocksuite/affine-model';
import { DataViewBlockSchema } from './data-view-block/data-view-model.js';
export const AffineSchemas = [
    CodeBlockSchema,
    ParagraphBlockSchema,
    RootBlockSchema,
    ListBlockSchema,
    NoteBlockSchema,
    DividerBlockSchema,
    ImageBlockSchema,
    SurfaceBlockSchema,
    BookmarkBlockSchema,
    FrameBlockSchema,
    DatabaseBlockSchema,
    SurfaceRefBlockSchema,
    DataViewBlockSchema,
    AttachmentBlockSchema,
    EmbedYoutubeBlockSchema,
    EmbedFigmaBlockSchema,
    EmbedGithubBlockSchema,
    EmbedHtmlBlockSchema,
    EmbedLinkedDocBlockSchema,
    EmbedSyncedDocBlockSchema,
    EmbedLoomBlockSchema,
    EdgelessTextBlockSchema,
    LatexBlockSchema
];
