import { createIdentifier } from '@blocksuite/global/di';
export const DragHandleConfigIdentifier = createIdentifier('AffineDragHandleIdentifier');
export function DragHandleConfigExtension(option) {
    return {
        setup: (di)=>{
            const key = typeof option.flavour === 'string' ? option.flavour : option.flavour.source;
            di.addImpl(DragHandleConfigIdentifier(key), ()=>option);
        }
    };
}
