export const createTagFilter = (id)=>{
    return {
        type: 'filter',
        left: {
            type: 'ref',
            name: 'Tags'
        },
        funcName: 'contains all',
        args: [
            {
                type: 'literal',
                value: [
                    id
                ]
            }
        ]
    };
};
