import { UaHelper } from './ua-helper.js';
export function setupGlobal() {
    if (globalThis.$AFFINE_SETUP) {
        return;
    }
    let environment;
    if (!globalThis.navigator) {
        environment = {
            isLinux: false,
            isMacOs: false,
            isSafari: false,
            isWindows: false,
            isFireFox: false,
            isChrome: false,
            isIOS: false,
            isPwa: false,
            isMobile: false
        };
    } else {
        const uaHelper = new UaHelper(globalThis.navigator);
        environment = {
            isMobile: uaHelper.isMobile,
            isLinux: uaHelper.isLinux,
            isMacOs: uaHelper.isMacOs,
            isSafari: uaHelper.isSafari,
            isWindows: uaHelper.isWindows,
            isFireFox: uaHelper.isFireFox,
            isChrome: uaHelper.isChrome,
            isIOS: uaHelper.isIOS,
            isPwa: uaHelper.isStandalone
        };
        if (environment.isChrome && !environment.isIOS) {
            environment = {
                ...environment,
                isSafari: false,
                isFireFox: false,
                isChrome: true,
                chromeVersion: uaHelper.getChromeVersion()
            };
        }
    }
    globalThis.environment = environment;
    globalThis.$AFFINE_SETUP = true;
}
