import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ConfirmModal } from '@affine/component';
import { WorkspacePropertiesAdapter } from '@affine/core/modules/properties';
import { Trans, useI18n } from '@affine/i18n';
import { useService } from '@toeverything/infra';
import { useMemo } from 'react';
import { PagePropertiesMetaManager } from './page-properties-manager';
export const ConfirmDeletePropertyModal = ({ onConfirm, onCancel, property, show })=>{
    const t = useI18n();
    const adapter = useService(WorkspacePropertiesAdapter);
    const count = useMemo(()=>{
        const manager = new PagePropertiesMetaManager(adapter);
        return manager.getPropertyRelatedPages(property.id)?.size || 0;
    }, [
        adapter,
        property.id
    ]);
    return _jsx(ConfirmModal, {
        open: show,
        closeButtonOptions: {
            onClick: onCancel
        },
        title: t['com.affine.settings.workspace.properties.delete-property'](),
        description: _jsxs(Trans, {
            values: {
                name: property.name,
                count
            },
            i18nKey: "com.affine.settings.workspace.properties.delete-property-prompt",
            children: [
                "The ",
                _jsx("strong", {
                    children: {
                        name: property.name
                    }
                }),
                " property will be removed from count doc(s). This action cannot be undone."
            ]
        }),
        confirmText: t['Confirm'](),
        onConfirm: onConfirm,
        cancelButtonOptions: {
            onClick: onCancel
        },
        confirmButtonOptions: {
            variant: 'error'
        }
    });
};
