import { getMembersByWorkspaceIdQuery } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class WorkspaceMembersStore extends Store {
    constructor(graphqlService){
        super(), this.graphqlService = graphqlService;
    }
    async fetchMembers(workspaceId, skip, take, signal) {
        const data = await this.graphqlService.gql({
            query: getMembersByWorkspaceIdQuery,
            variables: {
                workspaceId,
                skip,
                take
            },
            context: {
                signal
            }
        });
        return data.workspace;
    }
}
