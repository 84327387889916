import { useMemo } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
export const isI18nString = (value)=>{
    return typeof value === 'string' || typeof value === 'object' && 'key' in value;
};
function createI18nWrapper(getI18nFn, getI18nT) {
    const I18nMethod = {
        t (i18nStr) {
            const i18n = getI18nFn();
            if (typeof i18nStr === 'object') {
                return i18n.t(i18nStr.key, 'options' in i18nStr ? i18nStr.options : {});
            }
            return i18nStr;
        },
        get language () {
            const i18n = getI18nFn();
            return i18n.language;
        },
        changeLanguage (lng) {
            const i18n = getI18nFn();
            return i18n.changeLanguage(lng);
        },
        get on () {
            const i18n = getI18nFn();
            return i18n.on.bind(i18n);
        }
    };
    return new Proxy(I18nMethod, {
        get (self, key) {
            const i18n = getI18nFn();
            if (typeof key === 'string' && i18n.exists(key)) {
                return getI18nT().bind(null, key);
            } else {
                return self[key];
            }
        }
    });
}
export const useI18n = ()=>{
    const { i18n, t } = useTranslation();
    return useMemo(()=>createI18nWrapper(()=>i18n, ()=>t), [
        i18n,
        t
    ]);
};
export const I18n = createI18nWrapper(getI18n, ()=>getI18n().t);
