import { ErrorNames, UserFriendlyError } from '@affine/graphql';
import { Store } from '@toeverything/infra';
import { isBackendError } from '../../cloud';
export class ShareReaderStore extends Store {
    constructor(fetchService){
        super(), this.fetchService = fetchService;
    }
    async loadShare(workspaceId, docId) {
        try {
            const docResponse = await this.fetchService.fetch(`/api/workspaces/${workspaceId}/docs/${docId}`);
            const publishMode = docResponse.headers.get('publish-mode');
            const docBinary = await docResponse.arrayBuffer();
            const workspaceResponse = await this.fetchService.fetch(`/api/workspaces/${workspaceId}/docs/${workspaceId}`);
            const workspaceBinary = await workspaceResponse.arrayBuffer();
            return {
                doc: new Uint8Array(docBinary),
                workspace: new Uint8Array(workspaceBinary),
                publishMode
            };
        } catch (error) {
            if (error instanceof Error && isBackendError(error) && UserFriendlyError.fromAnyError(error).name === ErrorNames.ACCESS_DENIED) {
                return null;
            }
            throw error;
        }
    }
}
