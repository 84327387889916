function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _init_edgeless, _init_element, _init_richText, _initProto;
import { CommonUtils, TextUtils } from '@blocksuite/affine-block-surface';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { RANGE_SYNC_EXCLUDE_ATTR, ShadowlessElement } from '@blocksuite/block-std';
import { assertExists, Bound, Vec, WithDisposable } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { deleteElements } from '../../utils/crud.js';
import { getSelectedRect } from '../../utils/query.js';
const { toRadian } = CommonUtils;
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = query('rich-text');
export class EdgelessTextEditor extends WithDisposable(ShadowlessElement) {
    static{
        ({ e: [_init_edgeless, _init_element, _init_richText, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "edgeless"
            ],
            [
                _dec1,
                1,
                "element"
            ],
            [
                _dec2,
                1,
                "richText"
            ]
        ], []));
    }
    static{
        this.BORDER_WIDTH = 1;
    }
    static{
        this.PADDING_HORIZONTAL = 10;
    }
    static{
        this.PADDING_VERTICAL = 6;
    }
    static{
        this.PLACEHOLDER_TEXT = 'Type from here';
    }
    static{
        this.styles = css`
    .edgeless-text-editor {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      transform-origin: left top;
      font-kerning: none;
      border: ${EdgelessTextEditor.BORDER_WIDTH}px solid
        var(--affine-primary-color, #1e96eb);
      border-radius: 4px;
      box-shadow: 0px 0px 0px 2px rgba(30, 150, 235, 0.3);
      padding: ${EdgelessTextEditor.PADDING_VERTICAL}px
        ${EdgelessTextEditor.PADDING_HORIZONTAL}px;
      overflow: visible;
    }

    .edgeless-text-editor .inline-editor {
      white-space: pre-wrap !important;
      outline: none;
    }

    .edgeless-text-editor .inline-editor span {
      word-break: normal !important;
      overflow-wrap: anywhere !important;
    }

    .edgeless-text-editor-placeholder {
      pointer-events: none;
      color: var(--affine-text-disable-color);
      white-space: nowrap;
    }
  `;
    }
    get inlineEditor() {
        assertExists(this.richText.inlineEditor);
        return this.richText.inlineEditor;
    }
    get inlineEditorContainer() {
        return this.inlineEditor.rootElement;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.edgeless) {
            console.error('edgeless is not set.');
            return;
        }
        if (!this.element) {
            console.error('text element is not set.');
            return;
        }
        this.setAttribute(RANGE_SYNC_EXCLUDE_ATTR, 'true');
    }
    firstUpdated() {
        const edgeless = this.edgeless;
        const element = this.element;
        const { dispatcher } = this.edgeless;
        assertExists(dispatcher);
        this.updateComplete.then(()=>{
            this.inlineEditor.slots.renderComplete.on(()=>{
                this._updateRect();
                this.requestUpdate();
            });
            this.disposables.add(edgeless.service.surface.elementUpdated.on(({ id })=>{
                if (id === element.id) this.requestUpdate();
            }));
            this.disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>{
                this.requestUpdate();
            }));
            this.disposables.add(dispatcher.add('click', ()=>true));
            this.disposables.add(dispatcher.add('doubleClick', ()=>true));
            this.disposables.add(()=>{
                element.display = true;
                if (element.text.length === 0) {
                    deleteElements(edgeless, [
                        element
                    ]);
                }
                edgeless.service.selection.set({
                    elements: [],
                    editing: false
                });
            });
            this.disposables.addFromEvent(this.inlineEditorContainer, 'blur', ()=>!this._keeping && this.remove());
            this.disposables.addFromEvent(this.inlineEditorContainer, 'compositionstart', ()=>{
                this._isComposition = true;
                this.requestUpdate();
            });
            this.disposables.addFromEvent(this.inlineEditorContainer, 'compositionend', ()=>{
                this._isComposition = false;
                this.requestUpdate();
            });
            element.display = false;
        }).catch(console.error);
    }
    getContainerOffset() {
        const { PADDING_VERTICAL, PADDING_HORIZONTAL, BORDER_WIDTH } = EdgelessTextEditor;
        return `-${PADDING_HORIZONTAL + BORDER_WIDTH}px, -${PADDING_VERTICAL + BORDER_WIDTH}px`;
    }
    getCoordsOnCenterAlign(rect, w1, h1) {
        const centerX = rect.x + rect.w / 2;
        const centerY = rect.y + rect.h / 2;
        let deltaXPrime = 0;
        let deltaYPrime = -rect.h / 2 * Math.cos(rect.r);
        const vX = centerX + deltaXPrime;
        const vY = centerY + deltaYPrime;
        deltaXPrime = 0;
        deltaYPrime = -h1 / 2 * Math.cos(rect.r);
        const newCenterX = vX - deltaXPrime;
        const newCenterY = vY - deltaYPrime;
        return {
            x: newCenterX - w1 / 2,
            y: newCenterY - h1 / 2
        };
    }
    getCoordsOnLeftAlign(rect, w1, h1) {
        const cX = rect.x + rect.w / 2;
        const cY = rect.y + rect.h / 2;
        let deltaXPrime = -rect.w / 2 * Math.cos(rect.r) + rect.h / 2 * Math.sin(rect.r);
        let deltaYPrime = -rect.w / 2 * Math.sin(rect.r) - rect.h / 2 * Math.cos(rect.r);
        const vX = cX + deltaXPrime;
        const vY = cY + deltaYPrime;
        deltaXPrime = -w1 / 2 * Math.cos(rect.r) + h1 / 2 * Math.sin(rect.r);
        deltaYPrime = -w1 / 2 * Math.sin(rect.r) - h1 / 2 * Math.cos(rect.r);
        const newCenterX = vX - deltaXPrime;
        const newCenterY = vY - deltaYPrime;
        return {
            x: newCenterX - w1 / 2,
            y: newCenterY - h1 / 2
        };
    }
    getCoordsOnRightAlign(rect, w1, h1) {
        const centerX = rect.x + rect.w / 2;
        const centerY = rect.y + rect.h / 2;
        let deltaXPrime = rect.w / 2 * Math.cos(rect.r) - -rect.h / 2 * Math.sin(rect.r);
        let deltaYPrime = rect.w / 2 * Math.sin(rect.r) + -rect.h / 2 * Math.cos(rect.r);
        const vX = centerX + deltaXPrime;
        const vY = centerY + deltaYPrime;
        deltaXPrime = w1 / 2 * Math.cos(rect.r) - -h1 / 2 * Math.sin(rect.r);
        deltaYPrime = w1 / 2 * Math.sin(rect.r) + -h1 / 2 * Math.cos(rect.r);
        const newCenterX = vX - deltaXPrime;
        const newCenterY = vY - deltaYPrime;
        return {
            x: newCenterX - w1 / 2,
            y: newCenterY - h1 / 2
        };
    }
    async getUpdateComplete() {
        const result = await super.getUpdateComplete();
        await this.richText?.updateComplete;
        return result;
    }
    getVisualPosition(element) {
        const { x, y, w, h, rotate } = element;
        return Vec.rotWith([
            x,
            y
        ], [
            x + w / 2,
            y + h / 2
        ], toRadian(rotate));
    }
    render() {
        const { text, fontFamily, fontSize, fontWeight, fontStyle, textAlign, rotate, hasMaxWidth, w } = this.element;
        const lineHeight = TextUtils.getLineHeight(fontFamily, fontSize, fontWeight);
        const rect = getSelectedRect([
            this.element
        ]);
        const { translateX, translateY, zoom } = this.edgeless.service.viewport;
        const [visualX, visualY] = this.getVisualPosition(this.element);
        const containerOffset = this.getContainerOffset();
        const transformOperation = [
            `translate(${translateX}px, ${translateY}px)`,
            `translate(${visualX * zoom}px, ${visualY * zoom}px)`,
            `scale(${zoom})`,
            `rotate(${rotate}deg)`,
            `translate(${containerOffset})`
        ];
        const isEmpty = !text.length && !this._isComposition;
        const color = ThemeObserver.generateColorProperty(this.element.color, '#000000');
        return html`<div
      style=${styleMap({
            transform: transformOperation.join(' '),
            minWidth: hasMaxWidth ? `${rect.width}px` : 'none',
            maxWidth: hasMaxWidth ? `${w}px` : 'none',
            fontFamily: TextUtils.wrapFontFamily(fontFamily),
            fontSize: `${fontSize}px`,
            fontWeight,
            fontStyle,
            color,
            textAlign,
            lineHeight: `${lineHeight}px`,
            boxSizing: 'content-box'
        })}
      class="edgeless-text-editor"
    >
      <rich-text
        .yText=${text}
        .enableFormat=${false}
        .enableAutoScrollHorizontally=${false}
        style=${isEmpty ? styleMap({
            position: 'absolute',
            left: 0,
            top: 0,
            padding: `${EdgelessTextEditor.PADDING_VERTICAL}px
        ${EdgelessTextEditor.PADDING_HORIZONTAL}px`
        }) : nothing}
      ></rich-text>
      ${isEmpty ? html`<span class="edgeless-text-editor-placeholder">
            Type from here
          </span>` : nothing}
    </div>`;
    }
    setKeeping(keeping) {
        this._keeping = keeping;
    }
    #___private_edgeless_1;
    get edgeless() {
        return this.#___private_edgeless_1;
    }
    set edgeless(_v) {
        this.#___private_edgeless_1 = _v;
    }
    #___private_element_2;
    get element() {
        return this.#___private_element_2;
    }
    set element(_v) {
        this.#___private_element_2 = _v;
    }
    #___private_richText_3;
    get richText() {
        return this.#___private_richText_3;
    }
    set richText(_v) {
        this.#___private_richText_3 = _v;
    }
    constructor(...args){
        super(...args), this._isComposition = false, this._keeping = false, this._updateRect = ()=>{
            const edgeless = this.edgeless;
            const element = this.element;
            if (!edgeless || !element) return;
            const newWidth = this.inlineEditorContainer.scrollWidth;
            const newHeight = this.inlineEditorContainer.scrollHeight;
            const bound = new Bound(element.x, element.y, newWidth, newHeight);
            const { x, y, w, h, rotate } = element;
            switch(element.textAlign){
                case 'left':
                    {
                        const newPos = this.getCoordsOnLeftAlign({
                            x,
                            y,
                            w,
                            h,
                            r: toRadian(rotate)
                        }, newWidth, newHeight);
                        bound.x = newPos.x;
                        bound.y = newPos.y;
                    }
                    break;
                case 'center':
                    {
                        const newPos = this.getCoordsOnCenterAlign({
                            x,
                            y,
                            w,
                            h,
                            r: toRadian(rotate)
                        }, newWidth, newHeight);
                        bound.x = newPos.x;
                        bound.y = newPos.y;
                    }
                    break;
                case 'right':
                    {
                        const newPos = this.getCoordsOnRightAlign({
                            x,
                            y,
                            w,
                            h,
                            r: toRadian(rotate)
                        }, newWidth, newHeight);
                        bound.x = newPos.x;
                        bound.y = newPos.y;
                    }
                    break;
            }
            edgeless.service.updateElement(element.id, {
                xywh: bound.serialize()
            });
        }, this.#___private_edgeless_1 = (_initProto(this), _init_edgeless(this)), this.#___private_element_2 = _init_element(this), this.#___private_richText_3 = _init_richText(this);
    }
}
