import { jsx as _jsx } from "react/jsx-runtime";
import { notify } from '@affine/component';
import { AuthModal as AuthModalBase } from '@affine/component/auth-components';
import { authAtom } from '@affine/core/components/atoms';
import { AuthService } from '@affine/core/modules/cloud';
import { useI18n } from '@affine/i18n';
import { useLiveData, useService } from '@toeverything/infra';
import { useAtom } from 'jotai/react';
import { useCallback, useEffect, useRef } from 'react';
import { AfterSignInSendEmail } from './after-sign-in-send-email';
import { AfterSignUpSendEmail } from './after-sign-up-send-email';
import { SendEmail } from './send-email';
import { SignIn } from './sign-in';
import { SignInWithPassword } from './sign-in-with-password';
const config = {
    signIn: SignIn,
    afterSignUpSendEmail: AfterSignUpSendEmail,
    afterSignInSendEmail: AfterSignInSendEmail,
    signInWithPassword: SignInWithPassword,
    sendEmail: SendEmail
};
export function AuthModal() {
    const [authAtomValue, setAuthAtom] = useAtom(authAtom);
    const setOpen = useCallback((open)=>{
        setAuthAtom((prev)=>({
                ...prev,
                openModal: open
            }));
    }, [
        setAuthAtom
    ]);
    return _jsx(AuthModalBase, {
        open: authAtomValue.openModal,
        setOpen: setOpen,
        children: _jsx(AuthPanel, {})
    });
}
export function AuthPanel({ onSkip }) {
    const t = useI18n();
    const [authAtomValue, setAuthAtom] = useAtom(authAtom);
    const authService = useService(AuthService);
    const loginStatus = useLiveData(authService.session.status$);
    const previousLoginStatus = useRef(loginStatus);
    const setAuthData = useCallback((updates)=>{
        setAuthAtom((prev)=>({
                ...prev,
                ...updates
            }));
    }, [
        setAuthAtom
    ]);
    useEffect(()=>{
        if (loginStatus === 'authenticated' && previousLoginStatus.current === 'unauthenticated') {
            setAuthAtom({
                openModal: false,
                state: 'signIn'
            });
            notify.success({
                title: t['com.affine.auth.toast.title.signed-in'](),
                message: t['com.affine.auth.toast.message.signed-in']()
            });
        }
        previousLoginStatus.current = loginStatus;
    }, [
        loginStatus,
        setAuthAtom,
        t
    ]);
    const CurrentPanel = config[authAtomValue.state];
    const props = {
        ...authAtomValue,
        onSkip,
        setAuthData
    };
    return _jsx(CurrentPanel, {
        ...props
    });
}
