import { BlockComponent } from '@blocksuite/block-std';
import { html, nothing } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { styleMap } from 'lit/directives/style-map.js';
export class CaptionedBlockComponent extends BlockComponent {
    get captionEditor() {
        if (!this.useCaptionEditor || !this._captionEditorRef.value) {
            console.error('Oops! Please enable useCaptionEditor before accessing captionEditor');
        }
        return this._captionEditorRef.value;
    }
    constructor(){
        super(), this.#___private__captionEditorRef_1 = createRef(), this.#___private_blockContainerStyles_2 = undefined, this.#___private_showBlockSelection_3 = true, this.#___private_useCaptionEditor_4 = false, this.#___private_useZeroWidth_5 = false;
        this.addRenderer(this._renderWithWidget);
    }
    _renderWithWidget(content) {
        const style = styleMap({
            position: 'relative',
            ...this.blockContainerStyles
        });
        return html`<div style=${style} class="affine-block-component">
      ${content}
      ${this.useCaptionEditor ? html`<block-caption-editor
            ${ref(this._captionEditorRef)}
          ></block-caption-editor>` : nothing}
      ${this.showBlockSelection ? html`<affine-block-selection .block=${this}></affine-block-selection>` : nothing}
      ${this.useZeroWidth ? html`<block-zero-width .block=${this}></block-zero-width>` : nothing}
    </div>`;
    }
    #___private__captionEditorRef_1;
    get _captionEditorRef() {
        return this.#___private__captionEditorRef_1;
    }
    set _captionEditorRef(_v) {
        this.#___private__captionEditorRef_1 = _v;
    }
    #___private_blockContainerStyles_2;
    get blockContainerStyles() {
        return this.#___private_blockContainerStyles_2;
    }
    set blockContainerStyles(_v) {
        this.#___private_blockContainerStyles_2 = _v;
    }
    #___private_showBlockSelection_3;
    get showBlockSelection() {
        return this.#___private_showBlockSelection_3;
    }
    set showBlockSelection(_v) {
        this.#___private_showBlockSelection_3 = _v;
    }
    #___private_useCaptionEditor_4;
    get useCaptionEditor() {
        return this.#___private_useCaptionEditor_4;
    }
    set useCaptionEditor(_v) {
        this.#___private_useCaptionEditor_4 = _v;
    }
    #___private_useZeroWidth_5;
    get useZeroWidth() {
        return this.#___private_useZeroWidth_5;
    }
    set useZeroWidth(_v) {
        this.#___private_useZeroWidth_5 = _v;
    }
}
