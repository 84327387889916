import { ZipTransformer } from '@blocksuite/affine/blocks';
import { DocsService, Service } from '@toeverything/infra';
export class ImportTemplateService extends Service {
    constructor(workspacesService){
        super(), this.workspacesService = workspacesService;
    }
    async importToWorkspace(workspaceMetadata, docBinary, mode) {
        const { workspace, dispose: disposeWorkspace } = this.workspacesService.open({
            metadata: workspaceMetadata
        });
        await workspace.engine.waitForRootDocReady();
        const [importedDoc] = await ZipTransformer.importDocs(workspace.docCollection, new Blob([
            docBinary
        ], {
            type: 'application/zip'
        }));
        const docsService = workspace.scope.get(DocsService);
        if (importedDoc) {
            docsService.list.setPrimaryMode(importedDoc.id, mode);
            disposeWorkspace();
            return importedDoc.id;
        } else {
            throw new Error('Failed to import doc');
        }
    }
    async importToNewWorkspace(flavour, workspaceName, docBinary) {
        let docId = null;
        const { id: workspaceId } = await this.workspacesService.create(flavour, async (docCollection, _, docStorage)=>{
            docCollection.meta.initialize();
            docCollection.meta.setName(workspaceName);
            const doc = docCollection.createDoc();
            docId = doc.id;
            await docStorage.doc.set(doc.spaceDoc.guid, docBinary);
        });
        return {
            workspaceId,
            docId
        };
    }
}
