import { BLOCK_ID_ATTR } from '@blocksuite/block-std';
import { BLOCK_CHILDREN_CONTAINER_PADDING_LEFT } from '../../consts/index.js';
import { clamp } from '../math.js';
import { matchFlavours } from '../model/checker.js';
const ATTR_SELECTOR = `[${BLOCK_ID_ATTR}]`;
const MAX_SPACE = 32;
const STEPS = MAX_SPACE / 2 / 2;
function contains(parent, node) {
    return parent.compareDocumentPosition(node) & Node.DOCUMENT_POSITION_CONTAINED_BY;
}
function hasBlockId(element) {
    return element.hasAttribute(BLOCK_ID_ATTR);
}
function isRootOrNoteOrSurface(element) {
    return matchFlavours(element.model, [
        'affine:page',
        'affine:note',
        'affine:surface'
    ]);
}
function isBlock(element) {
    return !isRootOrNoteOrSurface(element);
}
function isImage({ tagName }) {
    return tagName === 'AFFINE-IMAGE';
}
function isDatabase({ tagName }) {
    return tagName === 'AFFINE-DATABASE-TABLE' || tagName === 'AFFINE-DATABASE';
}
export function getClosestBlockComponentByPoint(point, state = null, scale = 1) {
    const { y } = point;
    let container;
    let element = null;
    let bounds = null;
    let childBounds = null;
    let diff = 0;
    let n = 1;
    if (state) {
        const { snapToEdge = {
            x: true,
            y: false
        } } = state;
        container = state.container;
        const rect = state.rect || container?.getBoundingClientRect();
        if (rect) {
            if (snapToEdge.x) {
                point.x = Math.min(Math.max(point.x, rect.left) + BLOCK_CHILDREN_CONTAINER_PADDING_LEFT * scale - 1, rect.right - BLOCK_CHILDREN_CONTAINER_PADDING_LEFT * scale - 1);
            }
            if (snapToEdge.y) {
                if (scale !== 1) {
                    console.warn('scale is not supported yet');
                }
                point.y = clamp(point.y, rect.top + 1, rect.bottom - 1);
            }
        }
    }
    element = findBlockComponent(document.elementsFromPoint(point.x, point.y), container);
    if (element) {
        if (isDatabase(element)) {
            bounds = element.getBoundingClientRect();
            const rows = getDatabaseBlockRowsElement(element);
            if (rows) {
                childBounds = rows.getBoundingClientRect();
                if (childBounds.height) {
                    if (point.y < childBounds.top || point.y > childBounds.bottom) {
                        return element;
                    }
                    childBounds = null;
                } else {
                    return element;
                }
            }
        } else {
            bounds = getRectByBlockComponent(element);
            childBounds = element.querySelector('.affine-block-children-container')?.firstElementChild?.getBoundingClientRect();
            if (childBounds && childBounds.height) {
                if (bounds.x < point.x && point.x <= childBounds.x) {
                    return element;
                }
                childBounds = null;
            } else {
                return element;
            }
        }
        bounds = null;
        element = null;
    }
    do {
        point.y = y - n * 2;
        if (n < 0) n--;
        n *= -1;
        element = findBlockComponent(document.elementsFromPoint(point.x, point.y), container);
        if (element) {
            bounds = getRectByBlockComponent(element);
            diff = bounds.bottom - point.y;
            if (diff >= 0 && diff <= STEPS * 2) {
                return element;
            }
            diff = point.y - bounds.top;
            if (diff >= 0 && diff <= STEPS * 2) {
                return element;
            }
            bounds = null;
            element = null;
        }
    }while (n <= STEPS);
    return element;
}
export function findClosestBlockComponent(container, point, selector) {
    const children = Array.from(container.querySelectorAll(selector)).filter((child)=>child.host === container.host).filter((child)=>child !== container);
    let lastDistance = Number.POSITIVE_INFINITY;
    let lastChild = null;
    if (!children.length) return null;
    for (const child of children){
        const rect = child.getBoundingClientRect();
        if (rect.height === 0 || point.y > rect.bottom || point.y < rect.top) continue;
        const distance = Math.pow(point.y - (rect.y + rect.height / 2), 2) + Math.pow(point.x - rect.x, 2);
        if (distance <= lastDistance) {
            lastDistance = distance;
            lastChild = child;
        } else {
            return lastChild;
        }
    }
    return lastChild;
}
export function getClosestBlockComponentByElement(element) {
    if (!element) return null;
    if (hasBlockId(element) && isBlock(element)) {
        return element;
    }
    const blockComponent = element.closest(ATTR_SELECTOR);
    if (blockComponent && isBlock(blockComponent)) {
        return blockComponent;
    }
    return null;
}
export function getRectByBlockComponent(element) {
    if (isDatabase(element)) return element.getBoundingClientRect();
    return (element.firstElementChild ?? element).getBoundingClientRect();
}
export function getBlockComponentsExcludeSubtrees(elements) {
    if (elements.length <= 1) return elements;
    let parent = elements[0];
    return elements.filter((node, index)=>{
        if (index === 0) return true;
        if (contains(parent, node)) {
            return false;
        } else {
            parent = node;
            return true;
        }
    });
}
function findBlockComponent(elements, parent) {
    const len = elements.length;
    let element = null;
    let i = 0;
    while(i < len){
        element = elements[i];
        i++;
        if (parent && !contains(parent, element)) continue;
        if (hasBlockId(element) && isBlock(element)) return element;
        if (isImage(element)) {
            const element = elements[i];
            if (i < len && hasBlockId(element) && isBlock(element)) {
                return elements[i];
            }
            return getClosestBlockComponentByElement(element);
        }
    }
    return null;
}
function getDatabaseBlockRowsElement(element) {
    return element.querySelector('.affine-database-block-rows');
}
