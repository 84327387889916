function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init_backgroundButton, _init_edgeless, _init_enableNoteSlicer, _init_notes, _init_quickConnectButton, _initProto;
import { ExpandIcon, LineStyleIcon, NoteCornerIcon, NoteShadowIcon, ScissorsIcon, ShrinkIcon, SmallArrowDownIcon } from '@blocksuite/affine-components/icons';
import { renderToolbarSeparator } from '@blocksuite/affine-components/toolbar';
import { DEFAULT_NOTE_BACKGROUND_COLOR, NOTE_BACKGROUND_COLORS, NoteDisplayMode } from '@blocksuite/affine-model';
import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { assertExists, Bound, countBy, maxBy, WithDisposable } from '@blocksuite/global/utils';
import { html, LitElement, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { join } from 'lit/directives/join.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { when } from 'lit/directives/when.js';
import { packColor, packColorsWithColorScheme } from '../../edgeless/components/color-picker/utils.js';
import { LineStylesPanel } from '../../edgeless/components/panel/line-styles-panel.js';
import { getTooltipWithShortcut } from '../../edgeless/components/utils.js';
const SIZE_LIST = [
    {
        name: 'None',
        value: 0
    },
    {
        name: 'Small',
        value: 8
    },
    {
        name: 'Medium',
        value: 16
    },
    {
        name: 'Large',
        value: 24
    },
    {
        name: 'Huge',
        value: 32
    }
];
const DisplayModeMap = {
    [NoteDisplayMode.DocAndEdgeless]: 'Both',
    [NoteDisplayMode.EdgelessOnly]: 'Edgeless',
    [NoteDisplayMode.DocOnly]: 'Page'
};
function getMostCommonBackground(elements, colorScheme) {
    const colors = countBy(elements, (ele)=>{
        return typeof ele.background === 'object' ? ele.background[colorScheme] ?? ele.background.normal ?? null : ele.background;
    });
    const max = maxBy(Object.entries(colors), ([_k, count])=>count);
    return max ? max[0] : null;
}
_dec = query('edgeless-color-picker-button.background'), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
}), _dec4 = property({
    attribute: false
});
export class EdgelessChangeNoteButton extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_backgroundButton, _init_edgeless, _init_enableNoteSlicer, _init_notes, _init_quickConnectButton, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "backgroundButton"
            ],
            [
                _dec1,
                1,
                "edgeless"
            ],
            [
                _dec2,
                1,
                "enableNoteSlicer"
            ],
            [
                _dec3,
                1,
                "notes"
            ],
            [
                _dec4,
                1,
                "quickConnectButton"
            ]
        ], []));
    }
    get _advancedVisibilityEnabled() {
        return this.doc.awarenessStore.getFlag('enable_advanced_block_visibility');
    }
    get doc() {
        return this.edgeless.doc;
    }
    _getScaleLabel(scale) {
        return Math.round(scale * 100) + '%';
    }
    _handleNoteSlicerButtonClick() {
        const surfaceService = this.edgeless.service;
        if (!surfaceService) return;
        this.edgeless.slots.toggleNoteSlicer.emit();
    }
    _setBackground(background) {
        this.notes.forEach((element)=>{
            this.edgeless.service.updateElement(element.id, {
                background
            });
        });
    }
    _setCollapse() {
        this.notes.forEach((note)=>{
            const { collapse, collapsedHeight } = note.edgeless;
            if (collapse) {
                this.doc.updateBlock(note, ()=>{
                    note.edgeless.collapse = false;
                });
            } else if (collapsedHeight) {
                const { xywh, edgeless } = note;
                const bound = Bound.deserialize(xywh);
                bound.h = collapsedHeight * (edgeless.scale ?? 1);
                this.doc.updateBlock(note, ()=>{
                    note.edgeless.collapse = true;
                    note.xywh = bound.serialize();
                });
            }
        });
        this.requestUpdate();
    }
    _setDisplayMode(note, newMode) {
        const { displayMode: currentMode } = note;
        if (newMode === currentMode) {
            return;
        }
        this.edgeless.service.updateElement(note.id, {
            displayMode: newMode
        });
        const noteParent = this.doc.getParent(note);
        assertExists(noteParent);
        const noteParentChildNotes = noteParent.children.filter((block)=>matchFlavours(block, [
                'affine:note'
            ]));
        const noteParentLastNote = noteParentChildNotes[noteParentChildNotes.length - 1];
        if (currentMode === NoteDisplayMode.EdgelessOnly && newMode !== NoteDisplayMode.EdgelessOnly && note !== noteParentLastNote) {
            this.doc.moveBlocks([
                note
            ], noteParent, noteParentLastNote, false);
        }
        if (newMode === NoteDisplayMode.DocOnly) {
            this.edgeless.service.selection.clear();
        }
    }
    _setShadowType(shadowType) {
        this.notes.forEach((note)=>{
            const props = {
                edgeless: {
                    style: {
                        ...note.edgeless.style,
                        shadowType
                    }
                }
            };
            this.edgeless.service.updateElement(note.id, props);
        });
    }
    _setStrokeStyle(borderStyle) {
        this.notes.forEach((note)=>{
            const props = {
                edgeless: {
                    style: {
                        ...note.edgeless.style,
                        borderStyle
                    }
                }
            };
            this.edgeless.service.updateElement(note.id, props);
        });
    }
    _setStrokeWidth(borderSize) {
        this.notes.forEach((note)=>{
            const props = {
                edgeless: {
                    style: {
                        ...note.edgeless.style,
                        borderSize
                    }
                }
            };
            this.edgeless.service.updateElement(note.id, props);
        });
    }
    _setStyles({ type, value }) {
        if (type === 'size') {
            this._setStrokeWidth(value);
            return;
        }
        if (type === 'lineStyle') {
            this._setStrokeStyle(value);
        }
    }
    render() {
        const len = this.notes.length;
        const note = this.notes[0];
        const { edgeless, displayMode } = note;
        const { shadowType, borderRadius, borderSize, borderStyle } = edgeless.style;
        const colorScheme = this.edgeless.surface.renderer.getColorScheme();
        const background = getMostCommonBackground(this.notes, colorScheme) ?? DEFAULT_NOTE_BACKGROUND_COLOR;
        const { collapse } = edgeless;
        const scale = edgeless.scale ?? 1;
        const currentMode = DisplayModeMap[displayMode];
        const onlyOne = len === 1;
        const isDocOnly = displayMode === NoteDisplayMode.DocOnly;
        const buttons = [
            onlyOne && this._advancedVisibilityEnabled ? html`
            <span class="display-mode-button-label">Show in</span>
            <editor-menu-button
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Mode"
                  .tooltip=${'Display mode'}
                  .justify=${'space-between'}
                  .labelHeight=${'20px'}
                >
                  <span class="label">${currentMode}</span>
                  ${SmallArrowDownIcon}
                </editor-icon-button>
              `}
            >
              <note-display-mode-panel
                .displayMode=${displayMode}
                .onSelect=${(newMode)=>this._setDisplayMode(note, newMode)}
              >
              </note-display-mode-panel>
            </editor-menu-button>
          ` : nothing,
            isDocOnly ? nothing : when(this.edgeless.doc.awarenessStore.getFlag('enable_color_picker'), ()=>{
                const { type, colors } = packColorsWithColorScheme(colorScheme, background, note.background);
                return html`
                <edgeless-color-picker-button
                  class="background"
                  .label=${'Background'}
                  .pick=${this.pickColor}
                  .color=${background}
                  .colorType=${type}
                  .colors=${colors}
                  .palettes=${NOTE_BACKGROUND_COLORS}
                >
                </edgeless-color-picker-button>
              `;
            }, ()=>html`
              <editor-menu-button
                .contentPadding=${'8px'}
                .button=${html`
                  <editor-icon-button
                    aria-label="Background"
                    .tooltip=${'Background'}
                  >
                    <edgeless-color-button
                      .color=${background}
                    ></edgeless-color-button>
                  </editor-icon-button>
                `}
              >
                <edgeless-color-panel
                  .value=${background}
                  .options=${NOTE_BACKGROUND_COLORS}
                  @select=${(e)=>this._setBackground(e.detail)}
                >
                </edgeless-color-panel>
              </editor-menu-button>
            `),
            isDocOnly ? nothing : html`
            <editor-menu-button
              .contentPadding=${'6px'}
              .button=${html`
                <editor-icon-button
                  aria-label="Shadow style"
                  .tooltip=${'Shadow style'}
                >
                  ${NoteShadowIcon}${SmallArrowDownIcon}
                </editor-icon-button>
              `}
            >
              <edgeless-note-shadow-panel
                .value=${shadowType}
                .background=${background}
                .onSelect=${(value)=>this._setShadowType(value)}
              >
              </edgeless-note-shadow-panel>
            </editor-menu-button>

            <editor-menu-button
              .button=${html`
                <editor-icon-button
                  aria-label="Border style"
                  .tooltip=${'Border style'}
                >
                  ${LineStyleIcon}${SmallArrowDownIcon}
                </editor-icon-button>
              `}
            >
              <div data-orientation="horizontal">
                ${LineStylesPanel({
                selectedLineSize: borderSize,
                selectedLineStyle: borderStyle,
                onClick: (event)=>this._setStyles(event)
            })}
              </div>
            </editor-menu-button>

            <editor-menu-button
              ${ref(this._cornersPanelRef)}
              .contentPadding=${'8px'}
              .button=${html`
                <editor-icon-button aria-label="Corners" .tooltip=${'Corners'}>
                  ${NoteCornerIcon}${SmallArrowDownIcon}
                </editor-icon-button>
              `}
            >
              <edgeless-size-panel
                .size=${borderRadius}
                .sizeList=${SIZE_LIST}
                .minSize=${0}
                .onSelect=${(size)=>this._setBorderRadius(size)}
                .onPopperCose=${()=>this._cornersPanelRef.value?.hide()}
              >
              </edgeless-size-panel>
            </editor-menu-button>
          `,
            onlyOne && this._advancedVisibilityEnabled ? html`
            <editor-icon-button
              aria-label="Slicer"
              .tooltip=${getTooltipWithShortcut('Cutting mode', '-')}
              .active=${this.enableNoteSlicer}
              @click=${()=>this._handleNoteSlicerButtonClick()}
            >
              ${ScissorsIcon}
            </editor-icon-button>
          ` : nothing,
            onlyOne ? this.quickConnectButton : nothing,
            html`
        <editor-icon-button
          aria-label="Size"
          .tooltip=${collapse ? 'Auto height' : 'Customized height'}
          @click=${()=>this._setCollapse()}
        >
          ${collapse ? ExpandIcon : ShrinkIcon}
        </editor-icon-button>

        <editor-menu-button
          ${ref(this._scalePanelRef)}
          .contentPadding=${'8px'}
          .button=${html`
            <editor-icon-button
              aria-label="Scale"
              .tooltip=${'Scale'}
              .justify=${'space-between'}
              .labelHeight=${'20px'}
              .iconContainerWidth=${'65px'}
            >
              <span class="label">${this._getScaleLabel(scale)}</span
              >${SmallArrowDownIcon}
            </editor-icon-button>
          `}
        >
          <edgeless-scale-panel
            .scale=${Math.round(scale * 100)}
            .onSelect=${(scale)=>this._setNoteScale(scale)}
            .onPopperCose=${()=>this._scalePanelRef.value?.hide()}
          ></edgeless-scale-panel>
        </editor-menu-button>
      `
        ];
        return join(buttons.filter((button)=>button !== nothing), renderToolbarSeparator);
    }
    #___private__cornersPanelRef_1;
    get _cornersPanelRef() {
        return this.#___private__cornersPanelRef_1;
    }
    set _cornersPanelRef(_v) {
        this.#___private__cornersPanelRef_1 = _v;
    }
    #___private__scalePanelRef_2;
    get _scalePanelRef() {
        return this.#___private__scalePanelRef_2;
    }
    set _scalePanelRef(_v) {
        this.#___private__scalePanelRef_2 = _v;
    }
    #___private_backgroundButton_3;
    get backgroundButton() {
        return this.#___private_backgroundButton_3;
    }
    set backgroundButton(_v) {
        this.#___private_backgroundButton_3 = _v;
    }
    #___private_edgeless_4;
    get edgeless() {
        return this.#___private_edgeless_4;
    }
    set edgeless(_v) {
        this.#___private_edgeless_4 = _v;
    }
    #___private_enableNoteSlicer_5;
    get enableNoteSlicer() {
        return this.#___private_enableNoteSlicer_5;
    }
    set enableNoteSlicer(_v) {
        this.#___private_enableNoteSlicer_5 = _v;
    }
    #___private_notes_6;
    get notes() {
        return this.#___private_notes_6;
    }
    set notes(_v) {
        this.#___private_notes_6 = _v;
    }
    #___private_quickConnectButton_7;
    get quickConnectButton() {
        return this.#___private_quickConnectButton_7;
    }
    set quickConnectButton(_v) {
        this.#___private_quickConnectButton_7 = _v;
    }
    constructor(...args){
        super(...args), this._setBorderRadius = (borderRadius)=>{
            this.notes.forEach((note)=>{
                const props = {
                    edgeless: {
                        style: {
                            ...note.edgeless.style,
                            borderRadius
                        }
                    }
                };
                this.edgeless.service.updateElement(note.id, props);
            });
        }, this._setNoteScale = (scale)=>{
            this.notes.forEach((note)=>{
                this.doc.updateBlock(note, ()=>{
                    const bound = Bound.deserialize(note.xywh);
                    const oldScale = note.edgeless.scale ?? 1;
                    const ratio = scale / oldScale;
                    bound.w *= ratio;
                    bound.h *= ratio;
                    const xywh = bound.serialize();
                    note.xywh = xywh;
                    note.edgeless.scale = scale;
                });
            });
        }, this.pickColor = (event)=>{
            if (event.type === 'pick') {
                this.notes.forEach((element)=>{
                    const props = packColor('background', {
                        ...event.detail
                    });
                    this.edgeless.service.updateElement(element.id, props);
                });
                return;
            }
            this.notes.forEach((ele)=>ele[event.type === 'start' ? 'stash' : 'pop']('background'));
        }, this.#___private__cornersPanelRef_1 = createRef(), this.#___private__scalePanelRef_2 = createRef(), this.#___private_backgroundButton_3 = (_initProto(this), _init_backgroundButton(this)), this.#___private_edgeless_4 = _init_edgeless(this), this.#___private_enableNoteSlicer_5 = _init_enableNoteSlicer(this), this.#___private_notes_6 = _init_notes(this, []), this.#___private_quickConnectButton_7 = _init_quickConnectButton(this);
    }
}
export function renderNoteButton(edgeless, notes, quickConnectButton) {
    if (!notes?.length) return nothing;
    return html`
    <edgeless-change-note-button
      .notes=${notes}
      .edgeless=${edgeless}
      .enableNoteSlicer=${false}
      .quickConnectButton=${quickConnectButton?.pop() ?? nothing}
    >
    </edgeless-change-note-button>
  `;
}
