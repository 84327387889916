function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _init_connector, _init_currentSource, _init_edgeless, _init_position, _initProto;
import { CanvasElementType, CommonUtils } from '@blocksuite/affine-block-surface';
import { FontFamilyIcon, FrameIcon, SmallNoteIcon } from '@blocksuite/affine-components/icons';
import { DEFAULT_NOTE_BACKGROUND_COLOR, DEFAULT_SHAPE_FILL_COLOR, DEFAULT_SHAPE_STROKE_COLOR, DEFAULT_TEXT_COLOR, FontFamily, FontStyle, FontWeight, getShapeName, GroupElementModel, ShapeStyle, TextElementModel } from '@blocksuite/affine-model';
import { EditPropsStore } from '@blocksuite/affine-shared/services';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { captureEventTarget } from '@blocksuite/affine-shared/utils';
import { assertInstanceOf, Bound, serializeXYWH, Vec, WithDisposable } from '@blocksuite/global/utils';
import { DocCollection } from '@blocksuite/store';
import { baseTheme } from '@toeverything/theme';
import { css, html, LitElement, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { DEFAULT_NOTE_WIDTH, SHAPE_OVERLAY_HEIGHT, SHAPE_OVERLAY_WIDTH } from '../../utils/consts.js';
import { mountShapeTextEditor, mountTextElementEditor } from '../../utils/text.js';
import { ShapeComponentConfig } from '../toolbar/shape/shape-menu-config.js';
import { AutoCompleteFrameOverlay, AutoCompleteNoteOverlay, AutoCompleteShapeOverlay, AutoCompleteTextOverlay, capitalizeFirstLetter, createShapeElement, DEFAULT_NOTE_OVERLAY_HEIGHT, DEFAULT_TEXT_HEIGHT, DEFAULT_TEXT_WIDTH, Direction, isShape, PANEL_HEIGHT, PANEL_WIDTH } from './utils.js';
_dec = property({
    attribute: false
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = property({
    attribute: false
});
export class EdgelessAutoCompletePanel extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_connector, _init_currentSource, _init_edgeless, _init_position, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "connector"
            ],
            [
                _dec1,
                1,
                "currentSource"
            ],
            [
                _dec2,
                1,
                "edgeless"
            ],
            [
                _dec3,
                1,
                "position"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .auto-complete-panel-container {
      position: absolute;
      display: flex;
      width: 136px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
      gap: 8px;
      border-radius: 8px;
      background: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-shadow-2);
      z-index: 1;
    }

    .row-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 28px;
      padding: 4px 0;
      text-align: center;
      border-radius: 8px;
      font-family: ${unsafeCSS(baseTheme.fontSansFamily)};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      border: 1px solid var(--affine-border-color, #e3e2e4);
      box-sizing: border-box;
    }
  `;
    }
    constructor(position, edgeless, currentSource, connector){
        super(), this._overlay = null, this.#___private_connector_1 = (_initProto(this), _init_connector(this)), this.#___private_currentSource_2 = _init_currentSource(this), this.#___private_edgeless_3 = _init_edgeless(this), this.#___private_position_4 = _init_position(this);
        this.position = position;
        this.edgeless = edgeless;
        this.currentSource = currentSource;
        this.connector = connector;
    }
    _addFrame() {
        const bound = this._generateTarget(this.connector)?.nextBound;
        if (!bound) return;
        const { h } = bound;
        const w = h / 0.75;
        const target = this._getTargetXYWH(w, h);
        if (!target) return;
        const { xywh, position } = target;
        const edgeless = this.edgeless;
        const { service, surfaceBlockModel } = edgeless;
        const frameIndex = service.frames.length + 1;
        const id = service.addBlock('affine:frame', {
            title: new DocCollection.Y.Text(`Frame ${frameIndex}`),
            xywh: serializeXYWH(...xywh)
        }, surfaceBlockModel);
        edgeless.doc.captureSync();
        const frame = service.getElementById(id);
        if (!frame) return;
        this.connector.target = {
            id,
            position
        };
        edgeless.service.selection.set({
            elements: [
                frame.id
            ],
            editing: false
        });
    }
    _addNote() {
        const { doc } = this.edgeless;
        const service = this.edgeless.service;
        const target = this._getTargetXYWH(DEFAULT_NOTE_WIDTH, DEFAULT_NOTE_OVERLAY_HEIGHT);
        if (!target) return;
        const { xywh, position } = target;
        const id = service.addBlock('affine:note', {
            xywh: serializeXYWH(...xywh)
        }, doc.root?.id);
        doc.addBlock('affine:paragraph', {
            type: 'text'
        }, id);
        const group = this.currentSource.group;
        if (group instanceof GroupElementModel) {
            group.addChild(id);
        }
        this.connector.target = {
            id,
            position: position
        };
        service.updateElement(this.connector.id, {
            target: {
                id,
                position
            }
        });
        this.edgeless.service.selection.set({
            elements: [
                id
            ],
            editing: false
        });
    }
    _addShape(targetType) {
        const edgeless = this.edgeless;
        const result = this._generateTarget(this.connector);
        if (!result) return;
        const currentSource = this.currentSource;
        const { nextBound, position } = result;
        const { service } = edgeless;
        const id = createShapeElement(edgeless, currentSource, targetType);
        service.updateElement(id, {
            xywh: nextBound.serialize()
        });
        service.updateElement(this.connector.id, {
            target: {
                id,
                position
            }
        });
        mountShapeTextEditor(service.getElementById(id), this.edgeless);
        edgeless.service.selection.set({
            elements: [
                id
            ],
            editing: true
        });
        edgeless.doc.captureSync();
    }
    _addText() {
        const target = this._getTargetXYWH(DEFAULT_TEXT_WIDTH, DEFAULT_TEXT_HEIGHT);
        if (!target) return;
        const { xywh, position } = target;
        const bound = Bound.fromXYWH(xywh);
        const edgelessService = this.edgeless.service;
        const textFlag = this.edgeless.doc.awarenessStore.getFlag('enable_edgeless_text');
        if (textFlag) {
            const { textId } = this.edgeless.std.command.exec('insertEdgelessText', {
                x: bound.x,
                y: bound.y
            });
            if (!textId) return;
            edgelessService.updateElement(this.connector.id, {
                target: {
                    id: textId,
                    position
                }
            });
            if (this.currentSource.group instanceof GroupElementModel) {
                this.currentSource.group.addChild(textId);
            }
            this.edgeless.service.selection.set({
                elements: [
                    textId
                ],
                editing: false
            });
            this.edgeless.doc.captureSync();
        } else {
            const textId = edgelessService.addElement(CanvasElementType.TEXT, {
                xywh: bound.serialize(),
                text: new DocCollection.Y.Text(),
                textAlign: 'left',
                fontSize: 24,
                fontFamily: FontFamily.Inter,
                color: DEFAULT_TEXT_COLOR,
                fontWeight: FontWeight.Regular,
                fontStyle: FontStyle.Normal
            });
            const textElement = edgelessService.getElementById(textId);
            assertInstanceOf(textElement, TextElementModel);
            edgelessService.updateElement(this.connector.id, {
                target: {
                    id: textId,
                    position
                }
            });
            if (this.currentSource.group instanceof GroupElementModel) {
                this.currentSource.group.addChild(textId);
            }
            this.edgeless.service.selection.set({
                elements: [
                    textId
                ],
                editing: false
            });
            this.edgeless.doc.captureSync();
            mountTextElementEditor(textElement, this.edgeless);
        }
    }
    _autoComplete(targetType) {
        this._removeOverlay();
        if (!this._connectorExist()) return;
        switch(targetType){
            case 'text':
                this._addText();
                break;
            case 'note':
                this._addNote();
                break;
            case 'frame':
                this._addFrame();
                break;
            default:
                this._addShape(targetType);
        }
        this.remove();
    }
    _connectorExist() {
        return !!this.edgeless.service.getElementById(this.connector.id);
    }
    _generateTarget(connector) {
        const { currentSource } = this;
        let w = SHAPE_OVERLAY_WIDTH;
        let h = SHAPE_OVERLAY_HEIGHT;
        if (isShape(currentSource)) {
            const bound = Bound.deserialize(currentSource.xywh);
            w = bound.w;
            h = bound.h;
        }
        const point = connector.target.position;
        if (!point) return;
        const len = connector.path.length;
        const angle = CommonUtils.normalizeDegAngle(CommonUtils.toDegree(Vec.angle(connector.path[len - 2], connector.path[len - 1])));
        let nextBound;
        let position;
        let direction;
        if (angle >= 45 && angle <= 135) {
            nextBound = new Bound(point[0] - w / 2, point[1], w, h);
            position = [
                0.5,
                0
            ];
            direction = Direction.Bottom;
        } else if (angle >= 135 && angle <= 225) {
            nextBound = new Bound(point[0] - w, point[1] - h / 2, w, h);
            position = [
                1,
                0.5
            ];
            direction = Direction.Left;
        } else if (angle >= 225 && angle <= 315) {
            nextBound = new Bound(point[0] - w / 2, point[1] - h, w, h);
            position = [
                0.5,
                1
            ];
            direction = Direction.Top;
        } else {
            nextBound = new Bound(point[0], point[1] - h / 2, w, h);
            position = [
                0,
                0.5
            ];
            direction = Direction.Right;
        }
        return {
            nextBound,
            position,
            direction
        };
    }
    _getCurrentSourceInfo() {
        const { currentSource } = this;
        if (isShape(currentSource)) {
            const { shapeType, shapeStyle, radius } = currentSource;
            return {
                style: shapeStyle,
                type: getShapeName(shapeType, radius)
            };
        }
        return {
            style: ShapeStyle.General,
            type: 'note'
        };
    }
    _getPanelPosition() {
        const { viewport } = this.edgeless.service;
        const { boundingClientRect: viewportRect, zoom } = viewport;
        const result = this._getTargetXYWH(PANEL_WIDTH / zoom, PANEL_HEIGHT / zoom);
        const pos = result ? result.xywh.slice(0, 2) : this.position;
        const coord = viewport.toViewCoord(pos[0], pos[1]);
        const { width, height } = viewportRect;
        coord[0] = CommonUtils.clamp(coord[0], 20, width - 20 - PANEL_WIDTH);
        coord[1] = CommonUtils.clamp(coord[1], 20, height - 20 - PANEL_HEIGHT);
        return coord;
    }
    _getTargetXYWH(width, height) {
        const result = this._generateTarget(this.connector);
        if (!result) return null;
        const { nextBound: bound, direction, position } = result;
        if (!bound) return null;
        const { w, h } = bound;
        let x = bound.x;
        let y = bound.y;
        switch(direction){
            case Direction.Right:
                y += h / 2 - height / 2;
                break;
            case Direction.Bottom:
                x -= width / 2 - w / 2;
                break;
            case Direction.Left:
                y += h / 2 - height / 2;
                x -= width - w;
                break;
            case Direction.Top:
                x -= width / 2 - w / 2;
                y += h - height;
                break;
        }
        const xywh = [
            x,
            y,
            width,
            height
        ];
        return {
            xywh,
            position
        };
    }
    _removeOverlay() {
        if (this._overlay) this.edgeless.surface.renderer.removeOverlay(this._overlay);
    }
    _showFrameOverlay() {
        const bound = this._generateTarget(this.connector)?.nextBound;
        if (!bound) return;
        const { h } = bound;
        const w = h / 0.75;
        const xywh = this._getTargetXYWH(w, h)?.xywh;
        if (!xywh) return;
        const strokeColor = ThemeObserver.getPropertyValue('--affine-black-30');
        this._overlay = new AutoCompleteFrameOverlay(xywh, strokeColor);
        this.edgeless.surface.renderer.addOverlay(this._overlay);
    }
    _showNoteOverlay() {
        const xywh = this._getTargetXYWH(DEFAULT_NOTE_WIDTH, DEFAULT_NOTE_OVERLAY_HEIGHT)?.xywh;
        if (!xywh) return;
        const background = ThemeObserver.getColorValue(this.edgeless.std.get(EditPropsStore).lastProps$.value['affine:note'].background, DEFAULT_NOTE_BACKGROUND_COLOR, true);
        this._overlay = new AutoCompleteNoteOverlay(xywh, background);
        this.edgeless.surface.renderer.addOverlay(this._overlay);
    }
    _showOverlay(targetType) {
        this._removeOverlay();
        if (!this._connectorExist()) return;
        switch(targetType){
            case 'text':
                this._showTextOverlay();
                break;
            case 'note':
                this._showNoteOverlay();
                break;
            case 'frame':
                this._showFrameOverlay();
                break;
            default:
                this._showShapeOverlay(targetType);
        }
        this.edgeless.surface.refresh();
    }
    _showShapeOverlay(targetType) {
        const bound = this._generateTarget(this.connector)?.nextBound;
        if (!bound) return;
        const { x, y, w, h } = bound;
        const xywh = [
            x,
            y,
            w,
            h
        ];
        const { shapeStyle, strokeColor, fillColor, strokeWidth, roughness } = this.edgeless.std.get(EditPropsStore).lastProps$.value[`shape:${targetType}`];
        const stroke = ThemeObserver.getColorValue(strokeColor, DEFAULT_SHAPE_STROKE_COLOR, true);
        const fill = ThemeObserver.getColorValue(fillColor, DEFAULT_SHAPE_FILL_COLOR, true);
        const options = {
            seed: 666,
            roughness: roughness,
            strokeLineDash: [
                0,
                0
            ],
            stroke,
            strokeWidth,
            fill
        };
        this._overlay = new AutoCompleteShapeOverlay(xywh, targetType, options, shapeStyle);
        this.edgeless.surface.renderer.addOverlay(this._overlay);
    }
    _showTextOverlay() {
        const xywh = this._getTargetXYWH(DEFAULT_TEXT_WIDTH, DEFAULT_TEXT_HEIGHT)?.xywh;
        if (!xywh) return;
        this._overlay = new AutoCompleteTextOverlay(xywh);
        this.edgeless.surface.renderer.addOverlay(this._overlay);
    }
    connectedCallback() {
        super.connectedCallback();
        this.edgeless.handleEvent('click', (ctx)=>{
            const { target } = ctx.get('pointerState').raw;
            const element = captureEventTarget(target);
            const clickAway = !element?.closest('edgeless-auto-complete-panel');
            if (clickAway) this.remove();
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._removeOverlay();
    }
    firstUpdated() {
        this.disposables.add(this.edgeless.service.viewport.viewportUpdated.on(()=>this.requestUpdate()));
    }
    render() {
        const position = this._getPanelPosition();
        if (!position) return nothing;
        const style = styleMap({
            left: `${position[0]}px`,
            top: `${position[1]}px`
        });
        const { style: currentSourceStyle, type: currentSourceType } = this._getCurrentSourceInfo();
        const shapeButtons = repeat(ShapeComponentConfig, ({ name, generalIcon, scribbledIcon, tooltip })=>html`
        <edgeless-tool-icon-button
          .tooltip=${tooltip}
          @pointerenter=${()=>this._showOverlay(name)}
          @pointerleave=${()=>this._removeOverlay()}
          @click=${()=>this._autoComplete(name)}
        >
          ${currentSourceStyle === 'General' ? generalIcon : scribbledIcon}
        </edgeless-tool-icon-button>
      `);
        return html`<div class="auto-complete-panel-container" style=${style}>
      ${shapeButtons}

      <edgeless-tool-icon-button
        .tooltip=${'Text'}
        @pointerenter=${()=>this._showOverlay('text')}
        @pointerleave=${()=>this._removeOverlay()}
        @click=${()=>this._autoComplete('text')}
      >
        ${FontFamilyIcon}
      </edgeless-tool-icon-button>
      <edgeless-tool-icon-button
        .tooltip=${'Note'}
        @pointerenter=${()=>this._showOverlay('note')}
        @pointerleave=${()=>this._removeOverlay()}
        @click=${()=>this._autoComplete('note')}
      >
        ${SmallNoteIcon}
      </edgeless-tool-icon-button>
      <edgeless-tool-icon-button
        .tooltip=${'Frame'}
        @pointerenter=${()=>this._showOverlay('frame')}
        @pointerleave=${()=>this._removeOverlay()}
        @click=${()=>this._autoComplete('frame')}
      >
        ${FrameIcon}
      </edgeless-tool-icon-button>

      <edgeless-tool-icon-button
        .iconContainerPadding=${0}
        .tooltip=${capitalizeFirstLetter(currentSourceType)}
        @pointerenter=${()=>this._showOverlay(currentSourceType)}
        @pointerleave=${()=>this._removeOverlay()}
        @click=${()=>this._autoComplete(currentSourceType)}
      >
        <div class="row-button">Add a same object</div>
      </edgeless-tool-icon-button>
    </div>`;
    }
    #___private_connector_1;
    get connector() {
        return this.#___private_connector_1;
    }
    set connector(_v) {
        this.#___private_connector_1 = _v;
    }
    #___private_currentSource_2;
    get currentSource() {
        return this.#___private_currentSource_2;
    }
    set currentSource(_v) {
        this.#___private_currentSource_2 = _v;
    }
    #___private_edgeless_3;
    get edgeless() {
        return this.#___private_edgeless_3;
    }
    set edgeless(_v) {
        this.#___private_edgeless_3 = _v;
    }
    #___private_position_4;
    get position() {
        return this.#___private_position_4;
    }
    set position(_v) {
        this.#___private_position_4 = _v;
    }
}
