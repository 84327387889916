import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
const formatTime = (time)=>{
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
};
export const CountDownRender = forwardRef(({ timeLeft, ...props }, ref)=>{
    return _jsx("div", {
        ...props,
        ref: ref,
        children: formatTime(timeLeft)
    });
});
CountDownRender.displayName = 'CountDownRender';
