import { jsx as _jsx } from "react/jsx-runtime";
import { WorkspaceSettingDetail } from './new-workspace-setting-detail';
import { WorkspaceSettingProperties } from './properties';
export const WorkspaceSetting = ({ workspaceMetadata, subTab })=>{
    switch(subTab){
        case 'preference':
            return _jsx(WorkspaceSettingDetail, {
                workspaceMetadata: workspaceMetadata
            });
        case 'properties':
            return _jsx(WorkspaceSettingProperties, {
                workspaceMetadata: workspaceMetadata
            });
    }
};
