import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Modal, Scrollable } from '@affine/component';
import { DocInfoService } from '@affine/core/modules/doc-info';
import { DocsSearchService } from '@affine/core/modules/docs-search';
import { FeedTag } from '@affine/core/modules/tag/entities/internal-tag';
import { useI18n } from '@affine/i18n';
import { DocsService, FrameworkScope, LiveData, useLiveData, useService, useServices, WorkspaceService } from '@toeverything/infra';
import { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BlocksuiteHeaderTitle } from '../../../blocksuite/block-suite-header/title';
import { managerContext } from '../common';
import { PagePropertiesAddProperty, PagePropertyRow, SortableProperties, usePagePropertiesManager } from '../table';
import * as styles from './info-modal.css';
import { LinksRow } from './links-row';
import { TagsRow } from './tags-row';
import { TimeRow } from './time-row';
export const InfoModal = ()=>{
    const modal = useService(DocInfoService).modal;
    const docId = useLiveData(modal.docId$);
    const docsService = useService(DocsService);
    const [doc, setDoc] = useState(null);
    useEffect(()=>{
        if (!docId) return;
        const docRef = docsService.open(docId);
        setDoc(docRef.doc);
        return ()=>{
            docRef.release();
            setDoc(null);
        };
    }, [
        docId,
        docsService
    ]);
    if (!doc || !docId) return null;
    return _jsx(FrameworkScope, {
        scope: doc.scope,
        children: _jsx(InfoModalOpened, {
            docId: docId
        })
    });
};
const InfoModalOpened = ({ docId })=>{
    const modal = useService(DocInfoService).modal;
    const titleInputHandleRef = useRef(null);
    const manager = usePagePropertiesManager(docId ?? '');
    const handleClose = useCallback(()=>{
        modal.close();
    }, [
        modal
    ]);
    const workspaceService = useService(WorkspaceService);
    const page = workspaceService.workspace.docCollection.getDoc(docId);
    const hasSubscriptionTag = page?.meta?.tags.some((tag)=>tag === FeedTag.id);
    if (!manager.page || !hasSubscriptionTag && manager.readonly) {
        return null;
    }
    return _jsx(Modal, {
        contentOptions: {
            className: styles.container
        },
        open: true,
        onOpenChange: (v)=>modal.onOpenChange(v),
        withoutCloseButton: true,
        children: _jsxs(Scrollable.Root, {
            children: [
                _jsxs(Scrollable.Viewport, {
                    className: styles.viewport,
                    "data-testid": "info-modal",
                    children: [
                        _jsx("div", {
                            className: styles.titleContainer,
                            "data-testid": "info-modal-title",
                            children: _jsx(BlocksuiteHeaderTitle, {
                                docId: docId,
                                className: styles.titleStyle,
                                inputHandleRef: titleInputHandleRef
                            })
                        }),
                        _jsx(managerContext.Provider, {
                            value: manager,
                            children: _jsx(Suspense, {
                                children: _jsx(InfoTable, {
                                    docId: docId,
                                    onClose: handleClose,
                                    readonly: manager.readonly
                                })
                            })
                        })
                    ]
                }),
                _jsx(Scrollable.Scrollbar, {
                    className: styles.scrollBar
                })
            ]
        })
    });
};
export const InfoTable = ({ onClose, docId, readonly })=>{
    const t = useI18n();
    const manager = useContext(managerContext);
    const { docsSearchService } = useServices({
        DocsSearchService
    });
    const links = useLiveData(useMemo(()=>LiveData.from(docsSearchService.watchRefsFrom(docId), null), [
        docId,
        docsSearchService
    ]));
    const backlinks = useLiveData(useMemo(()=>LiveData.from(docsSearchService.watchRefsTo(docId), null), [
        docId,
        docsSearchService
    ]));
    return _jsxs("div", {
        children: [
            _jsx(TimeRow, {
                className: styles.timeRow,
                docId: docId
            }),
            _jsx(Divider, {
                size: "thinner"
            }),
            backlinks && backlinks.length > 0 ? _jsxs(_Fragment, {
                children: [
                    _jsx(LinksRow, {
                        references: backlinks,
                        onClick: onClose,
                        label: t['com.affine.page-properties.backlinks']()
                    }),
                    _jsx(Divider, {
                        size: "thinner"
                    })
                ]
            }) : null,
            links && links.length > 0 ? _jsxs(_Fragment, {
                children: [
                    _jsx(LinksRow, {
                        references: links,
                        onClick: onClose,
                        label: t['com.affine.page-properties.outgoing-links']()
                    }),
                    _jsx(Divider, {
                        size: "thinner"
                    })
                ]
            }) : null,
            _jsx(TagsRow, {
                docId: docId,
                readonly: readonly
            }),
            _jsx(SortableProperties, {
                children: (properties)=>properties.length ? _jsx("div", {
                        children: properties.filter((property)=>manager.isPropertyRequired(property.id) || property.visibility !== 'hide' && !(property.visibility === 'hide-if-empty' && !property.value)).map((property)=>_jsx(PagePropertyRow, {
                                property: property,
                                rowNameClassName: styles.rowNameContainer
                            }, property.id))
                    }) : null
            }),
            manager.readonly ? null : _jsx(PagePropertiesAddProperty, {})
        ]
    });
};
