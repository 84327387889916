export var MigrationPoint;
(function(MigrationPoint) {
    MigrationPoint[MigrationPoint["SubDoc"] = 1] = "SubDoc";
    MigrationPoint[MigrationPoint["GuidFix"] = 2] = "GuidFix";
    MigrationPoint[MigrationPoint["BlockVersion"] = 3] = "BlockVersion";
})(MigrationPoint || (MigrationPoint = {}));
export function checkWorkspaceCompatibility(docCollection, isCloud) {
    const spaceMetaObj = docCollection.doc.share.get('space:meta');
    const docKeys = Array.from(docCollection.doc.share.keys());
    const haveSpaceMeta = !!spaceMetaObj && spaceMetaObj.size > 0;
    const haveLegacySpace = docKeys.some((key)=>key.startsWith('space:'));
    if (!isCloud && (haveSpaceMeta || haveLegacySpace)) {
        return 1;
    }
    if (!docCollection.meta.docs?.length) {
        return null;
    }
    const meta = docCollection.doc.getMap('meta');
    const pages = meta.get('pages');
    for (const page of pages){
        const pageId = page.get('id');
        if (pageId?.includes(':')) {
            return 2;
        }
    }
    const spaces = docCollection.doc.getMap('spaces');
    for (const [pageId, _] of spaces){
        if (pageId.includes(':')) {
            return 2;
        }
    }
    const hasVersion = docCollection.meta.hasVersion;
    if (!hasVersion) {
        return 3;
    }
    if ((docCollection.meta.docs?.length ?? 0) <= 1) {
        try {
            docCollection.meta.validateVersion(docCollection);
        } catch (e) {
            console.info('validateVersion error', e);
            return 3;
        }
    }
    const blockVersions = docCollection.meta.blockVersions;
    for (const [flavour, version] of Object.entries(blockVersions ?? {})){
        const schema = docCollection.schema.flavourSchemaMap.get(flavour);
        if (schema?.version !== version) {
            return 3;
        }
    }
    return null;
}
