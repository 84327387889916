import { autoUpdate } from '@floating-ui/dom';
import { autoPlacement, computePosition, offset, shift, size } from '@floating-ui/dom';
export function listenClickAway(element, onClickAway) {
    const callback = (event)=>{
        const inside = event.composedPath().includes(element);
        if (!inside) {
            onClickAway();
        }
    };
    document.addEventListener('click', callback);
    return {
        dispose: ()=>{
            document.removeEventListener('click', callback);
        }
    };
}
const ATTR_SHOW = 'data-show';
export function createButtonPopper(reference, popperElement, stateUpdated = ()=>{}, { mainAxis, crossAxis, rootBoundary, ignoreShift } = {}) {
    let display = 'hidden';
    let cleanup;
    const originMaxHeight = window.getComputedStyle(popperElement).maxHeight;
    function compute() {
        const overflowOptions = {
            rootBoundary: typeof rootBoundary === 'function' ? rootBoundary() : rootBoundary
        };
        computePosition(reference, popperElement, {
            middleware: [
                offset({
                    mainAxis: mainAxis ?? 14,
                    crossAxis: crossAxis ?? 0
                }),
                autoPlacement({
                    allowedPlacements: [
                        'top',
                        'bottom'
                    ],
                    ...overflowOptions
                }),
                shift(overflowOptions),
                size({
                    ...overflowOptions,
                    apply ({ availableHeight }) {
                        popperElement.style.maxHeight = originMaxHeight ? `min(${originMaxHeight}, ${availableHeight}px)` : `${availableHeight}px`;
                    }
                })
            ]
        }).then(({ x, y, middlewareData: data })=>{
            if (!ignoreShift) {
                x += data.shift?.x ?? 0;
                y += data.shift?.y ?? 0;
            }
            Object.assign(popperElement.style, {
                position: 'absolute',
                zIndex: 1,
                left: `${x}px`,
                top: `${y}px`
            });
        }).catch(console.error);
    }
    const show = (force = false)=>{
        const displayed = display === 'show';
        if (displayed && !force) return;
        if (!displayed) {
            popperElement.setAttribute(ATTR_SHOW, '');
            display = 'show';
            stateUpdated({
                display
            });
        }
        cleanup?.();
        cleanup = autoUpdate(reference, popperElement, compute, {
            animationFrame: true
        });
    };
    const hide = ()=>{
        if (display === 'hidden') return;
        popperElement.removeAttribute(ATTR_SHOW);
        display = 'hidden';
        stateUpdated({
            display
        });
        cleanup?.();
    };
    const toggle = ()=>{
        if (popperElement.hasAttribute(ATTR_SHOW)) {
            hide();
        } else {
            show();
        }
    };
    const clickAway = listenClickAway(reference, ()=>hide());
    return {
        get state () {
            return display;
        },
        show,
        hide,
        toggle,
        dispose: ()=>{
            cleanup?.();
            clickAway.dispose();
        }
    };
}
