function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _init_alphaControl, _init_canvas, _init_colors, _init_hueControl, _init_paletteControl, _init_pick, _initProto;
import { on, once, stopPropagation } from '@blocksuite/affine-shared/utils';
import { SignalWatcher, WithDisposable } from '@blocksuite/global/utils';
import { batch, computed, signal } from '@preact/signals-core';
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { live } from 'lit/directives/live.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { AREA_CIRCLE_R, MATCHERS, SLIDER_CIRCLE_R } from './consts.js';
import { COLOR_PICKER_STYLE } from './styles.js';
import { bound01, clamp, defaultHsva, eq, hsvaToHex8, hsvaToRgba, linearGradientAt, parseHexToHsva, renderCanvas, rgbaToHex8, rgbaToHsva, rgbToHex, rgbToHsv } from './utils.js';
const TABS = [
    {
        type: 'colors',
        name: 'Colors'
    },
    {
        type: 'custom',
        name: 'Custom'
    }
];
_dec = query('.color-slider-wrapper.alpha .color-slider'), _dec1 = query('canvas'), _dec2 = property({
    attribute: false
}), _dec3 = query('.color-slider-wrapper.hue .color-slider'), _dec4 = query('.color-palette'), _dec5 = property({
    attribute: false
});
export class EdgelessColorPicker extends SignalWatcher(WithDisposable(LitElement)) {
    static{
        ({ e: [_init_alphaControl, _init_canvas, _init_colors, _init_hueControl, _init_paletteControl, _init_pick, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "alphaControl"
            ],
            [
                _dec1,
                1,
                "canvas"
            ],
            [
                _dec2,
                1,
                "colors"
            ],
            [
                _dec3,
                1,
                "hueControl"
            ],
            [
                _dec4,
                1,
                "paletteControl"
            ],
            [
                _dec5,
                1,
                "pick"
            ]
        ], []));
    }
    static{
        this.styles = COLOR_PICKER_STYLE;
    }
    #alphaRect;
    #editAlpha;
    #editHex;
    #hueRect;
    #paletteRect;
    #pick() {
        const hsva = this.hsva$.peek();
        const type = this.modeType$.peek();
        const detail = {
            [type]: hsvaToHex8(hsva)
        };
        if (type !== 'normal') {
            const another = type === 'light' ? 'dark' : 'light';
            const { hsva } = this[`${another}$`].peek();
            detail[another] = hsvaToHex8(hsva);
        }
        this.pick?.({
            type: 'pick',
            detail
        });
    }
    #pickEnd() {
        this.pick?.({
            type: 'end'
        });
    }
    #pickStart() {
        this.pick?.({
            type: 'start'
        });
    }
    #setAlphaPos(clientX) {
        const { left, width } = this.#alphaRect;
        const x = clamp(0, clientX - left, width);
        this.alphaPosX$.value = x;
    }
    #setAlphaPosWithWheel(y) {
        const { width } = this.#alphaRect;
        const px = this.alphaPosX$.peek();
        const ax = clamp(0, px + y * width / 100, width);
        this.alphaPosX$.value = ax;
    }
    #setControlsPos({ h, s, v, a }) {
        const hx = this.#hueRect.width * h;
        const px = this.#paletteRect.width * s;
        const py = this.#paletteRect.height * (1 - v);
        const ax = this.#alphaRect.width * a;
        batch(()=>{
            this.huePosX$.value = hx;
            this.alphaPosX$.value = ax;
            this.palettePos$.value = {
                x: px,
                y: py
            };
        });
    }
    #setHuePos(clientX) {
        const { left, width } = this.#hueRect;
        const x = clamp(0, clientX - left, width);
        this.huePosX$.value = x;
    }
    #setHuePosWithWheel(y) {
        const { width } = this.#hueRect;
        const px = this.huePosX$.peek();
        const ax = clamp(0, px + y * width / 100, width);
        this.huePosX$.value = ax;
    }
    #setPalettePos(clientX, clientY) {
        const { left, top, width, height } = this.#paletteRect;
        const x = clamp(0, clientX - left, width);
        const y = clamp(0, clientY - top, height);
        this.palettePos$.value = {
            x,
            y
        };
    }
    #setPalettePosWithWheel(x, y) {
        const { width, height } = this.#paletteRect;
        const pos = this.palettePos$.peek();
        const px = clamp(0, pos.x + x * width / 100, width);
        const py = clamp(0, pos.y + y * height / 100, height);
        this.palettePos$.value = {
            x: px,
            y: py
        };
    }
    #setRect({ left, top, width, height }, offset) {
        return new DOMRect(left + offset, top + offset, Math.round(width - offset * 2), Math.round(height - offset * 2));
    }
    #setRects() {
        this.#paletteRect = this.#setRect(this.paletteControl.getBoundingClientRect(), AREA_CIRCLE_R);
        this.#hueRect = this.#setRect(this.hueControl.getBoundingClientRect(), SLIDER_CIRCLE_R);
        this.#alphaRect = this.#setRect(this.alphaControl.getBoundingClientRect(), SLIDER_CIRCLE_R);
    }
    #switchModeTab(type) {
        this.modeType$.value = type;
        this.#setControlsPos(this.mode$.peek().hsva);
    }
    #switchNavTab(type) {
        this.navType$.value = type;
        if (type === 'colors') {
            const mode = this.mode$.peek();
            this.modes$.value[0].hsva = {
                ...mode.hsva
            };
            this.modeType$.value = 'normal';
        } else {
            const [normal, light, dark] = this.modes$.value;
            light.hsva = {
                ...normal.hsva
            };
            dark.hsva = {
                ...normal.hsva
            };
            this.modeType$.value = 'light';
        }
    }
    firstUpdated() {
        let clicked = false;
        let dragged = false;
        let outed = false;
        let picked = false;
        let pointerenter;
        let pointermove;
        let pointerout;
        let timerId = 0;
        this.disposables.addFromEvent(this, 'wheel', (e)=>{
            e.stopPropagation();
            const target = e.composedPath()[0];
            const isInHue = target === this.hueControl;
            const isInAlpha = !isInHue && target === this.alphaControl;
            const isInPalette = !isInAlpha && target === this.paletteControl;
            picked = isInHue || isInAlpha || isInPalette;
            if (timerId) {
                clearTimeout(timerId);
            }
            if (picked) {
                if (!timerId) {
                    this.#pickStart();
                }
                timerId = window.setTimeout(()=>{
                    this.#pickEnd();
                    timerId = 0;
                }, 110);
            }
            const update = (x, y)=>{
                if (!picked) return;
                const absX = Math.abs(x);
                const absY = Math.abs(y);
                x = Math.sign(x);
                y = Math.sign(y);
                if (Math.hypot(x, y) === 0) return;
                x *= Math.max(1, Math.log10(absX)) * -1;
                y *= Math.max(1, Math.log10(absY)) * -1;
                if (isInHue) {
                    this.#setHuePosWithWheel(x | y);
                }
                if (isInAlpha) {
                    this.#setAlphaPosWithWheel(x | y);
                }
                if (isInPalette) {
                    this.#setPalettePosWithWheel(x, y);
                }
                this.#pick();
            };
            update(e.deltaX, e.deltaY);
        });
        this.disposables.addFromEvent(this, 'pointerdown', (e)=>{
            e.stopPropagation();
            if (timerId) {
                clearTimeout(timerId);
                timerId = 0;
            }
            pointerenter = on(this, 'pointerenter', ()=>outed = false);
            pointerout = on(this, 'pointerout', ()=>outed = true);
            once(document, 'pointerup', ()=>{
                pointerenter?.();
                pointermove?.();
                pointerout?.();
                if (picked) {
                    this.#pickEnd();
                }
                if (dragged && outed) {
                    once(document, 'click', stopPropagation, true);
                }
                pointerenter = pointermove = pointerout = null;
                clicked = dragged = outed = picked = false;
            });
            clicked = true;
            const target = e.composedPath()[0];
            const isInHue = target === this.hueControl;
            const isInAlpha = !isInHue && target === this.alphaControl;
            const isInPalette = !isInAlpha && target === this.paletteControl;
            picked = isInHue || isInAlpha || isInPalette;
            if (picked) {
                this.#pickStart();
                const rect = target.getBoundingClientRect();
                if (isInHue) {
                    this.#hueRect = this.#setRect(rect, SLIDER_CIRCLE_R);
                } else if (isInAlpha) {
                    this.#alphaRect = this.#setRect(rect, SLIDER_CIRCLE_R);
                } else if (isInPalette) {
                    this.#paletteRect = this.#setRect(rect, AREA_CIRCLE_R);
                }
            }
            const update = (x, y)=>{
                if (!picked) return;
                if (isInHue) {
                    this.#setHuePos(x);
                }
                if (isInAlpha) {
                    this.#setAlphaPos(x);
                }
                if (isInPalette) {
                    this.#setPalettePos(x, y);
                }
                this.#pick();
            };
            update(e.x, e.y);
            pointermove = on(document, 'pointermove', (e)=>{
                if (!clicked) return;
                if (!dragged) dragged = true;
                update(e.x, e.y);
            });
        });
        this.disposables.addFromEvent(this, 'click', stopPropagation);
        const batches = [];
        const { type, modes } = this.colors;
        if ([
            'dark',
            'light'
        ].includes(type)) {
            batches.push(()=>{
                this.modeType$.value = type;
                this.navType$.value = 'custom';
            });
        }
        if (modes?.length) {
            batches.push(()=>{
                this.modes$.value.reduce((fallback, curr, n)=>{
                    const m = modes[n];
                    curr.hsva = m ? rgbaToHsva(m.rgba) : fallback;
                    return {
                        ...curr.hsva
                    };
                }, defaultHsva());
            });
        }
        batches.push(()=>{
            const mode = this.mode$.peek();
            this.#setControlsPos(mode.hsva);
        });
        this.#setRects();
        batch(()=>batches.forEach((fn)=>fn()));
        this.updateComplete.then(()=>{
            this.disposables.add(this.hsva$.subscribe((hsva)=>{
                const type = this.modeType$.peek();
                const mode = this.modes$.value.find((mode)=>mode.type === type);
                if (mode) {
                    mode.hsva = {
                        ...hsva
                    };
                }
            }));
            this.disposables.add(this.huePosX$.subscribe((x)=>{
                const { width } = this.#hueRect;
                const rgb = linearGradientAt(bound01(x, width));
                renderCanvas(this.canvas, rgb);
                this.hue$.value = rgb;
            }));
            this.disposables.add(this.hue$.subscribe((rgb)=>{
                const hsva = this.hsva$.peek();
                const h = rgbToHsv(rgb).h;
                this.hsva$.value = {
                    ...hsva,
                    h
                };
            }));
            this.disposables.add(this.alphaPosX$.subscribe((x)=>{
                const hsva = this.hsva$.peek();
                const { width } = this.#alphaRect;
                const a = bound01(x, width);
                this.hsva$.value = {
                    ...hsva,
                    a
                };
            }));
            this.disposables.add(this.palettePos$.subscribe(({ x, y })=>{
                const hsva = this.hsva$.peek();
                const { width, height } = this.#paletteRect;
                const s = bound01(x, width);
                const v = bound01(height - y, height);
                this.hsva$.value = {
                    ...hsva,
                    s,
                    v
                };
            }));
        }).catch(console.error);
    }
    render() {
        return html`
      <header>
        <nav>
          ${repeat(TABS, (tab)=>tab.type, ({ type, name })=>html`
              <button
                ?active=${type === this.navType$.value}
                @click=${()=>this.#switchNavTab(type)}
              >
                ${name}
              </button>
            `)}
        </nav>
      </header>

      <div class="modes" ?active=${this.navType$.value === 'custom'}>
        ${repeat([
            this.light$.value,
            this.dark$.value
        ], (mode)=>mode.type, ({ type, name, hsva })=>html`
            <div
              class="${classMap({
                mode: true,
                [type]: true
            })}"
              style=${styleMap({
                '--c': hsvaToHex8(hsva)
            })}
            >
              <button
                ?active=${this.modeType$.value === type}
                @click=${()=>this.#switchModeTab(type)}
              >
                <div class="color"></div>
                <div>${name}</div>
              </button>
            </div>
          `)}
      </div>

      <div class="content">
        <div
          class="color-palette-wrapper"
          style=${styleMap(this.paletteStyle$.value)}
        >
          <canvas></canvas>
          <div class="color-circle"></div>
          <div class="color-palette"></div>
        </div>
        <div
          class="color-slider-wrapper hue"
          style=${styleMap(this.hueStyle$.value)}
        >
          <div class="color-circle"></div>
          <div class="color-slider"></div>
        </div>
        <div
          class="color-slider-wrapper alpha"
          style=${styleMap(this.alphaStyle$.value)}
        >
          <div class="color-circle"></div>
          <div class="color-slider"></div>
        </div>
      </div>

      <footer>
        <label class="field color">
          <span>#</span>
          <input
            autocomplete="off"
            spellcheck="false"
            minlength="1"
            maxlength="6"
            .value=${live(this.hex6WithoutHash$.value)}
            @keydown=${this.#editHex}
            @cut=${stopPropagation}
            @copy=${stopPropagation}
            @paste=${stopPropagation}
          />
        </label>
        <label class="field alpha">
          <input
            type="number"
            min="0"
            max="100"
            .value=${live(this.alpha100$.value)}
            @input=${this.#editAlpha}
            @cut=${stopPropagation}
            @copy=${stopPropagation}
            @paste=${stopPropagation}
          />
          <span>%</span>
        </label>
      </footer>
    `;
    }
    #___private_alpha100$_1;
    get alpha100$() {
        return this.#___private_alpha100$_1;
    }
    set alpha100$(_v) {
        this.#___private_alpha100$_1 = _v;
    }
    #___private_alphaControl_2;
    get alphaControl() {
        return this.#___private_alphaControl_2;
    }
    set alphaControl(_v) {
        this.#___private_alphaControl_2 = _v;
    }
    #___private_alphaPosX$_3;
    get alphaPosX$() {
        return this.#___private_alphaPosX$_3;
    }
    set alphaPosX$(_v) {
        this.#___private_alphaPosX$_3 = _v;
    }
    #___private_alphaStyle$_4;
    get alphaStyle$() {
        return this.#___private_alphaStyle$_4;
    }
    set alphaStyle$(_v) {
        this.#___private_alphaStyle$_4 = _v;
    }
    #___private_canvas_5;
    get canvas() {
        return this.#___private_canvas_5;
    }
    set canvas(_v) {
        this.#___private_canvas_5 = _v;
    }
    #___private_colors_6;
    get colors() {
        return this.#___private_colors_6;
    }
    set colors(_v) {
        this.#___private_colors_6 = _v;
    }
    #___private_dark$_7;
    get dark$() {
        return this.#___private_dark$_7;
    }
    set dark$(_v) {
        this.#___private_dark$_7 = _v;
    }
    #___private_hex6$_8;
    get hex6$() {
        return this.#___private_hex6$_8;
    }
    set hex6$(_v) {
        this.#___private_hex6$_8 = _v;
    }
    #___private_hex6WithoutHash$_9;
    get hex6WithoutHash$() {
        return this.#___private_hex6WithoutHash$_9;
    }
    set hex6WithoutHash$(_v) {
        this.#___private_hex6WithoutHash$_9 = _v;
    }
    #___private_hex8$_10;
    get hex8$() {
        return this.#___private_hex8$_10;
    }
    set hex8$(_v) {
        this.#___private_hex8$_10 = _v;
    }
    #___private_hsva$_11;
    get hsva$() {
        return this.#___private_hsva$_11;
    }
    set hsva$(_v) {
        this.#___private_hsva$_11 = _v;
    }
    #___private_hue$_12;
    get hue$() {
        return this.#___private_hue$_12;
    }
    set hue$(_v) {
        this.#___private_hue$_12 = _v;
    }
    #___private_hueControl_13;
    get hueControl() {
        return this.#___private_hueControl_13;
    }
    set hueControl(_v) {
        this.#___private_hueControl_13 = _v;
    }
    #___private_huePosX$_14;
    get huePosX$() {
        return this.#___private_huePosX$_14;
    }
    set huePosX$(_v) {
        this.#___private_huePosX$_14 = _v;
    }
    #___private_hueStyle$_15;
    get hueStyle$() {
        return this.#___private_hueStyle$_15;
    }
    set hueStyle$(_v) {
        this.#___private_hueStyle$_15 = _v;
    }
    #___private_light$_16;
    get light$() {
        return this.#___private_light$_16;
    }
    set light$(_v) {
        this.#___private_light$_16 = _v;
    }
    #___private_mode$_17;
    get mode$() {
        return this.#___private_mode$_17;
    }
    set mode$(_v) {
        this.#___private_mode$_17 = _v;
    }
    #___private_modes$_18;
    get modes$() {
        return this.#___private_modes$_18;
    }
    set modes$(_v) {
        this.#___private_modes$_18 = _v;
    }
    #___private_modeType$_19;
    get modeType$() {
        return this.#___private_modeType$_19;
    }
    set modeType$(_v) {
        this.#___private_modeType$_19 = _v;
    }
    #___private_navType$_20;
    get navType$() {
        return this.#___private_navType$_20;
    }
    set navType$(_v) {
        this.#___private_navType$_20 = _v;
    }
    #___private_paletteControl_21;
    get paletteControl() {
        return this.#___private_paletteControl_21;
    }
    set paletteControl(_v) {
        this.#___private_paletteControl_21 = _v;
    }
    #___private_palettePos$_22;
    get palettePos$() {
        return this.#___private_palettePos$_22;
    }
    set palettePos$(_v) {
        this.#___private_palettePos$_22 = _v;
    }
    #___private_paletteStyle$_23;
    get paletteStyle$() {
        return this.#___private_paletteStyle$_23;
    }
    set paletteStyle$(_v) {
        this.#___private_paletteStyle$_23 = _v;
    }
    #___private_pick_24;
    get pick() {
        return this.#___private_pick_24;
    }
    set pick(_v) {
        this.#___private_pick_24 = _v;
    }
    #___private_rgba$_25;
    get rgba$() {
        return this.#___private_rgba$_25;
    }
    set rgba$(_v) {
        this.#___private_rgba$_25 = _v;
    }
    constructor(...args){
        super(...args), this.#alphaRect = new DOMRect(), this.#editAlpha = (e)=>{
            const target = e.target;
            const orignalValue = target.value;
            let value = orignalValue.trim().replace(/[^0-9]/, '');
            const alpha = clamp(0, Number(value), 100);
            const a = bound01(alpha, 100);
            const hsva = this.hsva$.peek();
            value = `${alpha}`;
            if (orignalValue !== value) {
                target.value = value;
            }
            if (hsva.a === a) return;
            const x = this.#alphaRect.width * a;
            this.alphaPosX$.value = x;
            this.#pick();
        }, this.#editHex = (e)=>{
            e.stopPropagation();
            const target = e.target;
            if (e.key === 'Enter') {
                const orignalValue = target.value;
                let value = orignalValue.trim().replace(MATCHERS.other, '');
                let matched;
                if ((matched = value.match(MATCHERS.hex3)) || (matched = value.match(MATCHERS.hex6))) {
                    const oldHsva = this.hsva$.peek();
                    const hsv = parseHexToHsva(matched[1]);
                    const newHsva = {
                        ...oldHsva,
                        ...hsv
                    };
                    value = rgbToHex(hsvaToRgba(newHsva));
                    if (orignalValue !== value) {
                        target.value = value;
                    }
                    if (eq(newHsva, oldHsva)) return;
                    this.#setControlsPos(newHsva);
                    this.#pick();
                } else {
                    target.value = this.hex6WithoutHash$.peek();
                }
            }
        }, this.#hueRect = new DOMRect(), this.#paletteRect = new DOMRect(), this.#___private_alpha100$_1 = computed(()=>`${Math.round(this.hsva$.value.a * 100)}`), this.#___private_alphaControl_2 = (_initProto(this), _init_alphaControl(this)), this.#___private_alphaPosX$_3 = signal(0), this.#___private_alphaStyle$_4 = computed(()=>{
            const x = this.alphaPosX$.value;
            const rgba = this.rgba$.value;
            const hex = `#${rgbToHex(rgba)}`;
            return {
                '--o': rgba.a,
                '--s': `${hex}00`,
                '--c': `${hex}ff`,
                '--x': `${x}px`,
                '--r': `${SLIDER_CIRCLE_R}px`
            };
        }), this.#___private_canvas_5 = _init_canvas(this), this.#___private_colors_6 = _init_colors(this, {
            type: 'normal'
        }), this.#___private_dark$_7 = computed(()=>this.modes$.value[2]), this.#___private_hex6$_8 = computed(()=>this.hex8$.value.substring(0, 7)), this.#___private_hex6WithoutHash$_9 = computed(()=>this.hex6$.value.substring(1)), this.#___private_hex8$_10 = computed(()=>rgbaToHex8(this.rgba$.value)), this.#___private_hsva$_11 = signal(defaultHsva()), this.#___private_hue$_12 = signal({
            r: 0,
            g: 0,
            b: 0
        }), this.#___private_hueControl_13 = _init_hueControl(this), this.#___private_huePosX$_14 = signal(0), this.#___private_hueStyle$_15 = computed(()=>{
            const x = this.huePosX$.value;
            const rgb = this.hue$.value;
            return {
                '--x': `${x}px`,
                '--c': `#${rgbToHex(rgb)}`,
                '--r': `${SLIDER_CIRCLE_R}px`
            };
        }), this.#___private_light$_16 = computed(()=>this.modes$.value[1]), this.#___private_mode$_17 = computed(()=>{
            const modeType = this.modeType$.value;
            return this.modes$.value.find((mode)=>mode.type === modeType);
        }), this.#___private_modes$_18 = signal([
            {
                type: 'normal',
                name: 'Normal',
                hsva: defaultHsva()
            },
            {
                type: 'light',
                name: 'Light',
                hsva: defaultHsva()
            },
            {
                type: 'dark',
                name: 'Dark',
                hsva: defaultHsva()
            }
        ]), this.#___private_modeType$_19 = signal('normal'), this.#___private_navType$_20 = signal('colors'), this.#___private_paletteControl_21 = _init_paletteControl(this), this.#___private_palettePos$_22 = signal({
            x: 0,
            y: 0
        }), this.#___private_paletteStyle$_23 = computed(()=>{
            const { x, y } = this.palettePos$.value;
            const c = this.hex6$.value;
            return {
                '--c': c,
                '--x': `${x}px`,
                '--y': `${y}px`,
                '--r': `${AREA_CIRCLE_R}px`
            };
        }), this.#___private_pick_24 = _init_pick(this), this.#___private_rgba$_25 = computed(()=>hsvaToRgba(this.hsva$.value));
    }
}
