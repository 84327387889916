import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@affine/component';
import { TagService } from '@affine/core/modules/tag';
import { useI18n } from '@affine/i18n';
import { TagsIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { InlineTagsList, TagsEditor } from '../tags-inline-editor';
import * as styles from './tags-row.css';
export const TagsRow = ({ docId, readonly, className })=>{
    const t = useI18n();
    const tagList = useService(TagService).tagList;
    const tagIds = useLiveData(tagList.tagIdsByPageId$(docId));
    const empty = !tagIds || tagIds.length === 0;
    return _jsxs("div", {
        className: clsx(styles.rowCell, className),
        "data-testid": "info-modal-tags-row",
        children: [
            _jsxs("div", {
                className: styles.rowNameContainer,
                children: [
                    _jsx("div", {
                        className: styles.icon,
                        children: _jsx(TagsIcon, {})
                    }),
                    _jsx("div", {
                        className: styles.rowName,
                        children: t['Tags']()
                    })
                ]
            }),
            _jsx(Menu, {
                contentOptions: {
                    side: 'bottom',
                    align: 'start',
                    sideOffset: 0,
                    avoidCollisions: false,
                    className: styles.tagsMenu,
                    onClick (e) {
                        e.stopPropagation();
                    }
                },
                items: _jsx(TagsEditor, {
                    pageId: docId,
                    readonly: readonly
                }),
                children: _jsx("div", {
                    className: clsx(styles.tagsInlineEditor, styles.rowValueCell),
                    "data-empty": empty,
                    "data-readonly": readonly,
                    "data-testid": "info-modal-tags-value",
                    children: empty ? t['com.affine.page-properties.property-value-placeholder']() : _jsx(InlineTagsList, {
                        pageId: docId,
                        readonly: true
                    })
                })
            })
        ]
    });
};
