import { use } from 'foxact/use';
import { useSyncExternalStore } from 'react';
function noopSubscribe() {
    return ()=>{};
}
function nullGetSnapshot() {
    return null;
}
function undefinedGetSnapshot() {
    return undefined;
}
export function useLiveData(liveData) {
    return useSyncExternalStore(liveData ? liveData.reactSubscribe : noopSubscribe, liveData ? liveData.reactGetSnapshot : liveData === undefined ? undefinedGetSnapshot : nullGetSnapshot);
}
export function useEnsureLiveData(liveData$) {
    const data = useLiveData(liveData$);
    if (data === null || data === undefined) {
        return use(new Promise((resolve, reject)=>{
            const subscription = liveData$.subscribe({
                next (value) {
                    if (value === null || value === undefined) {
                        resolve(value);
                        subscription.unsubscribe();
                    }
                },
                error (err) {
                    reject(err);
                },
                complete () {
                    reject(new Error('Unexpected completion'));
                }
            });
        }));
    }
    return data;
}
