import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FlexWrapper, Input, notify } from '@affine/component';
import { SettingHeader, SettingRow } from '@affine/component/setting-components';
import { Avatar } from '@affine/component/ui/avatar';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { useCatchEventCallback } from '@affine/core/components/hooks/use-catch-event-hook';
import { SubscriptionPlan } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { ArrowRightSmallIcon, CameraIcon } from '@blocksuite/icons/rc';
import { useEnsureLiveData, useLiveData, useService, useServices } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { AuthService, ServerConfigService } from '../../../../modules/cloud';
import { authAtom, openSettingModalAtom, openSignOutModalAtom } from '../../../atoms';
import { Upload } from '../../../pure/file-upload';
import { AIUsagePanel } from './ai-usage-panel';
import { StorageProgress } from './storage-progress';
import * as styles from './style.css';
export const UserAvatar = ()=>{
    const t = useI18n();
    const session = useService(AuthService).session;
    const account = useEnsureLiveData(session.account$);
    const handleUpdateUserAvatar = useAsyncCallback(async (file)=>{
        try {
            track.$.settingsPanel.accountSettings.uploadAvatar();
            await session.uploadAvatar(file);
            notify.success({
                title: 'Update user avatar success'
            });
        } catch (e) {
            notify.error({
                title: 'Update user avatar failed',
                message: String(e)
            });
        }
    }, [
        session
    ]);
    const handleRemoveUserAvatar = useCatchEventCallback(async ()=>{
        track.$.settingsPanel.accountSettings.removeAvatar();
        await session.removeAvatar();
    }, [
        session
    ]);
    return _jsx(Upload, {
        accept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
        fileChange: handleUpdateUserAvatar,
        "data-testid": "upload-user-avatar",
        children: _jsx(Avatar, {
            size: 56,
            name: account.label,
            url: account.avatar,
            hoverIcon: _jsx(CameraIcon, {}),
            onRemove: account.avatar ? handleRemoveUserAvatar : undefined,
            avatarTooltipOptions: {
                content: t['Click to replace photo']()
            },
            removeTooltipOptions: {
                content: t['Remove photo']()
            },
            "data-testid": "user-setting-avatar",
            removeButtonProps: {
                ['data-testid']: 'user-setting-remove-avatar-button'
            }
        })
    });
};
export const AvatarAndName = ()=>{
    const t = useI18n();
    const session = useService(AuthService).session;
    const account = useEnsureLiveData(session.account$);
    const [input, setInput] = useState(account.label);
    const allowUpdate = !!input && input !== account.label;
    const handleUpdateUserName = useAsyncCallback(async ()=>{
        if (account === null) {
            return;
        }
        if (!allowUpdate) {
            return;
        }
        try {
            track.$.settingsPanel.accountSettings.updateUserName();
            await session.updateLabel(input);
        } catch (e) {
            notify.error({
                title: 'Failed to update user name.',
                message: String(e)
            });
        }
    }, [
        account,
        allowUpdate,
        session,
        input
    ]);
    return _jsx(SettingRow, {
        name: t['com.affine.settings.profile'](),
        desc: t['com.affine.settings.profile.message'](),
        spreadCol: false,
        children: _jsxs(FlexWrapper, {
            style: {
                margin: '12px 0 24px 0'
            },
            alignItems: "center",
            children: [
                _jsx(UserAvatar, {}),
                _jsxs("div", {
                    className: styles.profileInputWrapper,
                    children: [
                        _jsx("label", {
                            children: t['com.affine.settings.profile.name']()
                        }),
                        _jsxs(FlexWrapper, {
                            alignItems: "center",
                            children: [
                                _jsx(Input, {
                                    defaultValue: input,
                                    "data-testid": "user-name-input",
                                    placeholder: t['com.affine.settings.profile.placeholder'](),
                                    maxLength: 64,
                                    minLength: 0,
                                    style: {
                                        width: 280,
                                        height: 32
                                    },
                                    onChange: setInput,
                                    onEnter: handleUpdateUserName
                                }),
                                allowUpdate ? _jsx(Button, {
                                    "data-testid": "save-user-name",
                                    onClick: handleUpdateUserName,
                                    style: {
                                        marginLeft: '12px'
                                    },
                                    children: t['com.affine.editCollection.save']()
                                }) : null
                            ]
                        })
                    ]
                })
            ]
        })
    });
};
const StoragePanel = ()=>{
    const t = useI18n();
    const setSettingModalAtom = useSetAtom(openSettingModalAtom);
    const onUpgrade = useCallback(()=>{
        track.$.settingsPanel.accountUsage.viewPlans({
            plan: SubscriptionPlan.Pro
        });
        setSettingModalAtom({
            open: true,
            activeTab: 'plans',
            scrollAnchor: 'cloudPricingPlan'
        });
    }, [
        setSettingModalAtom
    ]);
    return _jsx(SettingRow, {
        name: t['com.affine.storage.title'](),
        desc: "",
        spreadCol: false,
        children: _jsx(StorageProgress, {
            onUpgrade: onUpgrade
        })
    });
};
export const AccountSetting = ()=>{
    const { authService, serverConfigService } = useServices({
        AuthService,
        ServerConfigService
    });
    const serverFeatures = useLiveData(serverConfigService.serverConfig.features$);
    const t = useI18n();
    const session = authService.session;
    useEffect(()=>{
        session.revalidate();
    }, [
        session
    ]);
    const account = useEnsureLiveData(session.account$);
    const setAuthModal = useSetAtom(authAtom);
    const setSignOutModal = useSetAtom(openSignOutModalAtom);
    const onChangeEmail = useCallback(()=>{
        setAuthModal({
            openModal: true,
            state: 'sendEmail',
            email: account.email,
            emailType: account.info?.emailVerified ? 'changeEmail' : 'verifyEmail'
        });
    }, [
        account.email,
        account.info?.emailVerified,
        setAuthModal
    ]);
    const onPasswordButtonClick = useCallback(()=>{
        setAuthModal({
            openModal: true,
            state: 'sendEmail',
            email: account.email,
            emailType: account.info?.hasPassword ? 'changePassword' : 'setPassword'
        });
    }, [
        account.email,
        account.info?.hasPassword,
        setAuthModal
    ]);
    const onOpenSignOutModal = useCallback(()=>{
        setSignOutModal(true);
    }, [
        setSignOutModal
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingHeader, {
                title: t['com.affine.setting.account'](),
                subtitle: t['com.affine.setting.account.message'](),
                "data-testid": "account-title"
            }),
            _jsx(AvatarAndName, {}),
            _jsx(SettingRow, {
                name: t['com.affine.settings.email'](),
                desc: account.email,
                children: _jsx(Button, {
                    onClick: onChangeEmail,
                    children: account.info?.emailVerified ? t['com.affine.settings.email.action.change']() : t['com.affine.settings.email.action.verify']()
                })
            }),
            _jsx(SettingRow, {
                name: t['com.affine.settings.password'](),
                desc: t['com.affine.settings.password.message'](),
                children: _jsx(Button, {
                    onClick: onPasswordButtonClick,
                    children: account.info?.hasPassword ? t['com.affine.settings.password.action.change']() : t['com.affine.settings.password.action.set']()
                })
            }),
            _jsx(StoragePanel, {}),
            serverFeatures?.copilot && _jsx(AIUsagePanel, {}),
            _jsx(SettingRow, {
                name: t[`Sign out`](),
                desc: t['com.affine.setting.sign.out.message'](),
                style: {
                    cursor: 'pointer'
                },
                "data-testid": "sign-out-button",
                onClick: onOpenSignOutModal,
                children: _jsx(ArrowRightSmallIcon, {})
            })
        ]
    });
};
