export const defaultDatePickerProps = {
    format: 'YYYY-MM-DD',
    gapX: 8,
    gapY: 8,
    weekDays: [
        'Su',
        'Mo',
        'Tu',
        'We',
        'Th',
        'Fr',
        'Sa'
    ].join(','),
    monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ].join(','),
    todayLabel: 'Today'
};
