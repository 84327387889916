export class MenuContext {
    get firstElement() {
        return null;
    }
    close() {}
    isElement() {
        return false;
    }
}
export function getMoreMenuConfig(std) {
    return {
        configure: (groups)=>groups,
        ...std.getConfig('affine:page')?.toolbarMoreMenu
    };
}
