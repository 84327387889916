import { SurfaceGroupLikeModel } from '../element-model/base.js';
export const groupRelationMiddleware = (surface)=>{
    const disposables = [
        surface.elementUpdated.filter((payload)=>payload.local).on(({ id, props })=>{
            const element = surface.getElementById(id);
            if (element instanceof SurfaceGroupLikeModel && props['childIds']) {
                if (element.childIds.length === 0) {
                    surface.removeElement(id);
                }
            }
        })
    ];
    return ()=>{
        disposables.forEach((d)=>d.dispose());
    };
};
