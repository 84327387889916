import { CompatibleFavoriteItemsAdapter } from '@affine/core/modules/properties';
import { ShareDocsListService } from '@affine/core/modules/share-doc';
import { PublicPageMode } from '@affine/graphql';
import { useLiveData, useService } from '@toeverything/infra';
import { useCallback, useEffect, useMemo } from 'react';
import { filterPage, filterPageByRules } from './use-collection-manager';
export const useFilteredPageMetas = (pageMetas, options = {})=>{
    const shareDocsListService = useService(ShareDocsListService);
    const shareDocs = useLiveData(shareDocsListService.shareDocs?.list$);
    const getPublicMode = useCallback((id)=>{
        const mode = shareDocs?.find((shareDoc)=>shareDoc.id === id)?.mode;
        return mode ? mode === PublicPageMode.Edgeless ? 'edgeless' : 'page' : undefined;
    }, [
        shareDocs
    ]);
    useEffect(()=>{
        shareDocsListService.shareDocs?.revalidate();
    }, [
        shareDocsListService
    ]);
    const favAdapter = useService(CompatibleFavoriteItemsAdapter);
    const favoriteItems = useLiveData(favAdapter.favorites$);
    const filteredPageMetas = useMemo(()=>pageMetas.filter((pageMeta)=>{
            if (options.trash) {
                if (!pageMeta.trash) {
                    return false;
                }
            } else if (pageMeta.trash) {
                return false;
            }
            const pageData = {
                meta: pageMeta,
                favorite: favoriteItems.some((fav)=>fav.id === pageMeta.id),
                publicMode: getPublicMode(pageMeta.id)
            };
            if (options.filters && !filterPageByRules(options.filters, [], pageData)) {
                return false;
            }
            if (options.collection && !filterPage(options.collection, pageData)) {
                return false;
            }
            if (options.feedFilter) {
                const { source, read } = options.feedFilter;
                if (pageMeta.feedSource !== source) {
                    return false;
                }
                if (read !== undefined) {
                    const isRead = Boolean(pageMeta.read);
                    if (isRead !== read) {
                        return false;
                    }
                }
            }
            return true;
        }), [
        pageMetas,
        options.trash,
        options.filters,
        options.collection,
        options.feedFilter,
        favoriteItems,
        getPublicMode
    ]);
    return filteredPageMetas;
};
