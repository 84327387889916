function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _init__richTextElement, _initProto, _dec1, _init__richTextElement1, _initProto1;
import { DefaultInlineManagerExtension } from '@blocksuite/affine-components/rich-text';
import { getViewportElement } from '@blocksuite/affine-shared/utils';
import { BaseCellRenderer, createFromBaseCellRenderer, createIcon } from '@blocksuite/data-view';
import { IS_MAC } from '@blocksuite/global/env';
import { assertExists } from '@blocksuite/global/utils';
import { Text } from '@blocksuite/store';
import { css, nothing } from 'lit';
import { query } from 'lit/decorators.js';
import { keyed } from 'lit/directives/keyed.js';
import { html } from 'lit/static-html.js';
import { HostContextKey } from '../../context/host-context.js';
import { richTextColumnModelConfig } from './define.js';
function toggleStyle(inlineEditor, attrs) {
    const inlineRange = inlineEditor.getInlineRange();
    if (!inlineRange) return;
    const root = inlineEditor.rootElement;
    if (!root) {
        return;
    }
    const deltas = inlineEditor.getDeltasByInlineRange(inlineRange);
    let oldAttributes = {};
    for (const [delta] of deltas){
        const attributes = delta.attributes;
        if (!attributes) {
            continue;
        }
        oldAttributes = {
            ...attributes
        };
    }
    const newAttributes = Object.fromEntries(Object.entries(attrs).map(([k, v])=>{
        if (typeof v === 'boolean' && v === oldAttributes[k]) {
            return [
                k,
                !v
            ];
        } else {
            return [
                k,
                v
            ];
        }
    }));
    inlineEditor.formatText(inlineRange, newAttributes, {
        mode: 'merge'
    });
    root.blur();
    inlineEditor.syncInlineRange();
}
_dec = query('rich-text');
export class RichTextCell extends BaseCellRenderer {
    static{
        ({ e: [_init__richTextElement, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_richTextElement"
            ]
        ], []));
    }
    static{
        this.styles = css`
    affine-database-rich-text-cell {
      display: flex;
      align-items: center;
      width: 100%;
      user-select: none;
    }

    .affine-database-rich-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      outline: none;
      font-size: var(--data-view-cell-text-size);
      line-height: var(--data-view-cell-text-line-height);
      word-break: break-all;
    }

    .affine-database-rich-text v-line {
      display: flex !important;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .affine-database-rich-text v-line > div {
      flex-grow: 1;
    }
  `;
    }
    get attributeRenderer() {
        return this.inlineManager?.getRenderer();
    }
    get attributesSchema() {
        return this.inlineManager?.getSchema();
    }
    get inlineEditor() {
        assertExists(this._richTextElement);
        const inlineEditor = this._richTextElement.inlineEditor;
        assertExists(inlineEditor);
        return inlineEditor;
    }
    get inlineManager() {
        return this.view.contextGet(HostContextKey)?.std.get(DefaultInlineManagerExtension.identifier);
    }
    get service() {
        return this.view.contextGet(HostContextKey)?.std.getService('affine:database');
    }
    get topContenteditableElement() {
        const databaseBlock = this.closest('affine-database');
        return databaseBlock?.topContenteditableElement;
    }
    changeUserSelectAccordToReadOnly() {
        if (this && this instanceof HTMLElement) {
            this.style.userSelect = this.readonly ? 'text' : 'none';
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.changeUserSelectAccordToReadOnly();
    }
    render() {
        if (!this.service) return nothing;
        if (!this.value || !(this.value instanceof Text)) {
            return html`<div class="affine-database-rich-text"></div>`;
        }
        return keyed(this.value, html`<rich-text
        .yText=${this.value}
        .attributesSchema=${this.attributesSchema}
        .attributeRenderer=${this.attributeRenderer}
        .embedChecker=${this.inlineManager?.embedChecker}
        .markdownShortcutHandler=${this.inlineManager?.markdownShortcutHandler}
        .readonly=${true}
        class="affine-database-rich-text inline-editor"
      ></rich-text>`);
    }
    updated(changedProperties) {
        if (changedProperties.has('readonly')) {
            this.changeUserSelectAccordToReadOnly();
        }
    }
    #___private__richTextElement_1;
    get _richTextElement() {
        return this.#___private__richTextElement_1;
    }
    set _richTextElement(_v) {
        this.#___private__richTextElement_1 = _v;
    }
    constructor(...args){
        super(...args), this.#___private__richTextElement_1 = (_initProto(this), _init__richTextElement(this, null));
    }
}
_dec1 = query('rich-text');
export class RichTextCellEditing extends BaseCellRenderer {
    static{
        ({ e: [_init__richTextElement1, _initProto1] } = _apply_decs_2203_r(this, [
            [
                _dec1,
                1,
                "_richTextElement"
            ]
        ], []));
    }
    static{
        this.styles = css`
    affine-database-rich-text-cell-editing {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 1px;
      cursor: text;
    }

    .affine-database-rich-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      outline: none;
    }

    .affine-database-rich-text v-line {
      display: flex !important;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .affine-database-rich-text v-line > div {
      flex-grow: 1;
    }
  `;
    }
    get attributeRenderer() {
        return this.inlineManager?.getRenderer();
    }
    get attributesSchema() {
        return this.inlineManager?.getSchema();
    }
    get inlineEditor() {
        assertExists(this._richTextElement);
        const inlineEditor = this._richTextElement.inlineEditor;
        assertExists(inlineEditor);
        return inlineEditor;
    }
    get inlineManager() {
        return this.view.contextGet(HostContextKey)?.std.get(DefaultInlineManagerExtension.identifier);
    }
    get service() {
        return this.view.contextGet(HostContextKey)?.std.getService('affine:database');
    }
    get topContenteditableElement() {
        const databaseBlock = this.closest('affine-database');
        return databaseBlock?.topContenteditableElement;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.value || typeof this.value === 'string') {
            this._initYText(this.value);
        }
        const selectAll = (e)=>{
            if (e.key === 'a' && (IS_MAC ? e.metaKey : e.ctrlKey)) {
                e.stopPropagation();
                e.preventDefault();
                this.inlineEditor.selectAll();
            }
        };
        this.addEventListener('keydown', selectAll);
        this.disposables.addFromEvent(this, 'keydown', selectAll);
    }
    firstUpdated() {
        this._richTextElement?.updateComplete.then(()=>{
            this.disposables.add(this.inlineEditor.slots.keydown.on(this._handleKeyDown));
            this.inlineEditor.focusEnd();
        }).catch(console.error);
    }
    render() {
        if (!this.service) return nothing;
        return html`<rich-text
      .yText=${this.value}
      .inlineEventSource=${this.topContenteditableElement}
      .attributesSchema=${this.attributesSchema}
      .attributeRenderer=${this.attributeRenderer}
      .embedChecker=${this.inlineManager?.embedChecker}
      .markdownShortcutHandler=${this.inlineManager?.markdownShortcutHandler}
      .verticalScrollContainerGetter=${()=>this.topContenteditableElement?.host ? getViewportElement(this.topContenteditableElement.host) : null}
      class="affine-database-rich-text inline-editor"
    ></rich-text>`;
    }
    #___private__richTextElement_2;
    get _richTextElement() {
        return this.#___private__richTextElement_2;
    }
    set _richTextElement(_v) {
        this.#___private__richTextElement_2 = _v;
    }
    constructor(...args){
        super(...args), this._handleKeyDown = (event)=>{
            if (event.key !== 'Escape') {
                if (event.key === 'Tab') {
                    event.preventDefault();
                    return;
                }
                event.stopPropagation();
            }
            if (event.key === 'Enter' && !event.isComposing) {
                if (event.shiftKey) {
                    this._onSoftEnter();
                } else {
                    this.selectCurrentCell(false);
                }
                event.preventDefault();
                return;
            }
            const inlineEditor = this.inlineEditor;
            switch(event.key){
                case 'B':
                case 'b':
                    if (event.metaKey || event.ctrlKey) {
                        event.preventDefault();
                        toggleStyle(this.inlineEditor, {
                            bold: true
                        });
                    }
                    break;
                case 'I':
                case 'i':
                    if (event.metaKey || event.ctrlKey) {
                        event.preventDefault();
                        toggleStyle(this.inlineEditor, {
                            italic: true
                        });
                    }
                    break;
                case 'U':
                case 'u':
                    if (event.metaKey || event.ctrlKey) {
                        event.preventDefault();
                        toggleStyle(this.inlineEditor, {
                            underline: true
                        });
                    }
                    break;
                case 'S':
                case 's':
                    if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
                        event.preventDefault();
                        toggleStyle(inlineEditor, {
                            strike: true
                        });
                    }
                    break;
                case 'E':
                case 'e':
                    if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
                        event.preventDefault();
                        toggleStyle(inlineEditor, {
                            code: true
                        });
                    }
                    break;
                default:
                    break;
            }
        }, this._initYText = (text)=>{
            const yText = new Text(text);
            this.onChange(yText);
        }, this._onSoftEnter = ()=>{
            if (this.value && this.inlineEditor) {
                const inlineRange = this.inlineEditor.getInlineRange();
                assertExists(inlineRange);
                const text = new Text(this.inlineEditor.yText);
                text.replace(inlineRange.index, inlineRange.length, '\n');
                this.inlineEditor.setInlineRange({
                    index: inlineRange.index + 1,
                    length: 0
                });
            }
        }, this.#___private__richTextElement_2 = (_initProto1(this), _init__richTextElement1(this, null));
    }
}
export const richTextColumnConfig = richTextColumnModelConfig.createPropertyMeta({
    icon: createIcon('TextIcon'),
    cellRenderer: {
        view: createFromBaseCellRenderer(RichTextCell),
        edit: createFromBaseCellRenderer(RichTextCellEditing)
    }
});
