import { jsx as _jsx } from "react/jsx-runtime";
import { AffineOtherPageLayout } from '@affine/component/affine-other-page-layout';
import { SignInPageContainer } from '@affine/component/auth-components';
import { AuthService } from '@affine/core/modules/cloud';
import { useLiveData, useService } from '@toeverything/infra';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthPanel } from '../../../components/affine/auth';
import { RouteLogic, useNavigateHelper } from '../../../components/hooks/use-navigate-helper';
export const SignIn = ()=>{
    const session = useService(AuthService).session;
    const status = useLiveData(session.status$);
    const isRevalidating = useLiveData(session.isRevalidating$);
    const navigate = useNavigate();
    const { jumpToIndex } = useNavigateHelper();
    const [searchParams] = useSearchParams();
    const isLoggedIn = status === 'authenticated' && !isRevalidating;
    useEffect(()=>{
        if (isLoggedIn) {
            const redirectUri = searchParams.get('redirect_uri');
            if (redirectUri) {
                navigate(redirectUri, {
                    replace: true
                });
            } else {
                jumpToIndex(RouteLogic.REPLACE, {
                    search: searchParams.toString()
                });
            }
        }
    }, [
        jumpToIndex,
        navigate,
        isLoggedIn,
        searchParams
    ]);
    return _jsx(SignInPageContainer, {
        children: _jsx("div", {
            style: {
                maxWidth: '400px',
                width: '100%'
            },
            children: _jsx(AuthPanel, {
                onSkip: jumpToIndex
            })
        })
    });
};
export const Component = ()=>{
    return _jsx(AffineOtherPageLayout, {
        children: _jsx("div", {
            style: {
                padding: '0 20px'
            },
            children: _jsx(SignIn, {})
        })
    });
};
