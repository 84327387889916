import { getEnableUrlPreviewQuery, setEnableUrlPreviewMutation } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class WorkspaceShareSettingStore extends Store {
    constructor(graphqlService){
        super(), this.graphqlService = graphqlService;
    }
    async fetchWorkspaceEnableUrlPreview(workspaceId, signal) {
        const data = await this.graphqlService.gql({
            query: getEnableUrlPreviewQuery,
            variables: {
                id: workspaceId
            },
            context: {
                signal
            }
        });
        return data.workspace.enableUrlPreview;
    }
    async updateWorkspaceEnableUrlPreview(workspaceId, enableUrlPreview, signal) {
        await this.graphqlService.gql({
            query: setEnableUrlPreviewMutation,
            variables: {
                id: workspaceId,
                enableUrlPreview
            },
            context: {
                signal
            }
        });
    }
}
