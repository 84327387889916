import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { settingHeader } from './share.css';
export const SettingHeader = ({ title, subtitle, ...otherProps })=>{
    return _jsxs("div", {
        className: settingHeader,
        ...otherProps,
        children: [
            _jsx("div", {
                className: "title",
                children: title
            }),
            subtitle ? _jsx("div", {
                className: "subtitle",
                children: subtitle
            }) : null
        ]
    });
};
