import { Service } from '@toeverything/infra';
import { WorkspacePermission } from '../entities/permission';
export class WorkspacePermissionService extends Service {
    constructor(workspaceService, workspacesService, store){
        super(), this.workspaceService = workspaceService, this.workspacesService = workspacesService, this.store = store, this.permission = this.framework.createEntity(WorkspacePermission);
    }
    async leaveWorkspace() {
        await this.store.leaveWorkspace(this.workspaceService.workspace.id, this.workspaceService.workspace.name$.value ?? '');
        this.workspacesService.list.revalidate();
    }
}
