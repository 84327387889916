import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useJournalInfoHelper } from '@affine/core/components/hooks/use-journal';
import { DocDisplayMetaService } from '@affine/core/modules/doc-display-meta';
import { PeekViewService, useInsidePeekView } from '@affine/core/modules/peek-view';
import { WorkbenchLink } from '@affine/core/modules/workbench';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { useLiveData, useService } from '@toeverything/infra';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as styles from './styles.css';
export function AffinePageReference({ pageId, wrapper: Wrapper, params }) {
    const docDisplayMetaService = useService(DocDisplayMetaService);
    const journalHelper = useJournalInfoHelper();
    const t = useI18n();
    let linkWithMode = null;
    let linkToNode = false;
    if (params) {
        const m = params.get('mode');
        if (m && (m === 'page' || m === 'edgeless')) {
            linkWithMode = m;
        }
        linkToNode = params.has('blockIds') || params.has('elementIds');
    }
    const Icon = useLiveData(docDisplayMetaService.icon$(pageId, {
        mode: linkWithMode ?? undefined,
        reference: true,
        referenceToNode: linkToNode
    }));
    const title = useLiveData(docDisplayMetaService.title$(pageId));
    const el = _jsxs(_Fragment, {
        children: [
            _jsx(Icon, {
                className: styles.pageReferenceIcon
            }),
            _jsx("span", {
                className: "affine-reference-title",
                children: typeof title === 'string' ? title : t[title.key]()
            })
        ]
    });
    const ref = useRef(null);
    const [refreshKey, setRefreshKey] = useState(()=>nanoid());
    const peekView = useService(PeekViewService).peekView;
    const isInPeekView = useInsidePeekView();
    const isJournal = journalHelper.isPageJournal(pageId);
    const onClick = useCallback((e)=>{
        if (isJournal) {
            track.doc.editor.pageRef.navigate({
                to: 'journal'
            });
        }
        if (e.shiftKey && ref.current) {
            e.preventDefault();
            e.stopPropagation();
            peekView.open(ref.current).catch(console.error);
        }
        if (isInPeekView) {
            peekView.close();
        }
        setRefreshKey(nanoid());
        return;
    }, [
        isInPeekView,
        isJournal,
        peekView
    ]);
    const query = useMemo(()=>{
        let str = params?.toString() ?? '';
        if (str.length) str += '&';
        str += `refreshKey=${refreshKey}`;
        return '?' + str;
    }, [
        params,
        refreshKey
    ]);
    return _jsx(WorkbenchLink, {
        ref: ref,
        to: `/${pageId}${query}`,
        onClick: onClick,
        className: styles.pageReferenceLink,
        children: Wrapper ? _jsx(Wrapper, {
            children: el
        }) : el
    });
}
export function AffineSharedPageReference({ pageId, docCollection, wrapper: Wrapper, params }) {
    const docDisplayMetaService = useService(DocDisplayMetaService);
    const journalHelper = useJournalInfoHelper();
    const t = useI18n();
    let linkWithMode = null;
    let linkToNode = false;
    if (params) {
        const m = params.get('mode');
        if (m && (m === 'page' || m === 'edgeless')) {
            linkWithMode = m;
        }
        linkToNode = params.has('blockIds') || params.has('elementIds');
    }
    const Icon = useLiveData(docDisplayMetaService.icon$(pageId, {
        mode: linkWithMode ?? undefined,
        reference: true,
        referenceToNode: linkToNode
    }));
    const title = useLiveData(docDisplayMetaService.title$(pageId));
    const el = _jsxs(_Fragment, {
        children: [
            _jsx(Icon, {
                className: styles.pageReferenceIcon
            }),
            _jsx("span", {
                className: "affine-reference-title",
                children: typeof title === 'string' ? title : t[title.key]()
            })
        ]
    });
    const ref = useRef(null);
    const [refreshKey, setRefreshKey] = useState(()=>nanoid());
    const isJournal = journalHelper.isPageJournal(pageId);
    const onClick = useCallback((e)=>{
        if (isJournal) {
            track.doc.editor.pageRef.navigate({
                to: 'journal'
            });
        }
        setRefreshKey(nanoid());
        e.stopPropagation();
        return;
    }, [
        isJournal
    ]);
    const query = useMemo(()=>{
        let str = params?.toString() ?? '';
        if (str.length) str += '&';
        str += `refreshKey=${refreshKey}`;
        return '?' + str;
    }, [
        params,
        refreshKey
    ]);
    return _jsx(Link, {
        ref: ref,
        to: `/workspace/${docCollection.id}/${pageId}${query}`,
        onClick: onClick,
        className: styles.pageReferenceLink,
        children: Wrapper ? _jsx(Wrapper, {
            children: el
        }) : el
    });
}
