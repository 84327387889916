import { getDecoratorState } from './common.js';
import { convertProps } from './convert.js';
import { getDerivedProps, updateDerivedProps } from './derive.js';
export function local() {
    return function localDecorator(_target, context) {
        const prop = context.name;
        return {
            init (v) {
                this._local.set(prop, v);
                return v;
            },
            get () {
                return this._local.get(prop);
            },
            set (originalValue) {
                const isCreating = getDecoratorState(this.surface)?.creating;
                const oldValue = this._local.get(prop);
                const newVal = isCreating ? originalValue : convertProps(prop, originalValue, this);
                const derivedProps = getDerivedProps(prop, originalValue, this);
                this._local.set(prop, newVal);
                if (!isCreating) {
                    updateDerivedProps(derivedProps, this);
                    this._onChange({
                        props: {
                            [prop]: newVal
                        },
                        oldValues: {
                            [prop]: oldValue
                        },
                        local: true
                    });
                    this.surface['hooks'].update.emit({
                        id: this.id,
                        props: {
                            [prop]: newVal
                        },
                        oldValues: {
                            [prop]: oldValue
                        }
                    });
                }
            }
        };
    };
}
