import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuItem, Scrollable, Tooltip } from '@affine/component';
import { useI18n } from '@affine/i18n';
import clsx from 'clsx';
import { useMemo } from 'react';
import * as styles from './multi-select.css';
export const MultiSelect = ({ value, onChange, options })=>{
    const t = useI18n();
    const optionMap = useMemo(()=>Object.fromEntries(options.map((v)=>[
                v.value,
                v
            ])), [
        options
    ]);
    const content = useMemo(()=>value.map((id)=>optionMap[id]?.label).join(', '), [
        optionMap,
        value
    ]);
    const items = useMemo(()=>{
        return _jsxs(Scrollable.Root, {
            children: [
                _jsx(Scrollable.Viewport, {
                    "data-testid": "multi-select",
                    className: styles.optionList,
                    children: options.length === 0 ? _jsx(MenuItem, {
                        checked: true,
                        children: t['com.affine.filter.empty-tag']()
                    }) : options.map((option)=>{
                        const selected = value.includes(option.value);
                        const click = (e)=>{
                            e.stopPropagation();
                            e.preventDefault();
                            if (selected) {
                                onChange(value.filter((v)=>v !== option.value));
                            } else {
                                onChange([
                                    ...value,
                                    option.value
                                ]);
                            }
                        };
                        return _jsx(MenuItem, {
                            "data-testid": `multi-select-${option.label}`,
                            checked: selected,
                            onClick: click,
                            children: option.label
                        }, option.value);
                    })
                }),
                _jsx(Scrollable.Scrollbar, {
                    className: styles.scrollbar
                })
            ]
        });
    }, [
        onChange,
        options,
        t,
        value
    ]);
    return _jsx(Menu, {
        items: items,
        children: _jsx("div", {
            className: styles.content,
            children: _jsx(Tooltip, {
                content: content.length ? content : t['com.affine.filter.empty-tag'](),
                children: value.length ? _jsx("div", {
                    className: styles.text,
                    children: content
                }) : _jsx("div", {
                    className: clsx(styles.text, 'empty'),
                    children: t['com.affine.filter.empty-tag']()
                })
            })
        })
    });
};
