import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSettingHelper } from '@affine/core/components/hooks/affine/use-app-setting-helper';
import { AppSidebarService } from '@affine/core/modules/app-sidebar';
import { DocsService, GlobalContextService, useLiveData, useService } from '@toeverything/infra';
import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { appStyle, mainContainerStyle, toolStyle } from './index.css';
export const AppContainer = ({ useNoisyBackground, useBlurBackground, children, className, ...rest })=>{
    const noisyBackground = BUILD_CONFIG.isElectron && useNoisyBackground;
    const blurBackground = BUILD_CONFIG.isElectron && environment.isMacOs && useBlurBackground;
    return _jsx("div", {
        ...rest,
        className: clsx(appStyle, className, {
            'noisy-background': noisyBackground,
            'blur-background': blurBackground
        }),
        "data-noise-background": noisyBackground,
        "data-blur-background": blurBackground,
        children: children
    });
};
export const MainContainer = forwardRef(function MainContainer({ className, children, ...props }, ref) {
    const { appSettings } = useAppSettingHelper();
    const appSidebarService = useService(AppSidebarService).sidebar;
    const open = useLiveData(appSidebarService.open$);
    return _jsx("div", {
        ...props,
        className: clsx(mainContainerStyle, className),
        "data-is-desktop": BUILD_CONFIG.isElectron,
        "data-transparent": false,
        "data-client-border": appSettings.clientBorder,
        "data-side-bar-open": open,
        "data-testid": "main-container",
        ref: ref,
        children: _jsx("div", {
            className: mainContainerStyle,
            children: children
        })
    });
});
MainContainer.displayName = 'MainContainer';
export const MainContainerFallback = ({ children })=>{
    return _jsx(MainContainer, {
        children: children
    });
};
export const ToolContainer = (props)=>{
    const docId = useLiveData(useService(GlobalContextService).globalContext.docId.$);
    const docRecordList = useService(DocsService).list;
    const doc = useLiveData(docId ? docRecordList.doc$(docId) : undefined);
    const inTrash = useLiveData(doc?.meta$)?.trash;
    return _jsx("div", {
        className: clsx(toolStyle, {
            trash: inTrash
        }, props.className),
        children: props.children
    });
};
