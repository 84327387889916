import { DebugLogger } from '@affine/debug';
import { apis } from '@affine/electron-api';
const logger = new DebugLogger('popup');
const origin = BUILD_CONFIG.isElectron ? BUILD_CONFIG.serverUrlPrefix : location.origin;
export function popupWindow(target) {
    const isFullUrl = /^https?:\/\//.test(target);
    const redirectProxy = origin + '/redirect-proxy';
    target = isFullUrl ? target : origin + target;
    const targetUrl = new URL(target);
    let url;
    if (targetUrl.origin === origin) {
        url = target;
    } else {
        const search = new URLSearchParams({
            redirect_uri: target
        });
        url = `${redirectProxy}?${search.toString()}`;
    }
    if (BUILD_CONFIG.isElectron) {
        apis?.ui.openExternal(url).catch((e)=>{
            logger.error('Failed to open external URL', e);
        });
    } else {
        window.open(url, '_blank', `noreferrer noopener`);
    }
}
