import { Store } from '@toeverything/infra';
export var FeedNodeType;
(function(FeedNodeType) {
    FeedNodeType["Folder"] = "feedFolder";
    FeedNodeType["RSS"] = "feedRss";
    FeedNodeType["Email"] = "feedEmail";
})(FeedNodeType || (FeedNodeType = {}));
export var FeedExplorerType;
(function(FeedExplorerType) {
    FeedExplorerType["Folder"] = "explorer:feeds:folder";
    FeedExplorerType["RSS"] = "explorer:feeds:rss";
})(FeedExplorerType || (FeedExplorerType = {}));
export class FeedNodesStore extends Store {
    constructor(dbService){
        super(), this.dbService = dbService;
    }
    watchNodeInfo(nodeId) {
        return this.dbService.db.feedNodes.get$(nodeId);
    }
    watchNodeChildren(parentId) {
        return this.dbService.db.feedNodes.find$({
            parentId: parentId
        });
    }
    watchIsLoading() {
        return this.dbService.db.feedNodes.isLoading$;
    }
    rssNodeBySource(feedSource) {
        const [feed] = this.dbService.db.feedNodes.find({
            source: feedSource,
            type: "feedRss"
        });
        return feed || null;
    }
    folderById(folderId) {
        const [feed] = this.dbService.db.feedNodes.find({
            id: folderId,
            type: "feedFolder"
        });
        return feed || null;
    }
    feedById(feedId) {
        const [feed] = this.dbService.db.feedNodes.find({
            id: feedId,
            type: "feedRss"
        });
        return feed || null;
    }
    isAncestor(childId, ancestorId) {
        if (childId === ancestorId) {
            return false;
        }
        const history = new Set([
            childId
        ]);
        let current = childId;
        while(current){
            const info = this.dbService.db.feedNodes.get(current);
            if (info === null || !info.parentId) {
                return false;
            }
            current = info.parentId;
            if (history.has(current)) {
                return false;
            }
            history.add(current);
            if (current === ancestorId) {
                return true;
            }
        }
        return false;
    }
    renameNode(nodeId, name) {
        const node = this.dbService.db.feedNodes.get(nodeId);
        if (node === null) {
            throw new Error('Node not found');
        }
        if (node.type !== "feedFolder" && node.type !== "feedRss") {
            throw new Error('Cannot rename non-feed or non-folder node');
        }
        this.dbService.db.feedNodes.update(nodeId, {
            name: name,
            updatedAt: Date.now()
        });
    }
    incrUnreadCount(nodeId, count) {
        const node = this.dbService.db.feedNodes.get(nodeId);
        if (node === null) {
            throw new Error('Node not found');
        }
        this.dbService.db.feedNodes.update(nodeId, {
            unreadCount: Math.max(0, (node.unreadCount || 0) + count),
            updatedAt: Date.now()
        });
    }
    createFolder(parentId, name, index) {
        if (parentId) {
            const parent = this.dbService.db.feedNodes.get(parentId);
            if (parent === null || parent.type !== "feedFolder") {
                throw new Error('Parent folder not found');
            }
        }
        return this.dbService.db.feedNodes.create({
            parentId: parentId,
            type: "feedFolder",
            name: name,
            index: index,
            createdAt: Date.now()
        }).id;
    }
    createRSS(parentId, feedId, name, source, description, icon, index) {
        if (parentId) {
            const parent = this.dbService.db.feedNodes.get(parentId);
            if (parent === null || parent.type !== "feedFolder") {
                throw new Error('Parent folder not found');
            }
        }
        return this.dbService.db.feedNodes.create({
            parentId: parentId,
            type: "feedRss",
            name: name,
            source: source,
            description: description ?? undefined,
            icon: icon ?? undefined,
            index: index,
            createdAt: Date.now(),
            id: feedId,
            unreadCount: 0
        }).id;
    }
    removeFolder(folderId) {
        const info = this.dbService.db.feedNodes.get(folderId);
        if (info === null || info.type !== "feedFolder") {
            throw new Error('Folder not found');
        }
        const stack = [
            info
        ];
        while(stack.length > 0){
            const current = stack.pop();
            if (!current) {
                continue;
            }
            if (current.type !== "feedFolder") {
                this.dbService.db.feedNodes.delete(current.id);
            } else {
                const children = this.dbService.db.feedNodes.find({
                    parentId: current.id
                });
                stack.push(...children);
                this.dbService.db.feedNodes.delete(current.id);
            }
        }
    }
    removeFeed(feedId) {
        const feed = this.dbService.db.feedNodes.get(feedId);
        if (feed === null || feed.type !== "feedRss") {
            throw new Error('Feed not found');
        }
        this.dbService.db.feedNodes.delete(feedId);
    }
    moveNode(nodeId, parentId, index) {
        const node = this.dbService.db.feedNodes.get(nodeId);
        if (node === null) {
            throw new Error('Node not found');
        }
        if (parentId) {
            if (nodeId === parentId) {
                throw new Error('Cannot move a node to itself');
            }
            if (this.isAncestor(parentId, nodeId)) {
                throw new Error('Cannot move a node to its descendant');
            }
            const parent = this.dbService.db.feedNodes.get(parentId);
            if (parent === null || parent.type !== "feedFolder") {
                throw new Error('Parent folder not found');
            }
        } else {
            if (node.type !== "feedFolder" && node.type !== "feedRss") {
                throw new Error('Root node can only have folders');
            }
        }
        this.dbService.db.feedNodes.update(nodeId, {
            parentId,
            index,
            updatedAt: Date.now()
        });
    }
}
