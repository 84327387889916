import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cssVar } from '@toeverything/theme';
import { cssVarV2 } from '@toeverything/theme/v2';
import clsx from 'clsx';
import { getCommand } from '../../utils/keyboard-mapping';
import * as styles from './styles.css';
const TooltipShortcut = ({ shortcut })=>{
    const commands = (Array.isArray(shortcut) ? shortcut : [
        shortcut
    ]).map((cmd)=>cmd.trim()).map((cmd)=>getCommand(cmd));
    return _jsx("div", {
        className: styles.shortcut,
        children: commands.map((cmd, index)=>_jsx("div", {
                className: styles.command,
                "data-length": cmd.length,
                children: cmd
            }, `${index}-${cmd}`))
    });
};
export const Tooltip = ({ children, content, side = 'top', align = 'center', shortcut, options, rootOptions, portalOptions })=>{
    if (!content) {
        return children;
    }
    const { className, ...contentOptions } = options || {};
    const { style: contentStyle, ...restContentOptions } = contentOptions;
    return _jsx(TooltipPrimitive.Provider, {
        children: _jsxs(TooltipPrimitive.Root, {
            delayDuration: 500,
            ...rootOptions,
            children: [
                _jsx(TooltipPrimitive.Trigger, {
                    asChild: true,
                    children: children
                }),
                _jsx(TooltipPrimitive.Portal, {
                    ...portalOptions,
                    children: _jsxs(TooltipPrimitive.Content, {
                        className: clsx(styles.tooltipContent, className),
                        side: side,
                        align: align,
                        sideOffset: 5,
                        style: {
                            zIndex: cssVar('zIndexPopover'),
                            ...contentStyle
                        },
                        ...restContentOptions,
                        children: [
                            shortcut ? _jsxs("div", {
                                className: styles.withShortcut,
                                children: [
                                    _jsx("div", {
                                        className: styles.withShortcutContent,
                                        children: content
                                    }),
                                    _jsx(TooltipShortcut, {
                                        shortcut: shortcut
                                    })
                                ]
                            }) : content,
                            _jsx(TooltipPrimitive.Arrow, {
                                asChild: true,
                                children: _jsx("svg", {
                                    width: "10",
                                    height: "6",
                                    viewBox: "0 0 10 6",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    children: _jsx("path", {
                                        d: "M4.11111 5.55C4.55556 6.15 5.44445 6.15 5.88889 5.55L10 0H0L4.11111 5.55Z",
                                        fill: cssVarV2('tooltips/background')
                                    })
                                })
                            })
                        ]
                    })
                })
            ]
        })
    });
};
