import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class UserCopilotQuota extends Entity {
    constructor(authService, store, serverConfigService){
        super(), this.authService = authService, this.store = store, this.serverConfigService = serverConfigService, this.copilotActionLimit$ = new LiveData(null), this.copilotActionUsed$ = new LiveData(null), this.isRevalidating$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(map(()=>({
                accountId: this.authService.session.account$.value?.id
            })), exhaustMapSwitchUntilChanged((a, b)=>a.accountId === b.accountId, ({ accountId })=>fromPromise(async (signal)=>{
                if (!accountId) {
                    return;
                }
                const serverConfig = await this.serverConfigService.serverConfig.features$.waitForNonNull(signal);
                let aiQuota = null;
                if (serverConfig.copilot) {
                    aiQuota = await this.store.fetchUserCopilotQuota(signal);
                }
                return aiQuota;
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((data)=>{
                if (data) {
                    const { limit, used } = data;
                    this.copilotActionUsed$.next(used);
                    this.copilotActionLimit$.next(limit === null ? 'unlimited' : limit);
                } else {
                    this.copilotActionUsed$.next(null);
                    this.copilotActionLimit$.next(null);
                }
                return EMPTY;
            }), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false))), ()=>{
            this.reset();
        }));
    }
    reset() {
        this.copilotActionUsed$.next(null);
        this.copilotActionLimit$.next(null);
        this.error$.next(null);
        this.isRevalidating$.next(false);
    }
    dispose() {
        this.revalidate.unsubscribe();
    }
}
