import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker, Popover } from '@affine/component';
import { useI18n } from '@affine/i18n';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { datePickerTriggerInput } from './date-select.css';
const datePickerPopperContentOptions = {
    style: {
        padding: 20,
        marginTop: 10
    }
};
export const DateSelect = ({ value, onChange })=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const onDateChange = useCallback((e)=>{
        setOpen(false);
        onChange(dayjs(e, 'YYYY-MM-DD').valueOf());
    }, [
        onChange
    ]);
    return _jsx(Popover, {
        open: open,
        onOpenChange: setOpen,
        contentOptions: datePickerPopperContentOptions,
        content: _jsx(DatePicker, {
            weekDays: t['com.affine.calendar-date-picker.week-days'](),
            monthNames: t['com.affine.calendar-date-picker.month-names'](),
            todayLabel: t['com.affine.calendar-date-picker.today'](),
            value: dayjs(value).format('YYYY-MM-DD'),
            onChange: onDateChange
        }),
        children: _jsx("input", {
            value: dayjs(value).format('MMM DD'),
            className: datePickerTriggerInput
        })
    });
};
