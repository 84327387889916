import { Observable } from 'rxjs';
export class LocalStorageMemento {
    constructor(prefix){
        this.prefix = prefix;
    }
    keys() {
        const keys = [];
        for(let i = 0; i < localStorage.length; i++){
            const key = localStorage.key(i);
            if (key && key.startsWith(this.prefix)) {
                keys.push(key.slice(this.prefix.length));
            }
        }
        return keys;
    }
    get(key) {
        const json = localStorage.getItem(this.prefix + key);
        return json ? JSON.parse(json) : undefined;
    }
    watch(key) {
        return new Observable((subscriber)=>{
            const json = localStorage.getItem(this.prefix + key);
            const first = json ? JSON.parse(json) : undefined;
            subscriber.next(first);
            const channel = new BroadcastChannel(this.prefix + key);
            channel.addEventListener('message', (event)=>{
                subscriber.next(event.data);
            });
            return ()=>{
                channel.close();
            };
        });
    }
    set(key, value) {
        localStorage.setItem(this.prefix + key, JSON.stringify(value));
        const channel = new BroadcastChannel(this.prefix + key);
        channel.postMessage(value);
        channel.close();
    }
    del(key) {
        localStorage.removeItem(this.prefix + key);
    }
    clear() {
        for (const key of this.keys()){
            this.del(key);
        }
    }
}
export class LocalStorageGlobalCache extends LocalStorageMemento {
    constructor(){
        super('global-cache:');
    }
}
export class LocalStorageGlobalState extends LocalStorageMemento {
    constructor(){
        super('global-state:');
    }
}
