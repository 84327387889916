import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@affine/component/ui/button';
import { Tooltip } from '@affine/component/ui/tooltip';
import { generateSubscriptionCallbackLink } from '@affine/core/components/hooks/affine/use-subscription-notify';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { AuthService, SubscriptionService } from '@affine/core/modules/cloud';
import { popupWindow } from '@affine/core/utils';
import { SubscriptionPlan, SubscriptionStatus } from '@affine/graphql';
import { Trans, useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { DoneIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authAtom } from '../../../../atoms/index';
import { CancelAction, ResumeAction } from './actions';
import { ConfirmLoadingModal } from './modals';
import * as styles from './style.css';
export const PlanCard = (props)=>{
    const { detail, recurring } = props;
    const loggedIn = useLiveData(useService(AuthService).session.status$) === 'authenticated';
    const subscriptionService = useService(SubscriptionService);
    const proSubscription = useLiveData(subscriptionService.subscription.pro$);
    const currentPlan = proSubscription?.plan ?? SubscriptionPlan.Free;
    const isCurrent = loggedIn && detail.plan === currentPlan && recurring === proSubscription?.recurring;
    const isPro = detail.plan === SubscriptionPlan.Pro;
    return _jsxs("div", {
        "data-current": isCurrent,
        className: isPro ? styles.proPlanCard : styles.planCard,
        children: [
            _jsx("div", {
                className: styles.planCardBorderMock
            }),
            _jsxs("div", {
                className: styles.planTitle,
                children: [
                    _jsxs("div", {
                        style: {
                            paddingBottom: 12
                        },
                        children: [
                            _jsx("section", {
                                className: styles.planTitleName,
                                children: detail.name
                            }),
                            _jsx("section", {
                                className: styles.planTitleDescription,
                                children: detail.description
                            }),
                            _jsx("section", {
                                className: styles.planTitleTitle,
                                children: detail.titleRenderer(recurring, detail)
                            })
                        ]
                    }),
                    _jsx(ActionButton, {
                        ...props
                    })
                ]
            }),
            _jsx("div", {
                className: styles.planBenefits,
                children: Object.entries(detail.benefits).map(([groupName, benefitList])=>{
                    return _jsxs("ul", {
                        className: styles.planBenefitGroup,
                        children: [
                            _jsxs("section", {
                                className: styles.planBenefitGroupTitle,
                                children: [
                                    groupName,
                                    ":"
                                ]
                            }),
                            benefitList.map(({ icon, title }, index)=>{
                                return _jsxs("li", {
                                    className: styles.planBenefit,
                                    children: [
                                        _jsx("div", {
                                            className: styles.planBenefitIcon,
                                            children: icon ?? _jsx(DoneIcon, {})
                                        }),
                                        _jsx("div", {
                                            className: styles.planBenefitText,
                                            children: title
                                        })
                                    ]
                                }, index);
                            })
                        ]
                    }, groupName);
                })
            })
        ]
    }, detail.plan);
};
const ActionButton = ({ detail, recurring })=>{
    const t = useI18n();
    const loggedIn = useLiveData(useService(AuthService).session.status$) === 'authenticated';
    const subscriptionService = useService(SubscriptionService);
    const isBeliever = useLiveData(subscriptionService.subscription.isBeliever$);
    const primarySubscription = useLiveData(subscriptionService.subscription.pro$);
    const currentPlan = primarySubscription?.plan ?? SubscriptionPlan.Free;
    const currentRecurring = primarySubscription?.recurring;
    if (detail.type === 'dynamic') {
        return _jsx(BookDemo, {
            plan: detail.plan
        });
    }
    if (!loggedIn) {
        return _jsx(SignUpAction, {
            children: detail.plan === SubscriptionPlan.Free ? t['com.affine.payment.sign-up-free']() : t['com.affine.payment.buy-pro']()
        });
    }
    if (isBeliever) {
        return _jsx(Button, {
            className: styles.planAction,
            disabled: true,
            children: t['com.affine.payment.cloud.lifetime.included']()
        });
    }
    const isCanceled = !!primarySubscription?.canceledAt;
    const isFree = detail.plan === SubscriptionPlan.Free;
    const isCurrent = detail.plan === currentPlan && (isFree ? true : currentRecurring === recurring && primarySubscription?.status === SubscriptionStatus.Active);
    if (isCurrent) {
        return isCanceled ? _jsx(ResumeButton, {}) : _jsx(CurrentPlan, {});
    }
    if (isFree) {
        return _jsx(Downgrade, {
            disabled: isCanceled
        });
    }
    return currentPlan === detail.plan ? _jsx(ChangeRecurring, {
        from: currentRecurring,
        to: recurring,
        due: primarySubscription?.nextBillAt || '',
        disabled: isCanceled
    }) : _jsx(Upgrade, {
        recurring: recurring
    });
};
const CurrentPlan = ()=>{
    const t = useI18n();
    return _jsx(Button, {
        className: styles.planAction,
        children: t['com.affine.payment.current-plan']()
    });
};
const Downgrade = ({ disabled })=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const tooltipContent = disabled ? t['com.affine.payment.downgraded-tooltip']() : null;
    const handleClick = useCallback(()=>{
        setOpen(true);
    }, []);
    return _jsx(CancelAction, {
        open: open,
        onOpenChange: setOpen,
        children: _jsx(Tooltip, {
            content: tooltipContent,
            rootOptions: {
                delayDuration: 0
            },
            children: _jsx("div", {
                className: styles.planAction,
                children: _jsx(Button, {
                    className: styles.planAction,
                    variant: "primary",
                    onClick: handleClick,
                    disabled: disabled,
                    children: t['com.affine.payment.downgrade']()
                })
            })
        })
    });
};
const BookDemo = ({ plan })=>{
    const t = useI18n();
    const url = useMemo(()=>{
        switch(plan){
            case SubscriptionPlan.Team:
                return 'https://6dxre9ihosp.typeform.com/to/niBcdkvs';
            case SubscriptionPlan.Enterprise:
                return 'https://6dxre9ihosp.typeform.com/to/rFfobTjf';
            default:
                return 'https://wemem.ai/pricing';
        }
    }, [
        plan
    ]);
    return _jsx("a", {
        className: styles.planAction,
        href: url,
        target: "_blank",
        rel: "noreferrer",
        children: _jsx(Button, {
            className: styles.planAction,
            variant: "primary",
            "data-event-props": "$.settingsPanel.billing.bookDemo",
            "data-event-args-url": url,
            children: t['com.affine.payment.tell-us-use-case']()
        })
    });
};
export const Upgrade = ({ className, recurring, children, ...btnProps })=>{
    const [isMutating, setMutating] = useState(false);
    const [isOpenedExternalWindow, setOpenedExternalWindow] = useState(false);
    const t = useI18n();
    const subscriptionService = useService(SubscriptionService);
    const authService = useService(AuthService);
    const [idempotencyKey, setIdempotencyKey] = useState(nanoid());
    useEffect(()=>{
        if (isOpenedExternalWindow) {
            window.addEventListener('focus', subscriptionService.subscription.revalidate);
            return ()=>{
                window.removeEventListener('focus', subscriptionService.subscription.revalidate);
            };
        }
        return;
    }, [
        isOpenedExternalWindow,
        subscriptionService
    ]);
    const upgrade = useAsyncCallback(async ()=>{
        setMutating(true);
        track.$.settingsPanel.plans.checkout({
            plan: SubscriptionPlan.Pro,
            recurring: recurring
        });
        const link = await subscriptionService.createCheckoutSession({
            recurring,
            idempotencyKey,
            plan: SubscriptionPlan.Pro,
            coupon: null,
            successCallbackLink: generateSubscriptionCallbackLink(authService.session.account$.value, SubscriptionPlan.Pro, recurring)
        });
        setMutating(false);
        setIdempotencyKey(nanoid());
        popupWindow(link);
        setOpenedExternalWindow(true);
    }, [
        recurring,
        authService.session.account$.value,
        subscriptionService,
        idempotencyKey
    ]);
    return _jsx(Button, {
        className: clsx(styles.planAction, className),
        variant: "primary",
        onClick: upgrade,
        disabled: isMutating,
        loading: isMutating,
        ...btnProps,
        children: children ?? t['com.affine.payment.upgrade']()
    });
};
const ChangeRecurring = ({ from, to, disabled, due })=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const [isMutating, setIsMutating] = useState(false);
    const [idempotencyKey, setIdempotencyKey] = useState(nanoid());
    const subscription = useService(SubscriptionService).subscription;
    const onStartChange = useCallback(()=>{
        track.$.settingsPanel.plans.changeSubscriptionRecurring({
            plan: SubscriptionPlan.Pro,
            recurring: to
        });
        setOpen(true);
    }, [
        to
    ]);
    const change = useAsyncCallback(async ()=>{
        setIsMutating(true);
        await subscription.setSubscriptionRecurring(idempotencyKey, to);
        setIdempotencyKey(nanoid());
        setIsMutating(false);
    }, [
        subscription,
        to,
        idempotencyKey
    ]);
    const changeCurringContent = _jsxs(Trans, {
        values: {
            from,
            to,
            due
        },
        className: styles.downgradeContent,
        children: [
            "You are changing your ",
            _jsx("span", {
                className: styles.textEmphasis,
                children: from
            }),
            ' ',
            "subscription to ",
            _jsx("span", {
                className: styles.textEmphasis,
                children: to
            }),
            ' ',
            "subscription. This change will take effect in the next billing cycle, with an effective date of",
            ' ',
            _jsx("span", {
                className: styles.textEmphasis,
                children: new Date(due).toLocaleDateString()
            }),
            "."
        ]
    });
    return _jsxs(_Fragment, {
        children: [
            _jsx(Button, {
                className: styles.planAction,
                variant: "primary",
                onClick: onStartChange,
                disabled: disabled || isMutating,
                loading: isMutating,
                children: t['com.affine.payment.change-to']({
                    to
                })
            }),
            _jsx(ConfirmLoadingModal, {
                type: 'change',
                loading: isMutating,
                open: open,
                onConfirm: change,
                onOpenChange: setOpen,
                content: changeCurringContent
            })
        ]
    });
};
const SignUpAction = ({ children })=>{
    const setOpen = useSetAtom(authAtom);
    const onClickSignIn = useCallback(()=>{
        setOpen((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpen
    ]);
    return _jsx(Button, {
        onClick: onClickSignIn,
        className: styles.planAction,
        variant: "primary",
        children: children
    });
};
const ResumeButton = ()=>{
    const t = useI18n();
    const [open, setOpen] = useState(false);
    const subscription = useService(SubscriptionService).subscription;
    const handleClick = useCallback(()=>{
        setOpen(true);
        const pro = subscription.pro$.value;
        if (pro) {
            track.$.settingsPanel.plans.resumeSubscription({
                plan: SubscriptionPlan.Pro,
                recurring: pro.recurring
            });
        }
    }, [
        subscription.pro$.value
    ]);
    return _jsx(ResumeAction, {
        open: open,
        onOpenChange: setOpen,
        children: _jsxs(Button, {
            className: styles.resumeAction,
            onClick: handleClick,
            children: [
                _jsx("span", {
                    "data-show-hover": "true",
                    className: clsx(styles.resumeContent),
                    children: t['com.affine.payment.resume-renewal']()
                }),
                _jsx("span", {
                    "data-show-hover": "false",
                    className: clsx(styles.resumeContent),
                    children: t['com.affine.payment.current-plan']()
                })
            ]
        })
    });
};
