import { jsx as _jsx } from "react/jsx-runtime";
import { IconButton } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { CloseIcon, ExpandFullIcon, InformationIcon, OpenInNewIcon, SplitViewIcon } from '@blocksuite/icons/rc';
import { useService } from '@toeverything/infra';
import { clsx } from 'clsx';
import { useCallback, useMemo } from 'react';
import { DocInfoService } from '../../doc-info';
import { WorkbenchService } from '../../workbench';
import { PeekViewService } from '../services/peek-view';
import * as styles from './peek-view-controls.css';
export const ControlButton = ({ icon, nameKey, name, onClick })=>{
    const handleClick = useCallback((e)=>{
        e.stopPropagation();
        e.preventDefault();
        onClick();
    }, [
        onClick
    ]);
    return _jsx(IconButton, {
        variant: "solid",
        tooltip: name,
        "data-testid": "peek-view-control",
        "data-action-name": nameKey,
        size: "20",
        onClick: handleClick,
        icon: icon,
        className: styles.button
    });
};
export const DefaultPeekViewControls = ({ className, ...rest })=>{
    const peekView = useService(PeekViewService).peekView;
    const t = useI18n();
    const controls = useMemo(()=>{
        return [
            {
                icon: _jsx(CloseIcon, {}),
                nameKey: 'close',
                name: t['com.affine.peek-view-controls.close'](),
                onClick: ()=>peekView.close()
            }
        ].filter((opt)=>Boolean(opt));
    }, [
        peekView,
        t
    ]);
    return _jsx("div", {
        ...rest,
        className: clsx(styles.root, className),
        children: controls.map((option)=>_jsx(ControlButton, {
                ...option
            }, option.nameKey))
    });
};
export const DocPeekViewControls = ({ docId, mode, blockIds, elementIds, className, ...rest })=>{
    const peekView = useService(PeekViewService).peekView;
    const workbench = useService(WorkbenchService).workbench;
    const t = useI18n();
    const docInfoService = useService(DocInfoService);
    const controls = useMemo(()=>{
        return [
            {
                icon: _jsx(CloseIcon, {}),
                nameKey: 'close',
                name: t['com.affine.peek-view-controls.close'](),
                onClick: ()=>peekView.close()
            },
            {
                icon: _jsx(ExpandFullIcon, {}),
                name: t['com.affine.peek-view-controls.open-doc'](),
                nameKey: 'open',
                onClick: ()=>{
                    workbench.openDoc({
                        docId,
                        mode,
                        blockIds,
                        elementIds
                    });
                    peekView.close('none');
                }
            },
            {
                icon: _jsx(OpenInNewIcon, {}),
                nameKey: 'new-tab',
                name: t['com.affine.peek-view-controls.open-doc-in-new-tab'](),
                onClick: ()=>{
                    workbench.openDoc({
                        docId,
                        mode,
                        blockIds,
                        elementIds
                    }, {
                        at: 'new-tab'
                    });
                    peekView.close('none');
                }
            },
            BUILD_CONFIG.isElectron && {
                icon: _jsx(SplitViewIcon, {}),
                nameKey: 'split-view',
                name: t['com.affine.peek-view-controls.open-doc-in-split-view'](),
                onClick: ()=>{
                    workbench.openDoc({
                        docId,
                        mode
                    }, {
                        at: 'beside'
                    });
                    peekView.close('none');
                }
            },
            {
                icon: _jsx(InformationIcon, {}),
                nameKey: 'info',
                name: t['com.affine.peek-view-controls.open-info'](),
                onClick: ()=>{
                    docInfoService.modal.open(docId);
                }
            }
        ].filter((opt)=>Boolean(opt));
    }, [
        t,
        peekView,
        workbench,
        docId,
        mode,
        blockIds,
        elementIds,
        docInfoService.modal
    ]);
    return _jsx("div", {
        ...rest,
        className: clsx(styles.root, className),
        children: controls.map((option)=>_jsx(ControlButton, {
                ...option
            }, option.nameKey))
    });
};
