function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _init__remoteCursors, _init__remoteRects, _initProto;
import { RemoteCursor } from '@blocksuite/affine-components/icons';
import { requestThrottledConnectedFrame } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { assertExists, pickValues } from '@blocksuite/global/utils';
import { css, html } from 'lit';
import { state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getSelectedRect, isTopLevelBlock } from '../../../root-block/edgeless/utils/query.js';
import { RemoteColorManager } from '../../../root-block/remote-color-manager/remote-color-manager.js';
export const AFFINE_EDGELESS_REMOTE_SELECTION_WIDGET = 'affine-edgeless-remote-selection-widget';
_dec = state(), _dec1 = state();
export class EdgelessRemoteSelectionWidget extends WidgetComponent {
    static{
        ({ e: [_init__remoteCursors, _init__remoteRects, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_remoteCursors"
            ],
            [
                _dec1,
                1,
                "_remoteRects"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: left top;
      contain: size layout;
      z-index: 1;
    }

    .remote-rect {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      box-sizing: border-box;
      border-width: 3px;
      z-index: 1;
      transform-origin: center center;
    }

    .remote-cursor {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      z-index: 1;
    }

    .remote-cursor > svg {
      display: block;
    }

    .remote-username {
      margin-left: 22px;
      margin-top: -2px;

      color: white;

      max-width: 160px;
      padding: 0px 3px;
      border: 1px solid var(--affine-pure-black-20);

      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
      border-radius: 4px;

      font-size: 12px;
      line-height: 18px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;
    }
    get edgeless() {
        return this.block;
    }
    get selection() {
        return this.edgeless.service.selection;
    }
    get surface() {
        return this.edgeless.surface;
    }
    connectedCallback() {
        super.connectedCallback();
        const { _disposables, doc, edgeless } = this;
        pickValues(edgeless.service.surface, [
            'elementAdded',
            'elementRemoved',
            'elementUpdated'
        ]).forEach((slot)=>{
            _disposables.add(slot.on(this._updateOnElementChange));
        });
        _disposables.add(doc.slots.blockUpdated.on(this._updateOnElementChange));
        _disposables.add(this.selection.slots.remoteUpdated.on(this._updateRemoteRects));
        _disposables.add(this.selection.slots.remoteCursorUpdated.on(this._updateRemoteCursor));
        _disposables.add(edgeless.service.viewport.viewportUpdated.on(()=>{
            this._updateTransform();
        }));
        this._updateTransform();
        this._updateRemoteRects();
        this._remoteColorManager = new RemoteColorManager(this.std);
    }
    render() {
        const { _remoteRects, _remoteCursors, _remoteColorManager } = this;
        assertExists(_remoteColorManager);
        const { zoom } = this.edgeless.service.viewport;
        const rects = repeat(_remoteRects.entries(), (value)=>value[0], ([id, rect])=>html`<div
          data-client-id=${id}
          class="remote-rect"
          style=${styleMap({
                pointerEvents: 'none',
                width: `${zoom * rect.width}px`,
                height: `${zoom * rect.height}px`,
                borderStyle: rect.borderStyle,
                borderColor: _remoteColorManager.get(id),
                transform: `translate(${zoom * rect.left}px, ${zoom * rect.top}px) rotate(${rect.rotate}deg)`
            })}
        ></div>`);
        const cursors = repeat(_remoteCursors.entries(), (value)=>value[0], ([id, cursor])=>{
            return html`<div
          data-client-id=${id}
          class="remote-cursor"
          style=${styleMap({
                pointerEvents: 'none',
                transform: `translate(${zoom * cursor.x}px, ${zoom * cursor.y}px)`,
                color: _remoteColorManager.get(id)
            })}
        >
          ${RemoteCursor}
          <div
            class="remote-username"
            style=${styleMap({
                backgroundColor: _remoteColorManager.get(id)
            })}
          >
            ${cursor.user?.name ?? 'Unknown'}
          </div>
        </div>`;
        });
        return html`
      <div class="affine-edgeless-remote-selection">${rects}${cursors}</div>
    `;
    }
    #___private__remoteCursors_1;
    get _remoteCursors() {
        return this.#___private__remoteCursors_1;
    }
    set _remoteCursors(_v) {
        this.#___private__remoteCursors_1 = _v;
    }
    #___private__remoteRects_2;
    get _remoteRects() {
        return this.#___private__remoteRects_2;
    }
    set _remoteRects(_v) {
        this.#___private__remoteRects_2 = _v;
    }
    constructor(...args){
        super(...args), this._remoteColorManager = null, this._updateOnElementChange = (element)=>{
            const id = typeof element === 'string' ? element : element.id;
            if (this.isConnected && this.selection.hasRemote(id)) this._updateRemoteRects();
        }, this._updateRemoteCursor = ()=>{
            const remoteCursors = new Map();
            const status = this.doc.awarenessStore.getStates();
            this.selection.remoteCursorSelectionMap.forEach((cursorSelection, clientId)=>{
                remoteCursors.set(clientId, {
                    x: cursorSelection.x,
                    y: cursorSelection.y,
                    user: status.get(clientId)?.user
                });
            });
            this._remoteCursors = remoteCursors;
        }, this._updateRemoteRects = ()=>{
            const { selection, block } = this;
            const remoteSelectionsMap = selection.remoteSurfaceSelectionsMap;
            const remoteRects = new Map();
            remoteSelectionsMap.forEach((selections, clientId)=>{
                selections.forEach((selection)=>{
                    if (selection.elements.length === 0) return;
                    const elements = selection.elements.map((id)=>block.service.getElementById(id)).filter((element)=>element);
                    const rect = getSelectedRect(elements);
                    if (rect.width === 0 || rect.height === 0) return;
                    const { left, top } = rect;
                    const [width, height] = [
                        rect.width,
                        rect.height
                    ];
                    let rotate = 0;
                    if (elements.length === 1) {
                        const element = elements[0];
                        if (!isTopLevelBlock(element)) {
                            rotate = element.rotate ?? 0;
                        }
                    }
                    remoteRects.set(clientId, {
                        width,
                        height,
                        borderStyle: 'solid',
                        left,
                        top,
                        rotate
                    });
                });
            });
            this._remoteRects = remoteRects;
        }, this._updateTransform = requestThrottledConnectedFrame(()=>{
            const { translateX, translateY } = this.edgeless.service.viewport;
            this.style.setProperty('transform', `translate(${translateX}px, ${translateY}px)`);
            this.requestUpdate();
        }, this), this.#___private__remoteCursors_1 = (_initProto(this), _init__remoteCursors(this, new Map())), this.#___private__remoteRects_2 = _init__remoteRects(this, new Map());
    }
}
