import { FeatureType } from '@affine/graphql';
import { backoffRetry, catchErrorInto, effect, Entity, exhaustMapSwitchUntilChanged, fromPromise, LiveData, onComplete, onStart } from '@toeverything/infra';
import { EMPTY, map, mergeMap } from 'rxjs';
import { isBackendError, isNetworkError } from '../error';
export class UserFeature extends Entity {
    constructor(authService, store){
        super(), this.authService = authService, this.store = store, this.features$ = new LiveData(null), this.isEarlyAccess$ = this.features$.map((features)=>features === null ? null : features?.some((f)=>f === FeatureType.EarlyAccess)), this.isRevalidating$ = new LiveData(false), this.error$ = new LiveData(null), this.revalidate = effect(map(()=>({
                accountId: this.authService.session.account$.value?.id
            })), exhaustMapSwitchUntilChanged((a, b)=>a.accountId === b.accountId, ({ accountId })=>{
            return fromPromise(async (signal)=>{
                if (!accountId) {
                    return;
                }
                const { userId, features } = await this.store.getUserFeatures(signal);
                if (userId !== accountId) {
                    this.authService.session.revalidate();
                    await this.authService.session.waitForRevalidation();
                    return;
                }
                return {
                    userId: userId,
                    features: features
                };
            }).pipe(backoffRetry({
                when: isNetworkError,
                count: Infinity
            }), backoffRetry({
                when: isBackendError
            }), mergeMap((data)=>{
                if (data) {
                    this.features$.next(data.features);
                } else {
                    this.features$.next(null);
                }
                return EMPTY;
            }), catchErrorInto(this.error$), onStart(()=>this.isRevalidating$.next(true)), onComplete(()=>this.isRevalidating$.next(false)));
        }, ()=>{
            this.reset();
        }));
    }
    reset() {
        this.features$.next(null);
        this.error$.next(null);
        this.isRevalidating$.next(false);
    }
}
