import { blockComponentSymbol, GfxElementSymbol, toGfxBlockComponent } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
export function toEdgelessEmbedBlock(block) {
    return class extends toGfxBlockComponent(block) {
        get bound() {
            return Bound.deserialize(this.model.xywh);
        }
        get rootService() {
            return this.std.getService('affine:page');
        }
        _handleClick(_) {
            return;
        }
        connectedCallback() {
            super.connectedCallback();
            const rootService = this.rootService;
            this._disposables.add(rootService.slots.elementResizeStart.on(()=>{
                this._isResizing = true;
                this._showOverlay = true;
            }));
            this._disposables.add(rootService.slots.elementResizeEnd.on(()=>{
                this._isResizing = false;
                this._showOverlay = this._isResizing || this._isDragging || !this._isSelected;
            }));
        }
        renderGfxBlock() {
            const bound = Bound.deserialize(this.model.xywh);
            this.embedContainerStyle.width = `${bound.w}px`;
            this.embedContainerStyle.height = `${bound.h}px`;
            this.blockContainerStyles = {
                width: `${bound.w}px`
            };
            this._scale = bound.w / this._cardWidth;
            return this.renderPageContent();
        }
        #___private_blockContainerStyles_1;
        get blockContainerStyles() {
            return this.#___private_blockContainerStyles_1;
        }
        set blockContainerStyles(_v) {
            this.#___private_blockContainerStyles_1 = _v;
        }
        constructor(...args){
            super(...args), this._isDragging = false, this._isResizing = false, this._isSelected = false, this._showOverlay = false, this[blockComponentSymbol] = true, this.embedContainerStyle = {}, this[GfxElementSymbol] = true, this.#___private_blockContainerStyles_1 = undefined;
        }
    };
}
