import { getIsOwnerQuery, leaveWorkspaceMutation } from '@affine/graphql';
import { Store } from '@toeverything/infra';
export class WorkspacePermissionStore extends Store {
    constructor(graphqlService){
        super(), this.graphqlService = graphqlService;
    }
    async fetchIsOwner(workspaceId, signal) {
        const isOwner = await this.graphqlService.gql({
            query: getIsOwnerQuery,
            variables: {
                workspaceId
            },
            context: {
                signal
            }
        });
        return isOwner.isOwner;
    }
    async leaveWorkspace(workspaceId, workspaceName) {
        await this.graphqlService.gql({
            query: leaveWorkspaceMutation,
            variables: {
                workspaceId,
                workspaceName
            }
        });
    }
}
