export const CONSTRUCTOR_CONTEXT = {
    current: {}
};
export function withContext(cb, context) {
    const pre = CONSTRUCTOR_CONTEXT.current;
    try {
        CONSTRUCTOR_CONTEXT.current = context;
        return cb();
    } finally{
        CONSTRUCTOR_CONTEXT.current = pre;
    }
}
