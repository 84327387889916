export function itemsToItemGroups(items, groupDefs) {
    if (!groupDefs) {
        return [
            {
                id: 'all',
                items: items,
                allItems: items
            }
        ];
    }
    let groups = groupDefs.map((groupDef)=>({
            id: groupDef.id,
            label: undefined,
            items: [],
            allItems: items
        }));
    for (const item of items){
        const matchedGroups = groupDefs.filter((def)=>def.match(item));
        for (const groupDef of matchedGroups){
            const group = groups.find((g)=>g.id === groupDef.id);
            if (group) {
                group.items.push(item);
            }
        }
    }
    groups = groups.map((group)=>{
        const groupDef = groupDefs.find((def)=>def.id === group.id);
        if (groupDef) {
            if (typeof groupDef.label === 'function') {
                group.label = groupDef.label(group.items.length);
            } else {
                group.label = groupDef.label;
            }
        }
        return group;
    }).filter((group)=>group.items.length > 0);
    return groups;
}
