import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Scrollable, useHasScrollTop } from '@affine/component';
import clsx from 'clsx';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle } from 'react';
import { usePageHeaderColsDef } from './header-col-def';
import * as styles from './list.css';
import { ItemGroup } from './page-group';
import { ListTableHeader } from './page-header';
import { groupsAtom, listPropsAtom, ListProvider, selectionStateAtom, useAtom, useAtomValue, useSetAtom } from './scoped-atoms';
export const List = forwardRef(function List(props, ref) {
    return (_jsx(ListProvider, {
        initialValues: [
            [
                listPropsAtom,
                props
            ]
        ],
        children: _jsx(ListInnerWrapper, {
            ...props,
            handleRef: ref,
            children: _jsx(ListInner, {
                ...props
            })
        })
    }));
});
const useItemSelectionStateEffect = ()=>{
    const [selectionState, setSelectionActive] = useAtom(selectionStateAtom);
    useEffect(()=>{
        if (selectionState.selectionActive && selectionState.selectable === 'toggle') {
            const startTime = Date.now();
            const dblClickHandler = (e)=>{
                if (Date.now() - startTime < 200) {
                    return;
                }
                const target = e.target;
                if (target.tagName === 'BUTTON' || target.tagName === 'INPUT' || e.target.closest('button, input, [role="toolbar"]')) {
                    return;
                }
                setSelectionActive(false);
                selectionState.onSelectedIdsChange?.([]);
            };
            const escHandler = (e)=>{
                if (Date.now() - startTime < 200) {
                    return;
                }
                if (e.key === 'Escape') {
                    setSelectionActive(false);
                    selectionState.onSelectedIdsChange?.([]);
                }
            };
            document.addEventListener('dblclick', dblClickHandler);
            document.addEventListener('keydown', escHandler);
            return ()=>{
                document.removeEventListener('dblclick', dblClickHandler);
                document.removeEventListener('keydown', escHandler);
            };
        }
        return;
    }, [
        selectionState,
        selectionState.selectable,
        selectionState.selectionActive,
        setSelectionActive
    ]);
};
export const ListInnerWrapper = memo(({ handleRef, children, onSelectionActiveChange, ...props })=>{
    const setListPropsAtom = useSetAtom(listPropsAtom);
    const [selectionState, setListSelectionState] = useAtom(selectionStateAtom);
    useItemSelectionStateEffect();
    useEffect(()=>{
        setListPropsAtom(props);
    }, [
        props,
        setListPropsAtom
    ]);
    useEffect(()=>{
        onSelectionActiveChange?.(!!selectionState.selectionActive);
    }, [
        onSelectionActiveChange,
        selectionState.selectionActive
    ]);
    useImperativeHandle(handleRef, ()=>{
        return {
            toggleSelectable: ()=>{
                setListSelectionState(false);
            }
        };
    }, [
        setListSelectionState
    ]);
    return children;
});
ListInnerWrapper.displayName = 'ListInnerWrapper';
const ListInner = (props)=>{
    const groups = useAtomValue(groupsAtom);
    const pageHeaderColsDef = usePageHeaderColsDef();
    const hideHeader = props.hideHeader;
    return _jsxs("div", {
        className: clsx(props.className, styles.root),
        children: [
            !hideHeader ? _jsx(ListTableHeader, {
                headerCols: pageHeaderColsDef
            }) : null,
            _jsx("div", {
                className: styles.groupsContainer,
                children: groups.map((group)=>_jsx(ItemGroup, {
                        ...group
                    }, group.id))
            })
        ]
    });
};
export const ListScrollContainer = forwardRef(({ className, children, style }, ref)=>{
    const [setContainer, hasScrollTop] = useHasScrollTop();
    const setNodeRef = useCallback((r)=>{
        if (ref) {
            if (typeof ref === 'function') {
                ref(r);
            } else {
                ref.current = r;
            }
        }
        return setContainer(r);
    }, [
        ref,
        setContainer
    ]);
    return _jsxs(Scrollable.Root, {
        style: style,
        "data-has-scroll-top": hasScrollTop,
        className: clsx(styles.pageListScrollContainer, className),
        children: [
            _jsx(Scrollable.Viewport, {
                ref: setNodeRef,
                children: children
            }),
            _jsx(Scrollable.Scrollbar, {})
        ]
    });
});
ListScrollContainer.displayName = 'ListScrollContainer';
