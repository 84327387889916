function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _init_model, _init_onActiveStyleChange, _init_onImportMindMap, _init_std, _initProto;
import { toast } from '@blocksuite/affine-components/toast';
import { EditPropsStore, TelemetryProvider } from '@blocksuite/affine-shared/services';
import { modelContext, stdContext } from '@blocksuite/block-std';
import { ErrorCode } from '@blocksuite/global/exceptions';
import { SignalWatcher } from '@blocksuite/global/utils';
import { consume } from '@lit/context';
import { computed } from '@preact/signals-core';
import { css, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { getTooltipWithShortcut } from '../../utils.js';
import { EdgelessDraggableElementController } from '../common/draggable/draggable-element.controller.js';
import { EdgelessToolbarToolMixin } from '../mixins/tool.mixin.js';
import { getMindMaps } from './assets.js';
import { textRender } from './basket-elements.js';
import { importMindMapIcon, textIcon } from './icons.js';
import { MindMapPlaceholder } from './mindmap-importing-placeholder.js';
const textItem = {
    type: 'text',
    icon: textIcon,
    render: textRender
};
_dec = consume({
    context: modelContext
}), _dec1 = property({
    attribute: false
}), _dec2 = property({
    attribute: false
}), _dec3 = consume({
    context: stdContext
});
export class EdgelessMindmapMenu extends EdgelessToolbarToolMixin(SignalWatcher(LitElement)) {
    static{
        ({ e: [_init_model, _init_onActiveStyleChange, _init_onImportMindMap, _init_std, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "model"
            ],
            [
                _dec1,
                1,
                "onActiveStyleChange"
            ],
            [
                _dec2,
                1,
                "onImportMindMap"
            ],
            [
                _dec3,
                1,
                "std"
            ]
        ], []));
    }
    static{
        this.styles = css`
    :host {
      display: flex;
      z-index: -1;
      justify-content: flex-end;
    }
    .text-and-mindmap {
      display: flex;
      gap: 10px;
      padding: 8px 0px;
      box-sizing: border-box;
    }
    .thin-divider {
      width: 1px;
      transform: scaleX(0.5);
      height: 48px;
      background: var(--affine-border-color);
    }
    .text-item {
      width: 60px;
    }
    .mindmap-item {
      width: 64px;
    }

    .text-item,
    .mindmap-item {
      border-radius: 4px;
      height: 48px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-item > button,
    .mindmap-item > button {
      position: absolute;
      border-radius: inherit;
      border: none;
      background: none;
      cursor: grab;
      padding: 0;
    }
    .text-item:hover,
    .mindmap-item[data-is-active='true'],
    .mindmap-item:hover {
      background: var(--affine-hover-color);
    }
    .text-item > button.next,
    .mindmap-item > button.next {
      transition: transform 0.3s ease-in-out;
    }
  `;
    }
    get _rootBlock() {
        return this.std.view.getBlock(this.model.id);
    }
    get mindMaps() {
        return getMindMaps(this.theme);
    }
    _importMindMapEntry() {
        const { draggingElement } = this.draggableController?.states || {};
        const isBeingDragged = draggingElement?.data.type === 'import';
        return html`<div class="mindmap-item">
      <button
        style="opacity: ${isBeingDragged ? 0 : 1}"
        class="next"
        @mousedown=${(e)=>{
            this.draggableController.onMouseDown(e, {
                preview: importMindMapIcon,
                data: {
                    type: 'import',
                    icon: importMindMapIcon
                },
                standardWidth: 350
            });
        }}
        @touchstart=${(e)=>{
            this.draggableController.onTouchStart(e, {
                preview: importMindMapIcon,
                data: {
                    type: 'import',
                    icon: importMindMapIcon
                },
                standardWidth: 350
            });
        }}
        @click=${()=>{
            this.draggableController.cancel();
            const viewportBound = this._rootBlock.service.viewport.viewportBounds;
            viewportBound.x += viewportBound.w / 2;
            viewportBound.y += viewportBound.h / 2;
            this._onImportMindMap(viewportBound);
        }}
      >
        ${importMindMapIcon}
      </button>
      <affine-tooltip tip-position="top" .offset=${12}>
        ${getTooltipWithShortcut('Support import of FreeMind,OPML.')}
      </affine-tooltip>
    </div>`;
    }
    _onImportMindMap(bound) {
        const edgelessBlock = this._rootBlock;
        if (!edgelessBlock) return;
        const placeholder = new MindMapPlaceholder();
        placeholder.style.position = 'absolute';
        placeholder.style.left = `${bound.x}px`;
        placeholder.style.top = `${bound.y}px`;
        edgelessBlock.gfxViewportElm.append(placeholder);
        this.onImportMindMap?.(bound).then(()=>{
            this.std.get(TelemetryProvider)?.track('CanvasElementAdded', {
                page: 'whiteboard editor',
                type: 'imported mind map',
                other: 'success',
                module: 'toolbar'
            });
        }).catch((e)=>{
            if (e.code === ErrorCode.UserAbortError) return;
            this.std.get(TelemetryProvider)?.track('CanvasElementAdded', {
                page: 'whiteboard editor',
                type: 'imported mind map',
                other: 'failed',
                module: 'toolbar'
            });
            toast(this.edgeless.host, 'Import failed, please try again');
            console.error(e);
        }).finally(()=>{
            placeholder.remove();
        });
    }
    initDragController() {
        if (this.draggableController || !this.edgeless) return;
        this.draggableController = new EdgelessDraggableElementController(this, {
            service: this.edgeless.service,
            edgeless: this.edgeless,
            scopeElement: this,
            clickToDrag: true,
            onOverlayCreated: (_layer, element)=>{
                if (element.data.type === 'mindmap') {
                    this.onActiveStyleChange?.(element.data.style);
                }
                this.setEdgelessTool({
                    type: 'mindmap'
                });
            },
            onDrop: (element, bound)=>{
                if ('render' in element.data) {
                    const id = element.data.render(bound, this.edgeless.service, this.edgeless);
                    if (element.data.type === 'mindmap') {
                        this.onActiveStyleChange?.(element.data.style);
                        this.setEdgelessTool({
                            type: 'default'
                        }, {
                            elements: [
                                id
                            ],
                            editing: false
                        });
                    } else if (element.data.type === 'text') {
                        this.setEdgelessTool({
                            type: 'default'
                        });
                    }
                }
                if (element.data.type === 'import') {
                    this._onImportMindMap?.(bound);
                }
            }
        });
    }
    render() {
        const { cancelled, draggingElement, dragOut } = this.draggableController?.states || {};
        const isDraggingText = draggingElement?.data?.type === 'text';
        const showNextText = dragOut && !cancelled;
        return html`<edgeless-slide-menu .height=${'64px'}>
      <div class="text-and-mindmap">
        <div class="text-item">
          ${isDraggingText ? html`<button
                class="next"
                style="transform: translateY(${showNextText ? 0 : 64}px)"
              >
                ${textItem.icon}
              </button>` : nothing}
          <button
            style="opacity: ${isDraggingText ? 0 : 1}"
            @mousedown=${(e)=>this.draggableController.onMouseDown(e, {
                preview: textItem.icon,
                data: textItem
            })}
            @touchstart=${(e)=>this.draggableController.onTouchStart(e, {
                preview: textItem.icon,
                data: textItem
            })}
          >
            ${textItem.icon}
          </button>
          <affine-tooltip tip-position="top" .offset=${12}>
            ${getTooltipWithShortcut('Edgeless Text', 'T')}
          </affine-tooltip>
        </div>
        <div class="thin-divider"></div>
        <!-- mind map -->
        ${repeat(this.mindMaps, (mindMap)=>{
            const isDraggingMindMap = draggingElement?.data?.type !== 'text';
            const draggingEle = draggingElement?.data;
            const isBeingDragged = isDraggingMindMap && draggingEle?.style === mindMap.style;
            const showNext = dragOut && !cancelled;
            const isActive = this._style$.value === mindMap.style;
            return html`
            <div class="mindmap-item" data-is-active=${isActive}>
              ${isBeingDragged ? html`<button
                    style="transform: translateY(${showNext ? 0 : 64}px)"
                    class="next"
                  >
                    ${mindMap.icon}
                  </button>` : nothing}
              <button
                style="opacity: ${isBeingDragged ? 0 : 1}"
                @mousedown=${(e)=>{
                this.draggableController.onMouseDown(e, {
                    preview: mindMap.icon,
                    data: mindMap,
                    standardWidth: 350
                });
            }}
                @touchstart=${(e)=>{
                this.draggableController.onTouchStart(e, {
                    preview: mindMap.icon,
                    data: mindMap,
                    standardWidth: 350
                });
            }}
                @click=${()=>this.onActiveStyleChange?.(mindMap.style)}
              >
                ${mindMap.icon}
              </button>
              <affine-tooltip tip-position="top" .offset=${12}>
                ${getTooltipWithShortcut('Mind Map', 'M')}
              </affine-tooltip>
            </div>
          `;
        })}
        ${this.std.doc.awarenessStore.getFlag('enable_mind_map_import') ? this._importMindMapEntry() : nothing}
      </div>
    </edgeless-slide-menu>`;
    }
    updated(changedProperties) {
        if (!changedProperties.has('edgeless')) return;
        this.initDragController();
    }
    #___private_model_1;
    get model() {
        return this.#___private_model_1;
    }
    set model(_v) {
        this.#___private_model_1 = _v;
    }
    #___private_onActiveStyleChange_2;
    get onActiveStyleChange() {
        return this.#___private_onActiveStyleChange_2;
    }
    set onActiveStyleChange(_v) {
        this.#___private_onActiveStyleChange_2 = _v;
    }
    #___private_onImportMindMap_3;
    get onImportMindMap() {
        return this.#___private_onImportMindMap_3;
    }
    set onImportMindMap(_v) {
        this.#___private_onImportMindMap_3 = _v;
    }
    #___private_std_4;
    get std() {
        return this.#___private_std_4;
    }
    set std(_v) {
        this.#___private_std_4 = _v;
    }
    constructor(...args){
        super(...args), this._style$ = computed(()=>{
            const { style } = this.edgeless.std.get(EditPropsStore).lastProps$.value.mindmap;
            return style;
        }), this.type = 'mindmap', this.#___private_model_1 = (_initProto(this), _init_model(this)), this.#___private_onActiveStyleChange_2 = _init_onActiveStyleChange(this), this.#___private_onImportMindMap_3 = _init_onImportMindMap(this), this.#___private_std_4 = _init_std(this);
    }
}
