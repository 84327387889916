import { KeymapIdentifier } from '../identifier.js';
let id = 1;
export function KeymapExtension(keymapFactory, options) {
    return {
        setup: (di)=>{
            di.addImpl(KeymapIdentifier(`Keymap-${id++}`), {
                getter: keymapFactory,
                options
            });
        }
    };
}
