import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@affine/component/ui/button';
import { useI18n } from '@affine/i18n';
import { useCallback } from 'react';
import { Logo1IconBorder } from '../../wemem/icons';
import { DesktopNavbar } from './desktop-navbar';
import * as styles from './index.css';
import { MobileNavbar } from './mobile-navbar';
export const AffineOtherPageLayout = ({ children })=>{
    const t = useI18n();
    const openDownloadLink = useCallback(()=>{
        open(BUILD_CONFIG.downloadUrl, '_blank');
    }, []);
    return _jsxs("div", {
        className: styles.root,
        children: [
            BUILD_CONFIG.isElectron ? _jsx("div", {
                className: styles.draggableHeader
            }) : _jsxs("div", {
                className: styles.topNav,
                children: [
                    _jsx("a", {
                        href: "/",
                        rel: "noreferrer",
                        className: styles.affineLogo,
                        children: _jsx(Logo1IconBorder, {
                            width: 24,
                            height: 24
                        })
                    }),
                    _jsx(DesktopNavbar, {}),
                    _jsx(Button, {
                        onClick: openDownloadLink,
                        className: styles.hideInSmallScreen,
                        children: t['com.affine.auth.open.affine.download-app']()
                    }),
                    _jsx(MobileNavbar, {})
                ]
            }),
            children
        ]
    });
};
