import { ViewLayersIcon } from '@blocksuite/icons/rc';
import { Entity, LiveData } from '@toeverything/infra';
import Fuse from 'fuse.js';
import { highlighter } from '../utils/highlighter';
const group = {
    id: 'collections',
    label: {
        key: 'com.affine.cmdk.affine.category.affine.collections'
    },
    score: 10
};
export class CollectionsQuickSearchSession extends Entity {
    constructor(collectionService){
        super(), this.collectionService = collectionService, this.query$ = new LiveData(''), this.items$ = LiveData.computed((get)=>{
            const query = get(this.query$);
            const collections = get(this.collectionService.collections$);
            const fuse = new Fuse(collections, {
                keys: [
                    'name'
                ],
                includeMatches: true,
                includeScore: true,
                ignoreLocation: true,
                threshold: 0.0
            });
            const result = fuse.search(query);
            return result.map(({ item, matches, score = 1 })=>{
                const nomalizedRange = ([start, end])=>[
                        start,
                        end + 1
                    ];
                const titleMatches = matches?.filter((match)=>match.key === 'name').flatMap((match)=>match.indices.map(nomalizedRange));
                return {
                    id: 'collection:' + item.id,
                    source: 'collections',
                    label: {
                        title: (highlighter(item.name, '<b>', '</b>', titleMatches ?? []) ?? item.name) || {
                            key: 'Untitled'
                        }
                    },
                    group,
                    score: 1 - score,
                    icon: ViewLayersIcon,
                    payload: {
                        collectionId: item.id
                    }
                };
            });
        });
    }
    query(query) {
        this.query$.next(query);
    }
}
