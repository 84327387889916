function _isTimestampString(str) {
    return /^\d+$/.test(str);
}
export function toDate(date) {
    if (date instanceof Date) return date;
    if (typeof date === 'string' && _isTimestampString(date)) date = +date;
    return date ? new Date(date) : new Date();
}
export function getFirstDayOfMonth(maybeDate) {
    const date = toDate(maybeDate);
    return new Date(date.getFullYear(), date.getMonth(), 1);
}
export function getLastDayOfMonth(maybeDate) {
    const date = toDate(maybeDate);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export function getMonthMatrix(maybeDate) {
    const date = toDate(maybeDate);
    const firstDayOfMonth = getFirstDayOfMonth(date);
    const lastDayOfMonth = getLastDayOfMonth(date);
    const firstDayOfFirstWeek = new Date(firstDayOfMonth);
    firstDayOfFirstWeek.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay());
    const lastDayOfLastWeek = new Date(lastDayOfMonth);
    lastDayOfLastWeek.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()));
    const matrix = [];
    let week = [];
    const day = new Date(firstDayOfFirstWeek);
    while(day <= lastDayOfLastWeek){
        week.push(new Date(day));
        if (week.length === 7) {
            matrix.push(week);
            week = [];
        }
        day.setDate(day.getDate() + 1);
    }
    return matrix;
}
export function clamp(num1, num2, value) {
    const [min, max] = [
        num1,
        num2
    ].sort();
    return Math.min(Math.max(value, min), max);
}
