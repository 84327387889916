import { captureEventTarget, findNoteBlockModel, getBlockComponentsExcludeSubtrees, matchFlavours } from '@blocksuite/affine-shared/utils';
import { isGfxBlockComponent } from '@blocksuite/block-std';
import { GfxControllerIdentifier } from '@blocksuite/block-std/gfx';
import { Bound, Point } from '@blocksuite/global/utils';
import { render } from 'lit';
import { DropIndicator } from '../components/drop-indicator.js';
import { AFFINE_DRAG_HANDLE_WIDGET } from '../consts.js';
import { containBlock, getDuplicateBlocks, includeTextSelection } from '../utils.js';
export class DragEventWatcher {
    constructor(widget){
        this.widget = widget;
        this._changeCursorToGrabbing = ()=>{
            document.documentElement.classList.add('affine-drag-preview-grabbing');
        };
        this._createDropIndicator = ()=>{
            if (!this.widget.dropIndicator) {
                this.widget.dropIndicator = new DropIndicator();
                this.widget.rootComponent.append(this.widget.dropIndicator);
            }
        };
        this._dragEndHandler = (ctx)=>{
            this.widget.clearRaf();
            if (!this.widget.dragging || !this.widget.dragPreview) return false;
            if (this.widget.draggingElements.length === 0 || this.widget.doc.readonly) {
                this.widget.hide(true);
                return false;
            }
            const state = ctx.get('pointerState');
            const { target } = state.raw;
            if (!this.widget.host.contains(target)) {
                this.widget.hide(true);
                return true;
            }
            for (const option of this.widget.optionRunner.options){
                if (option.onDragEnd?.({
                    state,
                    draggingElements: this.widget.draggingElements,
                    dropBlockId: this.widget.dropBlockId,
                    dropType: this.widget.dropType,
                    dragPreview: this.widget.dragPreview,
                    noteScale: this.widget.noteScale.peek(),
                    editorHost: this.widget.host
                })) {
                    this.widget.hide(true);
                    if (this.widget.mode === 'edgeless') {
                        this.widget.edgelessWatcher.checkTopLevelBlockSelection();
                    }
                    return true;
                }
            }
            this._onDragEnd(state);
            if (this.widget.mode === 'edgeless') {
                this.widget.edgelessWatcher.checkTopLevelBlockSelection();
            }
            return true;
        };
        this._dragMoveHandler = (ctx)=>{
            if (this.widget.isHoverDragHandleVisible || this.widget.isTopLevelDragHandleVisible) {
                this.widget.hide();
            }
            if (!this.widget.dragging || this.widget.draggingElements.length === 0) {
                return false;
            }
            ctx.get('defaultState').event.preventDefault();
            const state = ctx.get('pointerState');
            for (const option of this.widget.optionRunner.options){
                if (option.onDragMove?.({
                    state,
                    draggingElements: this.widget.draggingElements
                })) {
                    return true;
                }
            }
            return this._onDragMove(state);
        };
        this._dragStartHandler = (ctx)=>{
            const state = ctx.get('pointerState');
            const { button } = state.raw;
            if (button !== 0) {
                return false;
            }
            for (const option of this.widget.optionRunner.options){
                if (option.onDragStart?.({
                    state,
                    startDragging: this._startDragging,
                    anchorBlockId: this.widget.anchorBlockId.peek() ?? '',
                    editorHost: this.widget.host
                })) {
                    return true;
                }
            }
            return this._onDragStart(state);
        };
        this._onDragEnd = (state)=>{
            const targetBlockId = this.widget.dropBlockId;
            const dropType = this.widget.dropType;
            const draggingElements = this.widget.draggingElements;
            this.widget.hide(true);
            if (!targetBlockId) {
                const target = captureEventTarget(state.raw.target);
                if (!target) return false;
                const isTargetEdgelessContainer = target.classList.contains('edgeless-container');
                if (!isTargetEdgelessContainer) return false;
                const selectedBlocks = getBlockComponentsExcludeSubtrees(draggingElements).map((element)=>element.model).filter((x)=>!!x);
                if (selectedBlocks.length === 0) return false;
                const isSurfaceComponent = selectedBlocks.some((block)=>{
                    const parent = this.widget.doc.getParent(block.id);
                    return matchFlavours(parent, [
                        'affine:surface'
                    ]);
                });
                if (isSurfaceComponent) return true;
                const edgelessRoot = this.widget.rootComponent;
                const { left: viewportLeft, top: viewportTop } = edgelessRoot.viewport;
                const newNoteId = edgelessRoot.addNoteWithPoint(new Point(state.raw.x - viewportLeft, state.raw.y - viewportTop), {
                    scale: this.widget.noteScale.peek()
                });
                const newNoteBlock = this.widget.doc.getBlockById(newNoteId);
                if (!newNoteBlock) return;
                const bound = Bound.deserialize(newNoteBlock.xywh);
                bound.h *= this.widget.noteScale.peek();
                bound.w *= this.widget.noteScale.peek();
                this.widget.doc.updateBlock(newNoteBlock, {
                    xywh: bound.serialize(),
                    edgeless: {
                        ...newNoteBlock.edgeless,
                        scale: this.widget.noteScale.peek()
                    }
                });
                const altKey = state.raw.altKey;
                if (altKey) {
                    const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                    this.widget.doc.addBlocks(duplicateBlocks, newNoteBlock);
                } else {
                    this.widget.doc.moveBlocks(selectedBlocks, newNoteBlock);
                }
                edgelessRoot.service.selection.set({
                    elements: [
                        newNoteBlock.id
                    ],
                    editing: true
                });
                return true;
            }
            if (containBlock(this.widget.selectionHelper.selectedBlockIds, targetBlockId)) {
                return false;
            }
            const selectedBlocks = getBlockComponentsExcludeSubtrees(draggingElements).map((element)=>element.model).filter((x)=>!!x);
            if (!selectedBlocks.length) {
                return false;
            }
            const targetBlock = this.widget.doc.getBlockById(targetBlockId);
            if (!targetBlock) return;
            const shouldInsertIn = dropType === 'in';
            const parent = shouldInsertIn ? targetBlock : this.widget.doc.getParent(targetBlockId);
            if (!parent) return;
            const altKey = state.raw.altKey;
            if (shouldInsertIn) {
                if (altKey) {
                    const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                    this.widget.doc.addBlocks(duplicateBlocks, targetBlock);
                } else {
                    this.widget.doc.moveBlocks(selectedBlocks, targetBlock);
                }
            } else {
                if (altKey) {
                    const duplicateBlocks = getDuplicateBlocks(selectedBlocks);
                    const parentIndex = parent.children.indexOf(targetBlock) + (dropType === 'after' ? 1 : 0);
                    this.widget.doc.addBlocks(duplicateBlocks, parent, parentIndex);
                } else {
                    this.widget.doc.moveBlocks(selectedBlocks, parent, targetBlock, dropType === 'before');
                }
            }
            setTimeout(()=>{
                if (!parent) return;
                const parentElement = this.widget.std.view.getBlock(parent.id);
                if (parentElement) {
                    const newSelectedBlocks = selectedBlocks.map((block)=>{
                        return this.widget.std.view.getBlock(block.id);
                    });
                    if (!newSelectedBlocks) return;
                    const note = findNoteBlockModel(parentElement.model);
                    if (!note) return;
                    this.widget.selectionHelper.setSelectedBlocks(newSelectedBlocks, note.id);
                }
            }, 0);
            return true;
        };
        this._onDragMove = (state)=>{
            this.widget.clearRaf();
            this.widget.rafID = requestAnimationFrame(()=>{
                this.widget.edgelessWatcher.updateDragPreviewPosition(state);
                this.widget.updateDropIndicator(state, true);
            });
            return true;
        };
        this._onDragStart = (state)=>{
            const hoverBlock = this.widget.anchorBlockComponent.peek();
            if (!hoverBlock) return false;
            const element = captureEventTarget(state.raw.target);
            const dragByHandle = !!element?.closest(AFFINE_DRAG_HANDLE_WIDGET);
            const isInSurface = isGfxBlockComponent(hoverBlock);
            if (isInSurface && dragByHandle) {
                const viewport = this.widget.std.get(GfxControllerIdentifier).viewport;
                const zoom = viewport.zoom ?? 1;
                const dragPreviewEl = document.createElement('div');
                const bound = Bound.deserialize(hoverBlock.model.xywh);
                const offset = new Point(bound.x * zoom, bound.y * zoom);
                render(this.widget.std.host.dangerouslyRenderModel(hoverBlock.model), dragPreviewEl);
                this._startDragging([
                    hoverBlock
                ], state, dragPreviewEl, offset);
                return true;
            }
            const selectBlockAndStartDragging = ()=>{
                this.widget.std.selection.setGroup('note', [
                    this.widget.std.selection.create('block', {
                        blockId: hoverBlock.blockId
                    })
                ]);
                this._startDragging([
                    hoverBlock
                ], state);
            };
            if (this.widget.draggingElements.length === 0) {
                const dragByBlock = hoverBlock.contains(element) && !hoverBlock.model.text;
                const canDragByBlock = matchFlavours(hoverBlock.model, [
                    'affine:attachment',
                    'affine:bookmark'
                ]) || hoverBlock.model.flavour.startsWith('affine:embed-');
                if (!isInSurface && dragByBlock && canDragByBlock) {
                    selectBlockAndStartDragging();
                    return true;
                }
            }
            if (!dragByHandle) {
                this.widget.hide();
                return false;
            }
            if (this.widget.draggingElements.length === 1) {
                if (!isInSurface) {
                    selectBlockAndStartDragging();
                    return true;
                }
            }
            if (!this.widget.isHoverDragHandleVisible) return false;
            let selections = this.widget.selectionHelper.selectedBlocks;
            if (selections.length > 0 && includeTextSelection(selections)) {
                const nativeSelection = document.getSelection();
                const rangeManager = this.widget.std.range;
                if (nativeSelection && nativeSelection.rangeCount > 0 && rangeManager) {
                    const range = nativeSelection.getRangeAt(0);
                    const blocks = rangeManager.getSelectedBlockComponentsByRange(range, {
                        match: (el)=>el.model.role === 'content',
                        mode: 'highest'
                    });
                    this.widget.selectionHelper.setSelectedBlocks(blocks);
                    selections = this.widget.selectionHelper.selectedBlocks;
                }
            }
            if (selections.length === 0 || !containBlock(selections.map((selection)=>selection.blockId), this.widget.anchorBlockId.peek())) {
                const block = this.widget.anchorBlockComponent.peek();
                if (block) {
                    this.widget.selectionHelper.setSelectedBlocks([
                        block
                    ]);
                }
            }
            const blocks = this.widget.selectionHelper.selectedBlockComponents;
            const blocksExcludingChildren = getBlockComponentsExcludeSubtrees(blocks);
            if (blocksExcludingChildren.length === 0) return false;
            this._startDragging(blocksExcludingChildren, state);
            this.widget.hide();
            return true;
        };
        this._startDragging = (blocks, state, dragPreviewEl, dragPreviewOffset)=>{
            if (!blocks.length) {
                return;
            }
            this.widget.draggingElements = blocks;
            this.widget.dragPreview = this.widget.previewHelper.createDragPreview(blocks, state, dragPreviewEl, dragPreviewOffset);
            this.widget.dragging = true;
            this._changeCursorToGrabbing();
            this._createDropIndicator();
            this.widget.hide();
        };
    }
    watch() {
        this.widget.handleEvent('dragStart', this._dragStartHandler);
        this.widget.handleEvent('dragMove', this._dragMoveHandler);
        this.widget.handleEvent('dragEnd', this._dragEndHandler, {
            global: true
        });
    }
}
