import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton, toast } from '@affine/component';
import { ExplorerTreeRoot } from '@affine/core/modules/explorer/views/tree';
import { FeedNodeType } from '@affine/core/modules/feeds';
import { FeedsService } from '@affine/core/modules/feeds/services/feeds';
import { useI18n } from '@affine/i18n';
import { mixpanel, track } from '@affine/track';
import { FolderIcon, PlusIcon } from '@blocksuite/icons/rc';
import { useLiveData, useServices } from '@toeverything/infra';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ExplorerService } from '../../../services/explorer';
import { CollapsibleSection } from '../../layouts/collapsible-section';
import { calcLocation, ExplorerFeedsNode } from '../../nodes/feed';
import { organizeChildrenDropEffect } from './dnd';
import { RootEmpty } from './empty';
export const ExplorerFeeds = ()=>{
    const { feedsService, explorerService } = useServices({
        FeedsService,
        ExplorerService
    });
    const explorerSection = explorerService.sections.feeds;
    const collapsed = useLiveData(explorerSection.collapsed$);
    const [newFolderId, setNewFolderId] = useState(null);
    const t = useI18n();
    const folderTree = feedsService.feedTree;
    const rootFolder = folderTree.rootFolder;
    const children = useLiveData(rootFolder.sortedChildren$);
    const isLoading = useLiveData(folderTree.isLoading$);
    const handleOpenSearchFeedModal = useCallback(()=>{
        feedsService.searchModal.show();
        mixpanel.track('NewOpened', {
            segment: 'navigation panel',
            control: 'new subscription button'
        });
    }, [
        feedsService
    ]);
    const handleCreateFolder = useCallback(()=>{
        const newFolderId = rootFolder.createFolder(t['com.affine.rootAppSidebar.organize.new-folders'](), rootFolder.indexAt('before'));
        track.$.navigationPanel.feeds.createFeedsItem({
            type: FeedNodeType.Folder
        });
        setNewFolderId(newFolderId);
        explorerSection.setCollapsed(false);
        return newFolderId;
    }, [
        explorerSection,
        rootFolder,
        t
    ]);
    const handleOnChildrenDrop = useCallback((data, node)=>{
        if (!node || !node.id) {
            return;
        }
        if (data.treeInstruction?.type === 'reorder-above' || data.treeInstruction?.type === 'reorder-below') {
            const at = data.treeInstruction?.type === 'reorder-below' ? 'after' : 'before';
            if (data.source.data.entity?.type === FeedNodeType.Folder || data.source.data.entity?.type === FeedNodeType.RSS) {
                rootFolder.moveHere(data.source.data.entity?.id ?? '', rootFolder.indexAt(at, node.id));
                track.$.navigationPanel.feeds.moveFeedNode({
                    type: data.source.data.entity?.type
                });
            } else {
                toast(t['ai.wemem.rootAppSidebar.feeds.root-folder-and-feeds-only']());
            }
        } else {
            return;
        }
    }, [
        rootFolder,
        t
    ]);
    const handleChildrenCanDrop = useMemo(()=>(args)=>[
                FeedNodeType.Folder,
                FeedNodeType.RSS
            ].includes(args.source.data.entity?.type), []);
    useEffect(()=>{
        if (collapsed) setNewFolderId(null);
    }, [
        collapsed
    ]);
    return _jsx(CollapsibleSection, {
        name: "feeds",
        title: t['ai.wemem.rootAppSidebar.feeds'](),
        actions: _jsxs(_Fragment, {
            children: [
                _jsx(IconButton, {
                    "data-testid": "explorer-bar-add-feeds-button",
                    onClick: handleOpenSearchFeedModal,
                    size: "16",
                    tooltip: t['ai.wemem.feeds.empty.new-feed-button'](),
                    children: _jsx(PlusIcon, {})
                }),
                _jsx(IconButton, {
                    "data-testid": "explorer-bar-add-feeds-folder-button",
                    onClick: handleCreateFolder,
                    size: "16",
                    tooltip: t['ai.wemem.rootAppSidebar.feeds.create-folder'](),
                    children: _jsx(FolderIcon, {})
                })
            ]
        }),
        children: _jsx(ExplorerTreeRoot, {
            placeholder: _jsx(RootEmpty, {
                onClickCreate: handleOpenSearchFeedModal,
                isLoading: isLoading
            }),
            children: children.map((child)=>_jsx(ExplorerFeedsNode, {
                    nodeId: child.id,
                    defaultRenaming: child.id === newFolderId,
                    onDrop: handleOnChildrenDrop,
                    dropEffect: organizeChildrenDropEffect,
                    canDrop: handleChildrenCanDrop,
                    reorderable: true,
                    location: calcLocation(child)
                }, child.id))
        })
    });
};
