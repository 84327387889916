import { useWorkspace } from '@affine/core/components/hooks/use-workspace';
export function useCleanDuplicateOnDocRemove() {
    const workspace = useWorkspace();
    workspace?.docCollection.slots.docAdded.on((docId)=>{
        const doc = workspace.docCollection.docs.get(docId);
        if (!doc) {
            return;
        }
        console.log('doc.meta?.feedSource', doc.meta?.feedSource);
    });
    workspace?.docCollection.slots.docRemoved.on((docId)=>{
        const doc = workspace.docCollection.docs.get(docId);
        if (!doc) {
            return;
        }
        console.log('doc.meta?.feedSource', doc.meta?.feedSource);
    });
}
