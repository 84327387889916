import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Empty } from '../../ui/empty';
import { AffineOtherPageLayout } from '../affine-other-page-layout';
import { authPageContainer, hideInSmallScreen } from './share.css';
export const AuthPageContainer = ({ children, title, subtitle })=>{
    return _jsx(AffineOtherPageLayout, {
        children: _jsx("div", {
            className: authPageContainer,
            children: _jsxs("div", {
                className: "wrapper",
                children: [
                    _jsxs("div", {
                        className: "content",
                        children: [
                            _jsx("p", {
                                className: "title",
                                children: title
                            }),
                            _jsx("p", {
                                className: "subtitle",
                                children: subtitle
                            }),
                            children
                        ]
                    }),
                    _jsx("div", {
                        className: hideInSmallScreen,
                        children: _jsx(Empty, {})
                    })
                ]
            })
        })
    });
};
