import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Input, Menu, RowInput, Scrollable } from '@affine/component';
import { useNavigateHelper } from '@affine/core/components/hooks/use-navigate-helper';
import { WorkspaceLegacyProperties } from '@affine/core/modules/properties';
import { DeleteTagConfirmModal, TagService } from '@affine/core/modules/tag';
import { isInternalTag } from '@affine/core/modules/tag/entities/internal-tag';
import { useI18n } from '@affine/i18n';
import { DeleteIcon, MoreHorizontalIcon, TagsIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import clsx from 'clsx';
import { clamp } from 'lodash-es';
import { useCallback, useMemo, useReducer, useRef, useState } from 'react';
import { TagItem, TempTagItem } from '../../page-list';
import { renderMenuItemOptions } from './menu-items';
import * as styles from './tags-inline-editor.css';
export const InlineTagsList = ({ pageId, readonly, children, focusedIndex, onRemove })=>{
    const tagList = useService(TagService).tagList;
    const tags = useLiveData(tagList.tags$);
    const tagIds = useLiveData(tagList.tagIdsByPageId$(pageId));
    return _jsxs("div", {
        className: styles.inlineTagsContainer,
        "data-testid": "inline-tags-list",
        children: [
            tagIds.map((tagId, idx)=>{
                const tag = tags.find((t)=>t.id === tagId);
                if (!tag) {
                    return null;
                }
                const onRemoved = readonly ? undefined : ()=>{
                    tag.untag(pageId);
                    onRemove?.();
                };
                return _jsx(TagItem, {
                    idx: idx,
                    focused: focusedIndex === idx,
                    onRemoved: onRemoved,
                    mode: "inline",
                    tag: tag
                }, tagId);
            }),
            children
        ]
    });
};
export const EditTagMenu = ({ tagId, onTagDelete, children })=>{
    const t = useI18n();
    const legacyProperties = useService(WorkspaceLegacyProperties);
    const tagService = useService(TagService);
    const tagList = tagService.tagList;
    const tag = useLiveData(tagList.tagByTagId$(tagId));
    const tagColor = useLiveData(tag?.color$);
    const tagValue = useLiveData(tag?.value$);
    const navigate = useNavigateHelper();
    const menuProps = useMemo(()=>{
        const options = [];
        const updateTagName = (name)=>{
            if (name.trim() === '') {
                return;
            }
            tag?.rename(name);
        };
        options.push(_jsx(Input, {
            defaultValue: tagValue,
            onBlur: (e)=>{
                updateTagName(e.currentTarget.value);
            },
            onKeyDown: (e)=>{
                if (e.key === 'Enter') {
                    e.preventDefault();
                    updateTagName(e.currentTarget.value);
                }
                e.stopPropagation();
            },
            placeholder: t['Untitled']()
        }));
        options.push('-');
        options.push({
            text: t['Delete'](),
            icon: _jsx(DeleteIcon, {}),
            type: 'danger',
            onClick () {
                onTagDelete([
                    tag?.id || ''
                ]);
            }
        });
        options.push({
            text: t['com.affine.page-properties.tags.open-tags-page'](),
            icon: _jsx(TagsIcon, {}),
            onClick () {
                navigate.jumpToTag(legacyProperties.workspaceId, tag?.id || '');
            }
        });
        options.push('-');
        options.push(tagService.tagColors.map(([name, color], i)=>{
            return {
                text: name,
                icon: _jsx("div", {
                    className: styles.tagColorIconWrapper,
                    children: _jsx("div", {
                        className: styles.tagColorIcon,
                        style: {
                            backgroundColor: color
                        }
                    })
                }, i),
                checked: tagColor === color,
                onClick () {
                    tag?.changeColor(color);
                }
            };
        }));
        const items = renderMenuItemOptions(options);
        return {
            contentOptions: {
                onClick (e) {
                    e.stopPropagation();
                }
            },
            items
        };
    }, [
        legacyProperties.workspaceId,
        navigate,
        onTagDelete,
        t,
        tag,
        tagColor,
        tagService.tagColors,
        tagValue
    ]);
    return _jsx(Menu, {
        ...menuProps,
        children: children
    });
};
const isCreateNewTag = (tagOption)=>{
    return 'create' in tagOption;
};
export const TagsEditor = ({ pageId, readonly })=>{
    const t = useI18n();
    const tagService = useService(TagService);
    const tagList = tagService.tagList;
    const tags = useLiveData(tagList.tags$);
    const tagIds = useLiveData(tagList.tagIdsByPageId$(pageId));
    const [inputValue, setInputValue] = useState('');
    const filteredTags = useLiveData(inputValue ? tagList.filterTagsByName$(inputValue) : tagList.tags$);
    const [open, setOpen] = useState(false);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const inputRef = useRef(null);
    const exactMatch = filteredTags.find((tag)=>tag.value$.value === inputValue);
    const showCreateTag = !exactMatch && inputValue.trim();
    const tagOptions = useMemo(()=>{
        if (showCreateTag) {
            return [
                {
                    create: true,
                    value: inputValue
                },
                ...filteredTags
            ];
        } else {
            return filteredTags;
        }
    }, [
        filteredTags,
        inputValue,
        showCreateTag
    ]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [focusedInlineIndex, setFocusedInlineIndex] = useState(tagIds.length);
    const safeFocusedIndex = clamp(focusedIndex, -1, tagOptions.length - 1);
    const safeInlineFocusedIndex = clamp(focusedInlineIndex, -1, tagIds.length);
    const scrollContainerRef = useRef(null);
    const handleCloseModal = useCallback((open)=>{
        setOpen(open);
        setSelectedTagIds([]);
    }, [
        setOpen
    ]);
    const onTagDelete = useCallback((tagIds)=>{
        setOpen(true);
        setSelectedTagIds(tagIds);
    }, [
        setOpen,
        setSelectedTagIds
    ]);
    const onInputChange = useCallback((value)=>{
        setInputValue(value);
    }, []);
    const onToggleTag = useCallback((id)=>{
        const tagEntity = tagList.tags$.value.find((o)=>o.id === id);
        if (!tagEntity) {
            return;
        }
        if (!tagIds.includes(id)) {
            tagEntity.tag(pageId);
        } else {
            tagEntity.untag(pageId);
        }
    }, [
        pageId,
        tagIds,
        tagList.tags$.value
    ]);
    const focusInput = useCallback(()=>{
        inputRef.current?.focus();
    }, []);
    const [nextColor, rotateNextColor] = useReducer((color)=>{
        const idx = tagService.tagColors.findIndex((c)=>c[1] === color);
        return tagService.tagColors[(idx + 1) % tagService.tagColors.length][1];
    }, tagService.tagColors[Math.floor(Math.random() * tagService.tagColors.length)][1]);
    const onCreateTag = useCallback((name)=>{
        rotateNextColor();
        const newTag = tagList.createTag(name.trim(), nextColor);
        return newTag.id;
    }, [
        nextColor,
        tagList
    ]);
    const onSelectTagOption = useCallback((tagOption)=>{
        const id = isCreateNewTag(tagOption) ? onCreateTag(tagOption.value) : tagOption.id;
        onToggleTag(id);
        setInputValue('');
        focusInput();
        setFocusedIndex(-1);
        setFocusedInlineIndex(tagIds.length + 1);
    }, [
        onCreateTag,
        onToggleTag,
        focusInput,
        tagIds.length
    ]);
    const onEnter = useCallback(()=>{
        if (safeFocusedIndex >= 0) {
            onSelectTagOption(tagOptions[safeFocusedIndex]);
        }
    }, [
        onSelectTagOption,
        safeFocusedIndex,
        tagOptions
    ]);
    const onInputKeyDown = useCallback((e)=>{
        if (e.key === 'Backspace' && inputValue === '' && tagIds.length) {
            const tagToRemove = safeInlineFocusedIndex < 0 || safeInlineFocusedIndex >= tagIds.length ? tagIds.length - 1 : safeInlineFocusedIndex;
            tags.find((item)=>item.id === tagIds.at(tagToRemove))?.untag(pageId);
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            const newFocusedIndex = clamp(safeFocusedIndex + (e.key === 'ArrowUp' ? -1 : 1), 0, tagOptions.length - 1);
            scrollContainerRef.current?.querySelector(`.${styles.tagSelectorItem}:nth-child(${newFocusedIndex + 1})`)?.scrollIntoView({
                block: 'nearest'
            });
            setFocusedIndex(newFocusedIndex);
            setFocusedInlineIndex(tagIds.length + 1);
        } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            const newItemToFocus = e.key === 'ArrowLeft' ? safeInlineFocusedIndex - 1 : safeInlineFocusedIndex + 1;
            e.preventDefault();
            setFocusedInlineIndex(newItemToFocus);
            setFocusedIndex(-1);
        }
    }, [
        inputValue,
        tagIds,
        safeFocusedIndex,
        tagOptions,
        safeInlineFocusedIndex,
        tags,
        pageId
    ]);
    return _jsxs("div", {
        "data-testid": "tags-editor-popup",
        className: styles.tagsEditorRoot,
        children: [
            _jsx("div", {
                className: styles.tagsEditorSelectedTags,
                children: _jsx(InlineTagsList, {
                    pageId: pageId,
                    readonly: readonly,
                    focusedIndex: safeInlineFocusedIndex,
                    onRemove: focusInput,
                    children: _jsx(RowInput, {
                        ref: inputRef,
                        value: inputValue,
                        onChange: onInputChange,
                        onKeyDown: onInputKeyDown,
                        onEnter: onEnter,
                        autoFocus: true,
                        className: styles.searchInput,
                        placeholder: "Type here ..."
                    })
                })
            }),
            _jsxs("div", {
                className: styles.tagsEditorTagsSelector,
                children: [
                    _jsx("div", {
                        className: styles.tagsEditorTagsSelectorHeader,
                        children: t['com.affine.page-properties.tags.selector-header-title']()
                    }),
                    _jsxs(Scrollable.Root, {
                        children: [
                            _jsx(Scrollable.Viewport, {
                                ref: scrollContainerRef,
                                className: styles.tagSelectorTagsScrollContainer,
                                children: tagOptions.map((tag, idx)=>{
                                    const commonProps = {
                                        ...safeFocusedIndex === idx ? {
                                            focused: 'true'
                                        } : {},
                                        onClick: ()=>onSelectTagOption(tag),
                                        onMouseEnter: ()=>setFocusedIndex(idx),
                                        ['data-testid']: 'tag-selector-item',
                                        ['data-focused']: safeFocusedIndex === idx,
                                        className: styles.tagSelectorItem
                                    };
                                    if (isCreateNewTag(tag)) {
                                        return _jsxs("div", {
                                            ...commonProps,
                                            children: [
                                                t['Create'](),
                                                ' ',
                                                _jsx(TempTagItem, {
                                                    value: inputValue,
                                                    color: nextColor
                                                })
                                            ]
                                        }, tag.value + '.' + idx);
                                    } else {
                                        return _jsxs("div", {
                                            ...commonProps,
                                            "data-tag-id": tag.id,
                                            "data-tag-value": tag.value$.value,
                                            children: [
                                                _jsx(TagItem, {
                                                    maxWidth: "100%",
                                                    tag: tag,
                                                    mode: "inline"
                                                }),
                                                _jsx("div", {
                                                    className: styles.spacer
                                                }),
                                                !isInternalTag(tag.value$.getValue()) && _jsx(EditTagMenu, {
                                                    tagId: tag.id,
                                                    onTagDelete: onTagDelete,
                                                    children: _jsx(IconButton, {
                                                        className: styles.tagEditIcon,
                                                        children: _jsx(MoreHorizontalIcon, {})
                                                    })
                                                })
                                            ]
                                        }, tag.id);
                                    }
                                })
                            }),
                            _jsx(Scrollable.Scrollbar, {
                                style: {
                                    transform: 'translateX(6px)'
                                }
                            })
                        ]
                    })
                ]
            }),
            _jsx(DeleteTagConfirmModal, {
                open: open,
                onOpenChange: handleCloseModal,
                selectedTagIds: selectedTagIds
            })
        ]
    });
};
export const TagsInlineEditor = ({ pageId, readonly, placeholder, className })=>{
    const tagList = useService(TagService).tagList;
    const tagIds = useLiveData(tagList.tagIdsByPageId$(pageId));
    console.log('tagIds', tagIds);
    const empty = !tagIds || tagIds.length === 0;
    return _jsx(Menu, {
        contentOptions: {
            side: 'bottom',
            align: 'start',
            sideOffset: 0,
            avoidCollisions: false,
            className: styles.tagsMenu,
            onClick (e) {
                e.stopPropagation();
            }
        },
        items: _jsx(TagsEditor, {
            pageId: pageId,
            readonly: readonly
        }),
        children: _jsx("div", {
            className: clsx(styles.tagsInlineEditor, className),
            "data-empty": empty,
            "data-readonly": readonly,
            children: empty ? placeholder : _jsx(InlineTagsList, {
                pageId: pageId,
                readonly: true
            })
        })
    });
};
