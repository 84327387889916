import { map } from 'rxjs';
import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
import { DocRecord } from './record';
export class DocRecordList extends Entity {
    constructor(store){
        super(), this.store = store, this.pool = new Map(), this.docs$ = LiveData.from(this.store.watchDocIds().pipe(map((ids)=>ids.map((id)=>{
                const exists = this.pool.get(id);
                if (exists) {
                    return exists;
                }
                const record = this.framework.createEntity(DocRecord, {
                    id
                });
                this.pool.set(id, record);
                return record;
            }))), []), this.trashDocs$ = LiveData.from(this.store.watchTrashDocIds().pipe(map((ids)=>ids.map((id)=>{
                const exists = this.pool.get(id);
                if (exists) {
                    return exists;
                }
                const record = this.framework.createEntity(DocRecord, {
                    id
                });
                this.pool.set(id, record);
                return record;
            }))), []), this.isReady$ = LiveData.from(this.store.watchDocListReady(), false);
    }
    doc$(id) {
        return this.docs$.map((record)=>record.find((record)=>record.id === id));
    }
    setPrimaryMode(id, mode) {
        return this.store.setDocPrimaryModeSetting(id, mode);
    }
    getPrimaryMode(id) {
        return this.store.getDocPrimaryModeSetting(id);
    }
    togglePrimaryMode(id) {
        const mode = this.getPrimaryMode(id) === 'edgeless' ? 'page' : 'edgeless';
        this.setPrimaryMode(id, mode);
        return this.getPrimaryMode(id);
    }
    primaryMode$(id) {
        return LiveData.from(this.store.watchDocPrimaryModeSetting(id), this.getPrimaryMode(id));
    }
}
