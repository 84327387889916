import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const AffineShapeIcon = (props)=>_jsxs("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 800 695",
        fill: "none",
        width: 200,
        ...props,
        children: [
            _jsx("rect", {
                x: "206.896",
                y: "154.635",
                width: "386.207",
                height: "386.207",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M207.336 347.738L400 155.074L592.664 347.738L400 540.402L207.336 347.738Z",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M398.421 153.574C430.649 134.691 468.171 123.866 508.222 123.866C628.347 123.866 725.729 221.247 725.729 341.372C725.729 430.974 671.549 507.921 594.163 541.241",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M593.501 346.677C612.385 378.905 623.21 416.427 623.21 456.478C623.21 576.603 525.829 673.984 405.703 673.984C316.101 673.984 239.154 619.805 205.835 542.419",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M401.578 541.24C369.35 560.123 331.828 570.948 291.777 570.948C171.651 570.948 74.2704 473.567 74.2704 353.441C74.2704 263.84 128.45 186.892 205.835 153.573",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M205.834 349.315C186.951 317.088 176.126 279.565 176.126 239.515C176.126 119.389 273.507 22.0086 393.633 22.0086C483.235 22.0085 560.182 76.188 593.501 153.573",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M205.835 153.574L594.164 541.903",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M594.164 153.574L205.835 541.903",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M399.999 153.574V541.903",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("path", {
                d: "M594.164 347.738L205.835 347.738",
                stroke: "currentColor",
                strokeWidth: "3"
            }),
            _jsx("ellipse", {
                cx: "593.102",
                cy: "154.635",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "593.102",
                cy: "540.842",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "593.102",
                cy: "347.738",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "206.896",
                cy: "154.635",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "206.896",
                cy: "540.842",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "206.896",
                cy: "347.738",
                rx: "15.9151",
                ry: "15.9151",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "400",
                cy: "154.635",
                rx: "15.9151",
                ry: "15.9151",
                transform: "rotate(-90 400 154.635)",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "400",
                cy: "345.616",
                rx: "15.9151",
                ry: "15.9151",
                transform: "rotate(-90 400 345.616)",
                fill: "currentColor"
            }),
            _jsx("ellipse", {
                cx: "400",
                cy: "540.842",
                rx: "15.9151",
                ry: "15.9151",
                transform: "rotate(-90 400 540.842)",
                fill: "currentColor"
            })
        ]
    });
