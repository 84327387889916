import { distributeAndNormalize } from './distribute.js';
import { getPathParamsForCorner, getSVGPathFromPathParams } from './draw.js';
export function getSvgPath({ cornerRadius = 0, topLeftCornerRadius, topRightCornerRadius, bottomRightCornerRadius, bottomLeftCornerRadius, cornerSmoothing, width, height, preserveSmoothing = false }) {
    topLeftCornerRadius = topLeftCornerRadius ?? cornerRadius;
    topRightCornerRadius = topRightCornerRadius ?? cornerRadius;
    bottomLeftCornerRadius = bottomLeftCornerRadius ?? cornerRadius;
    bottomRightCornerRadius = bottomRightCornerRadius ?? cornerRadius;
    if (topLeftCornerRadius === topRightCornerRadius && topRightCornerRadius === bottomRightCornerRadius && bottomRightCornerRadius === bottomLeftCornerRadius && bottomLeftCornerRadius === topLeftCornerRadius) {
        const roundingAndSmoothingBudget = Math.min(width, height) / 2;
        const cornerRadius = Math.min(topLeftCornerRadius, roundingAndSmoothingBudget);
        const pathParams = getPathParamsForCorner({
            cornerRadius,
            cornerSmoothing,
            preserveSmoothing,
            roundingAndSmoothingBudget
        });
        return getSVGPathFromPathParams({
            width,
            height,
            topLeftPathParams: pathParams,
            topRightPathParams: pathParams,
            bottomLeftPathParams: pathParams,
            bottomRightPathParams: pathParams
        });
    }
    const { topLeft, topRight, bottomLeft, bottomRight } = distributeAndNormalize({
        topLeftCornerRadius,
        topRightCornerRadius,
        bottomRightCornerRadius,
        bottomLeftCornerRadius,
        width,
        height
    });
    return getSVGPathFromPathParams({
        width,
        height,
        topLeftPathParams: getPathParamsForCorner({
            cornerSmoothing,
            preserveSmoothing,
            cornerRadius: topLeft.radius,
            roundingAndSmoothingBudget: topLeft.roundingAndSmoothingBudget
        }),
        topRightPathParams: getPathParamsForCorner({
            cornerSmoothing,
            preserveSmoothing,
            cornerRadius: topRight.radius,
            roundingAndSmoothingBudget: topRight.roundingAndSmoothingBudget
        }),
        bottomRightPathParams: getPathParamsForCorner({
            cornerSmoothing,
            preserveSmoothing,
            cornerRadius: bottomRight.radius,
            roundingAndSmoothingBudget: bottomRight.roundingAndSmoothingBudget
        }),
        bottomLeftPathParams: getPathParamsForCorner({
            cornerSmoothing,
            preserveSmoothing,
            cornerRadius: bottomLeft.radius,
            roundingAndSmoothingBudget: bottomLeft.roundingAndSmoothingBudget
        })
    });
}
