export { UserspaceService as UserDBService } from './services/userspace';
import { AuthService, WebSocketService } from '../cloud';
import { CurrentUserDB } from './entities/current-user-db';
import { UserDB } from './entities/user-db';
import { UserDBEngine } from './entities/user-db-engine';
import { UserDBTable } from './entities/user-db-table';
import { IndexedDBUserspaceDocStorage } from './impls/indexeddb-storage';
import { SqliteUserspaceDocStorage } from './impls/sqlite-storage';
import { UserspaceStorageProvider } from './provider/storage';
import { UserspaceService } from './services/userspace';
export function configureUserspaceModule(framework) {
    framework.service(UserspaceService).entity(CurrentUserDB, [
        UserspaceService,
        AuthService
    ]).entity(UserDB).entity(UserDBTable).entity(UserDBEngine, [
        UserspaceStorageProvider,
        WebSocketService
    ]);
}
export function configureIndexedDBUserspaceStorageProvider(framework) {
    framework.impl(UserspaceStorageProvider, {
        getDocStorage (userId) {
            return new IndexedDBUserspaceDocStorage(userId);
        }
    });
}
export function configureSqliteUserspaceStorageProvider(framework) {
    framework.impl(UserspaceStorageProvider, {
        getDocStorage (userId) {
            return new SqliteUserspaceDocStorage(userId);
        }
    });
}
