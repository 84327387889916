import { LiveData, Service } from '@toeverything/infra';
import { Observable } from 'rxjs';
export class WorkspaceLegacyProperties extends Service {
    constructor(workspaceService){
        super(), this.workspaceService = workspaceService, this.updateProperties = (properties)=>{
            this.workspaceService.workspace.docCollection.meta.setProperties(properties);
        }, this.properties$ = LiveData.from(new Observable((sub)=>{
            return this.subscribe(()=>sub.next(this.properties));
        }), this.properties), this.tagOptions$ = LiveData.from(new Observable((sub)=>{
            return this.subscribe(()=>sub.next(this.tagOptions));
        }), this.tagOptions), this.updateTagOptions = (options)=>{
            this.updateProperties({
                ...this.properties,
                tags: {
                    options
                }
            });
        }, this.updateTagOption = (id, option)=>{
            this.updateTagOptions(this.tagOptions.map((o)=>o.id === id ? option : o));
        }, this.removeTagOption = (id)=>{
            this.workspaceService.workspace.docCollection.doc.transact(()=>{
                this.updateTagOptions(this.tagOptions.filter((o)=>o.id !== id));
                this.workspaceService.workspace.docCollection.docs.forEach((doc)=>{
                    const tags = doc.meta?.tags ?? [];
                    if (tags.includes(id)) {
                        this.updatePageTags(doc.id, tags.filter((t)=>t !== id));
                    }
                });
            });
        }, this.updatePageTags = (pageId, tags)=>{
            this.workspaceService.workspace.docCollection.setDocMeta(pageId, {
                tags
            });
        };
    }
    get workspaceId() {
        return this.workspaceService.workspace.id;
    }
    get properties() {
        return this.workspaceService.workspace.docCollection.meta.properties;
    }
    get tagOptions() {
        return this.properties.tags?.options ?? [];
    }
    subscribe(cb) {
        const disposable = this.workspaceService.workspace.docCollection.meta.docMetaUpdated.on(cb);
        return disposable.dispose;
    }
}
