import { Entity } from '../../../framework';
export class Doc extends Entity {
    constructor(scope, store, workspaceService){
        super(), this.scope = scope, this.store = store, this.workspaceService = workspaceService, this.blockSuiteDoc = this.scope.props.blockSuiteDoc, this.record = this.scope.props.record, this.meta$ = this.record.meta$, this.properties$ = this.record.properties$, this.primaryMode$ = this.record.primaryMode$, this.title$ = this.record.title$, this.trash$ = this.record.trash$;
    }
    get workspace() {
        return this.workspaceService.workspace;
    }
    get id() {
        return this.scope.props.docId;
    }
    setPrimaryMode(mode) {
        return this.record.setPrimaryMode(mode);
    }
    getPrimaryMode() {
        return this.record.getPrimaryMode();
    }
    togglePrimaryMode() {
        this.setPrimaryMode(this.getPrimaryMode() === 'edgeless' ? 'page' : 'edgeless');
    }
    moveToTrash() {
        return this.record.moveToTrash();
    }
    restoreFromTrash() {
        return this.record.restoreFromTrash();
    }
    waitForSyncReady() {
        return this.store.waitForDocLoadReady(this.id);
    }
    setPriorityLoad(priority) {
        return this.store.setPriorityLoad(this.id, priority);
    }
    changeDocTitle(newTitle) {
        const pageBlock = this.blockSuiteDoc.getBlocksByFlavour('affine:page').at(0)?.model;
        if (pageBlock) {
            this.blockSuiteDoc.transact(()=>{
                pageBlock.title.delete(0, pageBlock.title.length);
                pageBlock.title.insert(newTitle, 0);
            });
            this.record.setMeta({
                title: newTitle
            });
        }
    }
}
