import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@affine/component';
import { Button } from '@affine/component/ui/button';
import { popupWindow } from '@affine/core/utils';
import { appInfo } from '@affine/electron-api';
import { OAuthProviderType } from '@affine/graphql';
import { GithubIcon, GoogleDuotoneIcon } from '@blocksuite/icons/rc';
import { useLiveData, useService } from '@toeverything/infra';
import { useCallback } from 'react';
import { ServerConfigService } from '../../../modules/cloud';
const OAuthProviderMap = {
    [OAuthProviderType.Google]: {
        icon: _jsx(GoogleDuotoneIcon, {})
    },
    [OAuthProviderType.GitHub]: {
        icon: _jsx(GithubIcon, {})
    },
    [OAuthProviderType.OIDC]: {
        icon: _jsx(GoogleDuotoneIcon, {})
    }
};
export function OAuth() {
    const serverConfig = useService(ServerConfigService).serverConfig;
    const oauth = useLiveData(serverConfig.features$.map((r)=>r?.oauth));
    const oauthProviders = useLiveData(serverConfig.config$.map((r)=>r?.oauthProviders));
    if (!oauth) {
        return _jsx(Skeleton, {
            height: 50
        });
    }
    return oauthProviders?.map((provider)=>_jsx(OAuthProvider, {
            provider: provider
        }, provider));
}
function OAuthProvider({ provider }) {
    const { icon } = OAuthProviderMap[provider];
    const onClick = useCallback(()=>{
        let oauthUrl = (BUILD_CONFIG.isElectron ? BUILD_CONFIG.serverUrlPrefix : '') + `/oauth/login?provider=${provider}`;
        if (BUILD_CONFIG.isElectron) {
            oauthUrl += `&client=${appInfo?.schema}`;
        }
        popupWindow(oauthUrl);
    }, [
        provider
    ]);
    return _jsxs(Button, {
        variant: "primary",
        block: true,
        size: "extraLarge",
        style: {
            marginTop: 30,
            width: '100%'
        },
        prefix: icon,
        onClick: onClick,
        children: [
            "Continue with ",
            provider
        ]
    }, provider);
}
