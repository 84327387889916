import { getRectByBlockComponent, matchFlavours } from '@blocksuite/affine-shared/utils';
import { BLOCK_ID_ATTR } from '@blocksuite/block-std';
import { assertExists } from '@blocksuite/global/utils';
const ATTR_SELECTOR = `[${BLOCK_ID_ATTR}]`;
export function buildPath(model) {
    const path = [];
    let current = model;
    while(current){
        path.unshift(current.id);
        current = current.doc.getParent(current);
    }
    return path;
}
export function getRootByEditorHost(editorHost) {
    return getPageRootByEditorHost(editorHost) ?? getEdgelessRootByEditorHost(editorHost);
}
export function getPageRootByEditorHost(editorHost) {
    return editorHost.querySelector('affine-page-root');
}
export function getEdgelessRootByEditorHost(editorHost) {
    return editorHost.querySelector('affine-edgeless-root');
}
export function getBlockComponentByModel(editorHost, model) {
    if (!model) return null;
    return editorHost.view.getBlock(model.id);
}
function isEdgelessChildNote({ classList }) {
    return classList.contains('note-background');
}
export function getHoveringNote(point) {
    return document.elementsFromPoint(point.x, point.y).find(isEdgelessChildNote) || null;
}
function getDatabaseBlockTableElement(element) {
    return element.querySelector('.affine-database-block-table');
}
function getDatabaseBlockColumnHeaderElement(element) {
    return element.querySelector('.affine-database-column-header');
}
function getDatabaseBlockRowsElement(element) {
    return element.querySelector('.affine-database-block-rows');
}
export var DropFlags;
(function(DropFlags) {
    DropFlags[DropFlags["Normal"] = 0] = "Normal";
    DropFlags[DropFlags["Database"] = 1] = "Database";
    DropFlags[DropFlags["EmptyDatabase"] = 2] = "EmptyDatabase";
})(DropFlags || (DropFlags = {}));
export function getDropRectByPoint(point, model, element) {
    const result = {
        rect: getRectByBlockComponent(element),
        flag: 0
    };
    const isDatabase = matchFlavours(model, [
        'affine:database'
    ]);
    if (isDatabase) {
        const table = getDatabaseBlockTableElement(element);
        if (!table) {
            return result;
        }
        let bounds = table.getBoundingClientRect();
        if (model.isEmpty.value) {
            result.flag = 2;
            if (point.y < bounds.top) return result;
            const header = getDatabaseBlockColumnHeaderElement(element);
            assertExists(header);
            bounds = header.getBoundingClientRect();
            result.rect = new DOMRect(result.rect.left, bounds.bottom, result.rect.width, 1);
        } else {
            result.flag = 1;
            const rows = getDatabaseBlockRowsElement(element);
            assertExists(rows);
            const rowsBounds = rows.getBoundingClientRect();
            if (point.y < rowsBounds.top || point.y > rowsBounds.bottom) return result;
            const elements = document.elementsFromPoint(point.x, point.y);
            const len = elements.length;
            let e;
            let i = 0;
            for(; i < len; i++){
                e = elements[i];
                if (e.classList.contains('affine-database-block-row-cell-content')) {
                    result.rect = getCellRect(e, bounds);
                    return result;
                }
                if (e.classList.contains('affine-database-block-row')) {
                    e = e.querySelector(ATTR_SELECTOR);
                    assertExists(e);
                    result.rect = getCellRect(e, bounds);
                    return result;
                }
            }
        }
    } else {
        const parent = element.parentElement;
        if (parent?.classList.contains('affine-database-block-row-cell-content')) {
            result.flag = 1;
            result.rect = getCellRect(parent);
            return result;
        }
    }
    return result;
}
function getCellRect(element, bounds) {
    if (!bounds) {
        const table = element.closest('.affine-database-block-table');
        assertExists(table);
        bounds = table.getBoundingClientRect();
    }
    const col = element.parentElement;
    assertExists(col);
    const row = col.parentElement;
    assertExists(row);
    const colRect = col.getBoundingClientRect();
    return new DOMRect(bounds.left, colRect.top, colRect.right - bounds.left, colRect.height);
}
export function hasClassNameInList(element, classList) {
    return classList.some((className)=>element.classList.contains(className));
}
