import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '../../ui/modal';
export const AuthModal = ({ children, open, setOpen })=>{
    return _jsx(Modal, {
        open: open,
        onOpenChange: setOpen,
        width: 400,
        minHeight: 500,
        contentOptions: {
            ['data-testid']: 'auth-modal',
            style: {
                padding: '44px 40px 0'
            }
        },
        children: children
    });
};
