import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Logo1IconBorder } from '@affine/component';
import { Divider } from '@affine/component/ui/divider';
import { MenuItem } from '@affine/component/ui/menu';
import { authAtom } from '@affine/core/components/atoms';
import { AuthService } from '@affine/core/modules/cloud';
import { CreateWorkspaceDialogService } from '@affine/core/modules/create-workspace';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { FeatureFlagService, useLiveData, useService, WorkspacesService } from '@toeverything/infra';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AddWorkspace } from './add-workspace';
import * as styles from './index.css';
import { UserAccountItem } from './user-account';
import { AFFiNEWorkspaceList } from './workspace-list';
export const SignInItem = ()=>{
    const setOpen = useSetAtom(authAtom);
    const t = useI18n();
    const onClickSignIn = useCallback(()=>{
        track.$.navigationPanel.workspaceList.signIn();
        setOpen((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpen
    ]);
    return _jsx(MenuItem, {
        className: styles.menuItem,
        onClick: onClickSignIn,
        "data-testid": "cloud-signin-button",
        children: _jsxs("div", {
            className: styles.signInWrapper,
            children: [
                _jsx("div", {
                    className: styles.iconContainer,
                    children: _jsx(Logo1IconBorder, {})
                }),
                _jsxs("div", {
                    className: styles.signInTextContainer,
                    children: [
                        _jsx("div", {
                            className: styles.signInTextPrimary,
                            children: t['com.affine.workspace.cloud.auth']()
                        }),
                        _jsx("div", {
                            className: styles.signInTextSecondary,
                            children: t['com.affine.workspace.cloud.description']()
                        })
                    ]
                })
            ]
        })
    });
};
const UserWithWorkspaceListInner = ({ onEventEnd, onClickWorkspace, onCreatedWorkspace, showSettingsButton, showEnableCloudButton })=>{
    const createWorkspaceDialogService = useService(CreateWorkspaceDialogService);
    const session = useLiveData(useService(AuthService).session.session$);
    const featureFlagService = useService(FeatureFlagService);
    const isAuthenticated = session.status === 'authenticated';
    const setOpenSignIn = useSetAtom(authAtom);
    const openSignInModal = useCallback(()=>{
        setOpenSignIn((state)=>({
                ...state,
                openModal: true
            }));
    }, [
        setOpenSignIn
    ]);
    const onNewWorkspace = useCallback(()=>{
        if (!isAuthenticated && !featureFlagService.flags.enable_local_workspace.value) {
            return openSignInModal();
        }
        track.$.navigationPanel.workspaceList.createWorkspace();
        createWorkspaceDialogService.dialog.open('new', (payload)=>{
            if (payload) {
                onCreatedWorkspace?.(payload);
            }
        });
        onEventEnd?.();
    }, [
        createWorkspaceDialogService,
        featureFlagService,
        isAuthenticated,
        onCreatedWorkspace,
        onEventEnd,
        openSignInModal
    ]);
    const onAddWorkspace = useCallback(()=>{
        track.$.navigationPanel.workspaceList.createWorkspace({
            control: 'import'
        });
        createWorkspaceDialogService.dialog.open('add', (payload)=>{
            if (payload) {
                onCreatedWorkspace?.(payload);
            }
        });
        onEventEnd?.();
    }, [
        createWorkspaceDialogService.dialog,
        onCreatedWorkspace,
        onEventEnd
    ]);
    const workspaceManager = useService(WorkspacesService);
    const workspaces = useLiveData(workspaceManager.list.workspaces$);
    return _jsxs("div", {
        className: styles.workspaceListWrapper,
        children: [
            isAuthenticated ? _jsx(UserAccountItem, {
                email: session.session.account.email ?? 'Unknown User',
                onEventEnd: onEventEnd
            }) : _jsx(SignInItem, {}),
            _jsx(Divider, {
                size: "thinner"
            }),
            _jsx(AFFiNEWorkspaceList, {
                onEventEnd: onEventEnd,
                onClickWorkspace: onClickWorkspace,
                showEnableCloudButton: showEnableCloudButton,
                showSettingsButton: showSettingsButton
            }),
            workspaces.length > 0 ? _jsx(Divider, {
                size: "thinner"
            }) : null,
            _jsx(AddWorkspace, {
                onAddWorkspace: onAddWorkspace,
                onNewWorkspace: onNewWorkspace
            })
        ]
    });
};
export const UserWithWorkspaceList = (props)=>{
    return _jsx(UserWithWorkspaceListInner, {
        ...props
    });
};
