import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSettingHelper } from '@affine/core/components/hooks/affine/use-app-setting-helper';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useService } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { WorkbenchService } from '../../services/workbench';
import { SplitViewPanel, SplitViewPanelContainer } from './panel';
import { ResizeHandle } from './resize-handle';
import * as styles from './split-view.css';
export const SplitView = ({ orientation = 'horizontal', className, views, renderer, onMove, ...attrs })=>{
    const rootRef = useRef(null);
    const [slots, setSlots] = useState({});
    const [resizingViewId, setResizingViewId] = useState(null);
    const { appSettings } = useAppSettingHelper();
    const workbench = useService(WorkbenchService).workbench;
    const sensors = useSensors(useSensor(PointerSensor, useMemo(()=>({
            activationConstraint: {
                distance: 0
            }
        }), [])));
    const onResizing = useCallback((index, { x, y })=>{
        const rootEl = rootRef.current;
        if (!rootEl) return;
        const rootRect = rootEl.getBoundingClientRect();
        const offset = orientation === 'horizontal' ? x : y;
        const total = orientation === 'horizontal' ? rootRect.width : rootRect.height;
        const percent = offset / total;
        workbench.resize(index, percent);
    }, [
        orientation,
        workbench
    ]);
    const resizeHandleRenderer = useCallback((view, index)=>index < views.length - 1 ? _jsx(ResizeHandle, {
            resizing: resizingViewId === view.id,
            onResizeStart: ()=>setResizingViewId(view.id),
            onResizeEnd: ()=>setResizingViewId(null),
            onResizing: (dxy)=>onResizing(index, dxy)
        }) : null, [
        onResizing,
        resizingViewId,
        views.length
    ]);
    const handleDragEnd = useCallback((event)=>{
        const { active, over } = event;
        if (active.id !== over?.id) {
            const fromIndex = views.findIndex((v)=>v.id === active.id);
            const toIndex = views.findIndex((v)=>v.id === over?.id);
            onMove?.(fromIndex, toIndex);
        }
    }, [
        onMove,
        views
    ]);
    return _jsxs("div", {
        ref: rootRef,
        className: clsx(styles.splitViewRoot, className),
        "data-orientation": orientation,
        "data-client-border": appSettings.clientBorder,
        ...attrs,
        children: [
            _jsx(DndContext, {
                sensors: sensors,
                collisionDetection: closestCenter,
                onDragEnd: handleDragEnd,
                children: _jsx(SortableContext, {
                    items: views,
                    strategy: horizontalListSortingStrategy,
                    children: views.map((view, index)=>_jsx(SplitViewPanel, {
                            view: view,
                            setSlots: setSlots,
                            children: resizeHandleRenderer(view, index)
                        }, view.id))
                })
            }),
            views.map((view, index)=>{
                const slot = slots[view.id]?.current;
                if (!slot) return null;
                return createPortal(renderer(view, index), slot, `portalToSplitViewPanel_${view.id}`);
            })
        ]
    });
};
export const SplitViewFallback = ({ children, className })=>{
    const { appSettings } = useAppSettingHelper();
    return _jsx("div", {
        className: clsx(styles.splitViewRoot, className),
        "data-client-border": appSettings.clientBorder,
        children: _jsx(SplitViewPanelContainer, {
            children: children
        })
    });
};
