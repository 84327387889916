import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DoneIcon } from '@blocksuite/icons/rc';
import clsx from 'clsx';
import { mobileMenuItem } from './mobile/styles.css';
import * as styles from './styles.css';
export const useMenuItem = ({ children: propsChildren, type = 'default', className: propsClassName, prefix, prefixIcon, suffix, suffixIcon, checked, selected, block, ...otherProps })=>{
    const className = clsx(styles.menuItem, {
        danger: type === 'danger',
        warning: type === 'warning',
        checked,
        selected,
        block,
        [mobileMenuItem]: BUILD_CONFIG.isMobileEdition
    }, propsClassName);
    const children = _jsxs(_Fragment, {
        children: [
            prefix,
            prefixIcon ? _jsx("div", {
                className: styles.menuItemIcon,
                children: prefixIcon
            }) : null,
            _jsx("span", {
                className: styles.menuSpan,
                children: propsChildren
            }),
            suffixIcon ? _jsx("div", {
                className: styles.menuItemIcon,
                children: suffixIcon
            }) : null,
            suffix,
            checked || selected ? _jsx("div", {
                className: clsx(styles.menuItemIcon, 'selected'),
                children: _jsx(DoneIcon, {})
            }) : null
        ]
    });
    return {
        children,
        className,
        otherProps
    };
};
