import { cssVar } from '@toeverything/theme';
import { createContext } from 'react';
export const managerContext = createContext();
const tagColorIds = [
    'Red',
    'Magenta',
    'Orange',
    'Yellow',
    'Green',
    'Teal',
    'Blue',
    'Purple',
    'Grey',
    'Black'
];
export const tagColors = tagColorIds.map((color)=>[
        color,
        cssVar(`paletteLine${color}`)
    ]);
