export var ChatHistoryOrder;
(function(ChatHistoryOrder) {
    ChatHistoryOrder["asc"] = "asc";
    ChatHistoryOrder["desc"] = "desc";
})(ChatHistoryOrder || (ChatHistoryOrder = {}));
export var ContentReader;
(function(ContentReader) {
    ContentReader["EPUB"] = "EPUB";
    ContentReader["PDF"] = "PDF";
    ContentReader["WEB"] = "WEB";
})(ContentReader || (ContentReader = {}));
export var CopilotModels;
(function(CopilotModels) {
    CopilotModels["DallE3"] = "DallE3";
    CopilotModels["Gpt4Omni"] = "Gpt4Omni";
    CopilotModels["Gpt4OmniMini"] = "Gpt4OmniMini";
    CopilotModels["TextEmbedding3Large"] = "TextEmbedding3Large";
    CopilotModels["TextEmbedding3Small"] = "TextEmbedding3Small";
    CopilotModels["TextEmbeddingAda002"] = "TextEmbeddingAda002";
    CopilotModels["TextModerationLatest"] = "TextModerationLatest";
    CopilotModels["TextModerationStable"] = "TextModerationStable";
})(CopilotModels || (CopilotModels = {}));
export var CopilotPromptMessageRole;
(function(CopilotPromptMessageRole) {
    CopilotPromptMessageRole["assistant"] = "assistant";
    CopilotPromptMessageRole["system"] = "system";
    CopilotPromptMessageRole["user"] = "user";
})(CopilotPromptMessageRole || (CopilotPromptMessageRole = {}));
export var DirectionalityType;
(function(DirectionalityType) {
    DirectionalityType["LTR"] = "LTR";
    DirectionalityType["RTL"] = "RTL";
})(DirectionalityType || (DirectionalityType = {}));
export var ErrorCode;
(function(ErrorCode) {
    ErrorCode["BAD_REQUEST"] = "BAD_REQUEST";
    ErrorCode["FORBIDDEN"] = "FORBIDDEN";
    ErrorCode["NOT_FOUND"] = "NOT_FOUND";
    ErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
})(ErrorCode || (ErrorCode = {}));
export var ErrorNames;
(function(ErrorNames) {
    ErrorNames["ACCESS_DENIED"] = "ACCESS_DENIED";
    ErrorNames["ACTION_FORBIDDEN"] = "ACTION_FORBIDDEN";
    ErrorNames["ALREADY_IN_SPACE"] = "ALREADY_IN_SPACE";
    ErrorNames["AUTHENTICATION_REQUIRED"] = "AUTHENTICATION_REQUIRED";
    ErrorNames["BLOB_NOT_FOUND"] = "BLOB_NOT_FOUND";
    ErrorNames["BLOB_QUOTA_EXCEEDED"] = "BLOB_QUOTA_EXCEEDED";
    ErrorNames["CANNOT_DELETE_ALL_ADMIN_ACCOUNT"] = "CANNOT_DELETE_ALL_ADMIN_ACCOUNT";
    ErrorNames["CANNOT_DELETE_OWN_ACCOUNT"] = "CANNOT_DELETE_OWN_ACCOUNT";
    ErrorNames["CANT_CHANGE_SPACE_OWNER"] = "CANT_CHANGE_SPACE_OWNER";
    ErrorNames["CANT_UPDATE_LIFETIME_SUBSCRIPTION"] = "CANT_UPDATE_LIFETIME_SUBSCRIPTION";
    ErrorNames["CAPTCHA_VERIFICATION_FAILED"] = "CAPTCHA_VERIFICATION_FAILED";
    ErrorNames["COPILOT_ACTION_TAKEN"] = "COPILOT_ACTION_TAKEN";
    ErrorNames["COPILOT_FAILED_TO_CREATE_MESSAGE"] = "COPILOT_FAILED_TO_CREATE_MESSAGE";
    ErrorNames["COPILOT_FAILED_TO_GENERATE_TEXT"] = "COPILOT_FAILED_TO_GENERATE_TEXT";
    ErrorNames["COPILOT_MESSAGE_NOT_FOUND"] = "COPILOT_MESSAGE_NOT_FOUND";
    ErrorNames["COPILOT_PROMPT_INVALID"] = "COPILOT_PROMPT_INVALID";
    ErrorNames["COPILOT_PROMPT_NOT_FOUND"] = "COPILOT_PROMPT_NOT_FOUND";
    ErrorNames["COPILOT_PROVIDER_SIDE_ERROR"] = "COPILOT_PROVIDER_SIDE_ERROR";
    ErrorNames["COPILOT_QUOTA_EXCEEDED"] = "COPILOT_QUOTA_EXCEEDED";
    ErrorNames["COPILOT_SESSION_DELETED"] = "COPILOT_SESSION_DELETED";
    ErrorNames["COPILOT_SESSION_NOT_FOUND"] = "COPILOT_SESSION_NOT_FOUND";
    ErrorNames["CUSTOMER_PORTAL_CREATE_FAILED"] = "CUSTOMER_PORTAL_CREATE_FAILED";
    ErrorNames["DOC_ACCESS_DENIED"] = "DOC_ACCESS_DENIED";
    ErrorNames["DOC_HISTORY_NOT_FOUND"] = "DOC_HISTORY_NOT_FOUND";
    ErrorNames["DOC_NOT_FOUND"] = "DOC_NOT_FOUND";
    ErrorNames["EARLY_ACCESS_REQUIRED"] = "EARLY_ACCESS_REQUIRED";
    ErrorNames["EMAIL_ALREADY_USED"] = "EMAIL_ALREADY_USED";
    ErrorNames["EMAIL_TOKEN_NOT_FOUND"] = "EMAIL_TOKEN_NOT_FOUND";
    ErrorNames["EMAIL_VERIFICATION_REQUIRED"] = "EMAIL_VERIFICATION_REQUIRED";
    ErrorNames["EXPECT_TO_PUBLISH_PAGE"] = "EXPECT_TO_PUBLISH_PAGE";
    ErrorNames["EXPECT_TO_REVOKE_PUBLIC_PAGE"] = "EXPECT_TO_REVOKE_PUBLIC_PAGE";
    ErrorNames["FAILED_TO_CHECKOUT"] = "FAILED_TO_CHECKOUT";
    ErrorNames["FAILED_TO_SAVE_UPDATES"] = "FAILED_TO_SAVE_UPDATES";
    ErrorNames["FAILED_TO_UPSERT_SNAPSHOT"] = "FAILED_TO_UPSERT_SNAPSHOT";
    ErrorNames["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    ErrorNames["INVALID_EMAIL"] = "INVALID_EMAIL";
    ErrorNames["INVALID_EMAIL_TOKEN"] = "INVALID_EMAIL_TOKEN";
    ErrorNames["INVALID_HISTORY_TIMESTAMP"] = "INVALID_HISTORY_TIMESTAMP";
    ErrorNames["INVALID_OAUTH_CALLBACK_STATE"] = "INVALID_OAUTH_CALLBACK_STATE";
    ErrorNames["INVALID_PASSWORD_LENGTH"] = "INVALID_PASSWORD_LENGTH";
    ErrorNames["INVALID_RUNTIME_CONFIG_TYPE"] = "INVALID_RUNTIME_CONFIG_TYPE";
    ErrorNames["LINK_EXPIRED"] = "LINK_EXPIRED";
    ErrorNames["MAILER_SERVICE_IS_NOT_CONFIGURED"] = "MAILER_SERVICE_IS_NOT_CONFIGURED";
    ErrorNames["MEMBER_QUOTA_EXCEEDED"] = "MEMBER_QUOTA_EXCEEDED";
    ErrorNames["MISSING_OAUTH_QUERY_PARAMETER"] = "MISSING_OAUTH_QUERY_PARAMETER";
    ErrorNames["NOT_FOUND"] = "NOT_FOUND";
    ErrorNames["NOT_IN_SPACE"] = "NOT_IN_SPACE";
    ErrorNames["NO_COPILOT_PROVIDER_AVAILABLE"] = "NO_COPILOT_PROVIDER_AVAILABLE";
    ErrorNames["OAUTH_ACCOUNT_ALREADY_CONNECTED"] = "OAUTH_ACCOUNT_ALREADY_CONNECTED";
    ErrorNames["OAUTH_STATE_EXPIRED"] = "OAUTH_STATE_EXPIRED";
    ErrorNames["PAGE_IS_NOT_PUBLIC"] = "PAGE_IS_NOT_PUBLIC";
    ErrorNames["PASSWORD_REQUIRED"] = "PASSWORD_REQUIRED";
    ErrorNames["RUNTIME_CONFIG_NOT_FOUND"] = "RUNTIME_CONFIG_NOT_FOUND";
    ErrorNames["SAME_EMAIL_PROVIDED"] = "SAME_EMAIL_PROVIDED";
    ErrorNames["SAME_SUBSCRIPTION_RECURRING"] = "SAME_SUBSCRIPTION_RECURRING";
    ErrorNames["SIGN_UP_FORBIDDEN"] = "SIGN_UP_FORBIDDEN";
    ErrorNames["SPACE_ACCESS_DENIED"] = "SPACE_ACCESS_DENIED";
    ErrorNames["SPACE_NOT_FOUND"] = "SPACE_NOT_FOUND";
    ErrorNames["SPACE_OWNER_NOT_FOUND"] = "SPACE_OWNER_NOT_FOUND";
    ErrorNames["SUBSCRIPTION_ALREADY_EXISTS"] = "SUBSCRIPTION_ALREADY_EXISTS";
    ErrorNames["SUBSCRIPTION_EXPIRED"] = "SUBSCRIPTION_EXPIRED";
    ErrorNames["SUBSCRIPTION_HAS_BEEN_CANCELED"] = "SUBSCRIPTION_HAS_BEEN_CANCELED";
    ErrorNames["SUBSCRIPTION_NOT_EXISTS"] = "SUBSCRIPTION_NOT_EXISTS";
    ErrorNames["SUBSCRIPTION_PLAN_NOT_FOUND"] = "SUBSCRIPTION_PLAN_NOT_FOUND";
    ErrorNames["TOO_MANY_REQUEST"] = "TOO_MANY_REQUEST";
    ErrorNames["UNKNOWN_OAUTH_PROVIDER"] = "UNKNOWN_OAUTH_PROVIDER";
    ErrorNames["UNSPLASH_IS_NOT_CONFIGURED"] = "UNSPLASH_IS_NOT_CONFIGURED";
    ErrorNames["USER_AVATAR_NOT_FOUND"] = "USER_AVATAR_NOT_FOUND";
    ErrorNames["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorNames["VERSION_REJECTED"] = "VERSION_REJECTED";
    ErrorNames["WRONG_SIGN_IN_CREDENTIALS"] = "WRONG_SIGN_IN_CREDENTIALS";
    ErrorNames["WRONG_SIGN_IN_METHOD"] = "WRONG_SIGN_IN_METHOD";
})(ErrorNames || (ErrorNames = {}));
export var FeatureType;
(function(FeatureType) {
    FeatureType["AIEarlyAccess"] = "AIEarlyAccess";
    FeatureType["Admin"] = "Admin";
    FeatureType["Copilot"] = "Copilot";
    FeatureType["EarlyAccess"] = "EarlyAccess";
    FeatureType["UnlimitedCopilot"] = "UnlimitedCopilot";
    FeatureType["UnlimitedWorkspace"] = "UnlimitedWorkspace";
})(FeatureType || (FeatureType = {}));
export var FetchContentType;
(function(FetchContentType) {
    FetchContentType["ALWAYS"] = "ALWAYS";
    FetchContentType["NEVER"] = "NEVER";
    FetchContentType["WHEN_EMPTY"] = "WHEN_EMPTY";
})(FetchContentType || (FetchContentType = {}));
export var InvoiceStatus;
(function(InvoiceStatus) {
    InvoiceStatus["Draft"] = "Draft";
    InvoiceStatus["Open"] = "Open";
    InvoiceStatus["Paid"] = "Paid";
    InvoiceStatus["Uncollectible"] = "Uncollectible";
    InvoiceStatus["Void"] = "Void";
})(InvoiceStatus || (InvoiceStatus = {}));
export var OAuthProviderType;
(function(OAuthProviderType) {
    OAuthProviderType["GitHub"] = "GitHub";
    OAuthProviderType["Google"] = "Google";
    OAuthProviderType["OIDC"] = "OIDC";
})(OAuthProviderType || (OAuthProviderType = {}));
export var PageType;
(function(PageType) {
    PageType["ARTICLE"] = "ARTICLE";
    PageType["BOOK"] = "BOOK";
    PageType["FILE"] = "FILE";
    PageType["HIGHLIGHTS"] = "HIGHLIGHTS";
    PageType["IMAGE"] = "IMAGE";
    PageType["PROFILE"] = "PROFILE";
    PageType["TWEET"] = "TWEET";
    PageType["UNKNOWN"] = "UNKNOWN";
    PageType["VIDEO"] = "VIDEO";
    PageType["WEBSITE"] = "WEBSITE";
})(PageType || (PageType = {}));
export var Permission;
(function(Permission) {
    Permission["Admin"] = "Admin";
    Permission["Owner"] = "Owner";
    Permission["Read"] = "Read";
    Permission["Write"] = "Write";
})(Permission || (Permission = {}));
export var PublicPageMode;
(function(PublicPageMode) {
    PublicPageMode["Edgeless"] = "Edgeless";
    PublicPageMode["Page"] = "Page";
})(PublicPageMode || (PublicPageMode = {}));
export var RuntimeConfigType;
(function(RuntimeConfigType) {
    RuntimeConfigType["Array"] = "Array";
    RuntimeConfigType["Boolean"] = "Boolean";
    RuntimeConfigType["Number"] = "Number";
    RuntimeConfigType["Object"] = "Object";
    RuntimeConfigType["String"] = "String";
})(RuntimeConfigType || (RuntimeConfigType = {}));
export var SearchErrorCode;
(function(SearchErrorCode) {
    SearchErrorCode["QUERY_TOO_LONG"] = "QUERY_TOO_LONG";
    SearchErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
})(SearchErrorCode || (SearchErrorCode = {}));
export var ServerDeploymentType;
(function(ServerDeploymentType) {
    ServerDeploymentType["Affine"] = "Affine";
    ServerDeploymentType["Selfhosted"] = "Selfhosted";
})(ServerDeploymentType || (ServerDeploymentType = {}));
export var ServerFeature;
(function(ServerFeature) {
    ServerFeature["Captcha"] = "Captcha";
    ServerFeature["Copilot"] = "Copilot";
    ServerFeature["OAuth"] = "OAuth";
    ServerFeature["Payment"] = "Payment";
})(ServerFeature || (ServerFeature = {}));
export var SubscribeErrorCode;
(function(SubscribeErrorCode) {
    SubscribeErrorCode["ALREADY_SUBSCRIBED"] = "ALREADY_SUBSCRIBED";
    SubscribeErrorCode["BAD_REQUEST"] = "BAD_REQUEST";
    SubscribeErrorCode["EXCEEDED_MAX_SUBSCRIPTIONS"] = "EXCEEDED_MAX_SUBSCRIPTIONS";
    SubscribeErrorCode["NOT_FOUND"] = "NOT_FOUND";
    SubscribeErrorCode["UNAUTHORIZED"] = "UNAUTHORIZED";
})(SubscribeErrorCode || (SubscribeErrorCode = {}));
export var SubscriptionPlan;
(function(SubscriptionPlan) {
    SubscriptionPlan["AI"] = "AI";
    SubscriptionPlan["Enterprise"] = "Enterprise";
    SubscriptionPlan["Free"] = "Free";
    SubscriptionPlan["Pro"] = "Pro";
    SubscriptionPlan["SelfHosted"] = "SelfHosted";
    SubscriptionPlan["Team"] = "Team";
})(SubscriptionPlan || (SubscriptionPlan = {}));
export var SubscriptionRecurring;
(function(SubscriptionRecurring) {
    SubscriptionRecurring["Lifetime"] = "Lifetime";
    SubscriptionRecurring["Monthly"] = "Monthly";
    SubscriptionRecurring["Yearly"] = "Yearly";
})(SubscriptionRecurring || (SubscriptionRecurring = {}));
export var SubscriptionStatus;
(function(SubscriptionStatus) {
    SubscriptionStatus["Active"] = "Active";
    SubscriptionStatus["Canceled"] = "Canceled";
    SubscriptionStatus["Incomplete"] = "Incomplete";
    SubscriptionStatus["IncompleteExpired"] = "IncompleteExpired";
    SubscriptionStatus["PastDue"] = "PastDue";
    SubscriptionStatus["Paused"] = "Paused";
    SubscriptionStatus["Trialing"] = "Trialing";
    SubscriptionStatus["Unpaid"] = "Unpaid";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export var SubscriptionType;
(function(SubscriptionType) {
    SubscriptionType["NEWSLETTER"] = "NEWSLETTER";
    SubscriptionType["RSS"] = "RSS";
})(SubscriptionType || (SubscriptionType = {}));
