import Graphemer from 'graphemer';
export function extractEmojiIcon(text) {
    const isStartsWithEmoji = /^(\p{Emoji_Presentation})/u.test(text);
    if (isStartsWithEmoji) {
        const emojiEnd = Graphemer.nextBreak(text, 0);
        return {
            emoji: text.substring(0, emojiEnd),
            rest: text.substring(emojiEnd)
        };
    }
    return {
        emoji: null,
        rest: text
    };
}
