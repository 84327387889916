import { HoverController } from '@blocksuite/affine-components/hover';
import { WidgetComponent } from '@blocksuite/block-std';
import { sleep } from '@blocksuite/global/utils';
import { offset } from '@floating-ui/dom';
import { computed } from '@preact/signals-core';
import { html } from 'lit';
export const AFFINE_CODE_LANGUAGE_LIST_WIDGET = 'affine-code-language-list-widget';
export class AffineCodeLanguageListWidget extends WidgetComponent {
    connectedCallback() {
        super.connectedCallback();
        this._hoverController.setReference(this.block);
        this._hoverController.onAbort = ()=>{
            if (this._isActivated) return;
            this._hoverController.abort();
            return;
        };
    }
    constructor(...args){
        super(...args), this._hoverController = new HoverController(this, ()=>{
            if (!this._shouldDisplay.value) {
                return null;
            }
            return {
                template: html`<language-list-button
          .blockComponent=${this.block}
          .onActiveStatusChange=${async (active)=>{
                    this._isActivated = active;
                    if (!active) {
                        if (this._hoverController.isHovering) return;
                        await sleep(1000);
                        if (this._hoverController.isHovering || this._isActivated) return;
                        this._hoverController.abort();
                    }
                }}
        >
        </language-list-button>`,
                portalStyles: {
                    zIndex: 'var(--affine-z-index-popover)'
                },
                container: this.block,
                computePosition: {
                    referenceElement: this.block,
                    placement: 'left-start',
                    middleware: [
                        offset({
                            mainAxis: -5,
                            crossAxis: 5
                        })
                    ],
                    autoUpdate: true
                }
            };
        }, {
            allowMultiple: true
        }), this._isActivated = false, this._shouldDisplay = computed(()=>{
            const selection = this.host.selection;
            const textSelection = selection.find('text');
            const hasTextSelection = !!textSelection && (!!textSelection.to || !!textSelection.from.length);
            if (hasTextSelection) {
                return false;
            }
            const blockSelections = selection.filter('block');
            const hasMultipleBlockSelections = blockSelections.length > 1 || blockSelections.length === 1 && blockSelections[0].blockId !== this.block.blockId;
            if (hasMultipleBlockSelections) {
                return false;
            }
            return true;
        });
    }
}
