import { Service } from '@toeverything/infra';
import { ExplorerSection } from '../entities/explore-section';
const allSectionName = [
    'recent',
    'favorites',
    'organize',
    'collections',
    'tags',
    'feeds',
    'favoritesOld',
    'migrationFavorites'
];
export class ExplorerService extends Service {
    constructor(...args){
        super(...args), this.sections = allSectionName.reduce((prev, name)=>Object.assign(prev, {
                [name]: this.framework.createEntity(ExplorerSection, {
                    name
                })
            }), {});
    }
}
