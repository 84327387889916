import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, RadioGroup } from '@affine/component';
import { useAllPageListConfig } from '@affine/core/components/hooks/affine/use-all-page-list-config';
import { useI18n } from '@affine/i18n';
import { useCallback, useMemo, useState } from 'react';
import * as styles from './edit-collection.css';
import { RulesMode } from './rules-mode';
import { SelectPage } from './select-page';
const contentOptions = {
    style: {
        padding: 0,
        maxWidth: 944,
        backgroundColor: 'var(--affine-background-primary-color)'
    }
};
export const EditCollectionModal = ({ init, onConfirm, open, onOpenChange, title, mode })=>{
    const t = useI18n();
    const onConfirmOnCollection = useCallback((view)=>{
        onConfirm(view);
        onOpenChange(false);
    }, [
        onConfirm,
        onOpenChange
    ]);
    const onCancel = useCallback(()=>{
        onOpenChange(false);
    }, [
        onOpenChange
    ]);
    if (!(open && init)) {
        return null;
    }
    return _jsx(Modal, {
        open: open,
        onOpenChange: onOpenChange,
        withoutCloseButton: true,
        width: "calc(100% - 64px)",
        height: "80%",
        contentOptions: contentOptions,
        persistent: true,
        children: _jsx(EditCollection, {
            title: title,
            onConfirmText: t['com.affine.editCollection.save'](),
            init: init,
            mode: mode,
            onCancel: onCancel,
            onConfirm: onConfirmOnCollection
        })
    });
};
export const EditCollection = ({ init, onConfirm, onCancel, onConfirmText, mode: initMode })=>{
    const t = useI18n();
    const config = useAllPageListConfig();
    const [value, onChange] = useState(init);
    const [mode, setMode] = useState(initMode ?? (init.filterList.length === 0 ? 'page' : 'rule'));
    const isNameEmpty = useMemo(()=>value.name.trim().length === 0, [
        value
    ]);
    const onSaveCollection = useCallback(()=>{
        if (!isNameEmpty) {
            onConfirm(value);
        }
    }, [
        value,
        isNameEmpty,
        onConfirm
    ]);
    const reset = useCallback(()=>{
        onChange({
            ...value,
            filterList: init.filterList,
            allowList: init.allowList
        });
    }, [
        init.allowList,
        init.filterList,
        value
    ]);
    const onIdsChange = useCallback((ids)=>{
        onChange({
            ...value,
            allowList: ids
        });
    }, [
        value
    ]);
    const buttons = useMemo(()=>_jsxs(_Fragment, {
            children: [
                _jsx(Button, {
                    onClick: onCancel,
                    className: styles.actionButton,
                    children: t['com.affine.editCollection.button.cancel']()
                }),
                _jsx(Button, {
                    className: styles.actionButton,
                    "data-testid": "save-collection",
                    variant: "primary",
                    disabled: isNameEmpty,
                    onClick: onSaveCollection,
                    children: onConfirmText ?? t['com.affine.editCollection.button.create']()
                })
            ]
        }), [
        onCancel,
        t,
        isNameEmpty,
        onSaveCollection,
        onConfirmText
    ]);
    const switchMode = useMemo(()=>_jsx(RadioGroup, {
            style: {
                minWidth: 158
            },
            value: mode,
            onChange: setMode,
            items: [
                {
                    value: 'page',
                    label: t['com.affine.editCollection.pages'](),
                    testId: 'edit-collection-pages-button'
                },
                {
                    value: 'rule',
                    label: t['com.affine.editCollection.rules'](),
                    testId: 'edit-collection-rules-button'
                }
            ]
        }, "mode-switcher"), [
        mode,
        t
    ]);
    return _jsx("div", {
        onKeyDown: (e)=>{
            if (e.key === 'Escape') {
                return;
            }
            e.stopPropagation();
        },
        className: styles.collectionEditContainer,
        children: mode === 'page' ? _jsx(SelectPage, {
            init: value.allowList,
            onChange: onIdsChange,
            header: switchMode,
            buttons: buttons
        }) : _jsx(RulesMode, {
            allPageListConfig: config,
            collection: value,
            switchMode: switchMode,
            reset: reset,
            updateCollection: onChange,
            buttons: buttons
        })
    });
};
