import { markdownToMindmap, MiniMindmapPreview } from '@blocksuite/affine/blocks';
import { noop } from '@blocksuite/affine/global/utils';
import { html, nothing } from 'lit';
import { getAIPanel } from '../ai-panel';
noop(MiniMindmapPreview);
export const createMindmapRenderer = (host, ctx, style)=>{
    return (answer, state)=>{
        if (state === 'generating') {
            const panel = getAIPanel(host);
            panel.generatingElement?.updateLoadingProgress(2);
        }
        if (state !== 'finished' && state !== 'error') {
            return nothing;
        }
        return html`<mini-mindmap-preview
      .ctx=${ctx}
      .host=${host}
      .answer=${answer}
      .mindmapStyle=${style}
      .templateShow=${style === undefined}
      .height=${300}
    ></mini-mindmap-preview>`;
    };
};
export const createMindmapExecuteRenderer = (host, ctx, handler)=>{
    return (answer, state)=>{
        if (state !== 'finished') {
            const panel = getAIPanel(host);
            panel.generatingElement?.updateLoadingProgress(2);
            return nothing;
        }
        ctx.set({
            node: markdownToMindmap(answer, host.doc)
        });
        handler(ctx);
        return nothing;
    };
};
