export const indentBlocks = (ctx, next)=>{
    let { blockIds } = ctx;
    const { std, stopCapture = true } = ctx;
    const { doc } = std;
    const { schema } = doc;
    if (!blockIds || !blockIds.length) {
        const text = std.selection.find('text');
        if (text) {
            blockIds = [
                text.from.blockId,
                text.to?.blockId
            ].filter((x)=>!!x);
        } else {
            blockIds = std.selection.getGroup('note').map((sel)=>sel.blockId);
        }
    }
    if (!blockIds || !blockIds.length || doc.readonly) return;
    if (blockIds.length === 1) {
        const block = doc.getBlock(blockIds[0]);
        if (!block || block.model.text) return;
    }
    let firstIndentIndex = -1;
    let previousSibling = null;
    for(let i = 0; i < blockIds.length; i++){
        previousSibling = doc.getPrev(blockIds[i]);
        const model = doc.getBlock(blockIds[i])?.model;
        if (model && previousSibling && schema.isValid(model.flavour, previousSibling.flavour)) {
            firstIndentIndex = i;
            break;
        }
    }
    if (firstIndentIndex === -1) return;
    if (stopCapture) doc.captureSync();
    const indentIds = blockIds.slice(firstIndentIndex);
    indentIds.forEach((id)=>{
        const parent = doc.getParent(id);
        if (!parent) return;
        if (!indentIds.includes(parent.id)) {
            std.command.exec('indentBlock', {
                blockId: id,
                stopCapture: false
            });
        }
    });
    return next();
};
