function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _init__isResizing, _init_backgroundElm, _init_edgelessTool, _init_gfxViewportElm, _init_mountElm, _init_selectedRect, _init_surface, _initProto;
import { CommonUtils } from '@blocksuite/affine-block-surface';
import { focusTextModel } from '@blocksuite/affine-components/rich-text';
import { toast } from '@blocksuite/affine-components/toast';
import { NoteDisplayMode } from '@blocksuite/affine-model';
import { EditPropsStore, FontLoaderService, TelemetryProvider } from '@blocksuite/affine-shared/services';
import { ThemeObserver } from '@blocksuite/affine-shared/theme';
import { handleNativeRangeAtPoint, humanFileSize, isTouchPadPinchEvent, requestConnectedFrame, requestThrottledConnectedFrame } from '@blocksuite/affine-shared/utils';
import { BlockComponent } from '@blocksuite/block-std';
import { GfxBlockElementModel } from '@blocksuite/block-std/gfx';
import { IS_WINDOWS } from '@blocksuite/global/env';
import { assertExists, Bound, Point, serializeXYWH, throttle, Vec } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { query, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { EMBED_CARD_HEIGHT, EMBED_CARD_WIDTH } from '../../_common/consts.js';
import { isSelectSingleMindMap } from '../../_common/edgeless/mindmap/index.js';
import { setAttachmentUploaded, setAttachmentUploading } from '../../attachment-block/utils.js';
import { EdgelessClipboardController } from './clipboard/clipboard.js';
import { EdgelessToolbar } from './components/toolbar/edgeless-toolbar.js';
import { calcBoundByOrigin, readImageSize } from './components/utils.js';
import { EdgelessPageKeyboardManager } from './edgeless-keyboard.js';
import { BrushToolController, ConnectorToolController, CopilotSelectionController, DefaultToolController, EraserToolController, FrameToolController, LassoToolController, MindmapToolController, NoteToolController, PanToolController, PresentToolController, ShapeToolController, TemplateToolController, TextToolController } from './tools/index.js';
import { edgelessElementsBound } from './utils/bound-utils.js';
import { DEFAULT_NOTE_HEIGHT, DEFAULT_NOTE_OFFSET_X, DEFAULT_NOTE_OFFSET_Y, DEFAULT_NOTE_WIDTH } from './utils/consts.js';
import { getBackgroundGrid, isCanvasElement } from './utils/query.js';
import { mountShapeTextEditor } from './utils/text.js';
const { normalizeWheelDeltaY } = CommonUtils;
_dec = state(), _dec1 = query('.edgeless-background'), _dec2 = state(), _dec3 = query('gfx-viewport'), _dec4 = query('.edgeless-mount-point'), _dec5 = query('edgeless-selected-rect'), _dec6 = query('affine-surface');
export class EdgelessRootBlockComponent extends BlockComponent {
    static{
        ({ e: [_init__isResizing, _init_backgroundElm, _init_edgelessTool, _init_gfxViewportElm, _init_mountElm, _init_selectedRect, _init_surface, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_isResizing"
            ],
            [
                _dec1,
                1,
                "backgroundElm"
            ],
            [
                _dec2,
                1,
                "edgelessTool"
            ],
            [
                _dec3,
                1,
                "gfxViewportElm"
            ],
            [
                _dec4,
                1,
                "mountElm"
            ],
            [
                _dec5,
                1,
                "selectedRect"
            ],
            [
                _dec6,
                1,
                "surface"
            ]
        ], []));
    }
    static{
        this.styles = css`
    affine-edgeless-root {
      -webkit-user-select: none;
      user-select: none;
      display: block;
      height: 100%;
      touch-action: none;
    }

    .widgets-container {
      position: absolute;
      left: 0;
      top: 0;
      contain: size layout;
      z-index: 1;
      height: 100%;
    }

    .edgeless-background {
      height: 100%;
      background-color: var(--affine-background-primary-color);
      background-image: radial-gradient(
        var(--affine-edgeless-grid-color) 1px,
        var(--affine-background-primary-color) 1px
      );
    }

    .edgeless-container {
      color: var(--affine-text-primary-color);
    }

    @media print {
      .selected {
        background-color: transparent !important;
      }
    }
  `;
    }
    get dispatcher() {
        return this.service?.uiEventDispatcher;
    }
    get slots() {
        return this.service.slots;
    }
    get surfaceBlockModel() {
        return this.model.children.find((child)=>child.flavour === 'affine:surface');
    }
    get tools() {
        return this.service.tool;
    }
    get viewport() {
        const { scrollLeft, scrollTop, scrollWidth, scrollHeight, clientWidth, clientHeight } = this.viewportElement;
        const { top, left } = this.viewportElement.getBoundingClientRect();
        return {
            top,
            left,
            scrollLeft,
            scrollTop,
            scrollWidth,
            scrollHeight,
            clientWidth,
            clientHeight
        };
    }
    get viewportElement() {
        if (this._viewportElement) return this._viewportElement;
        this._viewportElement = this.host.closest('.affine-edgeless-viewport');
        assertExists(this._viewportElement);
        return this._viewportElement;
    }
    _handleToolbarFlag() {
        const createToolbar = ()=>{
            const toolbar = new EdgelessToolbar(this);
            this.append(toolbar);
            this.components.toolbar = toolbar;
        };
        if (!this.components.toolbar) {
            createToolbar();
        }
    }
    _initFontLoader() {
        this.std.get(FontLoaderService).ready.then(()=>{
            this.surface.refresh();
        }).catch(console.error);
    }
    _initLayerUpdateEffect() {
        const updateLayers = requestThrottledConnectedFrame(()=>{
            const blocks = Array.from(this.gfxViewportElm.children);
            blocks.forEach((block)=>{
                block.updateZIndex?.();
            });
        });
        this._disposables.add(this.service.layer.slots.layerUpdated.on(()=>updateLayers()));
    }
    _initPanEvent() {
        this.disposables.add(this.dispatcher.add('pan', (ctx)=>{
            const { viewport } = this.service;
            if (viewport.locked) return;
            const multiPointersState = ctx.get('multiPointerState');
            const [p1, p2] = multiPointersState.pointers;
            const dx = 0.5 * (p1.delta.x + p2.delta.x) / viewport.zoom / viewport.scale;
            const dy = 0.5 * (p1.delta.y + p2.delta.y) / viewport.zoom / viewport.scale;
            viewport.applyDeltaCenter(-dx, -dy);
        }));
    }
    _initPinchEvent() {
        this.disposables.add(this.dispatcher.add('pinch', (ctx)=>{
            const { viewport } = this.service;
            if (viewport.locked) return;
            const multiPointersState = ctx.get('multiPointerState');
            const [p1, p2] = multiPointersState.pointers;
            const currentCenter = new Point(0.5 * (p1.x + p2.x), 0.5 * (p1.y + p2.y));
            const lastDistance = Vec.dist([
                p1.x - p1.delta.x,
                p1.y - p1.delta.y
            ], [
                p2.x - p2.delta.x,
                p2.y - p2.delta.y
            ]);
            const currentDistance = Vec.dist([
                p1.x,
                p1.y
            ], [
                p2.x,
                p2.y
            ]);
            const zoom = currentDistance / lastDistance * viewport.zoom;
            const [baseX, baseY] = viewport.toModelCoord(currentCenter.x, currentCenter.y);
            viewport.setZoom(zoom, new Point(baseX, baseY));
            return false;
        }));
    }
    _initPixelRatioChangeEffect() {
        let media;
        const onPixelRatioChange = ()=>{
            if (media) {
                this.service.viewport.onResize();
                media.removeEventListener('change', onPixelRatioChange);
            }
            media = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
            media.addEventListener('change', onPixelRatioChange);
        };
        onPixelRatioChange();
        this._disposables.add(()=>{
            media?.removeEventListener('change', onPixelRatioChange);
        });
    }
    _initRemoteCursor() {
        let rafId = null;
        const setRemoteCursor = (pos)=>{
            if (rafId) cancelAnimationFrame(rafId);
            rafId = requestConnectedFrame(()=>{
                if (!this.service?.viewport) return;
                const cursorPosition = this.service.viewport.toModelCoord(pos.x, pos.y);
                this.service.selection.setCursor({
                    x: cursorPosition[0],
                    y: cursorPosition[1]
                });
                rafId = null;
            }, this);
        };
        this.handleEvent('pointerMove', (e)=>{
            const pointerEvent = e.get('pointerState');
            setRemoteCursor(pointerEvent);
        });
    }
    _initResizeEffect() {
        const resizeObserver = new ResizeObserver((_)=>{
            if (!this.service || !this.service.selection || !this.service.viewport) {
                console.error('Service not ready');
                return;
            }
            this.service.selection.set(this.service.selection.surfaceSelections);
            this.service.viewport.onResize();
        });
        resizeObserver.observe(this.viewportElement);
        this._resizeObserver = resizeObserver;
    }
    _initSlotEffects() {
        const { disposables, slots } = this;
        this.disposables.add(ThemeObserver.instance.mode$.subscribe(()=>this.surface.refresh()));
        disposables.add(this.service.selection);
        disposables.add(slots.edgelessToolUpdated.on((tool)=>{
            this.edgelessTool = tool;
        }));
        disposables.add(slots.cursorUpdated.on(throttle((cursor)=>{
            this.style.cursor = cursor;
        }, 144)));
        let canCopyAsPng = true;
        disposables.add(slots.copyAsPng.on(({ blocks, shapes })=>{
            if (!canCopyAsPng) return;
            canCopyAsPng = false;
            this.clipboardController.copyAsPng(blocks, shapes).then(()=>toast(this.host, 'Copied to clipboard')).catch(()=>toast(this.host, 'Failed to copy as PNG')).finally(()=>{
                canCopyAsPng = true;
            });
        }));
    }
    _initTools() {
        const tools = [
            DefaultToolController,
            BrushToolController,
            EraserToolController,
            TextToolController,
            ShapeToolController,
            ConnectorToolController,
            NoteToolController,
            FrameToolController,
            PanToolController,
            PresentToolController,
            CopilotSelectionController,
            LassoToolController,
            TemplateToolController,
            MindmapToolController
        ];
        tools.forEach((tool)=>{
            this.service.registerTool(tool);
        });
        this.service.tool.mount(this);
    }
    _initViewport() {
        const { service, std } = this;
        const run = ()=>{
            const viewport = std.get(EditPropsStore).getStorage('viewport') ?? service.getFitToScreenData();
            if ('xywh' in viewport) {
                const bound = Bound.deserialize(viewport.xywh);
                service.viewport.setViewportByBound(bound, viewport.padding);
            } else {
                const { zoom, centerX, centerY } = viewport;
                service.viewport.setViewport(zoom, [
                    centerX,
                    centerY
                ]);
            }
        };
        run();
        this._disposables.add(()=>{
            std.get(EditPropsStore).setStorage('viewport', {
                centerX: service.viewport.centerX,
                centerY: service.viewport.centerY,
                zoom: service.viewport.zoom
            });
        });
    }
    _initWheelEvent() {
        this._disposables.add(this.dispatcher.add('wheel', (ctx)=>{
            const state = ctx.get('defaultState');
            const e = state.event;
            e.preventDefault();
            const { viewport, locked } = this.service;
            if (locked) return;
            if (isTouchPadPinchEvent(e)) {
                const rect = this.getBoundingClientRect();
                const [baseX, baseY] = this.service.viewport.toModelCoord(e.clientX - rect.x, e.clientY - rect.y);
                const zoom = normalizeWheelDeltaY(e.deltaY, viewport.zoom);
                viewport.setZoom(zoom, new Point(baseX, baseY));
                e.stopPropagation();
            } else {
                const simulateHorizontalScroll = IS_WINDOWS && e.shiftKey;
                const dx = simulateHorizontalScroll ? e.deltaY / viewport.zoom : e.deltaX / viewport.zoom;
                const dy = simulateHorizontalScroll ? 0 : e.deltaY / viewport.zoom;
                viewport.applyDeltaCenter(dx, dy);
                viewport.viewportMoved.emit([
                    dx,
                    dy
                ]);
                e.stopPropagation();
            }
        }));
    }
    async addAttachments(files, point) {
        if (!files.length) return [];
        const attachmentService = this.std.getService('affine:attachment');
        if (!attachmentService) {
            console.error('Attachment service not found');
            return [];
        }
        const maxFileSize = attachmentService.maxFileSize;
        const isSizeExceeded = files.some((file)=>file.size > maxFileSize);
        if (isSizeExceeded) {
            toast(this.host, `You can only upload files less than ${humanFileSize(maxFileSize, true, 0)}`);
            return [];
        }
        let { x, y } = this.service.viewport.center;
        if (point) [x, y] = this.service.viewport.toModelCoord(...point);
        const CARD_STACK_GAP = 32;
        const dropInfos = files.map((file, index)=>{
            const point = new Point(x + index * CARD_STACK_GAP, y + index * CARD_STACK_GAP);
            const center = Vec.toVec(point);
            const bound = Bound.fromCenter(center, EMBED_CARD_WIDTH.cubeThick, EMBED_CARD_HEIGHT.cubeThick);
            const blockId = this.service.addBlock('affine:attachment', {
                name: file.name,
                size: file.size,
                type: file.type,
                style: 'cubeThick',
                xywh: bound.serialize()
            }, this.surface.model);
            return {
                blockId,
                file
            };
        });
        const uploadPromises = dropInfos.map(async ({ blockId, file })=>{
            let sourceId;
            try {
                setAttachmentUploading(blockId);
                sourceId = await this.doc.blobSync.set(file);
            } catch (error) {
                console.error(error);
                if (error instanceof Error) {
                    toast(this.host, `Failed to upload attachment! ${error.message || error.toString()}`);
                }
            } finally{
                setAttachmentUploaded(blockId);
                this.doc.withoutTransact(()=>{
                    this.service.updateElement(blockId, {
                        sourceId
                    });
                });
            }
            return blockId;
        });
        const blockIds = await Promise.all(uploadPromises);
        this.service.selection.set({
            elements: blockIds,
            editing: false
        });
        return blockIds;
    }
    async addImages(files, point, inTopLeft) {
        const imageFiles = [
            ...files
        ].filter((file)=>file.type.startsWith('image/'));
        if (!imageFiles.length) return [];
        const imageService = this.std.getService('affine:image');
        if (!imageService) {
            console.error('Image service not found');
            return [];
        }
        const maxFileSize = imageService.maxFileSize;
        const isSizeExceeded = imageFiles.some((file)=>file.size > maxFileSize);
        if (isSizeExceeded) {
            toast(this.host, `You can only upload files less than ${humanFileSize(maxFileSize, true, 0)}`);
            return [];
        }
        let { x, y } = this.service.viewport.center;
        if (point) [x, y] = this.service.viewport.toModelCoord(...point);
        const dropInfos = [];
        const IMAGE_STACK_GAP = 32;
        imageFiles.map((file, index)=>{
            const point = new Point(x + index * IMAGE_STACK_GAP, y + index * IMAGE_STACK_GAP);
            const center = Vec.toVec(point);
            const bound = calcBoundByOrigin(center, inTopLeft);
            const blockId = this.service.addBlock('affine:image', {
                size: file.size,
                xywh: bound.serialize()
            }, this.surface.model);
            dropInfos.push({
                point,
                blockId
            });
        });
        const uploadPromises = imageFiles.map(async (file, index)=>{
            const { point, blockId } = dropInfos[index];
            const sourceId = await this.doc.blobSync.set(file);
            const imageSize = await readImageSize(file);
            const center = Vec.toVec(point);
            const bound = calcBoundByOrigin(center, inTopLeft, imageSize.width, imageSize.height);
            this.doc.withoutTransact(()=>{
                this.service.updateElement(blockId, {
                    sourceId,
                    ...imageSize,
                    xywh: bound.serialize()
                });
            });
        });
        await Promise.all(uploadPromises);
        const blockIds = dropInfos.map((info)=>info.blockId);
        this.service.selection.set({
            elements: blockIds,
            editing: false
        });
        return blockIds;
    }
    addNewNote(blocks, point, options) {
        this.doc.captureSync();
        const { left, top } = this.service.viewport;
        point.x -= left;
        point.y -= top;
        const noteId = this.addNoteWithPoint(point, options);
        const ids = this.doc.addBlocks(blocks.map(({ flavour, ...blockProps })=>{
            assertExists(flavour);
            return {
                flavour,
                blockProps
            };
        }), noteId);
        return {
            noteId,
            ids
        };
    }
    addNoteWithPoint(point, options = {}) {
        const { width = DEFAULT_NOTE_WIDTH, height = DEFAULT_NOTE_HEIGHT, offsetX = DEFAULT_NOTE_OFFSET_X, offsetY = DEFAULT_NOTE_OFFSET_Y, parentId = this.doc.root?.id, noteIndex: noteIndex, scale = 1 } = options;
        const [x, y] = this.service.viewport.toModelCoord(point.x, point.y);
        const blockId = this.service.addBlock('affine:note', {
            xywh: serializeXYWH(x - offsetX * scale, y - offsetY * scale, width, height),
            displayMode: NoteDisplayMode.EdgelessOnly
        }, parentId, noteIndex);
        this.std.getOptional(TelemetryProvider)?.track('CanvasElementAdded', {
            control: 'canvas:draw',
            page: 'whiteboard editor',
            module: 'toolbar',
            segment: 'toolbar',
            type: 'note'
        });
        return blockId;
    }
    bindHotKey(keymap, options) {
        const { service } = this;
        const selection = service.selection;
        Object.keys(keymap).forEach((key)=>{
            if (key.length === 1 && key >= 'A' && key <= 'z') {
                const handler = keymap[key];
                keymap[key] = (ctx)=>{
                    const elements = selection.selectedElements;
                    if (isSelectSingleMindMap(elements) && !selection.editing) {
                        const target = service.getElementById(elements[0].id);
                        if (target.text) {
                            this.doc.transact(()=>{
                                target.text.delete(0, target.text.length);
                                target.text.insert(0, key);
                            });
                        }
                        mountShapeTextEditor(target, this);
                    } else {
                        handler(ctx);
                    }
                };
            }
        });
        return super.bindHotKey(keymap, options);
    }
    connectedCallback() {
        super.connectedCallback();
        this.clipboardController.hostConnected();
        this.keyboardManager = new EdgelessPageKeyboardManager(this);
        this.handleEvent('selectionChange', ()=>{
            const surface = this.host.selection.value.find((sel)=>sel.is('surface'));
            if (!surface) return;
            const el = this.service.getElementById(surface.elements[0]);
            if (isCanvasElement(el)) {
                return true;
            }
            return;
        });
        this.mouseRoot = this.parentElement;
        this._initTools();
        this._disposables.add(this.slots.elementResizeStart.on(()=>{
            this._isResizing = true;
        }));
        this._disposables.add(this.slots.elementResizeEnd.on(()=>{
            this._isResizing = false;
        }));
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.clipboardController.hostDisconnected();
        if (this._resizeObserver) {
            this._resizeObserver.disconnect();
            this._resizeObserver = null;
        }
        this.keyboardManager = null;
        this.components.toolbar?.remove();
        this.components.toolbar = null;
    }
    firstUpdated() {
        this._initSlotEffects();
        this._initResizeEffect();
        this._initPixelRatioChangeEffect();
        this._initFontLoader();
        this._initRemoteCursor();
        this._initLayerUpdateEffect();
        this._initViewport();
        this._initWheelEvent();
        this._initPanEvent();
        this._initPinchEvent();
        if (this.doc.readonly) {
            this.tools.setEdgelessTool({
                type: 'pan',
                panning: true
            });
        }
        if (this.disableComponents) return;
        requestConnectedFrame(()=>{
            this._handleToolbarFlag();
            this.requestUpdate();
        }, this);
        this._disposables.add(this.service.viewport.viewportUpdated.on(()=>{
            this._refreshLayerViewport();
        }));
        this._refreshLayerViewport();
    }
    getElementsBound() {
        const { service } = this;
        return edgelessElementsBound([
            ...service.elements,
            ...service.blocks
        ]);
    }
    renderBlock() {
        const widgets = repeat(Object.entries(this.widgets), ([id])=>id, ([_, widget])=>widget);
        return html`
      <div class="edgeless-background edgeless-container">
        <gfx-viewport
          .maxConcurrentRenders=${6}
          .viewport=${this.service.viewport}
          .getModelsInViewport=${()=>{
            const blocks = this.service.gfx.grid.search(this.service.viewport.viewportBounds, undefined, {
                useSet: true,
                filter: (model)=>model instanceof GfxBlockElementModel
            });
            return blocks;
        }}
          .host=${this.host}
        >
          ${this.renderChildren(this.model)}${this.renderChildren(this.surfaceBlockModel)}
        </gfx-viewport>
      </div>

      <!--
        Used to mount component before widgets
        Eg., canvas text editor
      -->
      <div class="edgeless-mount-point"></div>

      <!-- need to be converted to widget -->
      <edgeless-dragging-area-rect
        .edgeless=${this}
      ></edgeless-dragging-area-rect>

      ${this._isResizing ? nothing : html`<note-slicer .edgeless=${this}></note-slicer>`}

      <edgeless-selected-rect .edgeless=${this}></edgeless-selected-rect>
      <edgeless-navigator-black-background
        .edgeless=${this}
      ></edgeless-navigator-black-background>
      <!-- end -->

      <div class="widgets-container">${widgets}</div>
    `;
    }
    setSelection(noteId, _active = true, blockId, point) {
        const noteBlock = this.service.blocks.filter((block)=>block.flavour === 'affine:note').find((b)=>b.id === noteId);
        assertExists(noteBlock);
        requestAnimationFrame(()=>{
            this.service.selection.set({
                elements: [
                    noteBlock.id
                ],
                editing: false
            });
            this.updateComplete.then(()=>{
                if (blockId) {
                    focusTextModel(this.std, blockId);
                } else if (point) {
                    handleNativeRangeAtPoint(point.x, point.y);
                }
            }).catch(console.error);
        });
    }
    #___private__isResizing_1;
    get _isResizing() {
        return this.#___private__isResizing_1;
    }
    set _isResizing(_v) {
        this.#___private__isResizing_1 = _v;
    }
    #___private_backgroundElm_2;
    get backgroundElm() {
        return this.#___private_backgroundElm_2;
    }
    set backgroundElm(_v) {
        this.#___private_backgroundElm_2 = _v;
    }
    #___private_edgelessTool_3;
    get edgelessTool() {
        return this.#___private_edgelessTool_3;
    }
    set edgelessTool(_v) {
        this.#___private_edgelessTool_3 = _v;
    }
    #___private_gfxViewportElm_4;
    get gfxViewportElm() {
        return this.#___private_gfxViewportElm_4;
    }
    set gfxViewportElm(_v) {
        this.#___private_gfxViewportElm_4 = _v;
    }
    #___private_mountElm_5;
    get mountElm() {
        return this.#___private_mountElm_5;
    }
    set mountElm(_v) {
        this.#___private_mountElm_5 = _v;
    }
    #___private_selectedRect_6;
    get selectedRect() {
        return this.#___private_selectedRect_6;
    }
    set selectedRect(_v) {
        this.#___private_selectedRect_6 = _v;
    }
    #___private_surface_7;
    get surface() {
        return this.#___private_surface_7;
    }
    set surface(_v) {
        this.#___private_surface_7 = _v;
    }
    constructor(...args){
        super(...args), this._refreshLayerViewport = requestThrottledConnectedFrame(()=>{
            const { zoom, translateX, translateY } = this.service.viewport;
            const { gap } = getBackgroundGrid(zoom, true);
            if (this.backgroundElm) {
                this.backgroundElm.style.setProperty('background-position', `${translateX}px ${translateY}px`);
                this.backgroundElm.style.setProperty('background-size', `${gap}px ${gap}px`);
            }
        }, this), this._resizeObserver = null, this._viewportElement = null, this.clipboardController = new EdgelessClipboardController(this), this.components = {
            toolbar: null
        }, this.disableComponents = false, this.keyboardManager = null, this.#___private__isResizing_1 = (_initProto(this), _init__isResizing(this, false)), this.#___private_backgroundElm_2 = _init_backgroundElm(this, null), this.#___private_edgelessTool_3 = _init_edgelessTool(this, {
            type: localStorage.defaultTool ?? 'default'
        }), this.#___private_gfxViewportElm_4 = _init_gfxViewportElm(this), this.#___private_mountElm_5 = _init_mountElm(this, null), this.#___private_selectedRect_6 = _init_selectedRect(this), this.#___private_surface_7 = _init_surface(this);
    }
}
