import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RadioGroup, Switch } from '@affine/component';
import { SettingHeader, SettingRow, SettingWrapper } from '@affine/component/setting-components';
import { useI18n } from '@affine/i18n';
import { FeatureFlagService, useLiveData, useService } from '@toeverything/infra';
import { useTheme } from 'next-themes';
import { useCallback, useMemo } from 'react';
import { useAppSettingHelper } from '../../../../../components/hooks/affine/use-app-setting-helper';
import { LanguageMenu } from '../../../language-menu';
import { settingWrapper } from './style.css';
import { ThemeEditorSetting } from './theme-editor-setting';
export const getThemeOptions = (t)=>[
        {
            value: 'system',
            label: t['com.affine.themeSettings.system'](),
            testId: 'system-theme-trigger'
        },
        {
            value: 'light',
            label: t['com.affine.themeSettings.light'](),
            testId: 'light-theme-trigger'
        },
        {
            value: 'dark',
            label: t['com.affine.themeSettings.dark'](),
            testId: 'dark-theme-trigger'
        }
    ];
export const ThemeSettings = ()=>{
    const t = useI18n();
    const { setTheme, theme } = useTheme();
    const radioItems = useMemo(()=>getThemeOptions(t), [
        t
    ]);
    return _jsx(RadioGroup, {
        items: radioItems,
        value: theme,
        width: 250,
        className: settingWrapper,
        onChange: useCallback((value)=>{
            setTheme(value);
        }, [
            setTheme
        ])
    });
};
export const AppearanceSettings = ()=>{
    const t = useI18n();
    const featureFlagService = useService(FeatureFlagService);
    const enableThemeEditor = useLiveData(featureFlagService.flags.enable_theme_editor.$);
    const { appSettings, updateSettings } = useAppSettingHelper();
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingHeader, {
                title: t['com.affine.appearanceSettings.title'](),
                subtitle: t['com.affine.appearanceSettings.subtitle']()
            }),
            _jsxs(SettingWrapper, {
                title: t['com.affine.appearanceSettings.theme.title'](),
                children: [
                    _jsx(SettingRow, {
                        name: t['com.affine.appearanceSettings.color.title'](),
                        desc: t['com.affine.appearanceSettings.color.description'](),
                        children: _jsx(ThemeSettings, {})
                    }),
                    _jsx(SettingRow, {
                        name: t['com.affine.appearanceSettings.language.title'](),
                        desc: t['com.affine.appearanceSettings.language.description'](),
                        children: _jsx("div", {
                            className: settingWrapper,
                            children: _jsx(LanguageMenu, {})
                        })
                    }),
                    BUILD_CONFIG.isElectron ? _jsx(SettingRow, {
                        name: t['com.affine.appearanceSettings.clientBorder.title'](),
                        desc: t['com.affine.appearanceSettings.clientBorder.description'](),
                        "data-testid": "client-border-style-trigger",
                        children: _jsx(Switch, {
                            checked: appSettings.clientBorder,
                            onChange: (checked)=>updateSettings('clientBorder', checked)
                        })
                    }) : null,
                    enableThemeEditor ? _jsx(ThemeEditorSetting, {}) : null
                ]
            }),
            BUILD_CONFIG.isElectron ? _jsxs(SettingWrapper, {
                title: t['com.affine.appearanceSettings.sidebar.title'](),
                children: [
                    _jsx(SettingRow, {
                        name: t['com.affine.appearanceSettings.noisyBackground.title'](),
                        desc: t['com.affine.appearanceSettings.noisyBackground.description'](),
                        children: _jsx(Switch, {
                            checked: appSettings.enableNoisyBackground,
                            onChange: (checked)=>updateSettings('enableNoisyBackground', checked)
                        })
                    }),
                    environment.isMacOs && _jsx(SettingRow, {
                        name: t['com.affine.appearanceSettings.translucentUI.title'](),
                        desc: t['com.affine.appearanceSettings.translucentUI.description'](),
                        children: _jsx(Switch, {
                            checked: appSettings.enableBlurBackground,
                            onChange: (checked)=>updateSettings('enableBlurBackground', checked)
                        })
                    })
                ]
            }) : null
        ]
    });
};
