import { BlockFlavourIdentifier, BlockServiceIdentifier, StdIdentifier } from '@blocksuite/affine/block-std';
import { AttachmentBlockService, AttachmentBlockSpec } from '@blocksuite/affine/blocks';
import bytes from 'bytes';
class CustomAttachmentBlockService extends AttachmentBlockService {
    mounted() {
        this.maxFileSize = bytes.parse('2GB');
        super.mounted();
    }
}
export const CustomAttachmentBlockSpec = [
    ...AttachmentBlockSpec,
    {
        setup: (di)=>{
            di.override(BlockServiceIdentifier('affine:attachment'), CustomAttachmentBlockService, [
                StdIdentifier,
                BlockFlavourIdentifier('affine:attachment')
            ]);
        }
    }
];
