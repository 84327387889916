import { handleError } from './copilot-client';
export function delay(ms) {
    return new Promise((resolve)=>setTimeout(resolve, ms));
}
const safeParseError = (data)=>{
    try {
        return JSON.parse(data);
    } catch  {
        return {
            status: 500
        };
    }
};
export function toTextStream(eventSource, { timeout, signal } = {}) {
    return {
        [Symbol.asyncIterator]: async function*() {
            const messageQueue = [];
            let resolveMessagePromise;
            let rejectMessagePromise;
            function resetMessagePromise() {
                if (resolveMessagePromise) {
                    resolveMessagePromise();
                }
                return new Promise((resolve, reject)=>{
                    resolveMessagePromise = resolve;
                    rejectMessagePromise = reject;
                });
            }
            let messagePromise = resetMessagePromise();
            function messageListener(event) {
                messageQueue.push({
                    type: event.type,
                    data: event.data
                });
                messagePromise = resetMessagePromise();
            }
            eventSource.addEventListener('message', messageListener);
            eventSource.addEventListener('attachment', messageListener);
            eventSource.addEventListener('error', (event)=>{
                const errorMessage = event.data;
                if (event.type === 'error' && errorMessage) {
                    const error = safeParseError(errorMessage);
                    rejectMessagePromise(handleError(error));
                } else {
                    resolveMessagePromise();
                }
                eventSource.close();
            });
            try {
                while(eventSource.readyState !== EventSource.CLOSED && !signal?.aborted){
                    if (messageQueue.length === 0) {
                        await (timeout ? Promise.race([
                            messagePromise,
                            delay(timeout).then(()=>{
                                if (!signal?.aborted) {
                                    throw new Error('Timeout');
                                }
                            })
                        ]) : messagePromise);
                    } else if (messageQueue.length > 0) {
                        const top = messageQueue.shift();
                        if (top) {
                            yield top;
                        }
                    }
                }
            } finally{
                eventSource.close();
            }
        }
    };
}
