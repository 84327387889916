import { apis } from '@affine/electron-api';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { DocCollection } from '@blocksuite/affine/store';
import { getAFFiNEWorkspaceSchema, LiveData, Service } from '@toeverything/infra';
import { isEqual } from 'lodash-es';
import { nanoid } from 'nanoid';
import { Observable } from 'rxjs';
import { applyUpdate, encodeStateAsUpdate } from 'yjs';
import { BroadcastChannelAwarenessConnection } from './engine/awareness-broadcast-channel';
import { StaticBlobStorage } from './engine/blob-static';
export const LOCAL_WORKSPACE_LOCAL_STORAGE_KEY = 'affine-local-workspace';
const LOCAL_WORKSPACE_CHANGED_BROADCAST_CHANNEL_KEY = 'affine-local-workspace-changed';
export class LocalWorkspaceFlavourProvider extends Service {
    constructor(storageProvider){
        super(), this.storageProvider = storageProvider, this.flavour = WorkspaceFlavour.LOCAL, this.notifyChannel = new BroadcastChannel(LOCAL_WORKSPACE_CHANGED_BROADCAST_CHANNEL_KEY), this.workspaces$ = LiveData.from(new Observable((subscriber)=>{
            let last = null;
            const emit = ()=>{
                const value = JSON.parse(localStorage.getItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY) ?? '[]').map((id)=>({
                        id,
                        flavour: WorkspaceFlavour.LOCAL
                    }));
                if (isEqual(last, value)) return;
                subscriber.next(value);
                last = value;
            };
            emit();
            const channel = new BroadcastChannel(LOCAL_WORKSPACE_CHANGED_BROADCAST_CHANNEL_KEY);
            channel.addEventListener('message', emit);
            return ()=>{
                channel.removeEventListener('message', emit);
                channel.close();
            };
        }), []), this.isRevalidating$ = new LiveData(false);
    }
    async deleteWorkspace(id) {
        const allWorkspaceIDs = JSON.parse(localStorage.getItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY) ?? '[]');
        localStorage.setItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY, JSON.stringify(allWorkspaceIDs.filter((x)=>x !== id)));
        if (BUILD_CONFIG.isElectron && apis) {
            await apis.workspace.delete(id);
        }
        this.notifyChannel.postMessage(id);
    }
    async createWorkspace(initial) {
        const id = nanoid();
        const blobStorage = this.storageProvider.getBlobStorage(id);
        const docStorage = this.storageProvider.getDocStorage(id);
        const docCollection = new DocCollection({
            id: id,
            idGenerator: ()=>nanoid(),
            schema: getAFFiNEWorkspaceSchema(),
            blobSources: {
                main: blobStorage
            }
        });
        await initial(docCollection, blobStorage, docStorage);
        await docStorage.doc.set(id, encodeStateAsUpdate(docCollection.doc));
        for (const subdocs of docCollection.doc.getSubdocs()){
            await docStorage.doc.set(subdocs.guid, encodeStateAsUpdate(subdocs));
        }
        const allWorkspaceIDs = JSON.parse(localStorage.getItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY) ?? '[]');
        allWorkspaceIDs.push(id);
        localStorage.setItem(LOCAL_WORKSPACE_LOCAL_STORAGE_KEY, JSON.stringify(allWorkspaceIDs));
        this.notifyChannel.postMessage(id);
        return {
            id,
            flavour: WorkspaceFlavour.LOCAL
        };
    }
    revalidate() {
        this.notifyChannel.postMessage(null);
    }
    async getWorkspaceProfile(id) {
        const docStorage = this.storageProvider.getDocStorage(id);
        const localData = await docStorage.doc.get(id);
        if (!localData) {
            return {
                isOwner: true
            };
        }
        const bs = new DocCollection({
            id,
            schema: getAFFiNEWorkspaceSchema()
        });
        if (localData) applyUpdate(bs.doc, localData);
        return {
            name: bs.meta.name,
            avatar: bs.meta.avatar,
            isOwner: true
        };
    }
    getWorkspaceBlob(id, blob) {
        return this.storageProvider.getBlobStorage(id).get(blob);
    }
    getEngineProvider(workspaceId) {
        return {
            getAwarenessConnections () {
                return [
                    new BroadcastChannelAwarenessConnection(workspaceId)
                ];
            },
            getDocServer () {
                return null;
            },
            getDocStorage: ()=>{
                return this.storageProvider.getDocStorage(workspaceId);
            },
            getLocalBlobStorage: ()=>{
                return this.storageProvider.getBlobStorage(workspaceId);
            },
            getRemoteBlobStorages () {
                return [
                    new StaticBlobStorage()
                ];
            }
        };
    }
}
