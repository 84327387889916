import { WorkspacesService } from '@toeverything/infra';
import { FetchService } from '../cloud';
import { ImportTemplateDialog } from './entities/dialog';
import { TemplateDownloader } from './entities/downloader';
import { ImportTemplateDialogService } from './services/dialog';
import { TemplateDownloaderService } from './services/downloader';
import { ImportTemplateService } from './services/import';
import { TemplateDownloaderStore } from './store/downloader';
export { ImportTemplateDialogService } from './services/dialog';
export { ImportTemplateDialogProvider } from './views/dialog';
export function configureImportTemplateModule(framework) {
    framework.service(ImportTemplateDialogService).entity(ImportTemplateDialog).service(TemplateDownloaderService).entity(TemplateDownloader, [
        TemplateDownloaderStore
    ]).store(TemplateDownloaderStore, [
        FetchService
    ]).service(ImportTemplateService, [
        WorkspacesService
    ]);
}
