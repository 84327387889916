import { toast } from '@affine/component';
import { EditorSettingService } from '@affine/core/modules/editor-settting';
import { WorkbenchService } from '@affine/core/modules/workbench';
import { DocsService, useServices } from '@toeverything/infra';
import { useCallback, useMemo } from 'react';
export const usePageHelper = (docCollection)=>{
    const { docsService, workbenchService, editorSettingService } = useServices({
        DocsService,
        WorkbenchService,
        EditorSettingService
    });
    const workbench = workbenchService.workbench;
    const docRecordList = docsService.list;
    const createPageAndOpen = useCallback((mode, open)=>{
        const docProps = {
            note: editorSettingService.editorSetting.get('affine:note')
        };
        const page = docsService.createDoc({
            docProps
        });
        if (mode) {
            docRecordList.doc$(page.id).value?.setPrimaryMode(mode);
        }
        if (open !== false) workbench.openDoc(page.id, {
            at: open === 'new-tab' ? 'new-tab' : 'active'
        });
        return page;
    }, [
        docRecordList,
        docsService,
        editorSettingService,
        workbench
    ]);
    const createEdgelessAndOpen = useCallback((open)=>{
        return createPageAndOpen('edgeless', open);
    }, [
        createPageAndOpen
    ]);
    const importFileAndOpen = useMemo(()=>async ()=>{
            const { showImportModal } = await import('@blocksuite/affine/blocks');
            const { promise, resolve, reject } = Promise.withResolvers();
            const onSuccess = (pageIds, options)=>{
                resolve(options);
                toast(`Successfully imported ${options.importedCount} Page${options.importedCount > 1 ? 's' : ''}.`);
                if (options.isWorkspaceFile) {
                    workbench.openAll();
                    return;
                }
                if (pageIds.length === 0) {
                    return;
                }
                const pageId = pageIds[0];
                workbench.openDoc(pageId);
            };
            showImportModal({
                collection: docCollection,
                onSuccess,
                onFail: (message)=>{
                    reject(new Error(message));
                }
            });
            return await promise;
        }, [
        docCollection,
        workbench
    ]);
    return useMemo(()=>{
        return {
            createPage: (mode, open)=>createPageAndOpen(mode, open),
            createEdgeless: createEdgelessAndOpen,
            importFile: importFileAndOpen
        };
    }, [
        createEdgelessAndOpen,
        createPageAndOpen,
        importFileAndOpen
    ]);
};
