import { jsx as _jsx } from "react/jsx-runtime";
import { AnimatedFolderIcon, IconButton, MenuItem, MenuSeparator, notify, RssIcon } from '@affine/component';
import { FeedAvatar } from '@affine/component';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { FeedExplorerType, FeedNodeType } from '@affine/core/modules/feeds';
import { FeedsService } from '@affine/core/modules/feeds/services/feeds';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { DeleteIcon, FolderIcon, PlusIcon, RemoveFolderIcon } from '@blocksuite/icons/rc';
import { FeatureFlagService, GlobalContextService, useLiveData, useServices } from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { ExplorerTreeNode } from '../../tree';
import { FolderEmpty } from './empty';
import { FavoriteFolderOperation, useExplorerRSSNodeOperations } from './operations';
import * as styles from './styles.css';
import { UnreadBadge } from './unread-bedge';
export const calcLocation = (node)=>{
    let at;
    if (node.type$.value === FeedNodeType.Folder) {
        at = FeedExplorerType.Folder;
    } else if (node.type$.value === FeedNodeType.RSS) {
        at = FeedExplorerType.RSS;
    } else {
        throw new Error('Invalid node type');
    }
    return {
        at,
        nodeId: node.id
    };
};
export const ExplorerFeedsNode = ({ nodeId, onDrop, defaultRenaming, operations, location, dropEffect, canDrop, reorderable })=>{
    const { feedsService } = useServices({
        FeedsService
    });
    const node = useLiveData(feedsService.feedTree.nodeById$(nodeId));
    const type = useLiveData(node?.type$);
    const handleDrop = useCallback((data)=>{
        if (!node) {
            return;
        }
        onDrop?.(data, node);
    }, [
        node,
        onDrop
    ]);
    const additionalOperations = useMemo(()=>{
        if (!type || !node) {
            return;
        }
        if (typeof operations === 'function') {
            return operations(type, node);
        }
        return operations;
    }, [
        node,
        operations,
        type
    ]);
    if (!node) {
        return;
    }
    if (type === FeedNodeType.Folder) {
        return _jsx(ExplorerFeedFolderNode, {
            nodeId: nodeId,
            onDrop: handleDrop,
            defaultRenaming: defaultRenaming,
            operations: additionalOperations,
            dropEffect: dropEffect,
            reorderable: reorderable,
            canDrop: canDrop
        });
    } else if (type === FeedNodeType.RSS) {
        return node && _jsx(ExplorerFeedRSSNode, {
            nodeId: nodeId,
            location: location,
            onDrop: handleDrop,
            reorderable: reorderable,
            canDrop: canDrop,
            dropEffect: dropEffect,
            operations: additionalOperations
        });
    }
    return;
};
const ExplorerFolderIcon = ({ collapsed, className, draggedOver, treeInstruction })=>_jsx(AnimatedFolderIcon, {
        className: className,
        open: !collapsed || !!draggedOver && treeInstruction?.type === 'make-child'
    });
export const ExplorerFeedFolderNode = ({ nodeId, onDrop, defaultRenaming, location, operations: additionalOperations, canDrop, dropEffect, reorderable })=>{
    const t = useI18n();
    const { featureFlagService, feedsService } = useServices({
        FeatureFlagService,
        FeedsService
    });
    const node = useLiveData(feedsService.feedTree.nodeById$(nodeId));
    const name = useLiveData(node?.name$);
    const enableEmojiIcon = useLiveData(featureFlagService.flags.enable_emoji_folder_icon.$);
    const [collapsed, setCollapsed] = useState(true);
    const [newFolderId, setNewFolderId] = useState(null);
    const handleDelete = useCallback(()=>{
        if (!node) {
            return;
        }
        node.delete();
        track.$.navigationPanel.organize.deleteOrganizeItem({
            type: 'folder'
        });
        notify.success({
            title: t['com.affine.rootAppSidebar.organize.delete.notify-title']({
                name
            }),
            message: t['com.affine.rootAppSidebar.organize.delete.notify-message']()
        });
    }, [
        name,
        node,
        t
    ]);
    const children = useLiveData(node?.sortedChildren$);
    const dndData = useMemo(()=>{
        if (!node) {
            return;
        }
        return {
            draggable: {
                entity: {
                    type: FeedNodeType.Folder,
                    id: node.id
                },
                from: location
            },
            dropTarget: {
                at: FeedExplorerType.Folder
            }
        };
    }, [
        location,
        node
    ]);
    const handleRename = useCallback((newName)=>{
        if (!node) {
            return;
        }
        node.rename(newName);
        track.$.navigationPanel.feeds.renameFeedFolder();
    }, [
        node
    ]);
    const handleDropOnFolder = useCallback((data)=>{
        if (!node) {
            return;
        }
        if (data.treeInstruction?.type === 'make-child') {
            if (data.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === data.source.data.entity.id || node.beChildOf(data.source.data.entity.id)) {
                    return;
                }
                node.moveHere(data.source.data.entity.id, node.indexAt('before'));
                track.$.navigationPanel.feeds.moveFeedNode({
                    type: FeedNodeType.Folder
                });
            } else if (data.source.data.entity?.type === FeedNodeType.RSS) {
                node.moveHere(data.source.data.entity.id, node.indexAt('before'));
                track.$.navigationPanel.feeds.moveFeedNode({
                    type: FeedNodeType.RSS
                });
            } else if (data.source.data.from?.at === FeedExplorerType.Folder) {
                node.moveHere(data.source.data.from.nodeId, node.indexAt('before'));
            }
        } else {
            onDrop?.(data);
        }
    }, [
        node,
        onDrop
    ]);
    const handleDropEffect = useCallback((data)=>{
        if (!node) {
            return;
        }
        if (data.treeInstruction?.type === 'make-child') {
            if (data.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === data.source.data.entity.id || node.beChildOf(data.source.data.entity.id)) {
                    return;
                }
                return 'move';
            } else if (data.source.data.from?.at === FeedExplorerType.Folder) {
                return 'move';
            } else if (data.source.data.entity?.type === FeedNodeType.RSS) {
                return 'move';
            }
        } else {
            return dropEffect?.(data);
        }
        return;
    }, [
        dropEffect,
        node
    ]);
    const handleDropOnChildren = useCallback((data, dropAtNode)=>{
        if (!node || !dropAtNode || !dropAtNode.id) {
            return;
        }
        if (data.treeInstruction?.type === 'reorder-above' || data.treeInstruction?.type === 'reorder-below') {
            const at = data.treeInstruction?.type === 'reorder-below' ? 'after' : 'before';
            if (data.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === data.source.data.entity.id || node.beChildOf(data.source.data.entity.id)) {
                    return;
                }
                node.moveHere(data.source.data.entity.id, node.indexAt(at, dropAtNode.id));
                track.$.navigationPanel.organize.moveOrganizeItem({
                    type: FeedNodeType.Folder
                });
            } else if (data.source.data.entity?.type === FeedNodeType.RSS) {
                node.moveHere(data.source.data.entity.id, node.indexAt(at, dropAtNode.id));
                track.$.navigationPanel.feeds.moveFeedNode({
                    type: data.source.data.entity?.type
                });
            }
        } else if (data.treeInstruction?.type === 'reparent') {
            const currentLevel = data.treeInstruction.currentLevel;
            const desiredLevel = data.treeInstruction.desiredLevel;
            if (currentLevel === desiredLevel + 1) {
                onDrop?.({
                    ...data,
                    treeInstruction: {
                        type: 'reorder-below',
                        currentLevel,
                        indentPerLevel: data.treeInstruction.indentPerLevel
                    }
                });
                return;
            } else {
                onDrop?.({
                    ...data,
                    treeInstruction: {
                        ...data.treeInstruction,
                        currentLevel: currentLevel - 1
                    }
                });
            }
        }
    }, [
        node,
        onDrop
    ]);
    const handleDropEffectOnChildren = useCallback((data)=>{
        if (!node) {
            return;
        }
        if (data.treeInstruction?.type === 'reorder-above' || data.treeInstruction?.type === 'reorder-below') {
            if (data.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === data.source.data.entity.id || node.beChildOf(data.source.data.entity.id)) {
                    return;
                }
                return 'move';
            } else if (data.source.data.from?.at === FeedExplorerType.Folder) {
                return 'move';
            } else if (data.source.data.entity?.type === FeedNodeType.RSS) {
                return 'move';
            }
        } else if (data.treeInstruction?.type === 'reparent') {
            const currentLevel = data.treeInstruction.currentLevel;
            const desiredLevel = data.treeInstruction.desiredLevel;
            if (currentLevel === desiredLevel + 1) {
                dropEffect?.({
                    ...data,
                    treeInstruction: {
                        type: 'reorder-below',
                        currentLevel,
                        indentPerLevel: data.treeInstruction.indentPerLevel
                    }
                });
                return;
            } else {
                dropEffect?.({
                    ...data,
                    treeInstruction: {
                        ...data.treeInstruction,
                        currentLevel: currentLevel - 1
                    }
                });
            }
        }
        return;
    }, [
        dropEffect,
        node
    ]);
    const handleCanDrop = useMemo(()=>(args)=>{
            if (!node) {
                return false;
            }
            const entityType = args.source.data.entity?.type;
            if (args.treeInstruction && args.treeInstruction?.type !== 'make-child') {
                return (typeof canDrop === 'function' ? canDrop(args) : canDrop) ?? true;
            }
            if (args.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === args.source.data.entity.id || node.beChildOf(args.source.data.entity.id)) {
                    return false;
                }
                return true;
            } else if (args.source.data.from?.at === FeedExplorerType.Folder) {
                return true;
            } else if (entityType === FeedNodeType.RSS) {
                return true;
            }
            return false;
        }, [
        canDrop,
        node
    ]);
    const handleChildrenCanDrop = useMemo(()=>(args)=>{
            if (!node) {
                return false;
            }
            const entityType = args.source.data.entity?.type;
            if (args.source.data.entity?.type === FeedNodeType.Folder) {
                if (node.id === args.source.data.entity.id || node.beChildOf(args.source.data.entity.id)) {
                    return false;
                }
                return true;
            } else if (args.source.data.from?.at === FeedExplorerType.Folder) {
                return true;
            } else if (entityType === FeedNodeType.RSS) {
                return true;
            }
            return false;
        }, [
        node
    ]);
    const handleNewFeed = useCallback(()=>{
        if (!node) {
            return;
        }
        feedsService.searchModal.show({
            folderId: node.id,
            folderName: name
        });
    }, [
        node,
        feedsService.searchModal,
        name
    ]);
    const handleCreateSubfolder = useCallback(()=>{
        if (!node) {
            return;
        }
        const newFolderId = node.createFolder(t['com.affine.rootAppSidebar.organize.new-folders'](), node.indexAt('before'));
        track.$.navigationPanel.feeds.createFeedsItem({
            type: FeedNodeType.Folder
        });
        setCollapsed(false);
        setNewFolderId(newFolderId);
    }, [
        node,
        t
    ]);
    const folderOperations = useMemo(()=>{
        return [
            {
                index: 0,
                inline: true,
                view: _jsx(IconButton, {
                    size: "16",
                    onClick: handleNewFeed,
                    tooltip: t['ai.wemem.rootAppSidebar.feeds.action'](),
                    children: _jsx(PlusIcon, {})
                })
            },
            {
                index: 100,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(RssIcon, {}),
                    onClick: handleNewFeed,
                    children: t['ai.wemem.rootAppSidebar.feeds.action']()
                })
            },
            {
                index: 101,
                view: _jsx(MenuItem, {
                    prefixIcon: _jsx(FolderIcon, {}),
                    onClick: handleCreateSubfolder,
                    children: t['com.affine.rootAppSidebar.organize.folder.create-subfolder']()
                })
            },
            {
                index: 200,
                view: node?.id ? _jsx(FavoriteFolderOperation, {
                    id: node.id
                }) : null
            },
            {
                index: 9999,
                view: _jsx(MenuSeparator, {}, "menu-separator")
            },
            {
                index: 10000,
                view: _jsx(MenuItem, {
                    type: 'danger',
                    prefixIcon: _jsx(DeleteIcon, {}),
                    onClick: handleDelete,
                    children: t['com.affine.rootAppSidebar.organize.delete']()
                })
            }
        ];
    }, [
        handleCreateSubfolder,
        handleDelete,
        handleNewFeed,
        node,
        t
    ]);
    const finalOperations = useMemo(()=>{
        if (additionalOperations) {
            return [
                ...additionalOperations,
                ...folderOperations
            ];
        }
        return folderOperations;
    }, [
        additionalOperations,
        folderOperations
    ]);
    const childrenOperations = useCallback((type, node)=>{
        if (type === 'doc' || type === 'collection' || type === 'tag') {
            return [
                {
                    index: 999,
                    view: _jsx(MenuItem, {
                        type: 'danger',
                        prefixIcon: _jsx(RemoveFolderIcon, {}),
                        "data-event-props": "$.navigationPanel.organize.deleteOrganizeItem",
                        "data-event-args-type": node.type$.value,
                        onClick: ()=>node.delete(),
                        children: t['com.affine.rootAppSidebar.organize.delete-from-folder']()
                    })
                }
            ];
        }
        return [];
    }, [
        t
    ]);
    const handleCollapsedChange = useCallback((collapsed)=>{
        if (collapsed) {
            setNewFolderId(null);
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, []);
    if (!node) {
        return null;
    }
    return _jsx(ExplorerTreeNode, {
        icon: ExplorerFolderIcon,
        name: name,
        dndData: dndData,
        onDrop: handleDropOnFolder,
        defaultRenaming: defaultRenaming,
        renameable: true,
        extractEmojiAsIcon: enableEmojiIcon,
        reorderable: reorderable,
        collapsed: collapsed,
        setCollapsed: handleCollapsedChange,
        onRename: handleRename,
        operations: finalOperations,
        canDrop: handleCanDrop,
        childrenPlaceholder: _jsx(FolderEmpty, {
            canDrop: handleCanDrop
        }),
        dropEffect: handleDropEffect,
        "data-testid": `explorer-folder-${node?.id}`,
        postfix: _jsx(UnreadBadge, {
            node: node
        }),
        children: children?.map((child)=>_jsx(ExplorerFeedsNode, {
                nodeId: child.id,
                defaultRenaming: child.id === newFolderId,
                onDrop: handleDropOnChildren,
                operations: childrenOperations,
                dropEffect: handleDropEffectOnChildren,
                canDrop: handleChildrenCanDrop,
                reorderable: true,
                location: calcLocation(child)
            }, child.id))
    });
};
export const ExplorerFeedRSSNode = ({ nodeId, location, reorderable, operations: additionalOperations })=>{
    const { feedsService, globalContextService, featureFlagService } = useServices({
        FeedsService,
        GlobalContextService,
        FeatureFlagService
    });
    const node = useLiveData(feedsService.feedTree.nodeById$(nodeId));
    const enableEmojiIcon = useLiveData(featureFlagService.flags.enable_emoji_folder_icon.$);
    const icon = useLiveData(node?.icon$);
    const name = useLiveData(node?.name$);
    const allPath = `/feed/${nodeId}/all`;
    const active = useLiveData(globalContextService.globalContext.feedId.$) === nodeId;
    const [collapsed, setCollapsed] = useState(true);
    const DocIcon = useCallback(({ className })=>{
        return _jsx("div", {
            className: clsx(styles.feedIconContainer, className),
            children: _jsx(FeedAvatar, {
                image: icon,
                name: name
            })
        });
    }, [
        icon,
        name
    ]);
    const Icon = useCallback(({ className })=>{
        return _jsx(DocIcon, {
            className: className
        });
    }, [
        DocIcon
    ]);
    const dndData = useMemo(()=>{
        return {
            draggable: {
                entity: {
                    type: FeedNodeType.RSS,
                    id: nodeId
                },
                from: location
            },
            dropTarget: {
                at: FeedExplorerType.RSS
            }
        };
    }, [
        nodeId,
        location
    ]);
    const handleRename = useAsyncCallback(async (newName)=>{
        if (!node) {
            return;
        }
        node.rename(newName);
        track.$.navigationPanel.feeds.renameFeed();
    }, [
        node
    ]);
    const operations = useExplorerRSSNodeOperations(node, useMemo(()=>({
            openInfoModal: ()=>feedsService.infoModal.open(nodeId),
            openNodeCollapsed: ()=>setCollapsed(false),
            removeNode: ()=>node?.delete()
        }), [
        nodeId,
        feedsService.infoModal,
        node
    ]));
    const finalOperations = useMemo(()=>{
        if (additionalOperations) {
            return [
                ...operations,
                ...additionalOperations
            ];
        }
        return operations;
    }, [
        additionalOperations,
        operations
    ]);
    if (!node) {
        return null;
    }
    return _jsx(ExplorerTreeNode, {
        icon: Icon,
        name: name,
        dndData: dndData,
        renameable: true,
        collapsed: collapsed,
        setCollapsed: setCollapsed,
        to: allPath,
        active: active,
        reorderable: reorderable,
        extractEmojiAsIcon: enableEmojiIcon,
        onRename: handleRename,
        operations: finalOperations,
        "data-testid": `explorer-feed-${node.id}`,
        postfix: _jsx(UnreadBadge, {
            node: node
        })
    });
};
