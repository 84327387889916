import { Service } from '../../../framework';
import { Workspace } from '../entities/workspace';
export class WorkspaceService extends Service {
    get workspace() {
        if (!this._workspace) {
            this._workspace = this.framework.createEntity(Workspace);
        }
        return this._workspace;
    }
    constructor(...args){
        super(...args), this._workspace = null;
    }
}
