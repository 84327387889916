import { isGfxContainerElm } from '@blocksuite/block-std/gfx';
export function getTopElements(elements) {
    const topElements = new Set(elements);
    const visitedElements = new Map();
    elements.forEach((element)=>{
        visitedElements.set(element, false);
    });
    const traverse = (element)=>{
        if (!visitedElements.has(element)) return;
        if (visitedElements.get(element)) return;
        visitedElements.set(element, true);
        if (isGfxContainerElm(element)) {
            element.childElements.forEach((child)=>{
                topElements.delete(child);
                traverse(child);
            });
        }
    };
    visitedElements.forEach((_, element)=>{
        traverse(element);
    });
    return [
        ...topElements
    ];
}
export function getAllDescendantElements(element, includeSelf = false) {
    const elements = [];
    const traverse = (element)=>{
        elements.push(element);
        if (isGfxContainerElm(element)) {
            element.childElements.forEach((child)=>{
                traverse(child);
            });
        }
    };
    if (includeSelf) {
        traverse(element);
    } else {
        if (isGfxContainerElm(element)) {
            element.childElements.forEach((child)=>{
                traverse(child);
            });
        }
    }
    return elements;
}
