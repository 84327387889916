import { NoteDisplayMode } from '@blocksuite/affine/blocks';
import { Text } from '@blocksuite/affine/store';
export function initEmptyDoc(doc, title) {
    doc.load(()=>{
        initDocFromProps(doc, {
            page: {
                title: new Text(title)
            }
        });
    });
}
export function initDocFromProps(doc, props) {
    doc.load(()=>{
        const pageBlockId = doc.addBlock('affine:page', props?.page || {
            title: new Text('')
        });
        doc.addBlock('affine:surface', props?.surface || {}, pageBlockId);
        const noteBlockId = doc.addBlock('affine:note', {
            ...props?.note,
            displayMode: NoteDisplayMode.DocAndEdgeless
        }, pageBlockId);
        doc.addBlock('affine:paragraph', props?.paragraph || {}, noteBlockId);
        doc.history.clear();
    });
}
