function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _init__backupShapeElement, _init__dragging, _init__isOutside, _init__shapeElement, _init__startCoord, _init_edgeless, _init_getContainerRect, _init_handleClick, _init_order, _init_shape, _init_shapeStyle, _init_shapeType, _initProto;
import { CanvasElementType } from '@blocksuite/affine-block-surface';
import { getShapeRadius, getShapeType } from '@blocksuite/affine-model';
import { Bound, sleep, WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { ShapeToolController } from '../../../tools/shape-tool.js';
_dec = query('#backup-shape-element'), _dec1 = state(), _dec2 = state(), _dec3 = query('#shape-tool-element'), _dec4 = state(), _dec5 = property({
    attribute: false
}), _dec6 = property({
    attribute: false
}), _dec7 = property({
    attribute: false
}), _dec8 = property({
    attribute: false
}), _dec9 = property({
    attribute: false
}), _dec10 = property({
    attribute: false
}), _dec11 = property({
    attribute: false
});
export class EdgelessShapeToolElement extends WithDisposable(LitElement) {
    static{
        ({ e: [_init__backupShapeElement, _init__dragging, _init__isOutside, _init__shapeElement, _init__startCoord, _init_edgeless, _init_getContainerRect, _init_handleClick, _init_order, _init_shape, _init_shapeStyle, _init_shapeType, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_backupShapeElement"
            ],
            [
                _dec1,
                1,
                "_dragging"
            ],
            [
                _dec2,
                1,
                "_isOutside"
            ],
            [
                _dec3,
                1,
                "_shapeElement"
            ],
            [
                _dec4,
                1,
                "_startCoord"
            ],
            [
                _dec5,
                1,
                "edgeless"
            ],
            [
                _dec6,
                1,
                "getContainerRect"
            ],
            [
                _dec7,
                1,
                "handleClick"
            ],
            [
                _dec8,
                1,
                "order"
            ],
            [
                _dec9,
                1,
                "shape"
            ],
            [
                _dec10,
                1,
                "shapeStyle"
            ],
            [
                _dec11,
                1,
                "shapeType"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .shape {
      --x: 0px;
      --y: 0px;
      --offset-x: 0px;
      --offset-y: 0px;
      --scale: 1;
      transform: translateX(calc(var(--offset-x) + var(--x)))
        translateY(calc(var(--y) + var(--offset-y))) scale(var(--scale));
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 12px;
      left: 16px;
      transition: all 0.5s cubic-bezier(0, -0.01, 0.01, 1.01);
    }
    .shape.dragging {
      transition: none;
    }
    .shape svg {
      height: 100%;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    }
  `;
    }
    connectedCallback() {
        super.connectedCallback();
        this._disposables.addFromEvent(this.edgeless.host, 'mousemove', this._onMouseMove);
        this._disposables.addFromEvent(this.edgeless.host, 'touchmove', this._touchMove);
        this._disposables.addFromEvent(this.edgeless.host, 'mouseup', this._onMouseUp);
        this._disposables.addFromEvent(this.edgeless.host, 'touchend', this._onTouchEnd);
    }
    render() {
        return html`
      <div
        id="shape-tool-element"
        class="shape"
        @mousedown=${(event)=>this._onDragStart({
                x: event.clientX,
                y: event.clientY
            })}
        @touchstart=${(event)=>{
            event.preventDefault();
            this._onDragStart({
                x: event.touches[0].clientX,
                y: event.touches[0].clientY
            });
        }}
      >
        ${this.shape.svg}
      </div>
      ${this.order === 1 ? html`<div id="backup-shape-element" class="shape">
            ${this.shape.svg}
          </div>` : null}
    `;
    }
    updated(changedProperties) {
        if (!changedProperties.has('shape') && !changedProperties.has('order')) {
            return;
        }
        const transform = this._transformMap[this.order <= 3 ? `z${this.order}` : 'hidden'];
        this._shapeElement.style.setProperty('--x', `${transform.x}px`);
        this._shapeElement.style.setProperty('--y', `${transform.y}px`);
        this._shapeElement.style.setProperty('--scale', String(transform.scale || 1));
        this._shapeElement.style.zIndex = String(999 - this.order);
        this._shapeElement.style.transformOrigin = transform.origin;
        if (this._backupShapeElement) {
            this._backupShapeElement.style.setProperty('--y', '100px');
            this._backupShapeElement.style.setProperty('--scale', '0.9');
            this._backupShapeElement.style.zIndex = '999';
        }
    }
    #___private__backupShapeElement_1;
    get _backupShapeElement() {
        return this.#___private__backupShapeElement_1;
    }
    set _backupShapeElement(_v) {
        this.#___private__backupShapeElement_1 = _v;
    }
    #___private__dragging_2;
    get _dragging() {
        return this.#___private__dragging_2;
    }
    set _dragging(_v) {
        this.#___private__dragging_2 = _v;
    }
    #___private__isOutside_3;
    get _isOutside() {
        return this.#___private__isOutside_3;
    }
    set _isOutside(_v) {
        this.#___private__isOutside_3 = _v;
    }
    #___private__shapeElement_4;
    get _shapeElement() {
        return this.#___private__shapeElement_4;
    }
    set _shapeElement(_v) {
        this.#___private__shapeElement_4 = _v;
    }
    #___private__startCoord_5;
    get _startCoord() {
        return this.#___private__startCoord_5;
    }
    set _startCoord(_v) {
        this.#___private__startCoord_5 = _v;
    }
    #___private_edgeless_6;
    get edgeless() {
        return this.#___private_edgeless_6;
    }
    set edgeless(_v) {
        this.#___private_edgeless_6 = _v;
    }
    #___private_getContainerRect_7;
    get getContainerRect() {
        return this.#___private_getContainerRect_7;
    }
    set getContainerRect(_v) {
        this.#___private_getContainerRect_7 = _v;
    }
    #___private_handleClick_8;
    get handleClick() {
        return this.#___private_handleClick_8;
    }
    set handleClick(_v) {
        this.#___private_handleClick_8 = _v;
    }
    #___private_order_9;
    get order() {
        return this.#___private_order_9;
    }
    set order(_v) {
        this.#___private_order_9 = _v;
    }
    #___private_shape_10;
    get shape() {
        return this.#___private_shape_10;
    }
    set shape(_v) {
        this.#___private_shape_10 = _v;
    }
    #___private_shapeStyle_11;
    get shapeStyle() {
        return this.#___private_shapeStyle_11;
    }
    set shapeStyle(_v) {
        this.#___private_shapeStyle_11 = _v;
    }
    #___private_shapeType_12;
    get shapeType() {
        return this.#___private_shapeType_12;
    }
    set shapeType(_v) {
        this.#___private_shapeType_12 = _v;
    }
    constructor(...args){
        super(...args), this._addShape = (coord, padding)=>{
            const width = 100;
            const height = 100;
            const { x: edgelessX, y: edgelessY } = this.edgeless.getBoundingClientRect();
            const zoom = this.edgeless.service.viewport.zoom;
            const [modelX, modelY] = this.edgeless.service.viewport.toModelCoord(coord.x - edgelessX - width * padding.x * zoom, coord.y - edgelessY - height * padding.y * zoom);
            const xywh = new Bound(modelX, modelY, width, height).serialize();
            this.edgeless.service.addElement(CanvasElementType.SHAPE, {
                shapeType: getShapeType(this.shape.name),
                xywh: xywh,
                radius: getShapeRadius(this.shape.name)
            });
        }, this._onDragEnd = async (coord)=>{
            if (this._startCoord.x === coord.x && this._startCoord.y === coord.y) {
                this.handleClick();
                this._dragging = false;
                return;
            }
            if (!this._dragging) {
                return;
            }
            this._dragging = false;
            this.edgeless.tools.setEdgelessTool({
                type: 'default'
            });
            if (this._isOutside) {
                const rect = this._shapeElement.getBoundingClientRect();
                this._backupShapeElement.style.setProperty('transition', 'none');
                this._backupShapeElement.style.setProperty('--y', '100px');
                this._shapeElement.style.setProperty('--offset-x', `${0}px`);
                this._shapeElement.style.setProperty('--offset-y', `${0}px`);
                await sleep(0);
                this._shapeElement.classList.remove('dragging');
                this._backupShapeElement.style.removeProperty('transition');
                const padding = {
                    x: (coord.x - rect.left) / rect.width,
                    y: (coord.y - rect.top) / rect.height
                };
                this._addShape(coord, padding);
            } else {
                this._shapeElement.classList.remove('dragging');
                this._shapeElement.style.setProperty('--offset-x', `${0}px`);
                this._shapeElement.style.setProperty('--offset-y', `${0}px`);
                this._backupShapeElement.style.setProperty('--y', '100px');
            }
        }, this._onDragMove = (coord)=>{
            if (!this._dragging) {
                return;
            }
            const controller = this.edgeless.tools.currentController;
            if (controller instanceof ShapeToolController) {
                controller.clearOverlay();
            }
            const { x, y } = coord;
            this._shapeElement.style.setProperty('--offset-x', `${x - this._startCoord.x}px`);
            this._shapeElement.style.setProperty('--offset-y', `${y - this._startCoord.y}px`);
            const containerRect = this.getContainerRect();
            const isOut = y < containerRect.top || x < containerRect.left || x > containerRect.right;
            if (isOut !== this._isOutside) {
                this._backupShapeElement.style.setProperty('--y', isOut ? '5px' : '100px');
                this._backupShapeElement.style.setProperty('--scale', isOut ? '1' : '0.9');
            }
            this._isOutside = isOut;
        }, this._onDragStart = (coord)=>{
            this._startCoord = {
                x: coord.x,
                y: coord.y
            };
            if (this.order !== 1) {
                return;
            }
            this._dragging = true;
            this._shapeElement.classList.add('dragging');
        }, this._onMouseMove = (event)=>{
            if (!this._dragging) {
                return;
            }
            this._onDragMove({
                x: event.clientX,
                y: event.clientY
            });
        }, this._onMouseUp = (event)=>{
            this._onDragEnd({
                x: event.clientX,
                y: event.clientY
            }).catch(console.error);
        }, this._onTouchEnd = (event)=>{
            if (!event.changedTouches.length) return;
            this._onDragEnd({
                x: event.changedTouches[0].clientX,
                y: event.changedTouches[0].clientY
            }).catch(console.error);
        }, this._touchMove = (event)=>{
            if (!this._dragging) {
                return;
            }
            this._onDragMove({
                x: event.touches[0].clientX,
                y: event.touches[0].clientY
            });
        }, this._transformMap = {
            z1: {
                x: 0,
                y: 5,
                scale: 1.1,
                origin: '50% 100%'
            },
            z2: {
                x: -15,
                y: 0,
                scale: 0.75,
                origin: '20% 20%'
            },
            z3: {
                x: 15,
                y: 0,
                scale: 0.75,
                origin: '80% 20%'
            },
            hidden: {
                x: 0,
                y: 120,
                scale: 0,
                origin: '50% 50%'
            }
        }, this.#___private__backupShapeElement_1 = (_initProto(this), _init__backupShapeElement(this)), this.#___private__dragging_2 = _init__dragging(this, false), this.#___private__isOutside_3 = _init__isOutside(this, false), this.#___private__shapeElement_4 = _init__shapeElement(this), this.#___private__startCoord_5 = _init__startCoord(this, {
            x: -1,
            y: -1
        }), this.#___private_edgeless_6 = _init_edgeless(this), this.#___private_getContainerRect_7 = _init_getContainerRect(this), this.#___private_handleClick_8 = _init_handleClick(this), this.#___private_order_9 = _init_order(this), this.#___private_shape_10 = _init_shape(this), this.#___private_shapeStyle_11 = _init_shapeStyle(this), this.#___private_shapeType_12 = _init_shapeType(this);
    }
}
