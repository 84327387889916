import { atom } from 'jotai';
export const openWorkspacesModalAtom = atom(false);
export const openSignOutModalAtom = atom(false);
export const openQuotaModalAtom = atom(false);
export const openStarAFFiNEModalAtom = atom(false);
export const openIssueFeedbackModalAtom = atom(false);
export const openHistoryTipsModalAtom = atom(false);
export const rightSidebarWidthAtom = atom(320);
export const openSettingModalAtom = atom({
    activeTab: 'appearance',
    open: false
});
export const authAtom = atom({
    openModal: false,
    state: 'signIn'
});
export const allPageFilterSelectAtom = atom('docs');
export const openWorkspaceListModalAtom = atom(false);
