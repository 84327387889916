import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { notify } from '@affine/component';
import { AuthContent, BackButton, CountDownRender, ModalHeader } from '@affine/component/auth-components';
import { Button } from '@affine/component/ui/button';
import { useAsyncCallback } from '@affine/core/components/hooks/affine-async-hooks';
import { AuthService } from '@affine/core/modules/cloud';
import { Trans, useI18n } from '@affine/i18n';
import { useService } from '@toeverything/infra';
import { useCallback, useEffect, useState } from 'react';
import * as style from './style.css';
import { Captcha, useCaptcha } from './use-captcha';
export const AfterSignInSendEmail = ({ setAuthData: setAuth, email })=>{
    const [resendCountDown, setResendCountDown] = useState(60);
    useEffect(()=>{
        const timer = setInterval(()=>{
            setResendCountDown((c)=>Math.max(c - 1, 0));
        }, 1000);
        return ()=>{
            clearInterval(timer);
        };
    }, []);
    const [isSending, setIsSending] = useState(false);
    const t = useI18n();
    const authService = useService(AuthService);
    const [verifyToken, challenge] = useCaptcha();
    const onResendClick = useAsyncCallback(async ()=>{
        setIsSending(true);
        try {
            if (verifyToken) {
                setResendCountDown(60);
                await authService.sendEmailMagicLink(email, verifyToken, challenge);
            }
        } catch (err) {
            console.error(err);
            notify.error({
                title: 'Failed to send email, please try again.'
            });
        }
        setIsSending(false);
    }, [
        authService,
        challenge,
        email,
        verifyToken
    ]);
    const onSignInWithPasswordClick = useCallback(()=>{
        setAuth({
            state: 'signInWithPassword'
        });
    }, [
        setAuth
    ]);
    const onBackBottomClick = useCallback(()=>{
        setAuth({
            state: 'signIn'
        });
    }, [
        setAuth
    ]);
    return _jsxs(_Fragment, {
        children: [
            _jsx(ModalHeader, {
                title: t['com.affine.auth.sign.in'](),
                subTitle: t['com.affine.auth.sign.in.sent.email.subtitle']()
            }),
            _jsxs(AuthContent, {
                style: {
                    height: 100
                },
                children: [
                    _jsx(Trans, {
                        i18nKey: "com.affine.auth.sign.sent.email.message.sent-tips",
                        values: {
                            email
                        },
                        components: {
                            a: _jsx("a", {
                                href: `mailto:${email}`
                            })
                        }
                    }),
                    t['com.affine.auth.sign.sent.email.message.sent-tips.sign-in']()
                ]
            }),
            _jsx("div", {
                className: style.resendWrapper,
                children: resendCountDown <= 0 ? _jsxs(_Fragment, {
                    children: [
                        _jsx(Captcha, {}),
                        _jsx(Button, {
                            style: !verifyToken ? {
                                cursor: 'not-allowed'
                            } : {},
                            disabled: !verifyToken || isSending,
                            variant: "plain",
                            size: "large",
                            onClick: onResendClick,
                            children: t['com.affine.auth.sign.auth.code.resend.hint']()
                        })
                    ]
                }) : _jsxs("div", {
                    className: style.sentRow,
                    children: [
                        _jsx("div", {
                            className: style.sentMessage,
                            children: t['com.affine.auth.sent']()
                        }),
                        _jsx(CountDownRender, {
                            className: style.resendCountdown,
                            timeLeft: resendCountDown
                        })
                    ]
                })
            }),
            _jsxs("div", {
                className: style.authMessage,
                style: {
                    marginTop: 20
                },
                children: [
                    t['com.affine.auth.sign.auth.code.message'](),
                    " ",
                    _jsx(Trans, {
                        i18nKey: "com.affine.auth.sign.auth.code.message.password",
                        components: {
                            1: _jsx("span", {
                                className: "link",
                                "data-testid": "sign-in-with-password",
                                onClick: onSignInWithPasswordClick
                            })
                        }
                    })
                ]
            }),
            _jsx(BackButton, {
                onClick: onBackBottomClick
            })
        ]
    });
};
