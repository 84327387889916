import { PaymentRequiredError, UnauthorizedError } from '@blocksuite/affine/blocks';
import { Slot } from '@blocksuite/affine/store';
export class AIProvider {
    static get slots() {
        return AIProvider.instance.slots;
    }
    static get actions() {
        return AIProvider.instance.actions;
    }
    static get userInfo() {
        return AIProvider.instance.userInfoFn();
    }
    static get photoEngine() {
        return AIProvider.instance.photoEngine;
    }
    static get histories() {
        return AIProvider.instance.histories;
    }
    static get actionHistory() {
        return AIProvider.instance.actionHistory;
    }
    static get toggleGeneralAIOnboarding() {
        return AIProvider.instance.toggleGeneralAIOnboarding;
    }
    static get forkChat() {
        return AIProvider.instance.forkChat;
    }
    static{
        this.instance = new AIProvider();
    }
    static{
        this.LAST_ACTION_SESSIONID = '';
    }
    static{
        this.LAST_ROOT_SESSION_ID = '';
    }
    static{
        this.MAX_LOCAL_HISTORY = 10;
    }
    provideAction(id, action) {
        if (this.actions[id]) {
            console.warn(`AI action ${id} is already provided`);
        }
        this.actions[id] = (...args)=>{
            const options = args[0];
            const slots = this.slots;
            slots.actions.emit({
                action: id,
                options,
                event: 'started'
            });
            this.actionHistory.push({
                action: id,
                options
            });
            if (this.actionHistory.length > AIProvider.MAX_LOCAL_HISTORY) {
                this.actionHistory.shift();
            }
            const result = action(...args);
            const isTextStream = (m)=>Reflect.has(m, Symbol.asyncIterator);
            if (isTextStream(result)) {
                return {
                    [Symbol.asyncIterator]: async function*() {
                        try {
                            yield* result;
                            slots.actions.emit({
                                action: id,
                                options,
                                event: 'finished'
                            });
                        } catch (err) {
                            slots.actions.emit({
                                action: id,
                                options,
                                event: 'error'
                            });
                            if (err instanceof PaymentRequiredError) {
                                slots.actions.emit({
                                    action: id,
                                    options,
                                    event: 'aborted:paywall'
                                });
                            } else if (err instanceof UnauthorizedError) {
                                slots.actions.emit({
                                    action: id,
                                    options,
                                    event: 'aborted:login-required'
                                });
                            } else {
                                slots.actions.emit({
                                    action: id,
                                    options,
                                    event: 'aborted:server-error'
                                });
                            }
                            throw err;
                        }
                    }
                };
            } else {
                return result.then((result)=>{
                    slots.actions.emit({
                        action: id,
                        options,
                        event: 'finished'
                    });
                    return result;
                }).catch((err)=>{
                    slots.actions.emit({
                        action: id,
                        options,
                        event: 'error'
                    });
                    if (err instanceof PaymentRequiredError) {
                        slots.actions.emit({
                            action: id,
                            options,
                            event: 'aborted:paywall'
                        });
                    }
                    throw err;
                });
            }
        };
    }
    static provide(id, action) {
        if (id === 'userInfo') {
            AIProvider.instance.userInfoFn = action;
        } else if (id === 'histories') {
            AIProvider.instance.histories = action;
        } else if (id === 'photoEngine') {
            AIProvider.instance.photoEngine = action;
        } else if (id === 'onboarding') {
            AIProvider.instance.toggleGeneralAIOnboarding = action;
        } else if (id === 'forkChat') {
            AIProvider.instance.forkChat = action;
        } else {
            AIProvider.instance.provideAction(id, action);
        }
    }
    constructor(){
        this.actions = {};
        this.photoEngine = null;
        this.histories = null;
        this.toggleGeneralAIOnboarding = null;
        this.forkChat = null;
        this.slots = {
            requestOpenWithChat: new Slot(),
            requestInsertTemplate: new Slot(),
            requestLogin: new Slot(),
            requestUpgradePlan: new Slot(),
            requestRunInEdgeless: new Slot(),
            actions: new Slot(),
            userInfo: new Slot(),
            toggleChatCards: new Slot()
        };
        this.actionHistory = [];
        this.userInfoFn = ()=>null;
    }
}
