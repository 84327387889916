import { jsx as _jsx } from "react/jsx-runtime";
import { RssIcon2, Skeleton } from '@affine/component';
import { useI18n } from '@affine/i18n';
import { ExplorerEmptySection } from '../../layouts/empty-section';
export const RootEmptyLoading = ()=>{
    return _jsx(Skeleton, {});
};
export const RootEmptyReady = ({ onClickCreate })=>{
    const t = useI18n();
    return _jsx(ExplorerEmptySection, {
        icon: _jsx(RssIcon2, {}),
        message: t['ai.wemem.rootAppSidebar.feeds.empty'](),
        messageTestId: "slider-bar-subscription-empty-message",
        actionText: t['ai.wemem.rootAppSidebar.feeds.action'](),
        onActionClick: onClickCreate
    });
};
export const RootEmpty = ({ isLoading, ...props })=>{
    return isLoading ? _jsx(RootEmptyLoading, {}) : _jsx(RootEmptyReady, {
        ...props
    });
};
