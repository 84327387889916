function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _dec1, _dec2, _dec3, _dec4, _init__dragging, _init__edgelessOnlyNotesSet, _init__index, _init__pageVisibleElementsMap, _init__show, _initProto;
import { AutoConnectLeftIcon, AutoConnectRightIcon, HiddenIcon, SmallDocIcon } from '@blocksuite/affine-components/icons';
import { FrameBlockModel, NoteBlockModel } from '@blocksuite/affine-model';
import { NoteDisplayMode } from '@blocksuite/affine-model';
import { matchFlavours, stopPropagation } from '@blocksuite/affine-shared/utils';
import { WidgetComponent } from '@blocksuite/block-std';
import { Bound } from '@blocksuite/global/utils';
import { css, html, nothing } from 'lit';
import { state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { isNoteBlock } from '../../edgeless/utils/query.js';
const PAGE_VISIBLE_INDEX_LABEL_WIDTH = 44;
const PAGE_VISIBLE_INDEX_LABEL_HEIGHT = 24;
const EDGELESS_ONLY_INDEX_LABEL_WIDTH = 24;
const EDGELESS_ONLY_INDEX_LABEL_HEIGHT = 24;
const INDEX_LABEL_OFFSET = 16;
function calculatePosition(gap, count, iconWidth) {
    const positions = [];
    if (count === 1) {
        positions.push([
            0,
            10
        ]);
        return positions;
    }
    const middleIndex = (count - 1) / 2;
    const isEven = count % 2 === 0;
    const middleOffset = (gap + iconWidth) / 2;
    function getSign(num) {
        return num - middleIndex > 0 ? 1 : -1;
    }
    for(let j = 0; j < count; j++){
        let left = 10;
        if (isEven) {
            if (Math.abs(j - middleIndex) < 1 && isEven) {
                left = 10 + middleOffset * getSign(j);
            } else {
                left = 10 + ((Math.ceil(Math.abs(j - middleIndex)) - 1) * (gap + 24) + middleOffset) * getSign(j);
            }
        } else {
            const offset = gap + iconWidth;
            left = 10 + Math.ceil(Math.abs(j - middleIndex)) * offset * getSign(j);
        }
        positions.push([
            0,
            left
        ]);
    }
    return positions;
}
function getIndexLabelTooltip(icon, content) {
    const styles = css`
    .index-label-tooltip {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 10px;
    }

    .index-label-tooltip-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .index-label-tooltip-content {
      font-size: var(--affine-font-sm);

      display: flex;
      height: 16px;
      line-height: 16px;
    }
  `;
    return html`<style>
      ${styles}
    </style>
    <div class="index-label-tooltip">
      <span class="index-label-tooltip-icon">${icon}</span>
      <span class="index-label-tooltip-content">${content}</span>
    </div>`;
}
function isAutoConnectElement(element) {
    return element instanceof NoteBlockModel || element instanceof FrameBlockModel;
}
export const AFFINE_EDGELESS_AUTO_CONNECT_WIDGET = 'affine-edgeless-auto-connect-widget';
_dec = state(), _dec1 = state(), _dec2 = state(), _dec3 = state(), _dec4 = state();
export class EdgelessAutoConnectWidget extends WidgetComponent {
    static{
        ({ e: [_init__dragging, _init__edgelessOnlyNotesSet, _init__index, _init__pageVisibleElementsMap, _init__show, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "_dragging"
            ],
            [
                _dec1,
                1,
                "_edgelessOnlyNotesSet"
            ],
            [
                _dec2,
                1,
                "_index"
            ],
            [
                _dec3,
                1,
                "_pageVisibleElementsMap"
            ],
            [
                _dec4,
                1,
                "_show"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .page-visible-index-label {
      box-sizing: border-box;
      padding: 0px 6px;
      border: 1px solid #0000001a;

      width: fit-content;
      height: 24px;
      min-width: 24px;

      color: var(--affine-white);
      font-size: 15px;
      line-height: 22px;
      text-align: center;

      cursor: pointer;
      user-select: none;

      border-radius: 25px;
      background: var(--affine-primary-color);
    }

    .navigator {
      width: 48px;
      padding: 4px;
      border-radius: 58px;
      border: 1px solid rgba(227, 226, 228, 1);
      transition: opacity 0.5s ease-in-out;
      background: rgba(251, 251, 252, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
    }

    .navigator div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .navigator span {
      display: inline-block;
      height: 8px;
      border: 1px solid rgba(227, 226, 228, 1);
    }

    .navigator div:hover {
      background: var(--affine-hover-color);
    }

    .navigator.show {
      opacity: 1;
    }
  `;
    }
    _EdgelessOnlyLabels() {
        const { _edgelessOnlyNotesSet } = this;
        if (!_edgelessOnlyNotesSet.size) return nothing;
        return html`${repeat(_edgelessOnlyNotesSet, (note)=>note.id, (note)=>{
            const { viewport } = this.service;
            const { zoom } = viewport;
            const bound = Bound.deserialize(note.xywh);
            const [left, right] = viewport.toViewCoord(bound.x, bound.y);
            const [width, height] = [
                bound.w * zoom,
                bound.h * zoom
            ];
            const style = styleMap({
                width: `${EDGELESS_ONLY_INDEX_LABEL_WIDTH}px`,
                height: `${EDGELESS_ONLY_INDEX_LABEL_HEIGHT}px`,
                borderRadius: '50%',
                backgroundColor: 'var(--affine-text-secondary-color)',
                border: '1px solid var(--affine-border-color)',
                color: 'var(--affine-white)',
                position: 'absolute',
                transform: `translate(${left + width / 2 - EDGELESS_ONLY_INDEX_LABEL_WIDTH / 2}px,
          ${right + height + INDEX_LABEL_OFFSET}px)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            });
            return html`<div style=${style} class="edgeless-only-index-label">
          ${HiddenIcon}
          <affine-tooltip tip-position="bottom">
            ${getIndexLabelTooltip(SmallDocIcon, 'Hidden on page')}
          </affine-tooltip>
        </div>`;
        })}`;
    }
    _getElementsAndCounts() {
        const elements = [];
        const counts = [];
        for (const [key, value] of this._pageVisibleElementsMap.entries()){
            elements.push(key);
            counts.push(value);
        }
        return {
            elements,
            counts
        };
    }
    _initLabels() {
        const { service } = this.block;
        const surfaceRefs = service.doc.getBlocksByFlavour('affine:surface-ref').map((block)=>block.model);
        const getVisibility = ()=>{
            const { selectedElements } = service.selection;
            if (selectedElements.length === 1 && !service.selection.editing && (isNoteBlock(selectedElements[0]) || surfaceRefs.some((ref)=>ref.reference === selectedElements[0].id))) {
                this._show = true;
            } else {
                this._show = false;
            }
            return this._show;
        };
        this._disposables.add(service.selection.slots.updated.on(()=>{
            getVisibility();
        }));
        this._disposables.add(this.doc.slots.blockUpdated.on((payload)=>{
            if (payload.flavour === 'affine:surface-ref') {
                switch(payload.type){
                    case 'add':
                        surfaceRefs.push(payload.model);
                        break;
                    case 'delete':
                        {
                            const idx = surfaceRefs.indexOf(payload.model);
                            if (idx >= 0) {
                                surfaceRefs.splice(idx, 1);
                            }
                        }
                        break;
                    case 'update':
                        if (payload.props.key !== 'reference') {
                            return;
                        }
                }
                this.requestUpdate();
            }
        }));
        this._disposables.add(service.surface.elementUpdated.on((payload)=>{
            if (payload.props['xywh'] && surfaceRefs.some((ref)=>ref.reference === payload.id)) {
                this.requestUpdate();
            }
        }));
    }
    _navigateToNext() {
        const { elements } = this._getElementsAndCounts();
        if (this._index >= elements.length - 1) return;
        this._index = this._index + 1;
        const element = elements[this._index];
        const bound = Bound.deserialize(element.xywh);
        this.service.selection.set({
            elements: [
                element.id
            ],
            editing: false
        });
        this.service.viewport.setViewportByBound(bound, [
            80,
            80,
            80,
            80
        ], true);
    }
    _navigateToPrev() {
        const { elements } = this._getElementsAndCounts();
        if (this._index <= 0) return;
        this._index = this._index - 1;
        const element = elements[this._index];
        const bound = Bound.deserialize(element.xywh);
        this.service.selection.set({
            elements: [
                element.id
            ],
            editing: false
        });
        this.service.viewport.setViewportByBound(bound, [
            80,
            80,
            80,
            80
        ], true);
    }
    _NavigatorComponent(elements) {
        const { viewport } = this.service;
        const { zoom } = viewport;
        const className = `navigator ${this._index >= 0 ? 'show' : 'hidden'}`;
        const element = elements[this._index];
        const bound = Bound.deserialize(element.xywh);
        const [left, right] = viewport.toViewCoord(bound.x, bound.y);
        const [width, height] = [
            bound.w * zoom,
            bound.h * zoom
        ];
        const navigatorStyle = styleMap({
            position: 'absolute',
            transform: `translate(${left + width / 2 - 26}px, ${right + height + 16}px)`
        });
        return html`<div class=${className} style=${navigatorStyle}>
      <div
        role="button"
        class="edgeless-auto-connect-previous-button"
        @pointerdown=${(e)=>{
            stopPropagation(e);
            this._navigateToPrev();
        }}
      >
        ${AutoConnectLeftIcon}
      </div>
      <span></span>
      <div
        role="button"
        class="edgeless-auto-connect-next-button"
        @pointerdown=${(e)=>{
            stopPropagation(e);
            this._navigateToNext();
        }}
      >
        ${AutoConnectRightIcon}
      </div>
    </div> `;
    }
    _PageVisibleIndexLabels(elements, counts) {
        const { viewport } = this.service;
        const { zoom } = viewport;
        let index = 0;
        return html`${repeat(elements, (element)=>element.id, (element, i)=>{
            const bound = Bound.deserialize(element.xywh$.value);
            const [left, right] = viewport.toViewCoord(bound.x, bound.y);
            const [width, height] = [
                bound.w * zoom,
                bound.h * zoom
            ];
            const style = styleMap({
                width: `${PAGE_VISIBLE_INDEX_LABEL_WIDTH}px`,
                maxWidth: `${PAGE_VISIBLE_INDEX_LABEL_WIDTH}px`,
                height: `${PAGE_VISIBLE_INDEX_LABEL_HEIGHT}px`,
                position: 'absolute',
                transform: `translate(${left + width / 2 - PAGE_VISIBLE_INDEX_LABEL_WIDTH / 2}px,
          ${right + height + INDEX_LABEL_OFFSET}px)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            });
            const components = [];
            const count = counts[i];
            const initGap = 24 / count - 24;
            const positions = calculatePosition(initGap, count, PAGE_VISIBLE_INDEX_LABEL_HEIGHT);
            for(let j = 0; j < count; j++){
                index++;
                components.push(html`
            <div
              style=${styleMap({
                    position: 'absolute',
                    top: positions[j][0] + 'px',
                    left: positions[j][1] + 'px',
                    transition: 'all 0.1s linear'
                })}
              index=${i}
              class="page-visible-index-label"
              @pointerdown=${(e)=>{
                    stopPropagation(e);
                    this._index = this._index === i ? -1 : i;
                }}
            >
              ${index}
              <affine-tooltip tip-position="bottom">
                ${getIndexLabelTooltip(SmallDocIcon, 'Page mode index')}
              </affine-tooltip>
            </div>
          `);
            }
            function updateChildrenPosition(e, positions) {
                if (!e.target) return;
                const children = e.target.children;
                Array.from(children).forEach((c, index)=>{
                    c.style.top = positions[index][0] + 'px';
                    c.style.left = positions[index][1] + 'px';
                });
            }
            return html`<div
          style=${style}
          @mouseenter=${(e)=>{
                const positions = calculatePosition(5, count, PAGE_VISIBLE_INDEX_LABEL_HEIGHT);
                updateChildrenPosition(e, positions);
            }}
          @mouseleave=${(e)=>{
                const positions = calculatePosition(initGap, count, PAGE_VISIBLE_INDEX_LABEL_HEIGHT);
                updateChildrenPosition(e, positions);
            }}
        >
          ${components}
        </div>`;
        })}`;
    }
    _setHostStyle() {
        this.style.position = 'absolute';
        this.style.top = '0';
        this.style.left = '0';
        this.style.zIndex = '1';
    }
    connectedCallback() {
        super.connectedCallback();
        this._setHostStyle();
        this._initLabels();
    }
    firstUpdated() {
        const { _disposables, service } = this;
        _disposables.add(service.viewport.viewportUpdated.on(()=>{
            this.requestUpdate();
        }));
        _disposables.add(service.selection.slots.updated.on(()=>{
            const { selectedElements } = service.selection;
            if (!(selectedElements.length === 1 && isNoteBlock(selectedElements[0]))) {
                this._index = -1;
            }
        }));
        _disposables.add(service.uiEventDispatcher.add('dragStart', ()=>{
            this._dragging = true;
        }));
        _disposables.add(service.uiEventDispatcher.add('dragEnd', ()=>{
            this._dragging = false;
        }));
        _disposables.add(service.slots.elementResizeStart.on(()=>{
            this._dragging = true;
        }));
        _disposables.add(service.slots.elementResizeEnd.on(()=>{
            this._dragging = false;
        }));
    }
    render() {
        const advancedVisibilityEnabled = this.doc.awarenessStore.getFlag('enable_advanced_block_visibility');
        if (!this._show || this._dragging || !advancedVisibilityEnabled) {
            return nothing;
        }
        this._updateLabels();
        const { elements, counts } = this._getElementsAndCounts();
        return html`${this._PageVisibleIndexLabels(elements, counts)}
    ${this._EdgelessOnlyLabels()}
    ${this._index >= 0 && this._index < elements.length ? this._NavigatorComponent(elements) : nothing} `;
    }
    #___private__dragging_1;
    get _dragging() {
        return this.#___private__dragging_1;
    }
    set _dragging(_v) {
        this.#___private__dragging_1 = _v;
    }
    #___private__edgelessOnlyNotesSet_2;
    get _edgelessOnlyNotesSet() {
        return this.#___private__edgelessOnlyNotesSet_2;
    }
    set _edgelessOnlyNotesSet(_v) {
        this.#___private__edgelessOnlyNotesSet_2 = _v;
    }
    #___private__index_3;
    get _index() {
        return this.#___private__index_3;
    }
    set _index(_v) {
        this.#___private__index_3 = _v;
    }
    #___private__pageVisibleElementsMap_4;
    get _pageVisibleElementsMap() {
        return this.#___private__pageVisibleElementsMap_4;
    }
    set _pageVisibleElementsMap(_v) {
        this.#___private__pageVisibleElementsMap_4 = _v;
    }
    #___private__show_5;
    get _show() {
        return this.#___private__show_5;
    }
    set _show(_v) {
        this.#___private__show_5 = _v;
    }
    constructor(...args){
        super(...args), this._updateLabels = ()=>{
            const service = this.service;
            if (!service.doc.root) return;
            const pageVisibleBlocks = new Map();
            const notes = service.doc.root?.children.filter((child)=>matchFlavours(child, [
                    'affine:note'
                ]));
            const edgelessOnlyNotesSet = new Set();
            notes.forEach((note)=>{
                if (isNoteBlock(note)) {
                    if (note.displayMode$.value === NoteDisplayMode.EdgelessOnly) {
                        edgelessOnlyNotesSet.add(note);
                    } else if (note.displayMode$.value === NoteDisplayMode.DocAndEdgeless) {
                        pageVisibleBlocks.set(note, 1);
                    }
                }
                note.children.forEach((model)=>{
                    if (matchFlavours(model, [
                        'affine:surface-ref'
                    ])) {
                        const reference = service.getElementById(model.reference);
                        if (!isAutoConnectElement(reference)) return;
                        if (!pageVisibleBlocks.has(reference)) {
                            pageVisibleBlocks.set(reference, 1);
                        } else {
                            pageVisibleBlocks.set(reference, pageVisibleBlocks.get(reference) + 1);
                        }
                    }
                });
            });
            this._edgelessOnlyNotesSet = edgelessOnlyNotesSet;
            this._pageVisibleElementsMap = pageVisibleBlocks;
        }, this.#___private__dragging_1 = (_initProto(this), _init__dragging(this, false)), this.#___private__edgelessOnlyNotesSet_2 = _init__edgelessOnlyNotesSet(this, new Set()), this.#___private__index_3 = _init__index(this, -1), this.#___private__pageVisibleElementsMap_4 = _init__pageVisibleElementsMap(this, new Map()), this.#___private__show_5 = _init__show(this, false);
    }
}
