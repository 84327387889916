import { createIdentifier } from '@blocksuite/global/di';
import { Slot } from '@blocksuite/global/utils';
export const RefNodeSlotsProvider = createIdentifier('AffineRefNodeSlots');
export function RefNodeSlotsExtension(slots = {
    docLinkClicked: new Slot()
}) {
    return {
        setup: (di)=>{
            di.addImpl(RefNodeSlotsProvider, ()=>slots);
        }
    };
}
