import { QuickSearchProvider } from '@blocksuite/affine-shared/services';
export const insertLinkByQuickSearchCommand = (ctx, next)=>{
    const { std } = ctx;
    const quickSearchService = std.getOptional(QuickSearchProvider);
    if (!quickSearchService) {
        next();
        return;
    }
    const insertedLinkType = quickSearchService.openQuickSearch().then((result)=>{
        if (!result) return null;
        if ('docId' in result) {
            std.command.exec('insertEmbedLinkedDoc', {
                docId: result.docId,
                params: result.params
            });
            return {
                flavour: 'affine:embed-linked-doc'
            };
        }
        if ('externalUrl' in result) {
            std.command.exec('insertBookmark', {
                url: result.externalUrl
            });
            return {
                flavour: 'affine:bookmark'
            };
        }
        return null;
    });
    next({
        insertedLinkType
    });
};
