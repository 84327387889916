import { builtInTemplates as builtInEdgelessTemplates } from '@affine/templates/edgeless';
import { builtInTemplates as builtInStickersTemplates } from '@affine/templates/stickers';
import { EdgelessNoteBlockSpec, EdgelessSurfaceBlockSpec, EdgelessSurfaceRefBlockSpec, EdgelessTemplatePanel, EdgelessTextBlockSpec, FrameBlockSpec } from '@blocksuite/affine/blocks';
import { AIBlockSpecs, DefaultBlockSpecs } from './common';
import { createEdgelessRootBlockSpec } from './custom/root-block';
export function createEdgelessModeSpecs(framework, enableAI) {
    return [
        ...enableAI ? AIBlockSpecs : DefaultBlockSpecs,
        EdgelessSurfaceBlockSpec,
        EdgelessSurfaceRefBlockSpec,
        FrameBlockSpec,
        EdgelessTextBlockSpec,
        EdgelessNoteBlockSpec,
        createEdgelessRootBlockSpec(framework, enableAI)
    ].flat();
}
export function effects() {
    EdgelessTemplatePanel.templates.extend(builtInStickersTemplates);
    EdgelessTemplatePanel.templates.extend(builtInEdgelessTemplates);
}
