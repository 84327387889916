import { DocsService, WorkspaceScope } from '@toeverything/infra';
import { WorkspacePropertiesAdapter } from '../properties';
import { DocDisplayMetaService } from './services/doc-display-meta';
export { DocDisplayMetaService };
export function configureDocDisplayMetaModule(framework) {
    framework.scope(WorkspaceScope).service(DocDisplayMetaService, [
        WorkspacePropertiesAdapter,
        DocsService
    ]);
}
