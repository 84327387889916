import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SettingHeader } from '@affine/component/setting-components';
import { useI18n } from '@affine/i18n';
import { Edgeless } from './edgeless';
import { General } from './general';
import { Page } from './page';
export const EditorSettings = ()=>{
    const t = useI18n();
    return _jsxs(_Fragment, {
        children: [
            _jsx(SettingHeader, {
                title: t['com.affine.settings.editorSettings.title'](),
                subtitle: t['com.affine.settings.editorSettings.subtitle']()
            }),
            _jsx(General, {}),
            _jsx(Page, {}),
            _jsx(Edgeless, {})
        ]
    });
};
