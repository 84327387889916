import { matchFlavours } from '@blocksuite/affine-shared/utils';
import { focusTextModel } from '../dom.js';
import { beforeConvert } from './utils.js';
export function toParagraph(std, model, type, prefix) {
    const { doc } = std;
    if (!matchFlavours(model, [
        'affine:paragraph'
    ])) {
        const parent = doc.getParent(model);
        if (!parent) return;
        const index = parent.children.indexOf(model);
        beforeConvert(std, model, prefix.length);
        const blockProps = {
            type: type,
            text: model.text?.clone(),
            children: model.children
        };
        doc.deleteBlock(model, {
            deleteChildren: false
        });
        const id = doc.addBlock('affine:paragraph', blockProps, parent, index);
        focusTextModel(std, id);
        return id;
    }
    if (matchFlavours(model, [
        'affine:paragraph'
    ]) && model.type !== type) {
        beforeConvert(std, model, prefix.length);
        doc.updateBlock(model, {
            type
        });
        focusTextModel(std, model.id);
    }
    return model.id;
}
