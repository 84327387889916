function applyDecs2203RFactory() {
    function createAddInitializerMethod(initializers, decoratorFinishedRef) {
        return function addInitializer(initializer) {
            assertNotFinished(decoratorFinishedRef, "addInitializer");
            assertCallable(initializer, "An initializer");
            initializers.push(initializer);
        };
    }
    function memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value) {
        var kindStr;
        switch(kind){
            case 1:
                kindStr = "accessor";
                break;
            case 2:
                kindStr = "method";
                break;
            case 3:
                kindStr = "getter";
                break;
            case 4:
                kindStr = "setter";
                break;
            default:
                kindStr = "field";
        }
        var ctx = {
            kind: kindStr,
            name: isPrivate ? "#" + name : name,
            static: isStatic,
            private: isPrivate,
            metadata: metadata
        };
        var decoratorFinishedRef = {
            v: false
        };
        ctx.addInitializer = createAddInitializerMethod(initializers, decoratorFinishedRef);
        var get, set;
        if (kind === 0) {
            if (isPrivate) {
                get = desc.get;
                set = desc.set;
            } else {
                get = function() {
                    return this[name];
                };
                set = function(v) {
                    this[name] = v;
                };
            }
        } else if (kind === 2) {
            get = function() {
                return desc.value;
            };
        } else {
            if (kind === 1 || kind === 3) {
                get = function() {
                    return desc.get.call(this);
                };
            }
            if (kind === 1 || kind === 4) {
                set = function(v) {
                    desc.set.call(this, v);
                };
            }
        }
        ctx.access = get && set ? {
            get: get,
            set: set
        } : get ? {
            get: get
        } : {
            set: set
        };
        try {
            return dec(value, ctx);
        } finally{
            decoratorFinishedRef.v = true;
        }
    }
    function assertNotFinished(decoratorFinishedRef, fnName) {
        if (decoratorFinishedRef.v) {
            throw new Error("attempted to call " + fnName + " after decoration was finished");
        }
    }
    function assertCallable(fn, hint) {
        if (typeof fn !== "function") {
            throw new TypeError(hint + " must be a function");
        }
    }
    function assertValidReturnValue(kind, value) {
        var type = typeof value;
        if (kind === 1) {
            if (type !== "object" || value === null) {
                throw new TypeError("accessor decorators must return an object with get, set, or init properties or void 0");
            }
            if (value.get !== undefined) {
                assertCallable(value.get, "accessor.get");
            }
            if (value.set !== undefined) {
                assertCallable(value.set, "accessor.set");
            }
            if (value.init !== undefined) {
                assertCallable(value.init, "accessor.init");
            }
        } else if (type !== "function") {
            var hint;
            if (kind === 0) {
                hint = "field";
            } else if (kind === 10) {
                hint = "class";
            } else {
                hint = "method";
            }
            throw new TypeError(hint + " decorators must return a function or void 0");
        }
    }
    function applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata) {
        var decs = decInfo[0];
        var desc, init, value;
        if (isPrivate) {
            if (kind === 0 || kind === 1) {
                desc = {
                    get: decInfo[3],
                    set: decInfo[4]
                };
            } else if (kind === 3) {
                desc = {
                    get: decInfo[3]
                };
            } else if (kind === 4) {
                desc = {
                    set: decInfo[3]
                };
            } else {
                desc = {
                    value: decInfo[3]
                };
            }
        } else if (kind !== 0) {
            desc = Object.getOwnPropertyDescriptor(base, name);
        }
        if (kind === 1) {
            value = {
                get: desc.get,
                set: desc.set
            };
        } else if (kind === 2) {
            value = desc.value;
        } else if (kind === 3) {
            value = desc.get;
        } else if (kind === 4) {
            value = desc.set;
        }
        var newValue, get, set;
        if (typeof decs === "function") {
            newValue = memberDec(decs, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
            if (newValue !== void 0) {
                assertValidReturnValue(kind, newValue);
                if (kind === 0) {
                    init = newValue;
                } else if (kind === 1) {
                    init = newValue.init;
                    get = newValue.get || value.get;
                    set = newValue.set || value.set;
                    value = {
                        get: get,
                        set: set
                    };
                } else {
                    value = newValue;
                }
            }
        } else {
            for(var i = decs.length - 1; i >= 0; i--){
                var dec = decs[i];
                newValue = memberDec(dec, name, desc, initializers, kind, isStatic, isPrivate, metadata, value);
                if (newValue !== void 0) {
                    assertValidReturnValue(kind, newValue);
                    var newInit;
                    if (kind === 0) {
                        newInit = newValue;
                    } else if (kind === 1) {
                        newInit = newValue.init;
                        get = newValue.get || value.get;
                        set = newValue.set || value.set;
                        value = {
                            get: get,
                            set: set
                        };
                    } else {
                        value = newValue;
                    }
                    if (newInit !== void 0) {
                        if (init === void 0) {
                            init = newInit;
                        } else if (typeof init === "function") {
                            init = [
                                init,
                                newInit
                            ];
                        } else {
                            init.push(newInit);
                        }
                    }
                }
            }
        }
        if (kind === 0 || kind === 1) {
            if (init === void 0) {
                init = function(instance, init) {
                    return init;
                };
            } else if (typeof init !== "function") {
                var ownInitializers = init;
                init = function(instance, init) {
                    var value = init;
                    for(var i = 0; i < ownInitializers.length; i++){
                        value = ownInitializers[i].call(instance, value);
                    }
                    return value;
                };
            } else {
                var originalInitializer = init;
                init = function(instance, init) {
                    return originalInitializer.call(instance, init);
                };
            }
            ret.push(init);
        }
        if (kind !== 0) {
            if (kind === 1) {
                desc.get = value.get;
                desc.set = value.set;
            } else if (kind === 2) {
                desc.value = value;
            } else if (kind === 3) {
                desc.get = value;
            } else if (kind === 4) {
                desc.set = value;
            }
            if (isPrivate) {
                if (kind === 1) {
                    ret.push(function(instance, args) {
                        return value.get.call(instance, args);
                    });
                    ret.push(function(instance, args) {
                        return value.set.call(instance, args);
                    });
                } else if (kind === 2) {
                    ret.push(value);
                } else {
                    ret.push(function(instance, args) {
                        return value.call(instance, args);
                    });
                }
            } else {
                Object.defineProperty(base, name, desc);
            }
        }
    }
    function applyMemberDecs(Class, decInfos, metadata) {
        var ret = [];
        var protoInitializers;
        var staticInitializers;
        var existingProtoNonFields = new Map();
        var existingStaticNonFields = new Map();
        for(var i = 0; i < decInfos.length; i++){
            var decInfo = decInfos[i];
            if (!Array.isArray(decInfo)) continue;
            var kind = decInfo[1];
            var name = decInfo[2];
            var isPrivate = decInfo.length > 3;
            var isStatic = kind >= 5;
            var base;
            var initializers;
            if (isStatic) {
                base = Class;
                kind = kind - 5;
                staticInitializers = staticInitializers || [];
                initializers = staticInitializers;
            } else {
                base = Class.prototype;
                protoInitializers = protoInitializers || [];
                initializers = protoInitializers;
            }
            if (kind !== 0 && !isPrivate) {
                var existingNonFields = isStatic ? existingStaticNonFields : existingProtoNonFields;
                var existingKind = existingNonFields.get(name) || 0;
                if (existingKind === true || existingKind === 3 && kind !== 4 || existingKind === 4 && kind !== 3) {
                    throw new Error("Attempted to decorate a public method/accessor that has the same name as a previously decorated public method/accessor. This is not currently supported by the decorators plugin. Property name was: " + name);
                } else if (!existingKind && kind > 2) {
                    existingNonFields.set(name, kind);
                } else {
                    existingNonFields.set(name, true);
                }
            }
            applyMemberDec(ret, base, decInfo, name, kind, isStatic, isPrivate, initializers, metadata);
        }
        pushInitializers(ret, protoInitializers);
        pushInitializers(ret, staticInitializers);
        return ret;
    }
    function pushInitializers(ret, initializers) {
        if (initializers) {
            ret.push(function(instance) {
                for(var i = 0; i < initializers.length; i++){
                    initializers[i].call(instance);
                }
                return instance;
            });
        }
    }
    function applyClassDecs(targetClass, classDecs, metadata) {
        if (classDecs.length > 0) {
            var initializers = [];
            var newClass = targetClass;
            var name = targetClass.name;
            for(var i = classDecs.length - 1; i >= 0; i--){
                var decoratorFinishedRef = {
                    v: false
                };
                try {
                    var nextNewClass = classDecs[i](newClass, {
                        kind: "class",
                        name: name,
                        addInitializer: createAddInitializerMethod(initializers, decoratorFinishedRef),
                        metadata
                    });
                } finally{
                    decoratorFinishedRef.v = true;
                }
                if (nextNewClass !== undefined) {
                    assertValidReturnValue(10, nextNewClass);
                    newClass = nextNewClass;
                }
            }
            return [
                defineMetadata(newClass, metadata),
                function() {
                    for(var i = 0; i < initializers.length; i++){
                        initializers[i].call(newClass);
                    }
                }
            ];
        }
    }
    function defineMetadata(Class, metadata) {
        return Object.defineProperty(Class, Symbol.metadata || Symbol.for("Symbol.metadata"), {
            configurable: true,
            enumerable: true,
            value: metadata
        });
    }
    return function applyDecs2203R(targetClass, memberDecs, classDecs, parentClass) {
        if (parentClass !== void 0) {
            var parentMetadata = parentClass[Symbol.metadata || Symbol.for("Symbol.metadata")];
        }
        var metadata = Object.create(parentMetadata === void 0 ? null : parentMetadata);
        var e = applyMemberDecs(targetClass, memberDecs, metadata);
        if (!classDecs.length) defineMetadata(targetClass, metadata);
        return {
            e: e,
            get c () {
                return applyClassDecs(targetClass, classDecs, metadata);
            }
        };
    };
}
function _apply_decs_2203_r(targetClass, memberDecs, classDecs, parentClass) {
    return (_apply_decs_2203_r = applyDecs2203RFactory())(targetClass, memberDecs, classDecs, parentClass);
}
var _dec, _init_column, _initProto;
import { WithDisposable } from '@blocksuite/global/utils';
import { css, html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { formatNumber } from '../../../property-presets/number/utils/formatter.js';
const IncreaseDecimalPlacesIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10.5H2.66176V13.5H0V10.5ZM13.3333 18H20V21H13.3333V18ZM22.6259 17.9356L24 19.5282L22.6312 21.0585L20 24V15L22.6259 17.9356ZM3.99019 4.4953C3.99019 2.01262 5.78279 0 7.98405 0C10.1898 0 11.9779 2.02109 11.9779 4.4953V9.0047C11.9779 11.4874 10.1853 13.5 7.98405 13.5C5.7783 13.5 3.99019 11.4789 3.99019 9.0047V4.4953ZM6 4.49786V9.00214C6 10.2525 6.89543 11.25 8 11.25C9.11227 11.25 10 10.2436 10 9.00214V4.49786C10 3.24754 9.10457 2.25 8 2.25C6.88773 2.25 6 3.2564 6 4.49786ZM13.3235 4.4953C13.3235 2.01262 15.1161 0 17.3174 0C19.5231 0 21.3113 2.02109 21.3113 4.4953V9.0047C21.3113 11.4874 19.5187 13.5 17.3174 13.5C15.1116 13.5 13.3235 11.4789 13.3235 9.0047V4.4953ZM15.3333 4.49786V9.00214C15.3333 10.2525 16.2288 11.25 17.3333 11.25C18.4456 11.25 19.3333 10.2436 19.3333 9.00214V4.49786C19.3333 3.24754 18.4379 2.25 17.3333 2.25C16.2211 2.25 15.3333 3.2564 15.3333 4.49786Z"
      fill="currentColor"
    />
  </svg>
`;
const DecreaseDecimalPlacesIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10.5H3V13.5H0V10.5ZM18.09 22.41L16.305 20.625H24V18.375H16.305L18.09 16.59L16.5 15L12 19.5L16.5 24L18.09 22.41ZM13.5 9.375V4.125C13.5 1.845 11.655 0 9.375 0C7.095 0 5.25 1.845 5.25 4.125V9.375C5.25 11.655 7.095 13.5 9.375 13.5C11.655 13.5 13.5 11.655 13.5 9.375ZM11.25 9.375C11.25 10.41 10.41 11.25 9.375 11.25C8.34 11.25 7.5 10.41 7.5 9.375V4.125C7.5 3.09 8.34 2.25 9.375 2.25C10.41 2.25 11.25 3.09 11.25 4.125V9.375Z"
      fill="currentColor"
    />
  </svg>
`;
_dec = property({
    attribute: false
});
export class DatabaseNumberFormatBar extends WithDisposable(LitElement) {
    static{
        ({ e: [_init_column, _initProto] } = _apply_decs_2203_r(this, [
            [
                _dec,
                1,
                "column"
            ]
        ], []));
    }
    static{
        this.styles = css`
    .number-format-toolbar-container {
      padding: 4px 12px;
      display: flex;
      gap: 7px;
      flex-direction: column;
    }

    .number-format-decimal-places {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;
    }

    .number-format-toolbar-button {
      box-sizing: border-box;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      color: var(--affine-icon-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      position: relative;

      user-select: none;
    }

    .number-format-toolbar-button svg {
      width: 16px;
      height: 16px;
    }

    .number-formatting-sample {
      font-size: var(--affine-font-xs);
      color: var(--affine-icon-color);
      margin-left: auto;
    }
    .number-format-toolbar-button:hover {
      background-color: var(--affine-hover-color);
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: var(--affine-border-color);
    }
  `;
    }
    render() {
        return html`
      <div class="number-format-toolbar-container">
        <div class="number-format-decimal-places">
          <button
            class="number-format-toolbar-button"
            aria-label="decrease decimal places"
            @click=${this._decrementDecimalPlaces}
          >
            ${DecreaseDecimalPlacesIcon}
          </button>

          <button
            class="number-format-toolbar-button"
            aria-label="increase decimal places"
            @click=${this._incrementDecimalPlaces}
          >
            ${IncreaseDecimalPlacesIcon}
          </button>
          <span class="number-formatting-sample">
            &lpar;&nbsp;${formatNumber(1, 'number', this.column.data$.value.decimal ?? 0)}&nbsp;&rpar;
          </span>
        </div>
        <div class="divider"></div>
      </div>
    `;
    }
    #___private_column_1;
    get column() {
        return this.#___private_column_1;
    }
    set column(_v) {
        this.#___private_column_1 = _v;
    }
    constructor(...args){
        super(...args), this._decrementDecimalPlaces = ()=>{
            this.column.dataUpdate((data)=>({
                    decimal: Math.max((data.decimal ?? 0) - 1, 0)
                }));
            this.requestUpdate();
        }, this._incrementDecimalPlaces = ()=>{
            this.column.dataUpdate((data)=>({
                    decimal: Math.min((data.decimal ?? 0) + 1, 8)
                }));
            this.requestUpdate();
        }, this.#___private_column_1 = (_initProto(this), _init_column(this));
    }
}
