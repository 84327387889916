import { transformDeltasToEmbedDeltas } from '../utils/index.js';
export class DeltaService {
    get embedDeltas() {
        return transformDeltasToEmbedDeltas(this.editor, this.editor.yTextDeltas);
    }
    constructor(editor){
        this.editor = editor;
        this.getDeltaByRangeIndex = (rangeIndex)=>{
            const deltas = this.editor.embedDeltas;
            let index = 0;
            for (const delta of deltas){
                if (index + delta.insert.length >= rangeIndex) {
                    return delta;
                }
                index += delta.insert.length;
            }
            return null;
        };
        this.getDeltasByInlineRange = (inlineRange)=>{
            return this.mapDeltasInInlineRange(inlineRange, (delta, index)=>[
                    delta,
                    {
                        index,
                        length: delta.insert.length
                    }
                ]);
        };
        this.mapDeltasInInlineRange = (inlineRange, callback)=>{
            const deltas = this.editor.embedDeltas;
            const result = [];
            deltas.reduce((rangeIndex, delta, deltaIndex)=>{
                const length = delta.insert.length;
                const from = inlineRange.index - length;
                const to = inlineRange.index + inlineRange.length;
                const deltaInRange = rangeIndex >= from && (rangeIndex < to || inlineRange.length === 0 && rangeIndex === inlineRange.index);
                if (deltaInRange) {
                    const value = callback(delta, rangeIndex, deltaIndex);
                    result.push(value);
                }
                return rangeIndex + length;
            }, 0);
            return result;
        };
    }
}
