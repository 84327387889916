import { ParagraphBlockSchema } from '@blocksuite/affine-model';
import { BlockService } from '@blocksuite/block-std';
export class ParagraphBlockService extends BlockService {
    static{
        this.flavour = ParagraphBlockSchema.model.flavour;
    }
    constructor(...args){
        super(...args), this.placeholderGenerator = (model)=>{
            if (model.type === 'text') {
                return "Type '/' for commands";
            }
            const placeholders = {
                h1: 'Heading 1',
                h2: 'Heading 2',
                h3: 'Heading 3',
                h4: 'Heading 4',
                h5: 'Heading 5',
                h6: 'Heading 6',
                quote: ''
            };
            return placeholders[model.type];
        };
    }
}
